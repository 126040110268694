import Joi from 'joi-browser'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { emailAvailability } from '../../../store/patient'
import AlertError from '../../common/alertError'
import Form from '../../common/form/form'
import {
  emailInvalid,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  relationRequired,
} from '../../common/misc'

class GuardianDetails extends Form {
  state = {
    relations: [
      {
        id: 'Father',
        name: 'Father',
      },
      {
        id: 'Mother',
        name: 'Mother',
      },

      {
        id: 'Grand Father',
        name: 'Grand Father',
      },
      {
        id: 'Grand Mother',
        name: 'Grand Mother',
      },
      {
        id: 'Brother',
        name: 'Brother',
      },
      {
        id: 'Sister',
        name: 'Sister',
      },
      { id: 'Husband', name: 'Husband' },
      { id: 'Friend', name: 'Friend' },
      { id: 'Others', name: 'Others' },
    ],
    data: {
      email_id: '',
      first_name: '',
      last_name: '',
      relation: '',
    },
    errors: {},
  }

  componentDidMount = () => {
    if (this.props.allState.guardianDetails.first_name) {
      const {
        email_id,
        first_name,
        last_name,
        relation,
      } = this.props.allState.guardianDetails
      this.setState({
        data: {
          email_id,
          first_name,
          last_name,
          relation,
        },
      })
    }
  }

  schema = {
    relation: Joi.string()
      .required()
      .label('Relation')
      .error(() => {
        return { message: relationRequired }
      }),
    email_id: Joi.string()
      .email()
      .max(50)
      .required()
      .label('Email')
      .error((errors) => {
        errors.forEach((err) => {
          console.log('eeee', err)
          switch (err.type) {
            case 'any.empty':
              err.message = emailRequired
              break

            case 'string.email':
              err.message = emailInvalid
              break
            case 'string.max':
              err.message = emailInvalid
              break
          }
        })
        return errors
      }),
    first_name: Joi.string()
      .required()
      .label('First Name')
      .error(() => {
        return { message: firstNameRequired }
      }),

    last_name: Joi.string()
      .required()
      .label('Last Name')
      .error(() => {
        return { message: lastNameRequired }
      }),
  }
  dateFormat = (date) => {
    var today = new Date(date)
    var dd = today.getDate()

    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }
    return mm + '/' + dd + '/' + yyyy
  }

  doSubmit = () => {
    this.props.emailAvailability(
      { email: this.state.data.email_id, type: 2 },
      this.callback,
    )
  }

  callback = (res) => {
    this.setState({
      loader: false,
      btnClass: 'btn btn-lg btn-primary btn-block',
    })

    if (res.status === 200) {
      this.props.updateGuardianDetails(this.state.data)
      this.props.updateStep(null, 5)
    } else {
      toast(
        <AlertError message="A user with this email already exists. Please login to access the dashboard." />,
      )
    }
  }
  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <a href="" onClick={(e) => this.props.updateStep(e, 10)}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  ></path>
                </svg>
              </a>
              <div className="mr-step">Step 4/5</div>
              <h2 className="mb-3">Caretaker/ Guardian's Details</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group custom-input">
                <div className="form-group custom-input show">
                  <label htmlFor="text1">Mobile Number</label>
                  <input
                    type="tel"
                    id="text1"
                    className="form-control"
                    placeholder=""
                    value={'+91 ' + this.props.allState.verifyMobile.mobile}
                    readOnly
                  />
                </div>
                {this.renderInput('email_id', 'Email ID')}
                {this.renderInput('first_name', 'First Name')}
                {this.renderInput('last_name', 'Last Name')}
                {this.renderSelect(
                  'relation',
                  'Relation',
                  this.state.relations,
                )}

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-block"
                  >
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
})
export default withRouter(connect(null, mapDispatchToProps)(GuardianDetails))
