import React from "react";
import { connect } from "react-redux";
import Form from "../../../common/form/form";
import { withRouter } from "react-router-dom";
import close from "../../../../include/images/icon-close.svg";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import { toast } from "react-toastify";
import {
  addAnswerTreatment,
  getPlan,
  loadAnswertreatment,
  loadAssesment,
  loadMedicalPlan,
  loadTechnique,
} from "../../../../store/treatmentPlan";

class TreatmentPlan extends Form {
  state = {
    data: {
      primary_problem: [],
      secondary_problem: [],
      achieve: [],
      technique: "",
      medical_plan: "",
      assesment: "",
      recomendation: "",
      appointment_advise: {
        days: null,
        session: null,
      },
    },
    loading: false,
    field_type: "",
    error: {},
    achieve1: "",
    achieve2: "",
    achieve3: "",
    errSession: false,
    errDays: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.planForm && this.props.planForm !== prevProps.planForm) {
      this.props.loadAssesment();
      this.props.loadMedicalPlan();
      this.props.loadTechnique();
      this.props.loadAnswertreatment(this.props.patientId);
    }
    if (
      this.props.planAnswer !== null &&
      this.props.planAnswer !== prevProps.planAnswer
    ) {
      this.state.field_type =
        this.props.planAnswer && this.props.planAnswer.field_type;
      const data = { ...this.state.data };
      data.primary_problem =
        this.props.planAnswer.primary_problem &&
        this.props.planAnswer.primary_problem.map((e) => ({
          label: e,
          value: e,
        }));
      data.secondary_problem =
        this.props.planAnswer.secondary_problem &&
        this.props.planAnswer.secondary_problem.map((e) => ({
          label: e,
          value: e,
        }));
      data.recomendation =
        this.props.planAnswer && this.props.planAnswer.recomendation;
      this.state.achieve1 =
        this.props.planAnswer.achieve &&
        this.props.planAnswer.achieve[0] &&
        this.props.planAnswer.achieve[0];
      this.state.achieve2 =
        this.props.planAnswer.achieve &&
        this.props.planAnswer.achieve[1] &&
        this.props.planAnswer.achieve[1];
      this.state.achieve3 =
        this.props.planAnswer.achieve &&
        this.props.planAnswer.achieve[2] &&
        this.props.planAnswer.achieve[2];
      data.medical_plan =
        this.props.planAnswer.medical_plan &&
        this.props.planAnswer.medical_plan.map((e) => ({
          label: e,
          value: e,
        }));
      data.technique =
        this.props.planAnswer.technique &&
        this.props.planAnswer.technique.map((e) => ({
          label: e,
          value: e,
        }));
      data.assesment =
        this.props.planAnswer.assesment &&
        this.props.planAnswer.assesment.map((e) => ({
          label: e,
          value: e,
        }));
      data.appointment_advise = {
        days:
          this.props.planAnswer &&
          this.props.planAnswer.appointment_advise &&
          this.props.planAnswer.appointment_advise.days,
        session:
          this.props.planAnswer &&
          this.props.planAnswer.appointment_advise &&
          this.props.planAnswer.appointment_advise.session,
      };
      this.setState({ data });
    }
    if (
      this.props.planAnswer == null &&
      this.props.planAnswer !== prevProps.planAnswer
    ) {
      this.clearData();
    }
  };

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const data = { ...this.state.data };
    var payLoad;
    payLoad = {
      recomendation: this.state.data.recomendation,
      patient_id: this.props.patientId,
    };
    if (
      this.state.data.primary_problem &&
      this.state.data.primary_problem.length != 0
    ) {
      payLoad.primary_problem =
        this.state.data.primary_problem &&
        this.state.data.primary_problem.map((e) => e.value);
    }
    if (
      this.state.data.secondary_problem &&
      this.state.data.secondary_problem.length != 0
    ) {
      payLoad.secondary_problem =
        this.state.data.secondary_problem &&
        this.state.data.secondary_problem.map((e) => e.value);
    }
    if (this.state.data.technique && this.state.data.technique.length != 0) {
      payLoad.technique =
        this.state.data.technique &&
        this.state.data.technique.map((e) => e.value);
    }
    if (
      this.state.data.medical_plan &&
      this.state.data.medical_plan.length != 0
    ) {
      payLoad.medical_plan =
        this.state.data.medical_plan &&
        this.state.data.medical_plan.map((e) => e.value);
    }
    if (this.state.data.assesment && this.state.data.assesment.length != 0) {
      payLoad.assesment =
        this.state.data.assesment &&
        this.state.data.assesment.map((e) => e.value);
    }
    if (
      (this.state.achieve1 && this.state.achieve1.length != 0) ||
      (this.state.achieve2 && this.state.achieve2.length != 0) ||
      (this.state.achieve3 && this.state.achieve3.length != 0)
    ) {
      const achieve = [];
      if (this.state.achieve1 && this.state.achieve1.length != 0) {
        achieve.push(this.state.achieve1);
      }
      if (this.state.achieve2 && this.state.achieve2.length != 0) {
        achieve.push(this.state.achieve2);
      }
      if (this.state.achieve3 && this.state.achieve3.length != 0) {
        achieve.push(this.state.achieve3);
      }
      payLoad.achieve = achieve;
    }

    if (
      (this.state.data.appointment_advise &&
        this.state.data.appointment_advise.days) ||
      (this.state.data.appointment_advise &&
        this.state.data.appointment_advise.session)
    ) {
      payLoad.appointment_advise = {
        days:
          this.state.data.appointment_advise &&
          this.state.data.appointment_advise.days,
        session:
          this.state.data.appointment_advise &&
          this.state.data.appointment_advise.session,
      };
    }

    if (data.appointment_advise.days && !data.appointment_advise.session) {
      this.setState({ errSession: true });
    } else if (
      !data.appointment_advise.days &&
      data.appointment_advise.session
    ) {
      this.setState({ errDays: true });
    } else {
      const newPayload = _.pickBy(payLoad, _.identity);
      this.props.addAnswerTreatment(newPayload, this.callBack);
    }
  };
  callBack = (res) => {
    if (res && res.status === 200) {
      this.props.togglePlan();
      this.props.loadAnswertreatment(this.props.patientId);
      toast(<AlertSuccess message="Information Saved." />);
      this.clearDate();
    } else {
      //toast(<AlertError message="Something Went Wrong." />);
    }
  };
  clearData = () => {
    this.setState({
      data: {
        primary_problem: [],
        secondary_problem: [],
        achieve: [],
        technique: "",
        medical_plan: "",
        assesment: "",
        recomendation: "",
        appointment_advise: {
          days: "",
          session: "",
        },
      },
      field_type: "",
      achieve1: "",
      achieve2: "",
      achieve3: "",
      errSession: false,
      errDays: false,
    });
  };

  render() {
    const components = {
      DropdownIndicator: null,
    };
    return (
      <>
        <div
          class="sidebar-box list-box"
          id="prescriptions_side_panel_1"
          style={{ right: this.props.planForm === true ? "0px" : "-100%" }}
        >
          <div class="sidebar-head d-flex align-items-center">
            <h5>Treatment Plan</h5>
            <div class="sidebar-right ml-auto d-flex align-items-centers">
              <div
                class="close-img"
                onClick={() => {
                  this.clearData();
                  this.props.togglePlan();
                }}
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>
          <div class="sidebar-body height-auto p-0">
            <div class="sidebar-inner-content">
              <div class="fun-box">
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Primary problems that have been noticed are
                  </label>
                  <CreatableSelect
                    components={components}
                    noOptionsMessage={() => "Type something and press enter..."}
                    isMulti
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    placeholder="Type something and press enter..."
                    name="primary_problem"
                    styles={this.customStyles}
                    value={this.state.data && this.state.data.primary_problem}
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.primary_problem = e;
                      } else if (!e) {
                        data.primary_problem = "";
                      }
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    There is also a secondary problem of
                  </label>
                  <CreatableSelect
                    components={components}
                    noOptionsMessage={() => "Type something and press enter..."}
                    isMulti
                    classNamePrefix="select"
                    placeholder="Type something and press enter..."
                    isDisabled={false}
                    isLoading={false}
                    name="secondary_problem"
                    styles={this.customStyles}
                    value={this.state.data && this.state.data.secondary_problem}
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.secondary_problem = e;
                      } else if (!e) {
                        data.secondary_problem = "";
                      }
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    I would like to achieve the following with you
                  </label>
                  <textarea
                    style={{ padding: "10px", marginBottom: "14px" }}
                    type="text"
                    onChange={(e) => {
                      if (e) {
                        this.setState({ achieve1: e.target.value });
                      }
                    }}
                    className="form-control"
                    value={this.state.achieve1}
                    placeholder="Write here..."
                  ></textarea>
                  <textarea
                    style={{ padding: "10px", marginBottom: "14px" }}
                    type="text"
                    onChange={(e) => {
                      if (e) {
                        this.setState({ achieve2: e.target.value });
                      }
                    }}
                    className="form-control"
                    value={this.state.achieve2}
                    placeholder="Write here..."
                  ></textarea>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      if (e) {
                        this.setState({ achieve3: e.target.value });
                      }
                    }}
                    className="form-control"
                    value={this.state.achieve3}
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Technique I would use (Optional)
                  </label>
                  <Select
                    isMulti
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="lavel_of_impairment"
                    styles={this.customStyles}
                    options={this.props.technique.map((ex) => ({
                      label: ex,
                      value: ex,
                    }))}
                    value={this.state.data.technique}
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e && e.length < 4) {
                        data.technique = e;
                      } else if (!e) {
                        data.technique = "";
                      }
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Medical plan I would like to use (Optional)
                  </label>
                  <Select
                    isMulti
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="lavel_of_impairment"
                    styles={this.customStyles}
                    options={this.props.medicalPlan.map((ex) => ({
                      label: ex,
                      value: ex,
                    }))}
                    value={this.state.data.medical_plan}
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e && e.length < 4) {
                        data.medical_plan = e;
                      } else if (!e) {
                        data.medical_plan = "";
                      }
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Conduct following assessments (Optional)
                  </label>
                  <Select
                    isMulti
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="lavel_of_impairment"
                    styles={this.customStyles}
                    options={this.props.assesment.map((ex) => ({
                      label: ex,
                      value: ex,
                    }))}
                    value={this.state.data.assesment}
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e && e.length < 4) {
                        data.assesment = e;
                      } else if (!e) {
                        data.assesment = "";
                      }
                      this.setState({ data });
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    I advise appointments every
                  </label>
                  <input
                    type="number"
                    class="advise-box"
                    value={
                      this.state.data.appointment_advise &&
                      this.state.data.appointment_advise.days
                    }
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.appointment_advise.days = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    style={{
                      border: this.state.errDays
                        ? "1px solid red"
                        : "1px solid #000",
                    }}
                  />
                  <label style={{ color: "#000" }}>days until</label>
                  <input
                    type="number"
                    class="advise-box"
                    value={
                      this.state.data.appointment_advise &&
                      this.state.data.appointment_advise.session
                    }
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.appointment_advise.session = e.target.value;
                        this.setState({ data, errSession: false });
                      }
                    }}
                    style={{
                      border: this.state.errSession
                        ? "1px solid red"
                        : "1px solid #000",
                    }}
                  />
                  <label style={{ color: "#000" }}>sessions</label>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    I would like to Recommend or advise
                  </label>
                  <textarea
                    style={{ padding: "10px", marginBottom: "14px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.recomendation = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={this.state.data && this.state.data.recomendation}
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="sidebar-footer d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.planForm === true ? "overlay3 show" : "overlay4"
          }
        ></div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadAssesment: () => dispatch(loadAssesment()),
  loadMedicalPlan: () => dispatch(loadMedicalPlan()),
  loadTechnique: () => dispatch(loadTechnique()),
  addAnswerTreatment: (data, callback) =>
    dispatch(addAnswerTreatment(data, callback)),
  loadAnswertreatment: (id) => dispatch(loadAnswertreatment(id)),
});

const mapStateToProps = (state) => ({
  medicalPlan: getPlan(state).medicalPlan,
  technique: getPlan(state).technique,
  assesment: getPlan(state).assesment,
  planAnswer: getPlan(state).planAnswer,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreatmentPlan)
);
