import React, { Component } from "react";
import {
  getDiary,
  loadPatientDiary,
  deleteDiary,
  shareDiary,
} from "../../../store/diary";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import verticalDots from "../../../include/images/vertical-dots.svg";
import shareIcon from "../../../include/images/Icon-share.svg";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
const months = [
  { name: "Month", number: "" },
  { name: "January", number: "01" },
  { name: "February", number: "02" },
  { name: "March", number: "03" },
  { name: "April", number: "04" },
  { name: "May", number: "05" },
  { name: "June", number: "06" },
  { name: "July", number: "07" },
  { name: "August", number: "08" },
  { name: "September", number: "09" },
  { name: "October", number: 10 },
  { name: "November", number: 11 },
  { name: "December", number: 12 },
];
const years = ["", "2022", "2021", "2020", "2019"];

class Diary extends Component {
  state = {
    month: "",
    year: "",
    step: 1,
    addForm: false,
    showMenu: false,
    activeState: 0,
    menuOptios: "",
    forEdit: false,
  };

  componentDidMount() {
    this.props.loadPatientDiary(this.props.id);
    document.body.className = "dashboard";
  }
  // componentDidUpdate = (prevProps, prevState) => {
  //   console.log(
  //     Object.keys(
  //       _.mapValues(_.groupBy(this.props.getDiary.diaries, "date"), (clist) =>
  //         clist.map((car) => _.omit(car, "date"))
  //       )
  //     )
  //   );
  // };
  onChangeMonth = (e) => {
    this.setState({ month: e.target.value });
    if (e.target.value && this.state.year) {
      this.props.loadPatientDiary(this.props.id, {
        date: e.target.value + "-" + this.state.year,
      });
    }
  };

  onChangeYear = (e) => {
    this.setState({ year: e.target.value });
    if (e.target.value && this.state.month) {
      this.props.loadPatientDiary(this.props.id, {
        date: this.state.month + "-" + e.target.value,
      });
    }
  };
  render() {
    const data = _.mapValues(
      _.groupBy(this.props.getDiary.patientDiary, "date"),
      (clist) => clist.map((car) => _.omit(car, "date"))
    );

    const selectedDiary =
      this.props.getDiary.patientDiary.length > 0
        ? this.props.getDiary.patientDiary.find(
            (e) => e.dairy_no == this.state.activeState
          )
          ? this.props.getDiary.patientDiary.find(
              (e) => e.dairy_no == this.state.activeState
            )
          : this.props.getDiary.patientDiary[0]
        : null;
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="diary"
      //   role="tabpanel"
      //   aria-labelledby="diary-tab"
      // >
      //   <div class="card-header">Diary</div>
      //   <div class="content-body add-scrollbar  scrollbar-dynamic">
      <div class="my-diary-box d-lg-flex flex-wrap" style={{ widows: "40%" }}>
        <div class="mdb-sidebar">
          <div class="mdb-sidebar-head  d-lg-flex">
            <div
              class="form-group flex-grow-1"
              style={{ width: "calc(33% - 8px)" }}
            >
              <select class="form-control" onChange={this.onChangeMonth}>
                {months.map((mo) => (
                  <option
                    selected={this.state.month === mo.number}
                    value={mo.number}
                  >
                    {mo.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              class="form-group show flex-grow-1"
              style={{ width: "calc(33% - 8px)" }}
            >
              <select class="form-control" onChange={this.onChangeYear}>
                {years.map((yr) => (
                  <option selected={this.state.year === yr} value={yr}>
                    {yr ? yr : "Year"}
                  </option>
                ))}
              </select>
            </div>

            <div
              class="form-group show flex-grow-1"
              style={{ width: "calc(33% - 8px)", marginTop: "20px" }}
            >
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ month: "", year: "" }, () =>
                    this.props.loadPatientDiary(this.props.id, {})
                  );
                }}
              >
                Clear Filter
              </a>
            </div>
          </div>
          <div class="mdb-sidebar-body">
            {Object.keys(data).map((el, i) => (
              <>
                <h6>
                  {moment().diff(
                    el.split("-")[1] +
                      "/" +
                      el.split("-")[0] +
                      "/" +
                      el.split("-")[2],
                    "days"
                  ) === 0
                    ? "Today"
                    : moment(
                        el.split("-")[1] +
                          "/" +
                          el.split("-")[0] +
                          "/" +
                          el.split("-")[2]
                      ).format("ll")}
                </h6>

                {data[el] &&
                  data[el].map((ed, i) => (
                    <div
                      class="mdb-intro-group"
                      style={
                        this.state.activeState == ed.dairy_no
                          ? { background: "rgb(88 43 184 / 15%)" }
                          : {}
                      }
                    >
                      <div
                        class="mdb-intro-box d-flex flex-wrap"
                        onClick={(e) => {
                          this.setState({
                            activeState: ed.dairy_no,
                            menuOptios: "",
                          });
                        }}
                      >
                        <div class="mdb-intro-text">
                          <h5>Diary No. {ed.dairy_no}</h5>
                          <p>
                            <LinesEllipsis
                              text={ed.situation}
                              maxLine="1"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </p>
                        </div>
                      </div>{" "}
                    </div>
                  ))}
              </>
            ))}
          </div>
        </div>
        {selectedDiary && (
          <div class="mdb-content">
            <div class="mdb-content-head d-lg-flex flex-wrap">
              <div class="mdb-content-left d-flex align-items-center flex-grow-1">
                <h2>Diary No. {selectedDiary.dairy_no}</h2>
                <a href="#!">
                  <img src="include/images/Icon-feather-edit.svg" alt="" />
                </a>
              </div>
              <div class="mdb-content-right flex-grow-1 d-flex align-items-center justify-content-end">
                <b>
                  {moment(selectedDiary.created_on).format(
                    "dddd, MMMM DD,  YYYY"
                  )}
                </b>
              </div>
            </div>

            <div class="mdb-content-body">
              <h5>Situation</h5>
              <p>
                What were you doing? What was happening around? Were there any
                mood changes you noticed? Were you thinking about something?
              </p>
              <div class="mdb-text-box">{selectedDiary.situation}</div>
              <h5>Thought</h5>
              <p>What went through your mind in that situation?</p>
              <div class="mdb-text-box">{selectedDiary.thought}</div>
              <h5>emotional</h5>
              <p>What went through your mind in that situation?</p>
              <div class="mdb-text-box">{selectedDiary.emotion}</div>
              <h5>Alternate Balanced Thought</h5>
              <p>What went through your mind in that situation?</p>
              <div class="mdb-text-box">
                {selectedDiary.alternate_balanced_thought}
              </div>
            </div>
          </div>
        )}
      </div>
      //   </div>
      // </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadPatientDiary: (id, params) => dispatch(loadPatientDiary(id, params)),
  deleteDiary: (diary_no, callback) =>
    dispatch(deleteDiary(diary_no, callback)),
  shareDiary: (diary_no, callback) => dispatch(shareDiary(diary_no, callback)),
});
const mapStateToProps = (state) => ({
  getDiary: getDiary(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Diary));
