import React from "react";
import { Modal } from "react-bootstrap";

function DeletePopup(props) {
  return (
    <>
      <Modal {...props} style={{ padding: "20% 0" }}>
        <div class="modal-body">
          <p>Are you sure you wan to delete this records?</p>
          <div class="modal-coustom-btn d-flex align-items-center justify-content-center">
            <a
              href="javascript:void (0)"
              class="btn btn-primary"
              onClick={() => props.deleted(props.delete_id, props.callback)}
            >
              Yes
            </a>
            <a
              href="javascript:void (0)"
              class="btn btn-outline no-shadow"
              onClick={props.onHide}
            >
              Cancel
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeletePopup;
