import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "chats",
  initialState: {
    chatData: [],
    lastAppointment: {},
    loading: false,
  },
  reducers: {
    chatReceived: (chats, action) => {
      chats.chatData.push(action.payload);
    },
    chatDataRequested: (chats) => {
      chats.chatData = [];
      chats.loading = true;
    },
    chatDataReceived: (chats, action) => {
      chats.loading = false;
      chats.chatData = action.payload.data;
      chats.lastAppointment = action.payload.lastAppointment
        ? action.payload.lastAppointment
        : {};
    },
    chatDataRequestFailed: (chats) => {
      chats.loading = false;
    },
  },
});

export const {
  chatReceived,
  chatDataRequested,
  chatDataReceived,
  chatDataRequestFailed,
} = slice.actions;
export default slice.reducer;

// // Action Creators
const url = "chat/";

export const loadChatForPatient = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "patient/" + id,
      onStart: chatDataRequested.type,
      onSuccess: chatDataReceived.type,
      onError: chatDataRequestFailed.type,
    })
  );
};

export const loadChatForDoctor = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "doctor/" + id,
      onStart: chatDataRequested.type,
      onSuccess: chatDataReceived.type,
      onError: chatDataRequestFailed.type,
    })
  );
};

export const getChats = createSelector(
  (state) => state.entities.chats,
  (chats) => chats
);
