import React, { Component } from "react";
import ReactToPrint from "react-to-print";

import { getPrescription, loadForAppointment } from "../../store/prescription";
import { connect } from "react-redux";
import { jsPDF } from "jspdf";
import PrintPres from "./printPres";

class PrescriptionDownload extends Component {
  handleAfterPrint = () => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  };

  handleBeforePrint = () => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  };

  handleOnBeforeGetContent = () => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    this.setState({ text: "Loading new text...", isLoading: true });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState(
          { text: "New, Updated Text!", isLoading: false },
          resolve
        );
      }, 2000);
    });
  };

  setComponentRef = (ref) => {
    this.componentRef = ref;
  };
  generatePdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.html(document.getElementById("pdfReady"), {
      margin: [20, 300, 20, 0],
      callback: (pdfDoc) => {
        const pageCount = pdfDoc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          pdfDoc.setPage(i);
          const pageSize = pdfDoc.internal.pageSize;
          const pageWidth = pageSize.width
            ? pageSize.width
            : pageSize.getWidth();
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          const header = <h1>This is testing</h1>;
          const footer = ``;

          pdfDoc.html(header);
        }
        pdfDoc.save(
          this.props.forAppointment.patient_id.first_name +
            " " +
            this.props.forAppointment.patient_id.last_name
        );
      },
    });
  };

  callback = (res) => {
    console.log("res", res)
  }

  componentDidMount() {
    this.props.loadForAppointment(this.props.match.params.id);
  }
  render() {
    const { doctor_id, patient_id } = this.props.forAppointment;

    return (
      <>
        {doctor_id && (
          <PrintPres
            forAppointment={this.props.forAppointment}
            ref={(el) => (this.componentRef = el)}
          />
        )}

        <div className="relativeCSS">
          <ReactToPrint
            documentTitle={
              patient_id && patient_id.first_name + " " + patient_id.last_name
            }
            onAfterPrint={this.handleAfterPrint}
            onBeforeGetContent={this.handleOnBeforeGetContent}
            onBeforePrint={this.handleBeforePrint}
            removeAfterPrint
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return (
                <div style={{textAlign:"center", marginTop:0, marginLeft:40,marginBottom:0}}>
                  <button className="btn2 btn-outline no-shadow" style={{textAlign:"center"}} href="#">
                    Print!
                  </button>
                </div>
              );
            }}
            content={() => this.componentRef}
          />
        </div>

        <div style={{textAlign:"center", marginTop:10, marginLeft:40,marginBottom:50}}>
          <button className="btn2 btn-outline no-shadow" style={{textAlign:"center"}} onClick={()=>{
            window.open(process.env.REACT_APP_APIBASE + `appointment/download-pdf/${this.props.match.params.id}`, '_blank', 'noopener,noreferrer');
          }}>download</button>
        </div>

      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadForAppointment: (id) => dispatch(loadForAppointment(id)),
});
const mapStateToProps = (state) => ({
  forAppointment: getPrescription(state).forAppointment,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionDownload);
