import React, { Component } from "react";
import modelCloseIcon from "../../../include/images/modal-close-icon.svg";
import { Modal } from "react-bootstrap";
import mindIcon from "../../../include/images/expert-mind-icon.svg";
import { getMisc, customerQuery } from "../../../store/misc";
import { connect } from "react-redux";

class Thoughts extends Component {
  state = {
    formModel: false,
    formSuccessModel: false,
    data: {
      name: "",
      email: "",
      mobile: "",
      message: "",
    },
  };
  toggleForm = (e) => {
    if (e) e.preventDefault();
    this.setState({ formModel: !this.state.formModel });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const { data } = this.state;
    if (name === "mobile") {
      data[name] = value.replace(/\D/, "");
    } else {
      data[name] = value;
    }
    this.setState({ data });
  };
  toggleFormSuccess = (e) => {
    if (e) e.preventDefault();
    this.setState({ formSuccessModel: !this.state.formSuccessModel });
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    if (e) e.preventDefault();
    const { data } = this.state;
    this.props.customerQuery(data, this.callback);
  };
  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      this.toggleFormSuccess();
      this.toggleForm();
      this.setState({
        data: {
          name: "",
          email: "",
          mobile: "",
          message: "",
        },
      });
    } else {
      this.setState({ formError: true });
    }
  };
  render() {
    const { name, email, mobile } = this.state.data;

    return (
      <>
        <div className="content-container block-intro-container">
          <div className="container container1">
            <div className="row">
              <div className="col-lg-5">
                <div className="intro-text-box">
                  <h3>
                    Scribble
                    <span>Your Thoughts</span>
                  </h3>
                  <p>Do you wanna share it with us?</p>
                  <div className="new-btn-group d-flex align-items-center">
                    <a
                      onClick={this.toggleForm}
                      href="#!"
                      className="btn btn-outline"
                      data-toggle="modal"
                      data-target="#yesbtn"
                    >
                      Yes
                    </a>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          data: {
                            name: "",
                            email: "",
                            mobile: "",
                            message: "",
                          },
                        });
                      }}
                      href="#!"
                      className="btn btn-default"
                      data-toggle="modal"
                      data-target="#nobtn"
                    >
                      No
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 add-blue-shape">
                <div className="intro-textarea-content ml-auto">
                  <textarea
                    onChange={this.handleChange}
                    value={this.state.data.message}
                    name="message"
                    placeholder="I'm looking for..."
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade form-popup show"
          show={this.state.formModel}
          onHide={this.toggleForm}
        >
          <button
            onClick={this.toggleForm}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={modelCloseIcon} alt="" />
          </button>

          <div className="modal-body">
            <div className="form-content d-flex flex-wrap align-items-center justify-content-center">
              <div className="fc-left">
                <h4>We can’t wait to know you.</h4>
              </div>
              <div className="fc-right">
                <h5>Fill your details</h5>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.data.name}
                    required
                  />
                  <label className="label-placeholder" htmlFor="name">
                    Name
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.data.email}
                    required
                  />
                  <label className="label-placeholder" htmlFor="name">
                    Email ID
                  </label>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="mobile"
                    onChange={this.handleChange}
                    value={this.state.data.mobile}
                    required
                  />

                  <label className="label-placeholder" htmlFor="name">
                    Contact no
                  </label>
                </div>
                <div className="form-group mb-0">
                  <button
                    onClick={this.doSubmit}
                    disabled={!name | !email | !mobile | this.state.loading}
                    className={
                      !name | !email | !mobile | this.state.loading
                        ? "btn btn-primary disabled"
                        : "btn btn-primary"
                    }
                  >
                    Share
                  </button>
                  {"   "}
                  {this.state.formError && (
                    <label className="errorLabel">
                      Invalid information, Please fill valid information
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade form-popup show"
          show={this.state.formSuccessModel}
          onHide={this.toggleFormSuccess}
        >
          <button
            onClick={this.toggleFormSuccess}
            type="button"
            className="close"
          >
            <img src={modelCloseIcon} alt="" />
          </button>
          <div className="modal-body d-flex align-items-center justify-content-center text-center">
            <div className="popup-content d-flex  flex-column align-items-center justify-content-center">
              <img src={mindIcon} alt="" />
              <h3>
                Your thoughts have been successfully shared with our experts
              </h3>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  customerQuery: (data, callback) => dispatch(customerQuery(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Thoughts);
