import React from 'react'
import Form from '../../common/form/form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import info from './../../../include/images/white-check-icon.svg'
import { getPatient } from '../../../store/patient'

class PatientWelcome extends Form {
  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
    this.id = setTimeout(() => {
      if (this.props.setInitial) {
        this.props.setInitial()
      }
      this.props.history.push('/patient/dashboard')
    }, 3000)
  }
  componentWillUnmount = () => {
    clearTimeout(this.id)
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }

  render() {
    return (
      <div
        class="welcome-box d-flex align-items-center justify-content-center"
        style={{ minHeight: 'auto' }}
      >
        <div class="wb-content-box d-flex align-items-center text-center flex-column">
          <div class="wb-icon">
            <img src={info} alt="" />
          </div>
          <h3>Welcome to YesMindy</h3>
          {!this.props.patientProfile.Psychiatrist &&
            !this.props.patientProfile.Psychologist && (
              <p>
                Let’s find you a specialist that you are comfortable sharing.
              </p>
            )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
})

export default withRouter(connect(mapStateToProps, null)(PatientWelcome))
