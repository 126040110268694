import React from "react";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Form from "../../common/form/form";
import { loadSeo } from "../../../store/seo";
import AlertError from "../../common/alertError";
import SubmitBtn from "../../common/form/submitBtn";
import { withRouter } from "react-router-dom";
import AlertSuccess from "../../common/alertSuccess";
import {
  loginFailed,
  loginSuccess,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  usernameRequired,
} from "../../common/misc";
import { setDoctorToken } from "../../services/localStorageServices";
import logo from "../../../include/images/yes-mindy-main-logo-white-color.png";
import { staffSignIn } from "../../../store/staff";

class StaffSignIn extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: {
      email: "",
      password: "",
    },
    errors: {},
  };

  componentDidMount = () => {
    this.props.loadSeo("staff-login");
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .required()
      .error(() => {
        return { message: usernameRequired };
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });
      const { data } = this.state;
      this.props.doctorSignIn(data, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      btnClass: "btn btn-lg btn-primary btn-block",
      loading: false,
    });
    if (res && res.status === 200) {
      //return console.log("stlogin", res.data);
      localStorage.setItem("staffProfile", JSON.stringify(res.data.data));
      const { xAuthToken, xRefreshToken } = res.data;
      setDoctorToken(xRefreshToken, xAuthToken);
      //toast(<AlertSuccess message={loginSuccess} />);
      this.props.history.push("/staff/dashboard");
    } else {
      toast(<AlertError message={loginFailed} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <div className="top-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div className="member-box d-flex login-form">
          <div className="member-left">
            <div className="ml-box">
              <h1>YesMindy.</h1>
              <p className="mb-0">Please enter your password to continue</p>
            </div>
          </div>
          <div className="member-right">
            <div className="member-right-content d-md-flex align-items-center">
              <div className="mr-box">
                <div className="mr-head">
                  <h2>Login</h2>
                </div>
                <form action="" onSubmit={this.handleSubmit}>
                  <div className="form-box">
                    {this.renderInput("email", "Email")}
                    {this.renderPasswordInput("password", "Password")}

                    <div className="form-group">
                      <SubmitBtn
                        label="Login"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  doctorSignIn: (param, callback) => dispatch(staffSignIn(param, callback)),
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
});
export default withRouter(connect(null, mapDispatchToProps)(StaffSignIn));
