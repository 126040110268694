import React, { Component, useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import image1 from "../../include/images/specialist3.png";
import { getMisc, loadConstant } from "../../store/misc";
import { connect } from "react-redux";

class ContactUs extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
  };
  componentDidMount = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.props.loadConstant();
  };

  render = () => {
    const { constants } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    return (
      <>
        <Header
          setPatient={this.setPatient}
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div className="outer-container">
          <div className="clinician-hero-container">
            <div className="container container1">
              <div className="row flex-row-reverse align-items-end">
                <div className="col-xl-4 offset-xl-1 col-lg-5">
                  <div className="clinician-hero-content">
                    <h4>Contact Us</h4>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="clinician-hero-img">
                    <img style={{ maxHeight: "225px" }} src={image1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="content-container">
          <div className="container container1">
            <div className="row">
              <div className="col-xl-10 offset-xl-1 text-center">
                <div
                  className="heading headingContact"
                  style={{ fontSize: "25px" }}
                >
                  <h2 style={{ color: "#611dbe", marginBottom: "38px" }}>
                    Registered Company Address
                  </h2>
                  <p>
                    <b style={{ fontWeight: "600" }}>
                      HEALTH AND WELLNESS INNOVATIONS PVT. LTD.
                    </b>
                  </p>
                  <p>A-3/31, Sector 15</p>
                  <p>Rohini, Delhi North west</p>
                  <p>Delhi, India, 110089</p>
                  <p>Email: directors@healthandwellnessinnovations.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
