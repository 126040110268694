import React from "react";

const LoginHere = (props) => {
  return (
    <>
      {props.msg}
      <br />
      Please{" "}
      <a style={{ color: "#6119c0" }} href="/patient/sign-in">
        click here
      </a>{" "}
      to login.
    </>
  );
};

export default LoginHere;
