import React, { Component } from "react";
import {
  birth_early,
  disorder,
  family,
  extracurricular,
  occupation,
  menstrual,
  sexual,
  forensic,
} from "./common";
export default class PersonalHistory extends Component {
  render() {
    return (
      <>
        <h4>Personal History</h4>
        <div class="radio-group-box">
          <h5>Birth & Early development</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {birth_early.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.birth_early.find(
                      (el) => el === e.Early
                    )}
                    id={e.Early}
                    class="form-check-input"
                    name="birth_early"
                    onChange={() => this.props.changeLang12(e.Early)}
                  />
                  <label class="form-check-label" htmlFor={e.Early}>
                    {e.Early}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify1
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify1">Please Specify</label>
                <textarea
                  type="text"
                  id="specify1"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify1}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Childhood Disorders</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {disorder.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.disorder.find(
                      (el) => el === e.Disorder
                    )}
                    id={e.Disorder}
                    class="form-check-input"
                    name="disorder"
                    onChange={() => this.props.changeLang13(e.Disorder)}
                  />
                  <label class="form-check-label" htmlFor={e.Disorder}>
                    {e.Disorder}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify2
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify2">Please Specify if Other</label>
                <textarea
                  type="text"
                  id="specify2"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify2}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Family/ Home atmosphere in childhood or Adolescence</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {family.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.family.find((el) => el === e.Family)}
                    id={e.Family}
                    class="form-check-input"
                    name="family"
                    onChange={() => this.props.changeLang14(e.Family)}
                  />
                  <label class="form-check-label" htmlFor={e.Family}>
                    {e.Family}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify3
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify3">
                  Please Specify Patient’s attitude towards parents
                </label>
                <textarea
                  type="text"
                  id="specify3"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify3}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Scholastic And Extracurricular</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {extracurricular.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.extracurricular.find(
                      (el) => el === e.Extracurricular
                    )}
                    id={e.Extracurricular}
                    class="form-check-input"
                    name="extracurricular"
                    onChange={() => this.props.changeLang15(e.Extracurricular)}
                  />
                  <label class="form-check-label" htmlFor={e.Extracurricular}>
                    {e.Extracurricular}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <h5>Occupation History</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {occupation.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.occupation.find(
                      (el) => el === e.Occupation
                    )}
                    id={e.Occupation}
                    class="form-check-input"
                    name="occupation"
                    onChange={() => this.props.changeLang16(e.Occupation)}
                  />
                  <label class="form-check-label" htmlFor={e.Occupation}>
                    {e.Occupation}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify4
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify4">
                  Please Specify Current Job Details
                </label>
                <textarea
                  type="text"
                  id="specify4"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify4}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Menstrual History</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {menstrual.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.menstrual &&
                      this.props.menstrual.find((el) => el === e.Menstrual)
                    }
                    id={e.Menstrual + "1"}
                    class="form-check-input"
                    name="menstrual"
                    onChange={() => this.props.changeLang17(e.Menstrual)}
                  />
                  <label class="form-check-label" htmlFor={e.Menstrual + "1"}>
                    {e.Menstrual}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <div class="input-group-row">
            <div
              className={
                this.props.LMP
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <label htmlFor="LMP">LMP</label>
              <textarea
                type="text"
                id="LMP"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.LMP}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <div class="input-group-row">
            <div
              className={
                this.props.menarche
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <label htmlFor="menarche">Age of menarche</label>
              <textarea
                type="text"
                id="menarche"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.menarche}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <div class="input-group-row">
            <div
              className={
                this.props.menopause
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <label htmlFor="menopause">Age of menopause</label>
              <textarea
                type="text"
                id="menopause"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.menopause}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Sexual/ Marital History</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {sexual.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.sexual.find((el) => el === e.Sexual)}
                    id={e.Sexual + "1"}
                    class="form-check-input"
                    name="sexual"
                    onChange={() => this.props.changeLang18(e.Sexual)}
                  />
                  <label class="form-check-label" htmlFor={e.Sexual + "1"}>
                    {e.Sexual}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify5
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify5">
                  Please Mention If anything else
                </label>
                <textarea
                  type="text"
                  id="specify5"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify5}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Forensic History</h5>
          <div class="radio-group-row d-flex flex-wrap align-items-center">
            {forensic.map((e) => (
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  id={e.Forensic + "1"}
                  name="forensic"
                  value={e.Forensic}
                  onChange={(e) => this.props.toggleForensic(e)}
                  checked={this.props.forensic === e.Forensic}
                />
                <label class="form-check-label" htmlFor={e.Forensic + "1"}>
                  {e.Forensic}
                </label>
              </div>
            ))}
          </div>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.specify6
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="specify6">
                  Please Mention The Details If Present
                </label>
                <textarea
                  type="text"
                  id="specify6"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.specify6}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pane-seprator"></div>
      </>
    );
  }
}
