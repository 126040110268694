import Joi from "joi-browser";
import moment from "moment";
import React, { Component } from "react";
import Form from "../../common/form/form";
import SelectSearchAsync from "../../common/form/selectSearchAsync";
import SelectSearch from "../../common/form/selectSearch";

import SubmitBtn from "../../common/form/submitBtn";
import { getPatient, patientSearch } from "../../../store/patient";
import Header from "./header";
import LeftBar from "./leftBar";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  createOrderByDoctor,
  getAppointment,
  loadAppointmentDetail,
  loadDate,
  loadSlot,
  rescheduleByDoctor,
} from "../../../store/appointment";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { getDoctor } from "../../../store/doctor";
class RescheduleAppointment extends Form {
  state = {
    loader: false,
    btnClass: "btn btn-primary btn-lg",
    data: {
      date: "",
      time: "",
    },
    errors: {},
  };
  getTimeStops = (start, end) => {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];

    while (startTime <= endTime) {
      timeStops.push({
        name: new moment(startTime).format("hh:mm A"),
        value: new moment(startTime).format("HH:mm"),
      });
      startTime.add(15, "minutes");
    }
    return timeStops;
  };
  schema = {
    date: Joi.object()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = "Date required";
              break;
          }
        });
        return errors;
      }),

    time: Joi.object().error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "object.base":
            err.message = "Time required";
            break;
        }
      });
      return errors;
    }),
  };
  componentDidMount() {
    console.log("history", this.props.history.location.state);
    this.props.loadAppointmentDetail(
      this.props.history.location.state.appointment_id,
      null
    );
    document.body.classList.remove("p-0");
    document.body.classList.add(
      this.props.history.location.pathname !== "/staff/newAppointment"
        ? "dashboard"
        : "dashboard-staff"
    );
    this.props.patientSearch({});
    this.props.loadDate();
  }

  updateData = (data) => {
    this.setState({ data });
    console.log(data);
  };

  loadOptions = (inputValue) => {
    this.props.patientSearch({ search: inputValue });
    console.log(inputValue);
  };
  updateStep = (step) => this.setState({ step });

  onChange = (res) => {
    const patient = res.currentTarget;
    const { data, errors } = this.state;
    data.patient = patient;
    errors.patient = "";
    this.setState({ data, errors });
  };

  onChangeDate = (res) => {
    const date = res.currentTarget;
    console.log(date);
    const { data, errors } = this.state;
    data.date = date;
    errors.date = "";
    this.setState({ data, errors });
    this.props.loadSlot(
      this.props.doctorProfile._id,
      date.value,
      this.props.getAppointment.appointmentDetail.data.appointment_type
    );
  };

  onChangeTime = (res) => {
    const time = res.currentTarget;
    console.log(time);
    const { data, errors } = this.state;
    data.time = time;
    errors.time = "";
    this.setState({ data, errors });
  };

  doSubmit = () => {
    const { date, time } = this.state.data;
    const timeArr = time.value.split(":");
    const dateTime = moment(date.value);

    const payload = {
      appointment_time: dateTime.format(
        "YYYY-MM-DD[T" + timeArr[0] + ":" + timeArr[1] + ":00.000Z]"
      ),
    };

    if (!this.state.loader) {
      this.props.rescheduleAppointment(
        this.props.history.location.state.appointment_id,
        payload,
        this.callBackReschedule
      );
    }
  };

  callBackReschedule = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Successfully rescheduled."} />);
      this.props.history.push(this.props.history.location.state.returnUrl);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    console.log(
      "this.props.history.location.state",
      this.props.getAppointment.appointmentDetail.data.appointment_type
    );
    const times = this.getTimeStops("00:00", "23:45");
    const patients = this.props.patients;
    const options = patients.map((e) => ({
      value: e._id,
      name: e.first_name + e.last_name,
    }));
    console.log("getAppointment", this.state.data);
    return (
      <>
        {this.props.history.location.pathname !== "/staff/newAppointment" && (
          <LeftBar />
        )}
        <Header />
        <div className="dashboard-main-container">
          <div className="dashboard-content-box">
            <h4>Update Appointment</h4>
            <form onSubmit={this.handleSubmit}>
              <div className="dc-body d-flex flex-wrap align-items-lg-center">
                <div className="dc-left">
                  <h4 className="d-block d-xl-none">Personal Details</h4>
                  <div className="dc-form-box">
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <SelectSearch
                            name="date"
                            options={this.props.getAppointment.dates.map(
                              (e) => ({ name: e.date, value: e.date })
                            )}
                            label="Date"
                            error={this.state.errors.date}
                            value={this.state.data.date}
                            onChange={this.onChangeDate}
                          />
                        </div>
                        <div className="col-md-6">
                          <SelectSearch
                            name="time"
                            options={this.props.getAppointment.slots.map(
                              (e) => ({
                                name: e.is_available
                                  ? moment(e.start).utc().format("hh:mm A")
                                  : moment(e.start).utc().format("hh:mm A") +
                                    "-" +
                                    "Not available",
                                value: moment(e.start).utc().format("HH:mm"),
                                isDisabled: !e.is_available,
                              })
                            )}
                            label="Time"
                            error={this.state.errors.time}
                            value={this.state.data.time}
                            onChange={this.onChangeTime}
                          />
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>

              <div className="dc-bottom-box">
                <SubmitBtn
                  label="Save"
                  loading={this.state.loading}
                  btnClass={this.state.btnClass}
                />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  patientSearch: (param) => dispatch(patientSearch(param)),
  loadDate: () => dispatch(loadDate()),
  createOrderByDoctor: (param, callback) =>
    dispatch(createOrderByDoctor(param, callback)),
  loadSlot: (drId, date, type) => dispatch(loadSlot(drId, date, type)),
  rescheduleAppointment: (id, data, callback) =>
    dispatch(rescheduleByDoctor(id, data, callback)),
  loadAppointmentDetail: (id, callback) =>
    dispatch(loadAppointmentDetail(id, callback)),
});
const mapStateToProps = (state) => ({
  patients: getPatient(state).patientList,
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RescheduleAppointment)
);
