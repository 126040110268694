import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../../common/form/form";
import SignUpLeftBar from "../../common/signUpLeftBar";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import loaderIcon from "../../../../include/images/loading-icon.svg";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorToken,
  setDoctorToken,
} from "../../../services/localStorageServices";
import {
  collegeRequired,
  educationRequired,
  yearInvalid,
  yearRequired,
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  yearOfPassingRequired,
  yearOfPassingInvalid,
} from "../../../common/misc";
import SubmitBtn from "../../../common/form/submitBtn";
import HelpText from "../helpText";
class Education extends Form {
  state = {
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      graducation_education: "",
      graducation_college: "",
      graducation_year: "",
      postgraducation_education: "",
      postgraducation_college: "",
      postgraducation_year: "",
      super_speciality_education: "",
      super_speciality_college: "",
      super_speciality_year: "",
    },
    errors: {
      graducation_education: "",
      graducation_college: "",
      graducation_year: "",
      postgraducation_education: "",
      postgraducation_college: "",
      postgraducation_year: "",
      super_speciality_education: "",
      super_speciality_college: "",
      super_speciality_year: "",
    },
  };

  schema = {
    graducation_education: Joi.string()
      .required()
      .label("Graducation")
      .error(() => {
        return { message: educationRequired };
      }),
    graducation_college: Joi.string()
      .required()
      .label("Signature")
      .error(() => {
        return { message: collegeRequired };
      }),
    graducation_year: Joi.number()
      .required()
      .min(1900)
      .max(parseInt(new Date().getFullYear()))
      .error((errors) => {
        errors.forEach((err) => {
          console.log("err.type", err);
          switch (err.type) {
            case "number.base":
              err.message = yearOfPassingRequired;
              break;
            case "number.max":
              err.message = yearOfPassingInvalid;
              break;
            case "number.min":
              err.message = yearOfPassingInvalid;
              break;
          }
        });
        return errors;
      }),

    postgraducation_education: Joi.optional().error(() => {
      return { message: educationRequired };
    }),
    postgraducation_college: Joi.optional().error(() => {
      return { message: collegeRequired };
    }),
    postgraducation_year: Joi.number()
      .allow("")
      .min(1900)
      .max(parseInt(new Date().getFullYear()))
      .error((errors) => {
        errors.forEach((err) => {
          console.log("err.type", err);
          switch (err.type) {
            case "number.base":
              err.message = yearOfPassingRequired;
              break;
            case "number.max":
              err.message = yearOfPassingInvalid;
              break;
            case "number.min":
              err.message = yearOfPassingInvalid;
              break;
          }
        });
        return errors;
      }),

    super_speciality_education: Joi.optional().error(() => {
      return { message: educationRequired };
    }),
    super_speciality_college: Joi.optional().error(() => {
      return { message: collegeRequired };
    }),
    super_speciality_year: Joi.number()
      .allow("")
      .min(1900)
      .max(parseInt(new Date().getFullYear()))
      .error((errors) => {
        errors.forEach((err) => {
          console.log("err.type", err);
          switch (err.type) {
            case "number.base":
              err.message = yearOfPassingRequired;
              break;
            case "number.max":
              err.message = yearOfPassingInvalid;
              break;
            case "number.min":
              err.message = yearOfPassingInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    const {
      graducation_education,
      graducation_college,
      graducation_year,
      postgraducation_education,
      postgraducation_college,
      postgraducation_year,
      super_speciality_education,
      super_speciality_college,
      super_speciality_year,
    } = this.state.data;
    const data = {
      graduation: {
        education: graducation_education,
        year_of_passing: graducation_year,
        college_university: graducation_college,
      },
    };

    if (postgraducation_education) {
      data.post_graduation = {
        education: postgraducation_education,
        year_of_passing: postgraducation_year,
        college_university: postgraducation_college,
      };
    } else {
      data.post_graduation = {};
    }

    if (super_speciality_education) {
      data.super_speciality = {
        education: super_speciality_education,
        year_of_passing: super_speciality_year,
        college_university: super_speciality_college,
      };
    } else {
      data.super_speciality = {};
    }
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(data, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/educational-detail/experience");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const data = {
        graducation_education: "",
        graducation_college: "",
        graducation_year: "",
        postgraducation_education: "",
        postgraducation_college: "",
        postgraducation_year: "",
        super_speciality_education: "",
        super_speciality_college: "",
        super_speciality_year: "",
      };
      if (this.props.doctorProfile.graduation) {
        data.graducation_education =
          this.props.doctorProfile.graduation.education;
        data.graducation_year =
          this.props.doctorProfile.graduation.year_of_passing;
        data.graducation_college =
          this.props.doctorProfile.graduation.college_university;
      }
      if (this.props.doctorProfile.post_graduation) {
        data.postgraducation_education =
          this.props.doctorProfile.post_graduation.education;
        data.postgraducation_year =
          this.props.doctorProfile.post_graduation.year_of_passing;
        data.postgraducation_college =
          this.props.doctorProfile.post_graduation.college_university;
      }
      if (this.props.doctorProfile.super_speciality) {
        data.super_speciality_education =
          this.props.doctorProfile.super_speciality.education;
        data.super_speciality_year =
          this.props.doctorProfile.super_speciality.year_of_passing;
        data.super_speciality_college =
          this.props.doctorProfile.super_speciality.college_university;
      }
      this.setState({ data });
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    console.log("testtest", this.props.doctorProfile);
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />

          <div className="container ml-0">
            <div className="main-heading">
              <h1>Educations </h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "graducation_education",
                      "Graduation - Mention Degree Only, e.g : MBBS/BA etc."
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "graducation_college",
                      "College/University name"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput("graducation_year", "Year of passing")}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "postgraducation_education",
                      "Post Graduation - Mention Degree Only, e.g : MD/MA etc."
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "postgraducation_college",
                      "College/University name"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "postgraducation_year",
                      "Year of passing"
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "super_speciality_education",
                      "Superspeciality/Fellowship - Mention Degree with Field."
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "super_speciality_college",
                      "College/University name"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput(
                      "super_speciality_year",
                      "Year of passing"
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Education);
