import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getDoctor, loadDoctorProfile } from "../../../store/doctor";
import { clearAllToken } from "../../services/localStorageServices";
import { Modal } from "react-bootstrap";
import circle from "../../../include/images/circle-check-icon.svg";
import { getMisc, loadConstant } from "../../../store/misc";
import communicationIcon from "../../../include/images/communication-center-icon1.svg";

class HelpText extends Component {
  state = {
    isShow1: false,
    formSuccessModel: false,
  };
  logout = (e) => {
    e.preventDefault();
    clearAllToken();
    this.props.history.push("/doctor/sign-in");
  };
  toggleFormSuccess = () => {
    this.setState({
      formSuccessModel: !this.state.formSuccessModel,
      isShow1: false,
    });
    document.body.addclassName = " modal-open";
  };

  chat = (e) => {
    e.preventDefault();
    localStorage.setItem("counselor_id", this.props.doctorProfile._id);
    localStorage.setItem("type", "counselor");
    localStorage.setItem(
      "counselorData",
      JSON.stringify(this.props.doctorProfile)
    );
    window.open("/counsellor/chat", "_blank");
  };
  componentDidMount = () => {
    this.props.loadConstant();
  };
  render() {
    const { field_type, is_approved } = this.props.doctorProfile;
    const { constants } = this.props.getMisc;
    const { helpline_no, header_email_address } = constants;
    return (
      <>
        {field_type && field_type.name === "Counsellor" ? (
          <div className="main-top">
            <a onClick={this.chat} href="">
              Chat with Patients
            </a>{" "}
            | Having trouble?{" "}
            <a href="javascript:void(0)" onClick={this.toggleFormSuccess}>
              Get help
            </a>{" "}
            |{" "}
            <a href="#" onClick={this.logout}>
              Logout
            </a>
          </div>
        ) : (
          <div className="main-top">
            Having trouble?{" "}
            <a href="javascript:void(0)" onClick={this.toggleFormSuccess}>
              Get help
            </a>{" "}
            |{" "}
            {is_approved && (
              <>
                <Link to="/doctor/dashboard">Dashboard</Link> |{" "}
              </>
            )}
            <a href="#" onClick={this.logout}>
              Logout
            </a>
          </div>
        )}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade personal-details-modal show"
          show={this.state.formSuccessModel}
          onHide={this.toggleFormSuccess}
        >
          <div class="text-center">
            <div class="modal-body">
              <div class="pdm-icon">
                <img src={communicationIcon} alt="" />
              </div>
              <h5></h5>
              <p>Helpline Number: {helpline_no}</p>
              <p>Helpdesk Email: {header_email_address}</p>
              <a
                href="javascript:void(0)"
                onClick={this.toggleFormSuccess}
                class="btn btn-default no-shadow"
              >
                CLOSE
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorProfile: getDoctor(state).profile,
  getMisc: getMisc(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HelpText)
);
