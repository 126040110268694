import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LoadingBar from 'react-top-loading-bar'
import { loadFieldType } from '../../../store/misc'
import {
  getPatient,
  loadPatientProfile,
  setInitialData,
} from '../../../store/patient'
import PatientDashboard from '../profile/patientDashboard'
import PersonalDetails from '../profile/personalDetails'
import PostAppointment from './postAppointment'

const ref = createRef(null)

class MainDashboard extends Component {
  state = { loading: true }

  componentDidMount = () => {
    this.setState({ loading: true })
    ref.current.continuousStart()
    this.props.loadPatientProfile((res) => {
      if (res.status == 401) {
        this.setState({ loading: true })
      } else {
        const patData = res.data.data
        if (
          (patData.nominated_representative_name && !patData.self) ||
          patData.self
        ) {
          this.props.setInitialData(false)
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
        }
      }
    })
    this.props.loadFieldType({ filter: true })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      ref.current.complete()
      this.setState({ loading: false })
    }
  }

  render() {
    console.log('this.props dashboard initial', this.props.initialData)
    return (
      <>
        <LoadingBar color="#582bb8" ref={ref} />
        {!this.state.loading && (
          <>
            {(!this.props.patientProfile.nominated_representative_name &&
              !this.props.patientProfile.self) ||
            (!this.props.patientProfile.self && this.props.initialData) ||
            this.props.initialData ? (
              <PersonalDetails
                setInitial={() => {
                  this.props.setInitialData(false)
                }}
                initial={this.props.initialData}
              />
            ) : null}
            {((this.props.patientProfile.nominated_representative_name &&
              !this.props.patientProfile.self) ||
              this.props.patientProfile.self) &&
              !this.props.initialData &&
              (this.props.patientProfile.Psychiatrist ||
                this.props.patientProfile.Psychologist) && <PostAppointment />}
            {((this.props.patientProfile.nominated_representative_name &&
              !this.props.patientProfile.self) ||
              this.props.patientProfile.self) &&
              !this.props.initialData &&
              !this.props.patientProfile.Psychiatrist &&
              !this.props.patientProfile.Psychologist && <PatientDashboard />}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  loading: getPatient(state).loading,
  initialData: getPatient(state).initial,
})
const mapDispatchToProps = (dispatch) => ({
  loadPatientProfile: (callback) => dispatch(loadPatientProfile(callback)),
  loadFieldType: (param) => dispatch(loadFieldType(param)),
  setInitialData: (param) => dispatch(setInitialData(param)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainDashboard),
)
