export const setToken = (refresh, auth) => {
  localStorage.setItem("x-refresh-token", refresh);
  localStorage.setItem("x-auth-token", auth);
};

export const getToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token");
  if (type === "auth") return localStorage.getItem("x-auth-token");
  return false;
};

export const setDoctorToken = (refresh, auth) => {
  localStorage.setItem("x-refresh-token-doctor", refresh);
  localStorage.setItem("x-auth-token-doctor", auth);
};

export const getDoctorToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token-doctor");
  if (type === "auth") return localStorage.getItem("x-auth-token-doctor");
  return false;
};

export const clearAllToken = () => {
  localStorage.clear();
};

export const setProfile = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};
