import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import _ from "lodash";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorToken,
  setDoctorToken,
} from "../../services/localStorageServices";
import {
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  awardRequired,
  yearRequired,
} from "../../common/misc";
import { Link } from "react-router-dom";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";
class Associations extends Form {
  state = {
    inputs: [1],
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      type1: "Hospital",
      value1: "",
    },
    errors: {},
  };

  schema = {
    type1: Joi.string()
      .required()
      .error(() => {
        return { message: "Field is required" };
      }),
    value1: Joi.string()
      .required()
      .error(() => {
        return { message: "Field is required" };
      }),
  };

  doSubmit = () => {
    const payload = [];
    this.state.inputs.forEach((element) => {
      const obj = {
        associationstype: this.state.data["type" + element],
        associationsvalue: this.state.data["value" + element],
      };
      payload.push(obj);
    });
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate({ associations: payload }, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/practice-preferences");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  addMore = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const NewNumber = inputs.length + 1;
    inputs.push(NewNumber);
    this.setState({ inputs });
    const { data } = this.state;

    const type = "type" + NewNumber;
    const value = "value" + NewNumber;

    const newData = {};
    //const newArr = {};

    newData[type] = "Hospital";

    this.schema[type] = Joi.string()
      .required()
      .error(() => {
        return { message: "Field is required" };
      });

    this.schema[value] = Joi.string()
      .required()
      .error(() => {
        return { message: "Field is required" };
      });
    this.setState({
      data: { ...data, ...newData },
    });
  };

  removeLast = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const removeNumber = inputs.length;
    inputs.pop();
    this.setState({ inputs });
    const newData = {};
    const newArr = {};
    const type = "type" + removeNumber;
    const value = "value" + removeNumber;

    const { data } = this.state;
    delete data[type];
    delete data[value];

    delete this.schema[type];
    delete this.schema[value];

    this.setState({ data, errors: {} });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const inputs = _.range(
        1,
        this.props.doctorProfile.associations.length + 1
      );
      const newData = {};
      if (inputs.length > 1) {
        inputs.forEach((element, i) => {
          newData["type" + element] = this.props.doctorProfile.associations[
            i
          ].associationstype;
          newData["value" + element] = this.props.doctorProfile.associations[
            i
          ].associationsvalue;

          this.schema["type" + element] = Joi.string()
            .required()
            .error(() => {
              return { message: "Field is required" };
            });
          this.schema["value" + element] = Joi.string()
            .required()
            .error(() => {
              return { message: "Field is required" };
            });
        });

        this.setState({ inputs, data: newData });
      }

      if (inputs.length === 1) {
        const data = {
          type1: this.props.doctorProfile.associations[0].associationstype,
          value1: this.props.doctorProfile.associations[0].associationsvalue,
        };

        this.setState({ inputs, data });
      }
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Associations</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                {this.state.inputs.map((element, i) => (
                  <React.Fragment key={element}>
                    <div className="row">
                      <div className="col-xl-3">
                        {this.renderSelect(
                          "type" + element,
                          "Hospital/Organisation",
                          [
                            { _id: "Hospital", name: "Hospital" },
                            { _id: "Organisation", name: "Organisation" },
                          ]
                        )}
                      </div>
                      <div className="col-xl-6">
                        {this.renderInput(
                          "value" + element,
                          this.state.data["type" + element]
                        )}
                      </div>

                      {this.state.inputs.length > 1 &&
                        this.state.inputs.length - 1 === i && (
                          <div className="col-xl-2">
                            <a
                              href=""
                              className="add-more-field m-0 errorLabel"
                              onClick={this.removeLast}
                            >
                              - Remove
                            </a>
                          </div>
                        )}
                    </div>
                  </React.Fragment>
                ))}
                <div className="row">
                  <div className="col-xl-5">
                    <a
                      href="#"
                      onClick={this.addMore}
                      className="add-more-field mb-4"
                    >
                      + Add more
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <div className="form-group">
                      <Link
                        className="add-more-field mb-4 p10-top"
                        to="/doctor/sign-up/practice-preferences"
                      >
                        Skip and fill later
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Associations);
