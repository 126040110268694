import React from "react";

import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { checkMessageForExtensionsData } from "../../util/common";

import ToolTip from "../ToolTip";
import ReplyCount from "../ReplyCount";
import ReadReciept from "../ReadReciept";
import Avatar from "../Avatar";
import { SvgAvatar } from "../../util/svgavatar";
import RegularReactionView from "../RegularReactionView";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
  messageTxtContainerStyle,
  messageTxtWrapperStyle,
  messageTxtTitleStyle,
  messageTxtStyle,
  messageBtnStyle,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle,
} from "./style";

import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";

import whiteboardIcon from "./resources/receiverwhiteboard.png";

class ReceiverWhiteboardBubble extends React.PureComponent {
  messageFrom = "receiver";

  constructor(props) {
    super(props);

    const message = Object.assign({}, props.message, {
      messageFrom: this.messageFrom,
    });
    if (message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
      if (!message.sender.avatar) {
        const uid = message.sender.getUid();
        const char = message.sender.getName().charAt(0).toUpperCase();

        message.sender.setAvatar(SvgAvatar.getAvatar(uid, char));
      }
    }

    this.state = {
      message: message,
      isHovering: false,
    };
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.message);
    const currentMessageStr = JSON.stringify(this.props.message);

    if (previousMessageStr !== currentMessageStr) {
      const message = Object.assign({}, this.props.message, {
        messageFrom: this.messageFrom,
      });
      this.setState({ message: message });
    }
  }

  launchCollaborativeWhiteboard = () => {
    let whiteboardUrl = null;
    let whiteboardData = checkMessageForExtensionsData(
      this.state.message,
      "whiteboard"
    );
    if (
      whiteboardData &&
      whiteboardData.hasOwnProperty("board_url") &&
      whiteboardData.board_url.length
    ) {
      let username = this.props.loggedInUser.name.split(" ").join("_");
      // Appending the username to the board_url
      whiteboardUrl = whiteboardData.board_url + "&username=" + username;
      window.open(whiteboardUrl, "", "fullscreen=yes, scrollbars=auto");
    }
  };

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  render() {
    let avatar = null,
      name = null;
    if (this.state.message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
      avatar = (
        <div style={messageThumbnailStyle} className="message__thumbnail">
          <Avatar
            borderColor={this.props.theme.borderColor.primary}
            image={this.state.message.sender.avatar}
          />
        </div>
      );

      name = (
        <div
          style={nameWrapperStyle(avatar)}
          className="message__name__wrapper"
        >
          <span style={nameStyle(this.props)} className="message__name">
            {this.props.message.sender.name}
          </span>
        </div>
      );
    }

    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.state.message,
      "reactions"
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            style={messageReactionsWrapperStyle()}
            className="message__reaction__wrapper"
          >
            <RegularReactionView
              {...this.props}
              message={this.state.message}
              reaction={reactionsData}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = (
        <ToolTip {...this.props} message={this.state.message} name={name} />
      );
    }

    const documentTitle = `${
      this.state.message.sender.name
    } ${Translator.translate(
      "SHARED_COLLABORATIVE_WHITEBOARD",
      this.props.lang
    )}`;

    return (
      <div
        style={messageContainerStyle()}
        className="receiver__message__container message__whiteboard"
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
      >
        <div style={messageWrapperStyle()} className="message__wrapper">
          {avatar}
          <div style={messageDetailStyle()} className="message__details">
            {name}
            {toolTipView}
            <div
              style={messageTxtContainerStyle()}
              className="message__whiteboard__container"
            >
              <div
                style={messageTxtWrapperStyle(this.props)}
                className="message__whiteboard__wrapper"
              >
                <div
                  style={messageTxtTitleStyle(this.props)}
                  className="message__whiteboard__title"
                >
                  <img
                    src={whiteboardIcon}
                    alt={Translator.translate(
                      "COLLABORATIVE_WHITEBOARD",
                      this.props.lang
                    )}
                  />
                  <p style={messageTxtStyle()} className="whiteboard__title">
                    {documentTitle}
                  </p>
                </div>

                <ul
                  style={messageBtnStyle(this.props)}
                  className="whiteboard__button"
                >
                  <li onClick={this.launchCollaborativeWhiteboard}>
                    <p>{Translator.translate("JOIN", this.props.lang)}</p>
                  </li>
                </ul>
              </div>
            </div>

            {messageReactions}

            <div
              style={messageInfoWrapperStyle()}
              className="message__info__wrapper"
            >
              <ReadReciept {...this.props} message={this.state.message} />
              <ReplyCount {...this.props} message={this.state.message} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
ReceiverWhiteboardBubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

ReceiverWhiteboardBubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default ReceiverWhiteboardBubble;
