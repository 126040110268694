import React, { useState, useEffect } from 'react';
import moment from "moment";

const Timer = () => {
    const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentTime( moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
        {currentTime.format("h:mm a")}
    </>
  );
};

export default Timer;