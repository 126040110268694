import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  getAppointment,
  loadAllAppointmentForDoctor,
} from "../../../store/appointment";
import AppointmentList from "./appointmentList";
import { getDoctor } from "../../../store/doctor";
import { loadChatForDoctor } from "../../../store/chats";

class BookedAppointment extends Component {
  state = {
    content: false,
    id: "",
    page: 1,
  };
  componentDidMount() {
    document.body.className = "dashboard";
    this.props.loadAllAppointmentForDoctor({
      page: this.state.page,
      booked: true,
    });
  }
  loadFunc = () => {
    if (
      Math.ceil(
        this.props.doctorAppointment.pagination.filtered_record /
          this.props.doctorAppointment.pagination.record_per_page
      ) > this.state.page
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.props.loadAllAppointmentForDoctor({
          page: this.state.page,
        })
      );
    }
  };
  toggleContent = (id) => {
    this.setState({
      content: !this.state.content,
      id: id,
    });
  };

  UpdateStar = (id) => {
    this.props.updateStar(id, this.callback);
  };
  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadAllAppointmentForDoctor({
        page: this.state.page,
      });
    }
  };

  render() {
    const { doctorAppointment, loading } = this.props;
    const { data, pagination } = doctorAppointment;
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="allAppointments"
      //   role="tabpanel"
      //   aria-labelledby="allAppointments-tab"
      // >
      //   <div class="card-header">Booked Appointments</div>
      //   <div class="content-body show add-scrollbar  scrollbar-dynamic">
      <div class="appointment-history-box">
        {/* <div class="seprator-text-row">
              <h4>Today</h4>
            </div> */}
        <ul class="new-profile-list">
          <AppointmentList
            loadChatForDoctor={this.props.loadChatForDoctor}
            doctorProfile={this.props.doctorProfile}
            states={this.state}
            doctorAppointment={data}
            pagination={pagination}
            loadFunc={this.loadFunc}
            loading={loading}
          />
        </ul>
      </div>
      //   </div>
      // </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadAllAppointmentForDoctor: (params) =>
    dispatch(loadAllAppointmentForDoctor(params)),
  loadChatForDoctor: (id) => dispatch(loadChatForDoctor(id)),
});
const mapStateToProps = (state) => ({
  doctorAppointment: getAppointment(state).doctorAppointment,
  loading: getAppointment(state).loadingDoctorAppointment,
  doctorProfile: getDoctor(state).profile,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookedAppointment)
);
