import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "react-bootstrap";
import { getMisc, loadConstant } from "../../store/misc";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import { getSeo, loadTermTemplate } from "../../store/seo";

class PrivacyPolicy extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
  };
  componentDidMount = () => {
    this.props.loadTermTemplate("PrivacyPolicy");
    this.props.loadConstant();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    const { constants, blogCategory, blog, loadingBlogCategory } =
      this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const { termTemplate } = this.props.getSeo;
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="content-container blog-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="
                  blog-content-box
                  d-xl-flex
                  align-items-xl-start
                  flex-xl-wrap
                "
                  >
                    <div>
                      <div class="heading-with-line grey overflow-hidden">
                        <h3>
                          <span>Privacy Policy</span>
                        </h3>
                      </div>
                      <div class="blog-box">
                        <div class="blog-left-upper d-flex flex-wrap">
                          <div class="blog-left-pannel">
                            {/* <h3>
                              Social media, internet use and suicide attempts in
                              adolescents
                            </h3> */}
                            <p>{ReactHtmlParser(termTemplate.html_data)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade form-popup video-popup show"
          show={this.state.modelShowVideo}
          onHide={this.toggleVideo}
        >
          <div className="modal-body">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/eEzD-Y97ges"
                id="video"
                allowscriptaccess="always"
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  getSeo: getSeo(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadTermTemplate: (key) => dispatch(loadTermTemplate(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
