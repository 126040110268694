import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import avtarImg from '../../../include/images/avatar1.png'
import bluestar from '../../../include/images/blue-star-icon.svg'
import calculatorimg from '../../../include/images/calculatorimg.svg'
import material from '../../../include/images/material-note.svg'
import match from '../../../include/images/icon-matchat.svg'
import dark from '../../../include/images/doument-icon-dark.svg'
import Moment from 'moment'
import _ from 'lodash'
import Prescription from '../../patient/dashboard/timeline/prescription'
import ReactTooltip from 'react-tooltip'
import NotesThought from './notesThought'
import Messages from './messages'
import AvtarImage from '../../common/avtarImage'
import NoRecord from '../../common/noRecord'

export default class AppointmentList extends Component {
  state = {
    isShow3: false,
    appointment_id: '',
    patientId: '',
    doctorId: '',
    patientType: '',
    doc_name: '',
    isShow2: false,
    isShow: false,
    image: '',
    field: '',
  }
  toggle2 = (patient_id, id, name, image, field) => {
    this.props.loadChatForDoctor(patient_id)
    this.setState({
      isShow2: !this.state.isShow2,
      patientId: patient_id,
      doctorId: id,
      doc_name: name,
      image: image,
      field: field,
    })
  }

  toggle = (id, patientId, doctorId, patientType) => {
    this.setState({
      isShow: !this.state.isShow,
      appointment_id: id,
      patientId: patientId,
      doctorId: doctorId,
      patientType: patientType,
    })
  }

  toggle3 = appointment_id => {
    this.setState({
      isShow3: !this.state.isShow3,
      appointment_id: appointment_id,
    })
  }
  render() {
    return (
      <div
        style={{ height: '700px', overflow: 'auto' }}
        ref={ref => (this.scrollParentRef = ref)}>
        {this.props.doctorAppointment &&
        this.props.doctorAppointment.length > 0 ? (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={this.props.loadFunc}
            hasMore={
              !this.props.loading &&
              this.props.pagination &&
              this.props.pagination.filtered_record !=
                this.props.pagination.total_record_on_current_page
            }
            loader={
              <div className='loader' key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}>
            {this.props.doctorAppointment &&
              this.props.doctorAppointment.map((e, i) => (
                <li class='np-item'>
                  <ReactTooltip className='testPurple' html={true} />
                  <div class='np-box d-md-flex align-items-center justify-content-between'>
                    <div class='np-left d-flex flex-wrap align-items-center'>
                      <div class='np-img-box'>
                        {e.patient_id && e.patient_id.thumb ? (
                          <img
                            src={
                              process.env.REACT_APP_S3URL + e.patient_id.thumb
                            }
                            alt=''
                          />
                        ) : (
                          <div class='person-img'>
                            <AvtarImage />
                          </div>
                        )}
                      </div>

                      <div class='np-text-box d-md-flex '>
                        <div class='nptb-left'>
                          <h5>
                            {e.patient_id &&
                              e.patient_id.first_name +
                                ' ' +
                                e.patient_id.last_name}
                            <i>
                              {e.patient_id.star.length > 0 &&
                                e.patient_id.star.find(
                                  e => e === this.props.doctorProfile._id
                                ) && (
                                  <i>
                                    <img src={bluestar} alt='star-icon' />
                                  </i>
                                )}
                            </i>
                          </h5>
                          <p>{e.patient_id.yesmindy_identification_number}</p>
                        </div>
                        <div class='nptb-right'>
                          <div class='patient-btn d-flex align-items-center justify-content-center'>
                            {e.appointment_type === 'online' ? (
                              <span class='patient-tags ml-0 light-green'>
                                Online
                              </span>
                            ) : (
                              <span class='patient-tags light-grey'>
                                {_.capitalize(e.appointment_type)}
                              </span>
                            )}
                            <span
                              class={
                                e.followup
                                  ? 'patient-tags '
                                  : 'patient-tags yellow-tag'
                              }>
                              {e.followup ? 'Follow Up' : 'New Patient'}
                            </span>
                            <a class='profile-box'>
                              {e.patient_id.type === 1 ? (
                                <i class='fas fa-user' aria-hidden='true'></i>
                              ) : (
                                <i class='fas fa-child' aria-hidden='true'></i>
                              )}
                            </a>
                          </div>
                          <strong>
                            {Moment(e.appointment_time).utc().format('lll')}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div class='np-right d-flex flex-wrap justify-content-end'>
                      <a
                        href='#'
                        class='np-links'
                        data-toggle='calculator'
                        data-tip='Prescription'
                        aria-hidden='true'
                        onClick={() => this.toggle3(e._id)}>
                        <img src={calculatorimg} alt='' />
                      </a>
                      <a
                        href='#'
                        class='np-links'
                        data-toggle='notes'
                        data-tip='Notes & Thoughts'
                        aria-hidden='true'
                        onClick={ev => {
                          ev.preventDefault()
                          this.toggle(
                            e._id,
                            e.patient_id._id,
                            e.doctor_id._id,
                            e.patient_id.type
                          )
                        }}>
                        <img src={material} alt='' />
                      </a>
                      <a
                        href='#'
                        class='np-links'
                        data-toggle='matchat'
                        data-tip='Message'
                        aria-hidden='true'
                        onClick={ev => {
                          ev.preventDefault()
                          this.toggle2(
                            e.patient_id._id,
                            e.doctor_id._id,
                            e.patient_id.first_name +
                              ' ' +
                              e.patient_id.last_name,
                            e.patient_id.thumb,
                            e.doctor_id.field_type.name
                          )
                        }}>
                        <img src={match} alt='' />
                      </a>
                    </div>
                  </div>
                </li>
              ))}
          </InfiniteScroll>
        ) : (
          <NoRecord
            noLogo={true}
            header='No Appointment Data To Show Here'
            text='We are here always for your assistance. Do let us know if in case you need any help.'
          />
        )}
        <Prescription
          isShow={this.state.isShow3}
          toggle={this.toggle3}
          appointment_id={this.state.appointment_id}
        />
        <NotesThought
          isShow={this.state.isShow}
          toggle={this.toggle}
          appoint_id={this.state.appointment_id}
          patientId={this.state.patientId}
          doctorId={this.state.doctorId}
          patientType={this.state.patientType}
        />
        <Messages
          isShow={this.state.isShow2}
          toggle={this.toggle2}
          doctor_id={this.state.doctorId}
          patient_id={this.state.patientId}
          doc_name={this.state.doc_name}
          image={this.state.image}
          field={this.state.field}
        />
      </div>
    )
  }
}
