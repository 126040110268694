import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'doctor',
  initialState: {
    profile: {},
    featured: [],
    offSchedules: [],
    doctors: {
      data: [],
      pagination: {
        current_page: 1,
        total_page: 0,
        total_record: 0,
        total_record_on_current_page: 0,
        filtered_record: 0,
        record_per_page: 0,
      },
    },
    offSchedulesLoading: [],
    profileLoading: false,
    profileLastFetch: null,
    featuredLastFetch: null,
    featuredloading: false,
    doctorLoading: true,
    doctorLastFetch: null,
  },
  reducers: {
    offSchedulesRequested: (doctor, action) => {
      doctor.offSchedulesLoading = true
    },
    offSchedulesReceived: (doctor, action) => {
      doctor.offSchedules = action.payload.data
      doctor.offSchedulesLoading = false
    },

    profileRequested: (doctor, action) => {
      doctor.profileLoading = true
    },
    profileReceived: (doctor, action) => {
      doctor.profile = action.payload.data
      doctor.profileLoading = false
      doctor.profileLastFetch = Date.now()
    },
    profileRequestFailed: (doctor, action) => {
      doctor.profileLoading = false
      doctor.profile = {}
    },
    featuredRequested: (doctor, action) => {
      doctor.featuredloading = true
    },
    featuredReceived: (doctor, action) => {
      doctor.featured = action.payload.data
      doctor.featuredloading = false
      doctor.featuredLastFetch = Date.now()
    },
    featuredRequestFailed: (doctor, action) => {
      doctor.featuredloading = false
      doctor.featured = []
    },
    doctorRequested: (doctor, action) => {
      doctor.doctorLoading = true
    },
    doctorReceived: (doctor, action) => {
      doctor.doctors = action.payload
      doctor.doctorLoading = false
      doctor.doctorLastFetch = Date.now()
    },
    doctorRequestFailed: (doctor, action) => {
      doctor.doctorLoading = false
    },
  },
})

export const {
  profileRequested,
  profileReceived,
  profileRequestFailed,
  doctorReceived,
  doctorRequestFailed,
  doctorRequested,
  featuredRequested,
  featuredReceived,
  featuredRequestFailed,
  offSchedulesRequested,
  offSchedulesReceived,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'doctor/'

export const emailAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'emailAvailability',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const mobileAvailability = (data, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'mobileAvailability',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const offSchedule = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'off_schedule',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const offScheduleDeactivate = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'off_scheduleDeactivate/' + data,
      method: 'POST',
      callback,
    })
  )
}

export const loadoffSchedule = () => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'off_schedule',
      onStart: offSchedulesRequested.type,
      onSuccess: offSchedulesReceived.type,
    })
  )
}

export const doctorSignUp = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signUp',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const doctorSignIn = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'signIn',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const counselorSignIn = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: 'counselor/signIn',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const doctorUpdate = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'me',
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const loadDoctorProfileByUsername = username => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'profile/' + username,
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  )
}

export const loadDoctorProfile = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'me',
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  )
}
export const loadDoctorFeatured = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'featured/' + params,
      onStart: featuredRequested.type,
      onSuccess: featuredReceived.type,
      onError: featuredRequestFailed.type,
    })
  )
}
export const resetPassword = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'resetPassword',
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const loadDoctors = data => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'list',
      method: 'POST',
      data,
      onStart: doctorRequested.type,
      onSuccess: doctorReceived.type,
      onError: doctorRequestFailed.type,
    })
  )
}
export const getDoctor = createSelector(
  state => state.entities.doctor,
  doctor => doctor
)
