import React, { Component } from 'react'
import close from '../../../include/images/icon-close.svg'
import down from '../../../include/images/down-icon-1.svg'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  addAnswerAssessment,
  loadAllAssessment,
} from '../../../store/assessment'
import { connect } from 'react-redux'
import { getPatient } from '../../../store/patient'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  getAppointment,
  loadAppointmentDetail,
} from '../../../store/appointment'
import { getDoctor } from '../../../store/doctor'
class NotesThought extends Component {
  state = {
    id: '',
    appointment_id: '',
    post: false,
    pre: false,
    progress: false,
    data: {},
    is_submitted: false,
    header: '',
    footer: '',
    confirmation: false,
    followup: false,
  }
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },
      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      'height': 55,
      'minHeight': 55,
      'borderColor': state.isFocused ? '#6119c0' : '#e0e0e0',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  onHide = e => {
    if (e) e.preventDefault()
    this.setState({ confirmation: !this.state.confirmation })
  }
  togglePost = (id, appointment_id) => {
    if (this.state.post === false) {
      this.setState({
        appointment_id,
        id: id,
        pre: false,
        post: !this.state.post,
        progress: false,
      })
    } else {
      this.setState({
        id: '',
        pre: false,
        post: !this.state.post,
        progress: false,
        data: '',
      })
    }
  }
  togglePre = (id, appointment_id) => {
    if (this.state.pre === false) {
      this.setState({
        appointment_id,
        id: id,
        post: false,
        pre: !this.state.pre,
        progress: false,
      })
    } else {
      this.setState({
        appointment_id,
        id: '',
        post: false,
        pre: !this.state.pre,
        progress: false,
        data: '',
      })
    }
  }
  toggleProgress = (id, appointment_id) => {
    if (this.state.progress === false) {
      this.setState({
        appointment_id,
        id: id,
        post: false,
        progress: !this.state.progress,
        pre: false,
      })
    } else {
      this.setState({
        appointment_id,
        id: '',
        post: false,
        progress: !this.state.progress,
        pre: false,
        data: '',
      })
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        //patient_id: this.props.patientId,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.appoint_id &&
      this.props.appoint_id != prevProps.appoint_id
    ) {
      this.props.loadAppointmentDetail(this.props.appoint_id, res =>
        this.setState({ followup: res.data.data.followup }, () => {
          this.props.loadAllAssessment({
            appointment_id: this.props.appoint_id,
            patient_id: this.props.patientId,
            doctor_id: this.props.doctorId,
            category: this.state.followup
              ? [
                  this.props.patientType === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.patientType === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                  this.props.patientType === 2
                    ? 'Progress Notes - Guardian'
                    : 'Progress Notes - Self',
                ]
              : [
                  this.props.patientType === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.patientType === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                ],
          })
        })
      )
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        is_submitted:
          this.props.answer.data && this.props.answer.data.is_submitted,
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
  }

  handleSubmit = e => {
    this.onHide()
    e.preventDefault()
    const formData = { ...this.state.data }
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        patient_id: this.props.patientId,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
        is_submitted: true,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    } else {
      payLoad = {
        patient_id: this.props.patientId,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
        is_submitted: true,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    }
  }
  callBack = res => {
    if (res && res.status === 200) {
      this.props.loadAnswerAssessment({
        patient_id: this.props.patientId,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
      })
      this.props.loadAllAssessment({
        appointment_id: this.props.appoint_id,
        patient_id: this.props.patientId,
        doctor_id: this.props.doctorId,
        category: this.state.followup
          ? [
              this.props.patientType === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.patientType === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
              this.props.patientType === 2
                ? 'Progress Notes - Guardian'
                : 'Progress Notes - Self',
            ]
          : [
              this.props.patientType === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.patientType === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
            ],
      })
      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
    }
  }
  // componentDidMount = () => {
  //   this.props.loadAllAssessment({
  //     appointment_id: this.props.appoint_id,
  //     patient_id: this.props.patientId,
  //     doctor_id: this.props.doctorId,
  //     category: [
  //       this.props.patientType === 2
  //         ? "Pre-Session Thoughts - Guardian"
  //         : "Pre-Session Thoughts - Self",
  //       this.props.patientType === 2
  //         ? "Post-Session Thoughts - Guardian"
  //         : "Post-Session Thoughts - Self",
  //       this.props.patientType === 2
  //         ? "Progress Notes - Guardian"
  //         : "Progress Notes - Self",
  //     ],
  //   });
  // };
  render() {
    const { detail, assessment } = this.props
    console.log(
      'appointment',
      this.props.appointmentDetail &&
        this.props.appointmentDetail.doctor_id &&
        this.props.appointmentDetail.doctor_id._id,
      this.props.doctorProfile._id
    )
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? 'sidebar-box notesopen'
              : 'sidebar-box notesclose'
          }
          id='calculator'>
          <div className='sidebar-head d-flex align-items-center'>
            <h5>Notes & Thoughts</h5>
            <div
              className='close-img ml-auto'
              onClick={e => {
                e.preventDefault()
                this.props.toggle()
                this.setState({
                  appointment_id: '',
                  id: '',
                  post: false,
                  Progress: false,
                  pre: false,
                  data: '',
                  post: false,
                  pre: false,
                })
              }}>
              <img src={close} alt='' />
            </div>
          </div>
          <div className='sidebar-body height-auto'>
            <div className='accordion' id='accordionExample'>
              <div className='item'>
                {assessment.length &&
                  (this.state.followup
                    ? [
                        assessment.find(
                          eds =>
                            eds.title.trim() === 'Pre-session Thoughts' ||
                            eds.title.trim() ===
                              'Pre-session Thoughts For Guardian'
                        ) || [],
                        assessment.find(
                          eds =>
                            eds.title.trim() === 'Progress Notes' ||
                            eds.title.trim() === 'Progress Notes For Guardian'
                        ) || [],
                        assessment.find(
                          eds =>
                            eds.title.trim() === 'Post session Thoughts' ||
                            eds.title.trim() ===
                              'Post session Thoughts For Guardian'
                        ) || [],
                      ]
                    : [
                        assessment.find(
                          eds =>
                            eds.title.trim() === 'Pre-session Thoughts' ||
                            eds.title.trim() ===
                              'Pre-session Thoughts For Guardian'
                        ) || [],
                        assessment.find(
                          eds =>
                            eds.title.trim() === 'Post session Thoughts' ||
                            eds.title.trim() ===
                              'Post session Thoughts For Guardian'
                        ) || [],
                      ]
                  ).map((e, i) => (
                    <>
                      {e.title &&
                        (e.title.trim() === 'Pre-session Thoughts' ||
                          e.title.trim() ===
                            'Pre-session Thoughts For Guardian') && (
                          <a
                            href='#'
                            className={
                              this.state.pre === true
                                ? 'ih-box'
                                : 'ih-box collapsed'
                            }
                            id='headingOne'
                            data-toggle='collapse'
                            data-target='#collapseOne'
                            aria-expanded={
                              this.state.pre === false ? 'false' : 'true'
                            }
                            aria-controls='collapseOne'
                            onClick={() =>
                              this.togglePre(e._id, e.appointment_id)
                            }>
                            <h3>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 30 30'>
                                <g transform='translate(-44.5 -129)'>
                                  <rect
                                    className='a'
                                    width='30'
                                    height='30'
                                    rx='15'
                                    transform='translate(44.5 129)'
                                  />
                                  <path
                                    className='b'
                                    d='M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z'
                                    transform='translate(51.5 139)'
                                  />
                                </g>
                              </svg>

                              <span>{e.title}</span>
                            </h3>
                            <span className='icon ml-auto'>
                              <i>
                                <img src={down} alt='' />
                              </i>
                            </span>
                          </a>
                        )}
                    </>
                  ))}

                <div
                  id='collapseOne'
                  className={
                    this.state.pre === true ? 'collapse show' : 'collapsing'
                  }
                  aria-labelledby='headingOne'
                  data-parent='#accordionExample'>
                  {this.state.pre === true && (
                    <>
                      <div className='t-p'>
                        {this.props.loading === true ? (
                          'Loading...'
                        ) : (
                          <form
                            action=''
                            onSubmit={this.handleSubmit}
                            className='sidebar-content-box'>
                            {this.props.answer.data &&
                            this.props.answer.data.is_submitted === true
                              ? detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => {
                                      const showValue =
                                        Array.isArray(
                                          this.state.data[e1.question]
                                        ) &&
                                        this.state.data[e1.question].length >
                                          0 &&
                                        typeof this.state.data[
                                          e1.question
                                        ][0] === 'object'
                                          ? this.state.data[e1.question]
                                              .map(u => u.label)
                                              .join(',')
                                          : Array.isArray(
                                              this.state.data[e1.question]
                                            )
                                          ? this.state.data[e1.question].join(
                                              ','
                                            )
                                          : typeof this.state.data[
                                              e1.question
                                            ] === 'object'
                                          ? this.state.data[e1.question].label
                                          : this.state.data[e1.question]

                                      return (
                                        <div
                                          //className='check-box-group'
                                          key={i1}>
                                          <div class='scb-right pb-3'>
                                            <p>{e1.question}</p>
                                            <h6>
                                              {this.state.data && showValue}
                                            </h6>
                                          </div>

                                          {/* <label>{e1.question}</label> */}

                                          {/* {e1.response_type === 'text' && (
                                            <input
                                              type='text'
                                              name=''
                                              className='form-control'
                                              value={
                                                this.state.data &&
                                                this.state.data[e1.question]
                                              }
                                              placeholder='Write here…'
                                            />
                                          )}
                                          {e1.response_type === 'text_area' && (
                                            <textarea
                                              type='text'
                                              className='form-control'
                                              placeholder=''
                                              value={
                                                this.state.data &&
                                                this.state.data[e1.question]
                                              }></textarea>
                                          )}
                                          {e1.response_type ===
                                            'free_text_pill_box' && (
                                            <CreatableSelect
                                              noOptionsMessage={() =>
                                                'Type and press enter to add options'
                                              }
                                              isMulti
                                              classNamePrefix='select'
                                              isDisabled={false}
                                              isLoading={false}
                                              name='color'
                                              styles={this.customStyles}
                                              value={
                                                this.state.data &&
                                                this.state.data[e1.question]
                                              }
                                              onChange={e => {
                                                console.log('eeeee', e)
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                data[e1.question] = e
                                                this.setState({ data })
                                                console.log(e)
                                              }}
                                            />
                                          )}
                                          {e1.response_type ===
                                            'multiselect_dropdown' && (
                                            <Select
                                              isMulti
                                              className='basic-single'
                                              classNamePrefix='select'
                                              isDisabled={false}
                                              isLoading={false}
                                              isClearable={false}
                                              isRtl={false}
                                              isSearchable={false}
                                              name='color'
                                              styles={this.customStyles}
                                              options={e1.options.map(ex => ({
                                                label: ex.title,
                                                value: ex.title,
                                              }))}
                                              value={
                                                this.state.data &&
                                                this.state.data[e1.question]
                                              }
                                              onChange={e => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                data[e1.question] = e
                                                this.setState({ data })
                                                console.log(e)
                                              }}
                                            />
                                          )}
                                          {e1.response_type === 'dropdown' && (
                                            <Select
                                              className='basic-single'
                                              classNamePrefix='select'
                                              isDisabled={false}
                                              isLoading={false}
                                              isClearable={false}
                                              isRtl={false}
                                              isSearchable={false}
                                              name='color'
                                              styles={this.customStyles}
                                              options={e1.options.map(ex => ({
                                                label: ex.title,
                                                value: ex.title,
                                              }))}
                                              value={
                                                this.state.data &&
                                                this.state.data[e1.question]
                                              }
                                              onChange={e => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                data[e1.question] = e
                                                this.setState({ data })
                                                console.log(e)
                                              }}
                                            />
                                          )} */}

                                          {/* <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                            {e1.response_type === 'checkbox' &&
                                              e1.options.map((e2, i2) => (
                                                <div
                                                  className='form-check'
                                                  key={i2}
                                                  onChange={event => {
                                                    const data = {
                                                      ...this.state.data,
                                                    }
                                                    if (event) {
                                                      const findData =
                                                        data[e1.question] &&
                                                        data[e1.question].find(
                                                          echeck =>
                                                            echeck ===
                                                            event.target.value
                                                        )

                                                      if (findData) {
                                                        data[e1.question] =
                                                          data[
                                                            e1.question
                                                          ].filter(
                                                            eqs =>
                                                              eqs !==
                                                              event.target.value
                                                          )
                                                      } else {
                                                        if (data[e1.question]) {
                                                          data[
                                                            e1.question
                                                          ].push(
                                                            event.target.value
                                                          )
                                                        } else {
                                                          data[e1.question] = [
                                                            event.target.value,
                                                          ]
                                                        }
                                                      }

                                                      this.setState({ data })
                                                    }
                                                  }}>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value={e2.title}
                                                    checked={
                                                      this.state.data &&
                                                      this.state.data[
                                                        e1.question
                                                      ] &&
                                                      this.state.data[
                                                        e1.question
                                                      ].find(
                                                        echeck =>
                                                          echeck === e2.title
                                                      )
                                                    }
                                                    name={e2.title + e1._id}
                                                    id={e2.title + e._id}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor={e2.title + e._id}>
                                                    <span></span>
                                                    {e2.title}
                                                  </label>
                                                </div>
                                              ))}
                                          </div>
                                          <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                            {e1.response_type === 'radio' &&
                                              e1.options.map((e2, i2) => (
                                                <div
                                                  className='form-check'
                                                  key={i2}>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    checked={
                                                      this.state.data &&
                                                      this.state.data[
                                                        e1.question
                                                      ] === e2.title
                                                    }
                                                    value={e2.title}
                                                    id={e2.title + e1._id}
                                                    name={e2.title + e1._id}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor={e2.title + e1._id}>
                                                    <span
                                                      style={{
                                                        marginLeft: '2.75rem',
                                                      }}></span>
                                                    {e2.title}
                                                  </label>
                                                </div>
                                              ))}
                                          </div> */}
                                        </div>
                                      )
                                    })}
                                  </div>
                                ))
                              : detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => (
                                      <div className='check-box-group' key={i1}>
                                        <label>{e1.question}</label>
                                        {e1.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e1.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e1.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }></textarea>
                                        )}
                                        {e1.response_type ===
                                          'free_text_pill_box' && (
                                          <CreatableSelect
                                            noOptionsMessage={() =>
                                              'Type and press enter to add options'
                                            }
                                            isMulti
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            name='color'
                                            styles={this.customStyles}
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            onChange={e => {
                                              console.log('eeeee', e)
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type ===
                                          'multiselect_dropdown' && (
                                          <Select
                                            isMulti
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type === 'dropdown' && (
                                          <Select
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'radio' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={e => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (e) {
                                                    data[e1.question] =
                                                      e.target.value
                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e1._id}
                                                  name={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}

                            {this.props.appointmentDetail &&
                              this.props.appointmentDetail.doctor_id &&
                              this.props.appointmentDetail.doctor_id._id &&
                              this.props.appointmentDetail.doctor_id._id ===
                                this.props.doctorProfile._id && (
                                <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                                  {(this.props.answer.data &&
                                    this.props.answer.data.is_submitted ===
                                      false) ||
                                  this.props.answer.data === null ? (
                                    <>
                                      <button
                                        type='submit'
                                        onClick={this.onHide}
                                        className='btn btn-primary'>
                                        Submit
                                      </button>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                          </form>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class='item'>
                {assessment.length &&
                  [
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Pre-session Thoughts' ||
                        eds.title.trim() === 'Pre-session Thoughts For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Progress Notes' ||
                        eds.title.trim() === 'Progress Notes For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Post session Thoughts' ||
                        eds.title.trim() ===
                          'Post session Thoughts For Guardian'
                    ) || [],
                  ].map((e, i) => (
                    <>
                      {e.title &&
                        (e.title.trim() === 'Progress Notes' ||
                          e.title.trim() === 'Progress Notes For Guardian') && (
                          <a
                            href='#'
                            className={
                              this.state.progress === true
                                ? 'ih-box'
                                : 'ih-box collapsed'
                            }
                            id='headingTwo'
                            data-toggle='collapse'
                            data-target='#collapseTwo'
                            aria-expanded={
                              this.state.progress === false ? 'false' : 'true'
                            }
                            aria-controls='collapseTwo'
                            onClick={() =>
                              this.toggleProgress(e._id, e.appointment_id)
                            }>
                            <h3>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 30 30'>
                                <g transform='translate(-44.5 -129)'>
                                  <rect
                                    class='a'
                                    width='30'
                                    height='30'
                                    rx='15'
                                    transform='translate(44.5 129)'
                                  />
                                  <path
                                    class='b'
                                    d='M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z'
                                    transform='translate(51.5 139)'
                                  />
                                </g>
                              </svg>
                              <span>{e.title}</span>
                            </h3>
                            <span class='icon ml-auto'>
                              <i>
                                <img src={down} alt='' />
                              </i>
                            </span>
                          </a>
                        )}
                    </>
                  ))}
                <div
                  id='collapseTwo'
                  className={
                    this.state.progress === true
                      ? 'collapse show'
                      : 'collapsing'
                  }
                  aria-labelledby='headingTwo'
                  data-parent='#accordionExample'>
                  {this.state.progress === true && (
                    <>
                      <div className='t-p'>
                        {this.props.loading === true ? (
                          'Loading...'
                        ) : (
                          <form
                            action=''
                            onSubmit={this.handleSubmit}
                            className='sidebar-content-box'>
                            {this.props.answer.data &&
                            this.props.answer.data.is_submitted === true
                              ? detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => {
                                      const showValue =
                                        Array.isArray(
                                          this.state.data[e1.question]
                                        ) &&
                                        this.state.data[e1.question].length >
                                          0 &&
                                        typeof this.state.data[
                                          e1.question
                                        ][0] === 'object'
                                          ? this.state.data[e1.question]
                                              .map(u => u.label)
                                              .join(',')
                                          : Array.isArray(
                                              this.state.data[e1.question]
                                            )
                                          ? this.state.data[e1.question].join(
                                              ','
                                            )
                                          : typeof this.state.data[
                                              e1.question
                                            ] === 'object'
                                          ? this.state.data[e1.question].label
                                          : this.state.data[e1.question]
                                      return (
                                        <div
                                          //className='check-box-group'
                                          key={i1}>
                                          <div class='scb-right pb-3'>
                                            <p>{e1.question}</p>
                                            <h6>
                                              {this.state.data && showValue}
                                            </h6>
                                          </div>
                                          {/* <label>{e1.question}</label> */}
                                          {/* {e1.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e1.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }></textarea>
                                        )}
                                        {e1.response_type ===
                                          'free_text_pill_box' && (
                                          <CreatableSelect
                                            noOptionsMessage={() =>
                                              'Type and press enter to add options'
                                            }
                                            isMulti
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            name='color'
                                            styles={this.customStyles}
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            onChange={e => {
                                              console.log('eeeee', e)
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type ===
                                          'multiselect_dropdown' && (
                                          <Select
                                            isMulti
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type === 'dropdown' && (
                                          <Select
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e._id}>
                                                  <span></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'radio' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e1._id}
                                                  name={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div> */}
                                        </div>
                                      )
                                    })}
                                  </div>
                                ))
                              : detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => (
                                      <div className='check-box-group' key={i1}>
                                        <label>{e1.question}</label>
                                        {e1.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e1.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e1.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }></textarea>
                                        )}
                                        {e1.response_type ===
                                          'free_text_pill_box' && (
                                          <CreatableSelect
                                            noOptionsMessage={() =>
                                              'Type and press enter to add options'
                                            }
                                            isMulti
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            name='color'
                                            styles={this.customStyles}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              console.log('eeeee', e)
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type ===
                                          'multiselect_dropdown' && (
                                          <Select
                                            isMulti
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type === 'dropdown' && (
                                          <Select
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e._id}>
                                                  <span></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'radio' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={e => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (e) {
                                                    data[e1.question] =
                                                      e.target.value
                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e1._id}
                                                  name={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                            {this.props.appointmentDetail &&
                              this.props.appointmentDetail.doctor_id &&
                              this.props.appointmentDetail.doctor_id._id &&
                              this.props.appointmentDetail.doctor_id._id ===
                                this.props.doctorProfile._id && (
                                <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                                  {(this.props.answer.data &&
                                    this.props.answer.data.is_submitted ===
                                      false) ||
                                  this.props.answer.data === null ? (
                                    <>
                                      <button
                                        type='submit'
                                        onClick={this.onHide}
                                        className='btn btn-primary'>
                                        Submit
                                      </button>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                          </form>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class='item'>
                {assessment.length &&
                  [
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Pre-session Thoughts' ||
                        eds.title.trim() === 'Pre-session Thoughts For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Progress Notes' ||
                        eds.title.trim() === 'Progress Notes For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Post session Thoughts' ||
                        eds.title.trim() ===
                          'Post session Thoughts For Guardian'
                    ) || [],
                  ].map((e, i) => (
                    <>
                      {e.title &&
                        (e.title.trim() === 'Post session Thoughts' ||
                          e.title.trim() ===
                            'Post session Thoughts For Guardian') && (
                          <a
                            href='#'
                            className={
                              this.state.post === true
                                ? 'ih-box'
                                : 'ih-box collapsed'
                            }
                            id='headingTwo'
                            data-toggle='collapse'
                            data-target='#collapseTwo'
                            aria-expanded={
                              this.state.post === false ? 'false' : 'true'
                            }
                            aria-controls='collapseTwo'
                            onClick={() =>
                              this.togglePost(e._id, e.appointment_id)
                            }>
                            <h3>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='30'
                                height='30'
                                viewBox='0 0 30 30'>
                                <g transform='translate(-44.5 -129)'>
                                  <rect
                                    class='a'
                                    width='30'
                                    height='30'
                                    rx='15'
                                    transform='translate(44.5 129)'
                                  />
                                  <path
                                    class='b'
                                    d='M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z'
                                    transform='translate(51.5 139)'
                                  />
                                </g>
                              </svg>
                              <span>{e.title}</span>
                            </h3>
                            <span class='icon ml-auto'>
                              <i>
                                <img src={down} alt='' />
                              </i>
                            </span>
                          </a>
                        )}
                    </>
                  ))}
                <div
                  id='collapseTwo'
                  className={
                    this.state.post === true ? 'collapse show' : 'collapsing'
                  }
                  aria-labelledby='headingTwo'
                  data-parent='#accordionExample'>
                  {this.state.post === true && (
                    <>
                      <div className='t-p'>
                        {this.props.loading === true ? (
                          'Loading...'
                        ) : (
                          <form
                            action=''
                            onSubmit={this.handleSubmit}
                            className='sidebar-content-box'>
                            {this.props.answer.data &&
                            this.props.answer.data.is_submitted === true
                              ? detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => {
                                      const showValue =
                                        Array.isArray(
                                          this.state.data[e1.question]
                                        ) &&
                                        this.state.data[e1.question].length >
                                          0 &&
                                        typeof this.state.data[
                                          e1.question
                                        ][0] === 'object'
                                          ? this.state.data[e1.question]
                                              .map(u => u.label)
                                              .join(',')
                                          : Array.isArray(
                                              this.state.data[e1.question]
                                            )
                                          ? this.state.data[e1.question].join(
                                              ','
                                            )
                                          : typeof this.state.data[
                                              e1.question
                                            ] === 'object'
                                          ? this.state.data[e1.question].label
                                          : this.state.data[e1.question]
                                      return (
                                        <div
                                          //className='check-box-group'
                                          key={i1}>
                                          <div class='scb-right pb-3'>
                                            <p>{e1.question}</p>
                                            <h6>
                                              {this.state.data && showValue}
                                            </h6>
                                          </div>
                                          {/* <label>{e1.question}</label> */}
                                          {/* {e1.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e1.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }></textarea>
                                        )}
                                        {e1.response_type ===
                                          'free_text_pill_box' && (
                                          <CreatableSelect
                                            noOptionsMessage={() =>
                                              'Type and press enter to add options'
                                            }
                                            isMulti
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            name='color'
                                            styles={this.customStyles}
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            onChange={e => {
                                              console.log('eeeee', e)
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type ===
                                          'multiselect_dropdown' && (
                                          <Select
                                            isMulti
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type === 'dropdown' && (
                                          <Select
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title}>
                                                  <span></span>
                                                  {e2.title + e1._id}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'radio' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e1._id}
                                                  name={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div> */}
                                        </div>
                                      )
                                    })}
                                  </div>
                                ))
                              : detail.questions &&
                                detail.questions.map((e, i) => (
                                  <div className='sidebar-body' key={i}>
                                    {e.questions.map((e1, i1) => (
                                      <div className='check-box-group' key={i1}>
                                        <label>{e1.question}</label>
                                        {e1.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e1.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e1.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            className='form-control'
                                            placeholder=''
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }></textarea>
                                        )}
                                        {e1.response_type ===
                                          'free_text_pill_box' && (
                                          <CreatableSelect
                                            noOptionsMessage={() =>
                                              'Type and press enter to add options'
                                            }
                                            isMulti
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            name='color'
                                            styles={this.customStyles}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              console.log('eeeee', e)
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type ===
                                          'multiselect_dropdown' && (
                                          <Select
                                            isMulti
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}
                                        {e1.response_type === 'dropdown' && (
                                          <Select
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={false}
                                            isRtl={false}
                                            isSearchable={false}
                                            name='color'
                                            styles={this.customStyles}
                                            options={e1.options.map(ex => ({
                                              label: ex.title,
                                              value: ex.title,
                                            }))}
                                            value={this.state.data[e1.question]}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              data[e1.question] = e
                                              this.setState({ data })
                                              console.log(e)
                                            }}
                                          />
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'radio' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={e => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (e) {
                                                    data[e1.question] =
                                                      e.target.value
                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e1._id}
                                                  name={e2.title + e1._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e1._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}

                            {this.props.appointmentDetail &&
                              this.props.appointmentDetail.doctor_id &&
                              this.props.appointmentDetail.doctor_id._id &&
                              this.props.appointmentDetail.doctor_id._id ===
                                this.props.doctorProfile._id && (
                                <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                                  {(this.props.answer.data &&
                                    this.props.answer.data.is_submitted ===
                                      false) ||
                                  this.props.answer.data === null ? (
                                    <>
                                      <button
                                        type='submit'
                                        onClick={this.onHide}
                                        className='btn btn-primary'>
                                        Submit
                                      </button>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}
                          </form>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow === true ? 'overlay4 show' : 'overlay4'
          }></div>
        <Modal
          show={this.state.confirmation}
          onHide={this.onHide}
          style={{ padding: '20% 0' }}>
          <div class='modal-body'>
            <h4
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              Are you sure you want to proceed?
            </h4>
            <div class='modal-coustom-btn d-flex align-items-center justify-content-center'>
              <a
                href='javascript:void (0)'
                class='btn btn-primary'
                onClick={this.handleSubmit}>
                Yes
              </a>
              <a
                href='javascript:void (0)'
                class='btn btn-outline no-shadow'
                onClick={this.onHide}>
                Cancel
              </a>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  detail: getAssessment(state).detail,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
  assessment: getAssessment(state).assessment,
  appointmentDetail: getAppointment(state).appointmentDetail.data,
  doctorProfile: getDoctor(state).profile,
})
const mapDispatchToProps = dispatch => ({
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerAssessment: params => dispatch(loadAnswerAssessment(params)),
  loadAppointmentDetail: (id, callBack) =>
    dispatch(loadAppointmentDetail(id, callBack)),
  addAnswerAssessment: (data, callBack) =>
    dispatch(addAnswerAssessment(data, callBack)),
  loadAllAssessment: params => dispatch(loadAllAssessment(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(NotesThought)
