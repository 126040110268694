import Joi from "joi-browser";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../include/images/yes-mindy-main-logo-white-color.png";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import Form from "../common/form/form";

import {
  emailRequired,
  loginFailed,
  loginSuccess,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  usernameRequired,
} from "../common/misc";
import { counselorSignIn, doctorSignIn } from "../../store/doctor";
import SubmitBtn from "../common/form/submitBtn";
import { loadSeo } from "../../store/seo";

class CounselorLogin extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: {
      username: "",
      password: "",
    },
    errors: {
      username: "",
      password: "",
    },
  };
  componentDidMount = () => {
    this.props.loadSeo("counsellor-login");
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),
    username: Joi.string()
      .required()
      .label("Username")
      .error(() => {
        return { message: usernameRequired };
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });
      const { data } = this.state;
      data.field_type = true;
      this.props.doctorSignIn(data, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      btnClass: "btn btn-lg btn-primary btn-block",
      loading: false,
    });
    if (res.status === 200) {
      localStorage.setItem("counselor_id", res.data.data._id);
      localStorage.setItem("type", "counselor");
      localStorage.setItem("counselorData", JSON.stringify(res.data.data));
      //toast(<AlertSuccess message={loginSuccess} />);
      this.props.history.push("/counsellor/chat");
    } else {
      toast(<AlertError message={loginFailed} />);
    }
  };

  render() {
    return (
      <div className="member-container">
        <div className="top-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div className="member-box d-flex login-form">
          <div className="member-left">
            <div className="ml-box">
              <h1>YesMindy.</h1>
              <p className="mb-0">Please enter your password to continue</p>
            </div>
          </div>

          <div className="member-right">
            <div className="member-right-content d-md-flex align-items-center">
              <div className="mr-box">
                <div className="mr-head">
                  <h2>Login</h2>
                </div>
                <form action="" onSubmit={this.handleSubmit}>
                  <div className="form-box">
                    {this.renderInput("username", "Email / Phone")}
                    {this.renderPasswordInput("password", "Password")}

                    <div className="form-group">
                      <SubmitBtn
                        label="Login"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                    <p className="forgot-password text-center">
                      <Link to="/doctor/forget-password">
                        Forgot/Set Password
                      </Link>
                    </p>
                    <div class="seprator">or</div>
                    <div class="login-as-text-box text-center">
                      <h5>
                        <Link to="/doctor/sign-in">
                          Login as a Health Professional
                        </Link>
                      </h5>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  doctorSignIn: (param, callback) => dispatch(doctorSignIn(param, callback)),
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
});
export default withRouter(connect(null, mapDispatchToProps)(CounselorLogin));
