import React, { Component } from "react";
import {
  relation,
  hobbies,
  mood,
  character,
  interpersonal,
  morbid,
  matters,
  energy,
  fantasy,
  habits,
} from "./common";
export default class Personality extends Component {
  render() {
    return (
      <>
        <h4>Personality</h4>
        <div class="radio-group-box">
          <h5>Social Relation</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {relation.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.social_realtion.find(
                      (el) => el === e.Relation
                    )}
                    id={e.Relation}
                    class="form-check-input"
                    name="social_realtion"
                    onChange={() => this.props.changeLang19(e.Relation)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Relation}>
                    {e.Relation}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore10
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore10">Write More</label>
                <textarea
                  type="text"
                  id="writeMore10"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore10}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Intellectual activities, hobbies and interests</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {hobbies.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.hobbies.find((el) => el === e.Hobbies)}
                    id={e.Hobbies}
                    class="form-check-input"
                    name="hobbies"
                    onChange={() => this.props.changeLang20(e.Hobbies)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Hobbies}>
                    {e.Hobbies}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore11
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore11">Write More</label>
                <textarea
                  type="text"
                  id="writeMore11"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore11}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Mood</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {mood.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.mood.find((el) => el === e.Mood)}
                    id={e.Mood}
                    class="form-check-input"
                    name="mood"
                    onChange={() => this.props.changeLang21(e.Mood)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Mood}>
                    {e.Mood}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore12
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore12">Write More</label>
                <textarea
                  type="text"
                  id="writeMore12"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore12}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Character At Work</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {character.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.character.find(
                      (el) => el === e.Character
                    )}
                    id={e.Character}
                    class="form-check-input"
                    name="character"
                    onChange={() => this.props.changeLang22(e.Character)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Character}>
                    {e.Character}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore13
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore13">Write More</label>
                <textarea
                  type="text"
                  id="writeMore13"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore13}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Character Towards Interpersonal Relationships</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {interpersonal.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.interpersonal.find(
                      (el) => el === e.Interpersonal
                    )}
                    id={e.Interpersonal}
                    class="form-check-input"
                    name="interpersonal"
                    onChange={() => this.props.changeLang23(e.Interpersonal)}
                  />
                  <label
                    class="form-check-label ml-4"
                    htmlFor={e.Interpersonal}
                  >
                    {e.Interpersonal}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore14
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore14">Write More</label>
                <textarea
                  type="text"
                  id="writeMore14"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore14}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Standards in Moral, religious, social and health matters</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {matters.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.matters.find((el) => el === e.Matter)}
                    id={e.Matter}
                    class="form-check-input"
                    name="matters"
                    onChange={() => this.props.changeLang24(e.Matter)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Matter}>
                    {e.Matter}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore15
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore15">Write More</label>
                <textarea
                  type="text"
                  id="writeMore15"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore15}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Energy/ Initiative</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {energy.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.energy.find((el) => el === e.Energy)}
                    id={e.Energy}
                    class="form-check-input"
                    name="energy"
                    onChange={() => this.props.changeLang25(e.Energy)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Energy}>
                    {e.Energy}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore16
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore16">Write More</label>
                <textarea
                  type="text"
                  id="writeMore16"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore16}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Fantasy Life</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {fantasy.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.fantasy.find((el) => el === e.Fantasy)}
                    id={e.Fantasy}
                    class="form-check-input"
                    name="fantasy"
                    onChange={() => this.props.changeLang26(e.Fantasy)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Fantasy}>
                    {e.Fantasy}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore17
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore17">Write More</label>
                <textarea
                  type="text"
                  id="writeMore17"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore17}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Habits</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {habits.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.habits.find((el) => el === e.Habits)}
                    id={e.Habits}
                    class="form-check-input"
                    name="habits"
                    onChange={() => this.props.changeLang27(e.Habits)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Habits}>
                    {e.Habits}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore18
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore18">Write More</label>
                <textarea
                  type="text"
                  id="writeMore18"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore18}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Morbid Personality</h5>
          <div class="radio-group-row d-flex flex-wrap align-items-center">
            {morbid.map((e) => (
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  id={e.Morbid}
                  name="morbid"
                  value={e.Morbid}
                  onChange={(e) => this.props.toggleMorbid(e)}
                  checked={this.props.morbid === e.Morbid}
                />
                <label class="form-check-label" htmlFor={e.Morbid}>
                  {e.Morbid}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div class="pane-seprator"></div>
      </>
    );
  }
}
