import React, { Component } from 'react'
import avtarImg from '../../../../include/images/avatar1.png'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import star from '../../../../include/images/start-0.svg'
import { loadList, getPrescription } from '../../../../store/prescription'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Moment from 'moment'
import AvtarImage from '../../../common/avtarImage'
import NoRecord from '../../../common/noRecord'
import { getDoctor } from '../../../../store/doctor'
import ReactTooltip from 'react-tooltip'

class CaseRecords extends Component {
  render() {
    const { list } = this.props
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div
          class="tab-pane fade show active"
          id="prescriptions"
          role="tabpanel"
          aria-labelledby="prescriptions-tab"
        >
          <div class="tb-body">
            {list.length === 0 && (
              <NoRecord
                noLogo={true}
                header=" Currently You Don’t Have Any Active Case Record Form To
               Display Here"
                text="One of the fastest, convenient, and detailed Case Record Form designed for Psychiatrists/ Psychologists to enhance their clinical practice."
              />
            )}
            {list.filter(
              (ecase) =>
                !ecase.caseRecord.finish ||
                !ecase.caseRecord.finish[
                  this.props.doctorProfile.field_type.name === 'Psychiatrist'
                    ? 'diagnosisPsychiatrist'
                    : 'diagnosis'
                ].diagnosis ||
                ecase.caseRecord.finish[
                  this.props.doctorProfile.field_type.name === 'Psychiatrist'
                    ? 'diagnosisPsychiatrist'
                    : 'diagnosis'
                ].diagnosis.length === 0,
            ).length > 0 && (
              <div class="chat-seprator1">
                <span>In-Complete</span>
              </div>
            )}

            <ul class="chat-member-list bordered">
              {list
                .filter(
                  (ecase) =>
                    !ecase.caseRecord.finish ||
                    (ecase.caseRecord.finish &&
                      ecase.caseRecord.finish[
                        this.props.doctorProfile.field_type.name ===
                        'Psychiatrist'
                          ? 'diagnosisPsychiatrist'
                          : 'diagnosis'
                      ] &&
                      (!ecase.caseRecord.finish[
                        this.props.doctorProfile.field_type.name ===
                        'Psychiatrist'
                          ? 'diagnosisPsychiatrist'
                          : 'diagnosis'
                      ].diagnosis ||
                        ecase.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ].diagnosis.length === 0)),
                )
                .map((e, i) => (
                  <Link
                    to={{
                      pathname: '/doctor/dashboard/case-record',
                      state: e._id,
                    }}
                    style={{ display: 'unset' }}
                  >
                    <li class="cm-item">
                      <div class="cm-box d-flex flex-wrap">
                        <div class="person-details-box d-flex align-items-center flex-grow-1">
                          <div class="person-img">
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=""
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class="person-text-box">
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt="star-icon" />
                                ) : (
                                  <img src={star} alt="star-icon" />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class="pd-right-content">
                          {e.lastAppointment && (
                            <>
                              Last appointment:{' '}
                              {Moment(e.lastAppointment.appointment_time)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </>
                          )}
                          <div>
                            {e.caseRecord.finish &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis.length > 0 && (
                                <div style={{ textAlign: 'right' }}>
                                  <div style={{ fontWeight: 'bold' }}>
                                    {
                                      e.caseRecord.finish[
                                        this.props.doctorProfile.field_type
                                          .name === 'Psychiatrist'
                                          ? 'diagnosisPsychiatrist'
                                          : 'diagnosis'
                                      ].diagnosis[0].value
                                    }
                                    <i
                                      data-tip={
                                        e.caseRecord.finish[
                                          this.props.doctorProfile.field_type
                                            .name === 'Psychiatrist'
                                            ? 'diagnosisPsychiatrist'
                                            : 'diagnosis'
                                        ].diagnosis[0].diagnosis
                                      }
                                      class="fas fa-info-circle ml-2"
                                      aria-hidden="true"
                                      style={{
                                        color: 'rgb(24 0 88 / 50%)',
                                        fontSize: '12px',
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
            {list.filter(
              (ecase) =>
                ecase.caseRecord.finish &&
                ecase.caseRecord.finish[
                  this.props.doctorProfile.field_type.name === 'Psychiatrist'
                    ? 'diagnosisPsychiatrist'
                    : 'diagnosis'
                ].diagnosis &&
                ecase.caseRecord.finish[
                  this.props.doctorProfile.field_type.name === 'Psychiatrist'
                    ? 'diagnosisPsychiatrist'
                    : 'diagnosis'
                ].diagnosis &&
                ecase.caseRecord.finish[
                  this.props.doctorProfile.field_type.name === 'Psychiatrist'
                    ? 'diagnosisPsychiatrist'
                    : 'diagnosis'
                ].diagnosis.length > 0,
            ).length > 0 && (
              <div class="chat-seprator1">
                <span>Complete</span>
              </div>
            )}
            <ul class="chat-member-list bordered">
              {list
                .filter(
                  (ecase) =>
                    ecase.caseRecord.finish &&
                    ecase.caseRecord.finish[
                      this.props.doctorProfile.field_type.name ===
                      'Psychiatrist'
                        ? 'diagnosisPsychiatrist'
                        : 'diagnosis'
                    ].diagnosis &&
                    ecase.caseRecord.finish[
                      this.props.doctorProfile.field_type.name ===
                      'Psychiatrist'
                        ? 'diagnosisPsychiatrist'
                        : 'diagnosis'
                    ].diagnosis &&
                    ecase.caseRecord.finish[
                      this.props.doctorProfile.field_type.name ===
                      'Psychiatrist'
                        ? 'diagnosisPsychiatrist'
                        : 'diagnosis'
                    ].diagnosis.length > 0,
                )
                .map((e, i) => (
                  <Link
                    to={{
                      pathname: '/doctor/dashboard/case-record',
                      state: e._id,
                    }}
                    style={{ display: 'unset' }}
                  >
                    <li class="cm-item">
                      <div class="cm-box d-flex flex-wrap">
                        <div class="person-details-box d-flex align-items-center flex-grow-1">
                          <div class="person-img">
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=""
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class="person-text-box">
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt="star-icon" />
                                ) : (
                                  <img src={star} alt="star-icon" />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class="pd-right-content">
                          <div>
                            {e.lastAppointment && (
                              <>
                                Last appointment:{' '}
                                {Moment(e.lastAppointment.appointment_time)
                                  .utc()
                                  .format('DD-MM-YYYY')}
                              </>
                            )}
                          </div>
                          <div>
                            {e.caseRecord.finish &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis.length > 0 && (
                                <>
                                  {e.caseRecord.finish[
                                    this.props.doctorProfile.field_type.name ===
                                    'Psychologist'
                                      ? 'diagnosis'
                                      : 'diagnosisPsychiatrist'
                                  ].diagnosis.map((ed) => (
                                    <div class="d-flex align-items-center justify-content-end">
                                      <strong>{ed.value}</strong>
                                      <div className="custom-dropright">
                                        <div>
                                          <a
                                            className="custom-dropdown-toggle"
                                            href="javascript:void(0)"
                                            data-tip={ed.diagnosis}
                                          >
                                            <i
                                              data-tip={ed.diagnosis}
                                              className="fas fa-info-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  list: getPrescription(state).list,
  doctorProfile: getDoctor(state).profile,
})
export default withRouter(connect(mapStateToProps, null)(CaseRecords))
