import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../../common/form/form";
import SignUpLeftBar from "../../common/signUpLeftBar";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import loaderIcon from "../../../../include/images/loading-icon.svg";
import _ from "lodash";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorToken,
  setDoctorToken,
} from "../../../services/localStorageServices";
import {
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  placeRequired,
  certificationRequired,
  yearRequired,
  yearInvalid,
} from "../../../common/misc";
import { Link } from "react-router-dom";
import SubmitBtn from "../../../common/form/submitBtn";
import HelpText from "../helpText";
class Cetrification extends Form {
  state = {
    inputs: [1],
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      title1: "",
      place1: "",
      year1: "",
    },
    errors: {},
  };

  schema = {
    title1: Joi.string()
      .required()
      .error(() => {
        return { message: certificationRequired };
      }),

    place1: Joi.string()
      .required()
      .error(() => {
        return { message: placeRequired };
      }),
    year1: Joi.number()
      .max(parseInt(new Date().getFullYear()))
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log("errerr", err);
          switch (err.type) {
            case "number.base":
              err.message = yearRequired;
              break;

            case "number.max":
              err.message = yearInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    const payload = [];

    this.state.inputs.forEach((element) => {
      const obj = {
        title: this.state.data["title" + element],
        place: this.state.data["place" + element],
        year: this.state.data["year" + element],
      };
      payload.push(obj);
    });
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate({ certification: payload }, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push(
        "/doctor/sign-up/educational-detail/affiliations"
      );
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  addMore = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const NewNumber = inputs.length + 1;
    inputs.push(NewNumber);
    this.setState({ inputs });

    const title = "title" + NewNumber;
    const place = "place" + NewNumber;
    const year = "year" + NewNumber;

    const newData = {};
    //const newArr = {};

    newData[title] = "";
    newData[place] = "";
    newData[year] = "";

    this.schema[title] = Joi.string()
      .min(1)
      .required()
      .error(() => {
        return { message: certificationRequired };
      });

    this.schema[year] = Joi.number()
      .max(parseInt(new Date().getFullYear()))
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log("errerr", err);
          switch (err.type) {
            case "number.base":
              err.message = yearRequired;
              break;

            case "number.max":
              err.message = yearInvalid;
              break;
          }
        });
        return errors;
      });
    this.schema[place] = Joi.string()
      .required()
      .error(() => {
        return { message: placeRequired };
      });

    const { data, errors } = this.state;

    this.setState({
      data: { ...data, ...newData },
      errors: { ...errors, ...newData },
    });
  };

  removeLast = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const removeNumber = inputs.length;
    inputs.pop();
    this.setState({ inputs });
    const newData = {};
    const newArr = {};
    const title = "title" + removeNumber;
    const place = "place" + removeNumber;
    const year = "year" + removeNumber;

    const { data, errors } = this.state;
    delete data[title];
    delete data[place];
    delete data[year];

    delete this.schema[title];
    delete this.schema[place];
    delete this.schema[year];
    this.setState({ data, errors: {} });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.doctorProfile.certification &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const inputs = _.range(
        1,
        this.props.doctorProfile.certification.length + 1
      );
      const newData = {};
      if (inputs.length > 1) {
        inputs.forEach((element, i) => {
          newData["title" + element] =
            this.props.doctorProfile.certification[i].title;
          newData["place" + element] =
            this.props.doctorProfile.certification[i].place;
          newData["year" + element] =
            this.props.doctorProfile.certification[i].year;

          this.schema["title" + element] = Joi.string()
            .required()
            .error(() => {
              return { message: certificationRequired };
            });
          this.schema["place" + element] = Joi.string()
            .required()
            .error(() => {
              return { message: placeRequired };
            });
          this.schema["year" + element] = Joi.number()
            .max(parseInt(new Date().getFullYear()))
            .required()
            .error((errors) => {
              errors.forEach((err) => {
                console.log("errerr", err);
                switch (err.type) {
                  case "number.base":
                    err.message = yearRequired;
                    break;

                  case "number.max":
                    err.message = yearInvalid;
                    break;
                }
              });
              return errors;
            });
        });

        this.setState({ inputs, data: newData });
      }

      if (inputs.length === 1) {
        const data = {
          title1: this.props.doctorProfile.certification[0].title,
          place1: this.props.doctorProfile.certification[0].place,
          year1: this.props.doctorProfile.certification[0].year,
        };

        this.setState({ inputs, data });
      }
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Certifications/Fellowships</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                {this.state.inputs.map((element, i) => (
                  <React.Fragment key={element}>
                    <div className="row">
                      <div className="col-xl-5">
                        {this.renderInput(
                          "title" + element,
                          "Certifications/PDF/Superspeciality"
                        )}
                      </div>
                      <div className="col-xl-2">
                        {this.renderInput("place" + element, "Place")}
                      </div>

                      <div className="col-xl-2">
                        {this.renderInput("year" + element, "Year")}
                      </div>
                      {this.state.inputs.length > 1 &&
                        this.state.inputs.length - 1 === i && (
                          <div className="col-xl-2">
                            <a
                              href=""
                              className="add-more-field m-0 errorLabel"
                              onClick={this.removeLast}
                            >
                              - Remove
                            </a>
                          </div>
                        )}
                    </div>
                  </React.Fragment>
                ))}
                <div className="row">
                  <div className="col-xl-5">
                    <a
                      href="#"
                      onClick={this.addMore}
                      className="add-more-field mb-4"
                    >
                      + Add more
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <div className="form-group">
                      <Link
                        className="add-more-field mb-4 p10-top"
                        to="/doctor/sign-up/educational-detail/affiliations"
                      >
                        Skip and fill later
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cetrification);
