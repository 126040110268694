import React, { Component } from "react";
import { CometChatUnified } from "./../../CometChat";
import { CometChat } from "@cometchat-pro/chat";
import LoadingBar from "react-top-loading-bar";
const ref = React.createRef(null);
class CounselorChat extends Component {
  state = {
    isLogeedIn: false,
  };
  componentDidMount = () => {
    ref.current.continuousStart();
    const uid = localStorage.getItem("counselor_id");
    if (!uid) this.props.history.push("/counsellor/chat");
    const counselorData = JSON.parse(localStorage.getItem("counselorData"));
    const appID = process.env.REACT_APP_CHATAPP;
    const region = "EU";
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();
    CometChat.init(appID, appSetting).then(
      () => {
        console.log("Initialization completed successfully");
        // You can now call login function.
        const authKey = process.env.REACT_APP_CHATKEY;

        CometChat.login(uid, authKey).then(
          (user) => {
            this.setState({ isLogeedIn: true });
            console.log("Login Successful:", { user });
          },
          (error) => {
            var name = counselorData.name;
            var image_url =
              process.env.REACT_APP_S3URL + counselorData.image_url;

            var user = new CometChat.User(uid);

            user.setName(name);
            user.setRole("agentonly");
            user.setAvatar(image_url);
            CometChat.createUser(user, authKey).then(
              (user) => {
                console.log("user created", user);
                CometChat.login(uid, authKey).then((user) => {
                  this.setState({ isLogeedIn: true });
                  console.log("Login Successful:", { user });
                });
              },
              (error) => {
                console.log("error", error);
              }
            );
            console.log("Login failed with exception:", { error });
          }
        );
      },
      (error) => {
        console.log("Initialization failed with error:", error);
        // Check the reason for error and take appropriate action.
      }
    );
  };
  render() {
    this.state.isLogeedIn && ref.current.complete();
    return (
      <>
        <LoadingBar color="#621ac0" ref={ref} shadow={true} />
        {this.state.isLogeedIn && <CometChatUnified />}
      </>
    );
  }
}

export default CounselorChat;
