import React from "react";

import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { CometChat } from "@cometchat-pro/chat";

import BanMemberView from "../BanMemberView";
import Backdrop from "../Backdrop";
import GroupDetailContext from "../CometChatGroupDetail/context";

import {
  modalWrapperStyle,
  modalCloseStyle,
  modalBodyStyle,
  modalTableStyle,
  tableCaptionStyle,
  tableBodyStyle,
  roleColumnStyle,
  actionColumnStyle,
  contactMsgStyle,
  contactMsgTxtStyle,
} from "./style";

import Translator from "../../resources/localization/translator";
import { theme } from "../../resources/theme";
import clearIcon from "./resources/close.png";

class CometChatBanMembers extends React.Component {
  static contextType = GroupDetailContext;

  constructor(props) {
    super(props);

    this.decoratorMessage = Translator.translate("LOADING", props.lang);

    this.state = {
      membersToBan: [],
      membersToUnBan: [],
    };
  }

  unbanMember = (memberToUnBan) => {
    const group = this.context;

    const guid = group.item.guid;
    CometChat.unbanGroupMember(guid, memberToUnBan.uid)
      .then((response) => {
        if (response) {
          console.log("Group member unbanning success with response", response);
          this.props.actionGenerated("unbanGroupMembers", [memberToUnBan]);
        }
      })
      .catch((error) => {
        console.log("Group member banning failed with error", error);
      });
  };

  updateMembers = (action, member) => {
    switch (action) {
      case "unban":
        this.unbanMember(member);
        break;
      default:
        break;
    }
  };

  handleScroll = (e) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.round(e.currentTarget.clientHeight);
    if (bottom) {
      this.props.actionGenerated("fetchBannedMembers");
    }
  };

  render() {
    const group = this.context;
    const membersList = [...group.bannedmemberlist];
    const bannedMembers = membersList.map((member, key) => {
      return (
        <BanMemberView
          theme={this.props.theme}
          key={key}
          member={member}
          item={this.props.item}
          loggedinuser={group.loggedinuser}
          lang={this.props.lang}
          widgetsettings={this.props.widgetsettings}
          actionGenerated={this.updateMembers}
        />
      );
    });

    let messageContainer = null;
    if (bannedMembers.length === 0) {
      this.decoratorMessage = Translator.translate(
        "NO_BANNED_MEMBERS_FOUND",
        this.props.lang
      );
      messageContainer = (
        <caption
          style={contactMsgStyle()}
          className="bannedmembers__decorator-message"
        >
          <p
            style={contactMsgTxtStyle(this.props)}
            className="decorator-message"
          >
            {this.decoratorMessage}
          </p>
        </caption>
      );
    }

    return (
      <React.Fragment>
        <Backdrop show={this.props.open} clicked={this.props.close} />
        <div
          style={modalWrapperStyle(this.props)}
          className="modal__bannedmembers"
        >
          <span
            style={modalCloseStyle(clearIcon)}
            className="modal__close"
            onClick={this.props.close}
            title={Translator.translate("CLOSE", this.props.lang)}
          ></span>
          <div style={modalBodyStyle()} className="modal__body">
            <table style={modalTableStyle(this.props)}>
              <caption
                style={tableCaptionStyle(
                  Translator.getDirection(this.props.lang)
                )}
                className="modal__title"
              >
                {Translator.translate("BANNED_MEMBERS", this.props.lang)}
              </caption>
              <thead>
                <tr>
                  <th className="name">
                    {Translator.translate("NAME", this.props.lang)}
                  </th>
                  <th style={roleColumnStyle()} className="role">
                    {Translator.translate("SCOPE", this.props.lang)}
                  </th>
                  <th style={actionColumnStyle()} className="unban">
                    {Translator.translate("UNBAN", this.props.lang)}
                  </th>
                </tr>
              </thead>
              {messageContainer}
              <tbody style={tableBodyStyle()} onScroll={this.handleScroll}>
                {bannedMembers}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Specifies the default values for props:
CometChatBanMembers.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

CometChatBanMembers.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default CometChatBanMembers;
