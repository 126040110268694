import React, { Component } from "react";
import down from "../../../include/images/down-icon-1.svg";
import { getCaserecord, loadCaseRecord } from "../../../store/caseRecord";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "moment";
class CaseRecord extends Component {
  componentDidMount() {
    document.body.className = "dashboard fixed";
    this.props.loadCaseRecord(this.props.id);
  }
  render() {
    const log =
      this.props.case && this.props.case.log ? [...this.props.case.log] : [];
    const reversed = log ? log.reverse() : [];
    console.log("loglog", log);
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="caserecords"
      //   role="tabpanel"
      //   aria-labelledby="caserecords-tab"
      // >
      //   <div class="card-header">Caserecords</div>
      //   <div class="content-body add-scrollbar  scrollbar-dynamic">
      <div class="content-history-box">
        <div class="accordion" id="accordionExample">
          {reversed &&
            reversed.map((e, i) =>
              i === 0 ? (
                <div class="item">
                  <a
                    href="javascript:void(0)"
                    class="ih-box collapsed"
                    id="headingThree"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push({
                        pathname: "/doctor/dashboard/case-record",
                        state: this.props.id,
                      });
                    }}
                  >
                    <h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g transform="translate(-44.5 -129)">
                          <rect
                            class="a"
                            width="30"
                            height="30"
                            rx="15"
                            transform="translate(44.5 129)"
                          />
                          <path
                            class="b"
                            d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z"
                            transform="translate(51.5 139)"
                          />
                        </g>
                      </svg>
                      <span>Case Record Log {log && log.length - i}</span>
                    </h3>
                    <div class="ih-text-right d-flex align-items-center ml-auto">
                      <div class="iht-content">
                        <p>Updated Tab</p>
                        <p>{e.updated_tab.toUpperCase()}</p>
                      </div>
                    </div>
                    <div class="ih-text-right d-flex align-items-center ml-auto">
                      <div class="iht-content">
                        <p>
                          Edited on {Moment(e.updated_on).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          By{" "}
                          <strong>{e.updated_by && e.updated_by.name}</strong>,{" "}
                          {e.updated_by &&
                            e.updated_by.field_type &&
                            e.updated_by.field_type.name}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p"></div>
                  </div>
                </div>
              ) : (
                <div class="item">
                  <a
                    class="ih-box collapsed completed"
                    id="headingThree"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g transform="translate(-44.5 -129)">
                          <rect
                            class="a"
                            width="30"
                            height="30"
                            rx="15"
                            transform="translate(44.5 129)"
                          />
                          <path
                            class="b"
                            d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z"
                            transform="translate(51.5 139)"
                          />
                        </g>
                      </svg>
                      <span>Case Record Log {log && log.length - i}</span>
                    </h3>
                    <div class="ih-text-right d-flex align-items-center ml-auto">
                      <div class="iht-content">
                        <p>Updated Tab</p>
                        <p>{e.updated_tab.toUpperCase()}</p>
                      </div>
                    </div>
                    <div class="ih-text-right d-flex align-items-center ml-auto">
                      <div class="iht-content">
                        <p>
                          Edited on {Moment(e.updated_on).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          By{" "}
                          <strong>{e.updated_by && e.updated_by.name}</strong>,{" "}
                          {e.updated_by &&
                            e.updated_by.field_type &&
                            e.updated_by.field_type.name}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p"></div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
      //   </div>
      // </div>
    );
  }
}
const mapStateToProps = (state) => ({
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
});
const mapDispatchToProps = (dispatch) => ({
  loadCaseRecord: (id) => dispatch(loadCaseRecord(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CaseRecord)
);
