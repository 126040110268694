import React, { Component } from "react";
import trackerImg from "../../../../include/images/tab-tracker2.jpg";

class Tracker extends Component {
  render() {
    return (
      <div class="content-box-inner">
        <div class="placeholder-img">
          <img src={trackerImg} alt="" />
        </div>
      </div>
    );
  }
}
export default Tracker;
