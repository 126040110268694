import React, { Component } from 'react'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import { toast } from 'react-toastify'
import { checkOtp, addPatient, sendOtp } from '../../../../store/patient'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { invalidOTP, expiredOTP } from '../../../common/misc'
import { setToken } from '../../../services/localStorageServices'

class VerifyOtp extends Component {
  state = {
    countDown: 59,
    reset: false,
    countDownId: 1,
    otp: {
      a: '',
      b: '',
      c: '',
      d: '',
    },
  }

  constructor(props) {
    super(props)
    this.aRef = React.createRef()
    this.bRef = React.createRef()
    this.cRef = React.createRef()
    this.dRef = React.createRef()
  }

  componentDidMount = () => {
    const countDown = setInterval(() => {
      if (this.state.countDown === 1 || this.state.reset)
        clearInterval(countDown)
      this.setState({ countDown: this.state.countDown - 1 })
    }, 1000)
    this.setState({ countDownId: countDown })
  }

  componentWillUnmount() {
    clearInterval(this.state.countDownId)
  }

  onChange = e => {
    const { name, value } = e.target
    const otp = this.state.otp

    otp[name] = value.replace(/\D/, '')
    if (otp[name] !== '') {
      this.setState({ otp })
      if (name === 'a') this.bRef.current.focus()
      if (name === 'b') this.cRef.current.focus()
      if (name === 'c') this.dRef.current.focus()
    } else {
      this.setState({ otp })
    }
  }

  doSubmit = e => {
    e.preventDefault()
    const { a, b, c, d } = this.state.otp
    if (this.state.countDown > 1) {
      const data = {
        mobile: this.props.data.email,
        otp: a + b + c + d,
      }
      this.props.checkOtp(data, this.callBaclOtpCheck)
    } else {
      return toast(<AlertError message={expiredOTP} />)
    }
  }

  callBaclOtpCheck = res => {
    if (res.status === 200) {
      const { a, b, c, d } = this.state.otp
      const { data } = this.props
      data.otp = a + b + c + d
      data.mobile = data.phone
      data.type = data.type === 'Self' ? 1 : 2
      data.date_of_birth = this.props.data.DOB.toString()
      delete data.phone
      delete data.DOB
      this.props.addPatient(data, this.callBackSignUp)
    } else {
      return toast(<AlertError message={res.data.error} />)
    }
  }

  callBackSignUp = res => {
    if (res.status === 200) {
      this.props.setId(res.data.data)
      this.props.updateStep(3)
    } else {
      return toast(<AlertError message={res.data.error} />)
    }
  }

  reSend = e => {
    clearInterval(this.state.countDownId)
    const param = {
      mobile: this.props.data.phone,
      email: this.props.data.email,
    }
    this.props.sendOtp(param, this.callback)
    e.preventDefault()
  }

  callback = res => {
    if (res.status === 200) {
      this.setState({ countDown: 59, reset: true }, () => {
        const countDown = setInterval(() => {
          if (this.state.countDown === 1) clearInterval(countDown)
          this.setState({ countDown: this.state.countDown - 1 })
        }, 1000)

        this.setState({ countDownId: countDown })
      })
      toast(<AlertSuccess message={res.data.otp} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  render() {
    return (
      <div className='dashboard-content-box'>
        <div className='dc-body d-flex flex-wrap align-items-lg-center'>
          <div className='mr-box'>
            <div className='mr-head mb-4'>
              {/* <a href="" onClick={(e) => this.props.updateStep(e, 2)}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                      c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                      c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  />
                </svg>
              </a> */}
              <div className='mr-step'>Step 2/2</div>
              <h2>Verify OTP</h2>
            </div>
            <form>
              <div className='form-box'>
                <div className='enter-otp-box'>
                  <p>Please enter OTP sent to phone number</p>
                  <div className='dc-form-box'>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className='row'>
                          <div className='col-md-1'>
                            <input
                              value={this.state.otp.a}
                              className='form-control'
                              id='codeBox1'
                              type='text'
                              name='a'
                              onChange={this.onChange}
                              maxLength='1'
                              ref={this.aRef}
                            />
                          </div>
                          <div className='col-md-1'>
                            <input
                              value={this.state.otp.b}
                              className='form-control'
                              id='codeBox2'
                              type='text'
                              name='b'
                              onChange={this.onChange}
                              maxLength='1'
                              ref={this.bRef}
                            />
                          </div>
                          <div className='col-md-1'>
                            <input
                              value={this.state.otp.c}
                              className='form-control'
                              id='codeBox3'
                              type='text'
                              name='c'
                              onChange={this.onChange}
                              maxLength='1'
                              ref={this.cRef}
                            />
                          </div>
                          <div className='col-md-1'>
                            <input
                              value={this.state.otp.d}
                              className='form-control'
                              id='codeBox4'
                              type='text'
                              name='d'
                              onChange={this.onChange}
                              maxLength='1'
                              ref={this.dRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-md-3'>
                      <div className='time-count'>
                        {this.state.countDown > 0
                          ? ' Code expires in: '
                          : ' Code expired '}
                        <span>
                          {this.state.countDown > 0 &&
                            (this.state.countDown > 9
                              ? '00:' + this.state.countDown
                              : '00:0' + this.state.countDown)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-md-3'>
                    <div className='form-group'>
                      <a
                        onClick={this.doSubmit}
                        href=''
                        type='submit'
                        className='btn btn-primary btn-block'>
                        <b className='btn-loader'></b>Verify
                      </a>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-3'>
                    <div className='resend-box'>
                      <p>
                        Didn't get the code?{' '}
                        <a href='#' onClick={this.reSend}>
                          Resend
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  checkOtp: (param, callback) => dispatch(checkOtp(param, callback)),
  addPatient: (data, callback) => dispatch(addPatient(data, callback)),
})
export default withRouter(connect(null, mapDispatchToProps)(VerifyOtp))
