import React, { Component } from "react";

import { Link } from "react-router-dom";

class Home extends Component {
  state = {};

  componentDidMount = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div class="col-md-12">
        <h6>Links</h6>
        <hr />
        <div class="mb-4">
          <Link to="doctor/sign-up" class="btn btn-lg btn-primary">
            Doctor Sign Up
          </Link>
          <Link to="doctor/sign-in" class="btn btn-lg btn-primary">
            Doctor Sign In
          </Link>
        </div>
        <div class="mb-4">
          <Link to="patient/sign-up" class="btn btn-lg btn-secondary">
            Patient Sign Up
          </Link>
          <Link to="patient/sign-in" class="btn btn-lg btn-secondary">
            Patient Sign In
          </Link>
        </div>

        <div class="mb-4">
          <Link to="patient/chat" class="btn btn-lg btn-secondary">
            Patient Chat
          </Link>
          <Link to="counselor/login" class="btn btn-lg btn-secondary">
            Counsellor Chat
          </Link>
        </div>
        <div class="mb-4">
          {/* <Link to="patient/appointment" class="btn btn-lg btn-warning ">
            Book Appointment
          </Link> */}
          <a
            href="http://testadmin.yesmindy.com"
            class="btn btn-lg btn-warning "
          >
            Admin Panel
          </a>
        </div>
      </div>
    );
  }
}

export default Home;
