import React, { Component } from "react";
import material from "../../../include/images/material-note.svg";
import shareIcon from "../../../include/images/Icon-share.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { loadAllverified } from "../../../store/doctorPatient";
import ReactTooltip from "react-tooltip";

class ToolboxList extends Component {
  componentDidMount = () => {
    this.props.patientSearch();
  };
  render() {
    console.log(this.props.assessment);
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        {this.props.assessment &&
          this.props.assessment.map((e, i) => (
            <li class="np-item">
              <div class="np-box d-md-flex align-items-center justify-content-between">
                <div class="np-left d-flex flex-wrap ">
                  <div class="person-details-box d-flex align-items-center flex-grow-1">
                    <div class="person-text-box">
                      <h3>{e.title}</h3>
                    </div>
                  </div>
                </div>
                <div
                  class="np-right d-flex flex-wrap np-right-test"
                  // style={{ width: "10%" }}
                >
                  <a
                    data-tip="View the document"
                    href="javascript:void(0)"
                    class="np-links"
                    data-toggle="notes"
                    onClick={() =>
                      this.props.toggle(
                        e,
                        e.title,
                        e.header_text,
                        e.footer_text
                      )
                    }
                  >
                    <img src={material} alt="" />
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="np-links"
                    data-toggle="calculator"
                    data-tip="Share with Patients"
                    aria-hidden="true"
                    onClick={() => this.props.toggle3(e._id)}
                  >
                    <img src={shareIcon} alt="" />
                  </a>
                </div>
              </div>
            </li>
          ))}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  patientSearch: () => dispatch(loadAllverified()),
});

export default withRouter(connect(null, mapDispatchToProps)(ToolboxList));
