import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import logo from '../../../include/images/mindy-logo.svg'
import logOut from '../../../include/images/logout-icon.svg'
import notificationIcon from '../../../include/images/notifications-icon.svg'
import downArrow from '../../../include/images/small-down.svg'
import { getAppointment } from '../../../store/appointment'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { loadSeo } from '../../../store/seo'
import { clearAllToken } from '../../services/localStorageServices'
import { Link } from 'react-router-dom'
import {
  getNotification,
  loadCountNotification,
} from '../../../store/notification'

class Header extends Component {
  state = {
    dropDown: false,
    intervalId: 1,
    staffProfile: localStorage.getItem('staffProfile')
      ? JSON.parse(localStorage.getItem('staffProfile'))
      : false,
  }

  toggleDropdown = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ dropDown: !this.state.dropDown })
  }

  logout = (e) => {
    e.preventDefault()
    clearAllToken()
    window.location.href = '/'
  }

  componentDidMount = () => {
    this.props.loadCountNotification()
    this.props.loadDoctorProfile()
    var intervalId = setInterval(() => this.props.loadCountNotification(), 5000)
    this.setState({ intervalId })
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoadin !== prevProps.profileLoading
    ) {
      if (!this.props.doctorProfile.is_approved)
        this.props.history.push('/doctor/sign-up/personal-detail')
    }
  }

  render() {
    const { name, image_url } = this.props.doctorProfile
    return (
      <div
        class="dashboard-top-bar"
        onClick={(e) => {
          e.stopPropagation()
          this.setState({ dropDown: false })
        }}
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <div
                onClick={this.props.toggleSidebar}
                class={
                  this.props.sidebarToggle ? 'menu-toggle on' : 'menu-toggle'
                }
              >
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
              </div>

              <div class="logo-area">
                <Link
                  to={
                    this.props.history.location.pathname.split('/')[1] ===
                    'staff'
                      ? '/staff/dashboard'
                      : '/doctor/dashboard'
                  }
                  class="navbar-brand"
                >
                  <img src={logo} alt="" />
                </Link>
              </div>

              <div class="dtb-mid d-md-flex align-items-md-center">
                <h2 class="flex-grow-1">
                  Welcome,{' '}
                  <strong>
                    {this.state.staffProfile
                      ? this.state.staffProfile.first_name +
                        ' ' +
                        this.state.staffProfile.last_name
                      : name}
                  </strong>
                </h2>
              </div>

              <div class="dtb-right ml-auto">
                <ul class="nav">
                  {this.props.history.location.pathname.split('/')[1] !==
                    'staff' && (
                    <li>
                      <Link to="/doctor/notification" class="notification-box">
                        <img src={notificationIcon} alt="" />
                        <span class="d-none d-lg-block">
                          {this.props.notificationcount.countUnRead
                            ? this.props.notificationcount.countUnRead
                            : 'No'}{' '}
                          New Notifications
                        </span>
                      </Link>
                    </li>
                  )}

                  <li>
                    <div class="custom-menu">
                      <div>
                        <a
                          href="#!"
                          class="profile-menu-box"
                          onClick={this.toggleDropdown}
                        >
                          <div class="profile-text-box d-none d-lg-block">
                            {this.state.staffProfile
                              ? this.state.staffProfile.first_name +
                                ' ' +
                                this.state.staffProfile.last_name
                              : name}
                            <img src={downArrow} alt="" />
                          </div>
                          {this.props.history.location.pathname.split(
                            '/',
                          )[1] !== 'staff' && (
                            <div class="profile-img">
                              <img
                                src={
                                  image_url
                                    ? process.env.REACT_APP_S3URL + image_url
                                    : ''
                                }
                              />
                            </div>
                          )}
                        </a>

                        <div
                          class={
                            this.state.dropDown
                              ? 'custom-dropdown show'
                              : 'custom-dropdown'
                          }
                        >
                          <ul class="menu-list">
                            {this.props.history.location.pathname.split(
                              '/',
                            )[1] !== 'staff' && (
                              <>
                                <li>
                                  <Link to="/doctor/sign-up/personal-detail">
                                    Profile Information
                                  </Link>
                                </li>
                                <li>
                                  <a href="/">Visit Site</a>
                                </li>
                              </>
                            )}
                            <li>
                              <a href="#!" onClick={this.logout}>
                                <b>
                                  <img src={logOut} alt="" />
                                  Logout
                                </b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
  loadCountNotification: () => dispatch(loadCountNotification()),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
})
const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  notificationcount: getNotification(state).count,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
