import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";
import { Link } from "react-router-dom";
import { getMisc, loadConstant } from "../../store/misc";
import image1 from "../../include/images/cb-right-img.jpg";
import image2 from "../../include/images/cb-left-img.jpg";
import image3 from "../../include/images/tick-check.svg";
import banner from "../../include/images/software-img.svg";
import conntect from "../../include/images/brain-icon.svg";
import pannel from "../../include/images/cmmunication-img1.png";
import icons1 from "../../include/images/cmmunicate-img2.png";
import work1 from "../../include/images/work-img1.svg";
import work2 from "../../include/images/work-img2.svg";
import work3 from "../../include/images/work-img3.svg";
import work4 from "../../include/images/work-img4.svg";
import work5 from "../../include/images/work-img5.svg";
import work6 from "../../include/images/work-img6.svg";
import work7 from "../../include/images/work-img7.svg";
import Slider from "react-slick";
import { loadDoctorFeatured, getDoctor } from "../../store/doctor";

const mobileSlider = {
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  infinite: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 5000,
      settings: {
        arrows: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        dots: false,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 579,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
  ],
};
class Services extends Component {
  state = {
    initSlider: false,
    sliderSetting: {
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: false,
      className:
        "new-service-list d-flex flex-wrap justify-content-center align-items-center services-slider",

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 774,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 501,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    tabPane: false,
    modelShowVideo: false,
    loading: false,
    error: false,
    formError: false,
    navBar: false,
  };

  componentDidMount = () => {
    this.props.loadConstant();
    setTimeout(() => {
      this.setState({ initSlider: true });
    }, 500);
    this.props.loadDoctorFeatured("featured_on_home");
  };

  render() {
    const { constants, blogCategory, blog, loadingBlogCategory } =
      this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const { featured } = this.props;

    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div className="content-container gradient-bg">
            <div className="container container1">
              <div className="row">
                <div class="col-lg-12">
                  <Slider {...mobileSlider}>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work1} alt="" />
                        </div>
                        <p>Confidential and Private</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work2} alt="" />
                        </div>
                        <p>HIPPA Compliance</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work3} alt="" />
                        </div>
                        <p>Telemedicine Oriented</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work4} alt="" />
                        </div>
                        <p>Licensed Professional</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work5} alt="" />
                        </div>
                        <p>Audio/Video Consult</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work6} alt="" />
                        </div>
                        <p>Virtual Patient Caretaker</p>
                      </div>
                    </li>
                    <li class="new-service-item">
                      <div class="new-service-box d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="new-sb-img">
                          <img src={work7} alt="" />
                        </div>
                        <p>Devoted To Mental Health</p>
                      </div>
                    </li>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container block-container">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="block-content-outer">
                    <div class="block-content-box d-lg-flex flex-wrap align-items-center mob-pt-0">
                      <div class="block-left">
                        <ul class="bl-list d-flex flex-wrap align-items-center justify-content-center">
                          <li class="bl-item d-flex align-items-center justify-content-center mob-mb-20">
                            <div class="consult-img-box">
                              <img src={pannel} alt="" />
                            </div>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center mob-mb-20">
                            <div class="consult-img-box">
                              <img src={icons1} alt="" />
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="block-right mob-full-width ">
                        <h5>Tele-consultation</h5>
                        <p>
                          Feel free to connect over Video or audio calls to
                          consult licensed Psychiatrists and Psychologists via
                          our dedicated Mental Health platform. We assure
                          privacy through this Virtual OPD and end-to-end
                          support for individuals, couples, caretakers on behalf
                          of their patients, etc.
                        </p>{" "}
                        <Link
                          to={
                            this.state.patient
                              ? "patient/doctors"
                              : "patient/appointment"
                          }
                          class="btn btn-default"
                        >
                          Book Consultation
                        </Link>
                      </div>
                    </div>
                    <div class="block-content-box d-lg-flex flex-wrap align-items-center reverse pb-0">
                      <div class="block-left">
                        <ul class="bl-list d-flex flex-wrap align-items-center justify-content-center">
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={conntect} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6> Keeps Mental health Records</h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={conntect} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Interdisciplinary referrals</h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={conntect} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Psycho Education </h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={conntect} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Order Medicines</h6>
                          </li>
                        </ul>
                      </div>
                      <div class="block-right mob-full-width ">
                        <h5>Extensive Dashboard</h5>
                        <p>
                          A specialized scientific dashboard designed for mental
                          health clients by our experts. We integrate all tools
                          for holistic consultation to enhance quality care and
                          complete recovery. Our dashboard engages with you in
                          your journey from start to end with features such as a
                          treatment tracker, assessment scales, thought
                          recording, and more.
                        </p>{" "}
                        <Link to={"/patient/sign-up"} class="btn btn-default">
                          Try It Out
                        </Link>
                      </div>
                    </div>
                    <div class="heading2 d-flex align-items-center">
                      <h5>For Mental Health Professionals</h5>{" "}
                      <span class="h-line"></span>
                    </div>

                    <div class="block-content-box d-lg-flex flex-wrap align-items-center reverse  bg-box py-0">
                      <div class="block-left">
                        <ul class="bl-list d-flex flex-wrap align-items-center justify-content-center">
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={banner} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>MANAGEs all Appointments </h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={banner} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Patient Data-keeping</h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={banner} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Specialised Templates</h6>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center">
                            <div class="bl-icon">
                              <img src={banner} alt="" />
                              <div class="bl-shape"></div>
                            </div>
                            <h6>Holistic treatment</h6>
                          </li>
                        </ul>
                      </div>
                      <div class="block-right mob-full-width ">
                        <h5>Practice Management Software </h5>
                        <p>
                          Establish your practice online along with the private
                          OPD as we enhance efficiency and output towards
                          patients through our Practice Management Software. The
                          software creates a deeper engagement for patients to
                          prevent dropouts while motivating them forward.
                        </p>{" "}
                        <Link to={"/doctor/sign-up"} class="btn btn-default">
                          Try It Out
                        </Link>
                      </div>
                    </div>

                    <div class="heading2 d-flex flex-wrap align-items-center">
                      <h5>For Business</h5> <span class="h-line"></span>
                    </div>
                    <div class="block-content-box d-lg-flex flex-wrap align-items-center pt-0 ">
                      <div class="block-left">
                        <ul class="bl-list d-flex flex-wrap align-items-center justify-content-center check-icon-list">
                          <li class="bl-item d-flex flex-wrap ">
                            <div class="bl-box d-flex align-items-center">
                              <div class="bl-img">
                                <img src={image3} alt="" />
                              </div>
                              <h3>REDUCED Absenteeism</h3>
                            </div>
                          </li>
                          <li class="bl-item d-flex flex-wrap  ">
                            <div class="bl-box d-flex align-items-center ">
                              <div class="bl-img">
                                <img src={image3} alt="" />
                              </div>
                              <h3>POSITIVE WORK Environment</h3>
                            </div>
                          </li>
                          <li class="bl-item d-flex flex-wrap ">
                            <div class="bl-box d-flex align-items-center">
                              <div class="bl-img">
                                <img src={image3} alt="" />
                              </div>
                              <h3>INCREASED Productivity </h3>
                            </div>
                          </li>
                          <li class="bl-item d-flex flex-wrap">
                            <div class="bl-box d-flex align-items-center">
                              <div class="bl-img">
                                <img src={image3} alt="" />
                              </div>
                              <h3>LESS EMPLOYMENT TURNOVER </h3>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="block-right mob-full-width ">
                        <h5>Corporate Support </h5>
                        <p>
                          Work becomes a burden on many employees’ minds, hence
                          we urge corporate companies to begin EAP (Employee
                          Assistance Program). Employers can take the first step
                          towards professional and customized Mental Health
                          solutions for their employees and their family.
                        </p>
                      </div>
                    </div>

                    <div class="block-content-box d-lg-flex flex-wrap align-items-center bg-box">
                      <div class="block-left">
                        <ul class="bl-list d-flex flex-wrap align-items-center justify-content-center">
                          <li class="bl-item d-flex align-items-center justify-content-center mob-mb-20">
                            <div class="bl-img-box">
                              <div
                                class="block-bg d-flex align-items-end"
                                style={{ backgroundImage: `url(${image2})` }}
                              >
                                <div class="block-text ">
                                  <div class="block-text-box">
                                    <h4>For Health Professionals</h4>
                                  </div>
                                  <div class="block-btn ">
                                    <Link
                                      to={{
                                        pathname: "/clinical-community",
                                        join: "Join",
                                      }}
                                      className="btn btn-default"
                                    >
                                      Join Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl-item d-flex align-items-center justify-content-center mob-mb-20">
                            <div class="bl-img-box">
                              <div
                                class="block-bg d-flex  align-items-end"
                                style={{ backgroundImage: `url(${image1})` }}
                              >
                                <div class="block-text ">
                                  <div class="block-text-box">
                                    <h4>Become a Voice For Mental Health</h4>
                                  </div>
                                  <div class="block-btn ">
                                    <Link
                                      to={{
                                        pathname: "/clinical-community",
                                        join: "Join",
                                      }}
                                      className="btn btn-default"
                                    >
                                      Join Now
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="block-right mob-full-width ">
                        <h5>Mental Health Community </h5>
                        <p>
                          A safe space to fit in while sharing stories with
                          people alike. Volunteers, certified counsellors can
                          initiate webinars, group sessions, therapy, etc. for
                          various environments. Chat anonymously with
                          counsellors for guidance or queries. Let’s bring
                          people closer to professionals to build a future
                          support system while destigmatizing mental illness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  featured: getDoctor(state).featured,
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadDoctorFeatured: (params) => dispatch(loadDoctorFeatured(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
