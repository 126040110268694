import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import mentalHealth from "../components/doctor/dashboard/caseRecords/mentalHealth";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "caseRecord",
  initialState: {
    demographicsAdd: {},
    metalHealth: {},
    problemgroups: [],
    problemData: {},
    reliability: [],
    diagnosis: [],
    diagnosisp: [],
    caserecord: {
      assessment: {
        psychiatric_psychological: { previous_diagnosis: { diagnosis: {} } },
      },
      log: [],
    },
    loading: false,
    diagnosisLoading: false,
    caseRecordLoading: false,
    lastFetch: null,
  },
  reducers: {
    problemRequested: (caseRecord, action) => {
      caseRecord.loading = true;
    },
    problemRecieved: (caseRecord, action) => {
      caseRecord.problemgroups = action.payload.data;
      caseRecord.loading = false;
      caseRecord.lastFetch = Date.now();
    },
    problemRequestFailed: (caseRecord, action) => {
      caseRecord.problemgroups = [];
      caseRecord.loading = false;
    },
    problemDataRequested: (caseRecord, action) => {
      caseRecord.loading = true;
    },
    problemDataRecieved: (caseRecord, action) => {
      caseRecord.problemData = action.payload.data;
      caseRecord.loading = false;
      caseRecord.lastFetch = Date.now();
    },
    problemDataRequestFailed: (caseRecord, action) => {
      caseRecord.problemData = {};
      caseRecord.loading = false;
    },
    diagnosisRequested: (caseRecord, action) => {
      caseRecord.diagnosisLoading = true;
    },
    diagnosisRecieved: (caseRecord, action) => {
      caseRecord.diagnosis = action.payload.data;
      caseRecord.diagnosisLoading = false;
      caseRecord.lastFetch = Date.now();
    },
    diagnosisRequestFailed: (caseRecord, action) => {
      caseRecord.diagnosisLoading = false;
    },

    diagnosisRequestedp: (caseRecord, action) => {
      caseRecord.diagnosisLoading = true;
    },
    diagnosisRecievedp: (caseRecord, action) => {
      caseRecord.diagnosisp = action.payload.data;
      caseRecord.diagnosisLoading = false;
      caseRecord.lastFetch = Date.now();
    },
    diagnosisRequestFailedp: (caseRecord, action) => {
      caseRecord.diagnosisLoading = false;
    },

    reliabilityRequested: (caseRecord, action) => {
      caseRecord.loading = true;
    },
    reliabilityRecieved: (caseRecord, action) => {
      caseRecord.reliability = action.payload.data;
      caseRecord.loading = false;
      caseRecord.lastFetch = Date.now();
    },
    reliabilityRequestFailed: (caseRecord, action) => {
      caseRecord.reliability = [];
      caseRecord.loading = false;
    },
    problemRequested: (caseRecord, action) => {
      caseRecord.loading = true;
    },
    problemRecieved: (caseRecord, action) => {
      caseRecord.problemgroups = action.payload.data;
      caseRecord.loading = false;
      caseRecord.lastFetch = Date.now();
    },
    problemRequestFailed: (caseRecord, action) => {
      caseRecord.problemgroups = [];
      caseRecord.loading = false;
    },
    caseRecordRequested: (caseRecord, action) => {
      caseRecord.caseRecordLoading = true;
    },
    caseRecordRecieved: (caseRecord, action) => {
      caseRecord.caserecord = action.payload.data ? action.payload.data : {};
      caseRecord.caseRecordLoading = false;
      caseRecord.lastFetch = Date.now();
    },
    caseRecordRequestFailed: (caseRecord, action) => {
      caseRecord.caserecord = {};
      caseRecord.caseRecordLoading = false;
    },
    demographicsAdded: (caseRecord, action) => {
      caseRecord.demographicsAdd = action.payload;
      caseRecord.loading = false;
      caseRecord.lastFetch = Date.now();
    },

    demographicsAddRequestFailed: (caseRecord, action) => {
      caseRecord.loading = false;
    },
  },
});

export const {
  demographicsAdded,
  demographicsAddRequestFailed,

  problemRecieved,
  problemRequestFailed,
  problemRequested,
  caseRecordRecieved,
  caseRecordRequestFailed,
  caseRecordRequested,
  reliabilityRecieved,
  reliabilityRequestFailed,
  reliabilityRequested,
  diagnosisRecieved,
  diagnosisRequestFailed,
  diagnosisRequested,

  diagnosisRecievedp,
  diagnosisRequestFailedp,
  diagnosisRequestedp,
  problemDataRecieved,
  problemDataRequestFailed,
  problemDataRequested,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "caserecord/";

export const addDemographics = (param, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url + "form/" + id,
      method: "post",
      data: param,
      onSuccess: demographicsAdded.type,
      onError: demographicsAddRequestFailed.type,
    })
  );
};

export const loadProblem = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "problemgroups",
      onStart: problemRequested.type,
      onSuccess: problemRecieved.type,
      onError: problemRequestFailed.type,
    })
  );
};
export const loadCaseRecord = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "form/" + id,
      onStart: caseRecordRequested.type,
      onSuccess: caseRecordRecieved.type,
      onError: caseRecordRequestFailed.type,
    })
  );
};
export const loadProblemData = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "problemgroups/" + id,
      onStart: problemDataRequested.type,
      onSuccess: problemDataRecieved.type,
      onError: problemDataRequestFailed.type,
    })
  );
};
export const loadDiagnosis = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "diagnosis/" + params,
      onStart: diagnosisRequested.type,
      onSuccess: diagnosisRecieved.type,
      onError: diagnosisRequestFailed.type,
    })
  );
};
export const loadDiagnosisp = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "diagnosis/" + params,
      onStart: diagnosisRequestedp.type,
      onSuccess: diagnosisRecievedp.type,
      onError: diagnosisRequestFailedp.type,
    })
  );
};
export const loadReliability = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "reliability",
      onStart: reliabilityRequested.type,
      onSuccess: reliabilityRecieved.type,
      onError: reliabilityRequestFailed.type,
    })
  );
};
export const getCaserecord = createSelector(
  (state) => state.entities.caseRecord,
  (caseRecord) => caseRecord
);
