import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../common/form/form";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import ReactTooltip from "react-tooltip";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorToken,
  setDoctorToken,
} from "../../services/localStorageServices";
import {
  clinicAddressRequired,
  cityRequired,
  stateRequired,
  picsRequired,
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  nameRequired,
  zipRequired,
  phoneRequired,
  phoneInvalid,
} from "../../common/misc";
import FileInput from "../../common/form/fileInput";
import { getMisc, loadCity, loadState } from "../../../store/misc";
import seUploadFun from "../../services/s3Services";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";
class Clinic extends Form {
  state = {
    stateOnchange: false,
    states: [],
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      phone: "",
      is_available: false,
      name: "",
      clinicaddress: "",
      city: "",
      state: "",
      zipcode: "",
      pics: [],
    },
    errors: {},
  };

  schema = {
    is_available: Joi.any(),
    phone: Joi.number()
      .required()
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          console.log("errerr", err);
          switch (err.type) {
            case "any.required":
              err.message = phoneRequired;
              break;
            case "number.base":
              err.message = phoneInvalid;
              break;

            case "number.max":
              err.message = phoneInvalid;
              break;

            case "number.min":
              err.message = phoneInvalid;
              break;
          }
        });
        return errors;
      }),
    name: Joi.string()
      .required()
      .error(() => {
        return { message: nameRequired };
      }),
    clinicaddress: Joi.string()
      .required()
      .error(() => {
        return { message: clinicAddressRequired };
      }),
    city: Joi.string()
      .required()
      .error(() => {
        return { message: cityRequired };
      }),
    state: Joi.string()
      .required()
      .error(() => {
        return { message: stateRequired };
      }),

    zipcode: Joi.string()
      .required()
      .error(() => {
        return { message: zipRequired };
      }),

    pics: Joi.array()
      .items(Joi.string().required())
      .required()
      .error(() => {
        return { message: picsRequired };
      }),
  };

  remoVeImg = (e, i) => {
    e.preventDefault();
    const data = this.state.data;
    const pics = data.pics;
    data.pics = pics.filter((p) => p !== i);
    this.setState({ data });
  };

  fileUpload = (e) => {
    const _id = this.props.doctorProfile._id;
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      const fileName =
        "doctor/" +
        _id +
        "/clinic/" +
        Date.now() +
        "-" +
        element.lastModified +
        "-" +
        element.size;
      const res = seUploadFun(element, fileName);
      res.then((result) => {
        const { data, errors } = this.state;
        errors.pics = "";
        data.pics = [result.key];
        this.setState({ data, errors });
      });
    }
  };

  doSubmit = () => {
    const {
      is_available,
      name,
      clinicaddress: address,
      city: cityId,
      pics: photos,
      zipcode,
      phone,
    } = this.state.data;
    const payLoad = {
      clinic_details: {
        is_available,
        zipcode,
        name,
        address,
        cityId,
        photos,
      },
    };
    if (phone) payLoad.clinic_details.phone = phone;

    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(payLoad, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/associations");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
      this.props.loadState();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    window.$("#state").niceSelect("update");
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const {
        address,
        cityId,
        is_available,
        name,
        photos,
        state,
        zipcode,
        phone,
      } = this.props.doctorProfile.clinic_details;
      const data = {
        is_available,
        name,
        clinicaddress: address,
        city: cityId,
        state,
        zipcode,
        phone: is_available ? "" : phone,
        pics: photos.map((e) => e),
      };
      if (is_available) {
        this.schema.name = Joi.any();
        this.schema.pics = Joi.any();
        this.schema.phone = Joi.any();
        this.schema.clinicaddress = Joi.any();
      }
      this.props.loadState();
      this.props.loadCity({ state: data.state });
      this.setState({ data });
    }
    if (
      !this.props.getMisc.loadingState &&
      this.props.getMisc.loadingState !== prevProps.getMisc.loadingState
    ) {
      const states = this.props.getMisc.state.map((ele) => ({
        _id: ele,
        name: ele,
      }));
      this.setState({ states }, () => {
        window.$("#state").niceSelect("update");
        window.$(".state").on("change", () => {
          const data = this.state.data;
          if (data.state !== window.$("#state").val()) {
            data.state = window.$("#state").val();
            this.setState({ data });
            this.props.loadCity({ state: data.state });
          }
        });
      });
    }

    if (
      !this.props.getMisc.loadingCity &&
      this.props.getMisc.loadingCity !== prevProps.getMisc.loadingCity
    ) {
      const states = this.props.getMisc.state.map((ele) => ({
        _id: ele,
        name: ele,
      }));
      this.setState({ states }, () => {
        window.$("#city").niceSelect("update");
        window.$(".city").on("change", () => {
          const data = this.state.data;
          if (data.city !== window.$("#city").val()) {
            data.city = window.$("#city").val();
            this.setState({ data });
          }
        });
      });
    }
    window.$("#state").niceSelect();
    window.$(".state").on("change", () => {
      const data = this.state.data;
      if (data.state !== window.$("#state").val()) {
        data.state = window.$("#state").val();
        this.setState({ data });
        this.props.loadCity({ state: data.state });
      }
    });

    window.$("#city").niceSelect();
    window.$(".city").on("change", () => {
      const data = this.state.data;
      if (data.city !== window.$("#city").val()) {
        data.city = window.$("#city").val();
        this.setState({ data });
      }
    });
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    return (
      <React.Fragment>
        <ReactTooltip className="testPurple" />
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />

          <div className="container ml-0">
            <div className="main-heading">
              <h1>Clinic Details</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheck1"
                          name="is_available"
                          onChange={() => {
                            const { data } = this.state;
                            data.is_available = !data.is_available;
                            data.name = "";
                            data.clinicaddress = "";
                            data.pics = [];
                            data.phone = "";
                            this.setState({ data, errors: {} });
                            if (data.is_available) {
                              this.schema.phone = Joi.any();
                              this.schema.name = Joi.any();
                              this.schema.pics = Joi.any();
                              this.schema.clinicaddress = Joi.any();
                            } else {
                              this.schema.phone = Joi.number()
                                .required()
                                .min(1000000000)
                                .max(999999999999999)
                                .error((errors) => {
                                  errors.forEach((err) => {
                                    console.log("errerr", err);
                                    switch (err.type) {
                                      case "any.required":
                                        err.message = phoneRequired;
                                        break;
                                      case "number.base":
                                        err.message = phoneInvalid;
                                        break;

                                      case "number.max":
                                        err.message = phoneInvalid;
                                        break;

                                      case "number.min":
                                        err.message = phoneInvalid;
                                        break;
                                    }
                                  });
                                  return errors;
                                });
                              this.schema.name = Joi.string()
                                .required()
                                .error(() => {
                                  return { message: nameRequired };
                                });
                              this.schema.pics = Joi.array()
                                .items(Joi.string().required())
                                .required()
                                .error(() => {
                                  return { message: picsRequired };
                                });
                              this.schema.clinicaddress = Joi.string()
                                .required()
                                .error(() => {
                                  return { message: clinicAddressRequired };
                                });
                            }
                          }}
                          checked={this.state.data.is_available}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheck1"
                        >
                          Click here if you don't own or perform consultation in
                          any clinic
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput(
                      "name",
                      "Name",
                      "text",
                      this.state.data.is_available
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput(
                      "phone",
                      "Phone Number",
                      "text",
                      this.state.data.is_available
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput(
                      "clinicaddress",
                      "Clinic Address",
                      "text",
                      this.state.data.is_available
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderSelect("state", "State", this.state.states)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3">
                    {this.renderSelect(
                      "city",
                      "City",
                      this.props.getMisc.city.map((ele) => ({
                        _id: ele._id,
                        name: ele.city,
                      }))
                    )}
                  </div>
                  <div className="col-xl-3">
                    {this.renderInput("zipcode", "Zip Code", "text")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <FileInput
                      totalUploaded={this.state.data.pics.length}
                      error={this.state.errors["pics"]}
                      label="Upload your clinic logo here if available"
                      name="pics"
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["pics"]}
                      disabled={this.state.data.is_available}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="imagePreviewBox">
                      {this.state.data.pics.map((e) => (
                        <div className="imagePreview">
                          <a
                            href="#"
                            onClick={(ele) => this.remoVeImg(ele, e)}
                            className="imagePreviewClose"
                          >
                            <i className="fas fa-times"></i>{" "}
                          </a>
                          <img
                            className="imageClinic"
                            width="180px"
                            src={process.env.REACT_APP_S3URL + e}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadCity: (data) => dispatch(loadCity(data)),
  loadState: () => dispatch(loadState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clinic);
