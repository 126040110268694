import React, { Component } from "react";
import { Link } from "react-router-dom";
import iconImg from "../../../include/images/border-check-icon.svg";

class ForgetPasswordCompleted extends Component {
  state = {};
  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center justify-content-center text-center">
          <div className="mr-box">
            <div className="form-check-icon">
              <img src={iconImg} alt="" />
            </div>
            <h3>Your password has been set successfully!</h3>
            <p className="mb-5">Please login to view dashboard.</p>
            <form action="index.html">
              <Link className="btn btn-primary btn-block" to="sign-in">
                Ok
              </Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgetPasswordCompleted;
