import React, { Component } from 'react'
import arrowtr from './../../../../include/images/arrow-tr.svg'

import locationIcon from '../../../../include/images/location-icon.svg'
import PatientPdf from '../../../../include/images/Patientbrochure_YesMindy.pdf'
import _ from 'lodash'

import moment from 'moment'

class OrderReview extends Component {
  state = {}
  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  render() {
    const clinic = this.props?.clinic ? this.props?.clinic : false
    console.log('this.props', this.props)
    return (
      <div className='side-pannel-box'>
        <div className='tab-sidebar-top d-flex align-items-center'>
          <a
            href='#!'
            className='close'
            onClick={e => {
              e.preventDefault()
              this.props.changePreview(false)
            }}>
            <div className='icon-img'>
              <img src={arrowtr} alt='' />
            </div>
          </a>
          <h6>Review &amp; Pay</h6>
        </div>
        <div className='pannel-tab-box'>
          <div className='card-box'>
            <div className='cb-upper d-flex flex-wrap align-items-start'>
              <div className='cb-upper-content d-flex flex-wrap'>
                <div className='cb-img-box'>
                  <a
                    href={'/dr/' + this.props.doctorProfile.user_name}
                    target='_blank'>
                    <img
                      src={
                        this.props.doctorProfile !== '' &&
                        process.env.REACT_APP_S3URL +
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].image_url
                      }
                      alt=''
                    />
                  </a>
                </div>
                <div class='cb-text-box'>
                  <div class='doc-intro-desc  font-montserrat'>
                    <h5>
                      {' '}
                      <a
                        href={'/dr/' + this.props.doctorProfile.user_name}
                        target='_blank'>
                        {this.props.doctorProfile !== '' &&
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].name}
                      </a>
                    </h5>
                    <h6>
                      {this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].graduation &&
                        this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].graduation.education}

                      {this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].post_graduation &&
                        ', ' +
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].post_graduation.education}

                      {this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].super_speciality &&
                        ', ' +
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].super_speciality.education}
                    </h6>

                    <h6>
                      {' '}
                      {
                        this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].field_type.name
                      }
                    </h6>
                    {this.props.getDoctor.doctors.data[this.props.doctorProfile]
                      .certification && (
                      <h6>
                        {' '}
                        {this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].certification.length > 0 &&
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].certification[0].title +
                            '(' +
                            this.props.getDoctor.doctors.data[
                              this.props.doctorProfile
                            ].certification[0].place +
                            ')'}
                      </h6>
                    )}

                    <h6>
                      {' '}
                      {
                        this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].total_experience
                      }{' '}
                      Years Experience
                    </h6>
                    {this.props.getDoctor.doctors.data[this.props.doctorProfile]
                      .practice_preferences.language.length > 0 && (
                      <h6>
                        Speaks:{' '}
                        {this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].practice_preferences.language.map((e, i) => (
                          <>
                            <span>{e}</span>
                            {this.props.getDoctor.doctors.data[
                              this.props.doctorProfile
                            ].practice_preferences.language.length -
                              1 !==
                              i && ', '}
                          </>
                        ))}
                      </h6>
                    )}
                    <div class='doc-loc-name d-flex align-items-center'>
                      <div class='location-pin-icon'>
                        <img src={locationIcon} alt='' />
                      </div>{' '}
                      <span>
                        {this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].clinic_details &&
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].clinic_details.city}
                        ,{' '}
                        {this.props.getDoctor.doctors.data[
                          this.props.doctorProfile
                        ].clinic_details &&
                          this.props.getDoctor.doctors.data[
                            this.props.doctorProfile
                          ].clinic_details.state}
                      </span>
                    </div>
                    <div class='doc-fee-desc'>
                      {this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].schedules.clinicFee &&
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].schedules.clinicFee > 0 ? (
                        <div class='d-flex align-items-center'>
                          {' '}
                          <strong>Physical Fee: </strong>
                          <ul class='fee-list d-flex align-items-center'>
                            <li>
                              ₹{' '}
                              {
                                this.props.getDoctor.doctors.data[
                                  this.props.doctorProfile
                                ].schedules.clinicFee
                              }
                            </li>
                            <li>
                              {
                                this.props.getDoctor.doctors.data[
                                  this.props.doctorProfile
                                ].schedules.time
                              }
                              mins
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}

                      {this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].schedules.onlineFee &&
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].schedules.onlineFee > 0 ? (
                        <div class='d-flex align-items-center'>
                          {' '}
                          <strong>Online Fee: </strong>
                          <ul class='fee-list d-flex align-items-center'>
                            <li>
                              ₹{' '}
                              {
                                this.props.getDoctor.doctors.data[
                                  this.props.doctorProfile
                                ].schedules.onlineFee
                              }
                            </li>
                            <li>
                              {
                                this.props.getDoctor.doctors.data[
                                  this.props.doctorProfile
                                ].schedules.time
                              }
                              mins
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pannel-intro-box'>
            {/* <div className="pi-head d-flex align-items-center">
              <div className="pi-img-box">
                <img
                  src={
                    this.props.doctorProfile !== "" &&
                    process.env.REACT_APP_S3URL +
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].image_url
                  }
                  alt=""
                />
              </div>
              <div className="pi-text-box">
                <h4>
                  Dr.{" "}
                  {this.props.doctorProfile !== "" &&
                    this.props.getDoctor.doctors.data[this.props.doctorProfile]
                      .name}
                </h4>
                <div className="pi-exp d-flex align-items-center">
                  <div className="mr-md-3 mr-1">
                    <img src={sstar} alt="" />
                  </div>
                  <span>
                    {this.props.doctorProfile !== "" &&
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].total_experience}{" "}
                    years of experience
                  </span>
                </div>
                {this.props.doctorProfile !== "" &&
                  this.props.getDoctor.doctors.data[this.props.doctorProfile]
                    .schedules.time && (
                    <div className="pi-fee-box">
                      <ul className="fee-list d-flex align-items-left flex-lg-column text-right">
                        <li>
                          <b>
                            <span>Online: ₹</span>
                            {this.props.doctorProfile !== "" &&
                              this.props.getDoctor.doctors.data[
                                this.props.doctorProfile
                              ].schedules.onlineFee}
                            , <span>Clinic: ₹</span>
                            {this.props.doctorProfile !== "" &&
                              this.props.getDoctor.doctors.data[
                                this.props.doctorProfile
                              ].schedules.clinicFee}
                          </b>
                        </li>
                        <li>
                          {this.props.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.props.doctorProfile
                            ].schedules.time}{" "}
                          min
                        </li>
                      </ul>
                    </div>
                  )}
                <div className="pi-location d-none d-lg-flex align-items-center">
                  <div className="mr-3">
                    <img src={pinImg} alt="" />
                  </div>
                  <span>
                    {this.props.doctorProfile !== "" &&
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].clinic_details.city}
                    ,
                  </span>{" "}
                  <span>
                    {" "}
                    {this.props.doctorProfile !== "" &&
                      this.props.getDoctor.doctors.data[
                        this.props.doctorProfile
                      ].clinic_details.state}{" "}
                  </span>
                </div>
              </div>
            </div> */}
            <div className='separator'></div>
            <div className='pi-body d-flex flex-wrap'>
              <div className='pi-left'>
                <h3>Date &amp; Timing</h3>
                <p>
                  {new moment(this.props.orderDetail?.ret?.appointment_time)
                    .utc()
                    .date()}{' '}
                  {
                    this.props.months[
                      new moment(this.props.orderDetail?.ret?.appointment_time)
                        .utc()
                        .month()
                    ]
                  }{' '}
                  |{' '}
                  {this.formatAMPM(
                    new Date(this.props.orderDetail?.ret?.appointment_time)
                  )}
                </p>
              </div>

              <div className='pi-right'>
                <h3>Type of visit</h3>
                <p>
                  {_.capitalize(this.props.orderDetail.ret?.appointment_type)}
                </p>
              </div>
            </div>
            <div className='pi-body d-flex flex-wrap'>
              <div className='pi-left'>
                <ul>
                  <li>
                    <p>Consultation Charges</p>
                  </li>
                  {!clinic && (
                    <>
                      <li>
                        <p>
                          Personal Dashboard Fee
                          <i
                            data-tip='You can message your experts for up to 3 days after every appointment.'
                            class='fas fa-info-circle ml-2'
                            aria-hidden='true'
                            style={{
                              color: 'rgb(24 0 88 / 50%)',
                              fontSize: '12px',
                            }}
                            onClick={() => {
                              window.open(PatientPdf)
                            }}></i>
                        </p>
                      </li>
                      <li>
                        <p>GST</p>
                      </li>
                      <li>
                        <p>Total</p>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className='pi-right'>
                <ul>
                  <li>
                    <p>
                      ₹{' '}
                      {this.props.orderDetail.ret?.consultation_charges.toFixed(
                        2
                      )}
                    </p>
                  </li>
                  {!clinic && (
                    <>
                      <li>
                        <p>
                          ₹{' '}
                          {this.props.orderDetail.ret?.convenience_charges.toFixed(
                            2
                          )}
                        </p>
                      </li>
                      <li>
                        <p>₹ {this.props.orderDetail.ret?.taxes.toFixed(2)}</p>
                      </li>
                      <li>
                        <p>₹ {this.props.orderDetail.ret?.amount.toFixed(2)}</p>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className='separatorNew'></div>
            <div className='pi-body d-flex flex-wrap'>
              <p style={{ fontSize: '11px', marginBottom: 0 }}>
                {clinic
                  ? '*Your Booking Is Confirmed - Please Pay At The Clinic'
                  : '*Cancellation is permitted only up to 12 hrs prior to the scheduled time of appointment'}
              </p>
              <p
                className='boldFont'
                style={{ fontSize: '11px', cursor: 'pointer' }}
                onClick={() => {
                  window.open(PatientPdf)
                }}>
                What is my personal dashboard?
              </p>
            </div>

            <a
              href='#!'
              className='btn btn-primary'
              onClick={this.props.payNow}>
              {clinic
                ? 'Confirm'
                : `Pay ₹ ${this.props.orderDetail.ret?.amount.toFixed(2)}`}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderReview
