import React, { Component } from "react";
import { Link } from "react-router-dom";
import Expert from "./common/expert";
import Thoughts from "./common/thoughts";
import {
  getMisc,
  loadConstant,
  loadBlog,
  customerQuery,
  loadFaq,
} from "../../store/misc";
import Footer from "./footer";
import Blog from "./common/blog";
import Header from "../home/header";
import { connect } from "react-redux";
import icon1 from "../../include/images/prowess-icon1.svg";
import icon2 from "../../include/images/prowess-icon2.svg";
import icon5 from "../../include/images/prowess-icon5.svg";
import icon3 from "../../include/images/prowess-icon3.svg";
import icon4 from "../../include/images/prowess-icon4.svg";
import icon6 from "../../include/images/prowess-icon6.svg";
import image1 from "../../include/images/clinician-psychologist-hero-img.png";

class Psychologist extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    toggle1: false,
    id: "",
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadFaq({ category: "Psychologist" });
    this.props.loadBlog({ page_title: "PSYCHOLOGIST", published: true });
  };
  toggle1 = (id) => {
    this.setState({
      id: id,
      toggle1: !this.state.toggle1,
    });
  };

  render() {
    const { constants, blog, faq } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const home = blog.filter((i) => i.published === true).slice(0, 3);

    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div className="outer-container">
          <div className="clinician-hero-container">
            <div className="container container1">
              <div className="row flex-row-reverse align-items-end">
                <div className="col-xl-4 offset-xl-1 col-lg-5">
                  <div className="clinician-hero-content">
                    <h3 className="d-block d-md-none">PSYCHOLOGIST</h3>
                    <h4>
                      Our professionals put themselves in your shoes to help you
                      find the light in darkness, empathetically.
                    </h4>
                    <Link
                      to={
                        this.state.patient
                          ? "patient/doctors"
                          : "patient/appointment"
                      }
                      class="btn btn-white btn-xl"
                    >
                      Book an appointment
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="clinician-hero-img">
                    <img src={image1} alt="" />
                    <div className="clinician-dot dot1">
                      <span>PSYCHIATRIST</span>
                    </div>
                    <div className="clinician-dot dot2">
                      <span>COUNSELLOR</span>
                    </div>
                    <div className="clinician-dot dot3">
                      <span>PSYCHOSOCIAL</span>
                    </div>
                    <div className="clinician-dot dot4 active">
                      <span>PSYCHOLOGIST</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Expert />
        <div className="content-container prowess-container">
          <div className="container container1">
            <div className="row d-flex align-items-center">
              <div className="col-lg-4">
                <div className="prowess-text-box text-center text-lg-left">
                  <h3>
                    Virtual Therapy <span>Prowess</span>
                  </h3>
                  <p>
                    Our licensed clinical Psychologists provide scientifically
                    proven therapy to treat disorders via an accurate diagnosis,
                    detailed assessments, and forming a treatment plan through
                    well designed sessions. We help you cope with your struggles
                    and help you find the light at the end of the ever-dark
                    tunnel.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="prowess-img-box">
                  <ul className="prowess-list d-flex flex-wrap align-items-center justify-content-between">
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon1} alt="" />
                      </div>
                      <h6>
                        Trained digital practitioners for <br />
                        accurate tele-communication
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon6} alt="" />
                      </div>
                      <h6>
                        Trained & well-equipped for <br />
                        virtual therapy
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon4} alt="" />
                      </div>
                      <h6>
                        Holistic teamwork approach for <br />
                        end-to-end support
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon2} alt="" />
                      </div>
                      <h6>
                        Right guidance from our <br />
                        professionals
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon3} alt="" />
                      </div>
                      <h6>
                        Engaging & easy-to-use <br />
                        dashboard system
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon5} alt="" />
                      </div>
                      <h6>
                        Digital tools: assessments, diary, <br />
                        trackers, & more
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="container container1">
            <div className="row">
              <div className="col-xl-12">
                <div className="heading text-center">
                  <h4>
                    Ignorance <span>Isn’t Bliss</span>
                  </h4>
                </div>
                <div className="accordion pc-outer" id="accordionExample">
                  {faq.map((e, i) => (
                    <div
                      className={
                        this.state.id === e._id && this.state.toggle1 === true
                          ? "card active"
                          : "card"
                      }
                    >
                      <div
                        className={
                          this.state.id === e._id && this.state.toggle1 === true
                            ? "card-header"
                            : "card-header collapsed"
                        }
                        onClick={() => {
                          this.toggle1(e._id);
                        }}
                        id="heading1"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        {e.question}
                      </div>
                      <div
                        id="collapse1"
                        className={
                          this.state.id === e._id && this.state.toggle1 === true
                            ? "collapse show"
                            : "collapse"
                        }
                        aria-labelledby="heading1"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>{e.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="accordion-link-box">
                    <Link to={"/faq"}>
                      Learn More<i className="fas fa-caret-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Blog blog={home} />
        <Thoughts />

        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlog: (params) => dispatch(loadBlog(params)),
  loadFaq: (params) => dispatch(loadFaq(params)),
  customerQuery: (data, callback) => dispatch(customerQuery(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Psychologist);
