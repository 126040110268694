import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import homeIcon from "../../../include/images/home-icon.svg";
import hospital from "../../../include/images/hospital.svg";
import calendar from "../../../include/images/feather-calendar.svg";
import bookmark from "../../../include/images/book-bookmark.svg";
import bookmark1 from "../../../include/images/bookmark.svg";
import invoice from "../../../include/images/invoice-sign-alt-o.svg";
import feather from "../../../include/images/feather-users.svg";
import mdesk from "../../../include/images/mindy-desk.svg";
import promotionsImg from "../../../include/images/promotions.svg";
import { loadSeo } from "../../../store/seo";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

class LeftBar extends Component {
  state = {};

  componentDidMount = () => {
    this.props.loadSeo("doctor-dashboard");
  };
  render() {
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div className="dashboard-left-bar">
          <ul className="dashboard-menu-list">
            <li
              className={
                this.props.match.path === "/doctor/dashboard"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link to="/doctor/dashboard">
                <img src={homeIcon} alt="" />
                <h4>Home</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/patient" ||
                this.props.match.path === "/doctor/patient-detail/:tab?"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="List of all your active patients"
                to="/doctor/patient"
              >
                <img src={hospital} alt="" />
                <h4>My Patients</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/dashboard/calender"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="All your appointment details at one place"
                to="/doctor/dashboard/calender"
              >
                <img src={calendar} alt="" />
                <h4>My Calendar</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/dashboard/toolbox"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="Assessments/ Worksheets/ Homework/ Educational materials"
                to={"/doctor/dashboard/toolbox"}
              >
                <svg
                  style={{ marginRight: "12px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.645"
                  height="12.203"
                  viewBox="0 0 14.645 12.203"
                >
                  <path
                    className="a"
                    style={{ fill: "#26262A" }}
                    d="M22.2,19.108H20.373V17.888a1.22,1.22,0,0,0-1.221-1.22H15.492a1.22,1.22,0,0,0-1.22,1.22v1.221H12.441A2.441,2.441,0,0,0,10,21.549v7.322H24.645V21.549A2.44,2.44,0,0,0,22.2,19.108Zm1.22,2.441V23.38H21.593v-3.05H22.2a1.221,1.221,0,0,1,1.22,1.22Zm-7.932-3.661h3.661v1.221H15.492Zm4.882,2.441v3.05h-6.1v-3.05Zm-7.932,0h.61v3.05H11.221V21.549a1.221,1.221,0,0,1,1.221-1.22ZM11.22,27.651V24.6H13.05v1.22h1.221V24.6h6.1v1.22h1.22V24.6h1.831v3.05Z"
                    transform="translate(-10 -16.668)"
                  />
                </svg>
                <h4>ToolBox</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path ===
                "/doctor/dashboard/appointment-history"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="All the appointments conducted by you and respective notes"
                to="/doctor/dashboard/appointment-history"
              >
                <img src={bookmark} alt="" />
                <h4>Appointments History</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/summary"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="Facts and Invoices of your appointments"
                to="/doctor/summary"
              >
                <img src={bookmark1} alt="" />
                <h4>Summary/Payment</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/notification"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="Any messages from YesMindy admin will be seen here"
                to="/doctor/notification"
              >
                <img src={promotionsImg} alt="" />
                <h4>Notifications</h4>
              </Link>
            </li>

            <li
              className={
                this.props.match.path === "/doctor/staff" ||
                this.props.match.path === "/doctor/staff/add"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link
                data-tip="Your added staff to handle appointments for you"
                to="/doctor/staff"
              >
                <img src={feather} alt="" />
                <h4>My Staff / Clinic</h4>
              </Link>
            </li>
            <li
              className={
                this.props.match.path === "/doctor/desk"
                  ? "dm-item active"
                  : "dm-item"
              }
            >
              <Link data-tip="FAQ and queries" to="/doctor/desk">
                <img src={mdesk} alt="" />
                <h4>Mindy Desk</h4>
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
});
export default withRouter(connect(null, mapDispatchToProps)(LeftBar));
