import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  getAssessment,
  loadAllAssessment,
  assignAssessment,
} from "../../../store/assessment";
import ToolboxList from "./toolboxList";
import close from "../../../include/images/icon-close.svg";
import circle from "../../../include/images/circle-check-icon.svg";
import { getPatient, patientSearch } from "../../../store/patient";
import Form from "../../common/form/form";
import Joi from "joi-browser";
import makeAnimated from "react-select/animated";
import { Modal } from "react-bootstrap";
import { getDoctorPatient } from "../../../store/doctorPatient";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";

class Assessments extends Form {
  state = {
    content: false,
    id: "",
    page: 1,
    isShow: false,
    isShow1: false,
    title: "",
    question: "",
    assessment_id: "",
    formSuccessModel: false,

    data: {
      patient_id: {},
    },
    errors: {},
  };
  schema = {
    patient_id: Joi.object()
      .required()
      .label("Patient")
      .error(() => {
        return { message: "Patient field is required" };
      }),
  };
  toggle = (data, title) => {
    this.setState({
      isShow: !this.state.isShow,
      question: data,
      title: title,
    });
  };

  toggleFormSuccess = () => {
    this.setState({
      formSuccessModel: !this.state.formSuccessModel,
      isShow1: false,
      assessment_id: "",
      data: { patient_id: "" },
    });
    document.body.addclassName = " modal-open";
  };
  toggle3 = (assessment_id) => {
    this.setState({
      isShow1: !this.state.isShow1,
      assessment_id: assessment_id,
    });
  };
  toggleClose = () => {
    this.setState({
      isShow: false,
      title: "",
      question: "",
    });
  };
  toggleClose1 = () => {
    this.setState({
      isShow1: false,
      assessment_id: "",
    });
  };
  doSubmit = () => {
    const { patient_id } = this.state.data;
    const { assessment_id } = this.state;

    const data = {
      patient_id: patient_id.value,
      assesment_id: assessment_id,
    };
    this.props.assignAssessment(data, this.callBackUpdate);
  };
  callBackUpdate = (res) => {
    if (res && res.status === 200) {
      this.toggleFormSuccess();
      this.setState({
        data: { patient_id: "" },
        assessment_id: "",
      });
    }
  };
  componentDidMount() {
    document.body.className = "dashboard";
    this.props.loadAllAssessment({ "category[]": "Assessments" });
  }
  loadFunc = () => {
    if (
      Math.ceil(
        this.props.doctorPatient.pagination.filtered_record /
          this.props.doctorPatient.pagination.record_per_page
      ) > this.state.page
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.props.loadAllDoctorpatient({
          page: this.state.page,
        })
      );
    }
  };
  toggleContent = (id) => {
    this.setState({
      content: !this.state.content,
      id: id,
    });
  };

  animatedComponents = makeAnimated();
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };

  render() {
    const { assessment } = this.props;
    const patients = this.props.patients;
    const ques = _.chain(this.state.question.questions)
      .groupBy("section.name")
      .map((value, key) => ({
        sections: key,
        question_group: _.chain(value)
          .groupBy("question_group.name")
          .map((value, key) => ({
            question_group: key,
            section: value,
          }))
          .value(),
      }))
      .value();
    console.log("quest", ques);
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="allAppointments"
          role="tabpanel"
          aria-labelledby="allAppointments-tab"
        >
          <div class="card-header">All Assessments</div>
          <div class="content-body show add-scrollbar  scrollbar-dynamic"> */}
        <div class="appointment-history-box">
          <ul class="new-profile-list">
            <ToolboxList
              assessment={assessment}
              toggleContent={this.toggleContent}
              id={this.state.id}
              content={this.state.content}
              toggle={this.toggle}
              toggleClose={this.toggleClose}
              isShow={this.state.isShow}
              toggle3={this.toggle3}
              isShow1={this.state.isShow1}
            />
          </ul>
        </div>
        {/* </div>
        </div> */}
        <div
          className={
            this.state.isShow === true
              ? "sidebar-box preSession opennote"
              : "sidebar-box preSession closenote"
          }
          id="pre"
        >
          <div className="sidebar-head d-flex align-items-center">
            {this.state.isShow === true && (
              <h5>
                {this.state.title}{" "}
                {this.state.question &&
                  this.state.question.header_text !== "" && (
                    <small>( {this.state.question.header_text} )</small>
                  )}
              </h5>
            )}
            {this.state.isShow === true ? (
              <div className="close-img ml-auto" onClick={this.toggleClose}>
                <img src={close} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="sidebar-body">
            {ques.map((e1, i1) => (
              <div className="check-box-group" key={i1}>
                <label class="section">
                  {e1.sections !== "undefined" && e1.sections}
                </label>
                {e1.question_group.map((e2) => (
                  <>
                    <div class="d-flex flex-column">
                      <label class="question_group">
                        {e2.question_group !== "undefined" && e2.question_group}
                      </label>
                    </div>
                    {e2.section.map((e3) => (
                      <>
                        <>
                          <label style={{ color: "#26262a" }}>
                            {e3.question}
                          </label>
                          {e3.response_type === "free_text_pill_box" && (
                            <CreatableSelect
                              noOptionsMessage={() =>
                                "Type and press enter to add options"
                              }
                              isMulti
                              classNamePrefix="select"
                              isDisabled={false}
                              isLoading={false}
                              name="color"
                              styles={this.customStyles}
                              value={
                                this.state.data && this.state.data[e3.question]
                              }
                              onChange={(e) => {
                                console.log("eeeee", e);
                                const data = { ...this.state.data };
                                data[e3.question] = e;
                                this.setState({ data });
                                console.log(e);
                              }}
                            />
                          )}
                          {e3.response_type === "multiselect_dropdown" && (
                            <Select
                              isMulti
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={false}
                              isLoading={false}
                              isClearable={false}
                              isRtl={false}
                              isSearchable={false}
                              name="color"
                              styles={this.customStyles}
                              options={e3.options.map((ex) => ({
                                label: ex.title,
                                value: ex.title,
                              }))}
                              value={
                                this.state.data && this.state.data[e3.question]
                              }
                              onChange={(e) => {
                                const data = { ...this.state.data };
                                data[e3.question] = e;
                                this.setState({ data });
                                console.log(e);
                              }}
                            />
                          )}
                          {e3.response_type === "dropdown" && (
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isDisabled={false}
                              isLoading={false}
                              isClearable={false}
                              isRtl={false}
                              isSearchable={false}
                              name="color"
                              styles={this.customStyles}
                              options={e3.options.map((ex) => ({
                                label: ex.title,
                                value: ex.title,
                              }))}
                              value={
                                this.state.data && this.state.data[e3.question]
                              }
                              onChange={(e) => {
                                const data = { ...this.state.data };
                                data[e3.question] = e;
                                this.setState({ data });
                                console.log(e);
                              }}
                            />
                          )}
                          {e3.response_type === "text" && (
                            <input
                              type="text"
                              name=""
                              className="form-control"
                              placeholder="Write here…"
                              value={
                                this.state.data &&
                                this.state.data[e1 && e3.question]
                              }
                            />
                          )}
                          {e3.response_type === "text_area" && (
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder=""
                              value={
                                this.state.data &&
                                this.state.data[e1 && e3.question]
                              }
                            ></textarea>
                          )}

                          <div className="radiobox-group d-flex flex-wrap align-items-center">
                            {e3.response_type === "checkbox" &&
                              e3.options.map((e2, i2) => (
                                <div className="form-check" key={i2}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={e2.title}
                                    id={e2.title + e3._id}
                                    name={e2.title + e3._id}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={e2.title + e3._id}
                                  >
                                    <span
                                      style={{
                                        marginLeft: "0.5rem",
                                      }}
                                    ></span>
                                    {e2.title}
                                  </label>
                                </div>
                              ))}
                          </div>
                          <div className="radiobox-group d-flex flex-wrap align-items-center">
                            {e3.response_type === "radio" &&
                              e3.options.map((e2, i2) => (
                                <div className="form-check" key={i2}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={e2.title}
                                    id={e2.title + e3._id}
                                    name={e2.title + e3._id}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={e2.title + e3._id}
                                  >
                                    <span
                                      style={{
                                        marginLeft: "2rem",
                                      }}
                                    ></span>
                                    {e2.title}
                                  </label>
                                </div>
                              ))}
                          </div>

                          <div className="radiobox-group d-flex flex-wrap align-items-center">
                            {e3.response_type === "doc" &&
                              e3.options.length > 0 && (
                                <>
                                  {e3.options[0].title.split(".").pop() ===
                                    "pdf" ||
                                  e3.options[0].title.split(".").pop() ===
                                    "PDF" ? (
                                    <>
                                      <embed
                                        src={
                                          process.env.REACT_APP_S3URL +
                                          e3.options[0].title
                                        }
                                      />
                                    </>
                                  ) : (
                                    <img
                                      src={
                                        process.env.REACT_APP_S3URL +
                                        e3.options[0].title
                                      }
                                      alt=""
                                    />
                                  )}
                                </>
                              )}
                          </div>
                          <br />
                        </>
                      </>
                    ))}
                  </>
                ))}
              </div>
            ))}
            <small className="mt-4"> {this.state.question.footer_text} </small>
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true
              ? "sidebar-pannel preSession opennote"
              : "sidebar-pannel preSession closenote"
          }
          id="pre"
        >
          <div className="sidebar-head d-flex align-items-center">
            {this.state.isShow1 === true && (
              <h5>
                Assign Task<br></br>
                <span style={{ fontSize: "10px" }}>
                  {" "}
                  (Please choose a re-assessment version of the task if task
                  being repeated)
                </span>
              </h5>
            )}
            {this.state.isShow1 === true ? (
              <div className="close-img ml-auto" onClick={this.toggleClose1}>
                <img src={close} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="sidebar-body">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label>Patient</label>
                  <Select
                    key={
                      this.state.data.patient_id &&
                      this.state.data.patient_id.length
                    }
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.patient_id = e;
                        this.setState({ data });
                      }
                    }}
                    placeholder="Select patient"
                    styles={this.customStyles}
                    className="form-groupxx"
                    closeMenuOnSelect={true}
                    components={this.animatedComponents}
                    options={patients.map((e) => ({
                      label:
                        e.first_name +
                        " " +
                        e.last_name +
                        " (" +
                        e.mobile +
                        ")",
                      value: e._id,
                    }))}
                    value={
                      this.state.data.patient_id.value
                        ? this.state.data.patient_id
                        : ""
                    }
                  />
                </div>
              </div>
              <div
                className="sidebar-footer d-flex align-items-center justify-content-between"
                style={{ padding: "15px 0px" }}
              >
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ isShow1: false, patient_id: "" })
                  }
                  className="btn btn-outline no-shadow"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  OK
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true || this.state.isShow === true
              ? "overlay4 show"
              : "overlay4"
          }
        ></div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade personal-details-modal show"
          show={this.state.formSuccessModel}
          onHide={this.toggleFormSuccess}
        >
          <div class="text-center">
            <div class="modal-body">
              <div class="pdm-icon">
                <img src={circle} alt="" />
              </div>
              <h5></h5>
              <p>Task has been successfully allotted.</p>
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "0",
                  marginTop: "24px",
                }}
              >
                Please make sure same task version has not been allotted earlier
              </p>
              <a
                href="javascript:void(0)"
                onClick={this.toggleFormSuccess}
                class="btn btn-default no-shadow"
              >
                CLOSE
              </a>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessment: (params) => dispatch(loadAllAssessment(params)),
  patientSearch: () => dispatch(patientSearch()),
  assignAssessment: (param, callback) =>
    dispatch(assignAssessment(param, callback)),
});

const mapStateToProps = (state) => ({
  assessment: getAssessment(state).assessment,
  patients: getDoctorPatient(state).verifiedList,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assessments)
);
