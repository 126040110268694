import React from "react";
import YesMindy from "./yesMindy";
const NoRecord = (props) => {
  return (
    <>
      <p className="boldFont mt-2">{props.header}</p>
      <p>
        {!props.noLogo && <YesMindy />} {props.text}
      </p>
    </>
  );
};

export default NoRecord;
