import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import loaderIcon from "../../../include/images/loading-icon.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { patientSignUp, updatePartner } from "../../../store/patient";
import AlertSuccess from "../../common/alertSuccess";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import {
  passwordInvalid,
  passwordRequired,
  securityQuestionRequired,
  answerRequired,
  answerInvalid,
  dobRequired,
  dobInvalid,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";
import { clearAllToken, setToken } from "../../services/localStorageServices";

class VerifyPartner extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    sequerityQuestions: [
      {
        id: "What was your childhood nickname?",
        name: "What was your childhood nickname?",
      },
      {
        id: "What is the name of your favorite childhood friend?",
        name: "What is the name of your favorite childhood friend?",
      },
      {
        id: "What is the middle name of your youngest child?",
        name: "What is the middle name of your youngest child?",
      },
      {
        id: "Where were you when you had your first kiss?",
        name: "Where were you when you had your first kiss?",
      },
      {
        id: "In what city or town was your first job?",
        name: "In what city or town was your first job?",
      },
    ],
    data: { password: "", security_question: "", answer: "", DOB: "" },
    errors: { password: "", security_question: "", answer: "", DOB: "" },
  };

  componentDidMount() {
    clearAllToken();
  }

  schema = {
    password: Joi.string()
      .min(8)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;
          }
        });
        return errors;
      }),
    security_question: Joi.string()
      .required()
      .label("Security question")
      .error(() => {
        return { message: securityQuestionRequired };
      }),
    answer: Joi.string()
      .required()
      .label("Answer")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = answerRequired;
              break;

            case "string.min":
              err.message = answerInvalid;
              break;
          }
        });
        return errors;
      }),
    DOB: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .required()
      .label("Date of birth")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = dobRequired;
              break;

            case "date.max":
              err.message = dobInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });
      const {
        answer: security_answer,
        password,
        security_question,
        DOB,
      } = this.state.data;

      const payLoad = {
        security_question,
        security_answer,
        date_of_birth: DOB.toString(),
        password,
      };
      this.props.updatePartner(this.props.partnerId, payLoad, this.callback);
    }
  };

  callback = (res) => {
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data;
      setToken(xRefreshToken, xAuthToken);
      localStorage.setItem("patient", JSON.stringify(res.data.data));
      this.props.updateStep(null, 6);
      //toast(<AlertSuccess message={res.data.error} />);
    } else {
      toast(<AlertError message={res.data.error} />);
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block ",
        loading: false,
      });
    }
  };
  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <h2 className="mb-3">Verify account</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-box">
                {this.renderPasswordInput("password", "Set Password")}
                {this.renderSelect(
                  "security_question",
                  "Security question",
                  this.state.sequerityQuestions
                )}
                {this.renderInput("answer", "Answer")}
                {this.renderDateInput("DOB", "Date Of Birth")}
                <div className="form-group">
                  <SubmitBtn
                    label="Continue"
                    loading={this.state.loading}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="mr-bottom">
            <p>
              By clicking Sign up button, you are agreeing to our{" "}
              <a href="#">Privacy Policy</a> and{" "}
              <a href="#">Terms of Service</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePartner: (id, param, callback) =>
    dispatch(updatePartner(id, param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(VerifyPartner));
