import React, { Component } from "react";
import _ from "lodash";
import Header from "./header";
import ill from "./../../../include/images/ill.svg";
import arrowtr from "./../../../include/images/arrow-tr.svg";
import dashbImg from "./../../../include/images/dashboard.svg";
import consultation from "./../../../include/images/consultation.svg";
import sstar from "./../../../include/images/shield-with-star.svg";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getMisc, loadFieldType, loadFilterCity } from "../../../store/misc";
import { getDoctor, loadDoctors } from "../../../store/doctor";
import LoadingBar from "react-top-loading-bar";
import crossIcon from "../../../include/images/cross.svg";
import pinImg from "../../../include/images/pin.svg";
import locationIcon from "../../../include/images/location-icon.svg";
import askMindy from "../../../include/images/help.svg";
import moment from "moment";

import {
  getAppointment,
  loadDate,
  loadSlot,
  createOrder,
  updateOrder,
} from "../../../store/appointment";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import OrderReview from "./appointment/orderReview";
import TypeSwitch from "./appointment/typeSwitch";
import DateTimeSelection from "./appointment/dateTimeSelection";
import InfiniteScroll from "react-infinite-scroller";
import { loadPatientProfile, getPatient } from "../../../store/patient";
import Footer from "../../home/footer";
const ref = React.createRef(null);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const fees = [
  "",
  { $lte: 500 },
  { $lte: 700, $gte: 500 },
  { $lte: 1000, $gte: 700 },
  { $lte: 1500, $gte: 1000 },
  { $gte: 1500 },
];

const experience = [
  "",
  { $lte: 2 },
  { $lte: 5, $gte: 2 },
  { $lte: 10, $gte: 5 },
  { $lte: 15, $gte: 10 },
  { $gte: 15 },
];
class Doctors extends Component {
  state = {
    customer: localStorage.getItem("customer_details")
      ? JSON.parse(localStorage.getItem("customer_details"))
      : {},
    params: {
      "field_type.id": "5fe61886084b3e3d4e6c7978",
      gender: [],
      cityId: [],
      feesNumber: [],
      language: [],
      experienceNumber: [],
    },
    page: 1,
    doctorProfile: "",
    selectedDate: "",
    selectedTime: "",
    preview: false,
    loading: false,
    orderDetail: {},
    apType: "clinic",
    sideBar: "",
    Psychiatrist: "",
    Psychologist: "",
    name: "",
  };

  setName = (e) => {
    this.setState({ name: e.target.value }, () =>
      this.props.loadDoctor(this.arrangeParam())
    );
  };

  applyFilter = () => {
    this.props.loadDoctor(this.arrangeParam());
  };

  doSubmit = (type) => {
    //alert(this.state.selectedDate);
    this.setState({ loading: true });
    if (type === 'clinic') {
      this.setState(
        {
          preview: true,
          loading: false,
          apType: type,
          orderDetail: {
            ret: {
              appointment_time: this.state.selectedTime,
              appointment_type: 'clinic',
              consultation_charges: Number(
                this.props.getDoctor.doctors.data[this.state.doctorProfile].schedules.clinicFee
              ),
            },
          },
          sideBar: 'appointment',
        },
        () => {
          document.body.classList.add('toggled-tab')
        },
      )
    }else{
      const payLoad = {
        appointment_time: moment(this.state.selectedTime).utc(),
        doctor_id:
          this.props.getDoctor.doctors.data[this.state.doctorProfile]._id,
        type: type,
      };
      console.log("payLoadpayLoad", payLoad);
      this.props.createOrder(payLoad, this.callBackOrder);
    }
  };

  callBackPayment = (res) => {
    this.props.loadPatientProfile();
    this.removeBodyClass();
    if (res.status === 200) {
      this.props.history.push("/patient/appointment-success");
    } else {
      toast(<AlertError message={"Something went wrong."} />);
    }
  };

  callBackOrderLogin = (res) => {
    this.props.loadPatientProfile();
    this.removeBodyClass();
    if (res.status === 200) {
      //toast(<AlertSuccess message={"Payment success"} />);
      this.props.history.push("/patient/appointment-success");
    } else {
      toast(<AlertError message={"Something went wrong."} />);
    }
  };

  payNow = (e) => {
    e.preventDefault();
    if (this.state.apType === 'clinic') {
      const payLoad = {
        appointment_time: this.state.selectedTime,
        doctor_id: this.props.getDoctor.doctors.data[this.state.doctorProfile]._id,
        type: this.state.apType,
      }
      this.props.createOrder(payLoad, this.callBackOrderLogin)
    }else{
      const res = this.state.orderDetail;
      const patientData = JSON.parse(localStorage.getItem("patient"));
      var options = {
        key: process.env.REACT_APP_REZORPAY,
        amount: res.rpay.amount_due * 100,
        currency: "INR",
        name: "YesMindy",
        description: "Appointment",
        image:
          process.env.REACT_APP_S3URL + "website/yes-mindy-color-logo-small.png",
        order_id: res.rpay.id,
        handler: (response) => {
          this.props.updateOrder(
            { payment_info: response },
            this.callBackPayment
          );
        },
        prefill: {
          name: patientData.first_name + " " + patientData.last_name,
          email: patientData.email,
          contact: patientData.mobile,
        },
        notes: {
          address: "Appointment",
        },
        theme: {
          color: "#621ac0",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        console.log("paymentfailed", response);
      });
      rzp1.open();
    }
  };

  callBackOrder = (res) => {
    if (res && res.data)
      this.setState({ preview: true, loading: false, orderDetail: res.data });
  };

  componentDidMount = () => {
    this.props.loadPatientProfile();
    this.props.loadFieldType({ filter: true });
    this.props.loadFilterCity();
    if (
      this.props.location.state &&
      this.props.location.state.field_type &&
      this.props.getMisc.fieldTypes.length
    ) {
      const defaultF = this.props.getMisc.fieldTypes.find(
        (ef) => ef.name === this.props.location.state.field_type
      );
      const { params } = this.state;
      params["field_type.id"] = defaultF.id;
      this.setState({ params });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.getMisc.loadingFieldTypes &&
      this.props.getMisc.loadingFieldTypes !==
        prevProps.getMisc.loadingFieldTypes
    ) {
      if (this.props.location.state && this.props.location.state.field_type) {
        const defaultF = this.props.getMisc.fieldTypes.find(
          (ef) => ef.name === this.props.location.state.field_type
        );
        const { params } = this.state;
        params["field_type.id"] = defaultF.id;
        this.setState({ params });
      }
    }
    if (
      !this.props.getPatient.loading &&
      this.props.getPatient.loading !== prevProps.getPatient.loading
    ) {
      const { Psychologist, Psychiatrist } = this.props.getPatient.profile;
      this.setState({ Psychologist, Psychiatrist }, () =>
        this.props.loadDoctor(this.arrangeParam())
      );
    }
    if (
      !this.props.getDoctor.doctorLoading &&
      this.props.getDoctor.doctorLoading !== prevProps.getDoctor.doctorLoading
    ) {
      ref.current.complete();
    }
    if (!this.props.getAppointment.loadingDates) {
      ref.current.complete();
    }
    if (this.props.getAppointment.loadingDates) {
      ref.current.continuousStart();
    }
  };

  arrangeParam = () => {
    const params = { ...this.state.params };
    const experienceData = params.experienceNumber.map((e) => experience[e]);
    const feesData = params.feesNumber.map((e) => fees[e]);
    const final = { page: this.state.page };
    if (params["field_type.id"])
      final["field_type._id"] = params["field_type.id"];
    if (params.gender.length === 1) final.sex = params.gender[0];
    if (params.cityId.length) final.cityId = params.cityId;
    if (params.feesNumber.length) final.fees = feesData;
    if (params.language.length) final.language = params.language;
    if (params.experienceNumber.length) final.experience = experienceData;
    if (this.state.name) final.name = this.state.name;
    if (
      this.state.Psychiatrist &&
      params["field_type.id"] === "5fe61886084b3e3d4e6c7978"
    ) {
      final.id = this.state.Psychiatrist;
    }
    if (
      this.state.Psychologist &&
      params["field_type.id"] === "5fe619be084b3e3d4e6c7979"
    ) {
      final.id = this.state.Psychologist;
    }

    return final;
  };

  updateParams = (key, val, e) => {
    ref.current.continuousStart();
    if (e) e.preventDefault();
    const params = { ...this.state.params };
    params[key] = val;
    this.setState({ params, doctorProfile: "" }, () =>
      this.props.loadDoctor(this.arrangeParam())
    );
  };

  changeGender = (mf) => {
    let params = { ...this.state.params };
    const find = params.gender.find((v) => v === mf);
    if (find) {
      const f = params.gender.filter((vf) => vf !== mf);
      params.gender = f;
    } else {
      params.gender.push(mf);
    }
    this.setState({ params });
  };

  changeCity = (cityId) => {
    let params = { ...this.state.params };
    const find = params.cityId.find((v) => v === cityId);
    if (find) {
      const f = params.cityId.filter((vf) => vf !== cityId);
      params.cityId = f;
    } else {
      params.cityId.push(cityId);
    }
    this.setState({ params });
  };

  changeLang = (Lang) => {
    let params = { ...this.state.params };
    const find = params.language.find((v) => v === Lang);
    if (find) {
      const f = params.language.filter((vf) => vf !== Lang);
      params.language = f;
    } else {
      params.language.push(Lang);
    }
    this.setState({ params });
  };
  changeFees = (number) => {
    let params = { ...this.state.params };
    const find = params.feesNumber.find((v) => v === number);
    if (find) {
      const f = params.feesNumber.filter((vf) => vf !== number);
      params.feesNumber = f;
    } else {
      params.feesNumber.push(number);
    }
    this.setState({ params });
  };

  changeExp = (number) => {
    let params = { ...this.state.params };
    const find = params.experienceNumber.find((v) => v === number);
    if (find) {
      const f = params.experienceNumber.filter((vf) => vf !== number);
      params.experienceNumber = f;
    } else {
      params.experienceNumber.push(number);
    }
    this.setState({ params });
  };

  changeBodyClass = (e, nameClass) => {
    e.preventDefault();
    this.setState({ selectedDate: "", selectedTime: "", preview: false });
    document.body.classList.add(nameClass);
    this.props.loadDate();
  };

  removeBodyClass = (e) => {
    if (e) e.preventDefault();
    document.body.className = "p-0";
  };

  selectDate = (type, date) => {
    this.setState({ selectedDate: date, selectedTime: "" });
    this.props.loadSlot(
      this.props.getDoctor.doctors.data[this.state.doctorProfile]._id,
      date,
      type
    );
  };

  changeSwitch = (type) => {
    this.setState({ apType: type, selectedDate: "" });
  };

  formatAMPM(date) {
    var hours = new moment(date, "HH").utc().hour();
    var minutes = new moment(date, "mm").utc().minutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  changePreview = (preview) => {
    this.setState({ preview });
  };

  changeTime = (time) => {
    this.setState({ selectedTime: time });
  };
  loadFunc = (p) => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.props.loadDoctor(this.arrangeParam())
    );
  };

  render() {
    const { constants } = this.props.getMisc;

    const { footer_email_address, facebook, instagram, twitter } = constants;
    return (
      <React.Fragment>
        <LoadingBar color="#621ac0" ref={ref} shadow={true} />
        <Header
          name={this.state.name}
          setName={this.setName}
          params={this.state.params}
          applyFilter={this.applyFilter}
          changeGender={this.changeGender}
          filterCity={this.props.getMisc.filterCity}
          changeCity={this.changeCity}
          changeFees={this.changeFees}
          changeLang={this.changeLang}
          changeExp={this.changeExp}
        />
        <main className="outer-container">
          <div className="content-container service-container mob-pt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="service-box">
                    {this.state.customer.customer_first_name && (
                      <div class="service-head d-lg-flex flex-wrap align-items-lg-center justify-content-lg-between d-none d-lg-block">
                        <h2>
                          Hello,{" "}
                          {`${this.state.customer.customer_first_name} ${this.state.customer.customer_last_name}`}{" "}
                          !
                        </h2>
                        <a
                          href="#!"
                          class="go-to-dashbord-box d-flex align-items-center"
                        >
                          <div class="gtd-icon">
                            <img src={dashbImg} alt="" />
                          </div>
                          <div class="gtd-text-box">Go to dashboard</div>
                        </a>
                      </div>
                    )}
                    <div className="service-head d-lg-flex flex-wrap align-items-lg-center justify-content-lg-between d-none d-lg-block">
                      <div class="heading w-auto mb-0">
                        {this.props.getPatient.profile.Psychiatrist ||
                        this.props.getPatient.profile.Psychologist ? (
                          <h5>
                            Your Treating <span>Team</span>
                          </h5>
                        ) : (
                          <h5>
                            Select <span>services</span>
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="service-body d-lg-flex flex-wrap">
                      <div className="sb-left">
                        <div className="nav flex-lg-column nav-pills vertical-nav">
                          {this.props.getMisc.fieldTypes.map((el, i) => (
                            <a
                              className={
                                this.state.params["field_type.id"] === el.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              href="#!"
                              onClick={(e) =>
                                this.updateParams("field_type.id", el.id, e)
                              }
                            >
                              <div className="pills-content-box d-flex flex-wrap align-items-center">
                                <div className="pc-img-box d-none d-lg-block">
                                  <img src={!i ? ill : consultation} alt="" />
                                </div>
                                <div className="pc-text-box">
                                  <h3>{el.name}</h3>
                                  {/* {!this.state[el.name] && (
                                    <p className="d-none d-lg-block">
                                      {el.doctors} Available
                                    </p>
                                  )} */}
                                </div>
                              </div>
                            </a>
                          ))}

                          {/* <a
                            className="nav-link"
                            href="#!"
                            onClick={(e) => e.preventDefault()}
                          >
                            <div className="pills-content-box d-flex flex-wrap align-items-center">
                              <div className="pc-img-box d-none d-lg-block">
                                <img src={askMindy} alt="" />
                              </div>
                              <div className="pc-text-box">
                                <h3>Ask Mindy.</h3>
                                <p className="d-none d-lg-block">info text</p>
                              </div>
                            </div>
                          </a> */}
                        </div>
                      </div>
                      <div className="search-box d-block d-lg-none mb-3">
                        <input
                          value={this.state.name}
                          onChange={this.setName}
                          type="text"
                          className="search-input"
                          placeholder="Search for doctor or location here"
                        />
                      </div>
                      <div className="sb-right">
                        <div className="card">
                          <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={this.loadFunc}
                            hasMore={
                              !this.props.getDoctor.doctors.doctorLoading &&
                              this.props.getDoctor.doctors.pagination
                                .filtered_record !==
                                this.props.getDoctor.doctors.pagination
                                  .total_record_on_current_page
                            }
                            loader={
                              <div className="loader" key={0}>
                                Loading ...
                              </div>
                            }
                          >
                            {!this.props.getDoctor.doctorLoading && (
                              <>
                                {this.props.getDoctor.doctors.data.length ? (
                                  this.props.getDoctor.doctors.data.map(
                                    (ed, i) => (
                                      <div className="card-box">
                                        <div className="cb-upper d-flex flex-wrap align-items-start">
                                          <div className="cb-upper-content d-flex flex-wrap">
                                            <div className="cb-img-box">
                                              <a
                                                href={"/dr/" + ed.user_name}
                                                target="_blank"
                                              >
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_S3URL +
                                                    ed.image_url
                                                  }
                                                  alt=""
                                                />
                                              </a>
                                            </div>
                                            <div class="cb-text-box">
                                              <div class="doc-intro-desc  font-montserrat">
                                                <h5>
                                                  {" "}
                                                  <a
                                                    href={"/dr/" + ed.user_name}
                                                    target="_blank"
                                                  >
                                                    {ed.name}
                                                  </a>
                                                </h5>
                                                <h6>
                                                  {ed.graduation &&
                                                    ed.graduation.education}
                                                  {ed.post_graduation &&
                                                    ", " +
                                                      ed.post_graduation
                                                        .education}
                                                  {ed.super_speciality &&
                                                    ", " +
                                                      ed.super_speciality
                                                        .education}
                                                </h6>
                                                <h6> {ed.field_type.name}</h6>
                                                {ed.certification && (
                                                  <h6>
                                                    {" "}
                                                    {ed.certification.length >
                                                      0 &&
                                                      ed.certification[0]
                                                        .title +
                                                        "(" +
                                                        ed.certification[0]
                                                          .place +
                                                        ")"}
                                                  </h6>
                                                )}
                                                <h6>
                                                  {" "}
                                                  {ed.total_experience} Years
                                                  Experience
                                                </h6>
                                                {ed.practice_preferences
                                                  .language.length > 0 && (
                                                  <h6>
                                                    Speaks:{" "}
                                                    {ed.practice_preferences.language.map(
                                                      (e, i) => (
                                                        <>
                                                          <span>{e}</span>
                                                          {ed
                                                            .practice_preferences
                                                            .language.length -
                                                            1 !==
                                                            i && ", "}
                                                        </>
                                                      )
                                                    )}
                                                  </h6>
                                                )}
                                                <div class="doc-loc-name d-flex align-items-center">
                                                  <div class="location-pin-icon">
                                                    <img
                                                      src={locationIcon}
                                                      alt=""
                                                    />
                                                  </div>{" "}
                                                  <span>
                                                    {ed.clinic_details.city}
                                                  </span>
                                                </div>
                                                <div class="doc-fee-desc">
                                                  {ed.schedules.clinicFee &&
                                                  ed.schedules.clinicFee > 0 ? (
                                                    <div class="d-flex align-items-center">
                                                      {" "}
                                                      <strong>
                                                        Physical Fee:{" "}
                                                      </strong>
                                                      <ul class="fee-list d-flex align-items-center">
                                                        <li>
                                                          ₹{" "}
                                                          {
                                                            ed.schedules
                                                              .clinicFee
                                                          }
                                                        </li>
                                                        <li>
                                                          {ed.schedules.time}
                                                          mins
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {ed.schedules.onlineFee &&
                                                  ed.schedules.onlineFee > 0 ? (
                                                    <div class="d-flex align-items-center">
                                                      {" "}
                                                      <strong>
                                                        Online Fee:{" "}
                                                      </strong>
                                                      <ul class="fee-list d-flex align-items-center">
                                                        <li>
                                                          ₹{" "}
                                                          {
                                                            ed.schedules
                                                              .onlineFee
                                                          }
                                                        </li>
                                                        <li>
                                                          {ed.schedules.time}
                                                          mins
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cb-btn">
                                            {ed.schedules.onlineFee &&
                                            ed.schedules.onlineFee > 0 ? (
                                              <a
                                                href="#!"
                                                onClick={(e) => {
                                                  this.setState(
                                                    {
                                                      doctorProfile: i,
                                                      sideBar: "appointment",
                                                    },
                                                    () => {
                                                      this.changeBodyClass(
                                                        e,
                                                        "toggled-tab"
                                                      );
                                                      this.changeSwitch(
                                                        "online"
                                                      );
                                                    }
                                                  );
                                                }}
                                                className="btn btn-primary btn-block onlineVisit"
                                              >
                                                Online visit
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                            {ed.schedules.clinicFee &&
                                            ed.schedules.clinicFee > 0 ? (
                                              <a
                                                href="#!"
                                                onClick={(e) => {
                                                  this.setState(
                                                    {
                                                      doctorProfile: i,
                                                      sideBar: "appointment",
                                                    },
                                                    () => {
                                                      this.changeBodyClass(
                                                        e,
                                                        "toggled-tab"
                                                      );
                                                      this.changeSwitch(
                                                        "clinic"
                                                      );
                                                    }
                                                  );
                                                }}
                                                className="btn btn-primary btn-block physicalVisit"
                                              >
                                                Physical visit
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div className="card-box">
                                    <h4>No Records Found</h4>
                                  </div>
                                )}
                              </>
                            )}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-filter-block d-block d-lg-none">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="filter-block-content d-flex align-items-center">
                    <div className="sortby-drop dropdown">
                      <div
                        className="filter-block d-flex align-items-center justify-content-center dropdown-toggle"
                        id="sortbyDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="fb-icon">
                          <img src="include/images/mob-sort-icon.svg" alt="" />
                        </div>
                        <div className="fb-text">Sort by</div>
                      </div>
                      <div
                        className="dropdown-menu sortby-dropdown"
                        aria-labelledby="sortbyDropdown"
                      >
                        <div className="custom-control-head">
                          <div className="custom-control heading">
                            <h6>SORT BY</h6>
                          </div>
                        </div>
                        <div className="custom-control-body">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio1"
                              name="customRadio"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio1"
                            >
                              Popularity
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio2"
                              name="customRadio"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio2"
                            >
                              Price -- High to Low
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio3"
                              name="customRadio"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio3"
                            >
                              Price -- Low to High
                            </label>
                          </div>
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadio4"
                              name="customRadio"
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customRadio4"
                            >
                              Newest First
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer
            footer_email_address={footer_email_address}
            facebook={facebook}
            instagram={instagram}
            twitter={twitter}
          />
        </main>

        <div className="side-pannel-container">
          {this.state.sideBar === "profile" && (
            <React.Fragment>
              {this.state.preview && (
                <OrderReview
                  selectedDate={this.state.selectedDate}
                  changePreview={this.changePreview}
                  doctorProfile={this.state.doctorProfile}
                  getDoctor={this.props.getDoctor}
                  months={months}
                  orderDetail={this.state.orderDetail}
                  formatAMPM={this.formatAMPM}
                  payNow={this.payNow}
                />
              )}

              {/**enbd */}

              <div className="side-pannel-box">
                <div className="pannel-top">
                  <a
                    href="#!"
                    className="close"
                    onClick={(e) => this.removeBodyClass(e)}
                  >
                    <img src={crossIcon} alt="" />
                  </a>
                </div>
                <div className="pannel-intro-box">
                  <div className="pi-head d-flex align-items-center">
                    <div className="pi-img-box">
                      <img
                        src={
                          this.state.doctorProfile !== "" &&
                          process.env.REACT_APP_S3URL +
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].image_url
                        }
                        alt=""
                      />
                    </div>
                    <div className="pi-text-box">
                      <h4>
                        Dr.{" "}
                        {this.state.doctorProfile !== "" &&
                          this.props.getDoctor.doctors.data[
                            this.state.doctorProfile
                          ].name}
                      </h4>
                      <div className="pi-exp d-flex align-items-center">
                        <div className="mr-md-3 mr-1">
                          <img src={sstar} alt="" />
                        </div>
                        <span>
                          {this.state.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].total_experience}{" "}
                          years of experience
                        </span>
                      </div>
                      {this.state.doctorProfile !== "" &&
                        this.props.getDoctor.doctors.data[
                          this.state.doctorProfile
                        ].schedules.time && (
                          <div className="pi-fee-box">
                            <ul className="fee-list d-flex align-items-left flex-lg-column text-right">
                              <li>
                                <b>
                                  <span>Online: ₹</span>
                                  {this.state.doctorProfile !== "" &&
                                    this.props.getDoctor.doctors.data[
                                      this.state.doctorProfile
                                    ].schedules.onlineFee}
                                  , <span>Clinic: ₹</span>
                                  {this.state.doctorProfile !== "" &&
                                    this.props.getDoctor.doctors.data[
                                      this.state.doctorProfile
                                    ].schedules.clinicFee}
                                </b>
                              </li>
                              <li>
                                {this.state.doctorProfile !== "" &&
                                  this.props.getDoctor.doctors.data[
                                    this.state.doctorProfile
                                  ].schedules.time}{" "}
                                min
                              </li>
                            </ul>
                          </div>
                        )}
                      <div className="pi-location d-none d-lg-flex align-items-center">
                        <div className="mr-3">
                          <img src={pinImg} alt="" />
                        </div>
                        <span>
                          {this.state.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].clinic_details.city}
                          ,
                        </span>{" "}
                        <span>
                          {" "}
                          {this.state.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].clinic_details.state}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="separator"></div>
                  <div className="pi-body">
                    <h6>
                      About Dr.{" "}
                      {this.state.doctorProfile !== "" &&
                        this.props.getDoctor.doctors.data[
                          this.state.doctorProfile
                        ].name}
                    </h6>
                    <p>
                      Dr{" "}
                      {this.state.doctorProfile !== "" &&
                        this.props.getDoctor.doctors.data[
                          this.state.doctorProfile
                        ].name}{" "}
                      is a consultant psychiatrist in practice with more than 8
                      years of experience in the domain of mental health problem
                      revolving around how people think, feel and behave. He
                      finished his DNB in psychiatry from an esteem institute in
                      Tamil Nadu and currently offers his consultation at a
                      clinic in Borival.
                    </p>
                    {this.state.doctorProfile !== "" &&
                      this.props.getDoctor.doctors.data[
                        this.state.doctorProfile
                      ].links && (
                        <div className="copy-content d-flex align-items-center">
                          <a href="#!" target="_blank" className="mr-3">
                            {this.state.doctorProfile !== "" &&
                              this.props.getDoctor.doctors.data[
                                this.state.doctorProfile
                              ].links &&
                              this.props.getDoctor.doctors.data[
                                this.state.doctorProfile
                              ].links.virtual}
                          </a>
                          <a
                            href="#!"
                            className="copy-link-text d-flex align-items-center"
                          >
                            <img
                              src="include/images/copy.svg"
                              className="mr-3"
                              alt=""
                            />
                            <span>copy</span>
                          </a>
                        </div>
                      )}
                  </div>
                </div>

                <div className="pannel-tab-box">
                  <TypeSwitch
                    changeSwitch={this.changeSwitch}
                    loadDate={this.props.loadDate}
                    apType={this.state.apType}
                    schedules={
                      this.props.getDoctor.doctors.data[
                        this.state.doctorProfile
                      ].schedules
                    }
                  />

                  <DateTimeSelection
                    apType={this.state.apType}
                    key={this.state.selectedTime + this.state.selectedDate}
                    getDoctor={this.props.getDoctor}
                    selectDate={this.selectDate}
                    selectedTime={this.state.selectedTime}
                    selectedDate={this.state.selectedDate}
                    months={months}
                    getAppointment={this.props.getAppointment}
                    changeTime={this.changeTime}
                    formatAMPM={this.formatAMPM}
                    keyId={1}
                    doSubmit={this.doSubmit}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div className="tab-sidebar">
          {this.state.sideBar === "appointment" && (
            <div className="side-pannel-box">
              {this.state.preview && (
                <OrderReview
                  changePreview={this.changePreview}
                  doctorProfile={this.state.doctorProfile}
                  getDoctor={this.props.getDoctor}
                  months={months}
                  orderDetail={this.state.orderDetail}
                  formatAMPM={this.formatAMPM}
                  payNow={this.payNow}
                  clinic={this.state.apType === 'clinic' ? true : false}
                />
              )}

              {!this.state.preview && (
                <React.Fragment>
                  <div className="tab-sidebar-top d-flex">
                    <a
                      href="#!"
                      className="close"
                      onClick={(e) => this.removeBodyClass(e)}
                    >
                      <div className="icon-img">
                        <img src={arrowtr} alt="" />
                      </div>
                    </a>
                    <div className="icon-text">
                      <h4>
                        {this.state.apType === "clinic"
                          ? "Book a slot for Clinic visit"
                          : "Book a slot for Online visit"}{" "}
                      </h4>
                      <div className="pi-location d-none d-flex align-items-center">
                        <div className="mr-3">
                          <img src="include/images/pin.svg" alt="" />
                        </div>
                        <span>
                          {this.state.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].clinic_details &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].clinic_details.city}
                          ,
                        </span>{" "}
                        <span>
                          {" "}
                          {this.state.doctorProfile !== "" &&
                            this.props.getDoctor.doctors.data[
                              this.state.doctorProfile
                            ].clinic_details.state}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="pannel-top">
                      <a
                        href="#!"
                        className="close"
                        onClick={(e) => this.removeBodyClass(e)}
                      >
                        <img src={crossIcon} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="pannel-tab-box">
                    <TypeSwitch
                      schedules={
                        this.props.getDoctor.doctors.data &&
                        this.props.getDoctor.doctors.data[
                          this.state.doctorProfile
                        ] &&
                        this.props.getDoctor.doctors.data[
                          this.state.doctorProfile
                        ].schedules
                      }
                      changeSwitch={this.changeSwitch}
                      loadDate={this.props.loadDate}
                      apType={this.state.apType}
                    />
                    <DateTimeSelection
                      apType={this.state.apType}
                      key={this.state.selectedTime + this.state.selectedDate}
                      getDoctor={this.props.getDoctor}
                      selectDate={this.selectDate}
                      selectedTime={this.state.selectedTime}
                      selectedDate={this.state.selectedDate}
                      months={months}
                      getAppointment={this.props.getAppointment}
                      changeTime={this.changeTime}
                      formatAMPM={this.formatAMPM}
                      keyId={1}
                      doSubmit={this.doSubmit}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className="overlay" onClick={(e) => this.removeBodyClass(e)}></div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadFieldType: (param) => dispatch(loadFieldType(param)),
  loadFilterCity: () => dispatch(loadFilterCity()),
  loadDoctor: (data) => dispatch(loadDoctors(data)),
  loadDate: () => dispatch(loadDate()),
  loadSlot: (drId, date, type) => dispatch(loadSlot(drId, date, type)),
  createOrder: (data, callback) => dispatch(createOrder(data, callback)),
  updateOrder: (data, callback) => dispatch(updateOrder(data, callback)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  getDoctor: getDoctor(state),
  getAppointment: getAppointment(state),
  getPatient: getPatient(state),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Doctors)
);
