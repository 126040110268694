import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import {
  getDoctorPatient,
  loadAllDoctorpatient,
  updateStar,
} from '../../../store/doctorPatient'
import PatientList from './patientList'
import { getDoctor } from '../../../store/doctor'

const AllPatient = props => {
  const [content, setContent] = useState(false)
  const [id, setId] = useState('')
  const [page, setPage] = useState(1)
  const [initialData, setInitialData] = useState([])
  const [paginate, setPaginate] = useState([])

  useEffect(() => {
    document.body.className = 'dashboard'
    props.loadAllDoctorpatient({ page: page }, null)
  }, [])

  const { doctorPatient, loading } = props

  useEffect(() => {
    let { data, pagination } = doctorPatient
    setPaginate(pagination)
    setInitialData([...data])
  }, [doctorPatient])

  const loadFunc = () => {
    if (
      Math.ceil(
        props.doctorPatient.pagination.filtered_record /
          props.doctorPatient.pagination.record_per_page
      ) > page
    ) {
      props.loadAllDoctorpatient(
        {
          page: page + 1,
        },
        callbackLoad
      )
      setPage(page + 1)
    }
  }

  const callbackLoad = res => {
    if (res && res.status === 200) {
      const { data, pagination } = res.data
      setPaginate(pagination)
      setInitialData([...initialData, ...data])
    }
  }

  const toggleContent = id => {
    setContent(!content)
    setId(id)
  }

  const UpdateStar = id => {
    props.updateStar(id, callback)
  }

  const callback = res => {
    if (res && res.status === 200) {
      props.loadAllDoctorpatient(
        {
          page: page,
        },
        null
      )
    }
  }

  return (
    <>
      <ul class='chat-member-list bordered has-shadow '>
        <PatientList
          doctorPatient={initialData}
          pagination={paginate}
          loadFunc={loadFunc}
          loading={loading}
          UpdateStar={UpdateStar}
          toggleContent={toggleContent}
          id={id}
          content={content}
        />
      </ul>
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  loadAllDoctorpatient: (params, callback) =>
    dispatch(loadAllDoctorpatient(params, callback)),
  updateStar: (id, callback) => dispatch(updateStar(id, callback)),
})
const mapStateToProps = state => ({
  doctorPatient: getDoctorPatient(state).doctorPatient,
  loading: getDoctorPatient(state).loading,
  doctorProfile: getDoctor(state).profile,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllPatient)
)
