import React from 'react'
import Moment from 'moment'
import { connect } from 'react-redux'
import Form from '../../../common/form/form'
import { withRouter } from 'react-router-dom'
import star from '../../../../include/images/start-0.svg'
import close from '../../../../include/images/icon-close.svg'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import dark from '../../../../include/images/doument-icon-dark.svg'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  getAssessment,
  loadDetailAssessment,
} from '../../../../store/assessment'
import {
  loadAnswerFollowup,
  addAnswerFollowup,
  getFollowup,
  loadTherapy,
} from '../../../../store/followUp'
import { loadAnswerBio, getBio } from '../../../../store/bioPsycosocial'
import moment from 'moment'
import _ from 'lodash'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import { toast } from 'react-toastify'
import closeIcon from '../../../../include/images/close.svg'
import ReactTooltip from 'react-tooltip'
import { getDoctor } from '../../../../store/doctor'
import AvtarImage from '../../../common/avtarImage'
import BioForm from './bioPycsocial'
import { loadForPatient } from '../../../../store/prescription'

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

class FollowUpForm extends Form {
  state = {
    data: {},
    is_submitted: false,
    therephy_method: '',
    techniques: '',
    technique: [],
    popup2: false,
    bioForm: false,
  }

  toggleBio = () => {
    this.setState({ bioForm: !this.state.bioForm })
  }
  componentDidMount() {
    //console.log('this.props.caseRecord', this.props.caseRecord)
    if (this.props?.doctorProfile?.field_type?.name !== 'Psychologist') {
      this.props.loadDetailAssessment('60e008b712b30d4b9468b9e4')
    } else {
      this.props.loadDetailAssessment('60eff53584694c08054f57c4')
    }
    if (this.props.id) {
      this.props.loadAnswerFollowup({
        patient_id: this.props.id,
        appointment_id: this.props.appointment_id,
      })
    }
    this.props.loadTherapy()
    this.props.loadAnswerBio(this.props.id)
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.id !== prevProps.id ||
      this.props.appointment_id !== prevProps.appointment_id
    ) {
      this.setState(
        {
          data: {},
          is_submitted: false,
          therephy_method: '',
          techniques: '',
          technique: [],
          popup2: false,
          bioForm: false,
        },
        () => {
          this.props.loadAnswerFollowup({
            patient_id: this.props.id,
            appointment_id: this.props.appointment_id,
          })
          if (this.props.answer.data !== prevProps.answer.data) {
            this.setState({
              is_submitted:
                this.props.answer.data && this.props.answer.data.is_submitted,
              techniques:
                this.props.answer.data && this.props.answer.data.techniques,
              therephy_method:
                this.props.answer.data &&
                this.props.answer.data.therephy_method,
              data:
                this.props.answer.data && this.props.answer.data.formData
                  ? this.props.answer.data.formData
                  : {},
            })
          }
          if (this.state.therephy_method !== prevState.therephy_method) {
            const parent = this.props.therapy.find(
              e => e.title === this.state.therephy_method
            )
            this.setState({ technique: parent && parent.techniques })
          }
        }
      )
    }
  }
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
  }
  toggleClose = () => {
    this.setState({
      is_submitted: false,
      data: {},
      therephy_method: '',
      techniques: '',
      technique: [],
    })
    this.props.toggle2()
  }
  handleSubmit = e => {
    if (e) e.preventDefault()
    const formData = { ...this.state.data }
    formData.followup = true
    formData['Provisional Diagnosis for patient'] =
      this.props.caseRecord && this.props.caseRecord.diagnosis
        ? this.props.caseRecord.diagnosis.map(ed => ed.value).join(', ')
        : ''
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        appointment_id: this.props.appointment_id,
        patient_id: this.props.patient_id,
        formData,
        is_submitted: this.state.is_submitted,
        therephy_method: this.state.therephy_method,
        techniques: this.state.techniques,
      }
      this.props.addAnswerFollowup(payLoad, this.callBack)
    } else {
      payLoad = {
        appointment_id: this.props.appointment_id,
        patient_id: this.props.patient_id,
        formData,
        therephy_method: this.state.therephy_method,
        techniques: this.state.techniques,
      }
      this.props.addAnswerFollowup(payLoad, this.callBack)
    }
  }
  callBack = res => {
    if (res && res.status === 200) {
      if (this.props.toggle2) {
        this.props.loadRefresh()
        this.props.toggle2()
      } else {
        this.props.toggle1()
      }
      this.props.loadList({
        appointment_date: this.props.video
          ? moment().format('YYYY MM DD')
          : this.props.appointment_date,
      })
      this.props.loadAnswerFollowup({
        patient_id: this.props.id,
        appointment_id: this.props.appointment_id,
      })

      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        data: {},
      })
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        data: {},
        therephy_method: '',
        techniques: '',
        technique: [],
      })
    }
  }
  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  render() {
    const { detail, caseRecord, answer } = this.props
    const { data } = answer

    console.log('answer update', this.state.data)
    return (
      <>
        <div
          class='sidebar-box list-box'
          id='prescriptions_side_panel_1'
          style={{ right: this.props.isShow2 === true ? '0px' : '-100%' }}>
          <div class='sidebar-head d-flex align-items-center'>
            <div
              className='d-flex align-items-center justify-content-between'
              style={{ width: '93%' }}>
              <h5>Follow Up Notes</h5>
              <div
                class='ml-auto bio-button'
                style={{ cursor: 'pointer' }}
                onClick={this.toggleBio}>
                {this.props.bioAnswer && this.props.bioAnswer.is_submitted ? (
                  <h5 className='tag'>Edit BioPsychoSocial</h5>
                ) : (
                  <h5 className='tag'>Add BioPsychoSocial</h5>
                )}
              </div>
            </div>
            <div class='sidebar-right ml-auto d-flex align-items-centers'>
              <div class='close-img' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            </div>
          </div>
          <div class='sidebar-body height-auto p-0'>
            <div class='sidebar-inner-content'>
              <div class='sic-head d-flex flex-wrap justify-content-between'>
                <div class='sic-head-left flex-grow-1 d-flex '>
                  <div class='sic-head-img'>
                    {this.props.image ? (
                      <img
                        src={process.env.REACT_APP_S3URL + this.props.image}
                        alt=''
                      />
                    ) : (
                      <AvtarImage />
                    )}
                  </div>
                  <div class='sic-head-text'>
                    <h4>
                      {this.props.first_name + ' ' + this.props.last_name}{' '}
                      <i>
                        {' '}
                        {this.props.star === true ? (
                          <img src={bluestar} alt='star-icon' />
                        ) : (
                          <img src={star} alt='star-icon' />
                        )}
                      </i>
                    </h4>
                    <p>{this.props.yesmindy_identification_number}</p>
                  </div>
                </div>
              </div>
              <div class='fun-box'>
                <div class='files-box d-flex flex-wrap align-items-center'>
                  <img src={dark} alt='' />
                  <div class='files-text-box'>
                    <h3>Follow Up Note</h3>
                  </div>
                </div>
                <div class='block-seprator'></div>
                <div class='fun-body-box'>
                  <ul class='fun-list d-flex flex-wrap'>
                    <li class='fun-item'>
                      <strong>
                        {this.props.first_name + ' ' + this.props.last_name}{' '}
                      </strong>
                      <p>Age: {moment().diff(this.props.age, 'years')} yrs</p>
                      <p>Gender: {this.props.sex}</p>
                    </li>
                    <li class='fun-item'>
                      <strong>
                        UHID:{this.props.yesmindy_identification_number}
                      </strong>
                      <p>Session Date:</p>
                      <p>{Moment(this.props.time).utc().format('lll')}</p>
                    </li>
                  </ul>
                </div>
                <div class='block-seprator'></div>
                <div className='sidebar-body'>
                  <div class='pd-left-content'>
                    <div class='radio-group-box'>
                      <h5>Diagnossis</h5>
                      {caseRecord &&
                        caseRecord.diagnosis &&
                        caseRecord.diagnosis.map(eds => (
                          <label className='form-check-label'>
                            {eds.value}
                          </label>
                        ))}
                    </div>
                  </div>
                  {detail.questions &&
                    detail.questions.map((e, i) => (
                      <>
                        {e.questions[0].section &&
                          e.questions[0].section?.name && (
                            <div class='heading-with-icon-box d-flex align-items-center'>
                              <div class='hwi-heading'>
                                <h3>
                                  {e.questions[0].section &&
                                    e.questions[0].section?.name}
                                </h3>
                              </div>
                            </div>
                          )}
                        {e.questions.map((e1, i1) => (
                          <>
                            <ReactTooltip
                              id='fform1'
                              className='testPurple'
                              html={true}
                            />
                            {!e1.parent_question_id && (
                              <>
                                {e1.childs ? (
                                  <>
                                    <div
                                      className='check-box-group mb-2'
                                      key={i1}>
                                      <label>{e1.question}</label>
                                      {e1.response_type ===
                                        'free_text_pill_box' && (
                                        <CreatableSelect
                                          noOptionsMessage={() =>
                                            'Type and press enter to add options'
                                          }
                                          isMulti
                                          classNamePrefix='select'
                                          isDisabled={false}
                                          isLoading={false}
                                          name='color'
                                          styles={this.customStyles}
                                          value={
                                            this.state.data &&
                                            this.state.data[e1.question]
                                          }
                                          onChange={e => {
                                            const data = { ...this.state.data }
                                            data[e1.question] = e
                                            this.setState({ data })
                                          }}
                                        />
                                      )}
                                      {e1.response_type ===
                                        'multiselect_dropdown' && (
                                        <Select
                                          isMulti
                                          className='basic-single'
                                          classNamePrefix='select'
                                          isDisabled={false}
                                          isLoading={false}
                                          isClearable={false}
                                          isRtl={false}
                                          isSearchable={false}
                                          name='color'
                                          styles={this.customStyles}
                                          options={e1.options.map(ex => ({
                                            label: ex.title,
                                            value: ex.title,
                                          }))}
                                          value={
                                            this.state.data &&
                                            this.state.data[e1.question]
                                          }
                                          onChange={e => {
                                            const data = { ...this.state.data }
                                            data[e1.question] = e
                                            this.setState({ data })
                                          }}
                                        />
                                      )}

                                      {e1.question ===
                                        'Provisional Diagnosis for patient' && (
                                        <div class='pd-left-content'>
                                          {caseRecord &&
                                            caseRecord.diagnosis &&
                                            caseRecord.diagnosis.map(eds => (
                                              <div class='d-flex align-items-center '>
                                                <strong>{eds.value}</strong>
                                                <div class='custom-dropright'>
                                                  <div>
                                                    <a
                                                      // data-tip={eds.diagnosis}
                                                      data-for='fform1'
                                                      class='custom-dropdown-toggle'
                                                      href='javascript:void(0)'
                                                      data-tip='Adult-onset fluency disorder'
                                                      currentitem='false'>
                                                      <i
                                                        class='fas fa-info-circle'
                                                        aria-hidden='true'></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      )}
                                      {e1.response_type === 'text' && (
                                        <input
                                          type='text'
                                          name=''
                                          onChange={e => {
                                            const data = {
                                              ...this.state.data,
                                            }
                                            if (e) {
                                              data[e1.question] = e.target.value
                                              this.setState({ data })
                                            }
                                          }}
                                          className='form-control'
                                          value={
                                            this.state.data &&
                                            this.state.data[e1.question] &&
                                            typeof this.state.data[
                                              e1.question
                                            ] == 'string'
                                              ? this.state.data[e1.question]
                                              : this.state.data[e1.question] &&
                                                this.state.data[e1.question]
                                                  .length > 0 &&
                                                this.state.data[e1.question]
                                                  .map(el => el.value)
                                                  .join(', ')
                                          }
                                          placeholder='Write here…'
                                        />
                                      )}
                                      {e1.response_type === 'text_area' && (
                                        <textarea
                                          type='text'
                                          onChange={e => {
                                            const data = {
                                              ...this.state.data,
                                            }
                                            if (e) {
                                              data[e1.question] = e.target.value
                                              this.setState({ data })
                                            }
                                          }}
                                          className='form-control'
                                          value={
                                            this.state.data &&
                                            this.state.data[e1.question] &&
                                            typeof this.state.data[
                                              e1.question
                                            ] == 'string'
                                              ? this.state.data[e1.question]
                                              : this.state.data[e1.question] &&
                                                this.state.data[e1.question]
                                                  .length > 0 &&
                                                this.state.data[e1.question]
                                                  .map(el => el.value)
                                                  .join(', ')
                                          }
                                          placeholder=''></textarea>
                                      )}

                                      <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                        {e1.response_type === 'checkbox' &&
                                          e1.options.map((e2, i2) => (
                                            <div
                                              className='form-check'
                                              key={i2}
                                              onChange={event => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                if (event) {
                                                  const findData =
                                                    data[e1.question] &&
                                                    data[e1.question].find(
                                                      echeck =>
                                                        echeck ===
                                                        event.target.value
                                                    )

                                                  if (findData) {
                                                    data[e1.question] = data[
                                                      e1.question
                                                    ].filter(
                                                      eqs =>
                                                        eqs !==
                                                        event.target.value
                                                    )
                                                  } else {
                                                    if (data[e1.question]) {
                                                      data[e1.question].push(
                                                        event.target.value
                                                      )
                                                    } else {
                                                      data[e1.question] = [
                                                        event.target.value,
                                                      ]
                                                    }
                                                  }

                                                  this.setState({ data })
                                                }
                                              }}>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value={e2.title}
                                                checked={
                                                  this.state.data &&
                                                  this.state.data[
                                                    e1.question
                                                  ] &&
                                                  this.state.data[
                                                    e1.question
                                                  ].find(
                                                    echeck =>
                                                      echeck === e2.title
                                                  )
                                                }
                                                name={e2.title + e1._id}
                                                id={e2.title + e._id}
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor={e2.title + e._id}>
                                                <span></span>
                                                {e2.title}
                                              </label>
                                            </div>
                                          ))}
                                      </div>
                                      <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                        {e1.response_type === 'radio' &&
                                          e1.options.map((e2, i2) => (
                                            <div
                                              className='form-check'
                                              key={i2}
                                              onChange={e => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                if (e) {
                                                  data[e1.question] =
                                                    e.target.value
                                                  this.setState({ data })
                                                }
                                              }}>
                                              <input
                                                className='form-check-input'
                                                type='radio'
                                                checked={
                                                  this.state.data[
                                                    e1.question
                                                  ] === e2.title
                                                }
                                                value={e2.title}
                                                id={e2.title + e1._id}
                                                name={e2.title + e1._id}
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor={e2.title + e1._id}>
                                                <span
                                                  style={{
                                                    marginLeft: '2.75rem',
                                                  }}></span>
                                                {e2.title}
                                              </label>
                                            </div>
                                          ))}

                                        {e1.response_type === 'dropdown' && (
                                          <select
                                            class='form-control'
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e1.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            value={
                                              this.state.data &&
                                              this.state.data[e1.question]
                                            }>
                                            <option value='' disabled selected>
                                              Please Select
                                            </option>

                                            {e1.options.map((e2, i2) => (
                                              <option key={i2}>
                                                {e2.title}{' '}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>

                                    {e1.childs.map((e3, i3) => (
                                      <div
                                        className='check-box-group mb-2'
                                        key={i3}
                                        style={{ marginTop: '0' }}>
                                        <label>{e3.question}</label>

                                        {e3.response_type === 'text' && (
                                          <input
                                            type='text'
                                            name=''
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e3.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e3.question]
                                            }
                                            placeholder='Write here…'
                                          />
                                        )}
                                        {e3.response_type === 'text_area' && (
                                          <textarea
                                            type='text'
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e3.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            className='form-control'
                                            value={
                                              this.state.data &&
                                              this.state.data[e3.question]
                                            }
                                            placeholder=''></textarea>
                                        )}

                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e1.response_type === 'checkbox' &&
                                            e1.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={event => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (event) {
                                                    const findData =
                                                      data[e1.question] &&
                                                      data[e1.question].find(
                                                        echeck =>
                                                          echeck ===
                                                          event.target.value
                                                      )

                                                    if (findData) {
                                                      data[e1.question] = data[
                                                        e1.question
                                                      ].filter(
                                                        eqs =>
                                                          eqs !==
                                                          event.target.value
                                                      )
                                                    } else {
                                                      if (data[e1.question]) {
                                                        data[e1.question].push(
                                                          event.target.value
                                                        )
                                                      } else {
                                                        data[e1.question] = [
                                                          event.target.value,
                                                        ]
                                                      }
                                                    }

                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value={e2.title}
                                                  checked={
                                                    this.state.data &&
                                                    this.state.data[
                                                      e1.question
                                                    ] &&
                                                    this.state.data[
                                                      e1.question
                                                    ].find(
                                                      echeck =>
                                                        echeck === e2.title
                                                    )
                                                  }
                                                  name={e2.title + e1._id}
                                                  id={e2.title + e._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e._id}>
                                                  <span></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                          {e3.response_type === 'radio' &&
                                            e3.options.map((e2, i2) => (
                                              <div
                                                className='form-check'
                                                key={i2}
                                                onChange={e => {
                                                  const data = {
                                                    ...this.state.data,
                                                  }
                                                  if (e) {
                                                    data[e3.question] =
                                                      e.target.value
                                                    this.setState({ data })
                                                  }
                                                }}>
                                                <input
                                                  className='form-check-input'
                                                  type='radio'
                                                  checked={
                                                    this.state.data[
                                                      e3.question
                                                    ] === e2.title
                                                  }
                                                  value={e2.title}
                                                  id={e2.title + e3._id}
                                                  name={e2.title + e3._id}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor={e2.title + e3._id}>
                                                  <span
                                                    style={{
                                                      marginLeft: '2.75rem',
                                                    }}></span>
                                                  {e2.title}
                                                </label>
                                              </div>
                                            ))}

                                          {e3.response_type === 'dropdown' && (
                                            <select
                                              class='form-control'
                                              onChange={e => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                if (e) {
                                                  data[e3.question] =
                                                    e.target.value
                                                  this.setState({ data })
                                                }
                                              }}
                                              value={
                                                this.state.data &&
                                                this.state.data[e3.question]
                                              }>
                                              <option
                                                value=''
                                                disabled
                                                selected>
                                                Please Select
                                              </option>

                                              {e3.options.map((e2, i2) => (
                                                <option key={i2}>
                                                  {e2.title}{' '}
                                                </option>
                                              ))}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <div
                                    className='check-box-group mb-2'
                                    key={i1}>
                                    <ReactTooltip
                                      id='fform'
                                      className='testPurple'
                                      html={true}
                                    />
                                    <label>{e1.question}</label>
                                    {e1.response_type ===
                                      'free_text_pill_box' && (
                                      <CreatableSelect
                                        noOptionsMessage={() =>
                                          'Type and press enter to add options'
                                        }
                                        isMulti
                                        classNamePrefix='select'
                                        isDisabled={false}
                                        isLoading={false}
                                        name='color'
                                        styles={this.customStyles}
                                        value={
                                          this.state.data &&
                                          this.state.data[e1.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e1.question] = e
                                          this.setState({ data })
                                        }}
                                      />
                                    )}
                                    {e1.response_type ===
                                      'multiselect_dropdown' && (
                                      <Select
                                        isMulti
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={false}
                                        name='color'
                                        styles={this.customStyles}
                                        options={e1.options.map(ex => ({
                                          label: ex.title,
                                          value: ex.title,
                                        }))}
                                        value={
                                          this.state.data &&
                                          this.state.data[e1.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e1.question] = e
                                          this.setState({ data })
                                        }}
                                      />
                                    )}

                                    {e1.question ===
                                      'Provisional Diagnosis for patient' && (
                                      <div class='pd-left-content'>
                                        {caseRecord &&
                                          caseRecord.diagnosis &&
                                          caseRecord.diagnosis.map(eds => (
                                            <div class='d-flex align-items-center '>
                                              <strong>{eds.value}</strong>
                                              <div class='custom-dropright'>
                                                <div>
                                                  <a
                                                    // data-tip={eds.diagnosis}
                                                    data-for='fform'
                                                    class='custom-dropdown-toggle'
                                                    href='javascript:void(0)'
                                                    data-tip='Adult-onset fluency disorder'
                                                    currentitem='false'>
                                                    <i
                                                      class='fas fa-info-circle'
                                                      aria-hidden='true'></i>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                    {e1.response_type === 'text' && (
                                      <input
                                        type='text'
                                        name=''
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          if (e) {
                                            data[e1.question] = e.target.value
                                            this.setState({ data })
                                          }
                                        }}
                                        className='form-control'
                                        value={
                                          this.state.data &&
                                          this.state.data[e1.question] &&
                                          typeof this.state.data[e1.question] ==
                                            'string'
                                            ? this.state.data[e1.question]
                                            : this.state.data[e1.question] &&
                                              this.state.data[e1.question]
                                                .length > 0 &&
                                              this.state.data[e1.question]
                                                .map(el => el.value)
                                                .join(', ')
                                        }
                                        placeholder='Write here…'
                                      />
                                    )}
                                    {e1.response_type === 'text_area' && (
                                      <textarea
                                        style={{ padding: '10px' }}
                                        type='text'
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          if (e) {
                                            data[e1.question] = e.target.value
                                            this.setState({ data })
                                          }
                                        }}
                                        className='form-control'
                                        value={
                                          this.state.data &&
                                          this.state.data[e1.question] &&
                                          typeof this.state.data[e1.question] ==
                                            'string'
                                            ? this.state.data[e1.question]
                                            : this.state.data[e1.question] &&
                                              this.state.data[e1.question]
                                                .length > 0 &&
                                              this.state.data[e1.question]
                                                .map(el => el.value)
                                                .join(', ')
                                        }
                                        placeholder=''></textarea>
                                    )}

                                    <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                      {e1.response_type === 'checkbox' &&
                                        e1.options.map((e2, i2) => (
                                          <div
                                            className='form-check'
                                            key={i2}
                                            onChange={event => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (event) {
                                                const findData =
                                                  data[e1.question] &&
                                                  data[e1.question].find(
                                                    echeck =>
                                                      echeck ===
                                                      event.target.value
                                                  )

                                                if (findData) {
                                                  data[e1.question] = data[
                                                    e1.question
                                                  ].filter(
                                                    eqs =>
                                                      eqs !== event.target.value
                                                  )
                                                } else {
                                                  if (data[e1.question]) {
                                                    data[e1.question].push(
                                                      event.target.value
                                                    )
                                                  } else {
                                                    data[e1.question] = [
                                                      event.target.value,
                                                    ]
                                                  }
                                                }

                                                this.setState({ data })
                                              }
                                            }}>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              value={e2.title}
                                              checked={
                                                this.state.data &&
                                                this.state.data[e1.question] &&
                                                this.state.data[
                                                  e1.question
                                                ].find(
                                                  echeck => echeck === e2.title
                                                )
                                              }
                                              name={e2.title + e1._id}
                                              id={e2.title + e._id}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={e2.title + e._id}>
                                              <span></span>
                                              {e2.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>
                                    <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                      {e1.response_type === 'radio' &&
                                        e1.options.map((e2, i2) => (
                                          <div
                                            className='form-check'
                                            key={i2}
                                            onChange={e => {
                                              const data = {
                                                ...this.state.data,
                                              }
                                              if (e) {
                                                data[e1.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              checked={
                                                this.state.data &&
                                                this.state.data[e1.question] ===
                                                  e2.title
                                              }
                                              value={e2.title}
                                              id={e2.title + e1._id}
                                              name={e2.title + e1._id}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={e2.title + e1._id}>
                                              <span
                                                style={{
                                                  marginLeft: '2.75rem',
                                                }}></span>
                                              {e2.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>
                                    {e1.response_type === 'dropdown' && (
                                      <select
                                        class='form-control'
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          if (e) {
                                            data[e1.question] = e.target.value
                                            this.setState({ data })
                                          }
                                        }}
                                        value={
                                          this.state.data &&
                                          this.state.data[e1.question]
                                        }>
                                        <option value='' disabled selected>
                                          Please Select
                                        </option>

                                        {e1.options.map((e2, i2) => (
                                          <option key={i2}>{e2.title} </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </>
                    ))}
                </div>
                {this.props?.doctorProfile?.field_type?.name ===
                  'Psychologist' && (
                  <div className='sidebar-body'>
                    <div className='check-box-group'>
                      <label>Therapy Method</label>
                      <select
                        class='form-control'
                        onChange={e => {
                          if (e) {
                            this.setState({
                              therephy_method: e.target.value,
                            })
                          }
                        }}
                        value={this.state.therephy_method}>
                        <option value='' disabled selected>
                          Please Select
                        </option>

                        {this.props.therapy.map((e2, i2) => (
                          <option key={i2}>{e2.title} </option>
                        ))}
                      </select>
                    </div>

                    <div className='check-box-group'>
                      <label>Techniques</label>
                      <select
                        class='form-control'
                        onChange={e => {
                          if (e) {
                            this.setState({ techniques: e.target.value })
                          }
                        }}
                        value={this.state.techniques}>
                        <option value='' disabled selected>
                          Please Select
                        </option>

                        {this.state.technique &&
                          this.state.technique.map((e2, i2) => (
                            <option key={i2}>{e2} </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
                <div
                  className='sidebar-footer d-flex align-items-center justify-content-between'
                  style={{ padding: '26px' }}>
                  {(this.props.answer.data &&
                    this.props.answer.data.is_submitted === false) ||
                  this.props.answer.data === null ? (
                    <>
                      <button
                        onClick={this.handleSubmit}
                        className='btn btn-outline no-shadow'>
                        Save
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                  <button
                    className='btn btn-primary'
                    onClick={e => {
                      this.setState({ is_submitted: true }, () =>
                        this.handleSubmit()
                      )
                    }}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow2 === true ? 'overlay3 show' : 'overlay4'
          }></div>
        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.popup2}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.setState({ popup2: false })
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <p>
                Once submitted you will not be able to modify the document
                again.
              </p>
              <div class='modal-action-btn'>
                <a
                  href='javascript:void (0)'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    this.setState({ is_submitted: true })
                    if (this.state.is_submitted === true)
                      return (
                        this.handleSubmit(e), this.setState({ popup2: false })
                      )
                  }}>
                  Ok
                </a>
              </div>
            </div>
          </>
        </Modal>
        <BioForm
          bioForm={this.state.bioForm}
          toggleBio={this.toggleBio}
          patientId={this.props && this.props.id}
        />
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadTherapy: () => dispatch(loadTherapy()),
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerFollowup: params => dispatch(loadAnswerFollowup(params)),
  addAnswerFollowup: (data, callBack) =>
    dispatch(addAnswerFollowup(data, callBack)),
  loadAnswerBio: id => dispatch(loadAnswerBio(id)),
  loadForPatient: id => dispatch(loadForPatient(id)),
})

const mapStateToProps = state => ({
  doctorProfile: getDoctor(state).profile,
  detail: getAssessment(state).detail,
  answer: getFollowup(state).answer,
  loading: getFollowup(state).answerLoading,
  therapy: getFollowup(state).therapy,
  bioAnswer: getBio(state).bioAnswer,
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FollowUpForm)
)
