import Header from "../home/header";
import Footer from "../home/footer";
import { connect } from "react-redux";
import React, { Component } from "react";
import { getMisc, loadConstant } from "../../store/misc";
import { loadDoctorFeatured, getDoctor } from "../../store/doctor";
import _ from "lodash";
import { Link } from "react-router-dom";

class OurExperts extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadDoctorFeatured("featured_on_expert");
  };

  render() {
    const { constants } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const { featured } = this.props;

    const monthName = (item) => item.field_type.name;
    const result = _.groupBy(featured, monthName);
    const groupArrays = Object.keys(result).map((category) => {
      return {
        category,
        doc: result[category],
      };
    });
    const array1 = [
      "Psychiatrist",
      "Psychologist",
      "Psychosocial Worker",
      "Counsellor",
    ];
    var expert = [];
    array1.forEach((element) => {
      const abc = groupArrays.find(({ category }) => category == element);
      expert.push(abc);
    });
    console.log("expert", expert);
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="content-container expert-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="heading">
                    <h4>
                      Meet Our <span>Experts</span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {expert &&
            expert[0] != undefined &&
            expert.map(
              (e2, i) =>
                e2 && (
                  <div class="experts-container overflow-hidden pt-0">
                    <div class="container container1">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="heading-with-line">
                            <h3>
                              <span>{e2.category}</span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-10 offset-md-1">
                          <ul class="experts-list new-experts-list d-flex flex-wrap">
                            {e2.doc.map((featured, i) => (
                              <li class="expert-item">
                                <div class="expert-box">
                                  <a
                                    href={
                                      process.env.REACT_APP_BASEURL +
                                      "dr/" +
                                      featured.user_name
                                    }
                                    target="_blank"
                                    style={{ width: "100%" }}
                                  >
                                    <div class="expert-img">
                                      <img
                                        src={
                                          process.env.REACT_APP_S3URL +
                                          featured.image_url
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <h4>{featured.name}</h4>
                                    <p>
                                      {featured.graduation &&
                                        featured.graduation.education + ", "}

                                      {featured.post_graduation &&
                                        featured.post_graduation.education}

                                      {featured.super_speciality &&
                                        ", " +
                                          featured.super_speciality.education}
                                    </p>
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {e2.category === "Psychiatrist" && (
                            <div class="viewmore-btn expert-btn text-center">
                              <Link
                                to={
                                  this.state.patient
                                    ? "patient/doctors"
                                    : "patient/appointment"
                                }
                                className="btn btn-default btn-lg"
                              >
                                Book an appointment
                              </Link>
                            </div>
                          )}
                          {e2.category === "Psychologist" && (
                            <div class="viewmore-btn expert-btn text-center">
                              <Link
                                to={
                                  this.state.patient
                                    ? "patient/doctors"
                                    : "patient/appointment"
                                }
                                className="btn btn-default btn-lg"
                              >
                                Book an appointment
                              </Link>
                            </div>
                          )}
                          {e2.category === "Counsellor" && (
                            <div class="viewmore-btn expert-btn text-center">
                              <Link
                                to="patient/chat"
                                className="btn btn-default btn-lg"
                                target="_blank"
                              >
                                Chat with us{" "}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  featured: getDoctor(state).featured,
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadDoctorFeatured: (params) => dispatch(loadDoctorFeatured(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OurExperts);
