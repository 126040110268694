import React, { Component } from "react";
import bluestar from "../../../include/images/blue-star-icon.svg";
import star from "../../../include/images/start-0.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { getPrescription } from "../../../store/prescription";
import AvtarImage from "../../common/avtarImage";
import NoRecord from "../../common/noRecord";
import moment from "moment";

class TaskTab extends Component {
  render() {
    const { list } = this.props;
    return (
      <>
        <div
          class="tab-pane fade show active"
          id="prescriptions"
          role="tabpanel"
          aria-labelledby="prescriptions-tab"
        >
          <div class="tb-body">
            {list.length === 0 && (
              <NoRecord
                noLogo={true}
                header="No appointment today for task allotment."
                text="Allotment of tasks like Worksheets/ Assessments/ Homework will help you establish deeper clinical engagement with your patients. Hence, fewer dropouts"
              />
            )}
            <ul class="chat-member-list bordered">
              {list.map((e, i) => (
                <li
                  class="cm-item"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/doctor/patient-detail/task",
                      state: e._id,
                    })
                  }
                >
                  <div class="cm-box d-flex flex-wrap">
                    <div class="person-details-box d-flex align-items-center flex-grow-1">
                      <div class="person-img">
                        {e.thumb ? (
                          <img
                            src={process.env.REACT_APP_S3URL + e.thumb}
                            alt=""
                          />
                        ) : (
                          <AvtarImage />
                        )}
                      </div>
                      <div class="person-text-box">
                        <h3>
                          {e.first_name + " " + e.last_name}{" "}
                          <i>
                            {e.isStar === true ? (
                              <img src={bluestar} alt="star-icon" />
                            ) : (
                              <img src={star} alt="star-icon" />
                            )}
                          </i>
                        </h3>
                        <p>{e.yesmindy_identification_number}</p>
                      </div>
                    </div>
                    {
                      <div class="pd-right-content">
                        {e.lastAlloted.created_at && (
                          <div>
                            Last Allotment Date:{" "}
                            {moment(e.lastAlloted.created_at).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        )}
                        {e.lastSubmited.updated_at && (
                          <div>
                            Last Submission Date:{" "}
                            {moment(e.lastSubmited.updated_at).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        )}
                      </div>
                    }
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  list: getPrescription(state).list,
});
export default withRouter(connect(mapStateToProps, null)(TaskTab));
