import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "seo",
  initialState: {
    seo: {},
    termTemplate: {},
    contract: {},
    loadingTermTemplate: false,
    loading: false,
    loadingcontract: false,
    lastFetch: null,
  },
  reducers: {
    seoRequested: (seo, action) => {
      seo.loading = true;
    },
    seoRecieved: (seo, action) => {
      seo.seo = action.payload.data;
      seo.loading = false;
      seo.lastFetch = Date.now();
    },
    seoRequestFailed: (seo, action) => {
      seo.seo = {};
      seo.loading = false;
    },

    termTemplateRequested: (seo, action) => {
      seo.termTemplate = {};
      seo.loadingTermTemplate = true;
    },
    termTemplateRecieved: (seo, action) => {
      seo.termTemplate = action.payload.data ? action.payload.data : {};
      seo.loadingTermTemplate = false;
      seo.lastFetch = Date.now();
    },
    termTemplateRequestFailed: (seo, action) => {
      seo.termTemplate = {};
      seo.loadingTermTemplate = false;
    },

    contractTemplateRequested: (seo, action) => {
      seo.contract = {};
      seo.loadingcontract = true;
    },
    contractTemplateRecieved: (seo, action) => {
      seo.contract = action.payload.data ? action.payload.data : {};
      seo.loadingcontract = false;
      seo.lastFetch = Date.now();
    },
    contractTemplateRequestFailed: (seo, action) => {
      seo.loadingcontract = false;
    },
  },
});

export const {
  seoRequested,
  seoRecieved,
  seoRequestFailed,
  termTemplateRequested,
  termTemplateRecieved,
  termTemplateRequestFailed,
  contractTemplateRequested,
  contractTemplateRecieved,
  contractTemplateRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "seo";
const urlTermTemplate = "termTemplate";

export const loadSeo = (page_key) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + "/" + page_key,
      onStart: seoRequested.type,
      onSuccess: seoRecieved.type,
      onError: seoRequestFailed.type,
    })
  );
};

export const loadTermTemplate = (key) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlTermTemplate + "/" + key,
      onStart: termTemplateRequested.type,
      onSuccess: termTemplateRecieved.type,
      onError: termTemplateRequestFailed.type,
    })
  );
};

export const loadContract = (key) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: urlTermTemplate + "/" + key,
      onStart: contractTemplateRequested.type,
      onSuccess: contractTemplateRecieved.type,
      onError: contractTemplateRequestFailed.type,
    })
  );
};

export const getSeo = createSelector(
  (state) => state.entities.seo,
  (seo) => seo
);
