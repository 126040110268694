import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../common/form/form";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDoctorToken } from "../../services/localStorageServices";
import {
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  clinicRequired,
  vertualRequired,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";

class LinkSetup extends Form {
  state = {
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      clinicUrl: "",
    },
    errors: {},
  };

  schema = {
    clinicUrl: Joi.string()
      .required()
      .error(() => {
        return { message: clinicRequired };
      }),
  };

  doSubmit = () => {
    const { clinicUrl } = this.state.data;
    const data = {
      links: {
        virtual: clinicUrl,
        clinic: clinicUrl,
      },
    };
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(data, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/contract");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.doctorProfile._id
    ) {
      const { _id, user_name } = this.props.doctorProfile;
      const data = {
        clinicUrl: process.env.REACT_APP_BASEURL + "dr/" + user_name,
      };
      this.setState({ data });
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    const { interest, expertise, therapy } = this.state;
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Your Profile Link</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-10">
                    {this.renderInput("clinicUrl", "Your Link", "text", true)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkSetup);
