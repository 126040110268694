import React from "react";

import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { CometChat } from "@cometchat-pro/chat";

import Avatar from "../Avatar";
import StatusIndicator from "../StatusIndicator";

import GroupDetailContext from "../CometChatGroupDetail/context";

import {
  tableRowStyle,
  tableColumnStyle,
  avatarStyle,
  nameStyle,
  roleStyle,
  scopeStyle,
  actionColumnStyle,
  scopeWrapperStyle,
  scopeSelectionStyle,
} from "./style";

import Translator from "../../resources/localization/translator";

import scopeIcon from "./resources/edit.png";
import doneIcon from "./resources/done.png";
import clearIcon from "./resources/close.png";
import banIcon from "./resources/block.png";
import kickIcon from "./resources/delete.png";

class MemberView extends React.Component {
  static contextType = GroupDetailContext;

  constructor(props) {
    super(props);

    this.changeScopeDropDown = (
      <select
        className="members-scope-select"
        onChange={this.scopeChangeHandler}
        defaultValue={this.props.member.scope}
      ></select>
    );

    this.state = {
      showChangeScope: false,
      scope: null,
    };

    this.roles = {};
    this.roles[CometChat.GROUP_MEMBER_SCOPE.ADMIN] = Translator.translate(
      "ADMINISTRATOR",
      props.lang
    );
    this.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR] = Translator.translate(
      "MODERATOR",
      props.lang
    );
    this.roles[CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT] = Translator.translate(
      "PARTICIPANT",
      props.lang
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.roles[CometChat.GROUP_MEMBER_SCOPE.ADMIN] = Translator.translate(
        "ADMINISTRATOR",
        this.props.lang
      );
      this.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR] = Translator.translate(
        "MODERATOR",
        this.props.lang
      );
      this.roles[
        CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ] = Translator.translate("PARTICIPANT", this.props.lang);
    }
  }

  toggleChangeScope = (flag) => {
    this.setState({ showChangeScope: flag });
  };

  scopeChangeHandler = (event) => {
    this.setState({ scope: event.target.value });
  };

  updateMemberScope = () => {
    this.props.actionGenerated(
      "changescope",
      this.props.member,
      this.state.scope
    );
    this.toggleChangeScope();
  };

  toggleTooltip = (event, flag) => {
    const elem = event.currentTarget;
    const nameContainer = elem.lastChild;

    const scrollWidth = nameContainer.scrollWidth;
    const clientWidth = nameContainer.clientWidth;

    if (scrollWidth <= clientWidth) {
      return false;
    }

    if (flag) {
      nameContainer.setAttribute("title", nameContainer.textContent);
    } else {
      nameContainer.removeAttribute("title");
    }
  };

  render() {
    const group = this.context;

    let editClassName = "";

    let name = this.props.member.name;
    let scope = (
      <span style={roleStyle()}>{this.roles[this.props.member.scope]}</span>
    );
    let changescope = null;
    let ban = (
      <img
        src={banIcon}
        alt={Translator.translate("BAN", this.props.lang)}
        onClick={() => {
          this.props.actionGenerated("ban", this.props.member);
        }}
      />
    );
    let kick = (
      <img
        src={kickIcon}
        alt={Translator.translate("KICK", this.props.lang)}
        onClick={() => {
          this.props.actionGenerated("kick", this.props.member);
        }}
      />
    );

    if (this.state.showChangeScope) {
      let options = (
        <React.Fragment>
          <option value={CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT}>
            {this.roles[CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT]}
          </option>
          <option value={CometChat.GROUP_MEMBER_SCOPE.MODERATOR}>
            {this.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR]}
          </option>
          <option value={CometChat.GROUP_MEMBER_SCOPE.ADMIN}>
            {this.roles[CometChat.GROUP_MEMBER_SCOPE.ADMIN]}
          </option>
        </React.Fragment>
      );

      if (
        this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ) {
        options = (
          <React.Fragment>
            <option value={CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT}>
              {this.roles[CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT]}
            </option>
            <option value={CometChat.GROUP_MEMBER_SCOPE.MODERATOR}>
              {this.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR]}
            </option>
          </React.Fragment>
        );
      }

      changescope = (
        <div style={scopeWrapperStyle()} className="scope__wrapper">
          <select
            style={scopeSelectionStyle()}
            className="scope__select"
            onChange={this.scopeChangeHandler}
            defaultValue={this.props.member.scope}
          >
            {options}
          </select>
          <img
            src={doneIcon}
            alt={Translator.translate("CHANGE_SCOPE", this.props.lang)}
            onClick={this.updateMemberScope}
          />
          <img
            src={clearIcon}
            alt={Translator.translate("CHANGE_SCOPE", this.props.lang)}
            onClick={() => this.toggleChangeScope(false)}
          />
        </div>
      );
    } else {
      if (this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT) {
        changescope = scope;
      } else {
        changescope = (
          <React.Fragment>
            {scope}
            <img
              src={scopeIcon}
              alt={Translator.translate("CHANGE_SCOPE", this.props.lang)}
              onClick={() => this.toggleChangeScope(true)}
            />
          </React.Fragment>
        );
      }
    }

    //disable change scope, kick, ban of group owner
    if (this.props.item.owner === this.props.member.uid) {
      scope = (
        <span style={roleStyle()}>
          {Translator.translate("OWNER", this.props.lang)}
        </span>
      );
      changescope = scope;
      ban = null;
      kick = null;
    }

    //disable change scope, kick, ban of self
    if (group.loggedinuser.uid === this.props.member.uid) {
      name = Translator.translate("YOU", this.props.lang);
      changescope = scope;
      ban = null;
      kick = null;
    }

    //if the loggedin user is moderator, don't allow to change scope, ban, kick group moderators or administrators
    if (
      this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
      (this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN ||
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR)
    ) {
      changescope = scope;
      ban = null;
      kick = null;
    }

    //if the loggedin user is administrator but not group owner, don't allow to change scope, ban, kick group administrators
    if (
      this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN &&
      this.props.item.owner !== group.loggedinuser.uid &&
      this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN
    ) {
      changescope = scope;
      ban = null;
      kick = null;
    }

    let editAccess = null;
    //if the loggedin user is participant, don't show change scope, ban, kick group members
    if (this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT) {
      editAccess = null;
      editClassName = "true";
    } else {
      editAccess = (
        <React.Fragment>
          <td style={actionColumnStyle()} className="ban">
            <span>{ban}</span>
          </td>
          <td style={actionColumnStyle()} className="kick">
            <span>{kick}</span>
          </td>
        </React.Fragment>
      );

      if (
        this.props.hasOwnProperty("widgetsettings") &&
        this.props.widgetsettings &&
        this.props.widgetsettings.hasOwnProperty("main")
      ) {
        //if kick_ban_members is disabled in chatwidget
        if (
          this.props.widgetsettings.main.hasOwnProperty(
            "allow_kick_ban_members"
          ) &&
          this.props.widgetsettings.main["allow_kick_ban_members"] === false
        ) {
          editAccess = null;
        }

        //if promote_demote_members is disabled in chatwidget
        if (
          this.props.widgetsettings.main.hasOwnProperty(
            "allow_promote_demote_members"
          ) &&
          this.props.widgetsettings.main["allow_promote_demote_members"] ===
            false
        ) {
          changescope = scope;
        }
      }
    }

    let userPresence = (
      <StatusIndicator
        widgetsettings={this.props.widgetsettings}
        status={this.props.member.status}
        borderColor={this.props.theme.borderColor.primary}
      />
    );

    return (
      <tr style={tableRowStyle(this.props)}>
        <td
          style={tableColumnStyle(editClassName)}
          className="userinfo"
          onMouseEnter={(event) => this.toggleTooltip(event, true)}
          onMouseLeave={(event) => this.toggleTooltip(event, false)}
        >
          <div style={avatarStyle(editClassName)} className="thumbnail">
            <Avatar
              image={this.props.member.avatar}
              borderColor={this.props.theme.borderColor.primary}
            />
            {userPresence}
          </div>
          <div style={nameStyle(editClassName)} className="name">
            {name}
          </div>
        </td>
        <td style={scopeStyle()} className="scope">
          {changescope}
        </td>
        {editAccess}
      </tr>
    );
  }
}

// Specifies the default values for props:
MemberView.defaultProps = {
  lang: Translator.getDefaultLanguage(),
};

MemberView.propTypes = {
  lang: PropTypes.string,
};

export default MemberView;
