import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import pulse from "../../../include/images/pulse.svg";
import { getDoctor } from "../../../store/doctor";
import {
  getPlan,
  loadAnswertreatment,
  loadListPlan,
} from "../../../store/treatmentPlan";
import moment from "moment";
import TreatmentPlan from "./prescription/treatmentPlan";

class TreatmentPlanPat extends Component {
  state = {
    planForm: false,
    answerapicall: false,
  };

  togglePlan = () => {
    this.setState({
      planForm: !this.state.planForm,
    });
  };
  componentDidMount() {
    document.body.className = "dashboard fixed";
    this.props.loadAnswertreatment(this.props.id);
    this.props.loadListPlan(this.props.id);
  }
  render() {
    const dataToShow =
      this.props.list &&
      this.props.list.filter(
        (e) => e && e.field_type !== this.props.doctorProfile.field_type.name
      );
    console.log("datatoShow", dataToShow);
    return (
      <>
        <div class="add-treatment-plan-panel">
          <div
            class="ac-label-left d-flex align-items-center"
            data-toggle="addTreatments"
            onClick={this.togglePlan}
          >
            <div class="ac-label-img">
              <img src={pulse} alt="" />
            </div>
            <div class="ac-label-text">
              {this.props.planAnswer && this.props.planAnswer.is_submitted ? (
                <h3>
                  Edit{" "}
                  {`${
                    this.props.doctorProfile &&
                    this.props.doctorProfile.field_type &&
                    this.props.doctorProfile.field_type.name
                  }`}{" "}
                  Treatment Plan
                </h3>
              ) : (
                <h3>
                  Add{" "}
                  {`${
                    this.props.doctorProfile &&
                    this.props.doctorProfile.field_type &&
                    this.props.doctorProfile.field_type.name
                  }`}{" "}
                  Treatment Plan
                </h3>
              )}
            </div>
            {this.props.planAnswer && this.props.planAnswer.is_submitted ? (
              <div class="ac-label-right ml-auto">
                <p style={{ fontSize: "14px" }}>Last Updated:</p>
                <h4>
                  {moment(
                    this.props.planAnswer && this.props.planAnswer.updated_at
                      ? this.props.planAnswer.updated_at
                      : this.props.planAnswer.created_at
                  ).format("DD MMM, YYYY")}
                </h4>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {dataToShow &&
          dataToShow.map((e) => (
            <div class="custom-accordion">
              <div class="ca-pannel">
                <input
                  class="ac-input"
                  id="ac-1"
                  name="accordion-1"
                  type="checkbox"
                />
                <label class="ac-label" for="ac-1">
                  <div class="ac-lable-content d-flex align-items-center justify-content-between">
                    <div class="ac-label-left d-flex align-items-center">
                      <div class="ac-label-img">
                        <img src={pulse} alt="" />
                      </div>
                      <div class="ac-label-text">
                        {this.props.doctorProfile &&
                        this.props.doctorProfile.field_type &&
                        this.props.doctorProfile.field_type.name ===
                          "Psychologist" ? (
                          <h3>Psychiatrist Treatment Plan</h3>
                        ) : (
                          <h3>Psychologist Treatment Plan</h3>
                        )}
                        <p>{e.doctor_id && e.doctor_id.name}</p>
                      </div>
                    </div>
                    <div class="ac-label-right">
                      <p>Last Updated:</p>
                      <h4>
                        {moment(
                          e && e.updated_at ? e.updated_at : e.created_at
                        ).format("DD MMM, YYYY")}
                      </h4>
                    </div>
                  </div>
                  <i></i>
                </label>
                <div class="article ac-content">
                  {e.primary_problem && e.primary_problem.length != 0 && (
                    <>
                      <h6>Primary problems that have been noticed are</h6>
                      {e.primary_problem &&
                        e.primary_problem.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.secondary_problem && e.secondary_problem.length != 0 && (
                    <>
                      <h6>There is also a secondary problem of</h6>
                      {e.secondary_problem &&
                        e.secondary_problem.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.achieve && e.achieve.length != 0 && (
                    <>
                      {" "}
                      <h6>I would like to achieve the following with you</h6>
                      {e.achieve &&
                        e.achieve.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.technique && e.technique.length != 0 && (
                    <>
                      <h6>Technique I would use</h6>
                      {e.technique &&
                        e.technique.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.medical_plan && e.medical_plan.length != 0 && (
                    <>
                      <h6> Medical plan I would like to use</h6>
                      {e.medical_plan &&
                        e.medical_plan.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.assesment && e.assesment.length != 0 && (
                    <>
                      <h6>Conduct following assessments</h6>
                      {e.assesment &&
                        e.assesment.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))}
                    </>
                  )}
                  {e.appointment_advise &&
                    e.appointment_advise.days &&
                    e.appointment_advise.session && (
                      <h6>{`I advise appointments every ${
                        e.appointment_advise && e.appointment_advise.days
                      } days until ${
                        e.appointment_advise && e.appointment_advise.session
                      } sessions`}</h6>
                    )}

                  {e.recomendation && (
                    <>
                      <h6>I would like to Recommend or advise</h6>
                      <p>{e.recomendation}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        <TreatmentPlan
          planForm={this.state.planForm}
          togglePlan={this.togglePlan}
          patientId={this.props && this.props.id}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  doctorProfile: getDoctor(state).profile,
  planAnswer: getPlan(state).planAnswer,
  list: getPlan(state).list,
});
const mapDispatchToProps = (dispatch) => ({
  loadAnswertreatment: (id) => dispatch(loadAnswertreatment(id)),
  loadListPlan: (id) => dispatch(loadListPlan(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanPat)
);
