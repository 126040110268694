import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPlan, loadSummaryPatbyId } from '../../../store/treatmentPlan'
import { getPatient } from '../../../store/patient'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import LinesEllipsis from 'react-lines-ellipsis'

class SummaryVid extends Component {
  state = {
    doctor_id: '',
    psychiatristFilter: false,
    psychologistFilter: false,
  }

  componentDidMount = () => {
    this.props.loadSummaryPatbyId(this.props.id)
  }

  loadDate = () => {
    this.props.loadSummaryPatbyId(this.props.id, {
      doctor_id: this.state.doctor_id,
    })
  }

  render() {
    return (
      <div class="content-body">
        <div class="tab-pane-inner add-scrollbar scrollbar-dynamic">
          <div class="filter-content-box d-md-flex flex-wrap align-items-center justify-content-between">
            <div class="fcb-box d-md-flex flex-wrap align-items-center">
              <div class="filter-text-box">
                <h6>Filters:</h6>
              </div>
              <div class="filter-check-box">
                <ul class="filter-check-list">
                  <li class="filter-check-item">
                    <div class="form-group preferred-field">
                      <div class="custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0">
                        {this.props.detail &&
                          this.props.detail.data &&
                          this.props.detail.data.Psychiatrist && (
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                class="custom-control-input"
                                id="smb"
                                name="radio"
                                onClick={() => {
                                  this.setState(
                                    {
                                      doctor_id:
                                        this.props.detail &&
                                        this.props.detail.data &&
                                        this.props.detail.data.Psychiatrist &&
                                        this.props.detail.data.Psychiatrist._id,
                                      psychiatristFilter: true,
                                      psychologistFilter: false,
                                    },
                                    () => this.loadDate(),
                                  )
                                }}
                                checked={this.state.psychiatristFilter}
                              />{' '}
                              <label class="custom-control-label" htmlFor="smb">
                                By Psychiatrist
                              </label>
                            </div>
                          )}
                        {this.props.detail &&
                          this.props.detail.data &&
                          this.props.detail.data.Psychologist && (
                            <div class="custom-control custom-radio">
                              <input
                                type="radio"
                                class="custom-control-input"
                                id="mid"
                                name="radio"
                                onClick={() => {
                                  this.setState(
                                    {
                                      doctor_id:
                                        this.props.detail &&
                                        this.props.detail.data &&
                                        this.props.detail.data.Psychologist &&
                                        this.props.detail.data.Psychologist._id,
                                      psychologistFilter: true,
                                      psychiatristFilter: false,
                                    },
                                    () => this.loadDate(),
                                  )
                                }}
                                checked={this.state.psychologistFilter}
                              />{' '}
                              <label class="custom-control-label" htmlFor="mid">
                                By Psychologist
                              </label>
                            </div>
                          )}
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="last"
                            name="radio"
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: '',
                                  psychologistFilter: false,
                                  psychiatristFilter: false,
                                },
                                () => this.loadDate(),
                              )
                            }}
                            checked={this.state.doctor_id === ''}
                          />{' '}
                          <label class="custom-control-label" htmlFor="last">
                            All
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {this.props.summaryPatbyId &&
            this.props.summaryPatbyId.map((e) => {
              const atPresentValues =
                e.currentFollowup &&
                e.currentFollowup.formData &&
                e.currentFollowup.formData['Current Complaints:']
                  ? e.currentFollowup
                  : e.currentPrescription
                  ? {
                      formData: {
                        ...e.currentPrescription,
                        'Current Complaints:': e.currentPrescription
                          .chief_complaints
                          ? e.currentPrescription.chief_complaints
                          : 'N/A',
                      },
                    }
                  : null
              const sideEffectsData =
                e.currentFollowup && e.currentFollowup.formData

              const sideEffectMedical =
                sideEffectsData && sideEffectsData['Medicine tolerance:']
                  ? sideEffectsData['Medicine tolerance:']
                  : sideEffectsData && sideEffectsData['Medicine Tolerance:']
                  ? sideEffectsData['Medicine Tolerance:']
                  : ''
              const sideEffectValue =
                sideEffectsData && sideEffectsData['Side Effects']
                  ? sideEffectsData['Side Effects']
                  : sideEffectsData &&
                    sideEffectsData['Side effects details (if present):']
                  ? sideEffectsData['Side effects details (if present):']
                  : ''

              return (
                <>
                  <div class="tab-summary-content-box">
                    <div class="scb-upper">
                      <div class="summary-details-box d-sm-flex align-items-center">
                        <div class="sdb-left">
                          <p>
                            {`${moment
                              .utc(e.appointment_time)
                              .format('DD MMM')},`}
                            <br />
                            <span>{`${moment
                              .utc(e.appointment_time)
                              .format('YYYY')}`}</span>
                            <br />
                            <span>{`${moment
                              .utc(e.appointment_time)
                              .format('hh:mm a')}`}</span>
                          </p>
                        </div>
                        <div class="sdb-right d-flex align-items-center justify-content-center">
                          <div class="sdb-img-box">
                            <img
                              src={
                                e.doctor_id &&
                                e.doctor_id.image_url &&
                                process.env.REACT_APP_S3URL +
                                  e.doctor_id.image_url
                              }
                              alt=""
                            />
                          </div>
                          <div class="sdb-desc">
                            <h4>{e.doctor_id && e.doctor_id.name}</h4>
                            <p>
                              {e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <ReactTooltip className="testPurple" html={true} />
                      <div class="sbd-text-box">
                        <p>At Present:</p>
                        <h4
                          data-tip={`${
                            atPresentValues &&
                            atPresentValues.formData &&
                            atPresentValues.formData['Current Complaints:'] &&
                            typeof atPresentValues.formData[
                              'Current Complaints:'
                            ] == 'object'
                              ? atPresentValues.formData['Current Complaints:']
                                  .length > 0
                                ? atPresentValues.formData[
                                    'Current Complaints:'
                                  ] &&
                                  atPresentValues.formData[
                                    'Current Complaints:'
                                  ].map((e) => ` ${e.label}`)
                                : 'NA'
                              : atPresentValues &&
                                atPresentValues.formData &&
                                atPresentValues.formData['Current Complaints:']
                              ? atPresentValues.formData['Current Complaints:']
                              : 'NA'
                          }`}
                        >
                          {e &&
                          atPresentValues &&
                          atPresentValues.formData &&
                          atPresentValues.formData['Current Complaints:'] &&
                          typeof atPresentValues.formData[
                            'Current Complaints:'
                          ] == 'object'
                            ? atPresentValues.formData['Current Complaints:']
                                .length > 0
                              ? atPresentValues.formData['Current Complaints:']
                                  .length > 1
                                ? `${
                                    atPresentValues.formData[
                                      'Current Complaints:'
                                    ][0] &&
                                    atPresentValues.formData[
                                      'Current Complaints:'
                                    ][0].label
                                  }...`
                                : atPresentValues.formData[
                                    'Current Complaints:'
                                  ][0] &&
                                  atPresentValues.formData[
                                    'Current Complaints:'
                                  ][0].label
                              : 'NA'
                            : atPresentValues &&
                              atPresentValues.formData &&
                              atPresentValues.formData['Current Complaints:']
                            ? atPresentValues.formData['Current Complaints:']
                            : 'NA'}
                        </h4>
                      </div>
                      <div class="sbd-side-advise d-md-flex align-items-center">
                        <div class="sbd-text-box advise-left">
                          <p>Last advise:</p>
                          <h4
                            data-tip={`${
                              e.doctor_id &&
                              e.doctor_id.field_type &&
                              e.doctor_id.field_type.name &&
                              e.doctor_id.field_type.name === 'Psychologist'
                                ? e.prevPrescription &&
                                  e.prevPrescription.theray_method &&
                                  e.prevPrescription.therapy_technique
                                  ? e.prevPrescription.theray_method +
                                    e.prevPrescription.therapy_technique
                                      .map((elp) => elp.label)
                                      .join(', ')
                                  : e.prevPrescription &&
                                    e.prevPrescription.theray_method
                                  ? e.prevPrescription.theray_method
                                  : e.prevPrescription &&
                                    e.prevPrescription.therapy_technique
                                  ? e.prevPrescription.therapy_technique
                                      .map((elp) => elp.label)
                                      .join(', ')
                                  : 'NA'
                                : e.prevPrescription &&
                                  e.prevPrescription.medical_prescription &&
                                  e.prevPrescription.medical_prescription
                                    .length > 0 &&
                                  e.prevPrescription.medical_prescription[0]
                                    .composition
                                ? e.prevPrescription.medical_prescription
                                    .map(
                                      (elp) =>
                                        elp.formulation +
                                        ' ' +
                                        elp.composition +
                                        ' ' +
                                        elp.dose,
                                    )
                                    .join(', ')
                                : 'NA'
                            }`}
                          >
                            {e.doctor_id &&
                            e.doctor_id.field_type &&
                            e.doctor_id.field_type.name &&
                            e.doctor_id.field_type.name === 'Psychologist' ? (
                              <LinesEllipsis
                                text={
                                  e.prevPrescription &&
                                  e.prevPrescription.theray_method &&
                                  e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.theray_method +
                                      e.prevPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : e.prevPrescription &&
                                      e.prevPrescription.theray_method
                                    ? e.prevPrescription.theray_method
                                    : e.prevPrescription &&
                                      e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : 'NA'
                                }
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            ) : (
                              <LinesEllipsis
                                text={
                                  e.prevPrescription &&
                                  e.prevPrescription.medical_prescription &&
                                  e.prevPrescription.medical_prescription
                                    .length > 0 &&
                                  e.prevPrescription.medical_prescription[0]
                                    .composition
                                    ? e.prevPrescription.medical_prescription
                                        .map(
                                          (elp) =>
                                            elp.formulation +
                                            ' ' +
                                            elp.composition +
                                            ' ' +
                                            elp.dose,
                                        )
                                        .join(', ')
                                    : 'NA'
                                }
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            )}
                          </h4>
                        </div>
                        <div class="sbd-text-box advise-right">
                          <ReactTooltip className="testPurple" html={true} />
                          <p class="yark-pink-color">Side effects:</p>
                          <h4
                            data-tip={`${
                              sideEffectValue &&
                              typeof sideEffectValue == 'object'
                                ? sideEffectValue.length > 0
                                  ? sideEffectValue.length > 1
                                    ? `${
                                        sideEffectValue[0] &&
                                        sideEffectValue[0].label
                                      }...`
                                    : sideEffectValue[0] &&
                                      sideEffectValue[0].label
                                  : 'NA'
                                : sideEffectValue && sideEffectMedical
                                ? sideEffectMedical + ' , ' + sideEffectValue
                                : sideEffectMedical
                                ? sideEffectMedical
                                : sideEffectValue
                                ? sideEffectValue
                                : 'NA'
                            }`}
                          >
                            {sideEffectValue &&
                            typeof sideEffectValue == 'object'
                              ? sideEffectValue.length > 0
                                ? sideEffectValue.length > 1
                                  ? `${
                                      sideEffectValue[0] &&
                                      sideEffectValue[0].label
                                    }...`
                                  : sideEffectValue[0] &&
                                    sideEffectValue[0].label
                                : 'NA'
                              : sideEffectValue && sideEffectMedical
                              ? sideEffectMedical + ' , ' + sideEffectValue
                              : sideEffectMedical
                              ? sideEffectMedical
                              : sideEffectValue
                              ? sideEffectValue
                              : 'NA'}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="block-seprator"></div>
                    <div class="scb-lower">
                      <div class="sbd-imro-content d-flex align-items-center justify-content-between">
                        <div class="sbd-text-box">
                          <p>Improvement:</p>
                          <h4>
                            {' '}
                            {e.currentFollowup &&
                            e.currentFollowup.formData &&
                            e.currentFollowup.formData['Overall Improvement:']
                              ? e.currentFollowup.formData[
                                  'Overall Improvement:'
                                ]
                              : 'NA'}
                          </h4>
                        </div>
                      </div>
                      <div class="sbd-text-box">
                        <p>Current advise:</p>
                        <h4
                          data-tip={`${
                            e.doctor_id &&
                            e.doctor_id.field_type &&
                            e.doctor_id.field_type.name &&
                            e.doctor_id.field_type.name === 'Psychologist'
                              ? e.currentPrescription &&
                                e.currentPrescription.theray_method &&
                                e.currentPrescription.therapy_technique
                                ? e.currentPrescription.theray_method +
                                  e.currentPrescription.therapy_technique
                                    .map((elp) => elp.label)
                                    .join(', ')
                                : e.currentPrescription &&
                                  e.currentPrescription.theray_method
                                ? e.currentPrescription.theray_method
                                : e.currentPrescription &&
                                  e.currentPrescription.therapy_technique
                                ? e.currentPrescription.therapy_technique
                                    .map((elp) => elp.label)
                                    .join(', ')
                                : 'NA'
                              : e.currentPrescription &&
                                e.currentPrescription.medical_prescription &&
                                e.currentPrescription.medical_prescription
                                  .length > 0 &&
                                e.currentPrescription.medical_prescription[0]
                                  .composition
                              ? e.currentPrescription.medical_prescription
                                  .map(
                                    (elp) =>
                                      elp.formulation +
                                      ' ' +
                                      elp.composition +
                                      ' ' +
                                      elp.dose,
                                  )
                                  .join(', ')
                              : 'NA'
                          }`}
                        >
                          <strong>
                            {e.doctor_id &&
                            e.doctor_id.field_type &&
                            e.doctor_id.field_type.name &&
                            e.doctor_id.field_type.name === 'Psychologist' ? (
                              <LinesEllipsis
                                text={
                                  e.currentPrescription &&
                                  e.currentPrescription.theray_method &&
                                  e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.theray_method +
                                      e.currentPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : e.currentPrescription &&
                                      e.currentPrescription.theray_method
                                    ? e.currentPrescription.theray_method
                                    : e.currentPrescription &&
                                      e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : 'NA'
                                }
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            ) : (
                              <LinesEllipsis
                                text={
                                  e.currentPrescription &&
                                  e.currentPrescription.medical_prescription &&
                                  e.currentPrescription.medical_prescription
                                    .length > 0 &&
                                  e.currentPrescription.medical_prescription[0]
                                    .composition
                                    ? e.currentPrescription.medical_prescription
                                        .map(
                                          (elp) =>
                                            elp.formulation +
                                            ' ' +
                                            elp.composition +
                                            ' ' +
                                            elp.dose,
                                        )
                                        .join(', ')
                                    : 'NA'
                                }
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            )}
                          </strong>
                        </h4>
                      </div>
                      <div class="sbd-text-box">
                        <p>Special mention:</p>
                        <h4
                          data-tip={`${
                            e.currentFollowup &&
                            e.currentFollowup.formData &&
                            e.currentFollowup.formData[
                              'Special Mention (Anything important about patient that you would want to mention)'
                            ]
                              ? e.currentFollowup.formData[
                                  'Special Mention (Anything important about patient that you would want to mention)'
                                ]
                              : 'NA'
                          }`}
                          class="light-green-color"
                        >
                          <LinesEllipsis
                            text={
                              e.currentFollowup &&
                              e.currentFollowup.formData &&
                              e.currentFollowup.formData[
                                'Special Mention (Anything important about patient that you would want to mention)'
                              ]
                                ? e.currentFollowup.formData[
                                    'Special Mention (Anything important about patient that you would want to mention)'
                                  ]
                                : 'NA'
                            }
                            maxLine="1"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  summaryPatbyId: getPlan(state).summaryPatbyId,
})

const mapDispatchToProps = (dispatch) => ({
  loadSummaryPatbyId: (id, data) => dispatch(loadSummaryPatbyId(id, data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SummaryVid),
)
