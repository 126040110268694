import React, { Component } from 'react'
import down from '../../../include/images/down-icon-1.svg'
import { getPatient, patientDetail } from '../../../store/patient'
import { connect } from 'react-redux'
import Moment from 'moment'

class History extends Component {
  state = {
    id: '',
    history: false,
  }
  toggleHistory = (id) => {
    this.setState({
      id: this.state.id === id ? '' : id,
      history: !this.state.history,
    })
  }
  componentDidMount() {
    document.body.className = 'dashboard fixed'
  }

  render() {
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="history"
      //   role="tabpanel"
      //   aria-labelledby="history-tab"
      // >
      //   <div class="card-header">History</div>
      //   <div class="content-body add-scrollbar  scrollbar-dynamic">
      <div class="content-history-box">
        {this.props.patientHistory && this.props.patientHistory.length > 0 ? (
          <div class="accordion" id="accordionExample">
            {this.props.patientHistory &&
              this.props.patientHistory.map((e, i) => (
                <div
                  class="item"
                  onClick={() => this.toggleHistory(e._id)}
                  style={{ cursor: 'pointer' }}
                >
                  <a
                    className={
                      this.state.id === e._id ? 'ih-box' : 'ih-box collapsed'
                    }
                    id="headingTwo"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded={this.state.id === e._id ? 'true' : 'false'}
                    aria-controls="collapseTwo"
                  >
                    <h3>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g transform="translate(-44.5 -129)">
                          <rect
                            class="a"
                            width="30"
                            height="30"
                            rx="15"
                            transform="translate(44.5 129)"
                          />
                          <path
                            class="b"
                            d="M6.5,10.8,0,4.3,2.1,2.2,6.5,6.5,13,0l2.1,2.1Z"
                            transform="translate(51.5 139)"
                          />
                        </g>
                      </svg>
                      <span>
                        History Log {this.props.patientHistory.length - i}
                      </span>
                    </h3>
                    <div class="ml-auto">
                      <span class="ih-text">
                        Edited on {Moment(e.created_on).format('DD-MM-YYYY')}
                      </span>
                      <span
                        class="icon"
                        onClick={() => this.toggleHistory(e._id)}
                      >
                        <i>
                          <img src={down} alt="" />
                        </i>
                      </span>
                    </div>
                  </a>
                  <div
                    id="collapseTwo"
                    className={
                      this.state.id === e._id ? 'collapse show' : 'collapsing'
                    }
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="t-p ch-main">
                      <div class="ch-main-box">
                        <div class="ch-content-box">
                          <p>Current or Past Complaints/Issues/Difficulties</p>
                          {e.problems.map((e1, i) => (
                            <h5>{e1}</h5>
                          ))}
                        </div>
                        <ul class="ch-list d-md-flex flex-wrap align-items-center">
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Facing the above problems since</p>
                              <h5>
                                {e.problems_since[0]}{' '}
                                {e.problems_since[2] === 'y'
                                  ? 'year'
                                  : e.problems_since[2] === 'm'
                                  ? 'month'
                                  : 'days'}
                              </h5>
                            </div>
                          </li>
                        </ul>
                        <div class="block-seprator"></div>

                        <ul class="ch-list d-md-flex flex-wra align-items-center">
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Course of Problems</p>
                              <h5>{e.complaints_course}</h5>
                            </div>
                          </li>
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Past mental health treatment details</p>
                              <h5>
                                {e.past_mental_health_treatment === 'therpy'
                                  ? 'therapy'
                                  : e.past_mental_health_treatment}
                              </h5>
                            </div>
                          </li>
                        </ul>

                        <ul class="ch-list d-md-flex flex-wrap ">
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Name of Medicines</p>
                              <h5>{e.medicine}</h5>
                            </div>
                          </li>
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Therapy name</p>
                              <h5>{e.therapy_name}</h5>
                            </div>
                          </li>
                        </ul>
                        <ul class="ch-list d-md-flex flex-wrap align-items-center">
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Treatment Regularity</p>
                              <h5>{e.treatment_regularity}</h5>
                            </div>
                          </li>
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Improvement observed</p>
                              <h5>
                                {e.improvement_observed &&
                                e.improvement_observed === 'contSay'
                                  ? 'Can’t say'
                                  : e.improvement_observed}
                              </h5>
                            </div>
                          </li>
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>Upload Recent Prescription/Reports</p>
                              <img
                                src={
                                  process.env.REACT_APP_S3URL +
                                  e.recent_prescription
                                }
                                alt=""
                              />
                            </div>
                          </li>
                        </ul>
                        <div class="block-seprator"></div>
                        <ul class="ch-list d-md-flex flex-wrap align-items-center">
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>
                                Do you have any Physical Illnesses
                                (Past/Current)?
                              </p>
                              {e.physical_illnesses.map((e3, i) => (
                                <h5>{e3}</h5>
                              ))}
                            </div>
                          </li>
                          <li class="ch-item">
                            <div class="ch-content-box">
                              <p>
                                Are you getting treated for above diagnosis?
                              </p>
                              <h5>{e.physical_illnesses_diagnosis}</h5>
                            </div>
                          </li>
                        </ul>
                        <div class="block-seprator"></div>
                        <div class="ch-content-box">
                          <p>If any surgeries in past</p>
                          <h5>{e.surgery}</h5>
                        </div>
                        <div class="block-seprator"></div>

                        <div class="ch-content-box">
                          <p>Choose your most important concern (pick one)</p>
                          <h5>{e.most_important_concern}</h5>
                        </div>
                        <div class="block-seprator"></div>
                        <div class="ch-content-box">
                          <p>
                            {' '}
                            Any stress or life event after which problems
                            started or became worse?
                          </p>
                          <h5>{e.problem_after_stress_or_life_event}</h5>
                        </div>
                        <div class="block-seprator"></div>
                        <div class="ch-content-box">
                          <p>
                            {' '}
                            Your expectations/thoughts at start from YesMindy’s
                            Experts
                          </p>
                          <h5>{e.expectations_from_yesmindy}</h5>
                        </div>

                        <div class="block-seprator"></div>
                        <div class="ch-content-box">
                          <p>You were referred by</p>
                          <h5>{e.referred_by}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div class="accordion" id="accordionExample">
            <div class="item">No records found.</div>
          </div>
        )}
      </div>
      //   </div>
      // </div>
    )
  }
}

const mapStateToProps = (state) => ({
  detail: getPatient(state).patientDetail,
})
const mapDispatchToProps = (dispatch) => ({
  patientDetail: (id) => dispatch(patientDetail(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(History)
