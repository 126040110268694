import React from 'react'
import Joi from 'joi-browser'
import JsFileDownloader from 'js-file-downloader'
import { isMobile } from 'react-device-detect'

import Form from '../../../../common/form/form'
import seUploadFun from '../../../../services/s3Services'
import upload from '../../../../../include/images/file-upload.svg'
import close from '../../../../../include/images/icon-close.svg'
import doc from '../../../../../include/images/document-img1.svg'
import pdf from '../../../../../include/images/pdf-documnet.svg'
import image2 from '../../../../../include/images/document-img-2.svg'
import closeIcon from '../../../../../include/images/close.svg'
import docIcon from './../../../../../include/images/followup-icon.svg'
import {
  getDocument,
  addDocument,
  loadDocumentCategory,
  deletedDocument,
  loadDocument,
  shareDocument,
} from '../../../../../store/document'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AlertSuccess from '../../../../common/alertSuccess'
import AlertError from '../../../../common/alertError'
import { toast } from 'react-toastify'
import {
  fileRequired,
  categoryRequired,
  docTitleRequired,
} from '../../../../common/misc'
import moment from 'moment'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'

class Document extends Form {
  state = {
    deletePopup: false,
    downloading: false,
    fileStatus: '',
    dropdown: false,
    id: '',
    share_id: '',
    doc: '',
    data: {
      file: '',
      document_title: '',
      category_id: '',
    },
    errors: {},
  }

  toggleDeletePop = deletePopup => this.setState({ deletePopup })

  schema = {
    file: Joi.string()
      .required()
      .error(() => {
        return { message: fileRequired }
      }),
    category_id: Joi.string()
      .required()
      .error(() => {
        return { message: categoryRequired }
      }),
    document_title: Joi.string()
      .required()
      .error(() => {
        return { message: docTitleRequired }
      }),
  }
  fileUpload = e => {
    this.setState({ fileStatus: 'Loading...' })

    const fileName = 'patient/document/' + Date.now()
    const res = seUploadFun(e.target.files[0], fileName)
    res.then(result => {
      const { data } = this.state
      data.file = result.key
      this.setState({
        doc: e.target.files[0].name,
        fileStatus: 'Uploaded successfully.',
        data,
      })
    })
  }
  componentDidMount = () => {
    this.props.loadDocumentCategory()
    this.props.loadDocument()
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.data.file) {
      document.body.classList.remove('p-0')
      document.body.classList.add('show-siderbar')
    }
    if (this.props.tab === 'documents' && this.props.tab !== prevProps.tab) {
      this.props.loadDocumentCategory()
      this.props.loadDocument()
    }
    if (this.props.tab === 'report' && this.props.tab !== prevProps.tab) {
      this.props.loadDocumentCategory()
      this.props.loadDocument()
    }
  }
  toggle = () => {
    this.setState({
      data: { file: '', document_title: '', category_id: '' },
      errors: {},
      fileStatus: '',
    })
    document.body.classList.remove('show-siderbar')
  }
  share = id => {
    this.props.shareDocument(id, this.shareCallback)
  }
  shareCallback = res => {
    if (res && res.status === 200) {
      this.props.loadDocument()
      toast(<AlertSuccess message={'Document Shared Successfully.'} />)
    } else {
      this.props.loadDocument()
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  delete = id => {
    this.props.deletedDocument(id, this.deleteCallback)
  }
  deleteCallback = res => {
    if (res && res.status === 200) {
      this.props.loadDocument()
      toast(<AlertSuccess message={'Document Deleted Successfully.'} />)
    } else {
      this.props.loadDocument()
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  doSubmit = () => {
    const { file, category_id, document_title } = this.state.data
    const data = {
      file: [file],
      category_id: category_id,
      document_title: document_title,
    }

    this.props.addDocument(data, this.callBack)
  }
  callBack = res => {
    if (res && res.status === 200) {
      this.props.loadDocument()

      this.setState({
        data: { file: '', document_title: '', category_id: '' },
        errors: {},
        fileStatus: '',
      })
      document.body.classList.remove('show-siderbar')
      toast(<AlertSuccess message={'Document Added Successfully.'} />)
    } else {
      this.props.loadDocument()
      this.setState({
        data: { file: '', document_title: '', category_id: '' },
        errors: {},
        fileStatus: '',
      })
      document.body.classList.remove('show-siderbar')
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  downloadFile = fileUrl => {
    if (!this.state.downloading) {
      this.setState({ downloading: true })
      new JsFileDownloader({
        url: fileUrl,
      })
        .then(() => {
          this.setState({ downloading: false })
          // Called when download ended
          toast(<AlertSuccess message={'Document downloaded successfully.'} />)
        })
        .catch(error => {
          this.setState({ downloading: false })
          // Called when an error occurred
          toast(
            <AlertError
              message={'Something went wrong while downloading file.'}
            />
          )
        })
    }
  }
  toggleModel = (fileData = null, val) => {
    this.setState({
      showPreview: val,
      fileObj: fileData,
    })
  }
  render() {
    const { documentCategory, document } = this.props
    const monthName = item => moment(item.created_on).format('DD-MMM-YYYY')
    const result = _.groupBy(document, monthName)
    const groupArrays = Object.keys(result).map(date => {
      return {
        date,
        doc: result[date],
      }
    })

    console.log('call docus', this.props.tab)

    return (
      <>
        {this.state.showPreview && (
          <Modal
            fullscreen={true}
            aria-labelledby='contained-modal-title-vcenter'
            dialogClassName='modal-dialog pdf-model'
            className='modal fade pdf-model'
            show={this.state.showPreview}
            onHide={() => this.toggleModel(null, false)}
            style={{ paddingLeft: 0 }}>
            <Modal.Header closeButton></Modal.Header>
            <div>
              {this.state.fileObj && this.state.fileObj.type == 'pdf' ? (
                <iframe
                  src={this.state.fileObj.fileUrl}
                  width='100%'
                  style={{ height: '100vh' }}
                />
              ) : (
                <img src={this.state.fileObj.fileUrl} alt='image' />
              )}
            </div>
          </Modal>
        )}
        {/* {!this.props.timeline && <div className="card-header">Documents</div>}
        <div
          className="content-body"
          onClick={() =>
            this.setState({
              dropdown: false,
            })
          }
        > */}
        <div
          onClick={e => this.props.setTab('documents', e)}
          className={
            this.props.tab === 'documents'
              ? 'card-header active'
              : 'card-header'
          }>
          <img src={docIcon} alt='' />
          <b>Documents</b>
        </div>
        <div
          onClick={() =>
            this.setState({
              dropdown: false,
            })
          }
          className={
            this.props.tab === 'documents'
              ? 'content-body show'
              : 'content-body'
          }>
          <div className='tab-pane-content-box'>
            {!this.props.videoCall && (
              <div className='tpc-upper'>
                <div
                  className='tpc-box d-md-flex align-items-md-center justify-content-md-center'
                  id='chooseFile'>
                  <div className='tpc-icon'>
                    <img src={upload} alt='' />
                  </div>
                  <p>{this.state.fileStatus ? this.state.fileStatus : ''}</p>
                  <label htmlFor='photograph2' className='btn btn-dark-outline'>
                    Choose file
                  </label>
                  <input
                    type='file'
                    id='photograph2'
                    className='d-none errorColor'
                    onChange={this.fileUpload}
                  />
                </div>
              </div>
            )}
            <div className='tlower'>
              {groupArrays.map((e2, i) => (
                <div className='document-content-box '>
                  <div className='tpc-month-box'>
                    <h6>{e2.date}</h6>
                  </div>
                  <ul className='documents-list  d-flex  flex-wrap'>
                    {e2.doc.map((e1, i) => (
                      <li
                        className='document-item'
                        onClick={() => {
                          this.setState({
                            id: 0,
                          })
                        }}>
                        <div className='document-box'>
                          <div className='document-img-box'>
                            <span className='document-intro'>
                              {e1.category.name}
                            </span>
                            <div className='document-dropdown'>
                              <div
                                className={
                                  this.state.dropdown === true &&
                                  this.state.id === e1._id
                                    ? 'dropright show'
                                    : 'dropright'
                                }>
                                <a
                                  className='dropdown-toggle'
                                  href='javascript:void(0)'
                                  data-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                  onClick={e => {
                                    e.stopPropagation()
                                    this.setState({
                                      dropdown: true,
                                      id: e1._id,
                                    })
                                  }}>
                                  <i className='fas fa-ellipsis-v'></i>
                                </a>
                                <div
                                  className={
                                    this.state.dropdown === true &&
                                    this.state.id === e1._id
                                      ? 'dropdown-menu dropdown-custom show'
                                      : 'dropdown-menu dropdown-custom'
                                  }
                                  // style={{
                                  //   position: "absolute",
                                  //   top: "0px",
                                  //   left: "0px",
                                  //   "will-change": "transform",
                                  // }}
                                  style={
                                    isMobile
                                      ? {
                                          zIndex: 99999999999,
                                          position: 'absolute',
                                          transform:
                                            'translate3d(-173px, 0px, 0px)',
                                          top: '0px',
                                          left: '0px',
                                          willChange: 'transform',
                                        }
                                      : {}
                                  }
                                  x-placement={
                                    isMobile ? 'left-start' : 'right-start'
                                  }>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                    onClick={() => {
                                      this.share(e1._id)
                                    }}>
                                    Share
                                  </a>

                                  <a
                                    className='dropdown-item'
                                    href='#'
                                    onClick={ele => {
                                      ele.preventDefault()
                                      this.downloadFile(
                                        process.env.REACT_APP_S3URL + e1.file
                                      )
                                    }}>
                                    Download
                                  </a>
                                  <a
                                    className='dropdown-item'
                                    href='javascript:void(0)'
                                    onClick={() => {
                                      this.toggleDeletePop(e1._id)
                                    }}>
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              className='document-img'
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                this.toggleModel(
                                  {
                                    fileUrl:
                                      process.env.REACT_APP_S3URL + e1.file,
                                    type:
                                      e1.file.split('.').pop() === 'pdf'
                                        ? 'pdf'
                                        : 'image',
                                  },
                                  true
                                )
                              }}>
                              {e1.file.split('.').pop() === 'pdf' && (
                                <img src={pdf} alt='' />
                              )}
                              {e1.file.split('.').pop() === 'jpeg' && (
                                <img src={image2} alt='' />
                              )}
                              {e1.file.split('.').pop() === 'jpg' && (
                                <img src={image2} alt='' />
                              )}
                              {e1.file.split('.').pop() === 'png' && (
                                <img src={image2} alt='' />
                              )}
                              {e1.file.split('.').pop() != 'png' &&
                                e1.file.split('.').pop() != 'jpg' &&
                                e1.file.split('.').pop() != 'jpeg' &&
                                e1.file.split('.').pop() != 'pdf' && (
                                  <img src={doc} alt='' />
                                )}
                            </div>
                          </div>
                          <h4>{e1.document_title}</h4>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div class='sidebar-pannel'>
          <div class='sp-head d-flex align-items-center'>
            <h4>Upload Documents</h4>
            <span
              class='close-icon ml-auto'
              onClick={e => {
                e.preventDefault()
                this.toggle()
              }}>
              <img src={close} alt='' />
            </span>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div class='sp-body'>
              <div class='sp-doc-box d-flex align-items-center'>
                {this.state.doc.split('.').pop() === 'pdf' && (
                  <img src={pdf} alt='' />
                )}
                {this.state.doc.split('.').pop() === 'jpeg' && (
                  <img src={image2} alt='' />
                )}
                {this.state.doc.split('.').pop() === 'jpg' && (
                  <img src={image2} alt='' />
                )}
                {this.state.doc.split('.').pop() === 'png' && (
                  <img src={image2} alt='' />
                )}
                {this.state.doc.split('.').pop() != 'png' &&
                  this.state.doc.split('.').pop() != 'jpg' &&
                  this.state.doc.split('.').pop() != 'jpeg' &&
                  this.state.doc.split('.').pop() != 'pdf' && (
                    <img src={doc} alt='' />
                  )}
                <p>{this.state.doc && this.state.doc}</p>
              </div>
              {this.state.errors.file && (
                <div class='form-group custom-input'>
                  <label className='error'>{this.state.errors.file}</label>
                </div>
              )}
              {this.renderInput('document_title', 'Document Title')}
              <h4>Select a Tag</h4>
              <ul class='tags d-flex flex-wrap'>
                {documentCategory.map((e, i) => (
                  <li
                    class={
                      this.state.data.category_id === e._id ? 'active' : ''
                    }>
                    <a
                      href='#'
                      class='tag'
                      id={e._id}
                      onClick={e => {
                        const data = { ...this.state.data }
                        if (e) {
                          data.category_id = e.target.id
                          this.setState({ data })
                        }
                      }}>
                      {e.name}
                    </a>
                  </li>
                ))}
              </ul>
              {this.state.errors.category_id && (
                <div class='form-group custom-input'>
                  <label className='error'>
                    {this.state.errors.category_id}
                  </label>
                </div>
              )}
              <div class='sb-upload-btn text-center'>
                <button class='btn btn-default no-shadow'>Upload</button>
              </div>
            </div>
          </form>
        </div>
        {this.state.data.file && <div class='overlay3'></div>}
        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.deletePopup}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.toggleDeletePop(false)
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <h4
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                Are you sure you want to delete?
              </h4>

              <div class='modal-coustom-btn d-flex align-items-center justify-content-center'>
                <a
                  href='#!'
                  onClick={e => {
                    this.delete(this.state.deletePopup)
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}
                  class='btn btn-primary'>
                  Delete
                </a>
                <a
                  href='#!'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}>
                  Cancel
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  document: getDocument(state).document,
  documentCategory: getDocument(state).category,
})
const mapDispatchToProps = dispatch => ({
  loadDocumentCategory: () => dispatch(loadDocumentCategory()),
  loadDocument: params => dispatch(loadDocument(params)),
  addDocument: (param, callback) => dispatch(addDocument(param, callback)),
  shareDocument: (id, callbackShare) =>
    dispatch(shareDocument(id, callbackShare)),
  deletedDocument: (id, callbackDelete) =>
    dispatch(deletedDocument(id, callbackDelete)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Document)
)
