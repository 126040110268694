import React, { Component } from "react";
import { connect } from "react-redux";
import { getMisc, loadtopArticle } from "../../store/misc";
import Moment from "moment";
import { Link } from "react-router-dom";

class TopArticle extends Component {
  componentDidMount = () => {
    this.props.loadtopArticle({ published: true, is_top: true });
  };
  render() {
    const misc = this.props.getMisc;
    return (
      <div class="br-content-box articals">
        <div class="br-heading">
          <h3>Top Articles</h3>
        </div>
        <ul class="latest-article-list">
          {misc
            .filter((ea) => ea.is_top === true)
            .map((e, i) => (
              <li class="latest-article-item">
                <a class="latest-article-box">
                  <div class="latest-article-sequence">{i + 1}</div>
                  <div class="latest-article-text">
                    <Link to={{ pathname: "/blog-detail/" + e.url }}>
                      <h3>{e.title}</h3>
                    </Link>
                    <p> {Moment(e.publish_date).format("MMM DD , YYYY")}</p>
                  </div>
                </a>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  getMisc: getMisc(state).topArticle,
});

const mapDispatchToProps = (dispatch) => ({
  loadtopArticle: (params) => dispatch(loadtopArticle(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopArticle);
