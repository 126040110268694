import React, { Component } from 'react'
import Header from './header'
import leftArrow from '../../../include/images/tp-left-arrow.svg'
import rightArrow from '../../../include/images/tp-right-arrow.svg'
import avtarImg from '../../../include/images/avatar1.png'
import starIcon from '../../../include/images/blue-star-icon.svg'
import blueStar from '../../../include/images/blue-star-icon.svg'
import moment from 'moment'
import {
  getAppointment,
  loadAllAppointmentForDoctor,
  loadAppointmentDetail,
} from '../../../store/appointment'
import { loadSeo } from '../../../store/seo'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  getDoctorPatient,
  loadAllDoctorpatient,
} from '../../../store/doctorPatient'
import AvtarImage from '../../common/avtarImage'
import SideTabs from './sideTab'

class DoctorDashboard extends Component {
  state = { date: moment(), time: moment(), intervalId: 1, type: 'online' }

  componentDidMount() {
    this.props.loadAllDoctorpatient({ page: 1 })
    const intervalId = setInterval(() => {
      this.setState({ time: moment() })
    }, 100)
    this.setState({ intervalId })
    document.body.className = 'dashboard-staff'
    this.props.loadAllAppointmentForDoctor({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }
  changeData = type => {
    if (type === 'next')
      this.setState({ date: this.state.date.add(1, 'days') }, () => {
        this.props.loadAllAppointmentForDoctor({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
      })
    if (type === 'prev')
      this.setState({ date: this.state.date.subtract(1, 'days') }, () => {
        this.props.loadAllAppointmentForDoctor({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
      })
  }
  render() {
    return (
      <>
        <Header staff={true} />
        <div class='dashboard-main-container'>
          <div class='dashboard-top-pannel d-lg-flex align-items-lg-center'>
            <div class='tp-text-box d-flex align-items-center justify-content-between'>
              <div class='tp-heading'>Dashboard</div>
              <div class='tp-date-desc'>
                {/* <div
                  class='tp-left-arrow'
                  onClick={() => this.changeData('prev')}>
                  <img src={leftArrow} alt='' />
                </div> */}
                <div class='text'>{this.state.date.format('dddd, D MMM')}</div>
                {/* <div
                  class='tp-right-arrow'
                  onClick={() => this.changeData('next')}>
                  <img src={rightArrow} alt='' />
                </div> */}
              </div>
              <div class='tp-time-intro'>
                <strong>{this.state.time.format('h:mm a')}</strong>
              </div>
            </div>
            <div class='tp-button-group d-flex align-items-center justify-content-end'>
              <Link to='/staff/recordVisit' class='btn2 btn-outline no-shadow'>
                Record Visit
              </Link>
              <Link
                to='/staff/newAppointment'
                class='btn2 btn-outline no-shadow'>
                + New Appointment
              </Link>
              <Link to='/staff/addPatient' class='btn2 btn-default'>
                + New Patient
              </Link>
            </div>
          </div>
          <div class='dashboard-main-pannel d-lg-flex flex-lg-wrap'>
            <div class='pannel-left-staff'>
              <div class='pannel-header'>
                <ul class='pannel-page-links d-flex flex-wrap'>
                  <li
                    class={
                      this.state.type === 'online'
                        ? 'ppl-item active'
                        : 'ppl-item'
                    }>
                    <a
                      href='#!'
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ type: 'online' })
                      }}
                      class='ppl-box'>
                      <strong>
                        {
                          this.props.getAppointment.data.filter(
                            e => e.appointment_type !== 'walkin'
                          ).length
                        }
                      </strong>
                      <p>
                        {this.state.date.format('dddd, D MMM') ===
                          moment().format('dddd, D MMM') && 'Today’s'}{' '}
                        Appointment
                      </p>
                    </a>
                  </li>
                  <li
                    class={
                      this.state.type === 'walkin'
                        ? 'ppl-item active'
                        : 'ppl-item'
                    }>
                    <a
                      href='#!'
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ type: 'walkin' })
                      }}
                      class='ppl-box'>
                      <strong>
                        {
                          this.props.getAppointment.data.filter(
                            e => e.appointment_type === 'walkin'
                          ).length
                        }
                      </strong>
                      <p>
                        {this.state.date.format('dddd, D MMM') ===
                          moment().format('dddd, D MMM') && 'Today’s'}{' '}
                        Walk-Ins
                      </p>
                    </a>
                  </li>
                  <li class='ppl-item'>
                    <a class='ppl-box'>
                      <strong>
                        {this.props.getDoctorPatient.total_record}
                      </strong>
                      <p>My Patients</p>
                    </a>
                  </li>
                </ul>
                <h4>
                  {this.state.date.format('dddd, D MMM') ===
                    moment().format('dddd, D MMM') && 'Today’s'}{' '}
                  Appointment
                </h4>
              </div>

              <div class='appointments-pannel-outer  bg-white border-radius2  add-scrollbar  scrollbar-dynamic'>
                <div class='appointments-pannel'>
                  {this.props.getAppointment.data
                    .filter(e =>
                      this.state.type === 'online'
                        ? e.appointment_type !== 'walkin' &&
                          !e.noShow &&
                          moment()
                            .subtract({
                              minutes: e.doctor_id.schedules.time,
                            })
                            .diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) < 0
                        : e.appointment_type === this.state.type &&
                          !e.noShow &&
                          !e.noShow &&
                          moment()
                            .subtract({
                              minutes: e.doctor_id.schedules.time,
                            })
                            .diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) < 0
                    )
                    .map(ea => (
                      <div class='person-details d-flex align-items-center justify-content-between'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img has-active'>
                            {ea.patient_id.thumb ? (
                              <img
                                src={
                                  process.env.REACT_APP_S3URL +
                                  ea.patient_id.thumb
                                }
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {ea.patient_id.first_name +
                                ' ' +
                                ea.patient_id.last_name}

                              {ea.patient_id.star.length > 0 &&
                                ea.patient_id.star.find(
                                  e => e === this.props.doctorProfile._id
                                ) && (
                                  <i>
                                    <img src={blueStar} alt='star-icon' />
                                  </i>
                                )}
                            </h3>
                            <div class='patient-btn d-flex align-items-center justify-content-left'>
                              <span class='patient-tags ml-0 light-blue'>
                                {ea.appointment_type}
                              </span>

                              <a class='profile-box'>
                                {ea.patient_id.type === 2 ? (
                                  <i class='fas fa-child'></i>
                                ) : (
                                  <i class='fas fa-user'></i>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                          <strong>
                            {moment(ea.appointment_time).utc().format('h:mm a')}
                          </strong>
                        </div>
                      </div>
                    ))}
                  {this.props.getAppointment.data.filter(e =>
                    this.state.type === 'online'
                      ? e.appointment_type !== 'walkin' && e.noShow
                      : e.appointment_type === this.state.type && e.noShow
                  ).length > 0 && (
                    <div class='chat-seprator1'>
                      <span>
                        No Show{' '}
                        {
                          this.props.getAppointment.data.filter(e =>
                            this.state.type === 'online'
                              ? e.appointment_type !== 'walkin' && e.noShow
                              : e.appointment_type === this.state.type &&
                                e.noShow
                          ).length
                        }
                      </span>
                    </div>
                  )}
                  {this.props.getAppointment.data
                    .filter(e =>
                      this.state.type === 'online'
                        ? e.appointment_type !== 'walkin' && e.noShow
                        : e.appointment_type === this.state.type && e.noShow
                    )
                    .map(ea => (
                      <div class='person-details d-flex align-items-center justify-content-between'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img has-active'>
                            {ea.patient_id.thumb ? (
                              <img
                                src={
                                  process.env.REACT_APP_S3URL +
                                  ea.patient_id.thumb
                                }
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {ea.patient_id.first_name +
                                ' ' +
                                ea.patient_id.last_name}

                              {ea.patient_id.star.length > 0 &&
                                ea.patient_id.star.find(
                                  e => e === this.props.doctorProfile._id
                                ) && (
                                  <i>
                                    <img src={blueStar} alt='star-icon' />
                                  </i>
                                )}
                            </h3>
                            <div class='patient-btn d-flex align-items-center justify-content-left'>
                              <span class='patient-tags ml-0 light-blue'>
                                {ea.appointment_type}
                              </span>

                              <a class='profile-box'>
                                {ea.patient_id.type === 2 ? (
                                  <i class='fas fa-child'></i>
                                ) : (
                                  <i class='fas fa-user'></i>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                          {ea.appointment_type === 'online' && (
                            <>
                              {(this.state.time.diff(
                                moment(ea.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 0 ||
                                process.env.REACT_APP_MODE === 'development') &&
                                (this.state.time
                                  .subtract({
                                    minutes: ea.doctor_id.schedules.time,
                                  })
                                  .diff(
                                    moment(ea.appointment_time).subtract({
                                      hours: 5,
                                      minutes: 30,
                                    }),
                                    'minutes'
                                  ) < 0 ||
                                  process.env.REACT_APP_MODE ===
                                    'development') &&
                                this.state.type === 'online' && (
                                  <div class='pd-text'>
                                    <a
                                      target='_blank'
                                      href={'/doctor/video-call/' + ea._id}
                                      class='btn btn-blue'
                                      // onClick={(e) => this.onCall(e, ea._id)}
                                    >
                                      Join Call
                                    </a>
                                  </div>
                                )}
                            </>
                          )}
                          <strong>
                            {moment(ea.appointment_time).utc().format('h:mm a')}
                          </strong>
                        </div>
                      </div>
                    ))}

                  {this.props.getAppointment.data.filter(e =>
                    this.state.type === 'online'
                      ? e.appointment_type !== 'walkin' &&
                        !e.noShow &&
                        moment()
                          .subtract({
                            minutes: e.doctor_id.schedules.time,
                          })
                          .diff(
                            moment(e.appointment_time).subtract({
                              hours: 5,
                              minutes: 30,
                            }),
                            'minutes'
                          ) > 0
                      : e.appointment_type === this.state.type &&
                        !e.noShow &&
                        !e.noShow &&
                        moment()
                          .subtract({
                            minutes: e.doctor_id.schedules.time,
                          })
                          .diff(
                            moment(e.appointment_time).subtract({
                              hours: 5,
                              minutes: 30,
                            }),
                            'minutes'
                          ) > 0
                  ).length > 0 && (
                    <div class='chat-seprator1'>
                      <span>Completed</span>
                    </div>
                  )}

                  {this.props.getAppointment.data
                    .filter(e =>
                      this.state.type === 'online'
                        ? e.appointment_type !== 'walkin' &&
                          !e.noShow &&
                          moment()
                            .subtract({
                              minutes: e.doctor_id.schedules.time,
                            })
                            .diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) > 0
                        : e.appointment_type === this.state.type &&
                          !e.noShow &&
                          !e.noShow &&
                          moment()
                            .subtract({
                              minutes: e.doctor_id.schedules.time,
                            })
                            .diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) > 0
                    )
                    .map(ea => (
                      <div class='person-details d-flex align-items-center justify-content-between'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img has-active'>
                            {ea.patient_id.thumb ? (
                              <img
                                src={
                                  process.env.REACT_APP_S3URL +
                                  ea.patient_id.thumb
                                }
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {ea.patient_id.first_name +
                                ' ' +
                                ea.patient_id.last_name}

                              {ea.patient_id.star.length > 0 &&
                                ea.patient_id.star.find(
                                  e => e === this.props.doctorProfile._id
                                ) && (
                                  <i>
                                    <img src={blueStar} alt='star-icon' />
                                  </i>
                                )}
                            </h3>
                            <div class='patient-btn d-flex align-items-center justify-content-left'>
                              <span class='patient-tags ml-0 light-blue'>
                                {ea.appointment_type}
                              </span>

                              <a class='profile-box'>
                                {ea.patient_id.type === 2 ? (
                                  <i class='fas fa-child'></i>
                                ) : (
                                  <i class='fas fa-user'></i>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                          {ea.appointment_type === 'online' && (
                            <>
                              {(this.state.time.diff(
                                moment(ea.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 0 ||
                                process.env.REACT_APP_MODE === 'development') &&
                                (this.state.time
                                  .subtract({
                                    minutes: ea.doctor_id.schedules.time,
                                  })
                                  .diff(
                                    moment(ea.appointment_time).subtract({
                                      hours: 5,
                                      minutes: 30,
                                    }),
                                    'minutes'
                                  ) < 0 ||
                                  process.env.REACT_APP_MODE ===
                                    'development') &&
                                this.state.type === 'online' && (
                                  <div class='pd-text'>
                                    <a
                                      target='_blank'
                                      href={'/doctor/video-call/' + ea._id}
                                      class='btn btn-blue'
                                      // onClick={(e) => this.onCall(e, ea._id)}
                                    >
                                      Join Call
                                    </a>
                                  </div>
                                )}
                            </>
                          )}
                          <strong>
                            {moment(ea.appointment_time).utc().format('h:mm a')}
                          </strong>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* <SideTabs appointment_date={this.state.date.format('YYYY-MM-DD')} /> */}
          </div>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadAllAppointmentForDoctor: params =>
    dispatch(loadAllAppointmentForDoctor(params)),
  loadAppointmentDetail: (id, callback) =>
    dispatch(loadAppointmentDetail(id, callback)),
  loadAllDoctorpatient: params => dispatch(loadAllDoctorpatient(params)),
})
const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state).doctorAppointment,
  getDoctorPatient: getDoctorPatient(state).doctorPatient.pagination,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DoctorDashboard)
)
