import React from "react";

const YesMindy = () => {
  return (
    <>
      <span
        style={{
          color: "#582bb8",
          fontWeight: 700,
        }}
      >
        Yes
      </span>
      <span
        style={{
          color: "rgb(246 142 63)",
          fontWeight: 700,
        }}
      >
        Mindy
      </span>
    </>
  );
};

export default YesMindy;
