import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCaserecord,
  addDemographics,
  loadCaseRecord,
} from "../../../../store/caseRecord";
import {
  past,
  home,
  parenting,
  scholastic,
  social,
  play,
  type,
  tolerance,
  dependence,
  withdrawal,
  cravings,
  observations,
  prenatal,
  birthTerm,
  birthPlace,
  deliveryType,
  motivation,
  postnatal,
  developmentalHistory,
  immunization,
  score,
  control,
  score1,
  score2,
  score3,
  score4,
  forensic,
} from "./common";
import download from "../../../../include/images/download-icon.svg";
import Joi from "joi-browser";
import Form from "../../../common/form/form";
import _ from "lodash";
import AlertSuccess from "../../../common/alertSuccess";
import { toast } from "react-toastify";
import PhysicalExamination from "./physicalExamination";
import Temperament from "./temperament";
import PersonalHistory from "./personalHistory";
import Personality from "./personality";

class Additional extends Form {
  state = {
    sections: [],
    homeEnvironment: [],
    parenting: [],
    scholastic: [],
    social: [],
    play: [],
    type: [],
    tolerance: [],
    dependence: [],
    withdrawal: [],
    cravings: [],
    observations: [],
    pulse: "",
    height: "",
    weight: "",
    bp: "",
    rr: "",
    bmi: "",
    bp2: "",
    temp: [],
    physicalOther: [],
    physicalGeneral: "",
    physicalFinding: "",
    cvs: "",
    abnormalFindings1: "",
    cns: [],
    abnormalFindings2: "",
    respiratory: [],
    abnormalFindings3: "",
    git: [],
    abnormalFindings4: "",
    higherMental: [],
    abnormalFindings5: "",
    activity: [],
    writeMore1: "",
    attention: [],
    writeMore2: "",
    approach: [],
    writeMore3: "",
    adaptability: [],
    writeMore4: "",
    distractability: [],
    writeMore5: "",
    intensity: [],
    writeMore6: "",
    threshold: [],
    writeMore7: "",
    moodQuality: [],
    writeMore8: "",
    rhythmicity: [],
    writeMore9: "",
    extraNote: "",
    birth_early: [],
    specify1: "",
    disorder: [],
    specify2: "",
    family: [],
    specify3: "",
    extracurricular: [],
    occupation: [],
    specify4: "",
    menstrual: [],
    LMP: "",
    menarche: "",
    menopause: "",
    sexual: [],
    specify5: "",
    specify6: "",
    forensic: "",
    social_realtion: [],
    writeMore11: "",
    writeMore10: "",
    hobbies: [],
    mood: [],
    writeMore12: "",
    character: [],
    writeMore13: "",
    interpersonal: [],
    writeMore14: "",
    morbid: "",
    matters: [],
    writeMore15: "",
    energy: [],
    writeMore16: "",
    writeMore17: "",
    writeMore18: "",
    fantasy: [],
    habits: [],
    data: {
      personalHistory: "",
      pleaseSpecify: "",
      birthTerm: "",
      birthPlace: "",
      delivery: "",
      birthSpecify: "",
      infection: "",
      convulsions: "",
      feedingIssues: "",
      failureThrive: "",
      postnatalSpecify: "",
      developHistory: "",
      developSpecify: "",
      immunization: "",
      immuneSpecify: "",
      strength: "",
      weakness: "",
      findings: "",
      typeSpecify: "",
      withdrawalSpecify: "",
      substanceOther: "",
      observationSpecify: "",
      locus: "",
      motivation: "",
      general_appearance: "",
      capacity: "",
      motorBehaviour: "",
      speech: "",
      affective_behaviour: "",
      attention_span: "",
      play_behaviour: "",
      intellectual_insight: "",
      motivational_insight: "",
      sexual_issue: "",
      masturbation_history: "",
      interpersonal_sexual: "",
      current_sexual: "",
      alibido_interests: "",
      arousal_performance: "",
      orgasm_satisfaction: "",
      pain_vaginismus: "",
      ejaculation: "",
      partners: "",
      orientation: "",
      psychosexual_disorder: "",
      year: "",
      month: "",
      date: "",
      day: "",
      season: "",
      country: "",
      state: "",
      city: "",
      street: "",
      floor: "",
      room: "",
      namingObjects: "",
      backwards: "",
      recall: "",
      showSimple: "",
      phrase: "",
      page: "",
      figure: "",
      nonDominant: "",
      totalScore: "",
      interpretation: "",
    },
    errors: {},
  };

  schema = {
    personalHistory: Joi.string().allow(""),
    pleaseSpecify: Joi.string().allow(""),
    birthTerm: Joi.string().allow(""),
    birthPlace: Joi.string().allow(""),
    delivery: Joi.string().allow(""),
    birthSpecify: Joi.string().allow(""),
    infection: Joi.string().allow(""),
    convulsions: Joi.string().allow(""),
    feedingIssues: Joi.string().allow(""),
    failureThrive: Joi.string().allow(""),
    postnatalSpecify: Joi.string().allow(""),
    developSpecify: Joi.string().allow(""),
    developHistory: Joi.string().allow(""),
    immunization: Joi.string().allow(""),
    immuneSpecify: Joi.string().allow(""),
    strength: Joi.string().allow(""),
    weakness: Joi.string().allow(""),
    findings: Joi.string().allow(""),
    typeSpecify: Joi.string().allow(""),
    withdrawalSpecify: Joi.string().allow(""),
    substanceOther: Joi.string().allow(""),
    observationSpecify: Joi.string().allow(""),
    locus: Joi.string().allow(""),
    motivation: Joi.string().allow(""),
    general_appearance: Joi.string().allow(""),
    capacity: Joi.string().allow(""),
    motorBehaviour: Joi.string().allow(""),
    speech: Joi.string().allow(""),
    affective_behaviour: Joi.string().allow(""),
    attention_span: Joi.string().allow(""),
    play_behaviour: Joi.string().allow(""),
    intellectual_insight: Joi.string().allow(""),
    motivational_insight: Joi.string().allow(""),
    sexual_issue: Joi.string().allow(""),
    masturbation_history: Joi.string().allow(""),
    interpersonal_sexual: Joi.string().allow(""),
    current_sexual: Joi.string().allow(""),
    alibido_interests: Joi.string().allow(""),
    arousal_performance: Joi.string().allow(""),
    orgasm_satisfaction: Joi.string().allow(""),
    pain_vaginismus: Joi.string().allow(""),
    ejaculation: Joi.string().allow(""),
    partners: Joi.string().allow(""),
    orientation: Joi.string().allow(""),
    psychosexual_disorder: Joi.string().allow(""),
    year: Joi.string().allow(""),
    month: Joi.string().allow(""),
    date: Joi.string().allow(""),
    day: Joi.string().allow(""),
    season: Joi.string().allow(""),
    country: Joi.string().allow(""),
    state: Joi.string().allow(""),
    city: Joi.string().allow(""),
    street: Joi.string().allow(""),
    floor: Joi.string().allow(""),
    room: Joi.string().allow(""),
    namingObjects: Joi.string().allow(""),
    backwards: Joi.string().allow(""),
    recall: Joi.string().allow(""),
    showSimple: Joi.string().allow(""),
    phrase: Joi.string().allow(""),
    page: Joi.string().allow(""),
    figure: Joi.string().allow(""),
    nonDominant: Joi.string().allow(""),
    totalScore: Joi.string().allow(""),
    interpretation: Joi.string().allow(""),
  };
  doSubmit = () => {
    const formData = {
      additional_section: {
        first_option: this.state.sections,
        formData: _.pickBy(
          {
            Childhood_History: {
              personalHistory: this.state.data.personalHistory,
              pleaseSpecify: this.state.data.pleaseSpecify,
              birthTerm: this.state.data.birthTerm,
              birthPlace: this.state.data.birthPlace,
              delivery: this.state.data.delivery,
              birthSpecify: this.state.data.birthSpecify,
              infection: this.state.data.infection,
              convulsions: this.state.data.convulsions,
              feedingIssues: this.state.data.feedingIssues,
              failureThrive: this.state.data.failureThrive,
              postnatalSpecify: this.state.data.postnatalSpecify,
              developHistory: this.state.data.developHistory,
              developSpecify: this.state.data.developSpecify,
              immunization: this.state.data.immunization,
              immuneSpecify: this.state.data.immuneSpecify,
              strength: this.state.data.strength,
              weakness: this.state.data.weakness,
              findings: this.state.data.findings,
              homeEnvironment: this.state.homeEnvironment,
              parenting: this.state.parenting,
              scholastic: this.state.scholastic,
              social: this.state.social,
              play: this.state.play,
            },
            Substance_History: _.pickBy(
              {
                typeSpecify: this.state.data.typeSpecify,
                withdrawalSpecify: this.state.data.withdrawalSpecify,
                substanceOther: this.state.data.substanceOther,
                observationSpecify: this.state.data.observationSpecify,
                locus: this.state.data.locus,
                motivation: this.state.data.motivation,
                type: this.state.type,
                tolerance: this.state.tolerance,
                dependence: this.state.dependence,
                withdrawal: this.state.withdrawal,
                cravings: this.state.cravings,
                observations: this.state.observations,
              },
              _.identity
            ),
            Behavioural_Observation: _.pickBy(
              {
                general_appearance: this.state.data.general_appearance,
                capacity: this.state.data.capacity,
                motorBehaviour: this.state.data.motorBehaviour,
                speech: this.state.data.speech,
                affective_behaviour: this.state.data.affective_behaviour,
                attention_span: this.state.data.attention_span,
                play_behaviour: this.state.data.play_behaviour,
                intellectual_insight: this.state.data.intellectual_insight,
                motivational_insight: this.state.data.motivational_insight,
              },
              _.identity
            ),
            Sexual_History: _.pickBy(
              {
                sexual_issue: this.state.data.sexual_issue,
                masturbation_history: this.state.data.masturbation_history,
                interpersonal_sexual: this.state.data.interpersonal_sexual,
                current_sexual: this.state.data.current_sexual,
                alibido_interests: this.state.data.alibido_interests,
                arousal_performance: this.state.data.arousal_performance,
                orgasm_satisfaction: this.state.data.orgasm_satisfaction,
                pain_vaginismus: this.state.data.pain_vaginismus,
                ejaculation: this.state.data.ejaculation,
                partners: this.state.data.partners,
                orientation: this.state.data.orientation,
                psychosexual_disorder: this.state.data.psychosexual_disorder,
              },
              _.identity
            ),
            MMSE: _.pickBy(
              {
                year: this.state.data.year,
                month: this.state.data.month,
                date: this.state.data.date,
                day: this.state.data.day,
                season: this.state.data.season,
                country: this.state.data.country,
                state: this.state.data.state,
                city: this.state.data.city,
                street: this.state.data.street,
                floor: this.state.data.floor,
                room: this.state.data.room,
                namingObjects: this.state.data.namingObjects,
                backwards: this.state.data.backwards,
                recall: this.state.data.recall,
                showSimple: this.state.data.showSimple,
                phrase: this.state.data.phrase,
                page: this.state.data.page,
                figure: this.state.data.figure,
                nonDominant: this.state.data.nonDominant,
                totalScore: this.state.data.totalScore,
                interpretation: this.state.data.interpretation,
              },
              _.identity
            ),
            Physical_Examination: _.pickBy(
              {
                pulse: this.state.pulse,
                height: this.state.height,
                weight: this.state.weight,
                bp: this.state.bp,
                rr: this.state.rr,
                bmi: this.state.bmi,
                bp2: this.state.bp2,
                temp: this.state.temp,
                physicalOther: this.state.physicalOther,
                physicalGeneral: this.state.physicalGeneral,
                physicalFinding: this.state.physicalFinding,
                cvs: this.state.cvs,
                abnormalFindings1: this.state.abnormalFindings1,
                cns: this.state.cns,
                abnormalFindings2: this.state.abnormalFindings2,
                respiratory: this.state.respiratory,
                abnormalFindings3: this.state.abnormalFindings3,
                git: this.state.git,
                abnormalFindings4: this.state.abnormalFindings4,
                higherMental: this.state.higherMental,
                abnormalFindings5: this.state.abnormalFindings5,
              },
              _.identity
            ),
            Temperament: _.pickBy(
              {
                activity: this.state.activity,
                writeMore1: this.state.writeMore1,
                attention: this.state.attention,
                writeMore2: this.state.writeMore2,
                approach: this.state.approach,
                writeMore3: this.state.writeMore3,
                adaptability: this.state.adaptability,
                writeMore4: this.state.writeMore4,
                distractability: this.state.distractability,
                writeMore5: this.state.writeMore5,
                intensity: this.state.intensity,
                writeMore6: this.state.writeMore6,
                threshold: this.sthreshold,
                writeMore7: this.state.writeMore7,
                moodQuality: this.state.moodQuality,
                writeMore8: this.state.writeMore8,
                rhythmicity: this.state.rhythmicity,
                writeMore9: this.state.writeMore9,
                extraNote: this.state.extraNote,
              },
              _.identity
            ),
            Personal_History: _.pickBy(
              {
                birth_early: this.state.birth_early,
                specify1: this.state.specify1,
                disorder: this.state.disorder,
                specify2: this.state.specify2,
                family: this.state.family,
                specify3: this.state.specify3,
                extracurricular: this.state.extracurricular,
                occupation: this.state.occupation,
                specify4: this.state.specify4,
                menstrual: this.state.menstrual,
                LMP: this.state.LMP,
                menarche: this.state.menarche,
                menopause: this.state.menopause,
                sexual: this.state.sexual,
                specify5: this.state.specify5,
                specify6: this.state.specify6,
                forensic: this.state.forensic,
              },
              _.identity
            ),
            Personality: _.pickBy(
              {
                social_realtion: this.state.social_realtion,
                writeMore11: this.state.writeMore11,
                writeMore10: this.state.writeMore10,
                hobbies: this.state.hobbies,
                mood: this.state.mood,
                writeMore12: this.state.writeMore12,
                character: this.state.character,
                writeMore13: this.state.writeMore13,
                interpersonal: this.state.interpersonal,
                writeMore14: this.state.writeMore14,
                morbid: this.state.morbid,
                matters: this.state.matters,
                writeMore15: this.state.writeMore15,
                energy: this.state.energy,
                writeMore16: this.state.writeMore16,
                writeMore17: this.state.writeMore17,
                writeMore18: this.state.writeMore18,
                fantasy: this.state.fantasy,
                habits: this.state.habits,
              },
              _.identity
            ),
          },
          _.identity
        ),
      },
    };
    const id = this.props.data._id;
    const newData = _.pickBy(formData, _.identity);
    this.props.addDemographics(newData, id, this.callBack);
  };
  callBack = (res) => {
    const _id = this.props.data && this.props.data._id;
    if (res && res.status === 200) {
      this.props.updateStep(null,5)
      this.props.loadCaseRecord(_id);
      toast(<AlertSuccess message={"Information Saved."} />);
    }
  };
  changeLang = (Lang) => {
    let sections = [...this.state.sections];
    const find = sections.find((v) => v === Lang);
    if (find) {
      const f = sections.filter((vf) => vf !== Lang);
      sections = f;
    } else {
      if (this.state.sections.length < 3) {
        sections.push(Lang);
      }
    }
    this.setState({ sections });
  };
  changeLang2 = (Lang) => {
    let physicalOther = [...this.state.physicalOther];
    const find = physicalOther.find((v) => v === Lang);
    if (find) {
      const f = physicalOther.filter((vf) => vf !== Lang);
      physicalOther = f;
    } else {
      physicalOther.push(Lang);
    }
    this.setState({ physicalOther });
  };
  changeCNS = (Lang) => {
    let cns = [...this.state.cns];
    const find = cns.find((v) => v === Lang);
    if (find) {
      const f = cns.filter((vf) => vf !== Lang);
      cns = f;
    } else {
      cns.push(Lang);
    }
    this.setState({ cns });
  };
  handleTempChange = (Lang) => {
    let temp = [...this.state.temp];
    const find = temp.find((v) => v === Lang);
    if (find) {
      const f = temp.filter((vf) => vf !== Lang);
      temp = f;
    } else {
      temp.push(Lang);
    }
    this.setState({ temp });
  };
  changeLang3 = (Lang) => {
    let activity = [...this.state.activity];
    const find = activity.find((v) => v === Lang);
    if (find) {
      const f = activity.filter((vf) => vf !== Lang);
      activity = f;
    } else {
      activity.push(Lang);
    }
    this.setState({ activity });
  };
  changeLang5 = (Lang) => {
    let approach = [...this.state.approach];
    const find = approach.find((v) => v === Lang);
    if (find) {
      const f = approach.filter((vf) => vf !== Lang);
      approach = f;
    } else {
      approach.push(Lang);
    }
    this.setState({ approach });
  };
  changeLang6 = (Lang) => {
    let adaptability = [...this.state.adaptability];
    const find = adaptability.find((v) => v === Lang);
    if (find) {
      const f = adaptability.filter((vf) => vf !== Lang);
      adaptability = f;
    } else {
      adaptability.push(Lang);
    }
    this.setState({ adaptability });
  };
  changeLang4 = (Lang) => {
    let attention = [...this.state.attention];
    const find = attention.find((v) => v === Lang);
    if (find) {
      const f = attention.filter((vf) => vf !== Lang);
      attention = f;
    } else {
      attention.push(Lang);
    }
    this.setState({ attention });
  };
  changeLang7 = (Lang) => {
    let distractability = [...this.state.distractability];
    const find = distractability.find((v) => v === Lang);
    if (find) {
      const f = distractability.filter((vf) => vf !== Lang);
      distractability = f;
    } else {
      distractability.push(Lang);
    }
    this.setState({ distractability });
  };
  changeLang8 = (Lang) => {
    let intensity = [...this.state.intensity];
    const find = intensity.find((v) => v === Lang);
    if (find) {
      const f = intensity.filter((vf) => vf !== Lang);
      intensity = f;
    } else {
      intensity.push(Lang);
    }
    this.setState({ intensity });
  };
  changeLang9 = (Lang) => {
    let threshold = [...this.state.threshold];
    const find = threshold.find((v) => v === Lang);
    if (find) {
      const f = threshold.filter((vf) => vf !== Lang);
      threshold = f;
    } else {
      threshold.push(Lang);
    }
    this.setState({ threshold });
  };
  changeLang11 = (Lang) => {
    let rhythmicity = [...this.state.rhythmicity];
    const find = rhythmicity.find((v) => v === Lang);
    if (find) {
      const f = rhythmicity.filter((vf) => vf !== Lang);
      rhythmicity = f;
    } else {
      rhythmicity.push(Lang);
    }
    this.setState({ rhythmicity });
  };
  changeLang10 = (Lang) => {
    let moodQuality = [...this.state.moodQuality];
    const find = moodQuality.find((v) => v === Lang);
    if (find) {
      const f = moodQuality.filter((vf) => vf !== Lang);
      moodQuality = f;
    } else {
      moodQuality.push(Lang);
    }
    this.setState({ moodQuality });
  };
  changeLang12 = (Lang) => {
    let birth_early = [...this.state.birth_early];
    const find = birth_early.find((v) => v === Lang);
    if (find) {
      const f = birth_early.filter((vf) => vf !== Lang);
      birth_early = f;
    } else {
      birth_early.push(Lang);
    }
    this.setState({ birth_early });
  };
  changeLang13 = (Lang) => {
    let disorder = [...this.state.disorder];
    const find = disorder.find((v) => v === Lang);
    if (find) {
      const f = disorder.filter((vf) => vf !== Lang);
      disorder = f;
    } else {
      disorder.push(Lang);
    }
    this.setState({ disorder });
  };
  changeLang14 = (Lang) => {
    let family = [...this.state.family];
    const find = family.find((v) => v === Lang);
    if (find) {
      const f = family.filter((vf) => vf !== Lang);
      family = f;
    } else {
      family.push(Lang);
    }
    this.setState({ family });
  };
  changeLang15 = (Lang) => {
    let extracurricular = [...this.state.extracurricular];
    const find = extracurricular.find((v) => v === Lang);
    if (find) {
      const f = extracurricular.filter((vf) => vf !== Lang);
      extracurricular = f;
    } else {
      extracurricular.push(Lang);
    }
    this.setState({ extracurricular });
  };
  changeLang16 = (Lang) => {
    let occupation = [...this.state.occupation];
    const find = occupation.find((v) => v === Lang);
    if (find) {
      const f = occupation.filter((vf) => vf !== Lang);
      occupation = f;
    } else {
      occupation.push(Lang);
    }
    this.setState({ occupation });
  };
  changeLang17 = (Lang) => {
    let menstrual = [...this.state.menstrual];
    const find = menstrual.find((v) => v === Lang);
    if (find) {
      const f = menstrual.filter((vf) => vf !== Lang);
      menstrual = f;
    } else {
      menstrual.push(Lang);
    }
    this.setState({ menstrual });
  };
  changeLang18 = (Lang) => {
    let sexual = [...this.state.sexual];
    const find = sexual.find((v) => v === Lang);
    if (find) {
      const f = sexual.filter((vf) => vf !== Lang);
      sexual = f;
    } else {
      sexual.push(Lang);
    }
    this.setState({ sexual });
  };
  changeLang19 = (Lang) => {
    let social_realtion = [...this.state.social_realtion];
    const find = social_realtion.find((v) => v === Lang);
    if (find) {
      const f = social_realtion.filter((vf) => vf !== Lang);
      social_realtion = f;
    } else {
      social_realtion.push(Lang);
    }
    this.setState({ social_realtion });
  };
  changeLang20 = (Lang) => {
    let hobbies = [...this.state.hobbies];
    const find = hobbies.find((v) => v === Lang);
    if (find) {
      const f = hobbies.filter((vf) => vf !== Lang);
      hobbies = f;
    } else {
      hobbies.push(Lang);
    }
    this.setState({ hobbies });
  };
  changeLang21 = (Lang) => {
    let mood = [...this.state.mood];
    const find = mood.find((v) => v === Lang);
    if (find) {
      const f = mood.filter((vf) => vf !== Lang);
      mood = f;
    } else {
      mood.push(Lang);
    }
    this.setState({ mood });
  };
  changeLang22 = (Lang) => {
    let character = [...this.state.character];
    const find = character.find((v) => v === Lang);
    if (find) {
      const f = character.filter((vf) => vf !== Lang);
      character = f;
    } else {
      character.push(Lang);
    }
    this.setState({ character });
  };
  changeLang23 = (Lang) => {
    let interpersonal = [...this.state.interpersonal];
    const find = interpersonal.find((v) => v === Lang);
    if (find) {
      const f = interpersonal.filter((vf) => vf !== Lang);
      interpersonal = f;
    } else {
      interpersonal.push(Lang);
    }
    this.setState({ interpersonal });
  };
  changeLang24 = (Lang) => {
    let matters = [...this.state.matters];
    const find = matters.find((v) => v === Lang);
    if (find) {
      const f = matters.filter((vf) => vf !== Lang);
      matters = f;
    } else {
      matters.push(Lang);
    }
    this.setState({ matters });
  };
  changeLang25 = (Lang) => {
    let energy = [...this.state.energy];
    const find = energy.find((v) => v === Lang);
    if (find) {
      const f = energy.filter((vf) => vf !== Lang);
      energy = f;
    } else {
      energy.push(Lang);
    }
    this.setState({ energy });
  };
  changeResp = (Lang)=>{
    let respiratory = [...this.state.respiratory];
    const find = respiratory.find((v) => v === Lang);
    if (find) {
      const f = respiratory.filter((vf) => vf !== Lang);
      respiratory = f;
    } else {
      respiratory.push(Lang);
    }
    this.setState({ respiratory });
    
  }

  changeLang26 = (Lang) => {
    let fantasy = [...this.state.fantasy];
    const find = fantasy.find((v) => v === Lang);
    if (find) {
      const f = fantasy.filter((vf) => vf !== Lang);
      fantasy = f;
    } else {
      fantasy.push(Lang);
    }
    this.setState({ fantasy });
  };
  changeLang27 = (Lang) => {
    let habits = [...this.state.habits];
    const find = habits.find((v) => v === Lang);
    if (find) {
      const f = habits.filter((vf) => vf !== Lang);
      habits = f;
    } else {
      habits.push(Lang);
    }
    this.setState({ habits });
  };
  changeHome = (Lang) => {
    let homeEnvironment = [...this.state.homeEnvironment];
    const find = homeEnvironment.find((v) => v === Lang);
    if (find) {
      const f = homeEnvironment.filter((vf) => vf !== Lang);
      homeEnvironment = f;
    } else {
      homeEnvironment.push(Lang);
    }
    this.setState({ homeEnvironment });
  };
  changeParenting = (Lang) => {
    let parenting = [...this.state.parenting];
    const find = parenting.find((v) => v === Lang);
    if (find) {
      const f = parenting.filter((vf) => vf !== Lang);
      parenting = f;
    } else {
      parenting.push(Lang);
    }
    this.setState({ parenting });
  };
  changeScholastic = (Lang) => {
    let scholastic = [...this.state.scholastic];
    const find = scholastic.find((v) => v === Lang);
    if (find) {
      const f = scholastic.filter((vf) => vf !== Lang);
      scholastic = f;
    } else {
      scholastic.push(Lang);
    }
    this.setState({ scholastic });
  };
  changeSocial = (Lang) => {
    let social = [...this.state.social];
    const find = social.find((v) => v === Lang);
    if (find) {
      const f = social.filter((vf) => vf !== Lang);
      social = f;
    } else {
      social.push(Lang);
    }
    this.setState({ social });
  };
  changePlay = (Lang) => {
    let play = [...this.state.play];
    const find = play.find((v) => v === Lang);
    if (find) {
      const f = play.filter((vf) => vf !== Lang);
      play = f;
    } else {
      play.push(Lang);
    }
    this.setState({ play });
  };
  changeType = (Lang) => {
    let type = [...this.state.type];
    const find = type.find((v) => v === Lang);
    if (find) {
      const f = type.filter((vf) => vf !== Lang);
      type = f;
    } else {
      type.push(Lang);
    }
    this.setState({ type });
  };
  changeTolerance = (Lang) => {
    let tolerance = [...this.state.tolerance];
    const find = tolerance.find((v) => v === Lang);
    if (find) {
      const f = tolerance.filter((vf) => vf !== Lang);
      tolerance = f;
    } else {
      tolerance.push(Lang);
    }
    this.setState({ tolerance });
  };
  changeDependence = (Lang) => {
    let dependence = [...this.state.dependence];
    const find = dependence.find((v) => v === Lang);
    if (find) {
      const f = dependence.filter((vf) => vf !== Lang);
      dependence = f;
    } else {
      dependence.push(Lang);
    }
    this.setState({ dependence });
  };
  changeWithdrawal = (Lang) => {
    let withdrawal = [...this.state.withdrawal];
    const find = withdrawal.find((v) => v === Lang);
    if (find) {
      const f = withdrawal.filter((vf) => vf !== Lang);
      withdrawal = f;
    } else {
      withdrawal.push(Lang);
    }
    this.setState({ withdrawal });
  };
  changeCravings = (Lang) => {
    let cravings = [...this.state.cravings];
    const find = cravings.find((v) => v === Lang);
    if (find) {
      const f = cravings.filter((vf) => vf !== Lang);
      cravings = f;
    } else {
      cravings.push(Lang);
    }
    this.setState({ cravings });
  };
  changeObservation = (Lang) => {
    let observations = [...this.state.observations];
    const find = observations.find((v) => v === Lang);
    if (find) {
      const f = observations.filter((vf) => vf !== Lang);
      observations = f;
    } else {
      observations.push(Lang);
    }
    this.setState({ observations });
  };
  changeGit = (Lang) => {
    let git = [...this.state.git];
    const find = git.find((v) => v === Lang);
    if (find) {
      const f = git.filter((vf) => vf !== Lang);
      git = f;
    } else {
      git.push(Lang);
    }
    this.setState({ git });
  };
  changeHigherMental = (Lang) => {
    let higherMental = [...this.state.higherMental];
    const find = higherMental.find((v) => v === Lang);
    if (find) {
      const f = higherMental.filter((vf) => vf !== Lang);
      higherMental = f;
    } else {
      higherMental.push(Lang);
    }
    this.setState({ higherMental });
  };
  togglePersonal = (e) => {
    const data = { ...this.state.data };
    data.personalHistory = e.target.value;
    this.setState({ data });
  };
  toggleBirthTerm = (e) => {
    const data = { ...this.state.data };
    data.birthTerm = e.target.value;
    this.setState({ data });
  };
  toggleBirthPlace = (e) => {
    const data = { ...this.state.data };
    data.birthPlace = e.target.value;
    this.setState({ data });
  };
  toggleDelivery = (e) => {
    const data = { ...this.state.data };
    data.delivery = e.target.value;
    this.setState({ data });
  };
  toggleInfection = (e) => {
    const data = { ...this.state.data };
    data.infection = e.target.value;
    this.setState({ data });
  };
  toggleConvulsions = (e) => {
    const data = { ...this.state.data };
    data.convulsions = e.target.value;
    this.setState({ data });
  };
  toggleForensic = (e) => {
    this.setState({ forensic: e.target.value });
  };
  toggleFeeding = (e) => {
    const data = { ...this.state.data };
    data.feedingIssues = e.target.value;
    this.setState({ data });
  };
  toggleThrive = (e) => {
    const data = { ...this.state.data };
    data.failureThrive = e.target.value;
    this.setState({ data });
  };
  toggleDelevopHistory = (e) => {
    const data = { ...this.state.data };
    data.developHistory = e.target.value;
    this.setState({ data });
  };
  toggleImmunization = (e) => {
    const data = { ...this.state.data };
    data.immunization = e.target.value;
    this.setState({ data });
  };
  toggleLocus = (e) => {
    const data = { ...this.state.data };
    data.locus = e.target.value;
    this.setState({ data });
  };
  toggleMotivation = (e) => {
    const data = { ...this.state.data };
    data.motivation = e.target.value;
    this.setState({ data });
  };
  toggleYear = (e) => {
    const data = { ...this.state.data };
    data.year = e.target.value;
    this.setState({ data });
  };
  toggleMonth = (e) => {
    const data = { ...this.state.data };
    data.month = e.target.value;
    this.setState({ data });
  };
  toggleDate = (e) => {
    const data = { ...this.state.data };
    data.date = e.target.value;
    this.setState({ data });
  };
  toggleDay = (e) => {
    const data = { ...this.state.data };
    data.day = e.target.value;
    this.setState({ data });
  };
  toggleSeason = (e) => {
    const data = { ...this.state.data };
    data.season = e.target.value;
    this.setState({ data });
  };
  toggleCountry = (e) => {
    const data = { ...this.state.data };
    data.country = e.target.value;
    this.setState({ data });
  };
  toggleState = (e) => {
    const data = { ...this.state.data };
    data.state = e.target.value;
    this.setState({ data });
  };
  toggleCity = (e) => {
    const data = { ...this.state.data };
    data.city = e.target.value;
    this.setState({ data });
  };
  toggleStreet = (e) => {
    const data = { ...this.state.data };
    data.street = e.target.value;
    this.setState({ data });
  };
  toggleRoom = (e) => {
    const data = { ...this.state.data };
    data.room = e.target.value;
    this.setState({ data });
  };
  toggleFloor = (e) => {
    const data = { ...this.state.data };
    data.floor = e.target.value;
    this.setState({ data });
  };
  toggleNaming = (e) => {
    const data = { ...this.state.data };
    data.namingObjects = e.target.value;
    this.setState({ data });
  };
  toggleBackwards = (e) => {
    const data = { ...this.state.data };
    data.backwards = e.target.value;
    this.setState({ data });
  };
  toggleRecall = (e) => {
    const data = { ...this.state.data };
    data.recall = e.target.value;
    this.setState({ data });
  };
  toggleshowSimple = (e) => {
    const data = { ...this.state.data };
    data.showSimple = e.target.value;
    this.setState({ data });
  };
  togglePhrase = (e) => {
    const data = { ...this.state.data };
    data.phrase = e.target.value;
    this.setState({ data });
  };
  togglePage = (e) => {
    const data = { ...this.state.data };
    data.page = e.target.value;
    this.setState({ data });
  };
  toggleFigure = (e) => {
    const data = { ...this.state.data };
    data.figure = e.target.value;
    this.setState({ data });
  };
  togglenonDominant = (e) => {
    const data = { ...this.state.data };
    data.nonDominant = e.target.value;
    this.setState({ data });
  };
  toggleMorbid = (e) => {
    this.setState({ morbid: e.target.value });
  };
  handleInputChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  handleInputCVS = (name) => {
    this.setState({
      cvs: name,
    });
  };
  handlephysicalGeneral = (name) => {
    this.setState({
      physicalGeneral: name,
    });
  };
  handleInputChange2 = (name) => {
    this.setState({
      git: name,
    });
  };
  handleInputChangeResp = (name) => {
    this.setState({
      respiratory: name,
    });
  };
  handleInputChangeCns = (name) => {
    this.setState({
      cns: name,
    });
  };
  handleInputChangeMental = (name) => {
    this.setState({
      higherMental: name,
    });
  };
  handleInputChangeTemp = (name) => {
    this.setState({
      temp: name,
    });
  };
  componentDidMount() {
    document.body.className = "dashboard fixed";
    if (this.props.data) {
      const { _id } = this.props.data;
      this.props.loadCaseRecord(_id);
    }
  }
  addtionalChildHood = (first, second) => {
    const { additional_section } = this.props.case;
    return additional_section &&
      additional_section.formData &&
      additional_section.formData[first] &&
      additional_section.formData[first][second]
      ? additional_section.formData[first][second]
      : [];
  };
  addtionalChildHood1 = (first, second) => {
    const { additional_section } = this.props.case;
    return additional_section &&
      additional_section.formData &&
      additional_section.formData[first] &&
      additional_section.formData[first][second]
      ? additional_section.formData[first][second]
      : "";
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.case && this.props.loading !== prevProps.loading) {
      const { additional_section } = this.props.case;
      let localCNS = this.addtionalChildHood1("Physical_Examination", "cns");
      let cnsarray = [];
      if(typeof localCNS == "string") cnsarray.push(localCNS);
      else cnsarray = localCNS;
      let localResp = this.addtionalChildHood1(
        "Physical_Examination",
        "respiratory"
      );
      let localgit=this.addtionalChildHood1("Physical_Examination", "git");
      let hmf = this.addtionalChildHood1("Physical_Examination","higherMental");
      let localtemp = this.addtionalChildHood1("Physical_Examination", "temp");

      this.setState({
        sections:
          additional_section && additional_section.first_option
            ? additional_section.first_option
            : [],
        homeEnvironment: this.addtionalChildHood(
          "Childhood_History",
          "homeEnvironment"
        ),
        parenting: this.addtionalChildHood("Childhood_History", "parenting"),
        scholastic: this.addtionalChildHood("Childhood_History", "scholastic"),
        social: this.addtionalChildHood("Childhood_History", "social"),
        play: this.addtionalChildHood("Childhood_History", "play"),
        type: this.addtionalChildHood("Substance_History", "type"),
        tolerance: this.addtionalChildHood("Substance_History", "tolerance"),
        dependence: this.addtionalChildHood("Substance_History", "dependence"),
        withdrawal: this.addtionalChildHood("Substance_History", "withdrawal"),
        cravings: this.addtionalChildHood("Substance_History", "cravings"),
        observations: this.addtionalChildHood(
          "Substance_History",
          "observations"
        ),
        pulse: this.addtionalChildHood1("Physical_Examination", "pulse"),
        height: this.addtionalChildHood1("Physical_Examination", "height"),
        weight: this.addtionalChildHood1("Physical_Examination", "weight"),
        bp: this.addtionalChildHood1("Physical_Examination", "bp"),
        rr: this.addtionalChildHood1("Physical_Examination", "rr"),
        bmi: this.addtionalChildHood1("Physical_Examination", "bmi"),
        bp2: this.addtionalChildHood1("Physical_Examination", "bp2"),
        temp: (typeof localtemp == "string")?[localtemp]:localtemp,
        physicalOther: this.addtionalChildHood1(
          "Physical_Examination",
          "physicalOther"
        ),
        physicalGeneral: this.addtionalChildHood1(
          "Physical_Examination",
          "physicalGeneral"
        ),
        physicalFinding: this.addtionalChildHood1(
          "Physical_Examination",
          "physicalFinding"
        ),
        cvs: this.addtionalChildHood1("Physical_Examination", "cvs"),
        abnormalFindings1: this.addtionalChildHood1(
          "Physical_Examination",
          "abnormalFindings1"
        ),
        cns: cnsarray,
        abnormalFindings2: this.addtionalChildHood1(
          "Physical_Examination",
          "abnormalFindings2"
        ),
        respiratory: (typeof localResp == "string")?[localResp]:localResp,
        abnormalFindings3: this.addtionalChildHood1(
          "Physical_Examination",
          "abnormalFindings3"
        ),
        git: (typeof localgit=="string")?[localgit]:localgit,
        abnormalFindings4: this.addtionalChildHood1(
          "Physical_Examination",
          "abnormalFindings4"
        ),
        higherMental: (typeof hmf == "string")?[hmf]:hmf,
        abnormalFindings5: this.addtionalChildHood1(
          "Physical_Examination",
          "abnormalFindings5"
        ),

        activity: this.addtionalChildHood("Temperament", "activity"),
        writeMore1: this.addtionalChildHood1("Temperament", "writeMore1"),
        attention: this.addtionalChildHood("Temperament", "attention"),
        writeMore2: this.addtionalChildHood1("Temperament", "writeMore2"),
        approach: this.addtionalChildHood("Temperament", "approach"),
        writeMore3: this.addtionalChildHood1("Temperament", "writeMore3"),
        adaptability: this.addtionalChildHood("Temperament", "adaptability"),
        writeMore4: this.addtionalChildHood1("Temperament", "writeMore4"),
        distractability: this.addtionalChildHood(
          "Temperament",
          "distractability"
        ),
        writeMore5: this.addtionalChildHood1("Temperament", "writeMore5"),
        intensity: this.addtionalChildHood("Temperament", "intensity"),
        writeMore6: this.addtionalChildHood1("Temperament", "writeMore6"),
        threshold: this.addtionalChildHood("Temperament", "threshold"),
        writeMore7: this.addtionalChildHood1("Temperament", "writeMore7"),
        moodQuality: this.addtionalChildHood("Temperament", "moodQuality"),
        writeMore8: this.addtionalChildHood1("Temperament", "writeMore8"),
        rhythmicity: this.addtionalChildHood("Temperament", "rhythmicity"),
        writeMore9: this.addtionalChildHood1("Temperament", "writeMore9"),
        extraNote: this.addtionalChildHood1("Temperament", "extraNote"),
        birth_early: this.addtionalChildHood("Personal_History", "birth_early"),
        specify1: this.addtionalChildHood1("Personal_History", "specify1"),
        disorder: this.addtionalChildHood("Personal_History", "disorder"),
        specify2: this.addtionalChildHood1("Personal_History", "specify2"),
        family: this.addtionalChildHood("Personal_History", "family"),
        specify3: this.addtionalChildHood1("Personal_History", "specify3"),
        extracurricular: this.addtionalChildHood(
          "Personal_History",
          "extracurricular"
        ),
        occupation: this.addtionalChildHood("Personal_History", "occupation"),
        specify4: this.addtionalChildHood1("Personal_History", "specify4"),
        menstrual: this.addtionalChildHood1("Personal_History", "menstrual"),
        LMP: this.addtionalChildHood1("Personal_History", "LMP"),
        menarche: this.addtionalChildHood1("Personal_History", "menarche"),
        menopause: this.addtionalChildHood1("Personal_History", "menopause"),
        sexual: this.addtionalChildHood("Personal_History", "sexual"),
        specify5: this.addtionalChildHood1("Personal_History", "specify5"),
        specify6: this.addtionalChildHood1("Personal_History", "specify6"),
        forensic: this.addtionalChildHood1("Personal_History", "forensic"),
        social_realtion: this.addtionalChildHood(
          "Personality",
          "social_realtion"
        ),
        writeMore11: this.addtionalChildHood1("Personality", "writeMore11"),
        writeMore10: this.addtionalChildHood1("Personality", "writeMore10"),
        hobbies: this.addtionalChildHood("Personality", "hobbies"),
        mood: this.addtionalChildHood("Personality", "mood"),
        writeMore12: this.addtionalChildHood1("Personality", "writeMore12"),
        character: this.addtionalChildHood("Personality", "character"),
        writeMore13: this.addtionalChildHood1("Personality", "writeMore13"),
        interpersonal: this.addtionalChildHood("Personality", "interpersonal"),
        writeMore14: this.addtionalChildHood1("Personality", "writeMore14"),
        morbid: this.addtionalChildHood1("Personality", "morbid"),
        matters: this.addtionalChildHood("Personality", "matters"),
        writeMore15: this.addtionalChildHood1("Personality", "writeMore15"),
        energy: this.addtionalChildHood("Personality", "energy"),
        writeMore16: this.addtionalChildHood1("Personality", "writeMore16"),
        writeMore17: this.addtionalChildHood1("Personality", "writeMore17"),
        writeMore18: this.addtionalChildHood1("Personality", "writeMore18"),
        fantasy: this.addtionalChildHood("Personality", "fantasy"),
        habits: this.addtionalChildHood("Personality", "habits"),
        data: {
          personalHistory: this.addtionalChildHood1(
            "Childhood_History",
            "personalHistory"
          ),
          pleaseSpecify: this.addtionalChildHood1(
            "Childhood_History",
            "pleaseSpecify"
          ),
          birthTerm: this.addtionalChildHood1("Childhood_History", "birthTerm"),
          birthPlace: this.addtionalChildHood1(
            "Childhood_History",
            "birthPlace"
          ),
          delivery: this.addtionalChildHood1("Childhood_History", "delivery"),
          birthSpecify: this.addtionalChildHood1(
            "Childhood_History",
            "birthSpecify"
          ),
          infection: this.addtionalChildHood1("Childhood_History", "infection"),
          convulsions: this.addtionalChildHood1(
            "Childhood_History",
            "convulsions"
          ),
          feedingIssues: this.addtionalChildHood1(
            "Childhood_History",
            "feedingIssues"
          ),
          failureThrive: this.addtionalChildHood1(
            "Childhood_History",
            "failureThrive"
          ),
          postnatalSpecify: this.addtionalChildHood1(
            "Childhood_History",
            "postnatalSpecify"
          ),
          developHistory: this.addtionalChildHood1(
            "Childhood_History",
            "developHistory"
          ),
          developSpecify: this.addtionalChildHood1(
            "Childhood_History",
            "developSpecify"
          ),
          immunization: this.addtionalChildHood1(
            "Childhood_History",
            "immunization"
          ),
          immuneSpecify: this.addtionalChildHood1(
            "Childhood_History",
            "immuneSpecify"
          ),
          strength: this.addtionalChildHood1("Childhood_History", "strength"),
          weakness: this.addtionalChildHood1("Childhood_History", "weakness"),
          findings: this.addtionalChildHood1("Childhood_History", "findings"),
          typeSpecify: this.addtionalChildHood1(
            "Substance_History",
            "typeSpecify"
          ),
          withdrawalSpecify: this.addtionalChildHood1(
            "Substance_History",
            "withdrawalSpecify"
          ),

          substanceOther: this.addtionalChildHood1(
            "Substance_History",
            "substanceOther"
          ),
          observationSpecify: this.addtionalChildHood1(
            "Substance_History",
            "observationSpecify"
          ),
          locus: this.addtionalChildHood1("Substance_History", "locus"),
          motivation: this.addtionalChildHood1(
            "Substance_History",
            "motivation"
          ),
          general_appearance: this.addtionalChildHood1(
            "Behavioural_Observation",
            "general_appearance"
          ),
          capacity: this.addtionalChildHood1(
            "Behavioural_Observation",
            "capacity"
          ),
          motorBehaviour: this.addtionalChildHood1(
            "Behavioural_Observation",
            "motorBehaviour"
          ),
          speech: this.addtionalChildHood1("Behavioural_Observation", "speech"),
          affective_behaviour: this.addtionalChildHood1(
            "Behavioural_Observation",
            "affective_behaviour"
          ),
          attention_span: this.addtionalChildHood1(
            "Behavioural_Observation",
            "attention_span"
          ),
          play_behaviour: this.addtionalChildHood1(
            "Behavioural_Observation",
            "play_behaviour"
          ),
          intellectual_insight: this.addtionalChildHood1(
            "Behavioural_Observation",
            "intellectual_insight"
          ),
          motivational_insight: this.addtionalChildHood1(
            "Behavioural_Observation",
            "motivational_insight"
          ),
          sexual_issue: this.addtionalChildHood1(
            "Sexual_History",
            "sexual_issue"
          ),
          masturbation_history: this.addtionalChildHood1(
            "Sexual_History",
            "masturbation_history"
          ),

          interpersonal_sexual: this.addtionalChildHood1(
            "Sexual_History",
            "interpersonal_sexual"
          ),
          current_sexual: this.addtionalChildHood1(
            "Sexual_History",
            "current_sexual"
          ),
          alibido_interests: this.addtionalChildHood1(
            "Sexual_History",
            "alibido_interests"
          ),
          arousal_performance: this.addtionalChildHood1(
            "Sexual_History",
            "arousal_performance"
          ),
          orgasm_satisfaction: this.addtionalChildHood1(
            "Sexual_History",
            "orgasm_satisfaction"
          ),
          pain_vaginismus: this.addtionalChildHood1(
            "Sexual_History",
            "pain_vaginismus"
          ),
          ejaculation: this.addtionalChildHood1(
            "Sexual_History",
            "ejaculation"
          ),
          partners: this.addtionalChildHood1("Sexual_History", "partners"),
          orientation: this.addtionalChildHood1(
            "Sexual_History",
            "orientation"
          ),
          psychosexual_disorder: this.addtionalChildHood1(
            "Sexual_History",
            "psychosexual_disorder"
          ),
          year: this.addtionalChildHood1("MMSE", "year"),
          month: this.addtionalChildHood1("MMSE", "month"),
          date: this.addtionalChildHood1("MMSE", "date"),
          day: this.addtionalChildHood1("MMSE", "day"),
          season: this.addtionalChildHood1("MMSE", "season"),
          country: this.addtionalChildHood1("MMSE", "country"),
          state: this.addtionalChildHood1("MMSE", "state"),
          city: this.addtionalChildHood1("MMSE", "city"),
          street: this.addtionalChildHood1("MMSE", "street"),
          floor: this.addtionalChildHood1("MMSE", "floor"),
          room: this.addtionalChildHood1("MMSE", "room"),
          namingObjects: this.addtionalChildHood1("MMSE", "namingObjects"),
          backwards: this.addtionalChildHood1("MMSE", "backwards"),
          recall: this.addtionalChildHood1("MMSE", "recall"),
          showSimple: this.addtionalChildHood1("MMSE", "showSimple"),
          phrase: this.addtionalChildHood1("MMSE", "phrase"),
          page: this.addtionalChildHood1("MMSE", "page"),
          figure: this.addtionalChildHood1("MMSE", "figure"),
          nonDominant: this.addtionalChildHood1("MMSE", "nonDominant"),
          totalScore: this.addtionalChildHood1("MMSE", "totalScore"),
          interpretation: this.addtionalChildHood1("MMSE", "interpretation"),
        },
      });
    }
  };

  render() {
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="additional-sections"
          role="tabpanel"
          aria-labelledby="additional-sections-tab"
        >
          <div class="card-header">Additional Sections</div>
          <div class="content-body add-scrollbar scrollbar-dynamic"> */}
        <form onSubmit={this.handleSubmit} id="myform">
          <div class="crf-content-box">
            <h4>
              Select Sections <small>(You can select maximum 3 forms)</small>
            </h4>
            <div class="checkbox-group-row">
              <ul class="checkbox-group-list four-column d-flex flex-wrap">
                {past.map((e, i) => (
                  <li class="checkbox-group-item">
                    <div class="form-check form-check-inline">
                      <input
                        type="checkbox"
                        checked={
                          this.state.sections.find((el) => el === e.Past)
                            ? true
                            : false
                        }
                        id={i}
                        class="form-check-input"
                        name="selectOption_15"
                        value={e.Past}
                        onChange={(e) => this.changeLang(e.target.value)}
                      />
                      <label class="form-check-label ml-4" htmlFor={i}>
                        {e.Past}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div class="pane-seprator"></div>
            {this.state.sections.find((v) => v === "Childhood History") && (
              <>
                <h4>Childhood History</h4>
                <div class="radio-group-box">
                  <h5>Prenatal History</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {prenatal.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Prenatal}
                          name="personal"
                          value={e.Prenatal}
                          onChange={(e) => this.togglePersonal(e)}
                          checked={
                            this.state.data.personalHistory === e.Prenatal
                          }
                        />
                        <label class="form-check-label" htmlFor={e.Prenatal}>
                          {e.Prenatal}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="textarea-row">
                  <h5>Please Specify</h5>
                  {this.renderTextarea("pleaseSpecify", "")}
                </div>
                <div class="radio-group-box">
                  <h5>Natal and Neonatal – Birth to 28 days</h5>
                  <div class="radio-group-box">
                    <h5>Birth Term</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {birthTerm.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.BirthTerm}
                            name="birthTerm"
                            value={e.BirthTerm}
                            onChange={(e) => this.toggleBirthTerm(e)}
                            checked={this.state.data.birthTerm === e.BirthTerm}
                          />
                          <label class="form-check-label" htmlFor={e.BirthTerm}>
                            {e.BirthTerm}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="radio-group-box">
                    <h5>Birth Place</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {birthPlace.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.BirthPlace}
                            name="birthPlace"
                            value={e.BirthPlace}
                            onChange={(e) => this.toggleBirthPlace(e)}
                            checked={
                              this.state.data.birthPlace === e.BirthPlace
                            }
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.BirthPlace}
                          >
                            {e.BirthPlace}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="radio-group-box">
                    <h5>Delivery Type</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {deliveryType.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.DeliveryType}
                            name="delivery"
                            value={e.DeliveryType}
                            onChange={(e) => this.toggleDelivery(e)}
                            checked={
                              this.state.data.delivery === e.DeliveryType
                            }
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.DeliveryType}
                          >
                            {e.DeliveryType}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="textarea-row">
                    <h5>Please Specify Anything Else</h5>
                    {this.renderTextarea("birthSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Postnatal – upto 6 weeks</h5>
                  <div class="radio-group-box">
                    <h5>Infections</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {postnatal.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.Postnatal + "1"}
                            name="infection"
                            value={e.Postnatal}
                            onChange={(e) => this.toggleInfection(e)}
                            checked={this.state.data.infection === e.Postnatal}
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.Postnatal + "1"}
                          >
                            {e.Postnatal}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="radio-group-box">
                    <h5>Convulsions</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {postnatal.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.Postnatal + "2"}
                            name="Convulsions"
                            value={e.Postnatal}
                            onChange={(e) => this.toggleConvulsions(e)}
                            checked={
                              this.state.data.convulsions === e.Postnatal
                            }
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.Postnatal + "2"}
                          >
                            {e.Postnatal}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="radio-group-box">
                    <h5>Feeding Issues</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {postnatal.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.Postnatal + "3"}
                            name="Feeding Issues"
                            value={e.Postnatal}
                            onChange={(e) => this.toggleFeeding(e)}
                            checked={
                              this.state.data.feedingIssues === e.Postnatal
                            }
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.Postnatal + "3"}
                          >
                            {e.Postnatal}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="radio-group-box">
                    <h5>Failure To Thrive</h5>
                    <div class="radio-group-row d-flex flex-wrap align-items-center">
                      {postnatal.map((e) => (
                        <div class="form-check">
                          <input
                            type="radio"
                            class="form-check-input"
                            id={e.Postnatal + "4"}
                            name="Failure To Thrive"
                            value={e.Postnatal}
                            onChange={(e) => this.toggleThrive(e)}
                            checked={
                              this.state.data.failureThrive === e.Postnatal
                            }
                          />
                          <label
                            class="form-check-label"
                            htmlFor={e.Postnatal + "4"}
                          >
                            {e.Postnatal}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div class="textarea-row">
                    <h5>Please Specify Anything Else</h5>
                    {this.renderTextarea("postnatalSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Developmental History</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {developmentalHistory.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Development}
                          name="developHistory"
                          value={e.Development}
                          onChange={(e) => this.toggleDelevopHistory(e)}
                          checked={
                            this.state.data.developHistory === e.Development
                          }
                        />
                        <label class="form-check-label" htmlFor={e.Development}>
                          {e.Development}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div class="textarea-row">
                    <h5>Please Specify Delayed Milestones</h5>
                    {this.renderTextarea("developSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Immunization</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {immunization.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Immunization + "1"}
                          name="immunization"
                          value={e.Immunization}
                          onChange={(e) => this.toggleImmunization(e)}
                          checked={
                            this.state.data.immunization === e.Immunization
                          }
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Immunization + "1"}
                        >
                          {e.Immunization}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div class="textarea-row">
                    <h5>Please Specify Delayed Ones</h5>
                    {this.renderTextarea("immuneSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Home Environment</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {home.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.homeEnvironment.find(
                              (el) => el === e.Home
                            )}
                            id={e.Home + 1}
                            class="form-check-input"
                            name={e.Home + 1}
                            onChange={() => this.changeHome(e.Home)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Home + 1}
                          >
                            {e.Home}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Parenting</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {parenting.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.parenting.find(
                              (el) => el === e.Parenting
                            )}
                            id={e.Parenting + 2}
                            class="form-check-input"
                            name={e.Parenting + 2}
                            onChange={() => this.changeParenting(e.Parenting)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Parenting + 2}
                          >
                            {e.Parenting}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Scholastic History</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {scholastic.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.scholastic.find(
                              (el) => el === e.Scholastic
                            )}
                            id={e.Scholastic + 3}
                            class="form-check-input"
                            name={e.Scholastic + 3}
                            onChange={() => this.changeScholastic(e.Scholastic)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Scholastic + 3}
                          >
                            {e.Scholastic}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Social History</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {social.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.social.find(
                              (el) => el === e.Social
                            )}
                            id={e.Scholastic + 3}
                            class="form-check-input"
                            name={e.Scholastic + 3}
                            onChange={() => this.changeSocial(e.Social)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Scholastic + 3}
                          >
                            {e.Social}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Play History</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {play.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.play.find(
                              (el) => el === e.Play
                            )}
                            id={e.Play + "4"}
                            class="form-check-input"
                            name="selectOption_5"
                            onChange={() => this.changePlay(e.Play)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Play + "4"}
                          >
                            {e.Play}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Strength</h5>
                    {this.renderTextarea("strength", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Weakness</h5>
                    {this.renderTextarea("weakness", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Other Details/ Findings</h5>
                    {this.renderTextarea("findings", "")}
                  </div>
                </div>
                <div class="pane-seprator"></div>
              </>
            )}
            {this.state.sections.find((v) => v === "Substance History") && (
              <>
                <h4>Substance History</h4>
                <div class="radio-group-box">
                  <h5>Type Of Substance</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {type.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.type.find(
                              (el) => el === e.Type
                            )}
                            id={e.Type + "7"}
                            class="form-check-input"
                            name="selectOption_6"
                            onChange={() => this.changeType(e.Type)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Type + "7"}
                          >
                            {e.Type}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Please Specify Quantity/ Day</h5>
                    {this.renderTextarea("typeSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Tolerance</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {tolerance.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.tolerance.find(
                              (el) => el === e.Tolerance
                            )}
                            id={e.Tolerance + "6"}
                            class="form-check-input"
                            name="selectOption_7"
                            onChange={() => this.changeTolerance(e.Tolerance)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Tolerance + "6"}
                          >
                            {e.Tolerance}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Dependence</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {dependence.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.dependence.find(
                              (el) => el === e.Dependence
                            )}
                            id={e.Dependence + "5"}
                            class="form-check-input"
                            name="selectOption_8"
                            onChange={() => this.changeDependence(e.Dependence)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Dependence + "5"}
                          >
                            {e.Dependence}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Withdrawal</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {withdrawal.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.withdrawal.find(
                              (el) => el === e.Withdrawal
                            )}
                            id={e.Withdrawal + "4"}
                            class="form-check-input"
                            name="selectOption_9"
                            onChange={() => this.changeWithdrawal(e.Withdrawal)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Withdrawal + "4"}
                          >
                            {e.Withdrawal}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Please Specify If Other Symptoms</h5>
                    {this.renderTextarea("withdrawalSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Cravings</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {cravings.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.cravings.find(
                              (el) => el === e.Cravings
                            )}
                            id={e.Cravings + "3"}
                            class="form-check-input"
                            name="selectOption_10"
                            onChange={() => this.changeCravings(e.Cravings)}
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Cravings + "3"}
                          >
                            {e.Cravings}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <h5>Other Observations</h5>
                  <ul class="checkbox-group-list four-column d-flex flex-wrap">
                    {observations.map((e) => (
                      <li class="checkbox-group-item">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            checked={this.state.observations.find(
                              (el) => el === e.Observations
                            )}
                            id={e.Observations}
                            class="form-check-input"
                            name="selectOption_11"
                            onChange={() =>
                              this.changeObservation(e.Observations)
                            }
                          />
                          <label
                            class="form-check-label ml-4"
                            htmlFor={e.Observations}
                          >
                            {e.Observations}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Please specify if any other</h5>
                    {this.renderTextarea("observationSpecify", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Locus Of Control</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {control.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Control + "1"}
                          name="locus"
                          value={e.Control}
                          onChange={(e) => this.toggleLocus(e)}
                          checked={this.state.data.locus === e.Control}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Control + "1"}
                        >
                          {e.Control}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Motivation</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {motivation.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Motivation + "2"}
                          name="motivation"
                          value={e.Motivation}
                          onChange={(e) => this.toggleMotivation(e)}
                          checked={this.state.data.motivation === e.Motivation}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Motivation + "2"}
                        >
                          {e.Motivation}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Other Important Points </h5>
                    {this.renderTextarea("substanceOther", "")}
                  </div>
                </div>
                <div class="pane-seprator"></div>
              </>
            )}
            {this.state.sections.find(
              (v) => v === "Behavioural Observation"
            ) && (
              <>
                <h4>Behavioural Observation</h4>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>General Appearance</h5>
                    {this.renderTextarea("general_appearance", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Relationship capacity & Sociability</h5>
                    {this.renderTextarea("capacity", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Motor Behaviour</h5>
                    {this.renderTextarea("motorBehaviour", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Speech</h5>
                    {this.renderTextarea("speech", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Affective Behaviour</h5>
                    {this.renderTextarea("affective_behaviour", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Distractability, Attention Span</h5>
                    {this.renderTextarea("attention_span", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Play Behaviour</h5>
                    {this.renderTextarea("play_behaviour", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Intellectual Insight</h5>
                    {this.renderTextarea("intellectual_insight", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Motivational Insight</h5>
                    {this.renderTextarea("motivational_insight", "")}
                  </div>
                </div>
                <div class="pane-seprator"></div>
              </>
            )}
            {this.state.sections.find((v) => v === "Sexual History") && (
              <>
                <h4>Sexual History</h4>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Sexual Issues</h5>
                    {this.renderTextarea("sexual_issue", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Masturbation History</h5>
                    {this.renderTextarea("masturbation_history", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Interpersonal Sexual Activity</h5>
                    {this.renderTextarea("interpersonal_sexual", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Current Sexual Functioning</h5>
                    {this.renderTextarea("current_sexual", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>ALibido/ Interests</h5>
                    {this.renderTextarea("alibido_interests", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Arousal/ Performance</h5>
                    {this.renderTextarea("arousal_performance", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Orgasm/ Satisfaction</h5>
                    {this.renderTextarea("orgasm_satisfaction", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Pain/ Vaginismus</h5>
                    {this.renderTextarea("pain_vaginismus", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Ejaculation</h5>
                    {this.renderTextarea("ejaculation", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Partners</h5>
                    {this.renderTextarea("partners", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Orientation</h5>
                    {this.renderTextarea("orientation", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Psychosexual Disorder Diagnosis</h5>
                    {this.renderTextarea("psychosexual_disorder", "")}
                  </div>
                </div>
                <div class="pane-seprator"></div>
              </>
            )}
            {this.state.sections.find((v) => v === "MMSE") && (
              <>
                <h4>MMSE</h4>
                <div class="radio-group-box">
                  <h5>What year is this?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "1"}
                          name="year"
                          value={e.Score}
                          onChange={(e) => this.toggleYear(e)}
                          checked={this.state.data.year === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "1"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What month is this?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "2"}
                          name="month"
                          value={e.Score}
                          onChange={(e) => this.toggleMonth(e)}
                          checked={this.state.data.month === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "2"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What is today’s date?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "3"}
                          name="date"
                          value={e.Score}
                          onChange={(e) => this.toggleDate(e)}
                          checked={this.state.data.date === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "3"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What day of the week is this?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "4"}
                          name="day"
                          value={e.Score}
                          onChange={(e) => this.toggleDay(e)}
                          checked={this.state.data.day === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "4"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What season is this?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "5"}
                          name="season"
                          value={e.Score}
                          onChange={(e) => this.toggleSeason(e)}
                          checked={this.state.data.season === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "5"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What country are we in?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "6"}
                          name="country"
                          value={e.Score}
                          onChange={(e) => this.toggleCountry(e)}
                          checked={this.state.data.country === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "6"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What state are we in?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "7"}
                          name="state"
                          value={e.Score}
                          onChange={(e) => this.toggleState(e)}
                          checked={this.state.data.state === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "7"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What city/town are we in?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "8"}
                          name="city"
                          value={e.Score}
                          onChange={(e) => this.toggleCity(e)}
                          checked={this.state.data.city === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "8"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What is the street address of the house?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "9"}
                          name="street"
                          value={e.Score}
                          onChange={(e) => this.toggleStreet(e)}
                          checked={this.state.data.street === e.Score}
                        />
                        <label class="form-check-label" htmlFor={e.Score + "9"}>
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What room are we in?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "10"}
                          name="room"
                          value={e.Score}
                          onChange={(e) => this.toggleRoom(e)}
                          checked={this.state.data.room === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "10"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>What floor of the building are we on?</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "11"}
                          name="floor"
                          value={e.Score}
                          onChange={(e) => this.toggleFloor(e)}
                          checked={this.state.data.floor === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "11"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Naming three objects and registration</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score1.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "12"}
                          name="namingObjects"
                          value={e.Score}
                          onChange={(e) => this.toggleNaming(e)}
                          checked={this.state.data.namingObjects === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "12"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Spell the word WORLD and Backwards</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score2.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "13"}
                          name="backwards"
                          value={e.Score}
                          onChange={(e) => this.toggleBackwards(e)}
                          checked={this.state.data.backwards === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "13"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Recall three objects</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score1.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "14"}
                          name="reCall"
                          value={e.Score}
                          onChange={(e) => this.toggleRecall(e)}
                          checked={this.state.data.recall === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "14"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Show simple objects and ask the name</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score3.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "15"}
                          name="showSimple"
                          value={e.Score}
                          onChange={(e) => this.toggleshowSimple(e)}
                          checked={this.state.data.showSimple === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "15"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>
                    I would like you to repeat a phrase after me: No ifs, ands,
                    or buts
                  </h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "16"}
                          name="phrase"
                          value={e.Score}
                          onChange={(e) => this.togglePhrase(e)}
                          checked={this.state.data.phrase === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "16"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Read the words on this page and then do what it says</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "17"}
                          name="page"
                          value={e.Score}
                          onChange={(e) => this.togglePage(e)}
                          checked={this.state.data.page === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "17"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Draw the figure test.</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "18"}
                          name="figure"
                          value={e.Score}
                          onChange={(e) => this.toggleFigure(e)}
                          checked={this.state.data.figure === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "18"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>
                    Take this paper in your right/left hand (whichever is
                    non-dominant), fold the paper in half once with both hands
                    and put the paper down on the floor.{" "}
                  </h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {score4.map((e) => (
                      <div class="form-check">
                        <input
                          type="radio"
                          class="form-check-input"
                          id={e.Score + "19"}
                          name="nonDominant"
                          value={e.Score}
                          onChange={(e) => this.togglenonDominant(e)}
                          checked={this.state.data.nonDominant === e.Score}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Score + "19"}
                        >
                          {e.Score}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Total Score</h5>
                    {this.renderTextarea("totalScore", "")}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Interpretation</h5>
                    {this.renderTextarea("interpretation", "")}
                  </div>
                </div>
                <div class="pane-seprator"></div>
              </>
            )}
            {this.state.sections.find((v) => v === "Physical Examination") && (
              <PhysicalExamination
                handleInputCVS={this.handleInputCVS}
                handlephysicalGeneral={this.handlephysicalGeneral}
                pulse={this.state.pulse}
                height={this.state.height}
                weight={this.state.weight}
                bp={this.state.bp}
                rr={this.state.rr}
                bmi={this.state.bmi}
                bp2={this.state.bp2}
                temp={this.state.temp}
                physicalOther={this.state.physicalOther}
                physicalGeneral={this.state.physicalGeneral}
                physicalFinding={this.state.physicalFinding}
                cvs={this.state.cvs}
                abnormalFindings1={this.state.abnormalFindings1}
                cns={this.state.cns}
                respiratory={this.state.respiratory}
                abnormalFindings2={this.state.abnormalFindings2}
                handleInputChange={this.handleInputChange}
                changeLang2={this.changeLang2}
                higherMental={this.state.higherMental}
                handleInputChange2={this.handleInputChange2}
                abnormalFindings3={this.state.abnormalFindings3}
                git={this.state.git}
                abnormalFindings4={this.state.abnormalFindings4}
                abnormalFindings5={this.state.abnormalFindings5}
                handleInputChangeResp={this.handleInputChangeResp}
                handleInputChangeCns={this.handleInputChangeCns}
                handleInputChangeMental={this.handleInputChangeMental}
                handleInputChangeTemp={this.handleInputChangeTemp}
                handleTempChange={this.handleTempChange}
                handleCNSChange={this.changeCNS}
                handleRespChange={this.changeResp}
                handleGitChange={this.changeGit}
                handleHigherMental = {this.changeHigherMental}
              />
            )}
            {this.state.sections.find((v) => v === "Temperament") && (
              <Temperament
                activity={this.state.activity}
                attention={this.state.attention}
                changeLang3={this.changeLang3}
                writeMore1={this.state.writeMore1}
                changeLang4={this.changeLang4}
                writeMore2={this.state.writeMore2}
                changeLang5={this.changeLang5}
                approach={this.state.approach}
                writeMore3={this.state.writeMore3}
                handleInputChange={this.handleInputChange}
                adaptability={this.state.adaptability}
                writeMore4={this.state.writeMore4}
                changeLang6={this.changeLang6}
                distractability={this.state.distractability}
                writeMore5={this.state.writeMore5}
                changeLang7={this.changeLang7}
                writeMore6={this.state.writeMore6}
                intensity={this.state.intensity}
                changeLang8={this.changeLang8}
                threshold={this.state.threshold}
                changeLang9={this.changeLang9}
                moodQuality={this.state.moodQuality}
                writeMore8={this.state.writeMore8}
                changeLang10={this.changeLang10}
                rhythmicity={this.state.rhythmicity}
                writeMore9={this.state.writeMore9}
                changeLang11={this.changeLang11}
                extraNote={this.state.extraNote}
                writeMore7={this.state.writeMore7}
              />
            )}
            {this.state.sections.find((v) => v === "Personal History") && (
              <PersonalHistory
                birth_early={this.state.birth_early}
                specify1={this.state.specify1}
                changeLang12={this.changeLang12}
                disorder={this.state.disorder}
                changeLang13={this.changeLang13}
                specify2={this.state.specify2}
                family={this.state.family}
                specify3={this.state.specify3}
                changeLang14={this.changeLang14}
                changeLang15={this.changeLang15}
                handleInputChange={this.handleInputChange}
                extracurricular={this.state.extracurricular}
                occupation={this.state.occupation}
                changeLang16={this.changeLang16}
                specify4={this.specify4}
                menstrual={this.state.menstrual}
                changeLang17={this.changeLang17}
                LMP={this.state.LMP}
                menarche={this.state.menarche}
                menopause={this.state.menopause}
                changeLang18={this.changeLang18}
                sexual={this.state.sexual}
                specify5={this.state.specify5}
                specify6={this.state.specify6}
                forensic={this.state.forensic}
                toggleForensic={this.toggleForensic}
              />
            )}
            {this.state.sections.find((v) => v === "Personality") && (
              <Personality
                social_realtion={this.state.social_realtion}
                writeMore10={this.state.writeMore10}
                changeLang19={this.changeLang19}
                handleInputChange={this.handleInputChange}
                writeMore11={this.state.writeMore11}
                hobbies={this.state.hobbies}
                changeLang20={this.changeLang20}
                changeLang21={this.changeLang21}
                mood={this.state.mood}
                writeMore12={this.state.writeMore12}
                character={this.state.character}
                writeMore13={this.state.writeMore13}
                changeLang22={this.changeLang22}
                interpersonal={this.state.interpersonal}
                writeMore14={this.state.writeMore14}
                changeLang23={this.changeLang23}
                morbid={this.state.morbid}
                toggleMorbid={this.toggleMorbid}
                matters={this.state.matters}
                writeMore15={this.state.writeMore15}
                changeLang24={this.changeLang24}
                writeMore16={this.state.writeMore16}
                writeMore17={this.state.writeMore17}
                writeMore18={this.state.writeMore18}
                energy={this.state.energy}
                changeLang25={this.changeLang25}
                fantasy={this.state.fantasy}
                changeLang26={this.changeLang26}
                changeLang27={this.changeLang27}
                habits={this.state.habits}
              />
            )}
          </div>
        </form>
        {/* </div>
        </div> */}
        <div class="patients-details-footer d-md-flex align-items-md-center justify-content-md-between">
          <Link to="/doctor/dashboard">
            <button class="btn btn-grey">EXIT</button>
          </Link>
          {/* <div class="pdf-btn d-flex align-items-center">
            <a href="javascript:void(0)">
              <img src={download} />
            </a>
          </div> */}
          <button type="submit" class="btn btn-primary btn-lg" form="myform">
            SAVE
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  demographicsAdd: getCaserecord(state).demographicsAdd,
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addDemographics: (params, id, callBack) =>
    dispatch(addDemographics(params, id, callBack)),
  loadCaseRecord: (id) => dispatch(loadCaseRecord(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Additional)
);
