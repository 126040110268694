import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class SettingPopup extends Component {
  render() {
    return (
      <>
        <Modal
          aria-labelledby="exampleModalCenterTitle"
          centered
          dialogClassName="modal-dialog modal-dialog-centered"
          className="modal fade emergency-contact-modal show"
          show={this.props.isShow}
        >
          <div class="ecm-box">
            <h4>Emergency Contacts Information</h4>
            <p>Use the following information in case of emergency</p>
            <div class="emc-row d-flex flex-wrap">
              <div class="emc-column">
                <p>Patient Mobile:</p>
                <a href="javascript:void(0)">
                  {this.props.detail && this.props.detail.mobile}
                </a>
              </div>
              <div class="emc-column">
                <p>Patient Email</p>
                <a href="javascript:void(0)">
                  {this.props.detail && this.props.detail.email}
                </a>
              </div>
              <div class="emc-column">
                <p>Patient Address</p>
                <address>
                  {this.props.detail && this.props.detail.state}, IN
                </address>
              </div>

              <div class="emc-column">
                <p>Representative Name</p>
                <em>
                  {this.props.detail &&
                    this.props.detail.nominated_representative_name}
                </em>
              </div>

              <div class="emc-column">
                <p>Representative Number</p>
                <em>
                  {this.props.detail && this.props.detail.representative_number}
                </em>
              </div>

              <div class="emc-column">
                <p>Emergency Number</p>
                <em>
                  {this.props.detail &&
                    this.props.detail.emergency_contact_number}
                </em>
              </div>

              <div class="emc-column">
                <p>Verification Id</p>
                {this.props.detail && (
                  <div class="file-upload add-file-upload">
                    <div class="drag-text">
                      <img
                        src={
                          process.env.REACT_APP_S3URL +
                          this.props.detail.photo_id
                        }
                        alt=""
                      />
                    </div>
                  </div>
                )}
                <em>
                  {this.props.detail && this.props.detail.representative_number}
                </em>
              </div>
            </div>

            <div class="ecm-btn text-center">
              <button
                class="btn btn-outline no-shadow "
                onClick={(e) => {
                  e.preventDefault();
                  this.props.toggle();
                }}
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
