import React, { Component } from "react";
import close from "../../../../include/images/icon-close.svg";
import logo from "../../../../include/images/yes-mindy-logo.svg";
import {
  getPrescription,
  loadForAppointment,
} from "../../../../store/prescription";
import { connect } from "react-redux";
import moment from "moment";
import NoRecord from "../../../common/noRecord";
import downloadIcon from "./../../../../include/images/file-download.svg";
import { getPatient } from "../../../../store/patient";

class Prescription extends Component {
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.appointment_id &&
      this.props.appointment_id !== prevProps.appointment_id
    ) {
      this.props.loadForAppointment(this.props.appointment_id);
    }
  };
  formatAMPM(date) {
    var hours = new moment(date, "HH").utc().hour();
    var minutes = new moment(date, "mm").utc().minutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  render() {
    return (
      <>
        <>
          <div
            className={
              this.props.isShow === true
                ? "sidebar-box notesopen"
                : "sidebar-box notesclose"
            }
            id="notes"
          >
            <div class="sidebar-head d-flex align-items-center">
              <h5 class="d-none d-md-block">Prescription</h5>
              <div class="sidebar-head-right ml-auto d-flex align-items-center">
                {this.props.forAppointment && (
                  <div>
                    <a
                      href={"/prescription/" + this.props.appointment_id}
                      target="_blank"
                      class="icon"
                    >
                      <img src={downloadIcon} alt="" />
                    </a>
                  </div>
                )}
                <div
                  class="close-img"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.toggle();
                  }}
                >
                  <img src={close} alt="" />
                </div>
              </div>
            </div>
            {this.props.forAppointment === null ? (
              <div className="m-2">
                <NoRecord
                  noLogo={true}
                  header={
                    this.props.profile._id
                      ? "Prescription Will Soon Be Available For You"
                      : "You Have Not Issued The Prescription"
                  }
                  text={
                    this.props.profile._id
                      ? "Please adhere with the expert’s advice for better outcomes. You can always reach out to them in case of any confusion/ queries."
                      : "You may fill out and send the prescription from your home section under incomplete prescriptions."
                  }
                />
              </div>
            ) : (
              <div class="sidebar-body height-auto">
                <div class="latter-head">
                  <div class="latter-logo-box">
                  {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .photos &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .photos.length > 0 && (
                            <img src={process.env.REACT_APP_S3URL+this.props.forAppointment.doctor_id.clinic_details
                              .photos[0]} alt="" width={40} class="text-left"/>
                          )}
                    <img src={logo} alt="" style={{float:"right"}}/>
                  </div>
                  <div class="latter-text-box d-flex flex-wrap justify-content-between">
                    <div class="lt-box">
                      {this.props.forAppointment &&
                        this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.name}
                      ,<br />
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.graduation &&
                        this.props.forAppointment.doctor_id.graduation
                          .education}
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.post_graduation &&
                        ",  " +
                          this.props.forAppointment.doctor_id.post_graduation
                            .education}
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.super_speciality &&
                        ",  " +
                          this.props.forAppointment.doctor_id.super_speciality
                            .education}
                      <br />
                      {this.props.forAppointment &&
                        this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.field_type
                          .name}{" "}
                      <br />
                      {this.props.forAppointment &&
                        this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.documents_url &&
                        this.props.forAppointment.doctor_id.documents_url
                          .registration_license &&
                        "Reg No :- " +
                          this.props.forAppointment.doctor_id.documents_url
                            .registration_license.licence_no}
                    </div>
                    <div class="lt-box text-right">
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details &&
                        this.props.forAppointment.doctor_id.clinic_details &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .name &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .name + ", "}{" "}
                      <br />
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .address &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .address + ", "}
                      <br />
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .phone &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .phone + ", "}
                      <br />
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .city &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .city + ", "}
                      <br />
                      {this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .state &&
                        this.props.forAppointment.doctor_id.clinic_details
                          .state + ", "}
                      <br />
                      <a href="javascript:void(0)">
                        {this.props.forAppointment.doctor_id &&
                          this.props.forAppointment.doctor_id.clinic_details
                            .zipcode}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="sep"></div>
                <div class="latter-body">
                  <div class="latter-text-one d-md-flex flex-wrap">
                    <div class="ltb-left">
                      <h4>
                        {this.props.forAppointment.patient_id &&
                          this.props.forAppointment.patient_id.first_name +
                            "  " +
                            this.props.forAppointment.patient_id.last_name}
                      </h4>
                      <p>
                        Age:{" "}
                        {moment().diff(
                          this.props.forAppointment.patient_id &&
                            this.props.forAppointment.patient_id.date_of_birth,
                          "years"
                        )}{" "}
                        yrs
                      </p>
                      <p>
                        Gender:{" "}
                        {this.props.forAppointment.patient_id &&
                        this.props.forAppointment.patient_id.sex === "M"
                          ? "Male"
                          : "Female"}
                      </p>
                    </div>
                    <div class="ltb-right">
                      <h4>
                        UID:
                        {this.props.forAppointment.patient_id &&
                          this.props.forAppointment.patient_id
                            .yesmindy_identification_number}
                      </h4>
                      <p>
                        Date:{" "}
                        {moment(
                          this.props.forAppointment.appointment_id &&
                            this.props.forAppointment.appointment_id
                              .appointment_time
                        )
                          .utc()
                          .format("lll")}
                      </p>
                    </div>
                  </div>
                  <div class="latter-text d-md-flex flex-wrap">
                    <div class="lt-left">
                      <p>Duration of session</p>
                      <b>{this.props.forAppointment.duration_of_session}</b>
                    </div>
                    <div class="lt-right">
                      <p>Session participants</p>
                      <b>
                        {this.props.forAppointment.session_participants &&
                          this.props.forAppointment.session_participants.join(
                            ", "
                          )}
                      </b>
                    </div>
                  </div>

                  <div class="latter-text d-md-flex flex-wrap">
                    <div class="lt-right">
                      <h3>Diagnosis</h3>
                      <b>
                        {this.props.forAppointment.diagnosis &&
                          this.props.forAppointment.diagnosis.replace(
                            ", ",
                            ", "
                          )}
                      </b>
                    </div>
                  </div>

                  {this.props.forAppointment.doctor_id &&
                    this.props.forAppointment.doctor_id.field_type &&
                    this.props.forAppointment.doctor_id.field_type.name ===
                      "Psychologist" && (
                      <div class="latter-text d-md-flex flex-wrap">
                        <div class="lt-left">
                          <p>Therapy methods</p>
                          <b> {this.props.forAppointment.theray_method}</b>
                        </div>
                        <div class="lt-right">
                          <p>Techniques</p>
                          <b>
                            {this.props.forAppointment.therapy_technique
                              .map((et) => et.label)
                              .join(", ")}
                          </b>
                        </div>
                      </div>
                    )}
                  <div class="latter-text d-md-flex flex-wrap">
                    <div class="lt-left">
                      <p>Chief Complaints</p>
                      <h6>
                        {this.props.forAppointment.medical_prescription &&
                          this.props.forAppointment.chief_complaints}
                      </h6>
                    </div>
                    {this.props.forAppointment.doctor_id &&
                      this.props.forAppointment.doctor_id.field_type &&
                      this.props.forAppointment.doctor_id.field_type.name ===
                        "Psychologist" && (
                        <div class="lt-right">
                          <p>Key issue/Themes</p>
                          <h6>
                            {this.props.forAppointment.medical_prescription &&
                              this.props.forAppointment.key_issue}
                          </h6>
                        </div>
                      )}
                  </div>

                  {this.props.forAppointment.doctor_id &&
                  this.props.forAppointment.doctor_id.field_type &&
                  this.props.forAppointment.doctor_id.field_type.name ===
                    "Psychologist" ? (
                    <></>
                  ) : (
                    <div class="sidebar-bg-box" style={{overflow:"auto"}}>
                      <div class="box-h-line">
                        <span>Rx</span>
                      </div>{" "}
                      <table class="table small-table table-responsive">
                        <thead>
                          <tr>
                            <th>Formulation</th>
                            <th width="10%">Composition</th>
                            <th>Brand</th>
                            <th>Dose</th>
                            <th>Frequency</th>
                            <th>Days</th>
                            <th width="20%">Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.forAppointment.medical_prescription &&
                            this.props.forAppointment.medical_prescription.map(
                              (e3, i) => (
                                <tr>
                                  <td>{e3.formulation}</td>
                                  <td>{e3.composition}</td>
                                  <td>{e3.brand}</td>
                                  <td>{e3.dose}</td>
                                  <td>
                                    {e3.frequency &&
                                      e3.frequency.map((em) => (
                                        <>
                                          {em.label?em.label:em.value}
                                          <br />
                                        </>
                                      ))}
                                  </td>
                                  <td>{e3.days}</td>
                                  <td>{e3.notes}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {this.props.forAppointment &&
                  this.props.forAppointment.doctor_id &&
                  this.props.forAppointment.doctor_id.field_type &&
                  this.props.forAppointment.doctor_id.field_type.name !==
                    "Psychologist" ? (
                    <>
                      <div class="accor-seprator"></div>
                      <div class="sidebar-content-box d-flex flex-wrap">
                        <div class="scb-left">
                          <p>Radiology</p>
                          <h6>
                            {this.props.forAppointment.radiology &&
                              this.props.forAppointment.radiology
                                .map((rs) => rs.label)
                                .join(",  ")}
                          </h6>
                        </div>
                        <div class="scb-right">
                          <p>Lab Investigation</p>
                          <h6>
                            {this.props.forAppointment.lab_investigations &&
                              this.props.forAppointment.lab_investigations
                                .map((rs) => rs.label)
                                .join(",  ")}
                          </h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div class="accor-seprator"></div>
                      <div class="sidebar-content-box d-flex flex-wrap">
                        <div class="scb-left">
                          <p>Technique I would use (Optional)</p>
                          <h6>
                            {this.props.forAppointment &&
                              this.props.forAppointment.technique}
                          </h6>
                        </div>
                        <div class="scb-right">
                          <p>Medicinal Plan I would use (Optional)</p>
                          <h6>
                            {this.props.forAppointment &&
                              this.props.forAppointment.medical_plan}
                          </h6>
                        </div>
                      </div>
                      <div class="sidebar-content-box d-flex flex-wrap">
                        <div class="scb-left">
                          <p>Conduct Following Assessments (Optional)</p>
                          <h6>{this.props.forAppointment.conduct}</h6>
                        </div>
                      </div> */}
                    </>
                  )}
                  <div class="sep"></div>
                  <ul class="lt-list d-md-flex flex-wrap">
                    <li class="lt-item">
                      <h3>Plan for next sessions</h3>
                      <p>
                        {this.props.forAppointment.plan_for_the_next_session}
                      </p>
                    </li>
                    <li class="lt-item">
                      <h3>Follow up sessions</h3>
                      <p>{this.props.forAppointment.followup_session}</p>
                    </li>
                    <li class="lt-item">
                      <h3>
                        {this.props.forAppointment &&
                        this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.field_type.name ===
                          "Psychologist"
                          ? "Task allotted"
                          : "Special instructions"}
                      </h3>
                      <p>{this.props.forAppointment.special_advice}</p>
                    </li>
                    <li class="lt-item">
                      <h3>Referral, if any</h3>
                      <p>{this.props.forAppointment.referral}</p>
                    </li>

                    <li class="lt-item">
                      <h3>
                        {this.props.forAppointment &&
                        this.props.forAppointment.doctor_id &&
                        this.props.forAppointment.doctor_id.field_type.name ===
                          "Psychologist"
                          ? "Others"
                          : "To Chemist"}
                      </h3>
                      <p>{this.props.forAppointment.to_chemist}</p>
                    </li>
                  </ul>
                  {this.props.forAppointment &&
                    this.props.forAppointment.doctor_id &&
                    this.props.forAppointment.doctor_id.field_type.name ===
                      "Psychologist" && (
                      <div class="sidebar-content-box d-flex flex-wrap">
                        <div class="scb-left">
                          <p>Objective of the session</p>
                          <ol style={{ marginLeft: " 20px" }}>
                            {this.props.forAppointment.objective.map((o) => (
                              <li>{o}</li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    )}
                  <div class="sep"></div>
                  <p style={{ fontSize: "11px" }}>
                    Please note that the prescription is not a Medicolegal
                    document. For any emergency/ severe complaints kindly
                    contact nearby hospital/ clinic for physical consultation.
                  </p>
                  <div class="sep"></div>
                  <div
                    class="latter-text flex justify-content-center"
                    style={{ display: "flex", fontWeight: "bold" }}
                  >
                    <p>
                      Powered by <span style={{ color: "#f79041" }}>Yes</span>
                      <span style={{ color: "#621bc0" }}>Mindy</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={
              this.props.isShow === true ? "overlay4 show" : "overlay4"
            }
          ></div>
        </>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadForAppointment: (id) => dispatch(loadForAppointment(id)),
});
const mapStateToProps = (state) => ({
  forAppointment: getPrescription(state).forAppointment,
  profile: getPatient(state).profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(Prescription);
