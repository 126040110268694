import React, { Component } from 'react'
import Select from 'react-select'

class selectOptions extends Component {
  state = {}
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }

  render() {
    const { attrind, attrnum, name } = this.props
    return (
      <Select
        styles={{ ...this.customStyles }}
        className={this.props.nameCl}
        value={this.props.value}
        onChange={e =>
          this.props.onChange({
            value: e.value,
            label: e.label,
            attrind,
            attrnum,
            name,
          })
        }
        maxMenuHeight={120}
        options={this.props.options}
      />
    )
  }
}

export default selectOptions
