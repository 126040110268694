import React, { Component } from 'react'
import HtmlParser from 'react-html-parser'
import communicationIcon from '../../include/images/communication-center-icon1.svg'
import { Link } from 'react-router-dom'
import logo from '../../include/images/yes-mindy-color-logo-small.png'
import dashboardIcon from '../../include/images/dashboard-icon.svg'
import { clearAllToken } from '../services/localStorageServices'
import { isMobile } from 'react-device-detect'

class Header extends Component {
  componentDidMount = () => {
    if (isMobile) {
      document.body.classList.add('mob-pd-50')
    }
  }
  state = {
    patient: localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))
      : false,
  }
  logOut = e => {
    if (this.props.setPatient) this.props.setPatient(false)
    e.preventDefault()
    clearAllToken()
    this.setState({ patient: false })
  }
  render() {
    const { helpline_no, header_email_address } = this.props
    return (
      <div className='header'>
        <div className='header-upper d-flex align-items-center'>
          <div className='container container1'>
            <div className='row'>
              <div className='col-md-12 d-flex align-items-center justify-content-between'>
                <ul className='query-list d-flex align-items-center d-none'>
                  <li>
                    <img src={communicationIcon} alt='' />
                    <span>Helpline</span>
                  </li>
                  <li>
                    <a href={'tel:' + helpline_no}>{helpline_no}</a>
                  </li>
                  <li>
                    <a href={'mailto:' + header_email_address}>
                      {header_email_address}
                    </a>
                  </li>
                </ul>

                {this.state.patient && (
                  <ul class='query-list d-flex align-items-lg-center ml-auto '>
                    <li>
                      <a
                        href='/patient/dashboard'
                        class='header-right-box d-flex align-items-center justify-content-center'>
                        <img src={dashboardIcon} alt='' />{' '}
                        <span class='d-none d-lg-block mr-1'>Go to </span>{' '}
                        <span class='d-none d-sm-block'>dashboard</span>
                      </a>
                    </li>
                    {this.state.patient.first_name && (
                      <li>
                        {HtmlParser(
                          `Welcome, <b>${this.state.patient.first_name} </b>`
                        )}
                      </li>
                    )}
                    <li>
                      {' '}
                      <a href='#!' onClick={this.logOut}>
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='header-lower'>
          <nav className='navbar navbar-expand-xl'>
            <div className='container container1'>
              <div className='nav-inside d-flex align-items-center justify-content-between'>
                <a className='navbar-brand' href='/'>
                  <img src={logo} alt='' />
                </a>
                <button
                  onClick={e => this.setState({ navBar: !this.state.navBar })}
                  className={
                    this.state.navBar
                      ? 'navbar-toggler'
                      : 'navbar-toggler collapsed'
                  }
                  type='button'
                  data-toggle='collapse'
                  data-target='#mainNav'
                  aria-controls='mainNav'
                  aria-expanded='false'
                  aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'></span>
                </button>
                <div
                  className={
                    this.state.navBar
                      ? 'collapse navbar-collapse collapsing show'
                      : 'collapse navbar-collapse'
                  }
                  id='mainNav'>
                  <div className='navbar-inside ml-auto'>
                    <ul className='navbar-nav'>
                      <li className='nav-item'>
                        <a className='nav-link' href={'/about-us'}>
                          About us
                        </a>
                      </li>
                      <li className='nav-item dropdown'>
                        <a
                          className='nav-link dropdown-toggle'
                          href='#'
                          id='dropdown01'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'>
                          Clinician
                        </a>
                        <ul
                          className='dropdown-menu'
                          aria-labelledby='dropdown01'>
                          <li
                            className={
                              window.location.pathname ===
                              '/clinical-psychiatrist'
                                ? 'active'
                                : ''
                            }>
                            <a
                              className='dropdown-item'
                              href={'/clinical-psychiatrist'}>
                              Psychiatrist
                            </a>
                          </li>
                          <li
                            className={
                              window.location.pathname ===
                              '/clinical-psychologist'
                                ? 'active'
                                : ''
                            }>
                            <a
                              class='dropdown-item'
                              href={'/clinical-psychologist'}>
                              Psychologist
                            </a>
                          </li>
                          <li
                            className={
                              window.location.pathname ===
                              '/clinical-psychosocial'
                                ? 'active'
                                : ''
                            }>
                            <a
                              className='dropdown-item'
                              href={'/clinical-psychosocial'}>
                              Psychosocial
                            </a>
                          </li>
                          <li
                            className={
                              window.location.pathname ===
                              '/clinical-counsellor'
                                ? 'active'
                                : ''
                            }>
                            <a
                              className='dropdown-item'
                              href={'/clinical-counsellor'}>
                              Counsellor
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href={'/services'}>
                          Services
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='nav-link' href={'/clinical-community'}>
                          Community
                        </a>
                      </li>

                      <li className='nav-item'>
                        <a className='nav-link' href={'/clinical-caregiver'}>
                          CareGiver
                        </a>
                      </li>
                    </ul>{' '}
                    <div class='nav-inside-content d-block d-lg-none text-center py-0'>
                      <span class='separeter'></span>
                      <ul class='query-list align-items-center mob-query-list'>
                        <li>
                          <a href='tel:9167 XXXX 12'>{helpline_no}</a>
                        </li>
                        <li>
                          <a href={'mailto:' + header_email_address}>
                            {header_email_address}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {!this.state.patient && (
                      <div className='nav-inside-content d-block d-md-none text-center'>
                        <span className='btn header-btn'>
                          <>
                            {/* <Link to='/patient/sign-up'>Signup</Link> &{' '} */}
                            <Link to='/patient/sign-in'>Signup & Login</Link>
                          </>
                        </span>
                      </div>
                    )}
                  </div>
                </div>{' '}
                {!this.state.patient && (
                  <div className='header-right  d-md-block'>
                    <span className='btn header-btn'>
                      <>
                        {/* <Link to='/patient/sign-up'>Signup</Link> &{' '} */}
                        <Link to='/patient/sign-in'>Signup & Login</Link>
                      </>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header
