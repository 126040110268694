import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from './header'
import Assessments from './assessments'
import { withRouter } from 'react-router-dom'
import LeftBar from './leftBar'
import Worksheets from './worksheets'
import Homeworks from './homeworks'
import Psychoeducation from './psychoeducation'
import { getDoctor } from '../../../store/doctor'
class Toolbox extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  }

  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Assessments updateStep={this.updateStep} step={this.state.step} />
        )
      case 2:
        return (
          <Worksheets updateStep={this.updateStep} step={this.state.step} />
        )
      case 3:
        return <Homeworks updateStep={this.updateStep} step={this.state.step} />
      case 4:
        return (
          <Psychoeducation
            updateStep={this.updateStep}
            step={this.state.step}
          />
        )

      default:
        break
    }
  }

  toggleSidebar = e => {
    if (e) e.preventDefault()
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  render() {
    return (
      <div class={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div className='dashboard-main-container'>
          <div className='dashboard-main-heading'>
            <h2>
              Welcome, <strong>{this.props.getDoctor.name}</strong>
            </h2>
            <p>Have a nice day at work</p>
          </div>
          <div className='dashboard-wrapper'>
            <div className='dashboard-single-panel'>
              <div className='dsp-head d-flex aligsn-items-md-center justify-content-md-between'>
                <h4>ToolBox</h4>
              </div>
              <div className='dsp-body'>
                <div className='tab-container d-none d-md-block'>
                  <ul
                    className='nav nav-tabs nav-tabs2'
                    id='myTab'
                    role='tablist'>
                    <li
                      class='nav-item'
                      role='presentation'
                      style={{ cursor: 'pointer' }}>
                      <a
                        className={
                          this.state.step === 1 ? 'nav-link active' : 'nav-link'
                        }
                        id='tasks-tab'
                        data-toggle='all'
                        role='tab'
                        aria-controls='all'
                        aria-selected='false'
                        onClick={e => this.updateStep(null, 1)}>
                        <b>Assessments</b>
                        <span>Assessments</span>
                      </a>
                    </li>
                    <li
                      class='nav-item'
                      role='presentation'
                      style={{ cursor: 'pointer' }}>
                      <a
                        className={
                          this.state.step === 2 ? 'nav-link active' : 'nav-link'
                        }
                        id='tasks-tab'
                        data-toggle='all'
                        role='tab'
                        aria-controls='all'
                        aria-selected='false'
                        onClick={e => this.updateStep(null, 2)}>
                        <b>Worksheets</b>
                        <span>Worksheets</span>
                      </a>
                    </li>
                    <li
                      className='nav-item'
                      role='presentation'
                      style={{ cursor: 'pointer' }}>
                      <a
                        className={
                          this.state.step === 3 ? 'nav-link active' : 'nav-link'
                        }
                        id='tasks-tab'
                        data-toggle='starred'
                        role='tab'
                        aria-controls='starred'
                        aria-selected='false'
                        onClick={e => this.updateStep(null, 3)}>
                        <b>Homeworks</b>
                        <span>Homeworks</span>
                      </a>
                    </li>
                    <li
                      className='nav-item'
                      role='presentation'
                      style={{ cursor: 'pointer' }}>
                      <a
                        className={
                          this.state.step === 4 ? 'nav-link active' : 'nav-link'
                        }
                        id='tasks-tab'
                        data-toggle='Other'
                        role='tab'
                        aria-controls='other'
                        aria-selected='false'
                        onClick={e => this.updateStep(null, 4)}>
                        <b>Psychoeducation</b>
                        <span>Psychoeducation</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='tab-content-box'>
                  <div className='tab-content' id='myTabContent'>
                    {' '}
                    <div
                      class={
                        this.state.step === 1
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 1)}>
                        Assessments
                      </div>
                      <div
                        className={
                          this.state.step === 1
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 1 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 2
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 2)}>
                        Worksheets
                      </div>
                      <div
                        className={
                          this.state.step === 2
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 2 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 3
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 3)}>
                        Homeworks
                      </div>
                      <div
                        className={
                          this.state.step === 3
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 3 && this.leftSideStep()}
                      </div>
                    </div>
                    <div
                      class={
                        this.state.step === 4
                          ? 'tab-pane card fade show active'
                          : 'tab-pane card fade d-none'
                      }
                      id='allpatients'
                      role='tabpanel'
                      aria-labelledby='allpatients-tab'>
                      <div
                        class='card-header'
                        onClick={e => this.updateStep(null, 4)}>
                        Psychoeducation
                      </div>
                      <div
                        className={
                          this.state.step === 4
                            ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                            : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                        }
                        style={{ position: 'relative' }}>
                        {' '}
                        {this.state.step === 4 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  getDoctor: getDoctor(state).profile,
})

export default withRouter(connect(mapStateToProps)(Toolbox))
