import React from "react";
import { connect } from "react-redux";
import Form from "../../../common/form/form";
import { withRouter } from "react-router-dom";
import close from "../../../../include/images/icon-close.svg";
import Select from "react-select";

import _ from "lodash";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import { toast } from "react-toastify";
import {
  addAnswerBio,
  getBio,
  loadAnswerBio,
  loadlevelOfImpairement,
} from "../../../../store/bioPsycosocial";
import { getDoctor } from "../../../../store/doctor";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

class BioForm extends Form {
  state = {
    data: {
      active_stressor_precipitating_factor: "",
      biological_medical_factor: "",
      psychological_personlity_factor: "",
      social_family_factor: "",
      lavel_of_impairment: "",
      lavel_of_activity_self_care: "",
    },
    loading: false,
    field_type: "",
    error: {},
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.bioForm && this.props.bioForm !== prevProps.bioForm) {
      this.props.loadlevelOfImpairement();
    }

    if (
      this.props.bioAnswer !== null &&
      this.props.bioAnswer !== prevProps.bioAnswer
    ) {
      this.state.field_type =
        this.props.bioAnswer && this.props.bioAnswer.field_type;
      const data = { ...this.state.data };
      data.active_stressor_precipitating_factor =
        this.props.bioAnswer &&
        this.props.bioAnswer.active_stressor_precipitating_factor;
      data.biological_medical_factor =
        this.props.bioAnswer && this.props.bioAnswer.biological_medical_factor;
      data.psychological_personlity_factor =
        this.props.bioAnswer &&
        this.props.bioAnswer.psychological_personlity_factor;

      data.social_family_factor =
        this.props.bioAnswer && this.props.bioAnswer.social_family_factor;
      data.lavel_of_impairment = {
        value: this.props.bioAnswer && this.props.bioAnswer.lavel_of_impairment,
        label: this.props.bioAnswer && this.props.bioAnswer.lavel_of_impairment,
      };
      data.lavel_of_activity_self_care =
        this.props.bioAnswer &&
        this.props.bioAnswer.lavel_of_activity_self_care;
      this.setState({ data });
    }
  };
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const data = { ...this.state.data };
    var payLoad;
    payLoad = {
      active_stressor_precipitating_factor:
        this.state.data.active_stressor_precipitating_factor,
      biological_medical_factor: this.state.data.biological_medical_factor,
      psychological_personlity_factor:
        this.state.data.psychological_personlity_factor,
      social_family_factor: this.state.data.social_family_factor,
      lavel_of_impairment: this.state.data.lavel_of_impairment.value,
      lavel_of_activity_self_care: this.state.data.lavel_of_activity_self_care,
      patient_id: this.props.patientId,
    };
    const newPayload = _.pickBy(payLoad, _.identity);
    this.props.addAnswerBio(newPayload, this.callBack);
  };
  callBack = (res) => {
    if (res && res.status === 200) {
      this.props.toggleBio();
      this.props.loadAnswerBio(this.props.patientId);
      toast(<AlertSuccess message="Information Saved." />);
      this.setState({
        data: {},
      });
    } else {
      toast(<AlertError message="Something Went Wrong." />);
      this.setState({
        data: {},
      });
    }
  };

  render() {
    return (
      <>
        <div
          class="sidebar-box list-box"
          id="prescriptions_side_panel_1"
          style={{ right: this.props.bioForm === true ? "0px" : "-100%" }}
        >
          <div class="sidebar-head d-flex align-items-center">
            <h5>BioPsychoSocial Formulation</h5>
            <div class="sidebar-right ml-auto d-flex align-items-centers">
              <div class="close-img" onClick={this.props.toggleBio}>
                <img src={close} alt="" />
              </div>
            </div>
          </div>
          <div class="sidebar-body height-auto p-0">
            <div class="sidebar-inner-content">
              <div class="fun-box">
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Active Stressor/Precipitating Factor
                  </label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.active_stressor_precipitating_factor =
                          e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={
                      this.state.data &&
                      this.state.data.active_stressor_precipitating_factor
                    }
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Biological/Medical Factor
                  </label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.biological_medical_factor = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={
                      this.state.data &&
                      this.state.data.biological_medical_factor
                    }
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Psychological/Personality Factor
                  </label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.psychological_personlity_factor = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={
                      this.state.data &&
                      this.state.data.psychological_personlity_factor
                    }
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>Social/Family Factor</label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.social_family_factor = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={
                      this.state.data && this.state.data.social_family_factor
                    }
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>Level Of Impairment</label>
                  <Select
                    classNamePrefix="select"
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="lavel_of_impairment"
                    styles={this.customStyles}
                    options={this.props.levelOfImpairement.map((ex) => ({
                      label: ex.title,
                      value: ex.title,
                    }))}
                    value={
                      this.state.data && this.state.data.lavel_of_impairment
                    }
                    onChange={(e) => {
                      const data = { ...this.state.data };
                      if (e) {
                        data.lavel_of_impairment = e;
                        this.setState({ data });
                      }
                    }}
                  />
                </div>
                <div className="check-box-group mb-2">
                  <label style={{ color: "#000" }}>
                    Level of Activity/Self Care
                  </label>
                  <textarea
                    style={{ padding: "10px" }}
                    type="text"
                    onChange={(e) => {
                      const data = {
                        ...this.state.data,
                      };
                      if (e) {
                        data.lavel_of_activity_self_care = e.target.value;
                        this.setState({ data });
                      }
                    }}
                    className="form-control"
                    value={
                      this.state.data &&
                      this.state.data.lavel_of_activity_self_care
                    }
                    placeholder="Write here..."
                  ></textarea>
                </div>
                <div className="sidebar-footer d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      this.handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.bioForm === true ? "overlay3 show" : "overlay4"}
        ></div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadlevelOfImpairement: () => dispatch(loadlevelOfImpairement()),
  loadAnswerBio: (id) => dispatch(loadAnswerBio(id)),
  addAnswerBio: (data, callback) => dispatch(addAnswerBio(data, callback)),
});

const mapStateToProps = (state) => ({
  doctorProfile: getDoctor(state).profile,
  levelOfImpairement: getBio(state).levelOfImpairement,
  bioAnswer: getBio(state).bioAnswer,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BioForm)
);
