import React, { Component } from "react";
import image1 from "../../include/images/clinician-caregiver-hero-img.png";
import icon2 from "../../include/images/caregivers-doc-img.png";
import icon1 from "../../include/images/caretaker-icon4.svg";
import icon5 from "../../include/images/caretaker-icon1.svg";
import icon3 from "../../include/images/caretaker-icon2.svg";
import icon4 from "../../include/images/caretaker-icon3.svg";
import icon6 from "../../include/images/caregivers-icon1.svg";
import icon7 from "../../include/images/caregivers-icon2.svg";
import icon8 from "../../include/images/caregivers-icon3.svg";
import icon9 from "../../include/images/caregivers-icon4.svg";
import { Link } from "react-router-dom";

import { getMisc, loadConstant, customerQuery } from "../../store/misc";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "./footer";

class CareGiver extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
  };

  render() {
    const { constants } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;

    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="clinician-hero-container caregivers-hero">
            <div class="container container1">
              <div class="row flex-row-reverse align-items-end">
                <div class="col-xl-4 offset-xl-1 col-lg-5">
                  <div class="clinician-hero-content pb-5">
                    <h3 class="d-block d-md-none">CareGiver</h3>
                    <h4 class="mb-0">YesMindy Support</h4>
                    <p>
                      We whole heartedly support Mental Health CareGivers for
                      your dedication towards destigmatizing and being a key
                      support figure for patients.
                    </p>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-7">
                  <div class="clinician-hero-img">
                    <img src={image1} alt="" />
                    <div class="clinician-dot dot1">
                      <span>PSYCHOSOCIAL</span>
                    </div>
                    <div class="clinician-dot dot2">
                      <span>PSYCHOLOGIST</span>
                    </div>
                    <div class="clinician-dot dot3">
                      <span>COUNSELLOR</span>
                    </div>
                    <div class="clinician-dot dot4 active">
                      <span>CareGiver</span>
                    </div>
                    <div class="clinician-dot dot-center">
                      <span>PSYCHIATRIST</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container cgl-container">
            <div class="container container1">
              <div class="row">
                <div class="col-xl-12">
                  <div class="heading text-center">
                    <h4>
                      CareGivers: <span>The Silent Salients</span>
                    </h4>
                  </div>
                  <div class="caregivers-outer">
                    <ul class="caregivers-list">
                      <li class="caregivers-item">
                        <div class="caregivers-box">
                          <div class="caregivers-content">
                            <div class="caregivers-content-inside">
                              CareGivers are key support for patients by
                              handling emergencies & protecting them from
                              societal discrimination.
                              <div class="caregivers-num">01</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="caregivers-item">
                        <div class="caregivers-box">
                          <div class="caregivers-content">
                            <div class="caregivers-content-inside">
                              CareGivers multitask daily: looking after
                              treatment, making decisions, financial support &
                              daily care.
                              <div class="caregivers-num">02</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="caregivers-item">
                        <div class="caregivers-box">
                          <div class="caregivers-content">
                            <div class="caregivers-content-inside">
                              CareGivers bridge the communication gap between
                              patient & professionals, while following doctor
                              appointments.
                              <div class="caregivers-num">03</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="caregivers-item">
                        <div class="caregivers-box">
                          <div class="caregivers-content">
                            <div class="caregivers-content-inside">
                              CareGivers monitor progress, create nurturing
                              environment & design coping strategies for
                              difficult situations
                              <div class="caregivers-num">04</div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <img class="caregivers-doc-img" src={icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container prowess-container">
            <div class="container container1">
              <div class="row d-flex align-items-center">
                <div class="col-lg-4">
                  <div class="prowess-text-box text-center text-lg-left">
                    <h3>
                      CareGiver <span>Struggles</span>
                    </h3>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="prowess-img-box">
                    <ul class="prowess-list d-flex flex-wrap align-items-center justify-content-between">
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon5} alt="" />
                        </div>
                        <h6>
                          Lack of community support, illness <br />
                          knowledge & infrastructure.
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon3} alt="" />
                        </div>
                        <h6>
                          Finding balance in life while <br />
                          supporting the patient.
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon4} alt="" />
                        </div>
                        <h6>
                          Criticism & dilemma they <br />
                          face being a decision maker.
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon1} alt="" />
                        </div>
                        <h6>
                          Scarce support for unexpected <br />
                          events during treatment.
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container prowess-container pt-0">
            <div class="container container1">
              <div class="row d-flex align-items-center">
                <div class="col-lg-4">
                  <div class="prowess-text-box text-center text-lg-left">
                    <h3>
                      Supporting <span>CareGivers</span>
                    </h3>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="prowess-img-box">
                    <ul class="prowess-list d-flex flex-wrap align-items-center justify-content-between">
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon6} alt="" />
                        </div>
                        <h6>
                          Coping strategies for self and <br />
                          patient emergencies
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon7} alt="" />
                        </div>
                        <h6>
                          Gaining knowledge to understand <br />
                          early warning signs for treatment
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon8} alt="" />
                        </div>
                        <h6>
                          Improve Mental Health infrastructure <br />
                          with community & expert support
                        </h6>
                      </li>
                      <li class="prowess-item d-flex align-items-center justify-content-center">
                        <div class="prowess-icon">
                          <img src={icon9} alt="" />
                        </div>
                        <h6>
                          Know your importance in recovery <br />& take frequent
                          breaks for yourself
                        </h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cta-container">
            <div class="container container1">
              <div class="row">
                <div class="col-xl-10 offset-xl-1">
                  <div class="cta-content text-center">
                    <h3>Need Any Help With Your Patient?</h3>
                    <div class="cta-btns d-sm-inline-flex justify-content-center align-items-start">
                      <div class="cta-btn d-flex flex-column justify-content-center align-items-center">
                        <Link
                          to="patient/chat"
                          className="btn btn-default"
                          target="_blank"
                        >
                          Chat with us{" "}
                        </Link>
                        <span style={{ cursor: "auto !important" }}>
                          It's Free
                        </span>
                      </div>
                      <div class="cta-btn d-flex flex-column justify-content-center align-items-center">
                        <Link
                          to={
                            this.state.patient
                              ? "patient/doctors"
                              : "patient/appointment"
                          }
                          className="btn btn-default"
                        >
                          Book an appointment
                        </Link>
                        <span>As a Guardian</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="container container1">
              <div class="row">
                <div class="col-xl-12">
                  <div class="heading text-center">
                    <h4>
                      5 MythBusters <span>For CareGiver</span>
                    </h4>
                  </div>
                  <div class="accordion pc-outer" id="accordionExample">
                    <div class="card card-true-false">
                      <div class="card-header">
                        Going to faith healers & getting married are forms of
                        treatment for mental disorders. <span>False</span>
                      </div>
                    </div>

                    <div class="card card-true-false">
                      <div class="card-header">
                        Mental illness is nothing but an attitude problem. Being
                        strong or positive gets you to recover.
                        <span>False</span>
                      </div>
                    </div>

                    <div class="card card-true-false">
                      <div class="card-header">
                        Children cannot suffer from mental illness
                        <span>False</span>
                      </div>
                    </div>

                    <div class="card card-true-false">
                      <div class="card-header">
                        Mental disorder treatment is lifelong and medicines are
                        addictive<span>False</span>
                      </div>
                    </div>

                    <div class="card card-true-false">
                      <div class="card-header">
                        Patients with mental health issues have low intelligence
                        and can never be normal<span>False</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  customerQuery: (data, callback) => dispatch(customerQuery(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CareGiver);
