import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class UserSelection extends Component {
  state = {};

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <div className="mr-step">Step 1/5</div>
              <h2 className="mb-3">Create profile for</h2>
              {!this.props.appointment && (
                <p className="login-text">
                  Have an account? <Link to="sign-in">Log In</Link>
                </p>
              )}
            </div>

            <div className="form-group">
              <a
                className="btn btn-lg btn-secondary btn-block"
                onClick={(e) => {
                  //this.props.history.push("signUp/next");
                  this.props.updateStep(e, 2, "self");
                }}
              >
                <span>For Myself</span>
              </a>
            </div>
            <div className="form-group">
              <a
                className="btn btn-lg btn-secondary btn-block"
                onClick={(e) => {
                  //this.props.history.push("signUp/next");
                  this.props.updateStep(e, 2, "guardian");
                }}
              >
                <span>
                  I am Guardian/Caretaker
                  <small className="d-block">
                    (Below 18yrs age or dependent client)
                  </small>
                </span>
              </a>
            </div>
            {/* <div className="form-group">
              <a
                className="btn btn-lg btn-secondary btn-block"
                onClick={(e) => {
                  //this.props.history.push("signUp/next");
                  this.props.updateStep(e, 2, "couple");
                }}
              >
                <span>For Couple</span>
              </a>
            </div> */}
            <div class="seprator">or</div>
            <div class="login-as-text-box text-center">
              <h5>
                <Link to="/doctor/sign-up">
                  Signup as a Health Professional
                </Link>
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserSelection);
