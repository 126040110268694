import React, { Component, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { getSeo } from '../../store/seo'
import { WatiScript } from '../../include/js/wati'

const HelmetComponent = props => {
  // useEffect(() => {
  //   const url = window?.location?.pathname
  //   console.log('url', url)
  //   if (
  //     url == '/doctor/dashboard' ||
  //     url == '/patient/dashboard' ||
  //     url.includes('/dr')
  //   ) {
  //     console.log('if app')
  //   } else {
  //     console.log('else app')
  //     const script = document
  //       .querySelectorAll(
  //         'script[src*="https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?97714"]'
  //       )
  //       .forEach(e => e.remove())

  //     const app = document.getElementById('whatsapp-chat-widget')

  //     console.log('else app script', script)

  //     if (app) {
  //       app.remove()
  //     }
  //   }
  //   console.log('call appjs', window.location.pathname)
  // }, [window?.location?.pathname])

  return (
    <Helmet key={props.getSeo.seo._id}>
      <title>
        {props.getSeo.seo.title ? props.getSeo.seo.title : 'Yesmindy'}
        {}
      </title>
      <meta
        name='description'
        content={
          props.getSeo.seo.description
            ? props.getSeo.seo.description
            : 'Yesmindy'
        }
      />
      <meta
        name='google-site-verification'
        content='fY5YafjFdFAYOkbQCJRlbVbIao5SJ4Lbh1-6UaFDh8Y'
      />
    </Helmet>
  )
}

// class HelmetComponent extends Component {
//   state = {}
//   render() {
//     return (
//       <Helmet key={this.props.getSeo.seo._id}>
//         <title>
//           {this.props.getSeo.seo.title
//             ? this.props.getSeo.seo.title
//             : 'Yesmindy'}
//           {}
//         </title>
//         <meta
//           name='description'
//           content={
//             this.props.getSeo.seo.description
//               ? this.props.getSeo.seo.description
//               : 'Yesmindy'
//           }
//         />
//         <meta
//           name='google-site-verification'
//           content='fY5YafjFdFAYOkbQCJRlbVbIao5SJ4Lbh1-6UaFDh8Y'
//         />
//       </Helmet>
//     )
//   }
// }

const mapStateToProps = state => ({
  getSeo: getSeo(state),
})
export default connect(mapStateToProps, null)(HelmetComponent)
