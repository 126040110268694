import React, { Component } from "react";
import { getCaserecord, loadCaseRecord } from "../../../store/caseRecord";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import noteIcon from "../../../include/images/note-icon.svg";
import efeather from "../../../include/images/Icon-feather-edit.svg";
import hospital from "../../../include/images/hospital.svg";

import { getDoctor } from "../../../store/doctor";
import BioPycsocial from "./followUp/bioPycsocial";
import {
  loadAnswerBio,
  getBio,
  loadListData,
} from "../../../store/bioPsycosocial";
import moment from "moment";

class BioPyscho extends Component {
  state = {
    bioForm: false,
    answerapicall: false,
  };

  toggleBio = () => {
    this.setState({
      bioForm: !this.state.bioForm,
      answerapicall: !this.state.answerapicall,
    });
  };
  componentDidMount() {
    document.body.className = "dashboard fixed";
    this.props.loadAnswerBio(this.props.id);
    this.props.loadListData(this.props.id);
  }
  render() {
    const dataToShow =
      this.props.list &&
      this.props.list.filter(
        (e) => e && e.field_type !== this.props.doctorProfile.field_type.name
      );
    console.log("datatoshow", dataToShow);
    return (
      <>
        <div class="add-treatment-plan-panel">
          <div
            class="ac-label-left d-flex align-items-center"
            data-toggle="addTreatments"
            onClick={this.toggleBio}
          >
            <div class="ac-label-img">
              {this.props.bioAnswer && this.props.bioAnswer.is_submitted ? (
                <img src={efeather} alt="" />
              ) : (
                <img src={hospital} alt="" />
              )}
            </div>
            <div class="ac-label-text">
              {this.props.bioAnswer && this.props.bioAnswer.is_submitted ? (
                <h3>
                  Edit{" "}
                  {`${
                    this.props.doctorProfile &&
                    this.props.doctorProfile.field_type &&
                    this.props.doctorProfile.field_type.name
                  }`}{" "}
                  BioPsychoSocial
                </h3>
              ) : (
                <h3>
                  Add{" "}
                  {`${
                    this.props.doctorProfile &&
                    this.props.doctorProfile.field_type &&
                    this.props.doctorProfile.field_type.name
                  }`}{" "}
                  BioPsychoSocial
                </h3>
              )}
            </div>
            {this.props.bioAnswer && this.props.bioAnswer.is_submitted ? (
              <div class="ac-label-right ml-auto">
                <p style={{ fontSize: "14px" }}>Last Updated:</p>
                <h4>
                  {moment(
                    this.props.bioAnswer && this.props.bioAnswer.updated_at
                      ? this.props.bioAnswer.updated_at
                      : this.props.bioAnswer.created_at
                  ).format("DD MMM, YYYY")}
                </h4>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {dataToShow &&
          dataToShow.map((e) => (
            <div class="custom-accordion">
              <div class="ca-pannel">
                <input
                  class="ac-input"
                  id="ac-1"
                  name="accordion-1"
                  type="checkbox"
                />
                <label class="ac-label" for="ac-1">
                  <div class="ac-lable-content d-flex align-items-center justify-content-between">
                    <div class="ac-label-left d-flex align-items-center">
                      {/* <div class="ac-label-img">
                        <img src={noteIcon} alt="" />
                      </div> */}
                      <div class="ac-label-text">
                        {this.props.doctorProfile &&
                        this.props.doctorProfile.field_type &&
                        this.props.doctorProfile.field_type.name ===
                          "Psychologist" ? (
                          <h3>Psychiatrist BioPsychoSocial</h3>
                        ) : (
                          <h3>Psychologist BioPsychoSocial</h3>
                        )}
                        <p>{e.doctor_id && e.doctor_id.name}</p>
                      </div>
                    </div>
                    <div class="ac-label-right">
                      <p>Last Updated:</p>
                      <h4>
                        {moment(
                          e && e.updated_at ? e.updated_at : e.created_at
                        ).format("DD MMM, YYYY")}
                      </h4>
                    </div>
                  </div>
                  <i></i>
                </label>
                <div class="article ac-content">
                  <h6> Active Stressor/Percipating Factor</h6>
                  <p>{e.active_stressor_precipitating_factor}</p>
                  <h6> Biological/Medical Factor</h6>
                  <p>{e.biological_medical_factor}</p>
                  <h6>Psychological/Personality Factor</h6>
                  <p>{e.psychological_personlity_factor}</p>
                  <h6>Social/Family Factor</h6>
                  <p>{e.social_family_factor}</p>
                  <h6>Level Of Impairment</h6>
                  <p>{e.lavel_of_impairment}</p>
                  <h6> Level of Activity/Self Care</h6>
                  <p>{e.lavel_of_activity_self_care}</p>
                </div>
              </div>
            </div>
          ))}
        <BioPycsocial
          bioForm={this.state.bioForm}
          toggleBio={this.toggleBio}
          answerapicall={this.state.answerapicall}
          patientId={this.props && this.props.id}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  doctorProfile: getDoctor(state).profile,
  bioAnswer: getBio(state).bioAnswer,
  list: getBio(state).list,
});
const mapDispatchToProps = (dispatch) => ({
  loadAnswerBio: (id) => dispatch(loadAnswerBio(id)),
  loadListData: (id) => dispatch(loadListData(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BioPyscho)
);
