import React, { Component } from "react";
import logo from "../../../include/images/yes-mindy-main-logo-white-color.png";
import borderCheckImg from "./../../../include/images/border-check-icon.svg";

class Success extends Component {
  state = {};
  render() {
    return (
      <div className="member-container">
        <div className="top-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div className="member-box d-flex login-form">
          <div className="member-left">
            <div className="ml-box"></div>
          </div>
          <div className="member-right">
            <div className="member-right-content d-md-flex align-items-center justify-content-center text-center">
              <div className="mr-box">
                <div className="form-check-icon">
                  <img src={borderCheckImg} alt="" />
                </div>
                <h3>Thank you</h3>
                <p className="mb-5">
                  After approval process your profile will be activated and you
                  will be informed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Success;
