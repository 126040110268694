import React, { Component } from "react";
import dropright1 from "../../../../include/images/arrow-dropright-1.svg";
import dropright from "../../../../include/images/arrow-dropright.svg";
import close from "../../../../include/images/icon-close.svg";
import avtarImg from "../../../../include/images/avatar1.png";
import bluestar from "../../../../include/images/blue-star-icon.svg";
import star from "../../../../include/images/start-0.svg";
import add from "../../../../include/images/icon-add.svg";
import file from "../../../../include/images/prescription-file-icon.svg";
import print from "../../../../include/images/dark-print-icon.svg";
import download from "../../../../include/images/file-download-dark-icon.svg";
import copy from "../../../../include/images/dark-copy-icon.svg";
import IncompletePrescription from "./incompletePrescription";
import {
  loadForPatient,
  getPrescription,
} from "../../../../store/prescription";
import { connect } from "react-redux";
import Moment from "moment";
import moment from "moment";
import _ from "lodash";
import dot from "../../../../include/images/dark-dot-icon.svg";
import down from "../../../../include/images/down-icon-1.svg";
import { getPatient, patientDetail } from "../../../../store/patient";
import { getDoctor } from "../../../../store/doctor";

class ListPrescription extends Component {
  state = {
    isShow2: false,
    id: "",
    number: "",
    age: "",
    list_id: "",
    caseRecord: "",
    detail: false,
    appointment_time: "",
  };
  toggleDetail = (list_id) => {
    this.setState({
      list_id: list_id,
      detail: !this.state.detail,
    });
  };

  toggle2 = (id, num, age, type, caseRecord, appointment_time) => {
    this.props.LoadpatientDetail(this.props.id);
    this.setState({
      isShow2: !this.state.isShow2,
      id: id,
      number: num,
      age: age,
      type: type,
      caseRecord: caseRecord,
      appointment_time,
    });
  };
  componentDidMount = () => {
    this.props.loadForPatient(this.props.id);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.tab == "prescriptions" && this.props.tab != prevProps.tab) {
      this.props.loadForPatient(this.props.id);
    }
  };

  render() {
    const { forPatient } = this.props;
    const monthName = (item) => item.is_completed;

    const result = _.groupBy(forPatient, monthName);
    const groupArrays = Object.keys(result).map((status) => {
      return {
        status,
        doc: result[status],
      };
    });
    return (
      <>
        <div class="sic-body">
          <>
            {!this.props.caseRecord ? (
              <p class="fill-case">Please fill Case Record Form first.</p>
            ) : !this.props.caseRecord.finish ? (
              <p class="fill-case">Please fill Case Record Form first.</p>
            ) : !this.props.caseRecord.finish[
                this.props.doctorProfile &&
                this.props.doctorProfile.field_type &&
                this.props.doctorProfile.field_type.name === "Psychiatrist"
                  ? "diagnosisPsychiatrist"
                  : "diagnosis"
              ] ? (
              <p class="fill-case">Please fill Case Record Form first.</p>
            ) : !this.props.caseRecord.finish[
                this.props.doctorProfile &&
                this.props.doctorProfile.field_type &&
                this.props.doctorProfile.field_type.name === "Psychiatrist"
                  ? "diagnosisPsychiatrist"
                  : "diagnosis"
              ].diagnosis ||
              this.props.caseRecord.finish[
                this.props.doctorProfile &&
                this.props.doctorProfile.field_type &&
                this.props.doctorProfile.field_type.name === "Psychiatrist"
                  ? "diagnosisPsychiatrist"
                  : "diagnosis"
              ].diagnosis.length === 0 ? (
              <p class="fill-case">Please fill Case Record Form first.</p>
            ) : (
              <>
                {groupArrays.length === 0 && "No previous appointment found."}
                {groupArrays.map((e2, i) => (
                  <>
                    {e2.status === "false" && (
                      <>
                        {e2.doc.map((e1, i) => (
                          <>
                            <div class="tpc-month-box">
                              <h6>
                                {Moment(e1.date.replace("-", "/")).format(
                                  "DD-MMM-YYYY"
                                )}
                              </h6>
                            </div>
                            <div class="simple-links-box">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  let findData = groupArrays.find(
                                    (ele) => ele.status === 'true',
                                  )

                                  const length =
                                    findData && findData.doc
                                      ? findData.doc.length
                                      : 0
                                  this.toggle2(
                                    e1._id,
                                    e2.doc.length - i + length,
                                    this.props.age,
                                    e1.doctor_id.field_type &&
                                      e1.doctor_id.field_type.name ===
                                        "Psychiatrist"
                                      ? "Psychiatrist"
                                      : "Psychologist",
                                    e1.doctor_id.field_type &&
                                      e1.doctor_id.field_type.name ===
                                        "Psychiatrist"
                                      ? Object.keys(e1.caseRecord.finish)
                                          .length &&
                                          e1.caseRecord.finish
                                            .diagnosisPsychiatrist.diagnosis
                                            .length > 0 &&
                                          e1.caseRecord.finish.diagnosisPsychiatrist.diagnosis.map(
                                            (ed) => ed.value
                                          )
                                      : Object.keys(e1.caseRecord.finish)
                                          .length &&
                                          e1.caseRecord.finish.diagnosis
                                            .diagnosis.length > 0 &&
                                          e1.caseRecord.finish.diagnosis.diagnosis.map(
                                            (ed) => ed.value
                                          ),
                                    e1.appointment_time
                                  );
                                }}
                                class="slb-link-box"
                                id="prescriptions10"
                                data-toggle="prescriptions_side_panel_1"
                              >
                                <div class="slb-box d-flex flex-wrap">
                                  <div class="slb-img">
                                    <img src={file} alt="" />
                                  </div>
                                  <div class="slb-text">
                                    <div class="d-flex align-items-center">
                                      <h3>
                                        Prescriptions{" "}
                                        {moment(e1.appointment_time)
                                          .utc()
                                          .format("LT")}{" "}
                                      </h3>
                                      <div class="ml-auto mr-2">
                                        <span class="new-tags">Incomplete</span>
                                      </div>
                                    </div>
                                    <p>
                                      <strong> {e1.doctor_id.name}</strong>,{" "}
                                      {e1.doctor_id.field_type.name}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </>
            )}
          </>
          {groupArrays.map((e2, i) => (
            <>
              {e2.status === "true" && (
                <div class="accordion" id="accordion1">
                  {e2.doc.map((e1, i) => (
                    <>
                      <div class="tpc-month-box">
                        <h6>
                          {Moment(e1.date.replace("-", "/")).format(
                            "DD-MMM-YYYY"
                          )}
                        </h6>
                      </div>
                      <div class="item">
                        <div
                          className={
                            this.state.detail === true &&
                            this.state.list_id == e1._id
                              ? "ih-box"
                              : "ih-box collapsed"
                          }
                          data-toggle="collapse"
                          data-target="#accor6"
                          aria-expanded={
                            (this.state.detail === true &&
                              this.state.list_id == e1._id &&
                              "true") ||
                            (this.state.detail === false && "false")
                          }
                        >
                          <div class="slb-box d-flex flex-wrap">
                            <div class="slb-img">
                              <img src={file} alt="" />
                            </div>
                            <div class="slb-text">
                              <h3>
                                {" "}
                                Prescriptions{" "}
                                {moment(e1.appointment_time)
                                  .utc()
                                  .format("LT")}{" "}
                              </h3>
                              <p>
                                <strong>{e1.doctor_id.name}</strong>,{" "}
                                {e1.doctor_id.field_type.name}
                              </p>
                            </div>
                          </div>
                          <div class="slb-icons-outer d-flex align-items-center justify-content-end">
                            {moment().diff(
                              moment(e1.prescription.submited_at),
                              "minutes"
                            ) < 60 && (
                              <i
                                class="fa fa-edit mr-2"
                                aria-hidden="true"
                                onClick={() => {
                                  let findData = groupArrays.find(
                                    (ele) => ele.status === 'true',
                                  )
                                  const length1 =
                                    findData && findData.doc
                                      ? findData.doc.length
                                      : 0
                                  this.toggle2(
                                    e1._id,
                                    e2.doc.length - i+length1,
                                    e1.patient_id.date_of_birth,
                                    e1.doctor_id.field_type &&
                                      e1.doctor_id.field_type.name ===
                                        "Psychiatrist"
                                      ? "Psychiatrist"
                                      : "Psychologist",
                                    Object.keys(e1.caseRecord.finish).length &&
                                      e1.caseRecord.finish.diagnosis.diagnosis
                                        .length > 0 &&
                                      e1.caseRecord.finish.diagnosis.diagnosis.map(
                                        (ed) => ed.value
                                      ),
                                    e1.appointment_time
                                  );
                                }}
                              ></i>
                            )}
                            <span
                              class="icon"
                              onClick={() => this.toggleDetail(e1._id)}
                            >
                              <i>
                                <img src={down} alt="" />
                              </i>
                            </span>
                          </div>
                        </div>
                        <div
                          id="accor6"
                          className={
                            (this.state.detail === true &&
                              this.state.list_id == e1._id &&
                              "collapse show") ||
                            (this.state.detail === false && "collapsing")
                          }
                          data-parent="#accordion1"
                        >
                          {this.state.list_id == e1._id && (
                            <>
                              <div class="t-p">
                                <div class="sidebar-info-box d-flex flex-wrap">
                                  <div class="sib-left">
                                    <h5>
                                      {e1.patient_id.first_name +
                                        " " +
                                        e1.patient_id.last_name}
                                    </h5>
                                    <p>
                                      Age:{" "}
                                      {moment().diff(
                                        e1.patient_id.date_of_birth,
                                        "years"
                                      )}{" "}
                                      yrs
                                    </p>
                                    {<p> Gender: {this.props.sex}</p>}
                                  </div>
                                  <div class="sib-right">
                                    <h5>
                                      <small>
                                        UHID:{" "}
                                        {
                                          e1.patient_id
                                            .yesmindy_identification_number
                                        }
                                      </small>
                                    </h5>
                                    <p>
                                      Date:{" "}
                                      {moment(e1.appointment_time)
                                        .utc()
                                        .format("lll")}
                                    </p>
                                  </div>
                                </div>
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  <div class="scb-left">
                                    <p>Duration of session</p>
                                    <h6>
                                      {e1.prescription.duration_of_session}
                                    </h6>
                                  </div>
                                  <div class="scb-right">
                                    <p>Session participants</p>
                                    <h6>
                                      <b>
                                        {e1.prescription.session_participants &&
                                          e1.prescription.session_participants.join(
                                            ", "
                                          )}
                                      </b>
                                      {/* {
                                              e1.prescription
                                                .session_participants
                                            } */}
                                    </h6>
                                  </div>
                                </div>
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  {/* <div class="scb-left">
                                          <p>Chief complaint with duration</p>
                                          <h6>
                                            {
                                              e1.prescription
                                                .cheif_complaints_with_duration
                                            }
                                          </h6>
                                        </div> */}
                                  <div class="scb-right">
                                    <p>Diagnosis</p>
                                    <h6>
                                      {e1.prescription.diagnosis &&
                                        e1.prescription.diagnosis.replace(
                                          ",",
                                          ", "
                                        )}
                                    </h6>
                                  </div>
                                </div>
                                {e1.doctor_id.field_type.name ===
                                  "Psychologist" && (
                                  <div class="sidebar-content-box d-flex flex-wrap">
                                    <div class="scb-left">
                                      <p>Therapy Method</p>
                                      <h6>{e1.prescription.theray_method}</h6>
                                    </div>
                                    <div class="scb-right">
                                      <p>Therapy Technique</p>
                                      <h6>
                                        {e1.prescription.therapy_technique
                                          .map((et) => et.label)
                                          .join(", ")}
                                      </h6>
                                    </div>
                                  </div>
                                )}
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  <div class="scb-right">
                                    <p>Chief Complaints</p>
                                    <h6>{e1.prescription.chief_complaints}</h6>
                                  </div>
                                  {e1.doctor_id.field_type.name ===
                                    "Psychologist" && (
                                    <div class="scb-right">
                                      <p>Key issue/Themes</p>
                                      <h6>{e1.prescription.key_issue}</h6>
                                    </div>
                                  )}
                                </div>
                                {e1.doctor_id.field_type.name ===
                                "Psychologist" ? (
                                  <div></div>
                                ) : (
                                  <div class="sidebar-bg-box">
                                    <div class="box-h-line">
                                      <span>Rx</span>
                                    </div>
                                    <table class="table small-table table-responsive">
                                      <thead>
                                        <tr>
                                          <th>Formulation</th>
                                          <th>Composition</th>
                                          <th>Brand</th>
                                          <th>Dose</th>
                                          <th>Frequency</th>
                                          <th>Days</th>
                                          <th width="20%">Note</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {e1.prescription.medical_prescription.map(
                                          (e3, i) => (
                                            <tr>
                                              <td>{e3.formulation}</td>
                                              <td>{e3.composition}</td>
                                              <td>{e3.brand}</td>
                                              <td>{e3.dose}</td>
                                              <td>
                                                {e3.frequency &&
                                                  e3.frequency.map((em) => (
                                                    <>
                                                      {em.label?em.label:em.value}
                                                      <br />
                                                    </>
                                                  ))}
                                              </td>
                                              <td>{e3.days}</td>
                                              <td>{e3.notes}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                    {/* {e1.prescription.medical_prescription.map(
                                      (e3, i) => (
                                        <ul class="sidebar-content-list d-flex flex-wrap">
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Formulation</p>
                                              <h6>{e3.formulation}</h6>
                                            </div>
                                          </li>
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Composition</p>
                                              <h6>{e3.composition}</h6>
                                            </div>
                                          </li>
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Brand</p>
                                              <h6>{e3.brand}</h6>
                                            </div>
                                          </li>

                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Dose</p>
                                              <h6>{e3.dose}</h6>
                                            </div>
                                          </li>
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Frequency</p>
                                              {e3.frequency &&
                                                e3.frequency.map((em) => (
                                                  <>
                                                    {em.value}
                                                    <br />
                                                  </>
                                                ))}
                                              <div class="scb-content d-flex align-items-center justify-content-end">
                                                <div class="scb-img">
                                                  <img
                                                    src="include/images/arrow-down-icon.svg"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Days</p>
                                              <h6>{e3.days}</h6>
                                            </div>
                                          </li>
                                          <li class="sidebar-content-item">
                                            <div class="sidebar-content-box">
                                              <p>Note</p>
                                              <h6>{e3.notes}</h6>
                                            </div>
                                          </li>
                                        </ul>
                                      )
                                    )} */}
                                  </div>
                                )}

                                <div class="accor-seprator"></div>
                                {e1.doctor_id.field_type.name ===
                                "Psychologist" ? (
                                  <>
                                    {/* <div class="sidebar-content-box d-flex flex-wrap">
                                      <div class="scb-left">
                                        <p>Technique I would use (Optional)</p>
                                        <h6>
                                          {e1.prescription.technique.join(
                                            ",  "
                                          )}
                                        </h6>
                                      </div>
                                      <div class="scb-right">
                                        <p>
                                          Medicinal Plan I would use (Optional)
                                        </p>
                                        <h6>
                                          {e1.prescription.medical_plan.join(
                                            ",  "
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="sidebar-content-box d-flex flex-wrap">
                                      <div class="scb-left">
                                        <p>
                                          Conduct Following Assessments
                                          (Optional)
                                        </p>
                                        <h6>
                                          {e1.prescription.conduct.join(",  ")}
                                        </h6>
                                      </div>
                                    </div> */}
                                  </>
                                ) : (
                                  <div class="sidebar-content-box d-flex flex-wrap">
                                    <div class="scb-left">
                                      <p>Radiology</p>
                                      <h6>
                                        {e1.prescription.radiology
                                          .map((rs) => rs.label)
                                          .join(",  ")}
                                      </h6>
                                    </div>
                                    <div class="scb-right">
                                      <p>Lab Investigation</p>
                                      <h6>
                                        {e1.prescription.lab_investigations
                                          .map((rs) => rs.label)
                                          .join(",  ")}
                                      </h6>
                                    </div>
                                  </div>
                                )}
                                <div class="accor-seprator"></div>
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  <div class="scb-left">
                                    <p>Plan for next session</p>
                                    <h6>
                                      {
                                        e1.prescription
                                          .plan_for_the_next_session
                                      }
                                    </h6>
                                  </div>
                                  <div class="scb-right">
                                    <p>
                                      {e1.doctor_id.field_type.name ===
                                      "Psychologist"
                                        ? "Task allotted"
                                        : "Special instructions"}
                                    </p>
                                    <h6>{e1.prescription.special_advice}</h6>
                                  </div>
                                </div>
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  <div class="scb-left">
                                    <p>Follow up session</p>
                                    <h6>{e1.prescription.followup_session}</h6>
                                  </div>
                                  <div class="scb-right">
                                    <p>Referral, if any</p>
                                    <h6>{e1.prescription.referral}</h6>
                                  </div>
                                </div>
                                <div class="sidebar-content-box d-flex flex-wrap">
                                  <div class="scb-left">
                                    <p>
                                      {e1.doctor_id.field_type.name ===
                                      "Psychologist"
                                        ? "Others"
                                        : "To chemist"}
                                    </p>
                                    <h6>{e1.prescription.to_chemist}</h6>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </>
          ))}
        </div>
        <IncompletePrescription
          time={this.state.appointment_time}
          number={this.state.number}
          yesmindy_identification_number={
            this.props.yesmindy_identification_number
          }
          age={this.state.age}
          sex={this.props.sex}
          toggle1={this.props.toggle1}
          first_name={this.props.first_name}
          last_name={this.props.last_name}
          image={this.props.image}
          star={this.props.star}
          isShow2={this.state.isShow2}
          toggle2={this.toggle2}
          id={this.state.id}
          patient_id={this.props.id}
          type={this.state.type}
          caseRecord={
            this.state.caseRecord ? this.state.caseRecord.join(", ") : ""
          }
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadForPatient: (id) => dispatch(loadForPatient(id)),
  LoadpatientDetail: (id) => dispatch(patientDetail(id)),
});
const mapStateToProps = (state) => ({
  forPatient: getPrescription(state).forPatient,
  patientDetailData: getPatient(state).patientDetail,
  doctorProfile: getDoctor(state).profile,
});
export default connect(mapStateToProps, mapDispatchToProps)(ListPrescription);
