import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'assessment',
  initialState: {
    assessment: [],
    detail: {},
    answer: {},
    add: {},
    assign: {},
    submitted: [],
    task: [],
    assessmentTask: [],
    loading: false,
    answerLoading: true,
    lastFetch: null,
  },
  reducers: {
    assessmentRequested: (assessment, action) => {
      assessment.loading = true
    },
    assessmentReceived: (assessment, action) => {
      assessment.assessment = action.payload.data
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },
    assessmentRequestFailed: (assessment, action) => {
      assessment.loading = false
    },

    taskRequested: (assessment, action) => {
      assessment.loading = true
    },
    taskReceived: (assessment, action) => {
      assessment.task = action.payload.data
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },
    taskRequestFailed: (assessment, action) => {
      assessment.loading = false
    },

    assessmentTaskRequested: (assessment, action) => {
      assessment.loading = true
    },
    assessmentTaskReceived: (assessment, action) => {
      // assessment.assessmentTask = action.payload.data;
      assessment.assessmentTask = action.payload
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },
    assessmentTaskRequestFailed: (assessment, action) => {
      assessment.loading = false
    },
    assessmentSubmittedRequested: (assessment, action) => {
      assessment.loading = true
    },

    assessmentSubmittedReceived: (assessment, action) => {
      assessment.submitted = action.payload.data
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },

    assessmentSubmittedRequestFailed: (assessment, action) => {
      assessment.loading = false
    },
    taskSubmittedRequested: (assessment, action) => {
      assessment.loading = true
    },

    taskSubmittedReceived: (assessment, action) => {
      assessment.task = action.payload.data
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },

    taskSubmittedRequestFailed: (assessment, action) => {
      assessment.loading = false
    },
    assessmentDetailRequested: (assessment, action) => {
      assessment.loading = true
    },

    assessmentDetailReceived: (assessment, action) => {
      assessment.detail = action.payload
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },

    assessmentDetailRequestFailed: (assessment, action) => {
      assessment.loading = false
    },
    assessmentAnswerRequested: (assessment, action) => {
      assessment.answerLoading = true
    },

    assessmentAnswerReceived: (assessment, action) => {
      assessment.answer = action.payload
      assessment.answerLoading = false
      assessment.lastFetch = Date.now()
    },

    assessmentAnswerRequestFailed: (assessment, action) => {
      assessment.answerLoading = false
    },
    assessmentAnswerAdded: (assessment, action) => {
      assessment.add = action.payload
      assessment.answerLoading = false
      assessment.lastFetch = Date.now()
    },

    assessmentAnswerAddRequestFailed: (assessment, action) => {
      assessment.answerLoading = false
    },
    assignTask: (assessment, action) => {
      assessment.assign = action.payload
      assessment.loading = false
      assessment.lastFetch = Date.now()
    },

    assignTaskRequestFailed: (assessment, action) => {
      assessment.loading = false
    },
  },
})

export const {
  taskRequested,
  taskReceived,
  taskRequestFailed,
  assessmentTaskRequested,
  assessmentTaskReceived,
  assessmentTaskRequestFailed,
  assessmentSubmittedRequested,
  assessmentSubmittedReceived,
  assessmentSubmittedRequestFailed,
  taskSubmittedRequested,
  taskSubmittedReceived,
  taskSubmittedRequestFailed,
  assessmentRequested,
  assessmentReceived,
  assessmentRequestFailed,
  assessmentDetailRequested,
  assessmentAnswerAdded,
  assessmentAnswerAddRequestFailed,
  assessmentDetailReceived,
  assessmentDetailRequestFailed,
  assessmentAnswerRequested,
  assessmentAnswerReceived,
  assessmentAnswerRequestFailed,
  assignTaskRequestFailed,
  assignTask,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'assesment/'
const url1 = 'assessment-answer'
const url2 = 'assessment/submited/records'
const url3 = 'assessment/submited/tasks'
const url4 = 'assessment/task/list'

export const loadSubmittedAssessment = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url2,
      params,
      onStart: assessmentSubmittedRequested.type,
      onSuccess: assessmentSubmittedReceived.type,
      onError: assessmentSubmittedRequestFailed.type,
    })
  )
}
export const loadSubmittedTask = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: 'assessment/submited/recordsForDoctor',
      params,
      onStart: taskSubmittedRequested.type,
      onSuccess: taskSubmittedReceived.type,
      onError: taskSubmittedRequestFailed.type,
    })
  )
}
export const loadAllAssessment = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url,
      params,
      onStart: assessmentRequested.type,
      onSuccess: assessmentReceived.type,
      onError: assessmentRequestFailed.type,
    })
  )
}

export const loadAllTasks = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + 'taskList',
      params,
      onStart: taskRequested.type,
      onSuccess: taskReceived.type,
      onError: taskRequestFailed.type,
    })
  )
}
export const fetchAllAssessmentTask = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url4,
      params,
      onStart: assessmentTaskRequested.type,
      onSuccess: assessmentTaskReceived.type,
      onError: assessmentTaskRequestFailed.type,
    })
  )
}
export const loadDetailAssessment = id => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      onStart: assessmentDetailRequested.type,
      onSuccess: assessmentDetailReceived.type,
      onError: assessmentDetailRequestFailed.type,
    })
  )
}
export const assignAssessment = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url + '/task/assign',
      method: 'post',
      data: param,
      onSuccess: assignTask.type,
      onError: assignTaskRequestFailed.type,
    })
  )
}
export const loadAnswerAssessment = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1,
      params,
      onStart: assessmentAnswerRequested.type,
      onSuccess: assessmentAnswerReceived.type,
      onError: assessmentAnswerRequestFailed.type,
    })
  )
}
export const addAnswerAssessment = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url1,
      method: 'post',
      data: param,
      onSuccess: assessmentAnswerAdded.type,
      onError: assessmentAnswerAddRequestFailed.type,
    })
  )
}

export const addAnswerAssessmentDoctor = (param, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url1 + '/doctor',
      method: 'post',
      data: param,
      onSuccess: assessmentAnswerAdded.type,
      onError: assessmentAnswerAddRequestFailed.type,
    })
  )
}

export const getAssessment = createSelector(
  state => state.entities.assessment,
  assessment => assessment
)
