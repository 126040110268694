import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import JsFileDownloader from 'js-file-downloader'

import {
  getDocument,
  loadDocument,
  shareDocument,
  deletedDocument,
} from '../../../../store/document'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import { toast } from 'react-toastify'
import doc from '../../../../include/images/document-img1.svg'
import closeIcon from '../../../../include/images/close.svg'

import pdf from '../../../../include/images/pdf-documnet.svg'
import image2 from '../../../../include/images/document-img-2.svg'
import { getPatient, loadPatientProfile } from '../../../../store/patient'
import { Modal } from 'react-bootstrap'

class MyReport extends Component {
  state = {
    downloading: false,
    deletePopup: false,
    dropdown: false,
    id: '',
    share_id: '',
    doc: '',
    doctor_id: '',
    patient_id: '',
    params: '0',
  }

  downloadFile = fileUrl => {
    if (!this.state.downloading) {
      this.setState({ downloading: true })
      new JsFileDownloader({
        url: fileUrl,
      })
        .then(() => {
          this.setState({ downloading: false })
          // Called when download ended
          toast(<AlertSuccess message={'Document downloaded successfully.'} />)
        })
        .catch(error => {
          this.setState({ downloading: false })
          // Called when an error occurred
          toast(
            <AlertError
              message={'Something went wrong while downloading file.'}
            />
          )
        })
    }
  }
  toggleDeletePop = deletePopup => this.setState({ deletePopup })
  componentDidMount = () => {
    this.props.loadDocument()
    this.props.loadPatientProfile()
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.doctor_id && this.state.doctor_id != prevState.doctor_id) {
      this.props.loadDocument({ doctor_id: this.state.doctor_id })
    } else if (
      this.state.patient_id &&
      this.state.patient_id != prevState.patient_id
    ) {
      this.props.loadDocument({ patient_id: this.state.patient_id })
    } else if (this.state.params && this.state.params != prevState.params) {
      this.props.loadDocument()
    }
  }
  share = id => {
    this.props.shareDocument(id, this.shareCallback)
  }
  shareCallback = res => {
    if (res && res.status === 200) {
      this.props.loadDocument()
      toast(<AlertSuccess message={'Document Shared Successfully.'} />)
    } else {
      this.props.loadDocument()
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  delete = id => {
    this.props.deletedDocument(id, this.deleteCallback)
  }
  deleteCallback = res => {
    if (res && res.status === 200) {
      this.props.loadDocument()
      toast(<AlertSuccess message={'Document Deleted Successfully.'} />)
    } else {
      this.props.loadDocument()
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  toggleModel = (fileData = null, val) => {
    this.setState({
      showPreview: val,
      fileObj: fileData,
    })
  }
  render() {
    const { document, patientProfile } = this.props
    const category = item => item.category.name
    const result = _.groupBy(document, category)
    const groupArrays = Object.keys(result).map(category => {
      return {
        category,
        doc: result[category],
      }
    })

    return (
      <>
        {this.state.showPreview && (
          <Modal
            fullscreen={true}
            aria-labelledby='contained-modal-title-vcenter'
            dialogClassName='modal-dialog pdf-model'
            className='modal fade pdf-model'
            show={this.state.showPreview}
            onHide={() => this.toggleModel(null, false)}
            style={{ paddingLeft: 0 }}>
            <Modal.Header closeButton></Modal.Header>
            <div>
              {this.state.fileObj && this.state.fileObj.type == 'pdf' ? (
                <iframe
                  src={this.state.fileObj.fileUrl}
                  width='100%'
                  style={{ height: '100vh' }}
                />
              ) : (
                <img src={this.state.fileObj.fileUrl} alt='image' />
              )}
            </div>
          </Modal>
        )}
        {/* <div
          class="tab-pane card fade show active"
          id="myreport"
          role="tabpanel"
          aria-labelledby="myreport-tab"
        >
          <div class="card-header">My Reports</div>
          <div class="content-body show"> */}
        <div className='cb-outer'>
          <div className='filter-content-box d-md-flex flex-wrap align-items-center'>
            <div className='filter-text-box'>
              <h6>Filters:</h6>
            </div>
            <div className='filter-check-box'>
              <ul className='filter-check-list'>
                <li className='filter-check-item'>
                  <div className='form-group preferred-field'>
                    <div className='custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0'>
                      {patientProfile.Psychiatrist && (
                        <div className='custom-control custom-radio'>
                          <input
                            type='radio'
                            className='custom-control-input'
                            id={patientProfile.Psychiatrist}
                            name='Psychiatrist'
                            onClick={() => {
                              this.setState({
                                doctor_id: patientProfile.Psychiatrist,
                                patient_id: '',
                                params: '',
                              })
                            }}
                            checked={
                              this.state.doctor_id ===
                              patientProfile.Psychiatrist
                            }
                          />
                          <label
                            className='custom-control-label'
                            htmlFor={patientProfile.Psychiatrist}>
                            By Psychiatrist
                          </label>
                        </div>
                      )}
                      {patientProfile.Psychologist && (
                        <div className='custom-control custom-radio'>
                          <input
                            type='radio'
                            className='custom-control-input'
                            id={patientProfile.Psychologist}
                            name='Psychologist'
                            onClick={() => {
                              this.setState({
                                doctor_id: patientProfile.Psychologist,
                                patient_id: '',
                                params: '',
                              })
                            }}
                            checked={
                              this.state.doctor_id ===
                              patientProfile.Psychologist
                            }
                          />
                          <label
                            className='custom-control-label'
                            htmlFor={patientProfile.Psychologist}>
                            By Psychologist
                          </label>
                        </div>
                      )}
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id={patientProfile._id}
                          name='me'
                          onClick={() => {
                            this.setState({
                              patient_id: patientProfile._id,
                              doctor_id: '',
                              params: '',
                            })
                          }}
                          checked={this.state.patient_id === patientProfile._id}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor={patientProfile._id}>
                          By Me
                        </label>
                      </div>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='0'
                          name='other'
                          onClick={() => {
                            this.setState({
                              params: '0',
                              doctor_id: '',
                              patient_id: '',
                            })
                          }}
                          checked={this.state.params === '0'}
                        />
                        <label className='custom-control-label' htmlFor='0'>
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class='tab-pane-content-box tpc-report'>
          <div class='tpc-lower'>
            {groupArrays.length === 0 ? (
              <div className='document-content-box '>No record found.</div>
            ) : (
              <>
                {groupArrays.map((e2, i) => (
                  <div className='document-content-box '>
                    <div className='tpc-month-box'>
                      <h6>{e2.category}</h6>
                    </div>
                    <ul className='documents-list  d-flex  flex-wrap'>
                      {e2.doc.map((e1, i) => (
                        <li
                          className='document-item'
                          onClick={() => {
                            this.setState({
                              id: 0,
                            })
                          }}>
                          <div className='document-box'>
                            <div className='document-img-box'>
                              <span className='document-intro'>
                                {e1.date_added}
                              </span>
                              <div className='document-dropdown'>
                                <div
                                  className={
                                    this.state.dropdown === true &&
                                    this.state.id === e1._id
                                      ? 'dropright show'
                                      : 'dropright'
                                  }>
                                  <a
                                    className='dropdown-toggle'
                                    href='javascript:void(0)'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setState({
                                        dropdown: true,
                                        id: e1._id,
                                      })
                                    }}>
                                    <i className='fas fa-ellipsis-v'></i>
                                  </a>
                                  <div
                                    className={
                                      this.state.dropdown === true &&
                                      this.state.id === e1._id
                                        ? 'dropdown-menu dropdown-custom show'
                                        : 'dropdown-menu dropdown-custom'
                                    }
                                    style={{
                                      'position': 'absolute',
                                      'top': '0px',
                                      'right': '0px',
                                      'will-change': 'transform',
                                    }}
                                    x-placement='right-start'>
                                    <a
                                      className='dropdown-item'
                                      href='javascript:void(0)'
                                      onClick={() => {
                                        this.share(e1._id)
                                      }}>
                                      Share
                                    </a>

                                    <a
                                      className='dropdown-item'
                                      href='#'
                                      target='_blank'
                                      onClick={ele => {
                                        ele.preventDefault()
                                        this.downloadFile(
                                          process.env.REACT_APP_S3URL + e1.file
                                        )
                                      }}>
                                      Download
                                    </a>
                                    <a
                                      className='dropdown-item'
                                      href='javascript:void(0)'
                                      onClick={() => {
                                        this.toggleDeletePop(e1._id)
                                      }}>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='document-img'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.toggleModel(
                                    {
                                      fileUrl:
                                        process.env.REACT_APP_S3URL + e1.file,
                                      type:
                                        e1.file.split('.').pop() === 'pdf'
                                          ? 'pdf'
                                          : 'image',
                                    },
                                    true
                                  )
                                }}>
                                {e1.file.split('.').pop() === 'pdf' && (
                                  <img src={pdf} alt='' />
                                )}
                                {e1.file.split('.').pop() === 'jpeg' && (
                                  <img src={image2} alt='' />
                                )}
                                {e1.file.split('.').pop() === 'jpg' && (
                                  <img src={image2} alt='' />
                                )}
                                {e1.file.split('.').pop() === 'png' && (
                                  <img src={image2} alt='' />
                                )}
                                {e1.file.split('.').pop() != 'png' &&
                                  e1.file.split('.').pop() != 'jpg' &&
                                  e1.file.split('.').pop() != 'jpeg' &&
                                  e1.file.split('.').pop() != 'pdf' && (
                                    <img src={doc} alt='' />
                                  )}
                              </div>
                            </div>
                            <h4>{e1.document_title}</h4>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/* </div>
        </div> */}
        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.deletePopup}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.toggleDeletePop(false)
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <h4
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                Are you sure you want to delete?
              </h4>

              <div class='modal-coustom-btn d-flex align-items-center justify-content-center'>
                <a
                  href='#!'
                  onClick={e => {
                    this.delete(this.state.deletePopup)
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}
                  class='btn btn-primary'>
                  Delete
                </a>
                <a
                  href='#!'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}>
                  Cancel
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  document: getDocument(state).document,
  patientProfile: getPatient(state).profile,
})
const mapDispatchToProps = dispatch => ({
  loadDocument: params => dispatch(loadDocument(params)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  shareDocument: (id, callbackShare) =>
    dispatch(shareDocument(id, callbackShare)),
  deletedDocument: (id, callbackDelete) =>
    dispatch(deletedDocument(id, callbackDelete)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyReport)
)
