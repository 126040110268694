export const past = [
  { Past: 'Childhood History' },
  { Past: 'Substance History' },
  { Past: 'Personal History' },
  { Past: 'Personality' },
  { Past: 'Temperament' },
  { Past: 'Physical Examination' },
  { Past: 'MMSE' },
  { Past: 'Behavioural Observation' },
  { Past: 'Sexual History' },
]
export const home = [
  { Home: 'Healthy' },
  { Home: 'Disturbed' },
  { Home: 'Crowded' },
  { Home: 'Congenial' },
  { Home: 'Non-congenial' },
]
export const parenting = [
  { Parenting: 'Permissive' },
  { Parenting: 'Rigid' },
  { Parenting: 'Consistent' },
  { Parenting: 'Inconsistent' },
  { Parenting: 'Strict' },
  { Parenting: 'Liberal' },
  { Parenting: 'Protective' },
  { Parenting: 'Non-Protective' },
  { Parenting: 'Disapproval' },
  { Parenting: 'High Expectations' },
]
export const scholastic = [
  { Scholastic: 'Poor' },
  { Scholastic: 'Average' },
  { Scholastic: 'Above Average' },
  { Scholastic: 'Good' },
]
export const social = [
  { Social: 'Poor' },
  { Social: 'Adequate' },
  { Social: 'Poor relations with authority' },
  { Social: 'Good' },
  { Social: 'Poor peer adjustments' },
]
export const play = [
  { Play: 'Enjoys' },
  { Play: 'Not Interested' },
  { Play: 'Observes Others' },
  { Play: 'Plays Alone' },
  { Play: 'Game rules not followed' },
]
export const type = [
  { Type: 'Alcohol' },
  { Type: 'Cannabis' },
  { Type: 'Brown Sugar' },
  { Type: 'Heroine' },
  { Type: 'Opioid' },
  { Type: 'Sedative/ Hypnotics' },
  { Type: 'Pain Killers' },
  { Type: 'Cocaine' },
  { Type: 'Benzo' },
  { Type: 'Drugs' },
  { Type: 'Cough Syrup' },
  { Type: 'PolySubstance' },
]
export const tolerance = [
  { Tolerance: 'Needing to consume more' },
  { Tolerance: 'Small Quantity' },
  { Tolerance: 'Gradually Increasing' },
]
export const dependence = [
  { Dependence: 'Eye Openers' },
  { Dependence: 'Daily' },
  { Dependence: 'Spending Excess Time and Energy' },
  { Dependence: 'Spending Excess Money' },
  { Dependence: 'No Pleasure without consumption' },
]
export const withdrawal = [
  { Withdrawal: 'NIL' },
  { Withdrawal: 'Mild' },
  { Withdrawal: 'Moderate' },
  { Withdrawal: 'Severe' },
  { Withdrawal: 'Irritability/ Aggressive' },
  { Withdrawal: 'Confusion' },
  { Withdrawal: 'Tremors' },
  { Withdrawal: 'Seizures' },
  { Withdrawal: 'Unconscious' },
  { Withdrawal: 'Anxiety' },
  { Withdrawal: 'Amotivation' },
  { Withdrawal: 'Mood Swings' },
]
export const cravings = [
  { Cravings: 'Strong' },
  { Cravings: 'Mild' },
  { Cravings: 'Not present' },
  { Cravings: 'Upon Exposure to cues' },
]
export const observations = [
  { Observations: 'Black outs' },
  { Observations: 'Binge Pattern' },
  { Observations: 'Medical Complications' },
  { Observations: 'Guilt' },
  { Observations: 'Society Pointing Out' },
  { Observations: 'Honeymoon Periods' },
  { Observations: 'Legal complications' },
  { Observations: 'Accidents' },
]
export const prenatal = [
  { Prenatal: 'Eventful' },
  { Prenatal: 'Uneventful' },
  { Prenatal: 'Not Sure' },
]
export const birthTerm = [
  { BirthTerm: 'Full Term' },
  { BirthTerm: 'Pre Term' },
  { BirthTerm: 'Post Term' },
  { BirthTerm: 'Not Known' },
]
export const birthPlace = [
  { BirthPlace: 'Home' },
  { BirthPlace: 'Hospital' },
  { BirthPlace: 'Others' },
]
export const deliveryType = [
  { DeliveryType: 'Normal' },
  { DeliveryType: 'Forceps' },
  { DeliveryType: 'Vaccum' },
  { DeliveryType: 'Caesarian' },
]
export const motivation = [
  { Motivation: 'Pre-Contemplation' },
  { Motivation: 'Contemplation' },
  { Motivation: 'Preparation' },
  { Motivation: 'Action' },
  { Motivation: 'Maintenance' },
  { Motivation: 'Termination' },
  { Motivation: 'Can’t Say' },
  { Motivation: 'Not Motivated' },
]
export const postnatal = [
  { Postnatal: 'Yes' },
  { Postnatal: 'No' },
  { Postnatal: 'Not Known' },
]
export const developmentalHistory = [
  { Development: 'Delayed' },
  { Development: 'Not Delayed' },
  { Development: 'Regression' },
]
export const immunization = [
  { Immunization: 'Complete' },
  { Immunization: 'Incomplete' },
  { Immunization: 'Not Sure' },
]
export const control = [
  { Control: 'External' },
  { Control: 'Internal' },
  { Control: 'Can’t Comment' },
]
export const score = [{ Score: 'Score 1' }, { Score: 'Score 0' }]
export const score1 = [
  { Score: 'Score 1' },
  { Score: 'Score 0' },
  { Score: 'Score 2' },
  { Score: 'Score 3' },
]
export const score2 = [
  { Score: 'Score 1' },
  { Score: 'Score 0' },
  { Score: 'Score 2' },
  { Score: 'Score 3' },
  { Score: 'Score 4' },
  { Score: 'Score 5' },
]
export const score3 = [
  { Score: 'Score 0/2' },
  { Score: 'Score 1/2' },
  { Score: 'Score 2/2' },
]
export const score4 = [
  { Score: 'Takes paper in correct hand. Score 1' },
  { Score: 'Folds It In half Score 2' },
  { Score: 'Puts it on the floor Score 3' },
]
export const physicalOthers = [
  { other: 'Pallor' },
  { other: 'Icterus' },
  { other: 'Clubbing' },
  { other: 'Cyanosis' },
  { other: 'Oedema' },
  { other: 'Dehydration' },
]
export const cnsvalue = [
  { CNS: 'Motor Intact' },
  { CNS: 'Sensory Intact' },
  { CNS: 'Cranial Nerves Intact' },
  { CNS: 'Reflexes Intact' },
  { CNS: 'Cortical Sensations Intact' },
]
export const resp = [
  { respiratory: 'Chest Clear' },
  { respiratory: 'B/L Air Entry Present' },
]
export const git = [
  { git: 'Abdomen Soft' },
  { git: 'Non Distend' },
  { git: 'Non Tender' },
  { git: 'Bowel Sounds Present' },
]
export const hmf = [
  { hmf: 'Conscious' },
  { hmf: 'Oriented to Time' },
  { hmf: 'Oriented to Place' },
  { hmf: 'Oriented to Person' },
  { hmf: 'Following Commands' },
  { hmf: 'Expression Intact' },
  { hmf: 'Comprehension Intact' },
]
export const activity = [
  { Activity: 'Active' },
  { Activity: 'Hyperactive' },
  { Activity: 'Inactive' },
  { Activity: 'Hardly Moves' },
  { Activity: 'Eating Still' },
  { Activity: 'Eating Moving About' },
]
export const attention = [
  { Attention: 'Easily Distracted' },
  { Attention: 'Goes Back To Same Act' },
  { Attention: 'Doesn’t Give Up Easily' },
  { Attention: 'Not Persistent' },
]
export const approach = [
  { Approach: 'Easily Gets Along' },
  { Approach: 'Frightened' },
  { Approach: 'Cries' },
  { Approach: 'Withdraws Physically' },
  { Approach: 'Goes Spontaneously' },
]
export const adaptability = [
  { Adaptability: 'Shy' },
  { Adaptability: 'Mixes Up Easily' },
  { Adaptability: 'Slow To Warm Up' },
  { Adaptability: 'Back To Routine Quickly' },
  { Adaptability: 'New Environment Excites' },
]
export const distractability = [
  { Distractability: 'Easily Distracted or Consoled' },
  { Distractability: 'Obeys Instructions Promptly' },
  { Distractability: 'Gets Engrossed Too Much' },
  { Distractability: 'Finishes What Started' },
  { Distractability: 'Switching Of Activities' },
]
export const intensity = [
  { Intensity: 'Not Much reaction' },
  { Intensity: 'Gets Excited Easily' },
  { Intensity: 'Roaring With Laughter' },
  { Intensity: 'Normal Ways Of Reaction' },
]
export const threshold = [
  { Threshold: 'Bothered Easily' },
  { Threshold: 'Ignores Stimuli Mostly' },
  { Threshold: 'Low Threshold' },
  { Threshold: 'High Threshold' },
]
export const moodQuality = [
  { Mood: 'Generally Happy' },
  { Mood: 'Generally Unhappy' },
  { Mood: 'Discontent' },
  { Mood: 'Angry' },
  { Mood: 'Frequent Crying' },
  { Mood: 'Irritable' },
  { Mood: 'Mostly laughing' },
]
export const rhythmicity = [
  { Rhythmicity: 'Regular Sleep' },
  { Rhythmicity: 'Regular Bowel' },
  { Rhythmicity: 'Regular Appetite' },
  { Rhythmicity: 'Always Variable' },
  { Rhythmicity: 'Predictable' },
]
export const birth_early = [
  { Early: 'Normal' },
  { Early: 'Antenatal Complications' },
  { Early: 'Postnatal Complications' },
]
export const disorder = [
  { Disorder: 'No Issues' },
  { Disorder: 'Present' },
  { Disorder: 'ADHD' },
  { Disorder: 'Autism' },
  { Disorder: 'Poor learning skills' },
  { Disorder: 'Conduct' },
  { Disorder: 'Antisocial' },
  { Disorder: 'Poor Social Life' },
  { Disorder: 'Eating Difficulties' },
  { Disorder: 'Fears' },
  { Disorder: 'Attention seeking' },
  { Disorder: 'Temper Tantrums' },
  { Disorder: 'Tics' },
  { Disorder: 'Enuresis' },
  { Disorder: 'Encopresis' },
]
export const family = [
  { Family: 'Healthy' },
  { Family: 'Congenial' },
  { Family: 'Disturbed' },
  { Family: 'Non- Congenial' },
  { Family: 'Adopted' },
  { Family: 'Parents separated' },
  { Family: 'Crowded' },
  { Family: 'Step Parent' },
]
export const extracurricular = [
  { Extracurricular: 'Good' },
  { Extracurricular: 'Average' },
  { Extracurricular: 'Below Average' },
  { Extracurricular: 'Poor' },
  { Extracurricular: 'Disciplinary Issues' },
  { Extracurricular: 'Relationship issues with peers' },
]
export const occupation = [
  { Occupation: 'Stable Job' },
  { Occupation: 'Frequent Job Changes' },
  { Occupation: 'Relationship Issues with Colleagues' },
  { Occupation: 'Promotion Stress' },
]
export const menstrual = [
  { Menstrual: 'Menopause Achieved' },
  { Menstrual: 'Regular Cycles' },
  { Menstrual: 'Delayed Cycles' },
  { Menstrual: 'Excess Pain' },
  { Menstrual: 'Excess Flow' },
  { Menstrual: 'Excess Mood Symptoms During Cycles' },
  { Menstrual: 'Eating Difficulties' },
  { Menstrual: 'Fears' },
  { Menstrual: 'Attention seeking' },
  { Menstrual: 'Temper Tantrums' },
  { Menstrual: 'Tics' },
  { Menstrual: 'Enuresis' },
  { Menstrual: 'Encopresis' },
]
export const sexual = [
  { Sexual: 'Normal' },
  { Sexual: 'Issues Present' },
  { Sexual: 'Disturbed marital life' },
  { Sexual: 'Sexual Issues' },
  { Sexual: 'Sexual Inadequacy Felt' },
  { Sexual: 'Difficulty Establishing Sexual Relation' },
]
export const forensic = [{ Forensic: 'Present' }, { Forensic: 'Not Present' }]
export const relation = [
  { Relation: 'Leader' },
  { Relation: 'Follower' },
  { Relation: 'Organizer' },
  { Relation: 'Aggressive' },
  { Relation: 'Submissive' },
  { Relation: 'Ambitious' },
  { Relation: 'Adjustable' },
  { Relation: 'Independent' },
]
export const hobbies = [
  { Hobbies: 'Music' },
  { Hobbies: 'Reader' },
  { Hobbies: 'Good Orator' },
  { Hobbies: 'Philosophical' },
  { Hobbies: 'Calculative' },
  { Hobbies: 'Prudent' },
  { Hobbies: 'Traveller' },
  { Hobbies: 'Party Goer' },
  { Hobbies: 'Workaholic' },
]
export const mood = [
  { Mood: 'Bright' },
  { Mood: 'Cheerful' },
  { Mood: 'Despondent' },
  { Mood: 'Worrying' },
  { Mood: 'Placid' },
  { Mood: 'Optimistic' },
  { Mood: 'Pessimistic' },
  { Mood: 'Self Depreciative' },
  { Mood: 'Satisfied' },
  { Mood: 'Mood Unstable' },
]
export const character = [
  { Character: 'Welcomes Responsibility' },
  { Character: 'Worried by responsibility' },
  { Character: 'Haphazard' },
  { Character: 'Methodical' },
  { Character: 'Rigid' },
  { Character: 'Flexible' },
  { Character: 'Foresightful' },
  { Character: 'Impulsive' },
  { Character: 'Persevering' },
  { Character: 'Easily Bored' },
]
export const interpersonal = [
  { Interpersonal: 'Self Confident' },
  { Interpersonal: 'Shy' },
  { Interpersonal: 'Insensitive' },
  { Interpersonal: 'Sensitive to Criticism' },
  { Interpersonal: 'Trusting' },
  { Interpersonal: 'Suspicious' },
  { Interpersonal: 'Jealous' },
  { Interpersonal: 'Tolerant' },
  { Interpersonal: 'Intolerant' },
  { Interpersonal: 'Quiet' },
  { Interpersonal: 'Expressive' },
  { Interpersonal: 'Adaptable' },
  { Interpersonal: 'Inadaptable' },
]
export const morbid = [
  { Morbid: 'Traits' },
  { Morbid: 'Personality Disorder' },
  { Morbid: 'Paranoid' },
  { Morbid: 'Schizoid' },
  { Morbid: 'Dissocial' },
  { Morbid: 'Emotionally Unstable Impulsive' },
  { Morbid: 'Borderline' },
  { Morbid: 'Histrionic' },
  { Morbid: 'Anankastic' },
  { Morbid: 'Anxious, Avoidant' },
  { Morbid: 'Dependent' },
  { Morbid: 'Unspecified' },
  { Morbid: 'Mixed' },
]
export const matters = [
  { Matter: 'High Aspirations' },
  { Matter: 'Low Aspirations' },
  { Matter: 'Tolerant to Frustrations' },
  { Matter: 'Intolerant to frustrations' },
  { Matter: 'Selfish' },
  { Matter: 'Unselfish' },
  { Matter: 'Too Concerned' },
  { Matter: 'Concerned too Little' },
]
export const energy = [
  { Energy: 'Energetic' },
  { Energy: 'Sluggish' },
  { Energy: 'Output Sustained' },
  { Energy: 'Fitful' },
  { Energy: 'Fatiguability' },
  { Energy: 'Energy Fluctuations' },
]
export const fantasy = [
  { Fantasy: 'Imaginative Thinking' },
  { Fantasy: 'Realistic' },
  { Fantasy: 'Innovative' },
  { Fantasy: 'Creativity' },
  { Fantasy: 'Daydreaming' },
]
export const habits = [
  { Habits: 'Self Medication' },
  { Habits: 'Eating Fads' },
  { Habits: 'Substance Dependence' },
]
export const general = [
  { General: 'Tidy' },
  { General: 'Untidy' },
  { General: 'Kempt' },
  { General: 'Unkempt' },
  { General: 'Well Groomed' },
  { General: 'Over Groomed' },
]
export const general1 = [
  { General: 'Tidy' },
  { General: 'Untidy' },
  { General: 'Kempt' },
  { General: 'Unkempt' },
]
export const attitude_fir = [
  { First: 'Cooperative' },
  { First: 'Uncooperative' },
  { First: 'Sitting through consultation' },
  { First: 'Restless' },
  { First: 'Attentive' },
  { First: 'Evasive' },
  { First: 'Guarded' },
]
export const attitude_fir1 = [
  { First: 'Alright' },
  { First: 'Uncooperative' },
  { First: 'Sitting through consultation' },
  { First: 'Restless' },
  { First: 'Irritable' },
]
export const attitude_sec = [
  { Second: 'Relaxed' },
  { Second: 'Tensed' },
  { Second: 'Overfamilliar' },
  { Second: 'Hostile' },
  { Second: 'Irritable' },
  { Second: 'Disinhibited' },
  { Second: 'Withdrawn' },
]
export const speech1 = [
  { First: 'Relevant' },
  { First: 'Irrelevant' },
  { First: 'Coherent' },
  { First: 'Incoherent' },
  { First: 'Spontaneous' },
]
export const speech = [
  { First: 'Normal' },
  { First: 'Irrelevant' },
  { First: 'Incoherent' },
  { First: 'Loud' },
  { First: 'Increase rate' },
  { First: 'Decrease rate' },
]
export const speech2 = [
  { Second: 'Soft' },
  { Second: 'Loud' },
  { Second: 'Hoarse' },
  { Second: 'Spontaneous' },
  { Second: 'Goal Directed' },
]
export const attentions = [
  { First: '100-7' },
  { First: '40-3' },
  { First: 'Months/days of week backword' },
  ,
]
export const orient = [
  { First: 'Time' },
  { First: 'Place' },
  { First: 'Person' },
  { First: 'Passage of time' },
]
export const memory1 = [
  { First: 'Immediate' },
  { First: 'Recent' },
  { First: 'Remote' },
]
export const memory2 = [
  { Second: 'Digit Forword' },
  { Second: 'Digit Backward' },
  { Second: 'Recall Objects' },
  { Second: 'Remote Personal events recall' },
]
export const abstract1 = [
  { First: 'Proverb meaning' },
  { First: 'Similarity' },
  { First: 'Differences' },
]
export const abstract2 = [
  { Second: 'General Knowledge' },
  { Second: 'Calculation' },
]
export const stream = [
  { Stream: 'Normal' },
  { Stream: 'Accelerated' },
  { Stream: 'Pressured Speech' },
  { Stream: 'Flight of Ideas' },
  { Stream: 'Prolixity' },
  { Stream: 'Poverty of Speach' },
  { Stream: 'Circumstantiality' },
  { Stream: 'Tangentiality' },
  { Stream: 'Retardation' },
  { Stream: 'Perservation' },
  { Stream: 'Thought Blocking' },
  { Stream: 'Others' },
]
export const possession = [
  { Possession: 'Normal' },
  { Possession: 'Loosening of Association' },
  { Possession: 'Derailment' },
  { Possession: 'Word Salad' },
  { Possession: 'Neologism' },
  { Possession: 'Thought Broadcasting' },
  { Possession: 'Thought Withdrawal' },
  { Possession: 'Thought Insertion' },
  { Possession: 'Obsession' },
  { Possession: 'Others' },
]
export const content = [
  { Content: 'No Abnormality Detected' },
  { Content: 'Worry' },
  { Content: 'Phobia' },
  { Content: 'Somatic' },
  { Content: 'Persecutory ideas' },
  { Content: 'Ideas of reference' },
  { Content: 'Grandiosity' },
  { Content: 'Hopelessness' },
  { Content: 'Worthlessness' },
  { Content: 'Helplessness' },
  { Content: 'Guilt' },
  { Content: 'Regret' },
  { Content: 'Hypochondriasis' },
  { Content: 'Others' },
]
export const delusion = [
  { Delusion: 'Delusion of Infidelity' },
  { Delusion: 'Delusion of persecution' },
  { Delusion: 'Delusion of love' },
  { Delusion: 'Delusion of Control' },
  { Delusion: 'Other' },
]
export const perception1 = [
  { First: 'Auditory hallucination' },
  { First: 'Visual hallucination' },
  { First: 'Objective space' },
  { First: 'Diurnal Pattern' },
]
export const perception2 = [
  { Second: 'Imagery' },
  { Second: 'Illusion' },
  { Second: 'Pseudo-hallucination' },
]
export const perception3 = [
  { Third: 'Derealisation' },
  { Third: 'Depersonalisation' },
  { Third: 'Other' },
]
export const mood1 = [
  { First: 'Euthymic' },
  { First: 'Dysthymic' },
  { First: 'Euphoric' },
  { First: 'Ecstatic' },
]
export const mood2 = [
  { Second: 'Low' },
  { Second: 'Anxious' },
  { Second: 'Worry/stress' },
]
export const affect1 = [
  { First: 'Appropriate' },
  { First: 'Inappropriate' },
  { First: 'Congruent' },
  { First: 'Incongruent' },
]
export const affect2 = [
  { Second: 'Labile' },
  { Second: 'Communicable' },
  { Second: 'Non Communicable' },
]
export const affect3 = [
  { Third: 'Restricted' },
  { Third: 'Constricted' },
  { Third: 'Blunt' },
]
export const thought = [
  { Thought: 'Not Present' },
  { Thought: 'Circumstantiality' },
  { Thought: 'Perseveration' },
  { Thought: 'Loosening of association' },
  { Thought: 'Derailment' },
  { Thought: 'Thought withdrawal' },
  { Thought: 'Obsession' },
  { Thought: 'Thought insertion' },
  { Thought: 'Tangentiality' },
  { Thought: 'Poverty of speech' },
  { Thought: 'Retardation' },
  { Thought: 'Thought blocking' },
  { Thought: 'Flight of Ideas' },
]
