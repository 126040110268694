import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import logo from '../../../include/images/yes-mindy-main-logo-white-color.png'
import ForgetPasswordCompleted from './forgetPasswordCompleted'
import SetPassword from './setPassword'
import VerifyMobile from './verifyMobile'
import VerifyOtp from './verifyOtp'
import { getAppointment } from '../../../store/appointment'

class ForgetPassword extends Component {
  state = {
    step: 1,
    verifyMobile: {},
    msg: [
      'Please enter your Mobile Number to continue',
      'Please enter OTP to continue',
      '',
      'You are almost done',
    ],
  }

  updateStep = step => {
    this.setState({ step })
  }

  updateVerifyMobile = verifyMobile => {
    this.setState({ verifyMobile })
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <VerifyMobile
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            mobile={
              this.props.getAppointment.appointmentObj &&
              this.props.getAppointment.appointmentObj.mobile
                ? this.props.getAppointment.appointmentObj.mobile
                : ''
            }
          />
        )

      case 2:
        return (
          <VerifyOtp
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        )
      case 3:
        return (
          <SetPassword
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        )

      case 4:
        return <ForgetPasswordCompleted {...this.props} />
      default:
        break
    }
  }

  //render function
  render() {
    console.log('this.props', this.props)
    return (
      <div className='member-container'>
        <div className='top-logo'>
          <a href='/'>
            {' '}
            <img src={logo} alt='' />
          </a>
        </div>
        <div className='member-bg hero-background'></div>
        <div className='member-box d-flex login-form'>
          <div className='member-left'>
            <div className='ml-box'>
              <h1>
                {this.state.step === 3
                  ? 'Your Security And Privacy Is Of High Concern To Us.'
                  : 'Yes Mindy.'}
              </h1>
              <p className='mb-0'>{this.state.msg[this.state.step - 1]}</p>
            </div>
          </div>
          {this.leftSideStep()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  getAppointment: getAppointment(state),
})

export default withRouter(connect(mapStateToProps, null)(ForgetPassword))
