import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'

const slice = createSlice({
  name: 'appointment',
  initialState: {
    appointmentDetail: { data: {} },
    appointmentCheckDetail: { data: {} },
    loadingDetail: true,
    dates: [],
    appointment: [],
    doctorAppointment: {
      data: [],
      pagination: {
        current_page: 1,
        filtered_record: 7,
        record_per_page: 10,
        total_page: 29,
        total_record: 289,
        total_record_on_current_page: 7,
      },
    },
    slots: [],
    loadingDates: false,
    lastFetchDates: null,
    loadingSlots: false,
    lastFetchSlots: null,
    loading: true,
    lastFetchdoctorAppointment: null,
    loadingDoctorAppointment: true,
    appointmentObj: {
      selectedTime: '',
      type: '',
    },
  },
  reducers: {
    setAppointmentObj: (appointment, action) => {
      console.log('action.payload', action.payload)
      appointment.appointmentObj = {
        ...appointment.appointmentObj,
        ...action.payload,
      }
    },
    detailRequested: appointment => {
      appointment.loadingDetail = true
    },
    detailReceived: (appointment, action) => {
      appointment.appointmentDetail = action.payload
      appointment.loadingDetail = false
    },
    detailCheckReceived: (appointment, action) => {
      appointment.appointmentCheckDetail = action.payload
      appointment.loadingDetail = false
    },
    detailRequestFailed: appointment => {
      appointment.loadingDetail = false
    },

    dateRequested: appointment => {
      appointment.loadingDates = true
    },
    dateReceived: (appointment, action) => {
      appointment.dates = action.payload
      appointment.slots = []
      appointment.loadingDates = false
      appointment.lastFetchDates = Date.now()
    },
    dateRequestFailed: appointment => {
      appointment.loadingDates = false
    },
    appointmentRequested: (appointment, action) => {
      appointment.loading = true
    },

    appointmentReceived: (appointment, action) => {
      appointment.appointment = action.payload
      appointment.loading = false
      appointment.lastFetch = Date.now()
    },

    appointmentRequestFailed: (appointment, action) => {
      appointment.loading = false
    },

    appointmentRequestedDoctor: (appointment, action) => {
      appointment.loadingDoctorAppointment = true
    },

    appointmentReceivedDoctor: (appointment, action) => {
      appointment.doctorAppointment = action.payload
      appointment.loadingDoctorAppointment = false
      appointment.lastFetchdoctorAppointment = Date.now()
    },

    appointmentRequestFailedDoctor: (appointment, action) => {
      appointment.loadingDoctorAppointment = false
    },

    slotRequested: appointment => {
      appointment.loadingSlots = true
    },
    slotReceived: (appointment, action) => {
      appointment.slots = action.payload.schedules
      appointment.loadingSlots = false
      appointment.lastFetchSlots = Date.now()
    },
    slotRequestFailed: appointment => {
      appointment.loadingSlots = false
    },
  },
})

export const {
  dateRequested,
  dateReceived,
  dateRequestFailed,
  slotRequested,
  slotReceived,
  slotRequestFailed,
  appointmentRequested,
  appointmentReceived,
  appointmentRequestFailed,
  appointmentRequestedDoctor,
  appointmentReceivedDoctor,
  appointmentRequestFailedDoctor,
  detailRequested,
  detailReceived,
  detailRequestFailed,
  detailCheckReceived,
  setAppointmentObj,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'appointment/'
const url1 = 'appointment'
export const loadDate = () => (dispatch, getState) => {
  // const { lastFetchDates } = getState().entities.appointment;

  // const diffInMinutes = moment().diff(moment(lastFetchDates), "minutes");
  // if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: url + 'getDates',
      onStart: dateRequested.type,
      onSuccess: dateReceived.type,
      onError: dateRequestFailed.type,
    })
  )
}
export const loadAllAppointment = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1,
      params,
      onStart: appointmentRequested.type,
      onSuccess: appointmentReceived.type,
      onError: appointmentRequestFailed.type,
    })
  )
}

export const loadAllAppointmentForDoctor =
  (params, callback = null) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + 'listForDoctor',
        params,
        onStart: appointmentRequestedDoctor.type,
        onSuccess: appointmentReceivedDoctor.type,
        onError: appointmentRequestFailedDoctor.type,
        callback,
      })
    )
  }

export const loadAppointmentDetail = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + '/detail/' + id,
      onStart: detailRequested.type,
      onSuccess: detailReceived.type,
      onError: detailRequestFailed.type,
      callback,
    })
  )
}

export const loadAppointmentDetailPatient =
  (id, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + '/detailPatient/' + id,
        onStart: detailRequested.type,
        onSuccess: detailReceived.type,
        onError: detailRequestFailed.type,
        callback,
      })
    )
  }

export const loadAppointmentCheckDetailPatient =
  (id, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url + '/patient/appointmentCheck/' + id,
        onStart: detailRequested.type,
        onSuccess: detailCheckReceived.type,
        onError: detailRequestFailed.type,
        callback,
      })
    )
  }

export const loadSlot = (drId, date, type) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'getSlots/' + drId + '/' + date + '/' + type,
      onStart: slotRequested.type,
      onSuccess: slotReceived.type,
      onError: slotRequestFailed.type,
    })
  )
}

export const createOrderByDoctor = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'createOrderByDoctor',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const createOrder = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'createOrder',
      method: 'POST',
      data,
      callback,
    })
  )
}

export const updateOrder = (data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'updateOrder',
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const noShow = (id, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'noshow/' + id,
      method: 'PUT',
      callback,
    })
  )
}

export const rescheduleAppointment = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const rescheduleByDoctor = (id, data, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'reschedule/' + id,
      method: 'PUT',
      data,
      callback,
    })
  )
}

export const cancellAppointment = (id, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: 'DELETE',
      callback,
    })
  )
}

export const calcelByDoctor = (id, callback) => dispatch => {
  return dispatch(
    apiCallBegan({
      url: url + 'calcelByDoctor/' + id,
      method: 'DELETE',
      callback,
    })
  )
}

export const setAppointmentObjData = data => dispatch => {
  console.log('action', data)
  return dispatch({ type: setAppointmentObj.type, payload: data })
}

export const getAppointment = createSelector(
  state => state.entities.appointment,
  appointment => appointment
)
