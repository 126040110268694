import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
    name: "treatmentPlan",
    initialState: {
        planAnswer: {},
        medicalPlan: [],
        technique: [],
        assesment: [],
        list: [],
        listPat: [],
        loading: false,
        answerLoading: true,
        lastFetch: null,
        summaryPat: [],
        summaryPatbyId: []
    },
    reducers: {
        medicalPlanRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },
        medicalPlanReceived: (followUp, action) => {
            followUp.medicalPlan = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        medicalPlanRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        techniqueRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },
        techniqueReceived: (followUp, action) => {
            followUp.technique = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        techniqueRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        assesmentRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        assesmentReceived: (followUp, action) => {
            followUp.assesment = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        assesmentRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        getDataRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        getDataReceived: (followUp, action) => {
            followUp.planAnswer = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        getDataRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        listRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        listReceived: (followUp, action) => {
            followUp.list = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        listRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        listPatRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        listPatReceived: (followUp, action) => {
            followUp.listPat = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        listPatRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        summaryPatRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        summaryPatReceived: (followUp, action) => {
            followUp.summaryPat = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        summaryPatRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        summaryPatbyIdRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        summaryPatbyIdReceived: (followUp, action) => {
            followUp.summaryPatbyId = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        summaryPatbyIdRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
    },
});

export const {
    medicalPlanRequested,
    medicalPlanReceived,
    medicalPlanRequestFailed,
    techniqueRequested,
    techniqueReceived,
    techniqueRequestFailed,
    assesmentRequested,
    assesmentReceived,
    assesmentRequestFailed,
    getDataRequested,
    getDataReceived,
    getDataRequestFailed,
    listRequested,
    listReceived,
    listRequestFailed,
    listPatRequested,
    listPatReceived,
    listPatRequestFailed,
    summaryPatRequested,
    summaryPatReceived,
    summaryPatRequestFailed,
    summaryPatbyIdRequested,
    summaryPatbyIdReceived,
    summaryPatbyIdRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "prescription/";

export const loadAnswertreatment = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "medical-plan/" + "form-data/" + id,
            onStart: getDataRequested.type,
            onSuccess: getDataReceived.type,
            onError: getDataRequestFailed.type,
        })
    );
};

export const loadMedicalPlan = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "medicinalPlan",
            onStart: medicalPlanRequested.type,
            onSuccess: medicalPlanReceived.type,
            onError: medicalPlanRequestFailed.type,
        })
    );
};
export const loadTechnique = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "techniques",
            onStart: techniqueRequested.type,
            onSuccess: techniqueReceived.type,
            onError: techniqueRequestFailed.type,
        })
    );
};
export const loadAssesment = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "conduct",
            onStart: assesmentRequested.type,
            onSuccess: assesmentReceived.type,
            onError: assesmentRequestFailed.type,
        })
    );
};

export const loadListPlan = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "medical-plan/list/" + id,
            onStart: listRequested.type,
            onSuccess: listReceived.type,
            onError: listRequestFailed.type,
        })
    );
};

export const loadListPlanPat = (callback) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "medical-plan/list-for-patient/",
            callback,
            onStart: listPatRequested.type,
            onSuccess: listPatReceived.type,
            onError: listPatRequestFailed.type,
        })
    );
};

export const loadSummaryPat = (params) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "appointment/summary",
            params,
            onStart: summaryPatRequested.type,
            onSuccess: summaryPatReceived.type,
            onError: summaryPatRequestFailed.type,
        })
    );
};

export const loadSummaryPatbyId = (id, params) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "appointment/summary-by-patientid/" + id,
            params,
            onStart: summaryPatbyIdRequested.type,
            onSuccess: summaryPatbyIdReceived.type,
            onError: summaryPatbyIdRequestFailed.type,
        })
    );
};

export const addAnswerTreatment = (param, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            callback,
            url: "medical-plan",
            method: "post",
            data: param,
            // onSuccess: followAnswerAdded.type,
            // onError: followAnswerAddRequestFailed.type,
        })
    );
};

export const getPlan = createSelector(
    (state) => state.entities.treatmentPlan,
    (treatmentPlan) => treatmentPlan
);
