import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../common/form/form";
import seUploadFun from "../../services/s3Services";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import loaderIcon from "../../../include/images/loading-icon.svg";

import {
  drPersonalDetailFaild,
  exprienceRequired,
  graduationRequired,
  licenseRequired,
  postgraduationRequired,
  profileUpdatedSuccess,
  registrationRequired,
} from "../../common/misc";
import { getMisc } from "../../../store/misc";
import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDoctorToken } from "../../services/localStorageServices";
import FileInput from "../../common/form/fileInput";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";
class Documentation extends Form {
  state = {
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      graduation: "",
      postgraduation: "",
      registration: "",
      experience: "",
      super: "",
      license_no: "",
    },

    errors: {},
  };

  schema = {
    graduation: Joi.string()
      .required()
      .error(() => {
        return { message: graduationRequired };
      }),
    postgraduation: Joi.any().error(() => {
      return { message: postgraduationRequired };
    }),
    registration: Joi.string()
      .required()
      .error(() => {
        return { message: registrationRequired };
      }),
    experience: Joi.any().error(() => {
      return { message: exprienceRequired };
    }),
    super: Joi.any().error(() => {
      return { message: exprienceRequired };
    }),
    license_no: Joi.string()
      .required()
      .error(() => {
        return { message: licenseRequired };
      }),
  };

  fileUpload = (e) => {
    const _id = this.props.doctorProfile._id;
    const name = e.target.name;
    const file = e.target.files[0];
    const fileName = "doctor/" + _id + "/documents/" + Date.now();
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      const { data, errors } = this.state;
      data[name] = result.key;
      errors[name] = false;
      this.setState({ data });
    });
  };

  doSubmit = () => {
    const {
      experience,
      graduation,
      license_no,
      postgraduation,
      registration,
      super: super_specialty_degree,
    } = this.state.data;
    const data = {
      documents_url: {
        graduation,
        super_specialty_degree,
        post_graduation: postgraduation,
        registration_license: {
          doc_url: registration,
          licence_no: license_no,
        },
        experience_certificate: experience,
      },
    };
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(data, this.callBack);
    }
  };
  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/clinic");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.doctorProfile.documents_url
    ) {
      const {
        graduation,
        post_graduation,
        experience_certificate,
        registration_license,
        super_specialty_degree,
      } = this.props.doctorProfile.documents_url;
      const data = {
        graduation,
        postgraduation: post_graduation,
        registration: registration_license.doc_url,
        experience: experience_certificate,
        super: super_specialty_degree,
        license_no: registration_license.licence_no,
      };
      this.setState({ data });
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <HelpText />

          <div className="container ml-0">
            <div className="main-heading">
              <h1>Documentations</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-5">
                    <FileInput
                      totalUploaded={this.state.data["graduation"]}
                      error={this.state.errors["graduation"]}
                      label="Graduation certificate"
                      name="graduation"
                      text=""
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["graduation"]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    <FileInput
                      totalUploaded={this.state.data["postgraduation"]}
                      error={this.state.errors["postgraduation"]}
                      label="Post Graduation certificate"
                      name="postgraduation"
                      text=""
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["postgraduation"]}
                      optional={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    <FileInput
                      totalUploaded={this.state.data["registration"]}
                      error={this.state.errors["registration"]}
                      label="Registration/License certificate"
                      name="registration"
                      text=""
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["registration"]}
                    />
                  </div>
                  <div className="col-xl-5">
                    <label className="d-none d-xl-block">&nbsp;</label>
                    {this.renderInput("license_no", "License Number")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-5">
                    <FileInput
                      totalUploaded={this.state.data["super"]}
                      error={this.state.errors["experience"]}
                      label="Super Specialty degree certificate"
                      name="super"
                      text=""
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["super"]}
                      optional={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <FileInput
                      totalUploaded={this.state.data["experience"]}
                      error={this.state.errors["experience"]}
                      label="Experience certificate - (if any)"
                      name="experience"
                      text=""
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["experience"]}
                      optional={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fieldTypes: getMisc(state).fieldTypes,
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
