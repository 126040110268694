import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";
import Pagination from "../common/pagination";
import { Link } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import {
  getMisc,
  loadConstant,
  loadBlog,
  loadBlogCategory,
  loadKeyword,
  loadFaq,
} from "../../store/misc";
import image1 from "../../include/images/blog-hero.png";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import TopArticle from "./topArticle";
const ref = React.createRef();

class Blog extends PureComponent {
  input = React.createRef();
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: "",
    toggle1: false,
    id: "",
    keyword: "",
    keyword1: "",
    category: "",
  };
  onSearch = (e) => {
    e.preventDefault();
    this.props.loadBlog({ keyword: this.input.current.value, published: true });
  };

  onKeyword = (e) => {
    if (e) e.preventDefault();
    this.props.loadBlog({ keyword: this.state.keyword1, published: true });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      ref.current.complete();
    }
    if (this.state.keyword1 && this.state.keyword1 != prevState.keyword1) {
      window.scrollTo(0, 0);
      this.props.location.state && this.props.location.state.replace("", null);
      this.props.loadBlog({ keyword: this.state.keyword1, published: true });
    } else if (
      this.state.category &&
      this.state.category != prevState.category
    ) {
      this.props.location.state && this.props.location.state.replace("", null);
      window.scrollTo(0, 0);
      this.props.loadBlog({ category: this.state.category, published: true });
    }
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadBlogCategory();
    this.props.loadKeyword();
    this.props.loadFaq({ category: "Misc" });
    if (this.state.currentPage) {
      this.props.loadBlog({
        page: this.state.currentPage,
        category: this.props.location.state,
        keyword: this.props.location.keyword,
        published: true,
      });
    } else this.props.loadBlog();
  };
  onChangePage = (data) => {
    this.setState({
      pageLimit: data.pageLimit,
      totalPages: data.totalPages,
      currentPage: data.page,
    });
    if (
      !this.state.keyword1 &&
      !this.state.keyword &&
      !this.state.category &&
      data.page != this.state.currentPage
    ) {
      this.props.loadBlog({
        page: data.page,
        category: this.props.location.state,
        keyword: this.props.location.keyword,
        published: true,
      });
    }
  };
  toggle1 = (id) => {
    this.setState({
      id: id,
      toggle1: !this.state.toggle1,
    });
  };
  render() {
    const { constants, blogCategory, blog, keyword, faq } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const { record_per_page, total_page, current_page, filtered_record } =
      this.props.pagination;
    const list = blog;

    return (
      <>
        {" "}
        <LoadingBar color="#fff" ref={ref} shadow={true} />
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="clinician-hero-container blog-hero">
            <div class="container container1">
              <div class="row flex-row-reverse align-items-center">
                <div class="col-xl-5">
                  <div class="clinician-hero-content pb-5">
                    <h3 class="d-block d-md-none">Mental HEALTH</h3>
                    <p>
                      Our professionals assist your mental health journey via
                      detailed evaluation, diagnosis, prescription pads, and
                      holistic care.
                    </p>
                  </div>
                </div>
                <div class="col-xl-7">
                  <div class="clinician-hero-img">
                    <img src={image1} alt="" />
                    <div class="blog-hero-text d-none d-md-block">
                      <span>
                        Mental <br />
                        HEALTH
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container blog-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="blog-content-box d-xl-flex align-items-xl-start flex-xl-wrap">
                    <div class="blog-left">
                      <div class="heading-with-line grey overflow-hidden">
                        {this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.state.category &&
                          blog.length > 0 && (
                            <h3>
                              <span>Search Results</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          this.state.keyword1 &&
                          !this.state.category &&
                          blog.length > 0 && (
                            <h3>
                              <span>Search Results</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.state.category &&
                          !this.props.location.state &&
                          !this.props.location.keyword &&
                          blog.length > 0 && (
                            <h3>
                              <span>Latest Articles</span>
                            </h3>
                          )}
                        {/* {blog.length === 0 && (
                          <h3>
                            <span>No Records Found</span>
                          </h3>
                        )} */}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          this.props.location.state &&
                          !this.props.location.keyword &&
                          this.state.category && (
                            <h3>
                              <span>{this.state.category}</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.state.category &&
                          this.props.location.state && (
                            <h3>
                              <span>{this.props.location.state}</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.state.category &&
                          this.props.location.keyword &&
                          blog.length > 0 && (
                            <h3>
                              <span>Search Results</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.props.location.state &&
                          !this.props.location.keyword &&
                          this.state.category &&
                          blog.length > 0 && (
                            <h3>
                              <span>{this.state.category}</span>
                            </h3>
                          )}
                        {!this.state.keyword &&
                          !this.state.keyword1 &&
                          !this.props.location.state &&
                          this.props.location.keyword &&
                          this.state.category &&
                          blog.length > 0 && (
                            <h3>
                              <span>{this.state.category}</span>
                            </h3>
                          )}
                      </div>
                      {blog.length > 0 ? (
                        list.map((e, i) => (
                          <div class="blog-box">
                            <div class="blog-left-upper d-flex flex-wrap">
                              <div class="blog-left-pannel">
                                <Link
                                  to={{ pathname: "/blog-detail/" + e.url }}
                                >
                                  <h3 className="profiletab">{e.title} </h3>
                                </Link>
                                <p>
                                  <HTMLEllipsis
                                    unsafeHTML={e.text}
                                    maxLine="2"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </p>
                                <div class="mh-read_more">
                                  <Link
                                    to={{
                                      pathname: "/blog-detail/" + e.url,
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                              <div class="blog-right-pannel">
                                <div class="blog-pannel-img">
                                  <Link
                                    to={{
                                      pathname: "/blog-detail/" + e.url,
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_S3URL +
                                        e.featured_image_url
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div class="blog-left-lower">
                              <ul class="community-kw-list d-flex flex-wrap">
                                {e.keywords.map((e1, i) => (
                                  <li>
                                    <button
                                      class="community-kw-link"
                                      onClick={() =>
                                        this.props.loadBlog({
                                          keyword: e1,
                                          published: true,
                                        })
                                      }
                                    >
                                      {e1}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h5>No records found</h5>
                      )}
                      {this.props.pagination.total_page > 1 && (
                        <Pagination
                          totalRecords={filtered_record}
                          total_page={total_page}
                          pageLimit={record_per_page}
                          initialPage={current_page}
                          pagesToShow={5}
                          onChangePage={this.onChangePage}
                        />
                      )}
                    </div>
                    <div class="blog-right">
                      <div class="br-content-box">
                        <div class="br-seartch-bar">
                          <form onSubmit={this.onSearch}>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Look for topics"
                                ref={this.input}
                                // onChange={(e) =>
                                //   this.setState({
                                //     keyword: e.target.value,
                                //     keyword1: "",
                                //   })
                                // }
                              />
                            </div>
                          </form>
                        </div>
                        <ul class="community-kw-list d-flex flex-wrap">
                          {keyword.slice(0, 10).map((e, i) => (
                            <li onClick={this.onSearch}>
                              <button
                                class="community-kw-link"
                                onClick={() =>
                                  this.props.loadBlog({
                                    keyword: e.title,
                                    published: true,
                                  })
                                }
                              >
                                {e.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div class="br-content-box">
                        <div class="br-heading">
                          <h3>Topics</h3>
                        </div>
                        <ul class="topic-list">
                          {blogCategory.map((e, i) => (
                            <li>
                              <a
                                href="javascript:void(0)"
                                class="tl-link"
                                onClick={() =>
                                  this.setState({ category: e.name })
                                }
                              >
                                {e.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <TopArticle />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container mob-pt-0">
            <div class="container container1">
              <div class="row">
                <div class="col-xl-12">
                  <div class="heading text-center">
                    <h4>
                      Knowledge <span>Is Power</span>
                    </h4>
                  </div>
                  <div class="accordion pc-outer" id="accordionExample">
                    {faq.map((e, i) => (
                      <div
                        className={
                          this.state.id === e._id && this.state.toggle1 === true
                            ? "card active"
                            : "card"
                        }
                      >
                        <div
                          className={
                            this.state.id === e._id &&
                            this.state.toggle1 === true
                              ? "card-header"
                              : "card-header collapsed"
                          }
                          onClick={() => {
                            this.toggle1(e._id);
                          }}
                          id="heading1"
                          data-toggle="collapse"
                          data-target="#collapse1"
                          aria-expanded="false"
                          aria-controls="collapse1"
                        >
                          {e.question}
                        </div>
                        <div
                          id="collapse1"
                          className={
                            this.state.id === e._id &&
                            this.state.toggle1 === true
                              ? "collapse show"
                              : "collapse"
                          }
                          aria-labelledby="heading1"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <p>{e.answer}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  pagination: getMisc(state).pagination,
  loading: getMisc(state).loadingBlog,
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlogCategory: () => dispatch(loadBlogCategory()),
  loadBlog: (params) => dispatch(loadBlog(params)),
  loadKeyword: () => dispatch(loadKeyword()),
  loadFaq: (params) => dispatch(loadFaq(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
