import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../common/form/form";
import seUploadFun from "../../services/s3Services";
import Modal from "react-bootstrap/Modal";
import closeSvg from "../../../include/images/popup-close.svg";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import clipIm from "../../../include/images/paper-clip.svg";
import { getCroppedImg } from "./../../common/convasUtils";
import Cropper from "react-easy-crop";

import {
  dobInvalid,
  dobRequired,
  nameRequired,
  mobileInvalid,
  mobileRequired,
  sexRequired,
  emailRequired,
  emailInvalid,
  fieldTypeRequired,
  signRequired,
  photoRequired,
  emailExist,
  passwordRequired,
  passwordInvalid,
  passwordInvalidMax,
  drPersonalDetailFaild,
  nameMax,
  profileUpdatedSuccess,
} from "../../common/misc";
import { getMisc, loadFieldType } from "../../../store/misc";
import {
  doctorSignUp,
  doctorUpdate,
  emailAvailability,
  getDoctor,
  loadDoctorProfile,
  mobileAvailability,
  profileRequestFailed,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getDoctorToken,
  setDoctorToken,
} from "../../services/localStorageServices";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";
class PersonalDetail extends Form {
  state = {
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    displayImage: "",
    signatureStatus: "Add file",
    photoStatus: "Add file",
    fieldTypes: [
      { id: "Psychiatrist", name: "Psychiatrist" },
      { id: "Psychologist", name: "Psychologist" },
      { id: "Psychosocial Worker", name: "Psychosocial Worker" },
      { id: "Counsellor", name: "Counsellor" },
    ],
    modelShow: false,
    croppedAreaPixels: "",
    image: "",
    crop: { x: 0, y: 0 },
    zoom: 1,
    model: false,
    loading: false,
    data: {
      name: "",
      sex: "",
      DOB: "",
      email: "",
      phone: "",
      fieldType: "",
      photo: "",
      sign: "",
      password: "",
    },
    errors: {},
  };

  fileUpload = (file) => {
    this.setState({ photoStatus: "Loading" });
    const fileName = "doctor/photo/" + Date.now();
    const res = seUploadFun(file, fileName);
    res.then((result) => {
      const { data, errors } = this.state;
      data.photo = result.key;
      errors.photo = false;
      this.setState(
        {
          loading: false,
          photoStatus: "Uploaded successfully. Click here to change",
          data,
          displayImage: process.env.REACT_APP_S3URL + result.key,
        },
        () => {
          this.toggle();
        }
      );
    });
  };

  toggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ model: !this.state.model });
  };
  readFile(file) {
    if (!file) return;
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  uploadFile = async (e) => {
    const file = e.target.files[0];
    let image = await this.readFile(file);
    this.setState({ image });
    this.toggle();
  };

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };
  showCroppedImage = async (e) => {
    this.setState({ loading: true });
    //ref.current.continuousStart();
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      console.log("croppedImage", croppedImage);
      return this.fileUpload(this.dataURItoBlob(croppedImage));
    } catch (e) {
      console.error(e);
    }
  };

  schema = {
    photo: Joi.string()
      .required()
      .label("Photo")
      .error(() => {
        return { message: photoRequired };
      }),
    sign: Joi.string()
      .required()
      .label("Signature")
      .error(() => {
        return { message: signRequired };
      }),
    sex: Joi.string()
      .required()
      .label("Sex")
      .error(() => {
        return { message: sexRequired };
      }),
    fieldType: Joi.string()
      .required()
      .label("Field Type")
      .error(() => {
        return { message: fieldTypeRequired };
      }),
    name: Joi.string()
      .required()
      .max(50)
      .label("Name")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = nameRequired;
              break;

            case "string.max":
              err.message = nameMax;
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = emailRequired;
              break;

            case "string.email":
              err.message = emailInvalid;
              break;
            case "string.max":
              err.message = emailInvalid;
              break;
          }
        });
        return errors;
      }),

    phone: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),

    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),

    DOB: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .required()
      .label("Date of birth")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = dobRequired;
              break;

            case "date.max":
              err.message = dobInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  toggleModel = (e) => {
    if (e) e.preventDefault();
    this.setState({ modelShow: !this.state.modelShow });
  };
  // fileUpload = (e) => {
  //   this.setState({ photoStatus: "Loading" });
  //   const fileName = "doctor/photo/" + Date.now();
  //   const res = seUploadFun(e.target.files[0], fileName);
  //   res.then((result) => {
  //     const { data, errors } = this.state;
  //     data.photo = result.key;
  //     errors.photo = false;
  //     this.setState({
  //       photoStatus: "Uploaded successfully. Click here to change",
  //       data,
  //       displayImage: process.env.REACT_APP_S3URL + result.key,
  //     });

  //     this.toggleModel(null);
  //   });
  // };

  signUpload = (e) => {
    this.setState({ signatureStatus: "Loading" });
    const fileName = "doctor/signature/" + Date.now();
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      const { data, errors } = this.state;
      data.sign = result.key;
      errors.sign = false;
      this.setState({
        signatureStatus: "Uploaded successfully. Click here to change",
        data,
        errors,
        displayImage: process.env.REACT_APP_S3URL + result.key,
      });
    });
  };

  callBackUpdate = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/educational-detail/education");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  doSubmit = () => {
    if (getDoctorToken("auth")) {
      const {
        name,
        sex,
        DOB: date_of_birth,
        fieldType: field_type,
        photo: image_url,
        sign: signarture_url,
        password,
      } = this.state.data;
      const data = {
        name,
        sex,
        date_of_birth: date_of_birth.toString(),
        field_type,
        image_url,
        signarture_url,
        password,
      };
      this.props.doctorUpdate(data, this.callBackUpdate);
    } else {
      if (!this.state.loading) {
        this.setState({
          btnClass: "btn btn-primary btn-lg disabled",
          loading: true,
        });
        this.props.emailAvailability(
          { email: this.state.data.email },
          this.callBackEmail
        );
      }
    }
  };

  callBackEmail = (res) => {
    if (res.status === 200) {
      this.props.mobileAvailability(
        { mobile: this.state.data.phone },
        this.callBackMobile
      );
    } else {
      toast(<AlertError message={emailExist} />);
      this.setState({
        btnClass: "btn btn-primary btn-lg",
        loading: false,
      });
    }
  };

  callBackMobile = (res) => {
    if (res.status === 200) {
      const {
        name,
        sex,
        email,
        phone: mobile,
        DOB: date_of_birth,
        fieldType: field_type,
        photo: image_url,
        sign: signarture_url,
        password,
      } = this.state.data;
      const data = {
        name,
        sex,
        email,
        mobile,
        date_of_birth: date_of_birth.toString(),
        field_type,
        image_url,
        signarture_url,
        password,
      };
      this.props.doctorSignUp(data, this.signUpCallBack);
    } else {
      toast(<AlertError message={emailExist} />);
      this.setState({
        btnClass: "btn btn-primary btn-lg",
        loading: false,
      });
    }
  };

  removeData = (e, key) => {
    if (e) e.preventDefault();
    const data = this.state.data;
    data[key] = "";
    this.setData({ data });
  };

  signUpCallBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data;
      setDoctorToken(xRefreshToken, xAuthToken);
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
      this.props.history.push("/doctor/sign-up/educational-detail/education");
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    this.props.loadFieldType();
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else if (this.props.location.state) {
      const { data } = this.state;
      data.phone = this.props.location.state.mobile;
      this.setState({ data });
      this.props.profileRequestFailed();
    } else {
      this.props.history.push("/doctor/sign-up");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.doctorProfile._id &&
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      delete this.schema.password;
      const {
        name,
        sex,
        date_of_birth,
        email,
        mobile: phone,
        field_type,
        image_url: photo,
        signarture_url: sign,
      } = this.props.doctorProfile;
      const data = {
        name,
        sex,
        DOB: new Date(date_of_birth),
        email,
        phone,
        fieldType: field_type._id,
        photo,
        sign,
      };
      this.setState({ data });
    }
    if (
      prevProps.fieldTypes.length !== this.props.fieldTypes.length &&
      !this.props.profileLoading
    ) {
      window.$("#fieldType").niceSelect();
      window.$(".fieldType").on("change", () => {
        const data = this.state.data;
        data.fieldType = window.$("#fieldType").val();
        this.setState({ data });
      });
    }
    window.$("#fieldType").niceSelect();
    window.$(".fieldType").on("change", () => {
      const data = { ...this.state.data };
      const errors = { ...this.state.errors };
      data.fieldType = window.$("#fieldType").val();
      if (data.fieldType) errors.fieldType = "";
      if (!data.fieldType) errors.fieldType = fieldTypeRequired;
      this.setState({ data, errors });
    });

    window.$("#sex").niceSelect();
    window.$(".sex").on("change", () => {
      const data = { ...this.state.data };
      const errors = { ...this.state.errors };
      data.sex = window.$("#sex").val();
      if (data.sex) errors.sex = "";
      if (!data.sex) errors.sex = sexRequired;
      this.setState({ data, errors });
    });
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Personal Details</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput("name", "Name")}
                  </div>
                  <div className="col-xl-2">
                    {this.renderSelect("sex", "Sex", [
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                    ])}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    {this.renderDateInput(
                      "DOB",
                      "Date of birth",
                      "",
                      "",
                      this.props.doctorProfile &&
                        this.props.doctorProfile.date_of_birth
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput("email", "Email")}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    {this.renderInput("phone", "Mobile Number", "text", true)}
                  </div>
                </div>
                {!this.props.doctorProfile._id && (
                  <div className="row">
                    <div className="col-xl-5">
                      {this.renderPasswordInput("password", "Password")}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xl-5">
                    {this.props.fieldTypes.length &&
                      this.renderSelect(
                        "fieldType",
                        "Field Type",
                        this.props.fieldTypes
                      )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group custom-upload">
                      <label>Upload Photo</label>
                      <label
                        className={
                          this.state.errors.photo
                            ? "btn btn-upload btn-block pull-left errorColor"
                            : "btn btn-upload pull-left btn-block"
                        }
                        htmlFor="photograph2"
                      >
                        <span>
                          <img src={clipIm} alt="" />

                          {this.state.data.photo
                            ? "Uploaded successfully. Click here to change"
                            : this.state.photoStatus}
                        </span>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        id="photograph2"
                        className="d-none errorColor"
                        onChange={this.uploadFile}
                        //onChange={this.fileUpload}
                        disabled={this.state.photoStatus === "Loading"}
                      />

                      {this.state.errors.photo && (
                        <label className="errorLabel">
                          {this.state.errors.photo}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.data.photo && (
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="imagePreviewBox">
                        <div className="imagePreview">
                          <img
                            className="imageClinic"
                            width="180px"
                            src={
                              process.env.REACT_APP_S3URL +
                              this.state.data.photo
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group custom-upload">
                      <label>Upload Signature</label>
                      <label
                        className={
                          this.state.errors.sign
                            ? "btn btn-upload btn-block pull-left errorColor"
                            : "btn btn-upload pull-left btn-block"
                        }
                        htmlFor="photograph3"
                      >
                        <span>
                          <img src={clipIm} alt="" />

                          {this.state.data.sign
                            ? "Uploaded successfully. Click here to change"
                            : this.state.signatureStatus}
                        </span>
                      </label>
                      <input
                        accept="image/*"
                        type="file"
                        id="photograph3"
                        className="d-none errorColor"
                        onChange={this.signUpload}
                        disabled={this.state.signatureStatus === "Loading"}
                      />

                      {this.state.errors.sign && (
                        <label className="errorLabel">
                          {this.state.errors.sign}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.data.sign && (
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="imagePreviewBox">
                        <div className="imagePreview">
                          <img
                            className="imageClinic"
                            width="180px"
                            src={
                              process.env.REACT_APP_S3URL + this.state.data.sign
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="photo-upload-modal"
            show={this.state.modelShow}
            onHide={this.toggleModel}
          >
            <button
              onClick={this.toggleModel}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={closeSvg} alt="" />
            </button>
            <div className="modal-body">
              <div className="photo-upload-box d-md-flex flex-wrap">
                <div className="photo-upload-left">
                  <div className="mb-4">
                    <img
                      className="img-fluid w-100"
                      src={this.state.displayImage}
                      alt=""
                    />
                  </div>
                  <a
                    href="#"
                    className="btn btn-primary"
                    onClick={this.toggleModel}
                  >
                    Submit
                  </a>
                </div>
                <div className="photo-upload-right">
                  <h6>Photo guidelines</h6>
                  <ol>
                    <li>
                      Please provide photos with a resolution greater than 250 x
                      300
                    </li>
                    <li>
                      Face should be clearly visible, close up and looking at
                      the camera. Photos with dark regions or direct flash are
                      not allowed
                    </li>
                    <li>Grainy, blurred and tilted photos are not allowed</li>
                    <li>
                      Photos with copyright tags or watermarks of other
                      companies are not allowed, as that would be copyright
                      infringement
                    </li>
                    <li>
                      Profile photo should be passport type photo with only area
                      above the chest visible
                    </li>
                    <li>
                      Group photos, photos taking awards or full length photos
                      are not allowed
                    </li>
                    <li>
                      Photos with websites, phone numbers, hospital logo, and
                      other text are not allowed
                    </li>
                  </ol>
                  This photograph will be visible on your profiles and to
                  patients. (You can change from profile settings)
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal fade process-popup crop-popup verification-popup"
            dialogClassName="modal-dialog modal-dialog-centered"
            show={this.state.model}
            onHide={this.toggle}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Crop</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: "400px" }}>
              <p>
                <Cropper
                  image={this.state.image}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={4 / 4}
                  onCropChange={(e) => this.setState({ crop: e })}
                  onCropComplete={this.onCropComplete}
                />
              </p>
            </Modal.Body>
            <div className="crop-zoom">
              {/* <label for="customRange1" className="form-label">
              Zoom
            </label> */}
              <input
                style={{ width: "100%" }}
                max="100"
                min="1"
                type="range"
                className="form-range"
                id="customRange1"
                value={this.state.zoom}
                onChange={(e) => this.setState({ zoom: e.target.value })}
              />
            </div>
            <Modal.Footer>
              {!this.state.loading ? (
                <a
                  href="javascript:void(0)"
                  onClick={this.showCroppedImage}
                  className="btn btn-default"
                >
                  Crop
                </a>
              ) : (
                "Uploading..."
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fieldTypes: getMisc(state).fieldTypes,
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  loadFieldType: () => dispatch(loadFieldType()),
  emailAvailability: (data, callback) =>
    dispatch(emailAvailability(data, callback)),
  mobileAvailability: (data, callback) =>
    dispatch(mobileAvailability(data, callback)),

  doctorSignUp: (data, callback) => dispatch(doctorSignUp(data, callback)),
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  profileRequestFailed: () => dispatch(profileRequestFailed()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);
