import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "notification",
  initialState: {
    count: { totalCount: 0, countRead: 0, countUnRead: 0 },
    notification: { total: [], unRead: [] },
    loading: false,
    lastFetch: null,
    countloading: false,
    countlastFetch: null,
  },
  reducers: {
    notificationRequested: (notification) => {
      notification.loading = true;
    },
    notificationRecieved: (notification, action) => {
      notification.notification = action.payload;
      notification.loading = false;
      notification.lastFetch = Date.now();
    },
    notificationRequestFailed: (notification) => {
      notification.notification = {};
      notification.loading = false;
    },

    notificationCountRequested: (notification) => {
      notification.countloading = true;
    },
    notificationCountRecieved: (notification, action) => {
      notification.count = action.payload;
      notification.countloading = false;
      notification.countlastFetch = Date.now();
    },
    notificationCountRequestFailed: (notification) => {
      notification.count = {};
      notification.countloading = false;
    },
  },
});

export const {
  notificationRequested,
  notificationRecieved,
  notificationRequestFailed,
  notificationCountRequested,
  notificationCountRecieved,
  notificationCountRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "notification";
const urlTermTemplate = "termTemplate";

export const loadNotification = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "/list",
      onStart: notificationRequested.type,
      onSuccess: notificationRecieved.type,
      onError: notificationRequestFailed.type,
    })
  );
};

export const loadCountNotification = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "/count",
      onStart: notificationCountRequested.type,
      onSuccess: notificationCountRecieved.type,
      onError: notificationCountRequestFailed.type,
    })
  );
};

export const getNotification = createSelector(
  (state) => state.entities.notification,
  (notification) => notification
);
