import React, { Component } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form/form'
import { connect } from 'react-redux'
import DashboardTopBar from './common/dashboardTopBar'
import moment from 'moment'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import seUploadFun from '../../services/s3Services'
import clipIm from './../../../include/images/paper-clip.svg'
import {
  updatePatientProfile,
  loadPatientProfile,
  getPatient,
} from '../../../store/patient'
import { withRouter } from 'react-router-dom'
import avatar from './../../../include/images/Group2.svg'
import rocket from './../../../include/images/rocket.svg'
import SubmitBtn from '../../common/form/submitBtn'
import _ from 'lodash'

class PatientId extends Form {
  state = {
    btnClass: 'btn btn-primary btn-lg',
    loading: false,
    displayImage: '',
    photoStatus: '',
    data: {
      photo_id: '',
    },
    errors: {},
  }

  schema = {
    photo_id: Joi.string().allow(''),
  }
  componentDidMount = () => {
    //alert(111);
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
    if (localStorage.getItem('patient')) {
      this.props.loadPatientProfile()
    }
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.patientProfile._id &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      const { photo_id } = this.props.patientProfile
      const data = {
        photo_id,
      }
      const newData = _.pickBy(data, _.identity)
      this.setState({ data: newData })
    }
  }
  fileUpload = (e) => {
    this.setState({ photoStatus: 'Loading' })
    const fileName = 'patient/photo/' + Date.now()
    const res = seUploadFun(e.target.files[0], fileName)
    res.then((result) => {
      const { data, errors } = this.state
      data.photo_id = result.key
      errors.photo_id = false
      this.setState({
        photoStatus: 'Uploaded successfully. Click here to change',
        data,
        displayImage: process.env.REACT_APP_S3URL + result.key,
      })
    })
  }

  doSubmit = () => {
    if (localStorage.getItem('patient')) {
      const { photo_id } = this.state.data
      const data = {
        photo_id,
      }
      if (data.photo_id != undefined) {
        this.props.updatePatientProfile(data, this.callBackUpdate)
      } else if (!this.props.initial) {
        this.props.updateStep(null, 4)
      } else {
        this.props.loadPatientProfile()
        if (this.props.setInitial) {
          this.props.setInitial()
        }
        this.props.history.push('/patient/dashboard')
      }
    } else {
      if (!this.state.loading) {
        this.setState({
          btnClass: 'btn btn-primary btn-lg disabled',
          loading: true,
        })
      }
    }
  }
  callBackUpdate = (res) => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res && res.status === 200) {
      this.props.loadPatientProfile()
      if (!this.props.initial) {
        this.props.updateStep(null, 4)
      } else {
        if (this.props.setInitial) {
          this.props.setInitial()
        }
        this.props.history.push('/patient/dashboard')
      }
    } else {
      toast(<AlertError message="failed" />)
    }
  }
  render() {
    return (
      <div class="dashboard-content-box">
        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          ''
        ) : (
          <DashboardTopBar
            step={this.props.step}
            updateStep={this.props.updateStep}
          />
        )}
        <form onSubmit={this.handleSubmit} id="myform">
          <div
            className={
              window.location.pathname ===
              '/patient/dashboard/profile-information'
                ? ''
                : 'dc-body d-flex flex-wrap align-items-lg-center'
            }
          >
            <div class="dc-left">
              {/* <h4 class="d-block d-xl-none">Verify ID</h4> */}
              <p>
                Verify your Identity (Upload your Government ID, Driving
                License, etc )
              </p>
              <div class="file-upload add-file-upload">
                <div class="image-upload-wrap">
                  <input
                    class="file-upload-input"
                    type="file"
                    onChange={this.fileUpload}
                    disabled={this.state.photoStatus === 'Loading'}
                    accept="image/*"
                  />
                  <div class="drag-text">
                    <h3>Upload Photo ID (Optional)</h3>
                  </div>
                </div>
              </div>
              <p>
                Disclaimer : Uploading your photo id is advised before your
                first appointment on YesMindy for smooth experience.
              </p>
              {this.state.data.photo_id && (
                <div class="file-upload add-file-upload">
                  <div class="drag-text">
                    <img
                      src={
                        process.env.REACT_APP_S3URL + this.state.data.photo_id
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
            {window.location.pathname ===
            '/patient/dashboard/profile-information' ? (
              ''
            ) : (
              <div className="dc-right">
                <div className="dc-right-content">
                  <div className="dc-img-box">
                    <img src={rocket} alt="" />
                  </div>
                  <h1>
                    Welcome to <span>Yes</span>Mindy
                  </h1>
                  <p>Let's complete your profile</p>
                </div>
              </div>
            )}
          </div>
        </form>

        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          <div className="dc-bottom-box">
            <SubmitBtn
              label="SAVE and NEXT"
              form="myform"
              loading={this.state.loading}
              btnClass="btn btn-default no-shadow"
            />
          </div>
        ) : (
          <div className="dc-footer d-flex align-items-center justify-content-between">
            <button
              className="btn btn-grey"
              onClick={(e) => {
                this.props.updateStep(e, 2)
              }}
            >
              Go Back
            </button>
            <SubmitBtn
              label="SAVE and NEXT"
              form="myform"
              loading={this.state.loading}
              btnClass={this.state.btnClass}
            />
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  loading: getPatient(state).loading,
})
const mapDispatchToProps = (dispatch) => ({
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientId),
)
