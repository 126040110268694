import React, { Component } from "react";

import Header from "../header";
import LeftBar from "../leftBar";
import PersonalDetails from "./personalDetails";
import VerifyOtp from "./verifyOtp";
import PersonalDetailsStep from "../../../patient/profile/personalDetails";

class AddPatient extends Component {
  state = {
    data: {},
    step: 1,
    sidebarToogle: false,
    id: "",
  };
  toggleSidebar = () => {
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };

  componentDidMount() {
    document.body.classList.remove("p-0");
    document.body.classList.add(
      this.props.history.location.pathname !== "/staff/addPatient"
        ? "dashboard"
        : "dashboard-staff"
    );
  }

  updateData = (data) => {
    this.setState({ data });
    console.log(data);
  };
  updateStep = (step) => this.setState({ step });
  render() {
    return (
      <div className={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
        {this.props.history.location.pathname !== "/staff/addPatient" && (
          <LeftBar />
        )}
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div className="dashboard-main-container">
          {this.state.step === 1 ? (
            <PersonalDetails
              updateData={this.updateData}
              updateStep={this.updateStep}
            />
          ) : this.state.step === 2 ? (
            <VerifyOtp
              data={this.state.data}
              setId={(id) => this.setState({ id: id })}
              updateStep={this.updateStep}
            />
          ) : (
            <>
              <PersonalDetailsStep
                isDoctor={true}
                patientId={this.state.id}
                {...this.props}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default AddPatient;
