import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { validateWidgetSettings } from "../../util/common";

import { presenceStyle } from "./style";

const statusindicator = (props) => {
  //if user presence is disabled in chat widget
  if (
    validateWidgetSettings(props.widgetsettings, "show_user_presence") === false
  ) {
    return null;
  }

  const borderWidth = props.borderWidth;
  const borderColor = props.borderColor;
  const cornerRadius = props.cornerRadius;

  const getStyle = () => ({
    borderWidth: borderWidth,
    borderStyle: "solid",
    borderColor: borderColor,
    borderRadius: cornerRadius,
  });

  return <span style={presenceStyle(props)} className="presence"></span>;
};

// Specifies the default values for props:
statusindicator.defaultProps = {
  borderWidth: "1px",
  borderColor: "#AAA",
  cornerRadius: "50%",
};

statusindicator.propTypes = {
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  cornerRadius: PropTypes.string,
};

export default statusindicator;
