import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import {
  getAppointment,
  loadAllAppointmentForDoctor,
} from "../../../store/appointment";
import AppointmentList from "./appointmentList";
import { getDoctor, loadDoctorProfile } from "../../../store/doctor";
import { loadChatForDoctor } from "../../../store/chats";

function AllAppointments(props) {
  const [content, setContent] = useState(false);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [initialData, setInitialData] = useState([]);
  const [paginate, setPaginate] = useState([]);

  useEffect(() => {
    props.loadDoctorProfile();
    document.body.className = "dashboard";
    props.loadAllAppointmentForDoctor({ page: page });
  }, []);

  const { doctorAppointment, loading } = props;

  useEffect(() => {
    let { data, pagination } = doctorAppointment;
    setPaginate(pagination);
    setInitialData([...data]);
  }, [doctorAppointment]);

  const loadFunc = () => {
    if (
      Math.ceil(
        props.doctorAppointment.pagination.filtered_record /
          props.doctorAppointment.pagination.record_per_page
      ) > page
    ) {
      props.loadAllAppointmentForDoctor(
        {
          page: page + 1,
        },
        callbackLoad
      );
      setPage(page + 1);
    }
  };

  const callbackLoad = (res) => {
    if (res && res.status === 200) {
      const { data, pagination } = res.data;
      setPaginate(pagination);
      setInitialData([...initialData, ...data]);
    }
  };

  const toggleContent = (id) => {
    setContent(!content);
    setId(id);
  };

  const UpdateStar = (id) => {
    props.updateStar(id, callback);
  };
  const callback = (res) => {
    if (res && res.status === 200) {
      props.loadAllAppointmentForDoctor({
        page: page,
      });
    }
  };

  return (
    <div
      class="tab-pane card fade show active"
      id="allAppointments"
      role="tabpanel"
      aria-labelledby="allAppointments-tab"
    >
      <div class="card-header">All Appointments</div>
      <div class="content-body show add-scrollbar  scrollbar-dynamic">
        <div class="appointment-history-box">
          {/* <div class="seprator-text-row">
              <h4>Today</h4>
            </div> */}
          <ul class="new-profile-list">
            <AppointmentList
              loadChatForDoctor={props.loadChatForDoctor}
              doctorProfile={props.doctorProfile}
              doctorAppointment={initialData}
              pagination={paginate}
              loadFunc={loadFunc}
              loading={loading}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  loadAllAppointmentForDoctor: (params, callback = null) =>
    dispatch(loadAllAppointmentForDoctor(params, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadChatForDoctor: (id) => dispatch(loadChatForDoctor(id)),
});
const mapStateToProps = (state) => ({
  doctorAppointment: getAppointment(state).doctorAppointment,
  loading: getAppointment(state).loadingDoctorAppointment,
  doctorProfile: getDoctor(state).profile,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AllAppointments)
);
