import React, { Component } from 'react'
import logo from '../../../include/images/yes-mindy-main-logo-white-color.png'
import UserSelection from '../userSelection'
import PartnerDetails from './partnerDetails'
import PersonalDetails from './personalDetails'
import SetPassword from './setPassword'
import SuccessRegister from './successRegister'
import VerifyMobile from './verifyMobile'
import VerifyOtp from './verifyOtp'
import PartnerComletet from './partnerCompleted'
import VerifyPartner from './verifyPartner'
import PatientDetails from './patientDetails'
import GuardianDetails from './guardianlDetails'
import { clearAllToken } from '../../services/localStorageServices'
import { connect } from 'react-redux'
import { loadSeo } from '../../../store/seo'
import { withRouter } from 'react-router-dom'

class RequestOtp extends Component {
  state = {
    heroClass: 'member-bg hero-background-sp1',
    appointment: false,
    accountFor: 'self',
    partnerId: false,
    step: 1,
    type: 1,
    verifyMobile: {},
    personalDetails: {},
    guardianDetails: {},
    patientDetails: {},
    partnerDetail: {},
    passwordInfo: {},
    inforMation: [
      [
        'Begin Your Journey Of Wellness',
        'Mindy- A trusted provider & holistic mental health platform',
        'Highly Secured Platform',
        'Information You Share Is For Us Only',
        'Your Security And Privacy Is Of High Concern To Us',
        '',
        'Let Us Know A Bit About Your Partner',
        'Your Profile Is Successfully Created',
        '',
        'Information You Share Is For Us Only',
        'We Will Be With You At Every Step Towards The Recovery Of Your Loved One',
      ],
    ],
  }

  componentDidMount = () => {
    this.props.loadSeo('patient-registration')
    if (this.props.match.params.id)
      this.setState({ step: 9, partnerId: this.props.match.params.id })
    if (this.props.match.params.app) this.setState({ appointment: true })
    if (localStorage.getItem('x-auth-token')) {
      if (localStorage.getItem('patient')) {
        const patient = JSON.parse(localStorage.getItem('patient'))
        if (patient.profile_completed) clearAllToken()
        const accountFor = localStorage.getItem('accountFor')
        const verifyMobile = { mobile: patient.mobile }
        if (patient.date_of_birth) verifyMobile.DOB = patient.date_of_birth
        this.updateStep(null, accountFor === 'guardian' ? 10 : 4)
        this.setState({
          accountFor,
          verifyMobile,
        })
      }
    }
  }

  setPasswordInfo = passwordInfo => {
    this.setState({ passwordInfo })
  }

  updateStep = (e, step, accountFor = false) => {
    if (e) e.preventDefault()
    this.setState({ step })
    if (accountFor) {
      this.setState({ accountFor })
      localStorage.setItem('accountFor', accountFor)
    }
  }

  updateVerifyMobile = verifyMobile => {
    this.setState({ verifyMobile })
  }

  updatePersonalDetails = personalDetails => {
    this.setState({ personalDetails })
  }

  updatePatientDetails = patientDetails => {
    this.setState({ patientDetails })
  }

  updateGuardianDetails = guardianDetails => {
    this.setState({ guardianDetails })
  }
  updatePartnerDetails = partnerDetail => {
    this.setState({ partnerDetail })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.step !== prevState.step) {
      switch (this.state.step) {
        case 1:
          return this.setState({ heroClass: 'member-bg hero-background-sp1' })
        case 2:
          return this.setState({ heroClass: 'member-bg hero-background-sp4' })

        case 3:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        case 4:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        case 5:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })
        case 6:
          return this.setState({ heroClass: 'member-bg hero-background-sp2' })
        case 7:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        case 8:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })
        case 9:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        case 10:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        case 11:
          return this.setState({ heroClass: 'member-bg hero-background-sp3' })

        default:
          break
      }
    }
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <>
            <UserSelection
              appointment={this.state.appointment}
              updateStep={this.updateStep}
            />
          </>
        )

      case 2:
        return (
          <VerifyMobile
            accountFor={this.state.accountFor}
            updateStep={this.updateStep}
            step={this.state.step}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
            register={true}
          />
        )

      case 3:
        return (
          <VerifyOtp
            accountFor={this.state.accountFor}
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        )

      case 4:
        return (
          <PersonalDetails
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updatePersonalDetails={this.updatePersonalDetails}
            personalDetails={this.state.personalDetails}
          />
        )

      case 5:
        return (
          <SetPassword
            setPasswordInfo={this.setPasswordInfo}
            updateStep={this.updateStep}
            allState={this.state}
            accountFor={this.state.accountFor}
            partnerId={this.state.partnerId}
          />
        )
      case 6:
        return <SuccessRegister updateStep={this.updateStep} />
      case 7:
        return (
          <PartnerDetails
            updateStep={this.updateStep}
            allState={this.state}
            accountFor={this.state.accountFor}
            updatePartnerDetails={this.updatePartnerDetails}
          />
        )

      case 8:
        return (
          <PartnerComletet
            allState={this.state}
            partnerDetail={this.state.partnerDetail}
          />
        )
      case 9:
        return (
          <VerifyPartner
            setPasswordInfo={this.setPasswordInfo}
            updateStep={this.updateStep}
            allState={this.state}
            accountFor={this.state.accountFor}
            partnerId={this.state.partnerId}
          />
        )

      case 10:
        return (
          <PatientDetails
            updatePatientDetails={this.updatePatientDetails}
            setPasswordInfo={this.setPasswordInfo}
            updateStep={this.updateStep}
            allState={this.state}
            accountFor={this.state.accountFor}
          />
        )

      case 11:
        return (
          <GuardianDetails
            updateGuardianDetails={this.updateGuardianDetails}
            setPasswordInfo={this.setPasswordInfo}
            updateStep={this.updateStep}
            allState={this.state}
            accountFor={this.state.accountFor}
          />
        )

      default:
        break
    }
  }
  //render function
  render() {
    return (
      <div className='member-container'>
        <div className='top-logo'>
          <a href='/'>
            <img src={logo} alt='' />
          </a>
        </div>

        <div className={this.state.heroClass}></div>
        <div className='member-box d-flex login-form'>
          <div className='member-left'>
            <div className='ml-box'>
              <h1>
                {this.state.accountFor === 'guardian' && this.state.step === 2
                  ? 'Thank You For Taking Care Of Your Loved Ones.'
                  : this.state.inforMation[this.state.type - 1][
                      this.state.step - 1
                    ]}
              </h1>

              {this.state.step === 7 && (
                <p className='mb-0'> We Will Get In Touch.</p>
              )}
              {this.state.step === 8 && (
                <p className='mb-0'>
                  Welcome To The Most Professional, Secured and Integrated
                  Platform For All Your Mental Health Needs.
                </p>
              )}
            </div>
          </div>
          {this.leftSideStep()}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
})

export default withRouter(connect(null, mapDispatchToProps)(RequestOtp))
