import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchAllAssessmentTask,
  getAssessment,
} from '../../../../store/assessment'
import NoRecord from '../../../common/noRecord'
import DashboardHeader from '../../profile/common/dashboardHeader'
import DashboardLeftBar from '../../profile/common/dashboardLeftBar'
import Task from './task'

class Timeline extends Component {
  state = {
    step: 1,
  }

  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }
  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
    this.props.loadAllAssessmentTask({ 'category[]': 'Psychoeducation' })
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }

  render() {
    return (
      <>
        <DashboardLeftBar updateStep={this.updateStep} />
        <DashboardHeader />
        <div className="dashboard-main-container">
          <div className="timeline-box">
            <h4>For My Knowledge</h4>
            <div className="chatbot-box">
              <div className="chatbot-body">
                <div className="tab-content" id="myTabContent">
                  {this.props.assessmentTask.length > 0 ? (
                    <Task assessmentTask={this.props.assessmentTask} />
                  ) : (
                    <NoRecord
                      header="No Knowledge Material Shared At This Moment For You"
                      text="believes that right knowledge is the first step to wellness and recovery. It equips you with right choices but only if you learn from authentic sources. Your experts will share  YesMindy created & verified materials for your better awareness."
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  assessmentTask: getAssessment(state).assessmentTask,
})
const mapDispatchToProps = (dispatch) => ({
  loadAllAssessmentTask: (params) => dispatch(fetchAllAssessmentTask(params)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Timeline),
)
