import React from "react";

import { jsx } from "@emotion/react";

import { checkMessageForExtensionsData } from "../../util/common";

import ToolTip from "../ToolTip";
import ReplyCount from "../ReplyCount";
import ReadReciept from "../ReadReciept";
import RegularReactionView from "../RegularReactionView";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageVideoWrapperStyle,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle,
} from "./style";

class SenderVideoBubble extends React.PureComponent {
  messageFrom = "sender";

  constructor(props) {
    super(props);

    const message = Object.assign({}, props.message, {
      messageFrom: this.messageFrom,
    });

    this.state = {
      message: message,
      isHovering: false,
    };
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.message);
    const currentMessageStr = JSON.stringify(this.props.message);

    if (previousMessageStr !== currentMessageStr) {
      const message = Object.assign({}, this.props.message, {
        messageFrom: this.messageFrom,
      });
      this.setState({ message: message });
    }
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  render() {
    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.state.message,
      "reactions"
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            style={messageReactionsWrapperStyle()}
            className="message__reaction__wrapper"
          >
            <RegularReactionView
              {...this.props}
              message={this.state.message}
              reaction={reactionsData}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = <ToolTip {...this.props} message={this.state.message} />;
    }

    return (
      <div
        style={messageContainerStyle()}
        className="sender__message__container message__video"
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
      >
        {toolTipView}

        <div style={messageWrapperStyle()} className="message__wrapper">
          <div
            style={messageVideoWrapperStyle(this.props)}
            className="message__video__wrapper"
          >
            <video controls src={this.state.message.data.url}></video>
          </div>
        </div>

        {messageReactions}

        <div
          style={messageInfoWrapperStyle()}
          className="message__info__wrapper"
        >
          <ReplyCount {...this.props} message={this.state.message} />
          <ReadReciept {...this.props} message={this.state.message} />
        </div>
      </div>
    );
  }
}

export default SenderVideoBubble;
