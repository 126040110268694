import React, { Component } from 'react'
import arrowtr from './../../../include/images/arrow-tr.svg'

import locationIcon from '../../../include/images/location-icon.svg'
import {
  cancellAppointment,
  getAppointment,
  loadAppointmentDetailPatient,
  loadAppointmentCheckDetailPatient,
  updateOrder,
  loadDate,
} from '../../../store/appointment'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import AlertError from '../../common/alertError'
import checkmark from '../../../include/images/alert-error-icon.svg'
import PatientPdf from '../../../include/images/Patientbrochure_YesMindy.pdf'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import moment from 'moment'
class AppointmentPay extends Component {
  state = {
    expired: false,
  }

  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  componentDidMount() {
    console.log('this.props', this.props)
    this.props.loadAppointmentDetailPatient(
      this.props.match.params.id,
      this.callBack
    )
  }

  payNow = e => {
    const {
      loadingDetail,
      appointmentCheckDetail: { data },
    } = this.props.getAppointment
    e.preventDefault()
    const res = this.state.orderDetail
    const patientData = data.patient_id
    var options = {
      key: process.env.REACT_APP_REZORPAY,
      amount: data.amount * 100,
      currency: 'INR',
      name: 'YesMindy',
      description: 'Appointment',
      image:
        process.env.REACT_APP_S3URL + 'website/yes-mindy-color-logo-small.png',
      order_id: data.order_id,
      handler: response => {
        this.props.updateOrder({ payment_info: response }, this.callBackPayment)
        console.log('paymentsuccess', response)
      },
      prefill: {
        name: patientData.first_name + ' ' + patientData.last_name,
        email: patientData.email,
        contact: patientData.mobile,
      },
      notes: {
        address: 'Appointment',
      },
      theme: {
        color: '#621ac0',
      },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      console.log('paymentfailed', response)
    })
    rzp1.open()
  }

  callBackPayment = res => {
    this.removeBodyClass()
    if (res.status === 200) {
      this.props.history.push('/patient/appointment-success')
    } else {
      toast(<AlertError message={'Something went wrong.'} />)
    }
  }

  removeBodyClass = e => {
    if (e) e.preventDefault()
    document.body.className = 'p-0'
  }

  callBack = res => {
    console.log('res', res)
    if (res.data.error) {
      this.setState({ expired: true })
    }
  }

  render() {
    const {
      loadingDetail,
      appointmentCheckDetail: { data },
    } = this.props.getAppointment

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    if (loadingDetail) {
      return (
        <div className='d-flex justify-content-center align-items-center pt-5 mt-5'>
          <div className=''>
            <h4>Loading...</h4>
          </div>
        </div>
      )
    }
    if (this.state.expired) {
      return (
        <div class='d-flex align-items-center justify-content-center'>
          <div class='member-right-content d-md-flex align-items-center justify-content-center text-center'>
            <div class='mr-box'>
              <div class='form-check-icon'>
                <img src={checkmark} alt='' />
              </div>
              <h3>Payment Link Expired</h3>
              <Link to='/patient/dashboard' class='btn btn-primary btn-block'>
                Proceed to Dashboard
              </Link>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='side-pannel-box'>
        <div className='tab-sidebar-top d-flex align-items-center'>
          <h6>Review &amp; Pay</h6>
        </div>
        <div className='pannel-tab-box'>
          <div className='card-box'>
            <div className='cb-upper d-flex flex-wrap align-items-start'>
              <div className='cb-upper-content d-flex flex-wrap'>
                <div className='cb-img-box'>
                  <img
                    src={
                      data &&
                      data.doctor_id &&
                      process.env.REACT_APP_S3URL + data.doctor_id.image_url
                    }
                    alt=''
                  />
                </div>
                <div class='cb-text-box'>
                  <div class='doc-intro-desc  font-montserrat'>
                    <h5> {data && data.doctor_id && data.doctor_id.name}</h5>
                    <h6>
                      {data &&
                        data.doctor_id &&
                        data.doctor_id.graduation &&
                        data.doctor_id.graduation.education}

                      {data &&
                        data.doctor_id &&
                        data.doctor_id.post_graduation &&
                        ', ' + data.doctor_id.post_graduation.education}
                    </h6>

                    <h6>
                      {' '}
                      {data && data.doctor_id && data.doctor_id.field_type.name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='pannel-intro-box'>
            <div className='separator'></div>
            <div className='pi-body d-flex flex-wrap'>
              <div className='pi-left'>
                <h3>Date &amp; Timing</h3>
                <p>
                  {new moment(data.appointment_time).utc().date()}{' '}
                  {months[new moment(data.appointment_time).utc().month()]} |{' '}
                  {this.formatAMPM(new Date(data.appointment_time))}
                </p>
              </div>

              <div className='pi-right'>
                <h3>Type of visit</h3>
                <p>{_.capitalize(data.appointment_type)}</p>
              </div>
            </div>
            <div className='pi-body d-flex flex-wrap'>
              <div className='pi-left'>
                <ul>
                  <li>
                    <p>Consultation Charges</p>
                  </li>
                  <li>
                    <p>Personal Dashboard Fee</p>
                  </li>
                  <li>
                    <p>GST</p>
                  </li>
                  <li>
                    <p>Total</p>
                  </li>
                </ul>
              </div>
              <div className='pi-right'>
                <ul>
                  <li>
                    <p>
                      ₹{' '}
                      {data &&
                        data.consultation_charges &&
                        data.consultation_charges.toFixed(2)}
                    </p>
                  </li>
                  <li>
                    <p>
                      ₹{' '}
                      {data &&
                        data.convenience_charges &&
                        data.convenience_charges.toFixed(2)}
                    </p>
                  </li>
                  <li>
                    <p>₹ {data && data.taxes && data.taxes.toFixed(2)}</p>
                  </li>
                  <li>
                    <p>₹ {data && data.amount && data.amount.toFixed(2)}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className='separatorNew'></div>
            <div className='pi-body d-flex flex-wrap'>
              <p style={{ fontSize: '11px', marginBottom: 0 }}>
                *Cancellation is permitted only up to 12 hrs prior to the
                scheduled time of appointment
              </p>
              <p
                className='boldFont'
                style={{ fontSize: '11px', cursor: 'pointer' }}
                onClick={() => {
                  window.open(PatientPdf)
                }}>
                What is my personal dashboard?
              </p>
            </div>

            {data && data.status === 1 && (
              <div className='pi-body d-flex flex-wrap'>
                <p style={{ fontSize: '11px' }}>
                  You already paid to this appointment
                </p>
              </div>
            )}

            {data && data.status && data.status === 1 ? (
              <Link to='/patient/dashboard' class='btn btn-primary'>
                Proceed to Dashboard
              </Link>
            ) : (
              <a href='#!' className='btn btn-primary' onClick={this.payNow}>
                Pay ₹ {data && data.amount && data.amount.toFixed(2)}
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: getAppointment(state).loading,
  getAppointment: getAppointment(state),
})
const mapDispatchToProps = dispatch => ({
  loadAppointmentDetailPatient: (id, callBack) =>
    dispatch(loadAppointmentCheckDetailPatient(id, callBack)),
  updateOrder: (data, callback) => dispatch(updateOrder(data, callback)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentPay)
)
