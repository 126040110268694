import React, { Component } from "react";
import logo from "./../../include/images/pdf/yes-mindy-logo.png";
import footerLogo from "./../../include/images/pdf/footer-logo.png";
import moment from "moment";

class PrintPres extends Component {
  render() {
    const {
      doctor_id,
      patient_id,
      appointment_id,
      diagnosis,
      medical_prescription,
      lab_investigations,
      radiology,
      special_advice,
      referral,
      followup_session,
      to_chemist,
      session_participants,
      duration_of_session,
      chief_complaints,
      objective,
      therapy_technique,
      theray_method,
      plan_for_the_next_session,
      key_issue,
    } = this.props.forAppointment;
    console.log("this.props.", this.props);
    return (
      <div className="relativeCSS">
        <link rel="stylesheet" href="/pdf.css" />

        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          height="100%"
          width="90%"
          align="middle"
          style={{ margin: "15px auto", background: "#fff" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "2px dashed #E4E4E4",
                  padding: "23px 45px 10px",
                }}
              >
                <img width="90" height="50" src={logo} alt="" />
              </th>
              <th
                style={{
                  borderBottom: "2px dashed #E4E4E4",
                  padding: "23px 45px 10px",
                }}
                align="right"
                valign="middle"
              >
                <img
                  style={{ display: "block", marginLeft: "auto" }}
                  width="55"
                  height="25"
                  src={
                    process.env.REACT_APP_S3URL +
                    doctor_id.clinic_details.photos
                  }
                  alt=""
                />
              </th>
            </tr>
          </thead>
          <tbody style={{ height: "90vh" }}>
            <tr>
              <th
                style={{
                  width: "50%",
                  lineHeight: "17px",
                  borderBottom: "5px solid #5E21B5",
                  padding: "5px 45px 9px",
                }}
                align="left"
                valign="top"
              >
                <b style={{ fontSize: "12px" }}>{doctor_id.name}</b>
                <p style={{ fontSize: "11px", fontWeight: "400" }}>
                  {" "}
                  {doctor_id.graduation && doctor_id.graduation.education}
                  {doctor_id.post_graduation &&
                    ", " + doctor_id.post_graduation.education}
                  {doctor_id.super_speciality &&
                    ", " + doctor_id.super_speciality.education}
                </p>
                <p style={{ fontSize: "11px", fontWeight: "400" }}>
                  {doctor_id.field_type.name}
                </p>
                <p style={{ fontSize: "11px", fontWeight: "400" }}>
                  Regd :{" "}
                  {doctor_id.documents_url &&
                    doctor_id.documents_url.registration_license &&
                    doctor_id.documents_url.registration_license.licence_no}
                </p>
                <p style={{ fontSize: "11px", fontWeight: "400" }}>
                  {doctor_id.clinic_details &&
                    doctor_id.clinic_details.city &&
                    doctor_id.clinic_details.city}
                  {doctor_id.clinic_details &&
                    doctor_id.clinic_details.state &&
                    ", " + doctor_id.clinic_details.state}
                </p>
              </th>
              <th
                style={{
                  width: "50%",
                  lineHeight: "17px",
                  borderBottom: " 5px solid #5E21B5",
                  padding: "5px 45px 9px",
                }}
                align="right"
                valign="top"
              >
                <address style={{ fontSize: "11px", fontStyle: "normal" }}>
                  <p>
                    <strong>Clinic :</strong>{" "}
                    {doctor_id.clinic_details &&
                      doctor_id.clinic_details.address &&
                      doctor_id.clinic_details.address + ", "}
                    {doctor_id.clinic_details &&
                      doctor_id.clinic_details.city &&
                      doctor_id.clinic_details.city}
                    {doctor_id.clinic_details &&
                      doctor_id.clinic_details.state &&
                      ", " + doctor_id.clinic_details.state}
                  </p>
                </address>
                {doctor_id.clinic_details && doctor_id.clinic_details.phone && (
                  <a
                    style={{ fontSize: "11px", fontStyle: "normal" }}
                    href="{tel:${doctor_id.clinic_details.phone}}"
                  >
                    {doctor_id.clinic_details.phone}
                  </a>
                )}
              </th>
            </tr>
            <tr>
              <td
                align="center"
                valign="top"
                width="100%"
                colspan="2"
                style={{ padding: "5px 45px 9px" }}
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  id="emailBody"
                  style={{ padding: "0 45px 60px" }}
                >
                  <tr>
                    <td
                      style={{
                        width: "54%",
                        padding: "15px 0",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      <table cellpadding="0" cellspacing="0">
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>
                              Name :{" "}
                              {patient_id.first_name +
                                " " +
                                patient_id.last_name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>
                              Age :
                              {moment().diff(
                                moment(patient_id.date_of_birth),
                                "years"
                              )}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>Gender :{patient_id.sex}</strong>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "46%",
                        padding: "15px 0",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      <table>
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>
                              UHID :{patient_id.yesmindy_identification_number}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>
                              Session date :{" "}
                              {moment(appointment_id.appointment_time)
                                .utc()
                                .format("lll")}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 0, lineHeight: "20px" }}>
                            <strong>
                              Mode of visit :{appointment_id.appointment_type}
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      colspan="2"
                      style={{ fontSize: "12px", lineHeight: "16px" }}
                    >
                      <b>Rx</b>
                    </td>
                  </tr> */}

                  {doctor_id.field_type.name === "Psychiatrist" && (
                    <>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Diagnosis</b>
                          </p>
                          <p>{diagnosis}</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            padding: "0px 0 0px",
                            fontSize: "12px",
                            lineHeight: "18px",
                            paddingBottom: "20px",
                          }}
                        >
                          <table class="table" cellpadding="0" cellspacing="0">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "13%",
                                  }}
                                >
                                  Formulation
                                </th>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "17%",
                                  }}
                                >
                                  Composition
                                </th>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "16%",
                                  }}
                                >
                                  Brand name
                                </th>

                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "11%",
                                  }}
                                >
                                  Dose
                                </th>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "13%",
                                  }}
                                >
                                  Frequency
                                </th>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "11%",
                                  }}
                                >
                                  Days
                                </th>
                                <th
                                  style={{
                                    padding: "9px 5px 17px",
                                    width: "13%",
                                  }}
                                >
                                  Notes
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {" "}
                              {medical_prescription &&
                                medical_prescription.map((em) => (
                                  <tr>
                                    <td
                                      align="left"
                                      valign="top"
                                      style={{ 
                                        textTransform: "uppercase",
                                        padding: "9px 5px 17px",
                                        width: "13%",
                                     }}
                                    >
                                      {em.formulation}
                                    </td>
                                    <td
                                      align="left"
                                      valign="top"
                                      style={{ 
                                        textTransform: "uppercase",
                                        padding: "9px 5px 17px",
                                        width: "17%",
                                      }}
                                    >
                                      {em.composition}
                                    </td>
                                    <td
                                      align="left"
                                      valign="top"
                                      style={{ 
                                        textTransform: "uppercase",
                                        padding: "9px 5px 17px",
                                        width: "16%",
                                      }}
                                    >
                                      {em.brand}
                                    </td>
                                    <td align="left" valign="top" style={{
                                      padding: "9px 5px 17px",
                                      width: "11%",
                                    }}>
                                      {em.dose}
                                    </td>
                                    <td align="left" valign="top" style={{
                                    padding: "9px 5px 17px",
                                    width: "13%",
                                  }}>
                                      {em.frequency.map((ef) => (
                                        <p>{ef.label}</p>
                                      ))}
                                    </td>
                                    <td align="left" valign="top" style={{
                                    padding: "9px 5px 17px",
                                    width: "11%",
                                  }}>
                                      {em.days} days
                                    </td>
                                    <td align="left" valign="top" style={{
                                    padding: "9px 5px 17px",
                                    width: "13%",
                                  }}>
                                      {em.notes}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Lab Investigations</b>
                          </p>
                          <p>
                            {lab_investigations
                              .map((ef) => ef.label)
                              .join(", ")}
                          </p>
                        </td>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Radiology Investigations</b>
                          </p>
                          <p>{radiology.map((ef) => ef.label).join(", ")}</p>
                        </td>
                      </tr>
                    </>
                  )}
                </table>
              </td>
            </tr>
            <tr>
              <td
                align="center"
                valign="top"
                width="100%"
                colspan="2"
                style={{ padding: "5px 45px 9px" }}
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  id="emailBody"
                  style={{ padding: "0 45px 60px" }}
                >
                  <tr>
                    <td
                      align="left"
                      valign="top"
                      style={{
                        width: "54%",
                        fontSize: "10px",
                        lineHeight: "14px",
                        paddingBottom: "22px",
                      }}
                    >
                      <p style={{ paddingBottom: "9px" }}>
                        <b>Session participants</b>
                      </p>
                      <p>{session_participants.join(", ")}</p>
                    </td>
                    {doctor_id.field_type.name !== "Psychiatrist" && (
                      <td
                        align="left"
                        valign="top"
                        style={{
                          width: "46%",
                          fontSize: "10px",
                          lineHeight: "14px",
                          paddingBottom: "22px",
                        }}
                      >
                        <p style={{ paddingBottom: "9px" }}>
                          <b>Key issue/Themes</b>
                        </p>
                        <p>{key_issue}</p>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td
                      align="left"
                      valign="top"
                      style={{
                        width: "54%",
                        fontSize: "10px",
                        lineHeight: "14px",
                        paddingBottom: "22px",
                      }}
                    >
                      <p style={{ paddingBottom: "9px" }}>
                        <b>Chief Complaints</b>
                      </p>
                      <p>{chief_complaints}</p>
                    </td>
                    <td
                      align="left"
                      valign="top"
                      style={{
                        width: "46%",
                        fontSize: "10px",
                        lineHeight: "14px",
                        paddingBottom: "22px",
                      }}
                    >
                      <p style={{ paddingBottom: "9px" }}>
                        <b>
                          {doctor_id.field_type.name !== "Psychiatrist"
                            ? "Task allotted"
                            : "Special instructions"}
                        </b>
                      </p>
                      <p>{special_advice}</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td
                align="center"
                valign="top"
                width="100%"
                colspan="2"
                style={{ padding: "5px 45px 9px" }}
              >
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  id="emailBody"
                  style={{ padding: "0 45px 60px" }}
                >
                  {doctor_id.field_type.name === "Psychiatrist" ? (
                    <>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Referral</b>
                          </p>
                          <p>{referral}</p>
                        </td>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Follow Up sessions</b>
                          </p>
                          <p>{followup_session}</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Follow Up sessions</b>
                          </p>
                          <p>{followup_session}</p>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Objectives of the session</b>
                          </p>
                          <p>{objective.join(", ")}</p>
                        </td>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Diagnosis</b>
                          </p>
                          <p>{diagnosis}</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Techniques</b>
                          </p>
                          <p>
                            {therapy_technique.map((et) => et.label).join(", ")}
                          </p>
                        </td>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Therapy Methods</b>
                          </p>
                          <p>{theray_method}</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "54%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Follow Up</b>
                          </p>
                          <p>{followup_session}</p>
                        </td>
                        <td
                          align="left"
                          valign="top"
                          style={{
                            width: "46%",
                            fontSize: "10px",
                            lineHeight: "14px",
                            paddingBottom: "22px",
                          }}
                        >
                          <p style={{ paddingBottom: "9px" }}>
                            <b>Others</b>
                          </p>
                          <p>{to_chemist}</p>
                        </td>
                      </tr>
                    </>
                  )}
                </table>
              </td>
            </tr>
            <tr>
              <td
                align="left"
                valign="top"
                style={{
                  width: "46%",
                  fontSize: "10px",
                  lineHeight: "14px",
                  padding: "5px 45px 22px",
                }}
              >
                <p style={{ paddingBottom: "9px" }}>
                  <b>Plan for next session</b>
                </p>
                <p>{plan_for_the_next_session}</p>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="2"
                align="left"
                valign="top"
                width="100%"
                style={{ paddingLeft: "45px", paddingBottom: "15px" }}
              >
                <img
                  width="150"
                  height="75"
                  src={
                    process.env.REACT_APP_S3URL +
                    (doctor_id && doctor_id.signarture_url)
                  }
                  alt=""
                />
              </td>
            </tr>
            <tr>
              <td colspan="2" align="left" valign="top">
                <p
                  style={{
                    fontSize: "10px",
                    lineHeight: "14px",
                    padding: "0 45px 15px",
                  }}
                >
                  <b>Signature</b>
                </p>
              </td>
            </tr>
            <tr>
              <td align="center" valign="top" width="100%" colspan="2">
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  id="emailFooter"
                >
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          borderTop: "2px dashed #E4E4E4",
                          padding: "9px 0 9px 45px",
                        }}
                      >
                        <p style={{ fontSize: "8px", lineHeight: "14px" }}>
                          Please note that the prescription Is not a Medicolegal
                          document. For any emergency / severe <br /> complaints
                          kindly contact nearby hospital/ clinic (For physical
                          consultation
                        </p>
                      </td>
                      <td
                        align="right"
                        valign="top"
                        style={{
                          borderTop: "2px dashed #E4E4E4",
                          padding: "9px 45px 9px 0",
                          lineHeight: "9px",
                        }}
                      >
                        <table>
                          <tr>
                            <td align="right" valign="top">
                              <span
                                style={{ fontSize: "8px", lineHeight: "14px" }}
                              >
                                Powered By :
                              </span>
                            </td>
                            <td align="right" valign="top">
                              <img
                                style={{ display: "block" }}
                                width="53"
                                height="15"
                                src={footerLogo}
                                alt=""
                              />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default PrintPres;
