import Demographics from './demographics'
import Header from '../header'
import LeftBar from '../leftBar'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import DetailHeader from '../detailHeader'
import { getPatient, patientDetail } from '../../../../store/patient'
import Assessment from './assessment'
import MentalHealth from './mentalHealth'
import Finish from './finish'
import Additional from './addintional'

class CaseRecordTab extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  }
  componentDidMount() {
    this.props.patientDetail(this.props.location.state)
  }
  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  toggleSidebar = () => {
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Demographics
            updateStep={this.updateStep}
            step={this.state.step}
            data={this.props.detail.data}
          />
        )
      case 2:
        return (
          <Assessment
            updateStep={this.updateStep}
            step={this.state.step}
            data={this.props.detail.data}
          />
        )
      case 3:
        return (
          <MentalHealth
            updateStep={this.updateStep}
            step={this.state.step}
            data={this.props.detail.data}
          />
        )
      case 4:
        return (
          <Additional
            updateStep={this.updateStep}
            step={this.state.step}
            data={this.props.detail.data}
          />
        )
      case 5:
        return (
          <Finish
            patientDetail={this.props.patientDetail}
            updateStep={this.updateStep}
            step={this.state.step}
            data={this.props.detail.data}
          />
        )
      default:
        break
    }
  }

  render() {
    const { data, caserecord } = this.props.detail
    return (
      <div className={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div class="dashboard-main-container">
          <div class="patients-detail-box">
            <DetailHeader detail={data} caserecord={caserecord} />
            <div class="patients-details-body">
              <div class="tab-container d-none d-md-block">
                <ul class="nav nav-tabs nav-tabs2" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 1 ? 'nav-link active' : 'nav-link'
                      }
                      onClick={(e) => this.updateStep(null, 1)}
                      id="Journey-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="Journey"
                      aria-selected="true"
                      style={{ cursor: 'pointer' }}
                    >
                      <b>Demographics</b>
                      <span>Demographics</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 2 ? 'nav-link active' : 'nav-link'
                      }
                      id="diary-tab"
                      data-toggle="tab"
                      onClick={(e) => this.updateStep(null, 2)}
                      role="tab"
                      aria-controls="diary"
                      aria-selected="false"
                      style={{ cursor: 'pointer' }}
                    >
                      <b>Assessment</b>
                      <span>Assessment</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 3 ? 'nav-link active' : 'nav-link'
                      }
                      onClick={(e) => this.updateStep(null, 3)}
                      id="caserecords-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="caserecords"
                      aria-selected="false"
                      style={{ cursor: 'pointer' }}
                    >
                      <b>Mental Health Assessment</b>
                      <span>Mental Health Assessment</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 4 ? 'nav-link active' : 'nav-link'
                      }
                      onClick={(e) => this.updateStep(null, 4)}
                      id="history-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="history"
                      aria-selected="false"
                      style={{ cursor: 'pointer' }}
                    >
                      <b>Additional Sections</b>
                      <span>Additional Sections</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 5 ? 'nav-link active' : 'nav-link'
                      }
                      onClick={(e) => this.updateStep(null, 5)}
                      id="summary-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="summary"
                      aria-selected="false"
                      style={{ cursor: 'pointer' }}
                    >
                      <b>Finish</b>
                      <span>Finish</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content-box">
                <div class="tab-content" id="myTabContent">
                  <div
                    class={
                      this.state.step === 1
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id="allpatients"
                    role="tabpanel"
                    aria-labelledby="allpatients-tab"
                  >
                    <div
                      class="card-header"
                      onClick={(e) => this.updateStep(null, 1)}
                    >
                      Demographics
                    </div>
                    <div
                      className={
                        this.state.step === 1
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      {' '}
                      {this.state.step === 1 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 2
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id="allpatients"
                    role="tabpanel"
                    aria-labelledby="allpatients-tab"
                  >
                    <div
                      class="card-header"
                      onClick={(e) => this.updateStep(null, 2)}
                    >
                      Assessment
                    </div>
                    <div
                      className={
                        this.state.step === 2
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      {' '}
                      {this.state.step === 2 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 3
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id="allpatients"
                    role="tabpanel"
                    aria-labelledby="allpatients-tab"
                  >
                    <div
                      class="card-header"
                      onClick={(e) => this.updateStep(null, 3)}
                    >
                      Mental Health Assessment
                    </div>
                    <div
                      className={
                        this.state.step === 3
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      {' '}
                      {this.state.step === 3 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 4
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id="allpatients"
                    role="tabpanel"
                    aria-labelledby="allpatients-tab"
                  >
                    <div
                      class="card-header"
                      onClick={(e) => this.updateStep(null, 4)}
                    >
                      Additional Sections
                    </div>
                    <div
                      className={
                        this.state.step === 4
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      {' '}
                      {this.state.step === 4 && this.leftSideStep()}
                    </div>
                  </div>
                  <div
                    class={
                      this.state.step === 5
                        ? 'tab-pane card fade show active'
                        : 'tab-pane card fade d-none'
                    }
                    id="allpatients"
                    role="tabpanel"
                    aria-labelledby="allpatients-tab"
                  >
                    <div
                      class="card-header"
                      onClick={(e) => this.updateStep(null, 5)}
                    >
                      Finish
                    </div>
                    <div
                      className={
                        this.state.step === 5
                          ? 'content-body show add-scrollbar  scrollbar-dynamic d-block'
                          : 'content-body show add-scrollbar  scrollbar-dynamic d-none'
                      }
                      style={{ position: 'relative' }}
                    >
                      {' '}
                      {this.state.step === 5 && this.leftSideStep()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  detail: getPatient(state).patientDetail,
})
const mapDispatchToProps = (dispatch) => ({
  patientDetail: (id) => dispatch(patientDetail(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CaseRecordTab)
