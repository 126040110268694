import React, { Component } from "react";
import {
  activity,
  attention,
  approach,
  adaptability,
  distractability,
  intensity,
  threshold,
  moodQuality,
  rhythmicity,
} from "./common";
export default class Temperament extends Component {
  render() {
    return (
      <>
        <h4>Temperament</h4>
        <div class="radio-group-box">
          <h5>Activity Level</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {activity.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.activity.find(
                      (el) => el === e.Activity
                    )}
                    id={e.Activity}
                    class="form-check-input"
                    name="activity"
                    onChange={() => this.props.changeLang3(e.Activity)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Activity}>
                    {e.Activity}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore1
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore1">Write More</label>
                <textarea
                  type="text"
                  id="writeMore1"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore1}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="radio-group-box">
          <h5>Attention Span & Persistence</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {attention.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.attention.find(
                      (el) => el === e.Attention
                    )}
                    id={e.Attention}
                    class="form-check-input"
                    name="attention"
                    onChange={() => this.props.changeLang4(e.Attention)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Attention}>
                    {e.Attention}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore2
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore2">Write More</label>
                <textarea
                  type="text"
                  id="writeMore2"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore2}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Approach Withdrawal</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {approach.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.approach.find(
                      (el) => el === e.Approach
                    )}
                    id={e.Approach}
                    class="form-check-input"
                    name="approach"
                    onChange={() => this.props.changeLang5(e.Approach)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Approach}>
                    {e.Approach}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore3
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore3">Write More</label>
                <textarea
                  type="text"
                  id="writeMore3"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore3}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Adaptability</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {adaptability.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.adaptability.find(
                      (el) => el === e.Adaptability
                    )}
                    id={e.Adaptability}
                    class="form-check-input"
                    name="adaptability"
                    onChange={() => this.props.changeLang6(e.Adaptability)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Adaptability}>
                    {e.Adaptability}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore4
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore4">Write More</label>
                <textarea
                  type="text"
                  id="writeMore4"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore4}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Distractability</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {distractability.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.distractability.find(
                      (el) => el === e.Distractability
                    )}
                    id={e.Distractability}
                    class="form-check-input"
                    name="distractability"
                    onChange={() => this.props.changeLang7(e.Distractability)}
                  />
                  <label
                    class="form-check-label ml-4"
                    htmlFor={e.Distractability}
                  >
                    {e.Distractability}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore5
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore5">Write More</label>
                <textarea
                  type="text"
                  id="writeMore5"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore5}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Intensity Of Reaction</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {intensity.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.distractability.find(
                      (el) => el === e.Distractability
                    )}
                    id={e.Intensity}
                    class="form-check-input"
                    name="intensity"
                    onChange={() => this.props.changeLang8(e.Intensity)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Intensity}>
                    {e.Intensity}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore6
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore6">Write More</label>
                <textarea
                  type="text"
                  id="writeMore6"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore6}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Threshold Of Responsiveness</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {threshold.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.threshold.find(
                      (el) => el === e.Threshold
                    )}
                    id={e.Threshold}
                    class="form-check-input"
                    name="threshold"
                    onChange={() => this.props.changeLang9(e.Threshold)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Threshold}>
                    {e.Threshold}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore7
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore7">Write More</label>
                <textarea
                  type="text"
                  id="writeMore7"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore7}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Quality Of Mood</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {moodQuality.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.moodQuality.find((el) => el === e.Mood)}
                    id={e.Mood}
                    class="form-check-input"
                    name="moodQuality"
                    onChange={() => this.props.changeLang10(e.Mood)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Mood}>
                    {e.Mood}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore8
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore8">Write More</label>
                <textarea
                  type="text"
                  id="writeMore8"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore8}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Rhythmicity</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {rhythmicity.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={this.props.rhythmicity.find(
                      (el) => el === e.Rhythmicity
                    )}
                    id={e.Rhythmicity}
                    class="form-check-input"
                    name="rhythmicity"
                    onChange={() => this.props.changeLang11(e.Rhythmicity)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.Rhythmicity}>
                    {e.Rhythmicity}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          <div class="radio-group-box">
            <div class="input-group-row">
              <div
                className={
                  this.props.writeMore9
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="writeMore9">Write More</label>
                <textarea
                  type="text"
                  id="writeMore9"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.writeMore9}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <div class="input-group-row">
            <div
              className={
                this.props.extraNote
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <label htmlFor="extraNote">Add Extra Note</label>
              <textarea
                id="extraNote"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.extraNote}
              ></textarea>
            </div>
          </div>
        </div>
        <div class="pane-seprator"></div>
      </>
    );
  }
}
