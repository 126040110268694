import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardHeader from "./common/dashboardHeader";
import DashboardLeftBar from "./common/dashboardLeftBar";
import PatientDetails from "./patientDetail";
import NomineeDetails from "./nomineeDetail";
import PatientId from "./patientId";
import PatientHistory from "./patientHistory";
import PatientWelcome from "./patientWelcome";
import { updatePatientProfile } from "../../../store/patient";
import { withRouter } from "react-router-dom";

class ProfileInformation extends Component {
  state = {
    step: 1,
  };

  updateStep = (e, step) => {
    if (e) e.preventDefault();
    this.setState({ step });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.step !== prevState.step) window.scrollTo(0, 0);
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <PatientDetails updateStep={this.updateStep} step={this.state.step} />
        );
      case 2:
        return (
          <NomineeDetails updateStep={this.updateStep} step={this.state.step} />
        );
      case 3:
        return (
          // <h1>Hello</h1>
          <PatientId updateStep={this.updateStep} step={this.state.step} />
        );
      case 4:
        return (
          <PatientHistory updateStep={this.updateStep} step={this.state.step} />
        );
      case 5:
        return (
          <PatientWelcome updateStep={this.updateStep} step={this.state.step} />
        );
      default:
        break;
    }
  };

  render() {
    return (
      <>
        <DashboardLeftBar />
        <DashboardHeader />
        <div className="dashboard-main-container">
          <div className="my-profile-box">
            <div className="d-flex align-items-start">
              <h4>My Profile</h4>
              <span className="ml-2" style={{ fontSize: "14px" }}>
                (Kindly fill the details carefully and completely for better
                consultation experience)
              </span>
            </div>
            <div className="chatbot-box">
              <div className="chatbot-head d-none d-md-block">
                <ul className="nav nav-tabs nav-tabs2">
                  <li className="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 1 ? "nav-link active" : "nav-link"
                      }
                      data-toggle="tab"
                      aria-controls="personalDetails"
                      aria-selected="true"
                    >
                      <b>Personal Details</b>
                      <span
                        className="profiletab"
                        onClick={(e) => this.updateStep(null, 1)}
                      >
                        Personal Details
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 2 ? "nav-link active" : "nav-link"
                      }
                      data-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      <b>Nominee & Emergency Contact</b>
                      <span
                        className="profiletab"
                        onClick={(e) => this.updateStep(null, 2)}
                      >
                        Nominee & Emergency Contact
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 3 ? "nav-link active" : "nav-link"
                      }
                      data-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      <b>Verify Id</b>
                      <span
                        className="profiletab"
                        onClick={(e) => this.updateStep(null, 3)}
                      >
                        Verify Id
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={
                        this.state.step === 4 ? "nav-link active" : "nav-link"
                      }
                      data-toggle="tab"
                      role="tab"
                      aria-selected="false"
                    >
                      <b>My History</b>
                      <span
                        className="profiletab"
                        onClick={(e) => this.updateStep(null, 4)}
                      >
                        My History
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chatbot-body p-md-0">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={
                      this.state.step === 1
                        ? "tab-pane card fade show active"
                        : "tab-pane card fade"
                    }
                    role="tabpanel"
                    aria-labelledby="personalDetails-tab"
                  >
                    <div
                      className="card-header"
                      onClick={(e) => this.setState({ step: 1 })}
                    >
                      Personal Details
                    </div>
                    <div
                      className={
                        this.state.step === 1
                          ? "content-body show"
                          : "content-body"
                      }
                    >
                      <div className="dc-box">
                        {this.state.step === 1 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.step === 2
                        ? "tab-pane card fade show active"
                        : "tab-pane card fade"
                    }
                    role="tabpanel"
                    aria-labelledby="personalDetails-tab"
                  >
                    <div
                      className="card-header"
                      onClick={(e) => this.setState({ step: 2 })}
                    >
                      Nominee & Emergency Contact
                    </div>
                    <div
                      className={
                        this.state.step === 2
                          ? "content-body show"
                          : "content-body"
                      }
                    >
                      <div className="dc-box">
                        {this.state.step === 2 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.state.step === 3
                        ? "tab-pane card fade show active"
                        : "tab-pane card fade"
                    }
                    role="tabpanel"
                    aria-labelledby="personalDetails-tab"
                  >
                    <div
                      className="card-header"
                      onClick={(e) => this.setState({ step: 3 })}
                    >
                      Verify Id
                    </div>
                    <div
                      className={
                        this.state.step === 3
                          ? "content-body show"
                          : "content-body"
                      }
                    >
                      <div className="dc-box">
                        {this.state.step === 3 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      this.state.step === 4
                        ? "tab-pane card fade show active"
                        : "tab-pane card fade"
                    }
                    role="tabpanel"
                    aria-labelledby="personalDetails-tab"
                  >
                    <div
                      className="card-header"
                      onClick={(e) => this.setState({ step: 4 })}
                    >
                      My History
                    </div>
                    <div
                      className={
                        this.state.step === 4
                          ? "content-body show"
                          : "content-body"
                      }
                    >
                      <div className="dc-box">
                        {this.state.step === 4 && this.leftSideStep()}
                      </div>
                    </div>
                  </div>
                  {this.state.step === 5 && (
                    <div
                      className={
                        this.state.step === 5
                          ? "tab-pane card fade show active"
                          : "tab-pane card fade"
                      }
                      role="tabpanel"
                      aria-labelledby="personalDetails-tab"
                    >
                      <div
                        className="card-header"
                        onClick={(e) => this.setState({ step: 4 })}
                      >
                        Welcome
                      </div>
                      <div
                        className={
                          this.state.step === 5
                            ? "content-body show"
                            : "content-body"
                        }
                      >
                        <div className="dc-box">
                          {this.state.step === 5 && this.leftSideStep()}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
});
export default withRouter(
  connect(null, mapDispatchToProps)(ProfileInformation)
);
