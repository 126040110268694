import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  getDocument,
  loadList,
  deletedDocument,
  addDoctorDocument,
  loadDocumentCategory,
} from '../../../store/document'
import {
  fileRequired,
  categoryRequired,
  docTitleRequired,
} from '../../common/misc'
import _ from 'lodash'
import Joi from 'joi-browser'
import Form from '../../common/form/form'
import seUploadFun from '../../services/s3Services'
import { connect } from 'react-redux'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import doc from '../../../include/images/document-img1.svg'
import closeIcon from '../../../include/images/close.svg'

import pdf from '../../../include/images/pdf-documnet.svg'
import image2 from '../../../include/images/document-img-2.svg'
import { getDoctor } from '../../../store/doctor'
import close from '../../../include/images/icon-close.svg'
import JsFileDownloader from 'js-file-downloader'
import { Modal } from 'react-bootstrap'

class MyReport extends Form {
  state = {
    deletePopup: false,
    downloading: false,
    doc_id: '',
    doc: '',
    doctor_id: '',
    patient_id: '',
    data: {
      file: '',
      document_title: '',
      category_id: '',
    },
    errors: {},
    dropdown: false,
    fileObj: {},
    showPreview: false,
  }
  downloadFile = (fileUrl) => {
    if (!this.state.downloading) {
      this.setState({ downloading: true })
      new JsFileDownloader({
        url: fileUrl,
      })
        .then(() => {
          this.setState({ downloading: false })
          // Called when download ended
          toast(<AlertSuccess message={'Document downloaded successfully.'} />)
        })
        .catch((error) => {
          this.setState({ downloading: false })
          // Called when an error occurred
          toast(
            <AlertError
              message={'Something went wrong while downloading file.'}
            />,
          )
        })
    }
  }
  toggleDeletePop = (deletePopup) => this.setState({ deletePopup })
  schema = {
    file: Joi.string()
      .required()
      .error(() => {
        return { message: fileRequired }
      }),
    category_id: Joi.string()
      .required()
      .error(() => {
        return { message: categoryRequired }
      }),
    document_title: Joi.string()
      .required()
      .error(() => {
        return { message: docTitleRequired }
      }),
  }
  fileUpload = (e) => {
    this.setState({ fileStatus: 'Loading...' })

    const fileName = 'doctor/document/' + Date.now()
    const res = seUploadFun(e.target.files[0], fileName)
    res.then((result) => {
      const { data } = this.state
      data.file = result.key
      this.setState({
        doc: e.target.files[0].name,
        fileStatus: 'Uploaded successfully.',
        data,
      })
    })
  }
  componentDidMount = () => {
    this.props.loadList(this.props.id)
    document.body.className = 'dashboard'
    this.props.loadDocumentCategory()
  }
  toggle = () => {
    this.setState({
      data: { file: '', document_title: '', category_id: '' },
      errors: {},
      fileStatus: '',
    })
    document.body.classList.remove('show-siderbar')
  }
  doSubmit = () => {
    const { file, category_id, document_title } = this.state.data
    const data = {
      file: [file],
      category_id: category_id,
      document_title: document_title,
    }

    this.props.addDoctorDocument(this.props.id, data, this.callBack)
  }
  callBack = (res) => {
    if (res && res.status === 200) {
      this.props.loadList(this.props.id)

      this.setState({
        data: { file: '', document_title: '', category_id: '' },
        errors: {},
        fileStatus: '',
      })
      document.body.classList.remove('show-siderbar')
      toast(<AlertSuccess message={'Document Added Successfully.'} />)
    } else {
      this.props.loadList(this.props.id)
      this.setState({
        data: { file: '', document_title: '', category_id: '' },
        errors: {},
        fileStatus: '',
      })
      document.body.classList.remove('show-siderbar')
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.doctor_id && this.state.doctor_id != prevState.doctor_id) {
      this.props.loadList(this.props.id, { doctor_id: this.state.doctor_id })
    } else if (
      this.state.patient_id &&
      this.state.patient_id != prevState.patient_id
    ) {
      this.props.loadList(this.props.id, { patient_id: this.state.patient_id })
    } else if (this.state.params && this.state.params != prevState.params) {
      this.props.loadList(this.props.id)
    } else if (this.state.data.file) {
      document.body.classList.add('show-siderbar')
    }
  }
  delete = (id) => {
    this.props.deletedDocument(id, this.deleteCallback)
  }
  deleteCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadList(this.props.id)
      toast(<AlertSuccess message={'Document Deleted Successfully.'} />)
    } else {
      this.props.loadList(this.props.id)
      toast(<AlertError message={'Something Went Wrong.'} />)
    }
  }
  toggleModel = (fileData = null, val) => {
    this.setState({
      showPreview: val,
      fileObj: fileData,
    })
  }
  render() {
    const { documentCategory, document } = this.props
    const category = (item) => item.category.name
    const result = _.groupBy(document, category)
    const groupArrays = Object.keys(result).map((category) => {
      return {
        category,
        doc: result[category],
      }
    })

    return (
      <>
        {this.state.showPreview && (
          <Modal
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="modal-dialog pdf-model"
            className="modal fade pdf-model"
            show={this.state.showPreview}
            onHide={() => this.toggleModel(null, false)}
            style={{ paddingLeft: 0 }}
          >
            <Modal.Header closeButton></Modal.Header>
            <div>
              {this.state.fileObj && this.state.fileObj.type == 'pdf' ? (
                <iframe
                  src={this.state.fileObj.fileUrl}
                  width="100%"
                  style={{ height: '100vh' }}
                />
              ) : (
                <img src={this.state.fileObj.fileUrl} alt="image" />
              )}
            </div>
          </Modal>
        )}
        {/* <div
          class="tab-pane card fade show active"
          id="myreport"
          role="tabpanel"
          aria-labelledby="myreport-tab"
        >
          <div class="card-header">My Reports</div>
          <div class="content-body show" style={{ overflow: "unset" }}> */}
        <div className="cb-outer">
          <div className="filter-content-box d-md-flex flex-wrap align-items-center">
            <div className="filter-text-box">
              <h6>Filters:</h6>
            </div>
            <div className="filter-check-box">
              <ul className="filter-check-list">
                <li className="filter-check-item">
                  <div className="form-group preferred-field">
                    <div className="custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={this.props.id}
                          name="me"
                          onClick={() => {
                            this.setState({
                              patient_id: this.props.id,
                              doctor_id: '',
                              params: '',
                            })
                          }}
                          checked={this.state.patient_id === this.props.id}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={this.props.id}
                        >
                          By Patient
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id={this.props.doctorProfile._id}
                          name="me"
                          onClick={() => {
                            this.setState({
                              patient_id: '',
                              doctor_id: this.props.doctorProfile._id,
                              params: '',
                            })
                          }}
                          checked={
                            this.state.doctor_id ===
                            this.props.doctorProfile._id
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={this.props.doctorProfile._id}
                        >
                          By Me
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="0"
                          name="other"
                          onClick={() => {
                            this.setState({
                              params: '0',
                              doctor_id: '',
                              patient_id: '',
                            })
                          }}
                          checked={this.state.params === '0'}
                        />
                        <label className="custom-control-label" htmlFor="0">
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="filter-right ml-auto">
              <label htmlFor="photograph2" className="btn btn-secondary">
                + Add Report
              </label>
              <input
                type="file"
                id="photograph2"
                className="d-none errorColor"
                onChange={this.fileUpload}
              />
            </div>
          </div>
        </div>
        <div class="tab-pane-content-box tpc-report">
          <div class="tpc-lower">
            {groupArrays.length === 0 ? (
              <div className="document-content-box ">No record found.</div>
            ) : (
              <>
                {groupArrays.map((e2, i) => (
                  <div className="document-content-box ">
                    <div className="tpc-month-box">
                      <h6>{e2.category}</h6>
                    </div>
                    <ul className="documents-list  d-flex  flex-wrap">
                      {e2.doc.map((e1, i) => (
                        <li
                          className="document-item"
                          onClick={() => {
                            this.setState({
                              doc_id: 0,
                            })
                          }}
                        >
                          <div className="document-box">
                            <div className="document-img-box">
                              <span className="document-intro">
                                {e1.date_added}
                              </span>

                              <div className="document-dropdown">
                                <div
                                  className={
                                    this.state.dropdown === true &&
                                    this.state.doc_id === e1._id
                                      ? 'dropright show'
                                      : 'dropright'
                                  }
                                >
                                  <a
                                    className="dropdown-toggle"
                                    href="javascript:void(0)"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        dropdown: !this.state.dropdown,
                                        doc_id: e1._id,
                                      })
                                    }}
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </a>
                                  <div
                                    className={
                                      this.state.dropdown === true &&
                                      this.state.doc_id === e1._id
                                        ? 'dropdown-menu dropdown-custom show'
                                        : 'dropdown-menu dropdown-custom'
                                    }
                                    style={{
                                      position: 'absolute',
                                      top: '0px',
                                      right: '0px',
                                      'will-change': 'transform',
                                    }}
                                    x-placement="right-start"
                                  >
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      target="_blank"
                                      onClick={(ele) => {
                                        ele.preventDefault()
                                        this.downloadFile(
                                          process.env.REACT_APP_S3URL + e1.file,
                                        )
                                      }}
                                    >
                                      Download
                                    </a>
                                    {e1.created_by.user === 'Doctor' && (
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0)"
                                        onClick={() => {
                                          this.toggleDeletePop(e1._id)
                                        }}
                                      >
                                        Delete
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="document-img"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  this.toggleModel(
                                    {
                                      fileUrl:
                                        process.env.REACT_APP_S3URL + e1.file,
                                      type:
                                        e1.file.split('.').pop() === 'pdf'
                                          ? 'pdf'
                                          : 'image',
                                    },
                                    true,
                                  )
                                }}
                              >
                                {e1.file.split('.').pop() === 'pdf' && (
                                  <img src={pdf} alt="" />
                                )}
                                {e1.file.split('.').pop() === 'jpeg' && (
                                  <img src={image2} alt="" />
                                )}
                                {e1.file.split('.').pop() === 'jpg' && (
                                  <img src={image2} alt="" />
                                )}
                                {e1.file.split('.').pop() === 'png' && (
                                  <img src={image2} alt="" />
                                )}
                                {e1.file.split('.').pop() != 'png' &&
                                  e1.file.split('.').pop() != 'jpg' &&
                                  e1.file.split('.').pop() != 'jpeg' &&
                                  e1.file.split('.').pop() != 'pdf' && (
                                    <img src={doc} alt="" />
                                  )}
                              </div>
                            </div>
                            <h4>{e1.document_title}</h4>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/* </div>
        </div> */}
        <div class="sidebar-pannel">
          <div class="sp-head d-flex align-items-center">
            <h4>Upload Documents</h4>
            <span
              class="close-icon ml-auto"
              onClick={(e) => {
                e.preventDefault()
                this.toggle()
              }}
            >
              <img src={close} alt="" />
            </span>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div class="sp-body">
              <div class="sp-doc-box d-flex align-items-center">
                {this.state.doc.split('.').pop() === 'pdf' && (
                  <img src={pdf} alt="" />
                )}
                {this.state.doc.split('.').pop() === 'jpeg' && (
                  <img src={image2} alt="" />
                )}
                {this.state.doc.split('.').pop() === 'jpg' && (
                  <img src={image2} alt="" />
                )}
                {this.state.doc.split('.').pop() === 'png' && (
                  <img src={image2} alt="" />
                )}
                {this.state.doc.split('.').pop() != 'png' &&
                  this.state.doc.split('.').pop() != 'jpg' &&
                  this.state.doc.split('.').pop() != 'jpeg' &&
                  this.state.doc.split('.').pop() != 'pdf' && (
                    <img src={doc} alt="" />
                  )}
                <p>{this.state.doc && this.state.doc}</p>
              </div>
              {this.state.errors.file && (
                <div class="form-group custom-input">
                  <label className="error">{this.state.errors.file}</label>
                </div>
              )}
              {this.renderInput('document_title', 'Document Title')}
              <h4>Select a Tag</h4>
              <ul class="tags d-flex flex-wrap">
                {documentCategory.map((e, i) => (
                  <li
                    class={
                      this.state.data.category_id === e._id ? 'active' : ''
                    }
                  >
                    <a
                      href="javascript:void(0)"
                      class="tag"
                      id={e._id}
                      onClick={(e) => {
                        const data = { ...this.state.data }
                        if (e) {
                          data.category_id = e.target.id
                          this.setState({ data })
                        }
                      }}
                    >
                      {e.name}
                    </a>
                  </li>
                ))}
              </ul>
              {this.state.errors.category_id && (
                <div class="form-group custom-input">
                  <label className="error">
                    {this.state.errors.category_id}
                  </label>
                </div>
              )}
              <div class="sb-upload-btn text-center">
                <button class="btn btn-default no-shadow">Upload</button>
              </div>
            </div>
          </form>
        </div>
        {this.state.data.file && <div class="overlay3"></div>}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered modal-sm"
          className="modal fade "
          show={this.state.deletePopup}
        >
          <>
            <div class="modal-header">
              <button
                onClick={() => {
                  this.toggleDeletePop(false)
                }}
                type="button"
                class="close"
                data-dismiss="modal"
              >
                <img src={closeIcon} alt="close popup" />
              </button>
            </div>
            <div class="modal-body">
              <h4
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Are you sure you want to delete?
              </h4>

              <div class="modal-coustom-btn d-flex align-items-center justify-content-center">
                <a
                  href="#!"
                  onClick={(e) => {
                    this.delete(this.state.deletePopup)
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}
                  class="btn btn-primary"
                >
                  Delete
                </a>
                <a
                  href="#!"
                  class="btn btn-outline no-shadow"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    e.preventDefault()
                    this.toggleDeletePop(false)
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  document: getDocument(state).list,
  doctorProfile: getDoctor(state).profile,
  documentCategory: getDocument(state).category,
})
const mapDispatchToProps = (dispatch) => ({
  loadList: (id, params) => dispatch(loadList(id, params)),
  deletedDocument: (id, callbackDelete) =>
    dispatch(deletedDocument(id, callbackDelete)),
  loadDocumentCategory: () => dispatch(loadDocumentCategory()),
  addDoctorDocument: (id, param, callback) =>
    dispatch(addDoctorDocument(id, param, callback)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyReport),
)
