import React, { Component } from 'react'
import bluestar from '../../../include/images/blue-star-icon.svg'
import AvtarImage from '../../common/avtarImage'
import star from '../../../include/images/start-0.svg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Moment from 'moment'
import { getPrescription } from '../../../store/prescription'
import Messages from './messages'
import { loadChatForDoctor } from '../../../store/chats'
import NoRecord from '../../common/noRecord'

class Chat extends Component {
  state = {
    isShow2: false,
    patient_id: '',
    doc_id: '',
    doc_name: '',
    image: '',
    field: '',
    appointmentDate: '',
    initial: true,
  }
  toggle2 = (patient_id, id, name, image, field, date) => {
    this.props.loadChatForDoctor(patient_id)
    this.setState({
      isShow2: !this.state.isShow2,
      patient_id: patient_id,
      doc_id: id,
      doc_name: name,
      image: image,
      field: field,
      appointmentDate: date,
      initial: !this.state.isShow2 ? true : false,
    })
  }
  render() {
    console.log('initisl', this.state.initial)
    return (
      <>
        <div
          class='tab-pane fade show active'
          id='chats'
          role='tabpanel'
          aria-labelledby='chats-tab'>
          {this.props.list.length > 0 ? (
            <div class='chat-seprator1'>
              <span>Chats</span>
            </div>
          ) : (
            <NoRecord
              header='No Active Chats At The Moment'
              text='believes in helping the Patients every step of their way to wellness by helping their Experts to stay approachable professionally.'
            />
          )}
          <ul class='chat-member-list bordered'>
            {this.props.list.map((e, i) => (
              <li
                class='cm-item'
                onClick={() =>
                  this.toggle2(
                    e.appointmentData.patient_id,
                    e.appointmentData.doctor_id,
                    e.first_name + ' ' + e.last_name,
                    e.thumb,
                    '',
                    Moment(e.appointment_time).format('DD/MM/YYYY')
                  )
                }>
                <div class='cm-box d-flex flex-wrap'>
                  <div class='person-details-box d-flex align-items-center flex-grow-1'>
                    <div class='person-img'>
                      {e.thumb ? (
                        <img
                          src={process.env.REACT_APP_S3URL + e.thumb}
                          alt=''
                        />
                      ) : (
                        <AvtarImage />
                      )}
                    </div>
                    <div class='person-text-box'>
                      <h3>
                        {e.first_name + ' ' + e.last_name}{' '}
                        <i>
                          {e.isStar === true ? (
                            <img src={bluestar} alt='star-icon' />
                          ) : (
                            <img src={star} alt='star-icon' />
                          )}
                        </i>
                      </h3>
                      <p>{e.yesmindy_identification_number}</p>
                    </div>
                  </div>
                  {/* <div class="pd-right-content">
                    Last Appoint.:{" "}
                    {Moment(e.appointment_time).format("DD-MM-YYYY")}
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <Messages
          isShow={this.state.isShow2}
          toggle={this.toggle2}
          doctor_id={this.state.doc_id}
          patient_id={this.state.patient_id}
          doc_name={this.state.doc_name}
          image={this.state.image}
          field={this.state.field}
          appointmentDate={this.state.appointmentDate}
          setInitial={() => {
            this.setState({
              initial: false,
            })
          }}
          initial={this.state.initial}
        />
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadChatForDoctor: id => dispatch(loadChatForDoctor(id)),
})

const mapStateToProps = state => ({
  list: getPrescription(state).doctorChatList,
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat))
