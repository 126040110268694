import axios from "axios";
import { clearAllToken } from "../../components/services/localStorageServices";
import * as actions from "../api";
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log("call async error");
    const originalRequest = error.config;
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      console.log("call UNAUTHORIZED");
      const apiUrl = error.request.responseURL.split("/");
      if (
        apiUrl[apiUrl.length - 1] !== "refreshJWT" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        if (
          window.location.pathname.split("/")[1] === "patient" ||
          window.location.pathname.split("/")[1] === "dr"
        ) {
          const headers = {
            "auth-token": process.env.REACT_APP_TOKEN,
          };
          headers["x-refresh-token"] = localStorage.getItem("x-refresh-token");
          try {
            const response = await axios.request({
              baseURL: process.env.REACT_APP_APIBASE,
              headers,
              url: "patient/refreshJWT",
              method: "GET",
            });
            localStorage.setItem("x-auth-token", response.data.xAuthToken);
            axios.defaults.headers.common["x-auth-token"] =
              response.data.xAuthToken;
            console.log(originalRequest);
            originalRequest.headers["x-auth-token"] = response.data.xAuthToken;
            return axios(originalRequest);
          } catch (error) {
            console.log("call", error);
            clearAllToken();
            var url = window.location.href;
            var strippedUrl = new URL(url);
            console.log(
              "call",
              strippedUrl.pathname,
              typeof strippedUrl.pathname
            );
            sessionStorage.setItem(
              "initialurl",
              JSON.stringify(strippedUrl.pathname)
            );
            window.location.href = "/patient/sign-in";
          }
        } else {
          {
            const headers = {
              "auth-token": process.env.REACT_APP_TOKEN,
            };
            headers["x-refresh-token"] = localStorage.getItem(
              "x-refresh-token-doctor"
            );
            try {
              const response = await axios.request({
                baseURL: process.env.REACT_APP_APIBASE,
                headers,
                url: "doctor/refreshJWT",
                method: "GET",
              });
              localStorage.setItem(
                "x-auth-tokenx-auth-token-doctor",
                response.data.xAuthToken
              );
              axios.defaults.headers.common["x-auth-token"] =
                response.data.xAuthToken;
              console.log(originalRequest);
              originalRequest.headers["x-auth-token"] =
                response.data.xAuthToken;
              return axios(originalRequest);
            } catch (error) {
              clearAllToken();
              window.location.href = "/doctor/sign-in";
            }
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const {
      url,
      method,
      data,
      params,
      onStart,
      onSuccess,
      onError,
      callback = false,
    } = action.payload;
    if (onStart) dispatch({ type: onStart });

    next(action);

    const headers = {
      "auth-token": process.env.REACT_APP_TOKEN,
    };

    if (localStorage.getItem("x-auth-token")) {
      headers["x-auth-token"] = localStorage.getItem("x-auth-token");
    } else if (localStorage.getItem("x-auth-token-doctor")) {
      headers["x-auth-token"] = localStorage.getItem("x-auth-token-doctor");
    }

    try {
      const response = await axios.request({
        baseURL: process.env.REACT_APP_APIBASE,
        headers,
        url,
        method,
        params,
        data,
      });
      // General
      dispatch(actions.apiCallSuccess(response.data));
      // Specific
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
      if (callback) callback(response);
    } catch (error) {
      // General
      dispatch(actions.apiCallFailed(error.message));
      // Specific
      if (onError) dispatch({ type: onError, payload: error.message });
      if (callback) callback(error.response);
    }
  };

export default api;
