import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearAllToken } from "../../services/localStorageServices";
import borderCheckImg from "./../../../include/images/border-check-icon.svg";

class SuccessRegister extends Component {
  state = {};

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center justify-content-center text-center">
          <div className="mr-box">
            <div className="form-check-icon">
              <img src={borderCheckImg} alt="" />
            </div>
            <h3>Your Profile Is Successfully Created</h3>
            <p className="mb-5">
              Welcome To The Most Professional, Secured and Integrated Platform
              For All Your Mental Health Needs.
            </p>
            <Link
              to="/patient/doctors"
              className="btn btn-primary btn-block mb-2"
            >
              Book an Appointment
            </Link>
            <Link
              to="/patient/dashboard"
              className="btn btn-primary btn-block ml-0"
            >
              Proceed To My Dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessRegister;
