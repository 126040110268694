import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../../common/form/form";
import SignUpLeftBar from "../../common/signUpLeftBar";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import _ from "lodash";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDoctorToken } from "../../../services/localStorageServices";
import HelpText from "../helpText";

import {
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  exprienceRequired,
  postRequired,
  placeRequired,
  timePerioFromRequired,
  timePerioFromInvalid,
  timePerioToRequired,
  timePerioToInvalid,
  experienceRequired,
  exprienceInvalid,
} from "../../../common/misc";
import SubmitBtn from "../../../common/form/submitBtn";
class Experience extends Form {
  state = {
    inputs: [1],
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    currentlyWorking: false,
    data: {
      experience_year1: "",
      post1: "",
      place1: "",
      from1: "",
      to1: "",
    },
    errors: {},
  };

  schema = {
    experience_year1: Joi.number()
      .required()
      .error((errors) => {
        console.log(errors);
        errors.forEach((err) => {
          console.log("eee", err.type);
          switch (err.type) {
            case "number.base":
              err.message = exprienceInvalid;
              break;
            case "any.required":
              err.message = experienceRequired;
              break;
          }
        });
        return errors;
      }),

    post1: Joi.string()
      .allow("")
      .error(() => {
        return { message: postRequired };
      }),

    place1: Joi.string()
      .required()
      .error(() => {
        return { message: placeRequired };
      }),
    from1: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log("eeeee", err.type);
          switch (err.type) {
            case "date.base":
              err.message = timePerioFromRequired;
              break;

            case "date.required":
              err.message = timePerioFromInvalid;
              break;
          }
        });
        return errors;
      }),

    to1: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = timePerioToRequired;
              break;

            case "date.max":
              err.message = timePerioToInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    const payload = [];
    let total_experience = 0;

    this.state.inputs.forEach((element) => {
      const obj = {
        //experience_year: this.state.data["experience_year" + element],
        post: this.state.data["post" + element],
        work_place: this.state.data["place" + element],
        from: this.state.data["from" + element],
        to: this.state.data["to" + element],
      };
      payload.push(obj);
      //total_experience += this.state.data["experience_year" + element];
    });
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(
        {
          experiences: payload,
          total_experience: this.state.data["experience_year1"],
        },
        this.callBack
      );
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push(
        "/doctor/sign-up/educational-detail/certification"
      );
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  addMore = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const NewNumber = inputs.length + 1;
    inputs.push(NewNumber);
    this.setState({ inputs });

    //const experience_year = "experience_year" + NewNumber;
    const post = "post" + NewNumber;
    const place = "place" + NewNumber;
    const from = "from" + NewNumber;
    const to = "to" + NewNumber;

    const newData = {};

    //newData[experience_year] = "";
    newData[post] = "";
    newData[place] = "";
    newData[to] = "";
    newData[from] = "";

    this.schema[from] = Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log(err.type);
          switch (err.type) {
            case "date.base":
              err.message = timePerioFromRequired;
              break;

            case "date.required":
              err.message = timePerioFromInvalid;
              break;
          }
        });
        return errors;
      });
    this.schema[to] = Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log("222222", err.type);
          switch (err.type) {
            case "date.base":
              err.message = timePerioToRequired;
              break;

            case "date.max":
              err.message = timePerioToInvalid;
              break;
          }
        });
        return errors;
      });

    this.schema[post] = Joi.string()
      .allow("")
      .error(() => {
        return { message: postRequired };
      });
    this.schema[place] = Joi.string()
      .required()
      .error(() => {
        return { message: placeRequired };
      });

    const { data, errors } = this.state;

    this.setState({
      data: { ...data, ...newData },
      errors: {},
    });
  };

  removeLast = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const removeNumber = inputs.length;
    inputs.pop();
    this.setState({ inputs });
    const newData = {};
    const newArr = {};
    //const experience_year = "experience_year" + removeNumber;
    const post = "post" + removeNumber;
    const place = "place" + removeNumber;
    const from = "from" + removeNumber;
    const to = "to" + removeNumber;

    const { data, errors } = this.state;
    //delete data[experience_year];
    delete data[post];
    delete data[place];
    delete data[from];
    delete data[to];

    //delete errors[experience_year];
    delete errors[post];
    delete errors[place];

    //delete this.schema[experience_year];
    delete this.schema[post];
    delete this.schema[place];
    delete this.schema[from];
    delete this.schema[to];
    this.setState({ data, errors: {} });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.doctorProfile.experiences &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const inputs = _.range(
        1,
        this.props.doctorProfile.experiences.length + 1
      );
      const newData = {};
      if (inputs.length > 1) {
        inputs.forEach((element, i) => {
          if (i === 0)
            newData["experience_year" + element] =
              this.props.doctorProfile.total_experience;
          newData["post" + element] =
            this.props.doctorProfile.experiences[i].post;
          newData["place" + element] =
            this.props.doctorProfile.experiences[i].work_place;

          if (this.props.doctorProfile.experiences[i].to) {
            newData["to" + element] = new Date(
              this.props.doctorProfile.experiences[i].to
            );
          } else {
            this.working();
          }
          newData["from" + element] = new Date(
            this.props.doctorProfile.experiences[i].from
          );
          if (i === 0)
            this.schema["experience_year" + element] = Joi.number()
              .min(1)
              .required()
              .error(() => {
                return { message: exprienceRequired };
              });

          this.schema["post" + element] = Joi.string()
            .allow("")
            .error(() => {
              return { message: postRequired };
            });
          this.schema["place" + element] = Joi.string()
            .required()
            .error(() => {
              return { message: placeRequired };
            });

          this.schema["from" + element] = Joi.date()
            .required()
            .error((errors) => {
              errors.forEach((err) => {
                console.log(err.type);
                switch (err.type) {
                  case "date.base":
                    err.message = timePerioFromRequired;
                    break;

                  case "date.required":
                    err.message = timePerioFromInvalid;
                    break;
                }
              });
              return errors;
            });

          if (this.props.doctorProfile.experiences[i].to) {
            this.schema["to" + element] = Joi.date()
              .required()
              .error((errors) => {
                errors.forEach((err) => {
                  console.log("222222", err.type);
                  switch (err.type) {
                    case "date.base":
                      err.message = timePerioToRequired;
                      break;

                    case "date.max":
                      err.message = timePerioToInvalid;
                      break;
                  }
                });
                return errors;
              });
          }
        });

        this.setState({ inputs, data: newData });
      }

      if (inputs.length === 1) {
        const data = {
          experience_year1: this.props.doctorProfile.total_experience,
          post1: this.props.doctorProfile.experiences[0].post,
          place1: this.props.doctorProfile.experiences[0].work_place,

          from1: new Date(this.props.doctorProfile.experiences[0].from),
        };

        if (this.props.doctorProfile.experiences[0].to) {
          data.to1 = new Date(this.props.doctorProfile.experiences[0].to);
        } else {
          this.working();
        }

        this.setState({ inputs, data });
      }
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  working = () => {
    const { data } = this.state;
    if (this.state.currentlyWorking) {
      data.to1 = "";
      this.setState({ data });
      this.schema.to1 = Joi.date()
        .required()
        .error((errors) => {
          errors.forEach((err) => {
            console.log("222222", err.type);
            switch (err.type) {
              case "date.base":
                err.message = timePerioToRequired;
                break;

              case "date.max":
                err.message = timePerioToInvalid;
                break;
            }
          });
          return errors;
        });
    } else {
      delete this.schema.to1;
      delete data.to1;
      this.setState({ data });
    }
    this.setState({ currentlyWorking: !this.state.currentlyWorking });
  };

  render() {
    console.log(this.state);
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Work Experience</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                {this.state.inputs.map((element, i) => (
                  <React.Fragment key={element}>
                    <div className="row">
                      {i === 0 && (
                        <div
                          className="col-xl-5"
                          style={{
                            borderBottom: "1px solid #e0e0e0",
                            marginBottom: "20px",
                          }}
                        >
                          {this.renderInput(
                            "experience_year" + element,
                            "Total Experience (in years)"
                          )}
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-xl-5">
                        {this.renderInput("post" + element, "Post")}
                      </div>
                      {this.state.inputs.length > 1 &&
                        this.state.inputs.length - 1 === i && (
                          <div className="col-xl-5">
                            <a
                              href=""
                              className="add-more-field m-0 errorLabel"
                              onClick={this.removeLast}
                            >
                              - Remove
                            </a>
                          </div>
                        )}
                    </div>

                    <div className="row">
                      <div className="col-xl-5">
                        {this.renderInput(
                          "place" + element,
                          "Medical College/Hospital/Clinic"
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-5">
                        {this.renderDateInput(
                          "from" + element,
                          "Time Period from"
                        )}
                      </div>
                      {i === 0 && (
                        <div className="col-xl-2">
                          <div className="form-check">
                            <input
                              name="mon"
                              onChange={this.working}
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckMon"
                              checked={this.state.currentlyWorking}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckMon"
                            >
                              Currently Working
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    {(i !== 0 || !this.state.currentlyWorking) && (
                      <div className="row">
                        <div className="col-xl-5">
                          {this.renderDateInput(
                            "to" + element,
                            "Time Period to"
                          )}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <div className="row">
                  <div className="col-xl-5">
                    <a
                      href="#"
                      onClick={this.addMore}
                      className="add-more-field mb-4"
                    >
                      + Add previous experiences
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
