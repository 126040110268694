import React, { Component } from 'react'
import sendIcon from '../../../include/images/send-icon.svg'
import { io } from 'socket.io-client'
import { getPatient, loadPatientProfile } from '../../../store/patient'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { chatReceived, getChats, loadChatForDoctor } from '../../../store/chats'
import moment from 'moment'
import Scrollbars from 'react-custom-scrollbars-2'

class Messeges extends Component {
  state = {
    ready: false,
    msgData: '',
  }

  myRef = React.createRef()
  scrollToMyRef = (appointmentDate = '') => {
    if (this.myRef.current) {
      let element = document.getElementById(appointmentDate)
      if (appointmentDate && element && this.props.initial) {
        this.myRef.current.scrollTo(
          0,
          element.offsetTop -
            (document.getElementsByClassName('sidebar-head')[0].offsetHeight +
              90),
        )
      } else {
        const scroll =
          this.myRef.current.scrollHeight - this.myRef.current.clientHeight
        this.myRef.current.scrollTo(0, scroll)
      }
    }
  }

  sendMessege = (msg) => {
    this.scrollToMyRef()
    if (msg) {
      this.socket.emit('message', {
        msg: msg,
        sender: this.props.doctor_id,
        reciever: this.props.patient_id,
        created_on: moment().format(),
      })
      this.props.setInitial()
      this.scrollToMyRef()
      this.setState({ msgData: '' })
      this.recievedMsg({
        msg: msg,
        sender: this.props.doctor_id,
        reciever: this.props.patient_id,
        created_on: moment().format(),
      })
    }
  }

  recievedMsg = (msg) => {
    this.props.setInitial()
    this.scrollToMyRef()
    this.props.chatReceived(msg)
  }

  componentWillUnmount = () => {
    this.socket.disconnect()
  }

  socket

  componentDidUpdate = (prevProps, prevState) => {
    //this.scrollToMyRef(this.props.appointmentDate);
    this.scrollToMyRef()
    // this.scrollToMyRef();
    if (
      !this.props.chatLoading &&
      this.props.chatLoading !== prevProps.chatLoading
    ) {
      this.scrollToMyRef(this.props.appointmentDate)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.chats &&
      this.props.chats.length !== prevProps.chats.length
    ) {
      this.scrollToMyRef(this.props.appointmentDate)
    }
    //if (!this.props.loading && this.props.loading !== prevProps.loading) {
    //const _id = this.props.doctor_id;
    //this.props.loadChatForDoctor(this.props.patient_id);
    // this.socket = io(process.env.REACT_APP_SOCKET_URL, {
    //   // note changed URL here
    //   autoConnect: false,
    //   transports: ["websocket"],
    //   upgrade: false,
    //   query: {
    //     _id: _id,
    //   },
    // });
    // this.socket.on("connect", () => {
    //   this.socket.on("message", (message) => {
    //     console.log("message", message);
    //     this.recievedMsg(message);
    //   });
    //   this.socket.off("message", this.recievedMsg);
    // });
    // this.socket.connect();
    // this.socket.on("disconnect", () => {
    //   this.socket.removeAllListeners();
    // });
    // }
  }

  componentDidMount = () => {
    this.props.loadChatForDoctor(this.props.patient_id)
    if (this.props.doctor_id) {
      const _id = this.props.doctor_id
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: false,
        upgrade: false,
        transports: ['websocket'],
        query: {
          _id: _id,
        },
      })
      this.socket.on('connect', () => {
        this.socket.on('message', (message) => {
          this.recievedMsg(message)
        })
        this.socket.off('message', this.recievedMsg)
      })
      this.socket.connect()
      this.socket.on('disconnect', () => {
        this.socket.removeAllListeners()
      })
    }
    //this.schrollRef.scrollToBottom();
    //this.scrollToMyRef(this.props.appointmentDate);
  }
  render() {
    return (
      <>
        <div className="card-header">Messages</div>
        <div className="content-body show">
          <div className="chat-area">
            <div className="chat" ref={this.myRef}>
              {this.props.chats.map((es, i) => (
                <>
                  {(i === 0 ||
                    moment(this.props.chats[i ? i - 1 : 0].created_on).format(
                      'DD/MM/YYYY',
                    ) !== moment(es.created_on).format('DD/MM/YYYY')) && (
                    <div
                      class="chat-seprator"
                      id={moment(es.created_on).format('DD/MM/YYYY')}
                    >
                      <span> {moment(es.created_on).format('DD/MM/YYYY')}</span>
                    </div>
                  )}
                  {this.props.doctor_id === es.sender ? (
                    <div class="chat-message-box send-message">
                      <div>
                        <div class="chat-message">{es.msg || es.message}</div>
                      </div>
                      <div class="time">
                        {moment(es.created_on).format('hh:mm:ss A')}{' '}
                      </div>
                    </div>
                  ) : (
                    <div class="chat-message-box received-message d-flex flex-column align-items-end">
                      <div class="d-flex flex-column">
                        <div class="chat-message">{es.msg || es.message}</div>
                      </div>
                      <div class="time">
                        {' '}
                        {moment(es.created_on).format('hh:mm:ss A')}{' '}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="send">
              <input
                onChange={(e) => this.setState({ msgData: e.target.value })}
                className="input-message"
                name="msgData"
                value={this.state.msgData}
                placeholder="Write your message…"
                type="text"
                id="message"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') this.sendMessege(this.state.msgData)
                }}
              />
              <button
                className="input-send"
                onClick={(e) => {
                  if (this.state.msgData) this.sendMessege(this.state.msgData)
                }}
              >
                <i>
                  <img src={sendIcon} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  psychiatrist: getPatient(state).psychiatrist,
  psychologist: getPatient(state).psychologist,
  loading: getPatient(state).loading,
  chats: getChats(state).chatData,
  chatLoading: getChats(state).loading,
})
const mapDispatchToProps = (dispatch) => ({
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  chatReceived: (data) => dispatch(chatReceived(data)),
  loadChatForDoctor: (id) => dispatch(loadChatForDoctor(id)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messeges),
)
