import React, { Component } from "react";
import close from "../../../include/images/icon-close.svg";
import icon from "../../../include/images/send-icon.svg";
import {
  chatReceived,
  getChats,
  loadChatForDoctor,
} from "../../../store/chats";
import { io } from "socket.io-client";
import { getPatient, loadPatientProfile } from "../../../store/patient";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";
import AvtarImage from "../../common/avtarImage";

class Messages extends Component {
  state = {
    ready: false,
    msgData: "",
  };
  myRef = React.createRef();
  scrollToMyRef = (appointmentDate = "") => {
    if (this.myRef.current) {
      let element = document.getElementById(appointmentDate);
      if (appointmentDate && element && this.props.initial) {
        this.myRef.current.scrollTo(0, element.offsetTop - (document.getElementsByClassName('sidebar-head')[0].offsetHeight+90));
        // element.scrollIntoView();
      } else {
        const scroll =
          this.myRef.current.scrollHeight - this.myRef.current.clientHeight;
        this.myRef.current.scrollTo(0, scroll);
      }
    }
  };
  sendMessege = (msg) => {
    if (msg) {
      this.socket &&
        this.socket.emit("message", {
          msg: msg,
          sender: this.props.doctor_id,
          reciever: this.props.patient_id,
          created_on: moment().format(),
        });
      this.props.setInitial()
      this.scrollToMyRef();
      this.setState({ msgData: "" });
      this.recievedMsg({
        msg: msg,
        sender: this.props.doctor_id,
        reciever: this.props.patient_id,
        created_on: moment().format(),
      });
    }
  };

  recievedMsg = (msg) => {
    this.props.setInitial()
    this.props.chatReceived(msg);
    this.scrollToMyRef();
    
  };

  componentWillUnmount = () => {
    this.socket && this.socket.disconnect();
  };

  socket;
  componentDidUpdate = (prevProps, prevState) => {
    this.scrollToMyRef();
    if (this.props.patient_id != prevProps.patient_id) {
      this.props.loadChatForDoctor(this.props.patient_id);
    }
    if (this.props.isShow && this.props.isShow != prevProps.isShow) {
      this.scrollToMyRef();
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    console.log('this.props',this.props)
    this.scrollToMyRef(this.props.appointmentDate);
    if (prevProps.doctor_id != this.props.doctor_id) {
      const _id = this.props.doctor_id;
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: false,
        upgrade: false,
        transports: ["websocket"],
        query: {
          _id: _id,
        },
      });
      this.socket.on("connect", () => {
        this.socket.on("message", (message) => {
          this.recievedMsg(message);
        });
        this.socket.off("message", this.recievedMsg);
      });
      this.socket.connect();
      this.socket.on("disconnect", () => {
        this.socket.removeAllListeners();
      });
    }
  };

  componentDidMount = () => {
    if (this.props.patient_id) {
      this.props.loadChatForDoctor(this.props.patient_id);
    }
    if (this.props.doctor_id) {
      const _id = this.props.doctor_id;
      this.socket = io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: false,
        upgrade: false,
        transports: ["websocket"],
        query: {
          _id: _id,
        },
      });
      this.socket.on("connect", () => {
        this.socket.on("message", (message) => {
          this.recievedMsg(message);
        });
        this.socket.off("message", this.recievedMsg);
      });
      this.socket.connect();
      this.socket.on("disconnect", () => {
        this.socket.removeAllListeners();
      });
    }
  };
  render() {
    const days = moment().diff(
      moment(this.props.lastAppointment.appointment_time),
      "days"
    );
    this.scrollToMyRef();
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? "sidebar-box notesopen"
              : "sidebar-box notesclose"
          }
          id="calculator"
        >
          <div class="sidebar-head d-flex align-items-center">
            <h5>
              Chats{" "}
              <small>
                (Active for 3 days post appointment. Not for Emergency
                purposes.)
              </small>
            </h5>
            <div
              class="close-img ml-auto"
              onClick={(e) => {
                e.preventDefault();
                this.props.toggle(this.props.patient_id, this.props.doctor_id);
              }}
            >
              <img src={close} alt="" />
            </div>
          </div>
          <div class="sidebar-body height-auto">
            <div class="chats-box">
              <div class="chats-head d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <div class="doc-thumbnails">
                    {this.props.image ? (
                      <img
                        style={{ borderRadius: "50%" }}
                        src={process.env.REACT_APP_S3URL + this.props.image}
                        alt=""
                      />
                    ) : (
                      <AvtarImage />
                    )}
                  </div>
                  <div class="doc-text-box">
                    <h4>{this.props.doc_name}</h4>
                    {/* <p>
                      {this.props.field}, Addiction {this.props.field}
                    </p> */}
                  </div>
                </div>
              </div>
              <div class="chat-area">
                <div class="chat" ref={this.myRef}>
                  {this.props.chats.map((es, i) => (
                    <>
                      {(i === 0 ||
                        moment(
                          this.props.chats[i ? i - 1 : 0].created_on
                        ).format("DD/MM/YYYY") !==
                          moment(es.created_on).format("DD/MM/YYYY")) && (
                        <div
                          class="chat-seprator"
                          id={moment(es.created_on).format("DD/MM/YYYY")}
                        >
                          <span>
                            {" "}
                            {moment(es.created_on).format("DD/MM/YYYY")}
                          </span>
                        </div>
                      )}
                      {this.props.doctor_id === es.sender ? (
                        <div class="chat-message-box send-message">
                          <div>
                            <div class="chat-message">
                              {es.msg || es.message}
                            </div>
                          </div>
                          <div class="time">
                            {moment(es.created_on).format("hh:mm:ss A")}{" "}
                          </div>
                        </div>
                      ) : (
                        <div class="chat-message-box received-message d-flex flex-column align-items-end">
                          <div class="d-flex flex-column">
                            <div class="chat-message">
                              {es.msg || es.message}
                            </div>
                          </div>
                          <div class="time">
                            {" "}
                            {moment(es.time).format("hh:mm:ss A")}{" "}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>{" "}
                {this.props.lastAppointment.appointment_time && days < 3 && (
                  <div class="send" style={{ padding: "17px 0 17px" }}>
                    <input
                      onChange={(e) =>
                        this.setState({ msgData: e.target.value })
                      }
                      className="input-message"
                      name="msgData"
                      value={this.state.msgData}
                      placeholder="Write your message…"
                      type="text"
                      id="message"
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          this.sendMessege(this.state.msgData);
                      }}
                    />{" "}
                    <button
                      className="input-send"
                      onClick={(e) => {
                        if (this.state.msgData)
                          this.sendMessege(this.state.msgData);
                      }}
                    >
                      <i>
                        <img src={icon} alt="" />
                      </i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.props.isShow === true ? "overlay4 show" : "overlay4"}
        ></div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  psychiatrist: getPatient(state).psychiatrist,
  psychologist: getPatient(state).psychologist,
  loading: getPatient(state).loading,
  chats: getChats(state).chatData,
  lastAppointment: getChats(state).lastAppointment,
});
const mapDispatchToProps = (dispatch) => ({
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  chatReceived: (data) => dispatch(chatReceived(data)),
  loadChatForDoctor: (id) => dispatch(loadChatForDoctor(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
