import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import logoImg from "../../../include/images/yes-mindy-logo.svg";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";
import { getSeo, loadContract, loadTermTemplate } from "../../../store/seo";
import AlertError from "../../common/alertError";
import { drPersonalDetailFaild } from "../../common/misc";
import TermTemplatePopup from "../../common/termTemplatePopup";
import SignUpLeftBar from "../common/signUpLeftBar";
import HelpText from "./helpText";
import moment from "moment";

class Contract extends Component {
  state = {
    contracts: false,
    popShow: false,
  };
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow });
  };

  openPopup = (e, key) => {
    e.preventDefault();
    this.props.loadTermTemplate(key);
    this.togglePopup();
  };

  componentDidMount = () => {
    this.props.loadDoctorProfile();
    this.props.loadContract("contract");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.doctorProfile.contract_accepted &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      this.setState({ contracts: true });
    }
  };
  callBack = (res) => {
    if (res.status === 200) {
      if (this.props.doctorProfile.is_approved) {
        this.props.history.push("/doctor/dashboard");
      } else {
        this.props.history.push("/doctor/sign-up/success");
      }
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };
  doSubmit = (e) => {
    e.preventDefault();
    const payLoad = {
      contract_accepted: true,
      contract_accepted_on: new Date().toDateString(),
    };
    this.props.doctorUpdate(payLoad, this.callBack);
    //this.props.history.push("/doctor/sign-up/success");
  };
  replaceText = () => {
    console.log("this.props.doctorProfile", this.props.doctorProfile);
    const { name, clinic_details, date_of_birth } = this.props.doctorProfile;

    var data = this.props.getSeo.contract.html_data;
    data = data.replaceAll("$doctorName", name);
    data = data.replaceAll(
      "$age",
      moment().diff(moment(date_of_birth), "years")
    );
    data = data.replaceAll("$city", clinic_details.city);
    return data;
  };
  render() {
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler">
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src={logoImg} alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>{this.props.getSeo.contract.header_text}</h1>
            </div>
            <div className="main-content contract-content">
              <div className="row">
                <div className="col-md-11 mb-5">
                  {this.props.getSeo.contract.html_data &&
                    ReactHtmlParser(this.replaceText())}
                  <div className="form-check">
                    <input
                      name="mon"
                      onChange={(e) =>
                        this.setState({ contracts: !this.state.contracts })
                      }
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckMon"
                      checked={this.state.contracts}
                    />
                    <label className="form-check-label" htmlFor="flexCheckMon">
                      I agree to the terms and conditions in the above-mentioned
                      contract as well as the. "
                      <a
                        href=""
                        onClick={(e) => {
                          this.openPopup(e, "PractitionerTerms");
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        style={{
                          fontWeight: "600",
                          color: "#6219c0",
                          textDecoration: "underline",
                        }}
                      >
                        terms of use
                      </a>
                      " and "
                      <a
                        href=""
                        onClick={(e) => {
                          this.openPopup(e, "PrivacyPolicy");
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        style={{
                          fontWeight: "600",
                          color: "#6219c0",
                          textDecoration: "underline",
                        }}
                      >
                        privacy policy
                      </a>
                      " of YesMindy.
                    </label>
                  </div>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-11"></div>
              </div>

              <div className="row">
                <div className="col-md-11">
                  <a
                    className={
                      this.state.contracts
                        ? "btn btn-primary"
                        : "btn btn-primary disabled"
                    }
                    href=""
                    onClick={(e) => this.doSubmit(e)}
                  >
                    Accept
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TermTemplatePopup
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  getSeo: getSeo(state),
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadTermTemplate: (key) => dispatch(loadTermTemplate(key)),
  loadContract: (key) => dispatch(loadContract(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
