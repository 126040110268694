import React, { Component } from "react";
import { connect } from "react-redux";
import DashboardHeader from "./common/dashboardHeader";
import DashboardLeftBar from "./common/dashboardLeftBar";
import { Link, withRouter } from "react-router-dom";
import specialist3 from "./../../../include/images/specialist3.png";
import specialist1 from "./../../../include/images/specialist1.svg";
import specialist2 from "./../../../include/images/specialist2.svg";
import specialist4 from "./../../../include/images/specialist4.svg";

class PatientDashboard extends Component {
  state = {
    loading: true,
  };
  componentDidMount = () => {
    document.body.classList.remove("p-0");
    document.body.classList.add("dashboard");
    this.setState({ loading: false });
  };
  componentWillUnmount = () => {
    document.body.classList.remove("dashboard");
    document.body.classList.add("p-0");
  };

  render() {
    return (
      !this.state.loading && (
        <>
          <DashboardLeftBar />
          <DashboardHeader />
          <div className="dashboard-main-container">
            <div className="first-time-user-box">
              <div className="ftu-heading">
                <div>
                  <h6>Let’s find you a specialist</h6>
                  <p>
                    Select a Psychiatrist or Psychologist (Therapist) to book an
                    appointment.
                  </p>
                </div>
              </div>
              <ul className="specialist-list d-flex flex-wrap">
                <li className="specialist-item">
                  <div className="specialist-box d-flex flex-column text-center add-dot-border">
                    <div className="specialist-img">
                      <img src={specialist1} alt="" />
                    </div>
                    {/* <p>Name of Doctor</p> */}
                    <div class="specialist-btn">
                      <Link
                        to={{
                          pathname: "/patient/doctors",
                          state: { field_type: "Psychiatrist" },
                        }}
                        className="btn btn-secondary btn-block"
                      >
                        Select a Psychiatrist
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="specialist-item">
                  <div className="specialist-box d-flex flex-column text-center add-dot-border">
                    <div className="specialist-img">
                      <img src={specialist2} alt="" />
                    </div>
                    {/* <p>Name of Doctor</p> */}
                    <div className="specialist-btn">
                      <Link
                        to={{
                          pathname: "/patient/doctors",
                          state: { field_type: "Psychologist" },
                        }}
                        class="btn btn-secondary btn-block"
                      >
                        Select a Psychologist
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <li className="specialist-item">
                <div className="specialist-box d-flex flex-column text-center add-solid-border">
                  <div className="specialist-img">
                    <img src={specialist3} alt="" />
                  </div>
                  <p>
                    Need help? <br /> Ask Mindy your Virtual Assistant.
                  </p>
                  <div className="specialist-btn">
                    <a href="#!" className="btn btn-outline1 btn-block">
                      Ask Mindy
                    </a>
                  </div>
                </div>
              </li> */}
                {
                  <li className="specialist-item">
                    <div className="specialist-box d-flex flex-column text-center add-solid-border">
                      <div className="specialist-img">
                        <img src={specialist4} alt="" />
                      </div>
                      <p>
                        Need help? <br /> Chat with Counsellor for Free
                      </p>
                      <div className="specialist-btn">
                        <a
                          href="/patient/chat"
                          className="btn btn-secondary btn-block"
                        >
                          Chat Now
                        </a>
                      </div>
                    </div>
                  </li>
                }
              </ul>
            </div>
          </div>
        </>
      )
    );
  }
}

export default withRouter(connect(null)(PatientDashboard));
