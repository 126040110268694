import React, { Component } from "react";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { toast } from "react-toastify";
import { checkOtp, sendOtp } from "../../../store/patient";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { invalidOTP } from "../../common/misc";

class VerifyOtp extends Component {
  state = {
    countDown: 59,
    reset: false,
    countDownId: 1,
    otp: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
  };

  constructor(props) {
    super(props);
    this.aRef = React.createRef();
    this.bRef = React.createRef();
    this.cRef = React.createRef();
    this.dRef = React.createRef();
  }

  componentDidMount = () => {
    const countDown = setInterval(() => {
      if (this.state.countDown === 1 || this.state.reset)
        clearInterval(countDown);
      this.setState({ countDown: this.state.countDown - 1 });
    }, 1000);
    this.setState({ countDownId: countDown });
  };

  componentWillUnmount() {
    clearInterval(this.state.countDownId);
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const otp = this.state.otp;
    otp[name] = value;
    this.setState({ otp });
    if (name === "a") this.bRef.current.focus();
    if (name === "b") this.cRef.current.focus();
    if (name === "c") this.dRef.current.focus();
  };

  doSubmit = (e) => {
    e.preventDefault();
    const { a, b, c, d } = this.state.otp;
    const data = {
      mobile: this.props.verifyMobile.mobile,
      otp: a + b + c + d,
    };
    this.props.checkOtp(data, this.callbackCheckOtp);
  };

  callbackCheckOtp = (res) => {
    if (res.status === 200) {
      const { a, b, c, d } = this.state.otp;
      const { verifyMobile } = this.props;
      verifyMobile.otp = a + b + c + d;
      this.props.updateVerifyMobile(verifyMobile);
      this.props.updateStep(3);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  reSend = (e) => {
    clearInterval(this.state.countDownId);
    const param = {
      mobile: this.props.verifyMobile.mobile,
      type: "resetPassword",
    };
    this.props.sendOtp(param, this.callback);
    e.preventDefault();
  };

  callback = (res) => {
    if (res.status === 200) {
      this.setState({ countDown: 59, reset: true }, () => {
        const countDown = setInterval(() => {
          if (this.state.countDown === 1) clearInterval(countDown);
          this.setState({ countDown: this.state.countDown - 1 });
        }, 1000);

        this.setState({ countDownId: countDown });
      });

      toast(<AlertSuccess message={res.data.otp} />);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.props.verifyMobile.mobile,
        DOB: this.props.verifyMobile.DOB,
        type: 1,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head mb-4">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  this.props.updateStep(1);
                }}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                      c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                      c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  />
                </svg>
              </a>
              <div className="mr-step">Step 2/3</div>
              <h2>Verify Details</h2>
            </div>
            <form>
              <div className="form-box">
                <div className="enter-otp-box">
                  <p>Please enter OTP sent to phone number</p>
                  <div className="otp-box">
                    <input
                      className="form-control"
                      id="codeBox1"
                      type="text"
                      name="a"
                      onChange={this.onChange}
                      maxLength="1"
                      ref={this.aRef}
                    />
                    <input
                      className="form-control"
                      id="codeBox2"
                      type="text"
                      name="b"
                      onChange={this.onChange}
                      maxLength="1"
                      ref={this.bRef}
                    />
                    <input
                      className="form-control"
                      id="codeBox3"
                      type="text"
                      name="c"
                      onChange={this.onChange}
                      maxLength="1"
                      ref={this.cRef}
                    />
                    <input
                      className="form-control"
                      id="codeBox4"
                      type="text"
                      name="d"
                      onChange={this.onChange}
                      maxLength="1"
                      ref={this.dRef}
                    />
                  </div>
                  <div className="time-count">
                    Code expires in:{" "}
                    <span>
                      00:
                      {parseInt(
                        this.state.countDown > 9
                          ? this.state.countDown
                          : "0" + this.state.countDown
                      )}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <a
                    onClick={this.doSubmit}
                    href=""
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    <b className="btn-loader"></b>Verify
                  </a>
                </div>
                <div className="resend-box">
                  <p>
                    Didn't get the code?{" "}
                    <a href="#" onClick={this.reSend}>
                      Resend
                    </a>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  checkOtp: (param, callback) => dispatch(checkOtp(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(VerifyOtp));
