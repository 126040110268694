import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";
import { getMisc, loadConstant, loadFaq } from "../../store/misc";
import _ from "lodash";

class FAQ extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    id: "",
    toggle1: false,
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadFaq();
  };
  toggle1 = (id) => {
    this.setState({
      id: id,
      toggle1: !this.state.toggle1,
    });
  };
  render() {
    const { constants } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const { faq } = this.props;

    const monthName = (item) => item.category;
    const result = _.groupBy(faq, monthName);
    const groupArrays = Object.keys(result).map((category) => {
      return {
        category,
        doc: result[category],
      };
    });
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="content-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="heading">
                    <h4>FAQs</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {groupArrays.map((e2, i) => (
            <>
              {e2.category !== "Misc" && (
                <div class="experts-container overflow-hidden pt-0">
                  <div class="container container1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="heading-with-line">
                          <h3>
                            <span>
                              {e2.category === "Misc"
                                ? "miscellaneous"
                                : e2.category}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="accordion pc-outer" id="accordionExample">
                          {e2.doc.map((e, i) => (
                            <div
                              className={
                                this.state.id === e._id &&
                                this.state.toggle1 === true
                                  ? "card active"
                                  : "card"
                              }
                            >
                              <div
                                className={
                                  this.state.id === e._id &&
                                  this.state.toggle1 === true
                                    ? "card-header"
                                    : "card-header collapsed"
                                }
                                onClick={() => {
                                  this.toggle1(e._id);
                                }}
                                id="heading1"
                                data-toggle="collapse"
                                data-target="#collapse1"
                                aria-expanded="false"
                                aria-controls="collapse1"
                              >
                                {e.question}
                              </div>
                              <div
                                id="collapse1"
                                className={
                                  this.state.id === e._id &&
                                  this.state.toggle1 === true
                                    ? "collapse show"
                                    : "collapse"
                                }
                                aria-labelledby="heading1"
                                data-parent="#accordionExample"
                              >
                                <div class="card-body">
                                  <p>{e.answer}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}

          {groupArrays.map((e2, i) => (
            <>
              {e2.category === "Misc" && (
                <div class="experts-container overflow-hidden pt-0">
                  <div class="container container1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="heading-with-line">
                          <h3>
                            <span>
                              {e2.category === "Misc"
                                ? "miscellaneous"
                                : e2.category}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="accordion pc-outer" id="accordionExample">
                          {e2.doc.map((e, i) => (
                            <div
                              className={
                                this.state.id === e._id &&
                                this.state.toggle1 === true
                                  ? "card active"
                                  : "card"
                              }
                            >
                              <div
                                className={
                                  this.state.id === e._id &&
                                  this.state.toggle1 === true
                                    ? "card-header"
                                    : "card-header collapsed"
                                }
                                onClick={() => {
                                  this.toggle1(e._id);
                                }}
                                id="heading1"
                                data-toggle="collapse"
                                data-target="#collapse1"
                                aria-expanded="false"
                                aria-controls="collapse1"
                              >
                                {e.question}
                              </div>
                              <div
                                id="collapse1"
                                className={
                                  this.state.id === e._id &&
                                  this.state.toggle1 === true
                                    ? "collapse show"
                                    : "collapse"
                                }
                                aria-labelledby="heading1"
                                data-parent="#accordionExample"
                              >
                                <div class="card-body">
                                  <p>{e.answer}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  faq: getMisc(state).faq,
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadFaq: (params) => dispatch(loadFaq(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
