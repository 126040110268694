import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { WatiScript } from '../../../include/js/wati'
import AgoraRTC from 'agora-rtc-sdk-ng'
import Header from './header'
import { getPrescription, loadList } from '../../../store/prescription'
import Task from './../dashboard/task'
import bluestar from '../../../include/images/blue-star-icon.svg'
import ReactTooltip from 'react-tooltip'
import startZero from '../../../include/images/start-0.svg'
import checkcircle from '../../../include/images/check-circle-09.svg'
import verticalDots from '../../../include/images/vertical-dots.svg'
import videoslash from '../../../include/images/video-slash.svg'
import phoneslash from '../../../include/images/phone-slash.svg'
import tabDoc from '../../../include/images/tab-document.svg'
import iosExit from '../../../include/images/ios-exit.svg'
import fullScreenImg from '../../../include/images/fullscreen-icon.svg'
import videosOn from '../../../include/images/Videoon.png'
import micOn from '../../../include/images/micon.png'
import msgIcon from '../../../include/images/tab-message.svg'
import presC from '../../../include/images/precriptions-icon.svg'
import taskIcon from '../../../include/images/tab-dashboard.svg'
import notesIcon from '../../../include/images/notes-icon.svg'
import followUp from '../../../include/images/followup-icon.svg'
import Timer from '../../common/timer'
import moment from 'moment'
import Scrollbars from 'react-custom-scrollbars-2'

import {
  getAppointment,
  loadAppointmentDetail,
} from '../../../store/appointment'
import { loadSeo } from '../../../store/seo'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Messeges from './messeges'
import ListPrescription from './prescription/listPrescriptionVideoCall'
import ListFollowup from './prescription/listFollowup'
import Followup from './../dashboard/followUp/followUp'
import NotesThoughtVideoCall from './notesThoughtVideoCall'
import AvtarImage from '../../common/avtarImage'
import { Link } from 'react-router-dom'
import SettingPopup from './settingPopup'
import { getPatient, patientDetail } from '../../../store/patient'
import SummaryVid from './summaryVid'

import './style.css'

function VideoCall(props) {
  const APP_ID = process.env.REACT_APP_KEY
  const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

  const [isShow3, setShow3] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [data, setData] = useState({})
  const [caseRecord, setCaseRecord] = useState({})
  const [tokenA, setTokenA] = useState('')
  const [remoteStreams, setRemoteStreams] = useState([])
  const [video, setVideo] = useState(true)
  const [audio, setAudio] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
  const [tab, setTab] = useState('notes')
  const [localMedia, setLocalMedia] = useState([])

  const [initial, setInitial] = useState(true)

  client.on('user-published', async (user, mediaType) => {
    // Subscribe to the remote user when the SDK triggers the "user-published" event.
    await client.subscribe(user, mediaType)

    let channelParameters = {}
    // Subscribe and play the remote video in the container If the remote user publishes a video track.
    if (mediaType == 'video') {
      // Retrieve the remote video track.
      channelParameters.remoteVideoTrack = user.videoTrack
      // Retrieve the remote audio track.
      channelParameters.remoteAudioTrack = user.audioTrack
      // Save the remote user id for reuse.
      channelParameters.remoteUid = user.uid.toString()
      setRemoteStreams([channelParameters])
    }
    // Subscribe and play the remote audio track If the remote user publishes the audio track only.
    if (mediaType == 'audio') {
      // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
      channelParameters.remoteAudioTrack = user.audioTrack
      // Play the remote audio track. No need to pass any DOM element.
      channelParameters.remoteAudioTrack.play()
    }
  })
  client.on('user-unpublished', user => {
    setRemoteStreams([])
  })

  useEffect(() => {
    if (remoteStreams.length > 0)
      remoteStreams[0].remoteVideoTrack.play(
        'agora_remote' + remoteStreams[0].remoteUid
      )
  }, [remoteStreams])

  const toggle3 = () => {
    setShow3(!isShow3)
  }
  const changeTab = (tab, e) => {
    e.preventDefault()
    setTab(tab)
  }
  const toggleFull = () => {
    setFullScreen(!fullScreen)
  }
  const muteVideo = () => {
    if (localMedia.length > 1) {
      if (video) {
        localMedia[1].setEnabled(false)
      } else {
        localMedia[1].setEnabled(true)
      }
    }
    setVideo(!video)
  }

  const muteAudio = () => {
    if (localMedia.length > 1) {
      if (audio) {
        localMedia[0].setVolume(0)
        //localStream.muteAudio();
      } else {
        localMedia[0].setVolume(100)
        //localStream.unmuteAudio();
      }
    }
    setAudio(!audio)
  }

  const leave = async () => {
    if (localMedia.length > 1) {
      localMedia[0].close()
      localMedia[1].close()
    }
    setRemoteStreams([])
    await client.leave()
    window.close()
  }

  useEffect(() => {
    props.loadAppointmentDetail(props.match.params.appointment_id, res => {
      if (res && res.data) {
        setData(res.data.data)
        setTokenA(res.data.tokenA)
        setCaseRecord(res.data.caseRecord)
      }
    })
    props.loadList({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
    return () => {
      leave()
    }
  }, [])

  useEffect(async () => {
    if (data && data.patient_id) props.patientDetail(data.patient_id._id)

    document.body.className = 'dashboard-video'
    if (Object.keys(data).length > 0 && tokenA != '') {
      await client.join(APP_ID, data._id, tokenA, data.doctor_id._id)
      let localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
      let localVideoTrack = await AgoraRTC.createCameraVideoTrack()
      setLocalMedia([localAudioTrack, localVideoTrack])
      // Publish the local audio and video tracks in the channel.
      await client.publish([localAudioTrack, localVideoTrack])
      let localPlayerContainer = document.getElementById('agora_local')
      localVideoTrack.play(localPlayerContainer)
    }
  }, [data, tokenA])

  useEffect(() => {
    const classData = document.getElementsByClassName('mcb-body-fullscreen')
    const topDar = document.getElementsByClassName('dashboard-top-bar')
    if (classData.length > 0 && topDar && topDar.length > 0) {
      topDar[0].style.zIndex = -999
    } else if (topDar && topDar.length > 0) {
      topDar[0].style.zIndex = 999
    }
    let localVideoTrack = localMedia && localMedia.length > 0 && localMedia[1]
    if (localVideoTrack) {
      if (fullScreen) {
        let localPlayerContainer = document.getElementById('agora_local_full')
        localVideoTrack.play(localPlayerContainer)
      } else {
        let localPlayerContainer = document.getElementById('agora_local')
        localVideoTrack.play(localPlayerContainer)
      }
    }
  }, [fullScreen])

  return (
    <>
      {/* <Helmet>
        <script id='wati'>{WatiScript}</script>
      </Helmet> */}
      <Header />
      <ReactTooltip className='testPurple' html={true} />
      <div
        class='dashboard-main-container'
        onClick={e => {
          e.stopPropagation()
          setDropDown(false)
        }}>
        <div class='video-box video-box2 d-xl-flex flex-wrap'>
          <div
            className={
              fullScreen
                ? 'main-content-box main-content-box3'
                : 'main-content-box main-content-box2'
            }>
            <div class='mcb-head'>
              <div class='mcb-profile-head d-md-flex flex-wrap'>
                <div class='mcbp-left d-flex flex-wrap align-items-center'>
                  <div class='mcbp-img has-active'>
                    {data && data.patient_id && data.patient_id.thumb ? (
                      <img
                        src={
                          process.env.REACT_APP_S3URL + data.patient_id.thumb
                        }
                        alt=''
                      />
                    ) : (
                      <AvtarImage />
                    )}
                  </div>
                  <div class='mcbp-desc'>
                    <div class='mcbp-text d-flex flex-wrap'>
                      <h4>
                        {data &&
                          data.patient_id &&
                          data.patient_id.first_name +
                            ' ' +
                            data.patient_id.last_name}
                      </h4>
                      <span>
                        {data &&
                          data.patient_id &&
                          data.patient_id.star &&
                          data.patient_id.star.length > 0 &&
                          data.patient_id.star.find(
                            e => e === props.doctorProfile._id
                          ) && (
                            <i>
                              <img src={bluestar} alt='star-icon' />
                            </i>
                          )}
                      </span>
                      <span>
                        {caseRecord && caseRecord.finish && (
                          <img src={checkcircle} alt='' />
                        )}
                      </span>
                    </div>
                    <div class='pd-right-content'>
                      <div class='d-flex flex-wrap'>
                        {caseRecord &&
                          caseRecord.finish &&
                          caseRecord.finish[
                            props.doctorProfile &&
                            props.doctorProfile.field_type &&
                            props.doctorProfile.field_type.name ===
                              'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ] &&
                          caseRecord.finish[
                            props.doctorProfile &&
                            props.doctorProfile.field_type &&
                            props.doctorProfile.field_type.name ===
                              'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ].diagnosis.length > 0 &&
                          caseRecord.finish[
                            props.doctorProfile &&
                            props.doctorProfile.field_type &&
                            props.doctorProfile.field_type.name ===
                              'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ].diagnosis.map(ecs => (
                            <>
                              <ReactTooltip
                                id={ecs.value}
                                className='testPurple'
                                html={true}
                              />
                              <strong>{ecs.value}</strong>
                              <div class='custom-dropright mr-2'>
                                <div
                                  data-tip={ecs.diagnosis}
                                  data-for={ecs.value}>
                                  <a class='custom-dropdown-toggle' href='#!'>
                                    <i
                                      style={{ marginLeft: '5px' }}
                                      class='fas fa-info-circle'
                                      aria-hidden='true'></i>
                                  </a>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='mcbp-right d-flex align-items-center justify-content-between'>
                  <div class='patients-details-mid'>
                    <div class='pdm-inner-box d-flex align-items-center'>
                      <span>UHID:</span>
                      <p>
                        {data &&
                          data.patient_id &&
                          data.patient_id.yesmindy_identification_number}
                      </p>
                    </div>
                    {/* <div class="pdm-inner-box d-flex align-items-center">
                        <span>Sex:</span>
                        <p>Female</p>
                      </div> */}
                    <div class='pdm-inner-box d-flex align-items-center'>
                      <span>Date of Birth:</span>
                      <p>
                        {data &&
                          data.patient_id &&
                          data.patient_id.date_of_birth &&
                          moment(data.patient_id.date_of_birth).format(
                            'll'
                          )}{' '}
                        (
                        {data &&
                          data.patient_id &&
                          data.patient_id.date_of_birth &&
                          moment().diff(
                            data.patient_id.date_of_birth,
                            'years',
                            false
                          ) + ' yrs'}
                        )
                      </p>
                    </div>
                  </div>
                  <div class='mcbp-right-conent'>
                    <div class='pd-right-box d-flex align-items-center'>
                      <ul class='list-with-icon d-flex align-items-center'>
                        <li>
                          <a
                            href='javascript:void(0)'
                            onClick={e => {
                              e.preventDefault()
                              toggle3()
                            }}
                            class='icon-box orange-icon'
                            data-toggle='modal'
                            data-target='#exampleModalCenter'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='30'
                              height='29.489'
                              viewBox='0 0 30 29.489'>
                              <path
                                class='a'
                                style={{ fill: '#f1964f' }}
                                d='M27.305,38.134H18.8a.817.817,0,0,1-.818-.818V32.163l-4.462,2.574a.818.818,0,0,1-1.113-.3L8.16,27.083a.818.818,0,0,1-.082-.617.861.861,0,0,1,.378-.5l4.462-2.574L8.456,20.82a.8.8,0,0,1-.378-.5.809.809,0,0,1,.082-.617l4.247-7.362a.813.813,0,0,1,1.113-.3l4.462,2.574V9.463a.817.817,0,0,1,.818-.818h8.5a.812.812,0,0,1,.812.818v5.153l4.462-2.574a.812.812,0,0,1,1.113.3L37.941,19.7a.818.818,0,0,1,.082.617.861.861,0,0,1-.378.5L33.182,23.4l4.462,2.574a.8.8,0,0,1,.378.5.809.809,0,0,1-.082.617l-4.247,7.362a.813.813,0,0,1-1.113.3l-4.462-2.574v5.153a.814.814,0,0,1-.811.812Z'
                                transform='translate(-8.05 -8.645)'
                              />
                            </svg>{' '}
                          </a>
                        </li>
                      </ul>
                      <div class='pd-right-dropdown'>
                        <div class='btn-group bottom-align'>
                          <button
                            type='button'
                            class='dropdown-toggle'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            onClick={e => {
                              e.stopPropagation()
                              setDropDown(!dropDown)
                            }}>
                            <img src={verticalDots} />
                          </button>
                          <div
                            class={
                              dropDown
                                ? 'dropdown-menu dropdown-menu-right show'
                                : 'dropdown-menu dropdown-menu-right'
                            }>
                            <button
                              class='dropdown-item'
                              type='button'
                              onClick={e => window.close()}>
                              Foreclose
                            </button>
                            <Link
                              target='_blank'
                              class='dropdown-item'
                              type='button'
                              to={
                                '/doctor/patient-detail/history/' +
                                (data && data.patient_id && data.patient_id._id)
                              }>
                              View History
                            </Link>
                            <Link
                              target='_blank'
                              class='dropdown-item'
                              type='button'
                              to={
                                '/doctor/patient-detail/journey/' +
                                (data && data.patient_id && data.patient_id._id)
                              }>
                              View Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class={`${
                fullScreen ? 'mcb-body-fullscreen' : 'mcb-body'
              } call-end`}>
              {Object.keys(remoteStreams).length === 0 && (
                <>
                  <div class='doc-image'></div>
                  <h4>Connecting...</h4>
                </>
              )}
              {Object.keys(remoteStreams).map(key => {
                let stream = remoteStreams[key]
                let streamId = stream.remoteUid
                return (
                  <div
                    class='doc-image'
                    key={streamId}
                    id={`agora_remote${streamId}`}></div>
                )
              })}

              {!fullScreen && (
                <>
                  <div
                    class='fullscreen-button'
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleFull()}>
                    <img src={fullScreenImg} alt='' />
                  </div>
                  <div id='agora_local' class='front-thumbnail-box'></div>
                </>
              )}
            </div>

            {fullScreen && (
              <>
                <div
                  class='fullscreen-button'
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleFull()}>
                  <img src={fullScreenImg} alt='' />
                </div>
                <div id='agora_local_full' class='front-thumbnail-box'></div>
              </>
            )}

            <div class={`${fullScreen ? 'mcb-footer-fullscreen' : ''} `}>
              <div className={`${fullScreen ? 'mb-foor-app' : ''} `}>
                <div class='mcb-footer d-flex align-items-center justify-content-between'>
                  <div class='mcb-left'>{moment().format('ll')}</div>
                  <div class='mcb-mid d-flex align-items-center flex-wrap'>
                    <div class='mcb-link' onClick={muteVideo}>
                      <div class='img'>
                        <img src={!video ? videoslash : videosOn} alt='' />
                      </div>
                      <p>Camera</p>
                    </div>
                    <div class='mcb-link' onClick={muteAudio}>
                      <div class='img'>
                        <img src={!audio ? phoneslash : micOn} alt='' />
                      </div>
                      <p>Mic</p>
                    </div>
                    <div class='mcb-link leave' onClick={leave}>
                      <div class='img'>
                        <img src={iosExit} alt='' />
                      </div>
                      <p>Leave</p>
                    </div>
                  </div>
                  <div class='mcb-right'>
                    <Timer />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!fullScreen && (
            <div class='video-sidebar video-sidebar2'>
              <div class='vs-box'>
                <div class='tab-container d-none d-md-block'>
                  <ul
                    class='nav nav-tabs videochat-tabs'
                    id='myTab'
                    role='tablist'>
                    <li class='nav-item' role='presentation'>
                      <a
                        class={
                          tab === 'message' ? 'nav-link active' : 'nav-link'
                        }
                        id='messages-tab'
                        data-toggle='tab'
                        href='#messages'
                        role='tab'
                        aria-controls='messages'
                        aria-selected='true'
                        onClick={e => changeTab('message', e)}
                        data-tip='Chats will remain active for 3 days. Active chats can be accessed going to the specific date of appointment. '>
                        <img src={msgIcon} alt='' />
                        <b>Messages</b>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        class={
                          tab === 'prescriptions'
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        id='contact-tab'
                        data-toggle='tab'
                        href='#myCaseStudy'
                        role='tab'
                        aria-controls='myCaseStudy'
                        aria-selected='false'
                        onClick={e => changeTab('prescriptions', e)}
                        data-tip='Prescriptions can only be filled after the appointment is over and CRF is completed.'>
                        <img src={presC} alt='' />
                        <b>Prescriptions</b>
                      </a>
                    </li>

                    <li class='nav-item' role='presentation'>
                      <a
                        class={
                          tab === 'follow' ? 'nav-link active' : 'nav-link'
                        }
                        id='contact-tab'
                        data-toggle='tab'
                        href='#myFollowUp'
                        role='tab'
                        aria-controls='myFollowUp'
                        aria-selected='false'
                        onClick={e => changeTab('follow', e)}
                        data-tip='Follow up note is only from second appointment onwards'>
                        <img src={followUp} alt='' />
                        <b>Follow Up</b>
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        class={tab === 'tasks' ? 'nav-link active' : 'nav-link'}
                        id='contact-tab'
                        data-toggle='tab'
                        href='#myTasks'
                        role='tab'
                        aria-controls='myTasks'
                        aria-selected='false'
                        onClick={e => changeTab('tasks', e)}
                        data-tip='List of patients with current date appointment only. Task can be allotted to any patient from toolbox.'>
                        {' '}
                        <img src={taskIcon} alt='' />
                        <b>Tasks</b>{' '}
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        class={
                          tab === 'summary' ? 'nav-link active' : 'nav-link'
                        }
                        id='profile-tab'
                        data-toggle='tab'
                        href='#mySummary'
                        role='tab'
                        aria-controls='mySummary'
                        aria-selected='false'
                        onClick={e => changeTab('summary', e)}
                        data-tip='Smart auto generated treatment summary of the patient for the experts to analyse'>
                        {' '}
                        <img src={tabDoc} alt='' />
                        <b>Summary</b>{' '}
                      </a>
                    </li>
                    <li class='nav-item' role='presentation'>
                      <a
                        class={tab === 'notes' ? 'nav-link active' : 'nav-link'}
                        id='contact-tab'
                        data-toggle='tab'
                        href='#myNotes'
                        role='tab'
                        aria-controls='myNotes'
                        aria-selected='false'
                        onClick={e => changeTab('notes', e)}
                        data-tip='Notes & Thoughts'>
                        {' '}
                        <img src={notesIcon} alt='' />
                        <b>Notes & Thoughts</b>{' '}
                      </a>
                    </li>
                  </ul>
                </div>
                <div class='chatbot-body p-md-0'>
                  <div class='tab-content' id='myTabContent'>
                    <div
                      class={
                        tab === 'message'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id='messages'
                      role='tabpanel'
                      aria-labelledby='messages-tab'>
                      {data.patient_id && (
                        <Messeges
                          appointmentDate={moment(data.appointment_time).format(
                            'DD/MM/YYYY'
                          )}
                          setInitial={() => {
                            setInitial(false)
                          }}
                          initial={initial}
                          patient_id={data.patient_id._id}
                          doctor_id={data.doctor_id._id}
                          tab={tab}
                        />
                      )}
                    </div>
                    <Scrollbars
                      className={
                        tab === 'prescriptions'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={'80vh'}>
                      {data.patient_id && (
                        <ListPrescription
                          tab={tab}
                          yesmindy_identification_number={
                            data.patient_id.yesmindy_identification_number
                          }
                          id={data.patient_id._id}
                          first_name={data.patient_id.first_name}
                          last_name={data.patient_id.last_name}
                          age={data.patient_id.date_of_birth}
                          sex={data.patient_id.sex}
                          image={data.patient_id.thumb}
                          star={data.patient_id.star}
                          time={data.appointment_time}
                          caseRecord={caseRecord}
                        />
                      )}
                    </Scrollbars>
                    <div
                      class={
                        tab === 'follow'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id='messages'
                      role='tabpanel'
                      aria-labelledby='messages-tab'>
                      <Scrollbars
                        class={
                          tab === 'follow'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane'
                        }
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={'80vh'}>
                        {data.patient_id && (
                          <ListFollowup
                            isVideoCall={true}
                            tab={tab}
                            yesmindy_identification_number={
                              data.patient_id.yesmindy_identification_number
                            }
                            id={data.patient_id._id}
                            first_name={data.patient_id.first_name}
                            last_name={data.patient_id.last_name}
                            age={data.patient_id.date_of_birth}
                            sex={data.patient_id.sex}
                            image={data.patient_id.thumb}
                            star={data.patient_id.star}
                            time={data.appointment_time}
                            caseRecord={caseRecord}
                          />
                        )}
                      </Scrollbars>
                    </div>
                    {/* <div
                      class={
                        tab === 'follow'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id="messages"
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      {tab === 'follow' && (
                        <Followup
                          appointment_date={props.appointment_date}
                          video={true}
                          id={data.patient_id._id}
                          tab={tab}
                        />
                      )}
                    </div> */}
                    <div
                      class={
                        tab === 'tasks'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id='messages'
                      role='tabpanel'
                      aria-labelledby='messages-tab'>
                      {data.patient_id &&
                        data.patient_id._id &&
                        tab === 'tasks' && (
                          <Task videoCall={true} id={data.patient_id._id} />
                        )}
                    </div>
                    <div
                      class={
                        tab === 'summary'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id='messages'
                      role='tabpanel'
                      aria-labelledby='messages-tab'>
                      {data.patient_id &&
                        data.patient_id._id &&
                        tab === 'summary' && (
                          <SummaryVid
                            videoCall={true}
                            id={data.patient_id._id}
                            detail={props.detailData}
                          />
                        )}
                    </div>
                    <div
                      class={
                        tab === 'notes'
                          ? 'tab-pane card fade show active'
                          : 'tab-pane'
                      }
                      id='messages'
                      role='tabpanel'
                      aria-labelledby='messages-tab'>
                      {data.patient_id &&
                        data.patient_id._id &&
                        tab === 'notes' && (
                          <NotesThoughtVideoCall
                            appoint_id={data._id}
                            patientId={data.patient_id._id}
                            doctorId={data.doctor_id._id}
                            patientType={data.patient_id.type}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <SettingPopup
        isShow={isShow3}
        toggle={toggle3}
        detail={props.detailData.data}
      />
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadAppointmentDetail: (id, callback) =>
    dispatch(loadAppointmentDetail(id, callback)),
  loadList: params => dispatch(loadList(params)),
  patientDetail: id => dispatch(patientDetail(id)),
})
const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state).doctorAppointment,
  detailData: getPatient(state).patientDetail,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoCall)
)
