import Joi from 'joi-browser'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { emailAvailability, mobileAvailability } from '../../../store/patient'
import AlertError from '../../common/alertError'
import Form from '../../common/form/form'

import {
  firstNameRequired,
  lastNameRequired,
  lnameMax,
  fnameMax,
} from '../../common/misc'
import LoginHere from './loginHere'

class PersonalDetails extends Form {
  state = {
    data: {
      first_name: '',
      last_name: '',
    },
    errors: {
      first_name: '',
      last_name: '',
    },
  }

  componentDidMount = () => {
    if (this.props.personalDetails.first_name) {
      this.setState({
        data: {
          first_name: this.props.personalDetails.first_name,
          last_name: this.props.personalDetails.last_name,
        },
      })
    }
  }

  schema = {
    first_name: Joi.string()
      .required()
      .max(50)
      .label('First Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = firstNameRequired
              break

            case 'string.max':
              err.message = fnameMax
              break
          }
        })
        return errors
      }),

    last_name: Joi.string()
      .required()
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),
  }
  dateFormat = date => {
    var today = new Date(date)
    var dd = today.getDate()

    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }
    return dd + '/' + mm + '/' + yyyy
  }

  doSubmit = () => {
    this.props.updatePersonalDetails(this.state.data)
    this.props.updateStep(null, 5)
    // this.props.emailAvailability(
    //   { email: this.state.data.email_id, type: 1 },
    //   this.callback
    // )
    // return;
    // this.props.updatePersonalDetails(this.state.data);
    // this.props.updateStep(null, 5);
  }
  callback = res => {
    this.setState({
      loader: false,
      btnClass: 'btn btn-lg btn-primary btn-block',
    })

    if (res.status === 200) {
      this.props.updatePersonalDetails(this.state.data)
      this.props.updateStep(null, 5)
    } else {
      toast(<AlertError message={<LoginHere msg={res.data.error} />} />)
    }
  }
  render() {
    return (
      <div className='member-right'>
        <div className='member-right-content d-md-flex align-items-center'>
          <div className='mr-box'>
            <div className='mr-head'>
              <div className='mr-step'>Step 4/5</div>
              <h2 className='mb-3'>About You</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className='form-group custom-input'>
                <div className='form-group custom-input show'>
                  <label htmlFor='text1'>Mobile Number</label>
                  <input
                    type='tel'
                    id='text1'
                    className='form-control'
                    placeholder=''
                    value={'+91 ' + this.props.verifyMobile.mobile}
                    readOnly
                  />
                </div>
                <div className='form-group custom-input show'>
                  <label htmlFor='text1'>Email</label>
                  <input
                    type='email'
                    id='text1'
                    className='form-control'
                    placeholder=''
                    value={this.props.verifyMobile.email}
                    readOnly
                  />
                </div>
                {/* {this.renderInput('email_id', 'Email ID')} */}
                {this.renderInput('first_name', 'First Name')}
                {this.renderInput('last_name', 'Last Name')}

                <div className='form-group custom-input show'>
                  <label htmlFor='text5'>Date Of Birth</label>
                  <input
                    type='text'
                    id='text5'
                    className='form-control'
                    placeholder=''
                    value={this.dateFormat(this.props.verifyMobile.DOB)}
                    readOnly
                  />
                </div>
                <div className='form-group'>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary btn-block'>
                    <span>Next</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  emailAvailability: (param, callback) =>
    dispatch(emailAvailability(param, callback)),
})
export default withRouter(connect(null, mapDispatchToProps)(PersonalDetails))
