import React, { Component } from 'react'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  loadAllAssessment,
  addAnswerAssessment,
  loadAllTasks,
} from '../../../../../store/assessment'
import close from '../../../../../include/images/icon-close.svg'
import { connect } from 'react-redux'
import { getPatient } from '../../../../../store/patient'
import AlertSuccess from '../../../../common/alertSuccess'
import AlertError from '../../../../common/alertError'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import taskIcon from './../../../../../include/images/tab-dashboard.svg'

class Task extends Component {
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },
      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      'height': 55,
      'minHeight': 55,
      'borderColor': state.isFocused ? '#6119c0' : '#e0e0e0',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  state = {
    id: '',
    isShow1: false,
    data: {},
    is_submitted: false,
    category: '',
  }
  toggle1 = (id, category) => {
    this.setState({
      isShow1: !this.state.isShow1,
      id: id,
      category: category,
    })
  }

  toggleClose = () => {
    this.setState({
      isShow1: false,
      is_submitted: false,
      data: '',
      id: '',
    })
  }
  myRef = React.createRef()
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isShow1 !== prevState.isShow1) {
      this.myRef.current.scrollIntoView()
    }
    if (this.props.doctor_id !== prevProps.doctor_id)
      this.props.loadAllAssessment({ doctor_id: this.props.doctor_id })
    if (this.props.tab === 'tasks' && this.props.tab !== prevProps.tab) {
      this.props.loadAllAssessment({ doctor_id: this.props.doctor_id })
    }
    if (this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        doctor_id: this.props.doctor_id,
        // appointment_id: this.props.aapointment_id,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        is_submitted:
          this.props.answer.data && this.props.answer.data.is_submitted,
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
  }
  componentDidMount = () => {
    this.props.loadAllAssessment({ doctor_id: this.props.doctor_id })
  }
  handleSubmit = e => {
    e.preventDefault()
    const formData = { ...this.state.data }
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appointment_id,
        assesment_id: this.state.id,
        is_submitted: this.state.is_submitted,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    } else {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.aapointment_id,
        assesment_id: this.state.id,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    }
  }
  callBack = res => {
    this.props.loadAllAssessment({ doctor_id: this.props.doctor_id })
    if (res && res.status === 200) {
      this.props.loadAnswerAssessment({
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appointment_id,
        assesment_id: this.state.id,
      })
      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
    }
  }
  onChangeValue(event) {}
  render() {
    const { detail, assessment } = this.props
    const category = item => item.category && item.category.name
    const result = _.groupBy(assessment, category)
    const groupArrays = Object.keys(result).map(category => {
      return {
        category,
        assess: result[category],
      }
    })
    const ques =
      detail.questions &&
      detail.questions.map(e =>
        _.chain(e.questions)
          .groupBy('section.name')
          .map((value, key) => ({
            section: key,
            question_group: _.chain(value)
              .groupBy('question_group.name')
              .map((value, key) => ({ question_group: key, question: value }))
              .value(),
          }))
          .value()
      )
    console.log('ques', this.props)
    return (
      <>
        <div
          onClick={e => this.props.setTab('tasks', e)}
          className={
            this.props.tab === 'tasks' ? 'card-header active' : 'card-header'
          }>
          <img src={taskIcon} alt='' />
          <b>Tasks</b>
        </div>
        <div
          className={
            this.props.tab === 'tasks' ? 'content-body show' : 'content-body'
          }>
          {groupArrays.length > 0 ? (
            groupArrays.map((e2, i) => (
              <div className='notes-pane-content'>
                {e2.category === 'Assessments' ||
                e2.category === 'Homeworks' ||
                e2.category === 'Worksheets' ? (
                  <p className='mt-3'>Lets fill up your {e2.category}</p>
                ) : (
                  ''
                )}
                {e2.category === 'Assessments' ||
                e2.category === 'Homeworks' ||
                e2.category === 'Worksheets' ? (
                  e2.assess.map((e1, i1) => (
                    <div className='notes-box d-flex align-items-center'>
                      <p>{e1.title}</p>
                      <div
                        className='fillup-btn'
                        onClick={() => this.toggle1(e1._id, e1.title)}>
                        Fill Up
                      </div>
                    </div>
                  ))
                ) : (
                  <h5></h5>
                )}
              </div>
            ))
          ) : (
            <div className='notes-pane-content'>
              <h5>Currently you have no task pending or newly alloted.</h5>
            </div>
          )}
        </div>
        <div
          className={
            this.state.isShow1 === true
              ? 'sidebar-box preSession opennote'
              : 'sidebar-box preSession closenote'
          }
          id='pre'>
          <div className='sidebar-head d-flex align-items-center'>
            {this.state.isShow1 === true && (
              <h5>
                {detail && detail.data && detail.data.title}{' '}
                {detail && detail.data && detail.data.header_text && (
                  <small>({detail.data.header_text})</small>
                )}
              </h5>
            )}
            {this.state.isShow1 === true ? (
              <div className='close-img ml-auto' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='sidebar-body'>
            <form action='' onSubmit={this.handleSubmit} ref={this.myRef}>
              <>
                {ques &&
                  ques.map((e, i) => (
                    <>
                      {e.map((e1, i1) => (
                        <div className='check-box-group' key={i1}>
                          {e1.section !== 'undefined' && (
                            <label class='section'>{e1.section}</label>
                          )}
                          {e1.question_group.map(e2 => (
                            <>
                              <div class='d-flex flex-column'>
                                <label class='question_group'>
                                  {e2.question_group !== 'undefined' &&
                                    e2.question_group}
                                </label>
                              </div>
                              {e2.question.map(e3 => (
                                <>
                                  <>
                                    <label style={{ color: '26262a' }}>
                                      {e3.question}
                                    </label>
                                    {e3.response_type ===
                                      'free_text_pill_box' && (
                                      <CreatableSelect
                                        noOptionsMessage={() =>
                                          'Type and press enter to add options'
                                        }
                                        isMulti
                                        classNamePrefix='select'
                                        isDisabled={false}
                                        isLoading={false}
                                        name='color'
                                        styles={this.customStyles}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }}
                                      />
                                    )}
                                    {e3.response_type ===
                                      'multiselect_dropdown' && (
                                      <Select
                                        isMulti
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={false}
                                        name='color'
                                        styles={this.customStyles}
                                        options={e3.options.map(ex => ({
                                          label: ex.title,
                                          value: ex.title,
                                        }))}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }}
                                      />
                                    )}
                                    {e3.response_type === 'dropdown' && (
                                      <Select
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={false}
                                        name='color'
                                        styles={this.customStyles}
                                        options={e3.options.map(ex => ({
                                          label: ex.title,
                                          value: ex.title,
                                        }))}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }}
                                      />
                                    )}
                                    {e3.response_type === 'text' && (
                                      <input
                                        type='text'
                                        name=''
                                        className='form-control'
                                        placeholder='Write here…'
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e.target.value
                                          this.setState({ data })
                                        }}
                                      />
                                    )}
                                    {e3.response_type === 'text_area' && (
                                      <textarea
                                        style={{ padding: '10px' }}
                                        type='text'
                                        className='form-control'
                                        placeholder=''
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        onChange={e => {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e.target.value
                                          this.setState({ data })
                                        }}></textarea>
                                    )}

                                    <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                      {e3.response_type === 'checkbox' &&
                                        e3.options.map((e4, i2) => (
                                          <div className='form-check' key={i2}>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              value={e4.title}
                                              id={e3.question + e4.title}
                                              name={e4.title + e4._id}
                                              checked={
                                                this.state.data &&
                                                this.state.data[e3.question] &&
                                                this.state.data[
                                                  e3.question
                                                ].find(
                                                  echeck => echeck === e4.title
                                                )
                                              }
                                              onChange={event => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                if (event) {
                                                  const findData =
                                                    data[e3.question] &&
                                                    data[e3.question].find(
                                                      echeck =>
                                                        echeck ===
                                                        event.target.value
                                                    )

                                                  if (findData) {
                                                    data[e3.question] = data[
                                                      e3.question
                                                    ].filter(
                                                      eqs =>
                                                        eqs !==
                                                        event.target.value
                                                    )
                                                  } else {
                                                    if (data[e3.question]) {
                                                      data[e3.question].push(
                                                        event.target.value
                                                      )
                                                    } else {
                                                      data[e3.question] = [
                                                        event.target.value,
                                                      ]
                                                    }
                                                  }

                                                  this.setState({ data })
                                                }
                                              }}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={e3.question + e4.title}>
                                              <span
                                                style={{
                                                  marginLeft: '0.5rem',
                                                }}></span>
                                              {e4.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>
                                    <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                      {e3.response_type === 'radio' &&
                                        e3.options.map((e4, i2) => (
                                          <div className='form-check' key={i2}>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              value={e4.title}
                                              id={e3.question + e4.title}
                                              name={e3.question + e4.title}
                                              onChange={e => {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                data[e3.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }}
                                              checked={
                                                this.state.data &&
                                                this.state.data[e3.question] ==
                                                  e4.title
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={e3.question + e4.title}>
                                              <span
                                                style={{
                                                  marginLeft: '2rem',
                                                }}></span>
                                              {e4.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>

                                    <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                      {e3.response_type === 'doc' &&
                                        e3.options.length > 0 && (
                                          <>
                                            {e3.options[0].title
                                              .split('.')
                                              .pop() === 'pdf' ||
                                            e3.options[0].title
                                              .split('.')
                                              .pop() === 'PDF' ? (
                                              <>
                                                <embed
                                                  src={
                                                    process.env
                                                      .REACT_APP_S3URL +
                                                    e3.options[0].title
                                                  }
                                                />
                                              </>
                                            ) : (
                                              <img
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e3.options[0].title
                                                }
                                                alt=''
                                              />
                                            )}
                                          </>
                                        )}
                                    </div>
                                    <br />
                                  </>
                                </>
                              ))}
                            </>
                          ))}
                        </div>
                      ))}
                    </>
                  ))}
                {detail && detail.data && (
                  <small>{detail.data.footer_text}</small>
                )}

                <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                  <div class='pi-body d-flex flex-wrap'>
                    <p style={{ fontSize: '11px' }}></p>
                  </div>
                </div>
                <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                  {(this.props.answer.data &&
                    this.props.answer.data.is_submitted === false) ||
                  this.props.answer.data === null ? (
                    <>
                      <button
                        type='submit'
                        className='btn btn-outline no-shadow'>
                        Save
                      </button>
                      <button
                        type='submit'
                        onClick={() => this.setState({ is_submitted: true })}
                        className='btn btn-primary'>
                        Submit
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            </form>
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true ? 'overlay4 show' : 'overlay4'
          }></div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  detail: getAssessment(state).detail,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
  assessment: getAssessment(state).task,
})
const mapDispatchToProps = dispatch => ({
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerAssessment: params => dispatch(loadAnswerAssessment(params)),
  addAnswerAssessment: (data, callBack) =>
    dispatch(addAnswerAssessment(data, callBack)),
  loadAllAssessment: params => dispatch(loadAllTasks(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Task)
