import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAppointment,
  loadAllAppointmentForDoctor,
} from "../../../store/appointment";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import Moment from "moment";
import _, { debounce } from "lodash";

class AppointmentHeader extends Form {
  state = {
    filter: false,
    new_patient: 0,
    patient_type: "",
    individual: 0,
    star: false,
    guardian: 0,
    followup: 0,
    page: 1,
    search: "",
    data: {
      start_date: "",
      end_date: "",
    },
    errors: {},
  };
  schema = {
    start_date: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 0))
      .label("From"),
    end_date: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 0))
      .label("To"),
  };

  onNew = () => {
    if (this.state.new_patient === 0) {
      this.setState({ new_patient: 1 });
    } else {
      this.setState({ new_patient: 0 });
    }
  };
  onFollow = () => {
    if (this.state.followup === 0) {
      this.setState({ followup: 1 });
    } else {
      this.setState({ followup: 0 });
    }
  };
  onIndividual = () => {
    if (this.state.individual === 0) {
      this.setState({ individual: 1, patient_type: 1 });
    } else {
      this.setState({ individual: 0, patient_type: "" });
    }
  };
  onGuardian = () => {
    if (this.state.guardian === 0) {
      this.setState({ guardian: 1, patient_type: 2 });
    } else {
      this.setState({ guardian: 0, patient_type: "" });
    }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.patient_type !== prevState.patient_type ||
      this.state.new_patient != prevState.new_patient ||
      this.state.star != prevState.star ||
      this.state.followup != prevState.followup
    ) {
      const { start_date, end_date } = this.state.data;

      this.props.loadAllAppointmentForDoctor({
        appointment_type: this.props.step == 3 ? "clinic" : "",
        search: this.state.search ? this.state.search.trim() : "",
        patient_type: this.state.patient_type,
        page: this.state.page,
        new_patient: this.state.new_patient,
        star: this.state.star,
        followup: this.state.followup,
        start_date: start_date
          ? Moment(start_date).add({ hours: 5, minutes: 30 }).format()
          : "",
        end_date: end_date
          ? Moment(end_date).add({ hours: 29, minutes: 30 }).format()
          : "",
      });
    }
  };

  fetchData = debounce((val) => {
    const { start_date, end_date } = this.state.data;
    const filter = {
      appointment_type: this.props.step == 3 ? "clinic" : "",
      search: val.trim(),
      patient_type: this.state.patient_type,
      page: this.state.page,
      new_patient: this.state.new_patient,
      star: this.state.star,
      followup: this.state.followup,
      start_date: start_date
        ? Moment(start_date).add({ hours: 5, minutes: 30 }).format()
        : "",
      end_date: end_date
        ? Moment(end_date).add({ hours: 29, minutes: 30 }).format()
        : "",
    };

    this.props.loadAllAppointmentForDoctor(filter);
  }, 500);

  toggleFilter = () => {
    this.setState({ filter: !this.state.filter });
  };
  onApply = () => {
    const { start_date, end_date } = this.state.data;
    this.props.loadAllAppointmentForDoctor({
      search: this.state.search,
      page: this.state.page,
      new_patient: this.state.new_patient,
      star: this.state.star,
      followup: this.state.followup,
      start_date: start_date
        ? Moment(start_date).add({ hours: 5, minutes: 30 }).format()
        : "",
      end_date: end_date
        ? Moment(end_date).add({ hours: 29, minutes: 30 }).format()
        : "",
    });
    this.setState({ filter: false });
  };
  onSearch = () => {
    const { start_date, end_date } = this.state.data;
    this.props.loadAllAppointmentForDoctor({
      search: this.state.search,
      patient_type: this.state.patient_type,
      page: this.state.page,
      new_patient: this.state.new_patient,
      star: this.state.star,
      followup: this.state.followup,
      start_date: start_date
        ? Moment(start_date).add({ hours: 5, minutes: 30 }).format()
        : "",
      end_date: end_date
        ? Moment(end_date).add({ hours: 29, minutes: 30 }).format()
        : "",
    });
  };
  clear = () => {
    this.props.loadAllAppointmentForDoctor({
      page: 1,
    });
    this.setState({
      page: 1,
      patient_type: "",
      new_patient: 0,
      star: false,
      followup: 0,
      filter: false,
      data: {
        start_date: "",
        end_date: "",
      },
    });
  };
  render() {
    return (
      <div className="dsp-head d-flex aligsn-items-md-center justify-content-md-between">
        <h4>Appointments History</h4>
        <div className="dsp-right-content d-flex">
          <div className="new-search-box">
            <input
              type="text"
              className="new-search-input"
              name=""
              value={this.state.search}
              placeholder="Search"
              onChange={(e) => {
                this.setState({ search: e.target.value });
                this.fetchData(e.target.value);
              }}
            />
          </div>
          <div className="new-filter-box-outer d-flex align-items-center justify-content-center">
            <div className="new-filter-box">
              <div
                className={
                  this.state.filter === true
                    ? "btn-group bottom-align filter-dropdown show"
                    : "btn-group bottom-align filter-dropdown"
                }
              >
                <button
                  type="button"
                  className="dropdown-toggle"
                  onClick={this.toggleFilter}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-filter"></i>
                </button>
                <div
                  className={
                    this.state.filter === true
                      ? "dropdown-menu dropdown-menu-right show"
                      : "dropdown-menu dropdown-menu-right"
                  }
                  x-placement="top-end"
                >
                  <div className="new-filter-content">
                    <div className="new-filter-heading d-flex justify-content-between ">
                      <h6>Filters</h6>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          this.clear();
                        }}
                        class="clear-text"
                      >
                        Clear All
                      </a>
                    </div>
                    <div className="new-checkbox-content">
                      <div className="signup-agree-box">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onChange={() => this.onNew()}
                            type="checkbox"
                            value="1"
                            id="1"
                            checked={this.state.new_patient === 1}
                          />
                          <label className="form-check-label" htmlFor="1">
                            New
                          </label>
                        </div>
                      </div>
                      <div className="signup-agree-box">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            onChange={() => this.onFollow()}
                            type="checkbox"
                            value="2"
                            id="2"
                            checked={this.state.followup === 1}
                          />
                          <label className="form-check-label" htmlFor="2">
                            Follow Up
                          </label>
                        </div>
                      </div>
                      <div className="signup-agree-box">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            onChange={() =>
                              this.setState({ star: !this.state.star })
                            }
                            type="checkbox"
                            value="3"
                            id="3"
                            checked={this.state.star === true}
                          />
                          <label class="form-check-label" htmlFor="3">
                            Starred Patient
                          </label>
                        </div>
                      </div>
                      <div class="new-seprator"></div>
                      <div class="signup-agree-box">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="4"
                            id="4"
                            onChange={() => {
                              if (this.state.patient_type === 1) {
                                this.setState({ patient_type: "" });
                              } else {
                                this.setState({ patient_type: 1 });
                              }
                            }}
                            checked={this.state.patient_type === 1}
                          />
                          <label class="form-check-label" for="4">
                            Individual
                          </label>
                        </div>
                      </div>

                      <div class="signup-agree-box">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="6"
                            id="6"
                            onChange={() => {
                              if (this.state.patient_type === 2) {
                                this.setState({ patient_type: "" });
                              } else {
                                this.setState({ patient_type: 2 });
                              }
                            }}
                            checked={this.state.patient_type === 2}
                          />
                          <label class="form-check-label" htmlFor="6">
                            Guardian
                          </label>
                        </div>
                      </div>
                      <div class="new-seprator"></div>
                      <p>Date Filters:</p>
                      <div class="date-filter-box d-flex flex-wrap justify-content-between">
                        <div class="dfb-left">
                          {this.renderDateInput("start_date", "From")}
                        </div>
                        <div class="dfb-right">
                          {this.renderDateInput("end_date", "From")}
                        </div>
                      </div>
                      <div class="apply-btn d-flex align-items-center justify-content-center no-shadow">
                        <button
                          type="button"
                          onClick={() => this.onApply()}
                          class=" btn btn-primary no-shadow"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to={"/doctor/addPatient"} class="btn btn-default no-shadow">
            + New Patient
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAllAppointmentForDoctor: (params) =>
    dispatch(loadAllAppointmentForDoctor(params)),
});
const mapStateToProps = (state) => ({
  doctorAppointment: getAppointment(state).doctorAppointment,
  loading: getAppointment(state).loadingDoctorAppointment,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentHeader)
);
