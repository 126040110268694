import _ from 'lodash'
import React, { Component } from 'react'
import moment from 'moment'

import close from '../../../../include/images/icon-close.svg'
import material from '../../../../include/images/material-note.svg'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  loadSubmittedAssessment,
} from '../../../../store/assessment'
import { getPatient } from '../../../../store/patient'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

class Task extends Component {
  state = {
    isShow: false,
    title: '',
    que: [],
    assesTitle: '',
  }

  toggle = (que, title, header_text, footer_text) => {
    this.setState({ header_text, footer_text })

    const result = que.reduce((acc, cv) => {
      acc.push({
        id: cv.section && cv.section._id,
        title: cv.section && cv.section.name,
        question: cv.question,
        options: cv.options,
      })
      return acc
    }, [])
    let arr = []
    result.forEach((e) => {
      const found = arr.find((ele) => ele.id === e.id)
      if (found) {
        arr.push({ question: e.question, options: e.options })
      } else {
        arr.push(e)
      }
    })
    this.setState((state) => {
      return {
        isShow: !state.isShow,
        que: arr,
        title,
      }
    })
  }

  toggleClose = () => {
    this.setState({
      isShow: false,
      que: [],
    })
  }

  render() {
    const { assessmentTask } = this.props
    const quest = assessmentTask.filter(
      (e) => this.state.assesTitle === e.assesment.title,
    )
    const ques = quest.map((e) =>
      _.chain(e.assesment && e.assesment.questions)
        .groupBy('section.name')
        .map((value, key) => ({
          section: key,
          question_group: _.chain(value)
            .groupBy('question_group.name')
            .map((value, key) => ({ question_group: key, question: value }))
            .value(),
        }))
        .value(),
    )

    let showFullScreen = false

    ques.map((e, i) => {
      e.map((e1, i1) => {
        e1.question_group.map((e2) => {
          e2.question.map((e3) => {
            if (e3.response_type === 'doc' && e3.options.length > 0) {
              if (
                e3.options[0].title.split('.').pop() === 'pdf' ||
                e3.options[0].title.split('.').pop() === 'PDF'
              )
                showFullScreen = true
            }
          })
        })
      })
    })

    return (
      <>
        <div
          class="tab-pane card fade show active"
          id="myreport"
          role="tabpanel"
          aria-labelledby="myreport-tab"
        >
          <div class="card-header">My Task</div>
          <div class="content-body show">
            <div class="tab-pane-content-box tpc-report">
              <div class="tpc-lower">
                {assessmentTask && assessmentTask.length === 0 ? (
                  <div className="document-content-box ">No Records Found.</div>
                ) : (
                  <>
                    {assessmentTask &&
                      assessmentTask.map((e2, i) => (
                        <>
                          <div className="document-content-box ">
                            <ul class="new-profile-list">
                              <li class="np-item" key={i}>
                                <div class="doc-info-upper d-flex flex-wrap no-border-no-padding">
                                  <div class="mid d-flex">
                                    <div class="block">
                                      <div class="text-block">
                                        <p>Title</p>
                                        <h5>
                                          {e2.assesment && e2.assesment.title}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="block">
                                      <div class="text-block">
                                        <p>Alloted By</p>
                                        <h5>{e2.doctor && e2.doctor.name}</h5>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="right d-flex">
                                    <div class="block">
                                      <div class="text-block">
                                        <p>Date of Allotment</p>
                                        <h5>
                                          {moment(e2.created_on).format(
                                            'DD MMM, YYYY',
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="np-right">
                                      <div>&nbsp;</div>
                                      <a
                                        href="javascript:void(0)"
                                        class="np-links"
                                        data-toggle="notes"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              assesTitle:
                                                e2.assesment &&
                                                e2.assesment.title,
                                            },
                                            () =>
                                              this.toggle(
                                                e2.assesment &&
                                                  e2.assesment.questions,
                                                e2.assesment &&
                                                  e2.assesment.title,

                                                e2.assesment &&
                                                  e2.assesment.header_text,
                                                e2.assesment &&
                                                  e2.assesment.footer_text,
                                              ),
                                          )
                                        }}
                                      >
                                        <img src={material} alt="" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.isShow && showFullScreen ? (
          <div className="pdf-model">
            <Modal
              fullscreen={true}
              aria-labelledby="contained-modal-title-vcenter"
              dialogClassName="modal-dialog pdf-model"
              className="modal fade pdf-model"
              show={showFullScreen}
              onHide={this.toggleClose}
              style={{ paddingLeft: 0 }}
            >
              <Modal.Header closeButton></Modal.Header>
              <div className="">
                {ques &&
                  ques.map((e, i) => (
                    <>
                      {e.map((e1, i1) => (
                        <div className="check-box-group" key={i1}>
                          {e1.section !== 'undefined' && (
                            <label class="section">{e1.section}</label>
                          )}
                          {e1.question_group.map((e2) => (
                            <>
                              <div class="d-flex flex-column">
                                <label class="question_group">
                                  {e2.question_group !== 'undefined' &&
                                    e2.question_group}
                                </label>
                              </div>
                              {e2.question.map((e3) => (
                                <>
                                  <>
                                    <label style={{ color: '#26262a' }}>
                                      {e3.question}
                                    </label>
                                    {e3.response_type ===
                                      'free_text_pill_box' && (
                                      <CreatableSelect
                                        noOptionsMessage={() =>
                                          'Type and press enter to add options'
                                        }
                                        isMulti
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        isLoading={false}
                                        name="color"
                                        styles={this.customStyles}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        // onChange={(e) => {
                                        //   console.log("eeeee", e);
                                        //   const data = { ...this.state.data };
                                        //   data[e3.question] = e;
                                        //   this.setState({ data });
                                        //   console.log(e);
                                        // }}
                                      />
                                    )}
                                    {e3.response_type ===
                                      'multiselect_dropdown' && (
                                      <Select
                                        isMulti
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={false}
                                        name="color"
                                        styles={this.customStyles}
                                        options={e3.options.map((ex) => ({
                                          label: ex.title,
                                          value: ex.title,
                                        }))}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        // onChange={(e) => {
                                        //   const data = { ...this.state.data };
                                        //   data[e3.question] = e;
                                        //   this.setState({ data });
                                        //   console.log(e);
                                        // }}
                                      />
                                    )}
                                    {e3.response_type === 'dropdown' && (
                                      <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={false}
                                        isSearchable={false}
                                        name="color"
                                        styles={this.customStyles}
                                        options={e3.options.map((ex) => ({
                                          label: ex.title,
                                          value: ex.title,
                                        }))}
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                        // onChange={(e) => {
                                        //   const data = { ...this.state.data };
                                        //   data[e3.question] = e;
                                        //   this.setState({ data });
                                        //   console.log(e);
                                        // }}
                                      />
                                    )}
                                    {e3.response_type === 'text' && (
                                      <input
                                        type="text"
                                        name=""
                                        className="form-control"
                                        placeholder="Write here…"
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                      />
                                    )}
                                    {e3.response_type === 'text_area' && (
                                      <textarea
                                        style={{ padding: '10px' }}
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        value={
                                          this.state.data &&
                                          this.state.data[e3.question]
                                        }
                                      ></textarea>
                                    )}

                                    <div className="radiobox-group d-flex flex-wrap align-items-center">
                                      {e3.response_type === 'checkbox' &&
                                        e3.options.map((e4, i2) => (
                                          <div className="form-check" key={i2}>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value={e4.title}
                                              id={e4._id}
                                              name={e4.title + e4._id}
                                              checked={
                                                this.state.data &&
                                                this.state.data[e3.question] &&
                                                this.state.data[
                                                  e3.question
                                                ].find(
                                                  (echeck) =>
                                                    echeck === e4.title,
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={e4._id}
                                            >
                                              <span
                                                style={{
                                                  marginLeft: '0.5rem',
                                                }}
                                              ></span>
                                              {e4.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>
                                    <div className="radiobox-group d-flex flex-wrap align-items-center">
                                      {e3.response_type === 'radio' &&
                                        e3.options.map((e4, i2) => (
                                          <div className="form-check" key={i2}>
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              value={e4.title}
                                              id={e4.title + e4._id}
                                              name={e4.title + e4._id}
                                              checked={
                                                this.state.data &&
                                                this.state.data[e3.question] ==
                                                  e4.title
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={e4.title + e4._id}
                                            >
                                              <span
                                                style={{
                                                  marginLeft: '2rem',
                                                }}
                                              ></span>
                                              {e4.title}
                                            </label>
                                          </div>
                                        ))}
                                    </div>

                                    <div className="radiobox-group d-flex flex-wrap align-items-center">
                                      {e3.response_type === 'doc' &&
                                        e3.options.length > 0 && (
                                          <>
                                            {e3.options[0].title
                                              .split('.')
                                              .pop() === 'pdf' ||
                                            e3.options[0].title
                                              .split('.')
                                              .pop() === 'PDF' ? (
                                              <>
                                                <iframe
                                                  src={
                                                    process.env
                                                      .REACT_APP_S3URL +
                                                    e3.options[0].title +
                                                    '#navpanes=0'
                                                  }
                                                  width="100%"
                                                  style={{ height: '90vh' }}
                                                />
                                              </>
                                            ) : (
                                              <img
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e3.options[0].title
                                                }
                                                alt=""
                                              />
                                            )}
                                          </>
                                        )}
                                    </div>
                                    <br />
                                  </>
                                </>
                              ))}
                            </>
                          ))}
                        </div>
                      ))}
                    </>
                  ))}
                <div className="d-flex align-items-center justify-content-between">
                  <div class="pi-body d-flex flex-wrap">
                    <p style={{ fontSize: '11px' }}>{this.state.footer_text}</p>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div
            className={
              this.state.isShow === true
                ? 'sidebar-box preSession opennote'
                : 'sidebar-box preSession closenote'
            }
            id="pre"
            style={{ width: showFullScreen ? '100%' : '' }}
          >
            <div className="sidebar-head d-flex align-items-center">
              {this.state.isShow === true && (
                <h5>
                  <b>
                    {this.state.title}
                    {this.state.header_text && ' - ' + this.state.header_text}
                  </b>
                </h5>
              )}
              {this.state.isShow === true ? (
                <div className="close-img ml-auto" onClick={this.toggleClose}>
                  <img src={close} alt="" />
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="sidebar-body">
              {ques &&
                ques.map((e, i) => (
                  <>
                    {e.map((e1, i1) => (
                      <div className="check-box-group" key={i1}>
                        {e1.section !== 'undefined' && (
                          <label class="section">{e1.section}</label>
                        )}
                        {e1.question_group.map((e2) => (
                          <>
                            <div class="d-flex flex-column">
                              <label class="question_group">
                                {e2.question_group !== 'undefined' &&
                                  e2.question_group}
                              </label>
                            </div>
                            {e2.question.map((e3) => (
                              <>
                                <>
                                  <label style={{ color: '#26262a' }}>
                                    {e3.question}
                                  </label>
                                  {e3.response_type ===
                                    'free_text_pill_box' && (
                                    <CreatableSelect
                                      noOptionsMessage={() =>
                                        'Type and press enter to add options'
                                      }
                                      isMulti
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      name="color"
                                      styles={this.customStyles}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      // onChange={(e) => {
                                      //   console.log("eeeee", e);
                                      //   const data = { ...this.state.data };
                                      //   data[e3.question] = e;
                                      //   this.setState({ data });
                                      //   console.log(e);
                                      // }}
                                    />
                                  )}
                                  {e3.response_type ===
                                    'multiselect_dropdown' && (
                                    <Select
                                      isMulti
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name="color"
                                      styles={this.customStyles}
                                      options={e3.options.map((ex) => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      // onChange={(e) => {
                                      //   const data = { ...this.state.data };
                                      //   data[e3.question] = e;
                                      //   this.setState({ data });
                                      //   console.log(e);
                                      // }}
                                    />
                                  )}
                                  {e3.response_type === 'dropdown' && (
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name="color"
                                      styles={this.customStyles}
                                      options={e3.options.map((ex) => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      // onChange={(e) => {
                                      //   const data = { ...this.state.data };
                                      //   data[e3.question] = e;
                                      //   this.setState({ data });
                                      //   console.log(e);
                                      // }}
                                    />
                                  )}
                                  {e3.response_type === 'text' && (
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="Write here…"
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    />
                                  )}
                                  {e3.response_type === 'text_area' && (
                                    <textarea
                                      style={{ padding: '10px' }}
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    ></textarea>
                                  )}

                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'checkbox' &&
                                      e3.options.map((e4, i2) => (
                                        <div className="form-check" key={i2}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={e4.title}
                                            id={e4._id}
                                            name={e4.title + e4._id}
                                            checked={
                                              this.state.data &&
                                              this.state.data[e3.question] &&
                                              this.state.data[e3.question].find(
                                                (echeck) => echeck === e4.title,
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={e4._id}
                                          >
                                            <span
                                              style={{
                                                marginLeft: '0.5rem',
                                              }}
                                            ></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'radio' &&
                                      e3.options.map((e4, i2) => (
                                        <div className="form-check" key={i2}>
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value={e4.title}
                                            id={e4.title + e4._id}
                                            name={e4.title + e4._id}
                                            checked={
                                              this.state.data &&
                                              this.state.data[e3.question] ==
                                                e4.title
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={e4.title + e4._id}
                                          >
                                            <span
                                              style={{
                                                marginLeft: '2rem',
                                              }}
                                            ></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'doc' &&
                                      e3.options.length > 0 && (
                                        <>
                                          {e3.options[0].title
                                            .split('.')
                                            .pop() === 'pdf' ||
                                          e3.options[0].title
                                            .split('.')
                                            .pop() === 'PDF' ? (
                                            <>
                                              <iframe
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e3.options[0].title
                                                  //'#toolbar=0&navpanes=0'
                                                }
                                                width="100%"
                                                height={'100vh'}
                                              />
                                            </>
                                          ) : (
                                            <img
                                              src={
                                                process.env.REACT_APP_S3URL +
                                                e3.options[0].title
                                              }
                                              alt=""
                                            />
                                          )}
                                        </>
                                      )}
                                  </div>
                                  <br />
                                </>
                              </>
                            ))}
                          </>
                        ))}
                      </div>
                    ))}
                  </>
                ))}
              <div className="sidebar-footer d-flex align-items-center justify-content-between">
                <div class="pi-body d-flex flex-wrap">
                  <p style={{ fontSize: '11px' }}>{this.state.footer_text}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={this.state.isShow1 === true ? 'overlay4 show' : 'overlay4'}
        ></div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
  assessment: getAssessment(state).submitted,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
})
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  loadSubmittedAssessment: (params) =>
    dispatch(loadSubmittedAssessment(params)),
  loadAnswerAssessment: (params) => dispatch(loadAnswerAssessment(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Task)
