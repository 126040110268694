import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";

import {
  getMisc,
  loadConstant,
  loadBlogDetail,
  loadBlogCategory,
  loadKeyword,
  loadFaq,
  loadBlog,
} from "../../store/misc";
import image1 from "../../include/images/blog-hero.png";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment";
import { Link } from "react-router-dom";
import TopArticle from "./topArticle";

class BlogDetail extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    totalRecords: "",
    totalPages: "",
    pageLimit: "",
    currentPage: 1,
    toggle1: false,
    id: "",
    keyword: "",
    blog_id: "",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadBlogCategory();
    this.props.loadKeyword();
    this.props.loadBlog();
    this.props.loadFaq({ category: "Blog" });
    this.props.loadBlogDetail(this.props.match.params.url);
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.match.params.url !== prevProps.match.params.url) {
      this.props.loadBlogDetail(this.props.match.params.url);
    }
  };

  toggle1 = (id) => {
    this.setState({
      id: id,
      toggle1: !this.state.toggle1,
    });
  };
  onSearch = (e) => {
    e.preventDefault();
    if (this.state.keyword) {
      this.props.history.push({
        pathname: "/blog",
        keyword: this.state.keyword,
      });
    }
  };
  render() {
    const { constants, blogCategory, blogdetail, blog, keyword, faq } =
      this.props.getMisc;

    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    console.log("blogthis.props", this.props.match.params.url);
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="clinician-hero-container blog-hero">
            <div class="container container1">
              <div class="row flex-row-reverse align-items-center">
                <div class="col-xl-5">
                  <div class="clinician-hero-content pb-5">
                    <h3 class="d-block d-md-none">Mental HEALTH</h3>
                    <p>
                      Our professionals assist your mental health journey via
                      detailed evaluation, diagnosis, prescription pads, and
                      holistic care.
                    </p>
                  </div>
                </div>
                <div class="col-xl-7">
                  <div class="clinician-hero-img">
                    <img src={image1} alt="" />
                    <div class="blog-hero-text d-none d-md-block">
                      <span>
                        Mental <br />
                        HEALTH
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container blog-container">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="blog-content-box d-xl-flex align-items-xl-start flex-xl-wrap">
                    <div class="blog-left">
                      <h6>
                        <strong>{blogdetail.author}</strong>{" "}
                        {Moment(blogdetail.publish_date).format("MMM DD, YYYY")}
                      </h6>
                      <div class="heading-with-line grey overflow-hidden">
                        <h3>
                          <span>{ReactHtmlParser(blogdetail.title)}</span>
                        </h3>
                      </div>
                      <img
                        src={
                          process.env.REACT_APP_S3URL +
                          blogdetail.featured_image_url
                        }
                        alt=""
                      />
                      <div className="mt-5">
                        <p>{ReactHtmlParser(blogdetail.text)}</p>
                      </div>
                      <div class="blog-left-lower">
                        <ul class="community-kw-list d-flex flex-wrap">
                          {blogdetail.keywords &&
                            blogdetail.keywords.map((e1, i) => (
                              <li>
                                <a href="#" class="community-kw-link">
                                  {e1}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div class="blog-right">
                      <div class="br-content-box">
                        <div class="br-seartch-bar">
                          <form onSubmit={this.onSearch}>
                            <div class="form-group" onClick={this.onSearch}>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Look for topics"
                                value={this.state.keyword}
                                onChange={(e) =>
                                  this.setState({ keyword: e.target.value })
                                }
                              />
                            </div>
                          </form>
                        </div>
                        <ul class="community-kw-list d-flex flex-wrap">
                          {keyword.slice(0, 10).map((e, i) => (
                            <li onClick={this.onSearch}>
                              <Link
                                to={{
                                  pathname: "/blog",
                                  keyword: e.title,
                                }}
                              >
                                <button
                                  class="community-kw-link"
                                  onClick={() =>
                                    this.setState({ keyword: e.title })
                                  }
                                >
                                  {e.title}
                                </button>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div class="br-content-box">
                        <div class="br-heading">
                          <h3>Topics</h3>
                        </div>
                        <ul class="topic-list">
                          {blogCategory.map((e, i) => (
                            <li>
                              <Link
                                to={{ pathname: "/blog", state: e.name }}
                                class="tl-link"
                              >
                                {e.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <TopArticle />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlogCategory: () => dispatch(loadBlogCategory()),
  loadBlogDetail: (id) => dispatch(loadBlogDetail(id)),
  loadKeyword: () => dispatch(loadKeyword()),
  loadFaq: (params) => dispatch(loadFaq(params)),
  loadBlog: (params) => dispatch(loadBlog(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
