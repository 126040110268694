import React, { Component } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form/form'
import { connect } from 'react-redux'
import DashboardTopBar from './common/dashboardTopBar'
import AlertError from '../../common/alertError'
import AlertSuccess from '../../common/alertSuccess'
import { toast } from 'react-toastify'
import _ from 'lodash'
import {
  updatePatientProfile,
  loadPatientProfile,
  getPatient,
  patientDetail,
  updatePatientProfileByDoctor,
} from '../../../store/patient'
import { withRouter } from 'react-router-dom'
import rocket from './../../../include/images/rocket.svg'
import SubmitBtn from '../../common/form/submitBtn'
import TermTemplatePopup from '../../common/termTemplatePopup'
import { loadTermTemplate } from '../../../store/seo'
import ReactTooltip from 'react-tooltip'

class NomineeDetails extends Form {
  state = {
    btnClass: 'btn btn-primary btn-lg',
    loading: false,
    self: false,
    contact: false,
    popShow: false,
    relations: [
      {
        id: 'Father',
        name: 'Father',
      },
      {
        id: 'Mother',
        name: 'Mother',
      },

      {
        id: 'Grand Father',
        name: 'Grand Father',
      },
      {
        id: 'Grand Mother',
        name: 'Grand Mother',
      },
      {
        id: 'Brother',
        name: 'Brother',
      },
      {
        id: 'Sister',
        name: 'Sister',
      },
      {
        id: 'Son',
        name: 'Son',
      },
      {
        id: 'Daughter',
        name: 'Daughter',
      },
      {
        id: 'Husband',
        name: 'Husband',
      },
      {
        id: 'Wife',
        name: 'Wife',
      },
    ],
    data: {
      nominated_representative_name: '',
      representative_number: '',
      representative_relation: '',
      emergency_contact_number: '',
    },
    errors: {},
  }
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow })
  }

  openPopup = (e, key) => {
    e.preventDefault()
    this.props.loadTermTemplate(key)
    this.togglePopup()
  }
  toggleMaster = () => {
    const data = { ...this.state.data }
    const {
      nominated_representative_name,
      representative_relation,
      mobile,
      representative_number,
      guardian_relation,
      guardian_first_name,
      guardian_last_name,
      emergency_contact_number,
    } = this.props.patientProfile

    if (this.state.self === false) {
      data.representative_number = ''
      data.representative_relation = ''
      data.nominated_representative_name = ''
      this.setState({
        errors: {
          representative_number: '',
          nominated_representative_name: '',
          representative_relation: '',
        },
      })
    } else {
      data.representative_number = representative_number
        ? representative_number
        : mobile
      data.representative_relation = representative_relation
        ? representative_relation
        : guardian_relation
      data.nominated_representative_name = nominated_representative_name
        ? nominated_representative_name
        : guardian_first_name && guardian_first_name + ' ' + guardian_last_name
    }
    this.setState({
      self: !this.state.self,
      data,
    })
  }
  toggleMobile = () => {
    const data = { ...this.state.data }
    const {
      representative_number,
      nominated_representative_name,
      representative_relation,
    } = this.state.data

    if (this.state.contact === true) {
      this.setState({
        contact: false,
        errors: {
          representative_number: '',
          nominated_representative_name: '',
          representative_relation: '',
        },
      })
    }

    if (this.state.contact === false) {
      this.setState({
        contact: true,
      })
      if (representative_number) {
        data.emergency_contact_number = representative_number
        this.setState({
          data,
        })
      } else if (this.state.self === true) {
        const { mobile } = JSON.parse(localStorage.getItem('patient'))
        data.emergency_contact_number = mobile
        this.setState({
          data,
        })
      } else if (
        representative_number === '' &&
        nominated_representative_name === '' &&
        representative_relation === ''
      ) {
        this.setState({
          errors: {
            representative_number: 'Representative Number field is required.',
            nominated_representative_name:
              'Nominee Representative Name field is required.',
            representative_relation:
              'Representative Relation field is required.',
          },
          data,
        })
      } else if (
        representative_number === null &&
        nominated_representative_name === '' &&
        representative_relation === ''
      ) {
        this.setState({
          errors: {
            representative_number: 'Representative Number field is required.',
            nominated_representative_name:
              'Nominee Representative Name field is required.',
            representative_relation:
              'Representative Relation field is required.',
          },
          data,
        })
      } else if (
        representative_number === '' &&
        representative_relation === ''
      ) {
        this.setState({
          errors: {
            representative_number: 'Representative Number field is required.',
            representative_relation:
              'Representative Relation field is required.',
          },
          data,
        })
      } else if (
        representative_number === '' &&
        nominated_representative_name === ''
      ) {
        this.setState({
          errors: {
            representative_number: 'Representative Number field is required.',
            nominated_representative_name:
              'Nominee Representative Name field is required.',
          },
          data,
        })
      } else if (representative_number === '') {
        this.setState({
          errors: {
            representative_number: 'Representative Number field is required.',
          },
          data,
        })
      }
    } else if (this.props.patientProfile.emergency_contact_number) {
      const { emergency_contact_number } = this.props.patientProfile
      data.emergency_contact_number = emergency_contact_number
      this.setState({
        data,
      })
    } else {
      data.emergency_contact_number = ''
      this.setState({
        data,
      })
    }
  }
  schema = {
    nominated_representative_name: Joi.string()
      .allow('')
      .label('Nominated Representative Name'),
    representative_relation: Joi.string()
      .allow('')
      .label('Select Relationship'),
    representative_number: Joi.number()
      .allow('', null)
      .label('Representative Number'),
    emergency_contact_number: Joi.number()
      .allow('')
      .label('Emergency Contact Number (optional)'),
  }
  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
    if (this.props.isDoctor) {
      this.props.loadPatientProfileDoctor(this.props.patientId)
    } else if (localStorage.getItem('patient')) {
      this.props.loadPatientProfile()
    }
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.patientProfile._id &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      const {
        nominated_representative_name,
        representative_relation,
        representative_number,
        emergency_contact_number,
        self,
        guardian_first_name,
        guardian_last_name,
        mobile,
        guardian_relation,
        same_as_representative,
      } = this.props.patientProfile
      this.setState({
        data: {
          nominated_representative_name: nominated_representative_name
            ? nominated_representative_name
            : self === true
            ? ''
            : guardian_first_name
            ? guardian_first_name
            : '',
          representative_relation: representative_relation
            ? representative_relation
            : self === true
            ? ''
            : guardian_relation
            ? guardian_relation
            : '',
          representative_number: representative_number
            ? representative_number
            : self === true
            ? ''
            : guardian_first_name
            ? mobile
            : '',
          emergency_contact_number: emergency_contact_number
            ? emergency_contact_number
            : '',
        },
        self: self,
        contact: same_as_representative,
      })
    }
  }

  doSubmit = () => {
    if (localStorage.getItem('patient') || this.props.isDoctor) {
      const {
        nominated_representative_name,
        representative_relation,
        representative_number,
        emergency_contact_number,
      } = this.state.data
      const { self, contact } = this.state
      const data = {
        nominated_representative_name,
        representative_relation,
        representative_number,
        emergency_contact_number,
        self,
        same_as_representative: contact,
      }
      if (this.state.contact === true && this.state.self === false) {
        if (
          representative_number === '' &&
          nominated_representative_name === '' &&
          representative_relation === ''
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
              nominated_representative_name:
                'Nominee Representative Name field is required.',
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          representative_number === '' &&
          representative_relation === ''
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          representative_number === '' &&
          nominated_representative_name === ''
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
              nominated_representative_name:
                'Nominee Representative Name field is required.',
            },
          })
        } else if (representative_number === '') {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
            },
          })
        } else if (
          data.representative_number == data.emergency_contact_number
        ) {
          if (this.props.isDoctor) {
            this.props.updatePatientProfileByDoctor(
              data,
              this.props.patientId,
              this.callBackUpdate
            )
          } else {
            this.props.updatePatientProfile(data, this.callBackUpdate)
          }
        }
      } else if (
        data.nominated_representative_name != undefined ||
        data.representative_relation != undefined ||
        data.representative_number != undefined
      ) {
        if (
          this.state.self === false &&
          data.nominated_representative_name === '' &&
          data.representative_number === '' &&
          data.representative_relation === ''
        ) {
          this.setState({
            errors: {
              nominated_representative_name:
                'Nominee Representative Name field is required.',
              representative_number: 'Representative Number field is required.',
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          data.nominated_representative_name === '' &&
          data.representative_number === null &&
          data.representative_relation === ''
        ) {
          this.setState({
            errors: {
              nominated_representative_name:
                'Nominee Representative Name field is required.',
              representative_number: 'Representative Number field is required.',
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          representative_number === '' &&
          representative_relation === ''
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          representative_number === '' &&
          nominated_representative_name === ''
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
              nominated_representative_name:
                'Nominee Representative Name field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          representative_relation === '' &&
          nominated_representative_name === ''
        ) {
          this.setState({
            errors: {
              representative_relation:
                'Representative Relation field is required.',
              nominated_representative_name:
                'Nominee Representative Name field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          representative_relation === ''
        ) {
          this.setState({
            errors: {
              representative_relation:
                'Representative Relation field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          nominated_representative_name === ''
        ) {
          this.setState({
            errors: {
              nominated_representative_name:
                'Nominee Representative Name field is required.',
            },
          })
        } else if (this.state.self === false && representative_number === '') {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
            },
          })
        } else if (
          this.state.self === false &&
          representative_number === null
        ) {
          this.setState({
            errors: {
              representative_number: 'Representative Number field is required.',
            },
          })
        } else {
          if (this.props.isDoctor) {
            this.props.updatePatientProfileByDoctor(
              data,
              this.props.patientId,
              this.callBackUpdate
            )
          } else {
            this.props.updatePatientProfile(data, this.callBackUpdate)
          }
        }
      }
    } else {
      if (!this.state.loading) {
        this.setState({
          btnClass: 'btn btn-primary btn-lg disabled',
          loading: true,
        })
      }
    }
  }
  callBackUpdate = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res && res.status === 200) {
      if (this.props.isDoctor) {
        toast(<AlertSuccess message='Date saved successfully' />)

        this.props.history.goBack()
      } else {
        this.props.updateStep(null, 3)
        this.props.loadPatientProfile()
      }
    } else {
      toast(<AlertError message='failed' />)
    }
  }
  render() {
    console.log('this.props', this.props.patientProfile)
    return (
      <div className='dashboard-content-box'>
        <ReactTooltip className='testPurple' />
        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          ''
        ) : (
          <DashboardTopBar
            step={this.props.step}
            updateStep={this.props.updateStep}
          />
        )}
        <form onSubmit={this.handleSubmit} id='myform'>
          <div
            className={
              window.location.pathname ===
              '/patient/dashboard/profile-information'
                ? ''
                : 'dc-body d-flex flex-wrap align-items-lg-center'
            }>
            <div className='dc-left'>
              {/* <h4 className="d-block d-xl-none">
                Nominated Representative Name
              </h4> */}

              {this.state.self === true ? (
                <>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'nominated_representative_name',
                        this.props.patientProfile.type === 1
                          ? 'Nominated Representative Name'
                          : 'Guardian Name',
                        'text',
                        true
                      )}
                    </div>
                    <div className='col-md-6'>
                      {this.renderNominee(
                        'representative_number',
                        this.props.patientProfile.type == 1
                          ? 'Representative Number'
                          : 'Guardian Number',
                        this.props.patientProfile.type == 1
                          ? 'Nominated representative is for the benefit of the patients, to provide a support system and assistance in treatment like helping in taking decisions, supervising treatment, handling emergency situations and be the point of contact for mental health experts only in case of need and not otherwise'
                          : '',
                        'text',
                        true,
                        this.props.patientProfile.type == 1 ? true : false
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderSelect(
                        'representative_relation',
                        'Select Relationship',
                        [],
                        true
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'nominated_representative_name',
                        this.props.patientProfile.type === 1
                          ? 'Nominated Representative Name'
                          : 'Nominated Guardian'
                      )}
                    </div>

                    <div className='col-md-6'>
                      {this.renderNominee(
                        'representative_number',
                        'Representative Number',
                        'Nominated representative is someone who can provide support and be your nominated caregiver whom you can trust in times of need and decision making. No personal or private information will be shared without your knowledge. Nominated representative may have some right to information in case of emergencies where it is in your welfare.',
                        'text',
                        false
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderSelect(
                        'representative_relation',
                        'Select Relationship',
                        this.state.relations
                      )}
                    </div>
                  </div>
                </>
              )}
              {this.props.patientProfile &&
                this.props.patientProfile.type &&
                this.props.patientProfile.type != 2 && (
                  <div className='row'>
                    <div className='form-group ml-2'>
                      <div className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckDefault'
                          defaultChecked={this.props.patientProfile.self}
                          onClick={this.toggleMaster}
                        />
                        <label
                          className='form-check-label ml-4'
                          htmlFor='flexCheckDefault'>
                          <strong>Self</strong> (In case you are not to declare
                          anyone)
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              <div className='row'>
                <div className='col-md-8'>
                  {this.renderNominee(
                    'emergency_contact_number',
                    'Emergency Contact Number (optional)',
                    'Someone who lives near you and is in close contact with you. Emergency contact will not be contacted to share any personal or private information. It is only for worse case scenarios and emergency situation to use for your benefits.'
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='form-group'>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexCheckDefault1'
                      defaultChecked={
                        this.props.patientProfile.same_as_representative
                      }
                      onClick={this.toggleMobile}
                    />
                    <label
                      className='form-check-label ml-4'
                      htmlFor='flexCheckDefault1'>
                      Same as representative
                    </label>
                  </div>
                </div>
              </div>
              <div className='agree-box'>
                <p>
                  By clicking “SAVE and NEXT”, you agree to the{' '}
                  <a href='' onClick={e => this.openPopup(e, 'PatientTerms')}>
                    Patient Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a
                    href=''
                    onClick={e =>
                      this.openPopup(e, 'nominated-representative-terms')
                    }>
                    Nominated Representative Terms & Conditions
                  </a>
                  {/* By clicking “NEXT”, you agree to the{" "}
                  <a href="" onClick={(e) => this.openPopup(e, "PatientTerms")}>
                    terms and conditions
                  </a> */}
                </p>
              </div>
            </div>
            {window.location.pathname ===
            '/patient/dashboard/profile-information' ? (
              ''
            ) : (
              <div className='dc-right'>
                <div className='dc-right-content'>
                  <div className='dc-img-box'>
                    <img src={rocket} alt='' />
                  </div>
                  <h1>
                    Welcome to <span>Yes</span>Mindy
                  </h1>
                  <p> Let's complete your profile</p>
                </div>
              </div>
            )}
          </div>
        </form>
        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          <div className='dc-bottom-box'>
            <SubmitBtn
              label='SAVE and NEXT'
              form='myform'
              loading={this.state.loading}
              btnClass='btn btn-default no-shadow'
            />
          </div>
        ) : (
          <div className='dc-footer d-flex align-items-center justify-content-between'>
            <button
              className='btn btn-grey'
              onClick={e => {
                this.props.updateStep(e, 1)
              }}>
              Go Back
            </button>
            <SubmitBtn
              label='SAVE and NEXT'
              form='myform'
              loading={this.state.loading}
              btnClass={this.state.btnClass}
            />
          </div>
        )}
        <TermTemplatePopup
          type={this.props.patientProfile.type}
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  patientProfile: getPatient(state).profile,
  loading: getPatient(state).loading,
})
const mapDispatchToProps = dispatch => ({
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
  updatePatientProfileByDoctor: (param, id, callback) =>
    dispatch(updatePatientProfileByDoctor(param, id, callback)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  loadPatientProfileDoctor: id => dispatch(patientDetail(id, true)),
  loadTermTemplate: key => dispatch(loadTermTemplate(key)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NomineeDetails)
)
