import React, { Component } from "react";

import Header from "./header";
import LeftBar from "./leftBar";
import PersonalDetails from "./personalDetails";

class AddStaff extends Component {
  state = { data: {}, step: 1, sidebarToogle: false };

  componentDidMount() {
    document.body.classList.remove("p-0");
    document.body.classList.add("dashboard");
  }

  updateData = (data) => {
    this.setState({ data });
  };
  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };
  updateStep = (step) => this.setState({ step });
  render() {
    return (
      <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div className="dashboard-main-container">
          <PersonalDetails
            updateData={this.updateData}
            updateStep={this.updateStep}
          />
        </div>
      </div>
    );
  }
}

export default AddStaff;
