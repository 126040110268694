import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import prior_appointment from './../../../../include/pdf/prior_appointment.pdf'
import TermTemplatePopup from '../../../common/termTemplatePopup'
import { loadTermTemplate } from '../../../../store/seo'
import { connect } from 'react-redux'

class DateTimeSelection extends Component {
  state = { popShow: false }
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow })
  }

  openPopup = (e, key) => {
    e.preventDefault()
    this.props.loadTermTemplate(key)
    this.togglePopup()
  }

  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  render() {
    return (
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="pane-tab-e"
          role="tabpanel"
          aria-labelledby="tab-e"
        >
          {this.props.getDoctor.doctors.data.length > 0 && (
            <div className="pane-content-box">
              <h4>Select Date</h4>
              <div className="custom-radio-group d-flex date-slot-parent">
                {this.props.getAppointment.dates.map((dv, i) => (
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id={dv.date}
                      name="radio1"
                      checked={this.props.selectedDate === dv.date}
                      onChange={(e) => {
                        e.preventDefault()
                        this.props.selectDate(this.props.apType, dv.date)
                      }}
                    />
                    <label className="custom-control-label" htmlFor={dv.date}>
                      <p>{dv.day.sortName}</p>
                      <h4>
                        {new Date(dv.date).getDate() +
                          ' ' +
                          this.props.months[new Date(dv.date).getMonth()]}
                      </h4>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="pane-content-box">
            <>
              {this.props.getAppointment.loadingSlots ? (
                <h4>Loading ....</h4>
              ) : (
                <>
                  <h4>
                    {this.props.selectedDate &&
                      (this.props.getAppointment.slots.length
                        ? 'Select time slot'
                        : 'Not Available!')}
                  </h4>
                  <div className="custom-radio-group slot-radio-group d-flex flex-wrap">
                    {_.uniqBy(this.props.getAppointment.slots, 'start').map(
                      (vs) => (
                        <div className="custom-control custom-radio">
                          <input
                            disabled={!vs.is_available}
                            type="radio"
                            className="custom-control-input"
                            id={vs.start}
                            name={vs.start}
                            checked={this.props.selectedTime === vs.start}
                            onChange={() => this.props.changeTime(vs.start)}
                          />
                          <label
                            className={
                              vs.is_available
                                ? 'custom-control-label'
                                : 'custom-control-label disabled-slot'
                            }
                            htmlFor={vs.start}
                          >
                            {this.formatAMPM(new Date(vs.start))}
                          </label>
                        </div>
                      ),
                    )}
                  </div>
                </>
              )}
            </>
          </div>{' '}
          <div className="pane-btn">
            <button
              disabled={
                this.props.selectedDate === '' ||
                this.props.selectedTime === '' ||
                this.props.loading
              }
              onClick={() => this.props.doSubmit(this.props.apType)}
              className={
                this.props.selectedDate !== '' &&
                this.props.selectedTime !== '' &&
                !this.props.loading
                  ? 'btn btn-primary'
                  : 'btn btn-primary disabled'
              }
            >
              Proceed
            </button>
          </div>
        </div>
        <div
          class="mr-bottom"
          style={{ position: 'relative', padding: '35px 0px 0px !important' }}
        >
          <p>
            By clicking proceed, I agree to{' '}
            <a href="" onClick={(e) => this.openPopup(e, 'PatientTerms')}>
              terms of service
            </a>{' '}
            and{' '}
            <a
              onClick={(e) => this.openPopup(e, 'user-privacy-policy')}
              href=""
            >
              privacy policy.
            </a>
          </p>
        </div>
        <TermTemplatePopup
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadTermTemplate: (key) => dispatch(loadTermTemplate(key)),
})
export default connect(null, mapDispatchToProps)(DateTimeSelection)
