import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../../../include/images/yes-mindy-color-logo-small.png";
import { getDoctor } from "../../../store/doctor";
import { loadSeo } from "../../../store/seo";
import { getDoctorToken } from "../../services/localStorageServices";

class SignUpLeftBar extends Component {
  state = {};

  navigate = (e, url) => {
    e.preventDefault();
    if (getDoctorToken("auth")) {
      this.props.history.push(url);
    }
  };

  componentDidMount = () => {
    this.props.loadSeo("doctor-registration");
    document.body.className = "";
  };

  changeBodyClass = () => {
    document.body.classList.remove("active");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.doctorProfile
    ) {
      console.log("12121212", this.props.doctorProfile);
    }
  };

  navClass = (url, completed = false) => {
    let className = "nav-item ";
    if (url) className = className + "active ";
    if (completed) className = className + "completed";
    console.log("dddd", className);
    return className;
  };
  render() {
    //const { email } = this.props.doctorProfile;
    return (
      <div className="leftbar">
        <div className="navbar">
          <a className="brand-logo">
            <img src={logo} alt="" />
          </a>
          <button className="nav-toggler" onClick={this.changeBodyClass}>
            <i className="fas fa-times"></i>
          </button>
          <ul className="nav-list">
            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/personal-detail",
                this.props.doctorProfile.mobile
                  ? this.props.doctorProfile.mobile
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) =>
                  this.navigate(e, "/doctor/sign-up/personal-detail")
                }
              >
                <em>1</em> Personal Details
              </a>
            </li>
            <li
              className={this.navClass(
                this.props.match.path ===
                  "/doctor/sign-up/educational-detail/education" ||
                  this.props.match.path ===
                    "/doctor/sign-up/educational-detail/experience" ||
                  this.props.match.path ===
                    "/doctor/sign-up/educational-detail/publications" ||
                  this.props.match.path ===
                    "/doctor/sign-up/educational-detail/certification" ||
                  this.props.match.path ===
                    "/doctor/sign-up/educational-detail/affiliations",
                this.props.doctorProfile.graduation
                  ? Object.keys(this.props.doctorProfile.graduation).length
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) =>
                  this.navigate(
                    e,
                    "/doctor/sign-up/educational-detail/education"
                  )
                }
              >
                <em>2</em> Qualifications &amp; Experience
              </a>
              <ul className="sub-menu">
                <li
                  className={
                    this.props.match.path ===
                    "/doctor/sign-up/educational-detail/education"
                      ? "active"
                      : "false"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) =>
                      this.navigate(
                        e,
                        "/doctor/sign-up/educational-detail/education"
                      )
                    }
                  >
                    Educations
                  </a>
                </li>
                <li
                  className={
                    this.props.match.path ===
                    "/doctor/sign-up/educational-detail/experience"
                      ? "active"
                      : "false"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) =>
                      this.navigate(
                        e,
                        "/doctor/sign-up/educational-detail/experience"
                      )
                    }
                  >
                    Work Experience
                  </a>
                </li>
                <li
                  className={
                    this.props.match.path ===
                    "/doctor/sign-up/educational-detail/certification"
                      ? "active"
                      : "false"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) =>
                      this.navigate(
                        e,
                        "/doctor/sign-up/educational-detail/certification"
                      )
                    }
                  >
                    Certifications/Fellowships
                  </a>
                </li>

                <li
                  className={
                    this.props.match.path ===
                    "/doctor/sign-up/educational-detail/affiliations"
                      ? "active"
                      : "false"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) =>
                      this.navigate(
                        e,
                        "/doctor/sign-up/educational-detail/affiliations"
                      )
                    }
                  >
                    Awards/Affiliations
                  </a>
                </li>
                <li
                  className={
                    this.props.match.path ===
                    "/doctor/sign-up/educational-detail/publications"
                      ? "active"
                      : "false"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) =>
                      this.navigate(
                        e,
                        "/doctor/sign-up/educational-detail/publications"
                      )
                    }
                  >
                    Publications
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/documentation",
                this.props.doctorProfile.documents_url
                  ? Object.keys(this.props.doctorProfile.documents_url).length
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) =>
                  this.navigate(e, "/doctor/sign-up/documentation")
                }
              >
                <em>3</em> Documentations
              </a>
            </li>

            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/clinic",
                this.props.doctorProfile.clinic_details
                  ? this.props.doctorProfile.clinic_details.cityId
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) => this.navigate(e, "/doctor/sign-up/clinic")}
              >
                <em>4</em> Clinic Details
              </a>
            </li>
            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/associations",
                this.props.doctorProfile.associations
                  ? this.props.doctorProfile.associations[0]
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) =>
                  this.navigate(e, "/doctor/sign-up/associations")
                }
              >
                <em>5</em> Associations
              </a>
            </li>
            <li
              className={this.navClass(
                this.props.match.path ===
                  "/doctor/sign-up/practice-preferences",
                this.props.doctorProfile.practice_preferences
                  ? this.props.doctorProfile.practice_preferences.about_me
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) =>
                  this.navigate(e, "/doctor/sign-up/practice-preferences")
                }
              >
                <em>6</em> Practice Preferences
              </a>
            </li>
            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/schedule",
                this.props.doctorProfile.schedules
                  ? this.props.doctorProfile.schedules.time
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) => this.navigate(e, "/doctor/sign-up/schedule")}
              >
                <em>7</em> Schedule
              </a>
            </li>

            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/bank",
                this.props.doctorProfile.bank_details
                  ? this.props.doctorProfile.bank_details.bank_name
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) => this.navigate(e, "/doctor/sign-up/bank")}
              >
                <em>8</em> Bank Details
              </a>
            </li>

            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/link",
                this.props.doctorProfile.links
                  ? this.props.doctorProfile.links.clinic
                  : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) => this.navigate(e, "/doctor/sign-up/link")}
              >
                <em>9</em> Link SetUp
              </a>
            </li>
            <li
              className={this.navClass(
                this.props.match.path === "/doctor/sign-up/contract",
                this.props.doctorProfile.contract_accepted ? true : false
              )}
            >
              <a
                href="#"
                className="nav-link"
                onClick={(e) => this.navigate(e, "/doctor/sign-up/contract")}
              >
                <em>10</em> Contract
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
});
const mapStateToProps = (state) => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpLeftBar)
);
