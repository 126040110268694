import React, { Component } from "react";
import sendIcon from "../../../../../include/images/send-icon.svg";
import { io } from "socket.io-client";
import { getPatient, loadPatientProfile } from "../../../../../store/patient";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  chatReceived,
  getChats,
  loadChatForPatient,
} from "../../../../../store/chats";
import moment from "moment";

class MessegesTest extends Component {
  state = {
    ready: false,
    msgData: "",
  };
  sendMessege = (msg) => {
    if (msg) {
      this.socket.emit("message", {
        msg: msg,
        reciever: "600a7e3de4402d28b5e0ef90",
        sender: "6001830fe76dc213e22ca57e",
        time: new Date(),
      });
      this.setState({ msgData: "" });
      this.recievedMsg({
        msg: msg,
        reciever: "600a7e3de4402d28b5e0ef90",
        sender: "6001830fe76dc213e22ca57e",
        //time: new Date(),
      });
    }
  };

  recievedMsg = (msg) => {
    this.props.chatReceived(msg);
  };

  componentWillUnmount = () => {
    this.socket.disconnect();
  };

  socket;

  componentDidMount = () => {
    this.socket = io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: false,
      upgrade: false,
      transports: ["websocket"],
      query: {
        _id: "6001830fe76dc213e22ca57e",
      },
    });
    this.socket.on("connect", () => {
      console.log("connected");
      this.socket.on("message", (message) => {
        console.log("message", message);
        this.recievedMsg(message);
      });
      this.socket.off("message", this.recievedMsg);
    });
    this.socket.connect();
    this.socket.on("disconnect", () => {
      this.socket.removeAllListeners();
    });
  };
  render() {
    return (
      <>
        <div className="card-header">Messages</div>
        <div className="content-body show">
          <div className="chat-area">
            <div className="chat">
              {this.props.chats.map((es) => (
                <>
                  {"6001830fe76dc213e22ca57e" === es.sender ? (
                    <div className="chat-message-box-even">
                      <div className="chat-message">{es.msg}</div>
                      <div className="time">
                        {moment(es.time).format("hh:mm:ss A")}{" "}
                      </div>
                    </div>
                  ) : (
                    <div className="chat-message-box">
                      <div className="d-flex">
                        <span></span>
                        <div className="chat-message">{es.msg}</div>
                      </div>
                      <div className="time">10:24</div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="send">
              <input
                onChange={(e) => this.setState({ msgData: e.target.value })}
                className="input-message"
                name="msgData"
                value={this.state.msgData}
                placeholder="Write your message…"
                type="text"
                id="message"
                onKeyDown={(e) => {
                  if (e.key === "Enter") this.sendMessege(this.state.msgData);
                }}
              />
              <button
                className="input-send"
                onClick={(e) => {
                  if (this.state.msgData) this.sendMessege(this.state.msgData);
                }}
              >
                <i>
                  <img src={sendIcon} alt="" />
                </i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  chats: getChats(state).chatData,
});
const mapDispatchToProps = (dispatch) => ({
  chatReceived: (data) => dispatch(chatReceived(data)),
  loadChatForPatient: (id) => dispatch(loadChatForPatient(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessegesTest)
);
