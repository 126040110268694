import React, { Component } from 'react'
import calculatorimg from '../../../../include/images/calculatorimg.svg'
import material from '../../../../include/images/material-note.svg'
import match from '../../../../include/images/icon-matchat.svg'
import InfiniteScroll from 'react-infinite-scroller'
import Moment from 'moment'
import moment from 'moment'
import NotesThought from './notesThought'
import Messages from './messages'
import Prescription from './prescription'
import ReactTooltip from 'react-tooltip'

export default class JourneyList extends Component {
  state = {
    isShow: false,
    isShow2: false,
    isShow3: false,
    appoint_id: '',
    doctor_id: '',
    doc_id: '',
    doc_name: '',
    image: '',
    field: '',
    appointment_id: '',
  }

  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  toggle = (id, doc_id) => {
    this.setState({
      isShow: !this.state.isShow,
      appoint_id: id,
      doctor_id: doc_id,
    })
  }
  toggle2 = (id, name, image, field) => {
    this.setState({
      isShow2: !this.state.isShow2,
      doc_id: id,
      doc_name: name,
      image: image,
      field: field,
    })
  }
  toggle3 = (appointment_id) => {
    this.setState({
      isShow3: !this.state.isShow3,
      appointment_id: appointment_id,
    })
  }
  render() {
    return (
      <>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={this.props.loadFunc}
          hasMore={
            !this.props.loading &&
            this.props.pagination.filtered_record !=
              this.props.pagination.total_record_on_current_page
          }
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          {this.props.appointment &&
            this.props.appointment.map((e, i) => {
              const arr = [
                e?.doctor_id?.field_type.name,
                e?.doctor_id?.graduation?.education,
                e?.doctor_id?.post_graduation?.education,
                e?.doctor_id?.super_speciality?.education,
              ]
              let valueText = ''
              arr.map((items, i) => {
                if (items) valueText += i === 0 ? items : ', ' + items
              })

              return (
                <>
                  {e.doctor_id && (
                    <li class="np-item" key={i}>
                      <ReactTooltip className="testPurple" html={true} />

                      <div class="np-box d-lg-flex align-items-center justify-content-between">
                        <div class="np-left d-lg-flex flex-wrap align-items-center">
                          <div class="np-img-box">
                            <img
                              src={
                                e.doctor_id &&
                                process.env.REACT_APP_S3URL +
                                  e.doctor_id.image_url
                              }
                              alt=""
                            />
                          </div>
                          <div class="np-text-box">
                            <h5>{e.doctor_id.name}</h5>
                            <p>{valueText}</p>
                          </div>
                        </div>
                        <div class="np-mid">
                          <span>
                            {moment(e.appointment_time)
                              .utc()
                              .format('DD MMM, YYYY')}
                          </span>
                          <span>
                            {this.formatAMPM(new Date(e.appointment_time))} -{' '}
                            {this.formatAMPM(
                              new Date(
                                moment(e.appointment_time).add(
                                  e.doctor_id.schedules.time,
                                  'minutes',
                                ),
                              ),
                            )}
                          </span>
                        </div>
                        <div class="np-right d-flex flex-wrap">
                          <a
                            href="#"
                            class="np-links"
                            data-toggle="calculator"
                            data-tip="Prescription"
                            aria-hidden="true"
                            onClick={(event) => {
                              event.preventDefault()
                              this.toggle3(e._id)
                            }}
                          >
                            <img src={calculatorimg} alt="" />
                          </a>

                          <a
                            href="#"
                            class="np-links"
                            data-toggle="notes"
                            data-tip="Notes & Thoughts"
                            aria-hidden="true"
                            onClick={() => this.toggle(e._id, e.doctor_id._id)}
                          >
                            <img src={material} alt="" />
                          </a>

                          {moment().diff(
                            moment(e.appointment_time).subtract({
                              hours: 5,
                              minutes: 30,
                            }),
                            'minutes',
                          ) > -1440 && (
                            <a
                              href="#"
                              class="np-links"
                              onClick={() =>
                                this.toggle2(
                                  e.doctor_id._id,
                                  e.doctor_id.name,
                                  e.doctor_id.image_url,
                                  e.doctor_id.field_type.name,
                                )
                              }
                              data-tip="Message"
                              aria-hidden="true"
                              data-toggle="matchat"
                            >
                              <img src={match} alt="" />
                            </a>
                          )}

                          <a
                            class="np-links"
                            data-toggle="notes"
                            data-tip="Download an invoice"
                            aria-hidden="true"
                            href={'/invoice/' + e._id}
                            target="_blank"
                            style={{ color: '#621ac0', fontSize: 25 }}
                          >
                            <i class="fa fa-file-pdf" aria-hidden="true"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  )}
                </>
              )
            })}
        </InfiniteScroll>
        <NotesThought
          isShow={this.state.isShow}
          toggle={this.toggle}
          appoint_id={this.state.appoint_id}
          doctor_id={this.state.doctor_id}
        />
        <Messages
          isShow={this.state.isShow2}
          toggle={this.toggle2}
          doctor_id={this.state.doc_id}
          patient_id={JSON.parse(localStorage.getItem('patient'))._id}
          doc_name={this.state.doc_name}
          image={this.state.image}
          field={this.state.field}
        />
        <Prescription
          isShow={this.state.isShow3}
          toggle={this.toggle3}
          appointment_id={this.state.appointment_id}
        />
      </>
    )
  }
}
