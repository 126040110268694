import React, { Component } from "react";
import { connect } from "react-redux";
import { physicalOthers, cnsvalue, resp, git, hmf } from "./common";
export default class PhysicalExamination extends Component {
  render() {
    return (
      <>
        <h4>Physical Examination</h4>
        <div class="radio-group-box">
          <h5>Vital Signs</h5>
          <div class="input-group-row d-flex flex-wrap">
            <div class="ig-column">
              <div
                className={
                  this.props.pulse
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="pulse">Pulse</label>
                <input
                  type="text"
                  id="pulse"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.pulse}
                />
              </div>
            </div>
            <div class="ig-column">
              <div
                className={
                  this.props.height
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="height">Height</label>
                <input
                  type="text"
                  id="height"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.height}
                />
              </div>
            </div>
          </div>
          <div class="input-group-row d-flex flex-wrap">
            <div class="ig-column">
              <div
                className={
                  this.props.bp
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="bp">BP</label>
                <input
                  type="text"
                  id="bp"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.bp}
                />
              </div>
            </div>
            <div class="ig-column">
              <div
                className={
                  this.props.weight
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="weight">Weight</label>
                <input
                  type="text"
                  id="weight"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.weight}
                />
              </div>
            </div>
          </div>
          <div class="input-group-row d-flex flex-wrap">
            <div class="ig-column">
              <div
                className={
                  this.props.rr
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="rr">RR</label>
                <input
                  type="text"
                  id="rr"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.rr}
                />
              </div>
            </div>
            <div class="ig-column">
              <div
                className={
                  this.props.bp2
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="bp2">BP</label>
                <input
                  type="text"
                  id="bp2"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.bp2}
                />
              </div>
            </div>
          </div>
          <div class="input-group-row d-flex flex-wrap">
            <div class="ig-column">
              <div
                className={
                  this.props.bmi
                    ? "form-group custom-input show on"
                    : "form-group custom-input"
                }
              >
                <label htmlFor="bmi">BMI</label>
                <input
                  type="text"
                  id="bmi"
                  class="form-control"
                  onChange={(event) => this.props.handleInputChange(event)}
                  value={this.props.bmi}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Temp</h5>
          <div class="radio-group-row d-flex flex-wrap align-items-center">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="Febrile"
                name="temp"
                onChange={() => this.props.handleTempChange("Febrile")}
                defaultChecked={
                  this.props.temp &&
                  this.props.temp.find((el) => el === "Febrile")
                }
              />
              <label class="form-check-label" htmlFor="Febrile">
                Febrile
              </label>
            </div>
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="Afebrile"
                name="temp"
                onChange={() => this.props.handleTempChange("Afebrile")}
                defaultChecked={
                  this.props.temp &&
                  this.props.temp.find((el) => el === "Afebrile")
                }
              />
              <label class="form-check-label" htmlFor="Afebrile">
                Afebrile
              </label>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Others</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {physicalOthers.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.physicalOther &&
                      this.props.physicalOther.find((el) => el === e.other)
                    }
                    id={e.other}
                    class="form-check-input"
                    name="selectOption_11"
                    onChange={() => this.props.changeLang2(e.other)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.other}>
                    {e.other}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <h5>General</h5>
          <div class="radio-group-row d-flex flex-wrap align-items-center">
            <div class="form-check">
              <input
                type="radio"
                class="form-check-input"
                id="physicalGeneral"
                name="physicalGeneral"
                onChange={(event) =>
                  this.props.handlephysicalGeneral("physicalGeneral")
                }
                checked={this.props.physicalGeneral}
              />
              <label class="form-check-label" htmlFor="physicalGeneral">
                Within Normal Limit
              </label>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Other Physical Findings</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.physicalFinding
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="physicalFinding"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.physicalFinding}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>CVS</h5>
          <div class="radio-group-row d-flex flex-wrap align-items-center">
            <div class="form-check">
              <input
                type="radio"
                class="form-check-input"
                id="cvs"
                name="cvs"
                value="cvs"
                onChange={(event) => this.props.handleInputCVS("csv")}
                checked={this.props.cvs}
              />
              <label class="form-check-label" htmlFor="cvs">
                Within Normal Limit
              </label>
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Abnormal Finding</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.abnormalFindings1
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="abnormalFindings1"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.abnormalFindings1}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>CNS</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {cnsvalue.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.cns &&
                      this.props.cns.find((el) => el === e.CNS)
                    }
                    id={e.CNS}
                    class="form-check-input"
                    name="cns"
                    onChange={() => this.props.handleCNSChange(e.CNS)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.CNS}>
                    {e.CNS}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Abnormal Findings</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.abnormalFindings2
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="abnormalFindings2"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.abnormalFindings2}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Respiratory</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {resp.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.respiratory &&
                      this.props.respiratory.find((el) => el === e.respiratory)
                    }
                    id={e.respiratory}
                    class="form-check-input"
                    name="respiratory"
                    onChange={() => this.props.handleRespChange(e.respiratory)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.respiratory}>
                    {e.respiratory}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Abnormal Findings</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.abnormalFindings3
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="abnormalFindings3"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.abnormalFindings3}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>GIT</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {git.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.git &&
                      this.props.git.find((el) => el === e.git)
                    }
                    id={e.git}
                    class="form-check-input"
                    name="respiratory"
                    onChange={() => this.props.handleGitChange(e.git)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.git}>
                    {e.git}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Abnormal Findings</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.abnormalFindings4
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="abnormalFindings4"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.abnormalFindings4}
              />
            </div>
          </div>
        </div>
        <div class="radio-group-box">
          <h5>Higher Mental Functions</h5>
          <ul class="checkbox-group-list four-column d-flex flex-wrap">
            {hmf.map((e) => (
              <li class="checkbox-group-item">
                <div class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    checked={
                      this.props.higherMental &&
                      this.props.higherMental.find((el) => el === e.hmf)
                    }
                    id={e.hmf}
                    class="form-check-input"
                    name="higher_mental"
                    onChange={() => this.props.handleHigherMental(e.hmf)}
                  />
                  <label class="form-check-label ml-4" htmlFor={e.hmf}>
                    {e.hmf}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div class="radio-group-box">
          <h5>Mention Any Abnormal Findings</h5>
          <div class="input-group-row">
            <div
              className={
                this.props.abnormalFindings5
                  ? "form-group custom-input show on"
                  : "form-group custom-input"
              }
            >
              <textarea
                type="text"
                id="abnormalFindings5"
                class="form-control"
                onChange={(event) => this.props.handleInputChange(event)}
                value={this.props.abnormalFindings5}
              />
            </div>
          </div>
        </div>
        <div class="pane-seprator"></div>
      </>
    );
  }
}
