import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  getAppointment,
  loadAllAppointment,
} from "../../../../store/appointment";
import JourneyList from "./journeyList";
import { getPatient } from "../../../../store/patient";
class MyJourney extends Component {
  state = { page: 1, doctor_id: "" };
  componentDidMount = () => {
    this.props.loadAllAppointment({
      page: this.state.page,
    });
  };
  loadFunc = () => {
    if (
      Math.ceil(
        this.props.appointment.pagination.filtered_record /
          this.props.appointment.pagination.record_per_page
      ) > this.state.page
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.props.loadAllAppointment({
          page: this.state.page,
          doctor_id: this.state.doctor_id,
        })
      );
    }
  };

  loadData = () => {
    this.props.loadAllAppointment({
      page: this.state.page,
      doctor_id: this.state.doctor_id,
    });
  };
  render() {
    const { appointment, loading, patientProfile } = this.props;
    const { data, pagination } = appointment;

    console.log(pagination);
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="myjourney"
      //   role="tabpanel"
      //   aria-labelledby="myjourney-tab"
      // >
      //   <div class="card-header">My Journey</div>
      //   <div class="content-body show">
      <>
        <div className="cb-outer">
          <div className="filter-content-box d-md-flex flex-wrap align-items-center">
            <div className="filter-text-box">
              <h6>Filters:</h6>
            </div>
            <div className="filter-check-box">
              <ul className="filter-check-list">
                <li className="filter-check-item">
                  <div className="form-group preferred-field">
                    <div className="custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0">
                      {patientProfile.Psychiatrist && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id={patientProfile.Psychiatrist}
                            name="Psychiatrist"
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: patientProfile.Psychiatrist,
                                  page: 1,
                                },
                                () => this.loadData()
                              );
                            }}
                            checked={
                              this.state.doctor_id ===
                              patientProfile.Psychiatrist
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={patientProfile.Psychiatrist}
                          >
                            By Psychiatrist
                          </label>
                        </div>
                      )}
                      {patientProfile.Psychologist && (
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id={patientProfile.Psychologist}
                            name="Psychologist"
                            onClick={() => {
                              this.setState(
                                {
                                  page: 1,
                                  doctor_id: patientProfile.Psychologist,
                                },
                                () => this.loadData()
                              );
                            }}
                            checked={
                              this.state.doctor_id ===
                              patientProfile.Psychologist
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={patientProfile.Psychologist}
                          >
                            By Psychologist
                          </label>
                        </div>
                      )}
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="0"
                          name="other"
                          onClick={() => {
                            this.setState(
                              {
                                page: 1,
                                doctor_id: "",
                              },
                              () => this.loadData()
                            );
                          }}
                          checked={this.state.doctor_id === ""}
                        />
                        <label className="custom-control-label" htmlFor="0">
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cb-outer">
          <ul class="new-profile-list">
            <JourneyList
              states={this.state}
              appointment={data}
              pagination={pagination}
              loadFunc={this.loadFunc}
              loading={loading}
            />
          </ul>
        </div>
      </>
      //   </div>
      // </div>
    );
  }
}
const mapStateToProps = (state) => ({
  appointment: getAppointment(state).appointment,
  loading: getAppointment(state).loading,
  patientProfile: getPatient(state).profile,
});
const mapDispatchToProps = (dispatch) => ({
  loadAllAppointment: (params) => dispatch(loadAllAppointment(params)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyJourney)
);
