import React, { Component } from "react";
import { Link } from "react-router-dom";
import image1 from "../../include/images/clinician-counsellor-hero-img.png";
import icon2 from "../../include/images/prowess-icon2.svg";
import icon5 from "../../include/images/prowess-icon5.svg";
import icon3 from "../../include/images/prowess-icon3.svg";
import icon4 from "../../include/images/prowess-icon4.svg";
import Expert from "./common/expert";
import Blog from "./common/blog";
import Thoughts from "./common/thoughts";
import {
  getMisc,
  loadConstant,
  loadBlog,
  customerQuery,
  loadFaq,
} from "../../store/misc";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "./footer";
import icon1 from "../../include/images/prowess-icon1.svg";
import icon6 from "../../include/images/prowess-icon6.svg";
class Counsellor extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    toggle1: false,
    id: "",
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
    this.props.loadBlog({ page_title: "COUNSELLOR", published: true });
    this.props.loadFaq({ category: "Counsellor" });
  };

  toggle1 = (id) => {
    this.setState({
      id: id,
      toggle1: !this.state.toggle1,
    });
  };

  render() {
    const { constants, blog, faq } = this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const home = blog.filter((i) => i.published === true).slice(0, 3);

    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div className="outer-container">
          <div className="clinician-hero-container">
            <div className="container container1">
              <div className="row flex-row-reverse align-items-end">
                <div className="col-xl-4 offset-xl-1 col-lg-5">
                  <div className="clinician-hero-content">
                    <h3 className="d-block d-md-none">COUNSELLOR</h3>
                    <h4>
                      Our counsellors are a shoulder to support you in the
                      toughest times. Connect with them for a conversation at
                      anytime.
                    </h4>
                    <Link
                      to="patient/chat"
                      className="btn btn-white"
                      target="_blank"
                    >
                      Chat with us
                    </Link>
                    <a href="#" className="btn btn-link text-white border-0">
                      It's Free
                    </a>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7">
                  <div className="clinician-hero-img">
                    <img src={image1} alt="" />
                    <div className="clinician-dot dot1">
                      <span>PSYCHOSOCIAL</span>
                    </div>
                    <div className="clinician-dot dot2">
                      <span>PSYCHOLOGIST</span>
                    </div>
                    <div className="clinician-dot dot3">
                      <span>PSYCHIATRIST</span>
                    </div>
                    <div className="clinician-dot dot4 active">
                      <span>COUNSELLOR</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Expert />
        <div className="content-container prowess-container">
          <div className="container container1">
            <div className="row d-flex align-items-center">
              <div className="col-lg-4">
                <div className="prowess-text-box text-center text-lg-left">
                  <h3>
                    Feel <br /> Better <span>Virtually</span>
                  </h3>
                  <p>
                    Counsellors are trained as your general support system. We
                    are easy to approach, respond quickly, and provide free help
                    for immediate support. We assist your journey as the first
                    point of contact to prevent negativity. We have diverse
                    backgrounds, but our common goal is to guide people in need.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="prowess-img-box">
                  <ul className="prowess-list d-flex flex-wrap align-items-center justify-content-between">
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon1} alt="" />
                      </div>
                      <h6>
                        Complimentary and quick chat <br />
                        to get queries answered.
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon6} alt="" />
                      </div>
                      <h6>
                        Connecting like it’s a one-to-one <br />
                        private conversation.
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon4} alt="" />
                      </div>
                      <h6>Learn right to decide right.</h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon2} alt="" />
                      </div>
                      <h6>Caregiver’s companion</h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon3} alt="" />
                      </div>
                      <h6>
                        Connecting the dots for you <br />
                        with other experts.
                      </h6>
                    </li>
                    <li className="prowess-item d-flex align-items-center justify-content-center">
                      <div className="prowess-icon">
                        <img src={icon5} alt="" />
                      </div>
                      <h6>
                        Unbiased opinions and <br />
                        immediate guidance
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="container container1">
            <div className="row">
              <div className="col-xl-12">
                <div className="heading text-center">
                  <h4>
                    Knowledge <span>Is Power</span>
                  </h4>
                </div>
                <div className="accordion pc-outer" id="accordionExample">
                  {faq.map((e, i) => (
                    <div
                      className={
                        this.state.id === e._id && this.state.toggle1 === true
                          ? "card active"
                          : "card"
                      }
                    >
                      <div
                        className={
                          this.state.id === e._id && this.state.toggle1 === true
                            ? "card-header"
                            : "card-header collapsed"
                        }
                        onClick={() => {
                          this.toggle1(e._id);
                        }}
                        id="heading1"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="false"
                        aria-controls="collapse1"
                      >
                        {e.question}
                      </div>
                      <div
                        id="collapse1"
                        className={
                          this.state.id === e._id && this.state.toggle1 === true
                            ? "collapse show"
                            : "collapse"
                        }
                        aria-labelledby="heading1"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>{e.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="accordion-link-box">
                    <Link to={"/faq"}>
                      Learn More<i className="fas fa-caret-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Blog blog={home} />
        <Thoughts />

        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlog: (params) => dispatch(loadBlog(params)),
  loadFaq: (params) => dispatch(loadFaq(params)),
  customerQuery: (data, callback) => dispatch(customerQuery(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Counsellor);
