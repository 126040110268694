import Joi from "joi-browser";
import React, { Component } from "react";
import Form from "../../common/form/form";
import {
  emailInvalid,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  mobileRequired,
  mobileInvalid,
  partnerEmailInvalid,
  partnerMobileInvalid,
  fnameMax,
  lnameMax,
} from "../../common/misc";
import loaderIcon from "../../../include/images/loading-icon.svg";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import { patientSignUp, updatePatientProfile } from "../../../store/patient";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertSuccess from "../../common/alertSuccess";
import SubmitBtn from "../../common/form/submitBtn";

class PartnerDetails extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: {
      partner_mobile: "",
      email_id: "",
      first_name: "",
      last_name: "",
    },
    errors: {
      partner_mobile: "",
      email: "",
      first_name: "",
      last_name: "",
    },
  };

  schema = {
    partner_mobile: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        console.log(errors);
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),
    email_id: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          console.log("eeee", err);
          switch (err.type) {
            case "any.empty":
              err.message = emailRequired;
              break;

            case "string.email":
              err.message = emailInvalid;
              break;

            case "string.max":
              err.message = emailInvalid;
              break;
          }
        });
        return errors;
      }),
    first_name: Joi.string()
      .required()
      .max(50)
      .label("First Name")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = firstNameRequired;
              break;

            case "string.max":
              err.message = fnameMax;
              break;
          }
        });
        return errors;
      }),

    last_name: Joi.string()
      .required()
      .max(50)
      .label("Last Name")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = lastNameRequired;
              break;

            case "string.max":
              err.message = lnameMax;
              break;
          }
        });
        return errors;
      }),
  };
  dateFormat = (date) => {
    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    return mm + "/" + dd + "/" + yyyy;
  };

  doSubmit = () => {
    //console.log(this.props.allState, this.state.data);
    //return;
    if (!this.state.loading) {
      this.props.updatePartnerDetails(this.state.data);
      const {
        first_name: partner_first_name,
        last_name: partner_last_name,
        email_id: partner_email,
        partner_mobile: partner_mobile,
      } = this.state.data;
      const {
        verifyMobile,
        personalDetails,
        passwordInfo,
      } = this.props.allState;
      const { mobile, otp, DOB, type } = verifyMobile;
      const { answer, password, security_question } = passwordInfo;
      const { email_id, first_name, last_name } = personalDetails;

      if (mobile === partner_mobile)
        return toast(<AlertError message={partnerMobileInvalid} />);
      if (email_id === partner_email)
        return toast(<AlertError message={partnerEmailInvalid} />);

      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });

      const payLoad = {
        email: email_id,
        password,
        first_name,
        last_name,
        date_of_birth: DOB.toString(),
        type,
        security_question,
        security_answer: answer,
        partner: true,
        partner_first_name,
        partner_last_name,
        partner_email,
        partner_mobile,
      };

      this.props.updatePatientProfile(payLoad, this.callback);
    }
  };

  callback = (res) => {
    if (res.status === 200) {
      localStorage.setItem("patient", JSON.stringify(res.data));
      this.props.updateStep(null, 8);
      //toast(<AlertSuccess message={res.data.error} />);
    } else {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block",
        loading: false,
      });
      toast(<AlertError message={res.data.error} />);
    }
  };
  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <a href="" onClick={(e) => this.props.updateStep(e, 5)}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  ></path>
                </svg>
              </a>
              <h2 className="mb-3">Your Companion</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group custom-input">
                {this.renderInput("partner_mobile", "Mobile Number")}
                {this.renderInput("email_id", "Email ID")}
                {this.renderInput("first_name", "First Name")}
                {this.renderInput("last_name", "Last Name")}

                <div className="form-group">
                  <SubmitBtn
                    label="Next"
                    loading={this.state.loading}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  patientSignUp: (param, callback) => dispatch(patientSignUp(param, callback)),
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(PartnerDetails));
