export const presenceStyle = (props) => {
  let presenceStatus = {
    backgroundColor: "#A9A9A9",
  };

  if (props.status === "online" || props.status === "available") {
    presenceStatus = {
      backgroundColor: "#009A02",
    };
  }

  return {
    width: "9px",
    height: "9px",
    top: "-12px",
    float: "right",
    position: "relative",
    borderRadius: "50%",
    ...presenceStatus,
  };
};
