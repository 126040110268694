import React, { Component } from 'react'
import home from './../../../../include/images/home-icon.svg'
import docLeft from './../../../../include/images/dock-left.svg'
import document from './../../../../include/images/document-add.svg'
import material from './../../../../include/images/material-list.svg'
import diary from './../../../../include/images/my-diary.svg'
import knowledge from './../../../../include/images/my-knowledge.svg'
import journey from './../../../../include/images/my-journey.svg'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { getPatient } from '../../../../store/patient'
import { connect } from 'react-redux'
import { loadSeo } from '../../../../store/seo'
import ReactTooltip from 'react-tooltip'
import pulse from './../../../../include/images/pulse.svg'

class DashboardLeftBar extends Component {
  state = {
    timeline: false,
  }
  changeBodyClass = () => {
    console.log('document', document)
    // document.body.className = "n";
  }

  componentDidMount = () => {
    this.props.loadSeo('patient-dashboard')
  }

  toggle = () => {
    this.setState({ timeline: !this.state.timeline })
  }

  render() {
    console.log('patientData', this.props.patientData)
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div class="dashboard-left-bar">
          <ul class="dashboard-menu-list">
            <li
              className={
                this.props.match.path === '/patient/dashboard/:type?'
                  ? 'dm-item active'
                  : 'dm-item'
              }
            >
              <Link
                to="/patient/dashboard"
                onClick={() => (window.document.body.className = 'dashboard')}
              >
                <img src={home} alt="" />
                <h4>Home</h4>
                {/* <i class="fas fa-info-circle ml-2" aria-hidden="true"></i> */}
              </Link>
            </li>

            {this.props.patientData.profile.profile_completed &&
              !this.props.patientData.initial &&
              (this.props.patientProfile.Psychiatrist ||
                this.props.patientProfile.Psychologist) && (
                <>
                  <li
                    className={
                      this.props.match.path === '/patient/post-appointment' ||
                      this.props.match.path === '/patient/dashboard-timeline'
                        ? 'dm-item hasmenu active'
                        : 'dm-item hasmenu'
                    }
                  >
                    <Link
                      data-tip="Summary of your all your consultations"
                      onClick={() =>
                        (window.document.body.className = 'dashboard')
                      }
                      to="/patient/dashboard-timeline"
                    >
                      <img src={docLeft} alt="" />
                      <h4>My Timeline</h4>
                      {/* <i
                        data-tip="Summary of your all your consultations"
                        class="fas fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i> */}
                    </Link>
                    <ul
                      class="new-submenu"
                      style={{
                        display:
                          this.props.match.path ===
                            '/patient/post-appointment' ||
                          this.props.match.path ===
                            '/patient/dashboard-timeline'
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <li>
                        <Link
                          onClick={(e) => {
                            this.props.updateStep(null, 1)
                            window.document.body.className = 'dashboard'
                          }}
                          to={'/patient/dashboard-timeline'}
                        >
                          <img src={journey} alt="" /> My Journey
                        </Link>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => {
                            window.document.body.className = 'dashboard'
                            this.props.updateStep(null, 2)
                          }}
                        >
                          <img src={document} alt="" />
                          My Report
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => {
                            window.document.body.className = 'dashboard'
                            this.props.updateStep &&
                              this.props.updateStep(null, 3)
                          }}
                        >
                          <img src={material} alt="" />
                          Tasks
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      this.props.match.path ===
                      '/patient/dashboard-treatment-plan'
                        ? 'dm-item active'
                        : 'dm-item'
                    }
                  >
                    <Link
                      data-tip="You can track your progress, have summary, and learn about the future course of treatment. "
                      to="/patient/dashboard-treatment-plan"
                    >
                      <img src={pulse} alt="" />
                      <h4>Treatment Plan / Tracker</h4>
                      {/* <i class="fas fa-info-circle ml-2" aria-hidden="true"></i> */}
                    </Link>
                  </li>
                  <li
                    className={
                      this.props.match.path === '/patient/dashboard-diary'
                        ? 'dm-item active'
                        : 'dm-item'
                    }
                  >
                    <Link
                      data-tip="Express yourself well by writing and recording your thoughts to share with your experts."
                      to="/patient/dashboard-diary"
                      onClick={() =>
                        (window.document.body.className = 'dashboard')
                      }
                    >
                      <img src={diary} alt="" />
                      <h4>My Diary</h4>
                      {/* <i
                        data-tip="Express yourself well by writing and recording your thoughts to share with your experts."
                        class="fas fa-info-circle ml-2"
                        aria-hidden="true"
                      ></i> */}
                    </Link>
                  </li>
                  <li
                    className={
                      this.props.match.path ===
                      '/patient/dashboard/for-my-knowledge'
                        ? 'dm-item active'
                        : 'dm-item'
                    }
                  >
                    <Link
                      data-tip="You can access educational material shared by your expert from here."
                      to="/patient/dashboard/for-my-knowledge"
                      onClick={() =>
                        (window.document.body.className = 'dashboard')
                      }
                    >
                      <img src={knowledge} alt="" />
                      <h4>For My Knowledge</h4>
                      {/* <i
                        class="fas fa-info-circle ml-2"
                        aria-hidden="true"
                        data-tip="You can access educational material shared by your expert from here."
                      ></i> */}
                    </Link>
                  </li>
                  {/* <li className="dm-item">
                  <a href="#">
                    <img src={lab} alt="" />
                    <h4>My Chemist/Lab</h4>
                  </a>
                </li> */}
                  {/* <li className="dm-item">
                  <a href="#">
                    <img src={testimonial} alt="" />
                    <h4>Write Testimonials</h4>
                  </a>
                </li> */}

                  <li
                    className={
                      this.props.match.path === '/patient/desk'
                        ? 'dm-item active'
                        : 'dm-item'
                    }
                  >
                    <Link
                      data-tip="For any query or help."
                      to="/patient/desk"
                      onClick={() =>
                        (window.document.body.className = 'dashboard')
                      }
                    >
                      <img src={diary} alt="" />
                      <h4>Mindy’s Desk</h4>
                      {/* <i
                        class="fas fa-info-circle ml-2"
                        aria-hidden="true"
                        data-tip="For any query or help."
                      ></i> */}
                    </Link>
                  </li>
                </>
              )}
          </ul>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  patientData: getPatient(state),
})

const mapDispatchToProps = (dispatch) => ({
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardLeftBar),
)
