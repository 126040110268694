import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "staff",
  initialState: {
    staffs: [],
    loading: false,
    lastFetch: null,
  },
  reducers: {
    staffRequested: (staff, action) => {
      staff.loading = true;
    },

    staffReceived: (staff, action) => {
      staff.staffs = action.payload.data;
      staff.loading = false;
      staff.lastFetch = Date.now();
    },

    staffRequestFailed: (staff, action) => {
      staff.loading = false;
    },
  },
});

export const { staffRequested, staffReceived, staffRequestFailed } =
  slice.actions;
export default slice.reducer;

// Action Creators
const url = "staff/";

export const addStaff = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "post",
      url,
      data,
      callback,
    })
  );
};

export const deleteStaff = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "delete",
      url: url + id,
      callback,
    })
  );
};

export const updateStaff = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      method: "put",
      url: url + id,
      data,
      callback,
    })
  );
};

export const loadStaff = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: staffRequested.type,
      onSuccess: staffReceived.type,
      onError: staffRequestFailed.type,
    })
  );
};

export const staffSignIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "signIn",
      method: "POST",
      data,
      callback,
    })
  );
};

// export const loadSubmittedTask = (params) => (dispatch, getState) => {
//   return dispatch(
//     apiCallBegan({
//       url: url3,
//       params,
//       onStart: taskSubmittedRequested.type,
//       onSuccess: taskSubmittedReceived.type,
//       onError: taskSubmittedRequestFailed.type,
//     })
//   );
// };
// export const loadAllAssessment = (params) => (dispatch, getState) => {
//   return dispatch(
//     apiCallBegan({
//       url,
//       params,
//       onStart: assessmentRequested.type,
//       onSuccess: assessmentReceived.type,
//       onError: assessmentRequestFailed.type,
//     })
//   );
// };
// export const loadDetailAssessment = (id) => (dispatch, getState) => {
//   return dispatch(
//     apiCallBegan({
//       url: url + id,
//       onStart: assessmentDetailRequested.type,
//       onSuccess: assessmentDetailReceived.type,
//       onError: assessmentDetailRequestFailed.type,
//     })
//   );
// };
// export const assignAssessment = (param, callback) => (dispatch) => {
//   return dispatch(
//     apiCallBegan({
//       callback,
//       url: url + "/task/assign",
//       method: "post",
//       data: param,
//       onSuccess: assignTask.type,
//       onError: assignTaskRequestFailed.type,
//     })
//   );
// };
// export const loadAnswerAssessment = (params) => (dispatch, getState) => {
//   return dispatch(
//     apiCallBegan({
//       url: url1,
//       params,
//       onStart: assessmentAnswerRequested.type,
//       onSuccess: assessmentAnswerReceived.type,
//       onError: assessmentAnswerRequestFailed.type,
//     })
//   );
// };
// export const addAnswerAssessment = (param, callback) => (dispatch) => {
//   return dispatch(
//     apiCallBegan({
//       callback,
//       url: url1,
//       method: "post",
//       data: param,
//       onSuccess: assessmentAnswerAdded.type,
//       onError: assessmentAnswerAddRequestFailed.type,
//     })
//   );
// };

export const getStaff = createSelector(
  (state) => state.entities.staff,
  (staff) => staff
);
