import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDoctorPatient,
  loadAllDoctorpatient,
  updateStar,
} from "../../../store/doctorPatient";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import Moment from "moment";
import _, { debounce } from "lodash";

class PatientHeader extends Form {
  state = {
    patient_type: "",
    filter: false,
    new_patient: 0,
    individual: 0,
    star: false,
    guardian: 0,
    followup: 0,
    page: 1,
    search: "",
  };

  onNew = () => {
    if (this.state.new_patient === 0) {
      this.setState({ new_patient: 1 });
    } else {
      this.setState({ new_patient: 0 });
    }
  };
  onFollow = () => {
    if (this.state.followup === 0) {
      this.setState({ followup: 1 });
    } else {
      this.setState({ followup: 0 });
    }
  };
  onIndividual = () => {
    if (this.state.individual === 0) {
      this.setState({ individual: 1 });
    } else {
      this.setState({ individual: 0 });
    }
  };
  onGuardian = () => {
    if (this.state.guardian === 0) {
      this.setState({ guardian: 1 });
    } else {
      this.setState({ guardian: 0 });
    }
  };

  componentDidMount = () => {
    const filter = {
      page: this.state.page,
      patient_type: this.state.patient_type,
      search: this.state.search,
    };
    if (this.props.step == 2) filter.star = "true";
    if (this.props.step == 3) filter.star = "false";

    this.props.loadAllDoctorpatient(filter);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.step !== prevProps.step ||
      this.state.patient_type != prevState.patient_type
    ) {
      const filter = {
        page: this.state.page,
        patient_type: this.state.patient_type,
        search: this.state.search,
      };
      if (this.props.step == 2) filter.star = "true";
      if (this.props.step == 3) filter.star = "false";

      this.props.loadAllDoctorpatient(filter);
    }
  };

  fetchData = debounce((val) => {
    const filter = {
      page: this.state.page,
      patient_type: this.state.patient_type,
      search: val,
    };
    if (this.props.step == 2) filter.star = "true";
    if (this.props.step == 3) filter.star = "false";

    this.props.loadAllDoctorpatient(filter);
  }, 500);

  toggleFilter = () => {
    this.setState({ filter: !this.state.filter });
  };
  onApply = () => {
    this.setState({ filter: false });
  };
  onSearch = () => {
    const filter = {
      page: this.state.page,
      patient_type: this.state.patient_type,
      search: this.state.search,
    };
    if (this.props.step == 2) filter.star = "true";
    if (this.props.step == 3) filter.star = "false";

    this.props.loadAllDoctorpatient(filter);
  };
  clear = () => {
    this.setState({
      search: "",
      patient_type: "",
      filter: false,
    });
  };
  render() {
    return (
      <div className="dsp-head d-flex aligsn-items-md-center justify-content-md-between">
        <h4>
          My Patients{" "}
          <strong>
            (
            {this.props.doctorPatient.data &&
              this.props.doctorPatient.pagination.total_record}
            )
          </strong>
        </h4>
        <div className="dsp-right-content d-flex">
          <div className="new-search-box" onClick={() => this.onSearch()}>
            <input
              type="text"
              className="new-search-input"
              name=""
              value={this.state.search}
              placeholder="Search"
              onChange={(e) => {
                this.setState({ search: e.target.value });
                this.fetchData(e.target.value);
              }}
            />
          </div>
          <div className="new-filter-box-outer d-flex align-items-center justify-content-center">
            <div className="new-filter-box">
              <div
                className={
                  this.state.filter === true
                    ? "btn-group bottom-align filter-dropdown show"
                    : "btn-group bottom-align filter-dropdown"
                }
              >
                <button
                  type="button"
                  className="dropdown-toggle"
                  onClick={this.toggleFilter}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-filter"></i>
                </button>
                <div
                  className={
                    this.state.filter === true
                      ? "dropdown-menu dropdown-menu-right show"
                      : "dropdown-menu dropdown-menu-right"
                  }
                  x-placement="top-end"
                >
                  <div className="new-filter-content">
                    <div className="new-filter-heading d-flex justify-content-between ">
                      <h6>Filters</h6>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          this.clear();
                        }}
                        class="clear-text"
                      >
                        Clear All
                      </a>
                    </div>
                    <div className="new-checkbox-content">
                      <div class="new-seprator"></div>
                      <div class="signup-agree-box">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="4"
                            id="4"
                            onChange={() => {
                              if (this.state.patient_type === 1) {
                                this.setState({ patient_type: "" });
                              } else {
                                this.setState({ patient_type: 1 });
                              }
                            }}
                            checked={this.state.patient_type === 1}
                          />
                          <label class="form-check-label" for="4">
                            Individual
                          </label>
                        </div>
                      </div>

                      <div class="signup-agree-box">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="6"
                            id="6"
                            onChange={() => {
                              if (this.state.patient_type === 2) {
                                this.setState({ patient_type: "" });
                              } else {
                                this.setState({ patient_type: 2 });
                              }
                            }}
                            checked={this.state.patient_type === 2}
                          />
                          <label class="form-check-label" htmlFor="6">
                            Guardian
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div class="apply-btn d-flex align-items-center justify-content-center no-shadow">
                    <button
                      type="button"
                      onClick={() => this.onApply()}
                      class=" btn btn-primary no-shadow"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to={"/doctor/addPatient"} class="btn btn-default no-shadow">
            + New Patient
          </Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadAllDoctorpatient: (params) => dispatch(loadAllDoctorpatient(params)),
});
const mapStateToProps = (state) => ({
  doctorPatient: getDoctorPatient(state).doctorPatient,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientHeader)
);
