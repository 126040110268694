import React, { Component } from "react";
import logo from "../../include/images/yes-mindy-color-logo-small.png";
import helpIcon from "../../include/images/help-icon.svg";
import emailIcon from "../../include/images/email-icon.svg";
import thumbIcon from "../../include/images/thumb-icon.svg";
import copyRightIcon from "../../include/images/copyright.svg";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};

  render() {
    const { footer_email_address, facebook, instagram, twitter } = this.props;
    return (
      <footer>
        <div className="footer-upper">
          <div className="container container1">
            <div className="row">
              <div className="col-lg-2">
                <div className="footer-logo-area">
                  <Link to="/" className="footer-logo">
                    <img src={logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="footer-upper-content">
                  <ul className="footer-nav d-md-flex align-items-center">
                    <li>
                      <Link to={"/about-us"} className="footer-nav-link">
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to={"/blog"} className="footer-nav-link">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to={"/faq"} className="footer-nav-link">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                  <div className="fuc-box-outer d-md-flex flex-wrap">
                    <div className="fuc-box">
                      <div className="fuc-icon">
                        <img src={helpIcon} alt="" />
                      </div>
                      <h4>NEED HELP?</h4>
                      <Link to={"/faq"}>
                        <p>View our knowledge centre</p>
                      </Link>
                    </div>
                    <div className="fuc-box">
                      <div className="fuc-icon">
                        <img src={emailIcon} alt="" />
                      </div>
                      <h4>24x7 SUPPORT</h4>
                      <p>
                        <a
                          style={{ overflowWrap: "anywhere" }}
                          href={"mailto:" + footer_email_address}
                        >
                          {footer_email_address}
                        </a>
                      </p>
                    </div>
                    <div className="fuc-box">
                      <div className="fuc-icon">
                        <img src={thumbIcon} alt="" />
                      </div>
                      <h4>CONNECT WITH US</h4>
                      <ul className="social-link d-flex flex-wrap">
                        <li>
                          <a href={facebook} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                            >
                              <path
                                id="Icon_awesome-facebook-square"
                                data-name="Icon awesome-facebook-square"
                                d="M31.25,2.25H3.75A3.75,3.75,0,0,0,0,6V33.5a3.75,3.75,0,0,0,3.75,3.75H14.473v-11.9H9.551v-5.6h4.922V15.481c0-4.855,2.891-7.537,7.318-7.537a29.818,29.818,0,0,1,4.338.378v4.766H23.685c-2.407,0-3.158,1.494-3.158,3.026V19.75H25.9l-.859,5.6H20.527v11.9H31.25A3.75,3.75,0,0,0,35,33.5V6A3.75,3.75,0,0,0,31.25,2.25Z"
                                transform="translate(0 -2.25)"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href={instagram} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                            >
                              <path
                                id="Icon_zocial-instagram"
                                data-name="Icon zocial-instagram"
                                d="M3.151,35.011a4.161,4.161,0,0,1-1.915-1.087A3.851,3.851,0,0,1,.176,32.1c-.123-.486-.13-.944-.13-14.451C.04,2.472.019,3.306.416,2.451A4.082,4.082,0,0,1,2.406.475c.882-.39.021-.369,15.312-.349L31.806.139l.438.15A4.148,4.148,0,0,1,34.87,2.916l.15.438.014,14.088c.021,15.647.055,14.478-.445,15.49a4.063,4.063,0,0,1-1.73,1.73c-1,.492.137.458-15.346.451C5.675,35.107,3.5,35.093,3.151,35.011Zm27.26-4.165c.561-.4.574-.438.595-3.043.021-2.537.007-2.667-.41-3.071-.376-.369-.554-.39-3.03-.383l-2.257.007L25,24.52A1.682,1.682,0,0,0,24.5,25l-.205.3-.007,2.264c-.007,1.238.021,2.366.055,2.5a1.277,1.277,0,0,0,.7.862l.342.171,2.373-.021,2.366-.021Zm-11.5-6.641a6.788,6.788,0,0,0,5.239-5.266,8.354,8.354,0,0,0,.075-2.223,6.888,6.888,0,0,0-3.508-5.033,7.339,7.339,0,0,0-1.805-.67c-.13-.014-.4-.055-.609-.089a6.756,6.756,0,0,0-6.1,2.612,6.691,6.691,0,0,0,.13,8.309A6.819,6.819,0,0,0,16.843,24.3,7.137,7.137,0,0,0,18.908,24.206ZM7,19.767a10.853,10.853,0,0,1,.9-6.941,10.06,10.06,0,0,1,2.045-2.811,10.666,10.666,0,0,1,9.308-3.036,10.834,10.834,0,0,1,8.713,7.933,10.621,10.621,0,0,1,.28,3.83,7.53,7.53,0,0,1-.28,1.477c-.021.034-.014.062.021.068.26.027,2.92.021,2.968-.014s.055-3.057.048-7.632l-.021-7.6-.205-.294a1.709,1.709,0,0,0-.513-.451L29.96,4.14l-12.488.021L4.991,4.174l-.28.185a1.8,1.8,0,0,0-.438.445l-.164.26L4.088,12.62c-.007,4.158-.007,7.584.014,7.625a7.359,7.359,0,0,0,1.525.068h1.5Z"
                                transform="translate(-0.044 -0.117)"
                              />
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a href={twitter} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                            >
                              <path
                                id="Icon_awesome-twitter-square"
                                data-name="Icon awesome-twitter-square"
                                d="M31.25,2.25H3.75A3.751,3.751,0,0,0,0,6V33.5a3.751,3.751,0,0,0,3.75,3.75h27.5A3.751,3.751,0,0,0,35,33.5V6A3.751,3.751,0,0,0,31.25,2.25ZM27.43,14.656c.016.219.016.445.016.664A14.479,14.479,0,0,1,12.867,29.9,14.511,14.511,0,0,1,5,27.6a10.8,10.8,0,0,0,1.234.062,10.273,10.273,0,0,0,6.359-2.187A5.131,5.131,0,0,1,7.8,21.922a5.522,5.522,0,0,0,2.312-.094,5.124,5.124,0,0,1-4.1-5.031v-.062a5.121,5.121,0,0,0,2.313.648,5.113,5.113,0,0,1-2.281-4.266,5.06,5.06,0,0,1,.7-2.586A14.549,14.549,0,0,0,17.3,15.891a5.135,5.135,0,0,1,8.742-4.68A10.04,10.04,0,0,0,29.3,9.977a5.111,5.111,0,0,1-2.25,2.82A10.2,10.2,0,0,0,30,12,10.785,10.785,0,0,1,27.43,14.656Z"
                                transform="translate(0 -2.25)"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-lower">
          <div className="container container1">
            <div className="row">
              <div className="col-md-12 d-lg-flex align-items-lg-center justify-content-lg-center">
                <p className="copyright">
                  <img src={copyRightIcon} alt="" /> 2022 YesMindy. All Rights
                  Reserved.
                </p>
                <ul class="fl-nav d-md-flex align-items-md-center">
                  <li>
                    <Link className="ml-2 mr-2" to="/website-disclaimer">
                      Website Disclaimer
                    </Link>
                  </li>
                  <li>
                    <Link className="ml-2 mr-2" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="ml-2 mr-2" to="/payment-return-policy">
                      Payment and Return Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="ml-2 mr-2" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
