import React, { Component } from "react";
import AsyncSelect from "react-select";

class SelectSearchAsync extends Component {
  state = {
    objValue: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== "") {
      this.setState({
        objValue: {
          name: this.props.value,
          value: this.props.value,
        },
      });
    }
  }
  customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      height: 55,
      minHeight: 55,
      borderColor: state.isFocused ? "#6119c0" : "#e0e0e0",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  handleInputChange = (newValue) => {
    console.log("==>", newValue);
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    this.props.loadOptions(inputValue);
    return inputValue;
  };

  render() {
    const { name, options, label, error, value, defaultValue, ...rest } =
      this.props;

    return (
      <div className="form-group custom-input">
        <AsyncSelect
          {...rest}
          onInputChange={this.handleInputChange}
          onChange={(a) =>
            this.props.onChange({
              currentTarget: { value: a.value, name: a.name },
            })
          }
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.value}
          defaultOptions
          placeholder={label}
          styles={this.customStyles}
        />
        {error && <label className="error">{error}</label>}
      </div>
    );
  }
}

export default SelectSearchAsync;
