import React, { Component } from 'react'
import DateRangePicker from 'rsuite/DateRangePicker'
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'

import Header from './dashboard/header'
import LeftBar from './dashboard/leftBar'
import wicon from '../../include/images/Icons/Bag.svg'
import usericon from '../../include/images/Icons/User.svg'
import hoursIcon from '../../include/images/Icons/Hours.svg'
import { toast } from 'react-toastify'
import AlertError from '../common/alertError'
import { getMisc, loadSummary } from '../../store/misc'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AlertSuccess from '../common/alertSuccess'
import moment from 'moment'
import _ from 'lodash'
import NoRecord from '../common/noRecord'

class Summary extends Component {
  state = {
    filter: 'week',
    dateValue: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    year: {
      start_date: moment().subtract(365, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    month: {
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    week: {
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    day: {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },

    errors: {},
    sidebarToogle: false,
  }
  componentDidMount() {
    document.body.className = 'dashboard'
    this.props.loadSummary(this.state.week)
  }
  onChange = e => {
    console.log(e.target)
    const { name, value } = e.target
    const { data } = this.state
    data[name] = value
    this.setState({ data })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { data } = this.state
    if (!data.topic || !data.description)
      return toast(<AlertError message={'Topic and Description required.'} />)
    this.props.doctorDesk(data, this.callback)
  }

  handleFilter = val => {
    this.props.loadSummary({
      start_date: moment(val[0]).format('YYYY-MM-DD'),
      end_date: moment(val[1]).format('YYYY-MM-DD'),
    })
  }

  callback = res => {
    this.setState({
      data: {
        topic: '',
        description: '',
      },
    })
    if (res.status === 200) {
      toast(<AlertSuccess message={'Your query has been sent to the Admin'} />)
    } else {
      toast(<AlertError message={'Something went wrong.'} />)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.filter !== prevState.filter) {
      console.log(
        'this.state[this.state.filter]',
        this.state[this.state.filter]
      )
      //this.props.loadSummary(this.state[this.state.filter])
    }
  }

  toggleSidebar = e => {
    if (e) e.preventDefault()
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  render() {
    const appointmentsData = this.props.getMisc.summary.filter(
      e => e.status !== 3
    )
    const refundData = appointmentsData.filter(e => e.refund_info)

    const refund = refundData.reduce((a, b) => a + b.refund_info.amount, 0)

    const earning = appointmentsData
      .filter(ele => ele.status == 1 || ele.status == 2)
      .reduce(
        (a, b) =>
          a + (b.consultation_charges ? Number(b.consultation_charges) : 0),
        0
      )
    const htSpend = appointmentsData.reduce(
      (a, b) => a + b.doctor_id.schedules.time,
      0
    )
    const un = _.uniq(_.map(this.props.getMisc.summary, 'patient_id._id'))
    return (
      <div class={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div class='dashboard-main-container'>
          <div class='my-summaries-box'>
            <div class='msb-head d-md-flex align-items-center justify-content-between'>
              <div class='msb-head-left'>
                <h4>My Summaries</h4>
              </div>
              <div class='msb-head-right'>
                <DateRangePicker
                  cleanable={false}
                  value={this.state.dateValue}
                  placement='auto'
                  onChange={value => {
                    console.log('onChange', value)
                    this.handleFilter(value)
                    this.setState({ dateValue: value })
                  }}
                  ranges={[
                    {
                      label: 'today',
                      value: [startOfDay(new Date()), endOfDay(new Date())],
                    },
                    {
                      label: 'yesterday',
                      value: [
                        startOfDay(addDays(new Date(), -1)),
                        endOfDay(addDays(new Date(), -1)),
                      ],
                    },
                    {
                      label: 'last7Days',
                      value: [
                        startOfDay(subDays(new Date(), 6)),
                        endOfDay(new Date()),
                      ],
                    },
                    {
                      label: 'Last 30 Days',
                      value: [
                        startOfDay(subDays(new Date(), 30)),
                        endOfDay(new Date()),
                      ],
                    },
                    {
                      label: 'Last One Year',
                      value: [
                        startOfDay(subDays(new Date(), 365)),
                        endOfDay(new Date()),
                      ],
                    },
                  ]}
                />
                {/* <div class='stv-radio-buttons-wrapper'>
                  <input
                    type='radio'
                    class='stv-radio-button'
                    name='radioButton1'
                    value='4'
                    id='button7'
                    checked={this.state.filter === 'year'}
                    onChange={() => this.setState({ filter: 'year' })}
                  />
                  <label for='button7'>Year</label>
                  <input
                    type='radio'
                    class='stv-radio-button'
                    name='radioButton1'
                    value='1'
                    id='button4'
                    checked={this.state.filter === 'month'}
                    onChange={() => this.setState({ filter: 'month' })}
                  />
                  <label for='button4'>Month</label>
                  <input
                    type='radio'
                    class='stv-radio-button'
                    name='radioButton1'
                    value='2'
                    id='button5'
                    checked={this.state.filter === 'week'}
                    onChange={() => this.setState({ filter: 'week' })}
                  />
                  <label for='button5'>Week</label>
                  <input
                    type='radio'
                    class='stv-radio-button'
                    name='radioButton1'
                    value='3'
                    id='button6'
                    checked={this.state.filter === 'day'}
                    onChange={() => this.setState({ filter: 'day' })}
                  />
                  <label for='button6'> Days</label>
                </div> */}
              </div>
            </div>
            <div class='msb-middle'>
              <ul class='earning-list d-flex flex-wrap'>
                <li class='earning-item'>
                  <div
                    class='
                  ei-content-box
                  d-flex
                  align-items-center
                  justify-content-between
                  green
                '>
                    <div class='ei-box d-flex align-items-center'>
                      <img width='60px' src={wicon} alt='' />

                      <div class='ei-desc'>
                        <h5>
                          &#8377;
                          {(earning - refund / 100)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </h5>
                        <p>Total Earnings</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class='earning-item'>
                  <div
                    class='
                  ei-content-box
                  blue
                  d-flex
                  align-items-center
                  justify-content-between
                '>
                    <div class='ei-box d-flex align-items-center'>
                      <img width='60px' src={usericon} alt='' />

                      <div class='ei-desc'>
                        <h5>
                          {un.length
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </h5>
                        <p>Total Patients Seen</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class='earning-item'>
                  <div
                    class='
                  ei-content-box
                  d-flex
                  align-items-center
                  justify-content-between
                  yellow
                '>
                    <div class='ei-box d-flex align-items-center'>
                      <img width='60px' src={hoursIcon} alt='' />
                      <div class='ei-desc'>
                        <h5>
                          {(htSpend / 60)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </h5>
                        <p>Hrs Spent on Consultancy</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class='msb-body'>
              <table class='table'>
                <thead>
                  <tr>
                    <th scope='col'>#</th>
                    <th scope='col'>Appointment Time</th>
                    <th scope='col'>Appointment Type</th>
                    {/* <th scope="col">Order Id</th> */}
                    <th scope='col'>Patient Name</th>
                    <th scope='col'>Booked on</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.getMisc.summary.length === 0 && (
                    <tr>
                      <td colSpan='7'>
                        <NoRecord
                          noLogo={true}
                          header='No Payment Record To Display'
                          text='We will be as quick as possible to transfer your settlements. Your payments will remain in safe hands till then.'
                        />
                      </td>
                    </tr>
                  )}
                  {this.props.getMisc.summary.map((e, i) => (
                    <tr>
                      <td scope='col'>{i + 1}</td>
                      <td scope='col'>
                        {moment(e.appointment_time).utc().format('lll')}
                      </td>
                      <td scope='col'>{e.appointment_type}</td>
                      {/* <td scope="col">{e.order_id}</td> */}
                      <td scope='col'>
                        {e.patient_id && (
                          <>
                            {e.patient_id.first_name
                              ? e.patient_id.first_name +
                                ' ' +
                                e.patient_id.last_name
                              : e.patient_id.mobile}
                          </>
                        )}
                      </td>
                      <td scope='col'>
                        {moment(e.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                      </td>
                      <td scope='col'>
                        {e.refund_info
                          ? 'Refunded by admin'
                          : e.status === 1
                          ? 'Booked'
                          : e.status === 2
                          ? 'Completed'
                          : e.status === 3
                          ? 'Cancelled'
                          : ''}
                      </td>
                      <td scope='col'>
                        {(
                          e.consultation_charges -
                          (e.refund_info ? e.refund_info.amount / 100 : 0)
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td scope='col'>
                        <a href={'/invoice/' + e._id} target='_blank'>
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  getMisc: getMisc(state),
})

const mapDispatchToProps = dispatch => ({
  loadSummary: param => dispatch(loadSummary(param)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary))
