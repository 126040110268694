import React, { useEffect } from 'react'
import RequestOtp from './components/patient/signUp/requestOtp'
import './include/css/bootstrap.min.css'
import './include/css/style.css'
import './include/css/dev.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AlertClose from './components/common/alertClose'
import configureStore from './store/configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SignIn from './components/patient/signIn/signIn'
import ForgetPassword from './components/patient/signIn/forgetPassword'
import PersonalDetail from './components/doctor/signup/personalDetail'
import Education from './components/doctor/signup/educationalDetail/education'
import Experience from './components/doctor/signup/educationalDetail/experience'
import Certification from './components/doctor/signup/educationalDetail/certification'
import Award from './components/doctor/signup/educationalDetail/award'
import Publication from './components/doctor/signup/educationalDetail/publication'
import Documentation from './components/doctor/signup/documentation'
import Clinic from './components/doctor/signup/clinic'
import Practice from './components/doctor/signup/practice'
import LinkSetup from './components/doctor/signup/linkSetup'
import RequestOtpDoctor from './components/doctor/signup/verify/requestOtpDoctor'
import Schedule from './components/doctor/signup/schedule'
import Contract from './components/doctor/signup/contract'
import Success from './components/doctor/signup/success'
import DoctorSignIn from './components/doctor/signIn/signIn'
import Bank from './components/doctor/signup/bank'
import Doctors from './components/patient/doctor/doctors'
import Home from './components/home/home'
import Appointment from './components/patient/doctor/appointment/appointment'
import AppointmentSuccess from './components/patient/doctor/appointment/appointmentSuccess'
import HelmetComponent from './components/common/helmetComponent'
import Chat from './components/home/chat'
import CounselorLogin from './components/home/counselorLogin'
import CounselorChat from './components/home/counselorChat'
import ForgetPasswordDoctor from './components/doctor/signIn/forgetPassword'
import HomePage from './components/home/homePage'
import Psychosocial from './components/home/clinicalPsychosocial'
import Psychologist from './components/home/clinicalPsychologist'
import Psychiatrist from './components/home/clinicalPsychiatrist'
import Counsellor from './components/home/clinicalCounsellor'
import CareGiver from './components/home/clinicalCaregiver'
import AboutUs from './components/pages/aboutUs'
import Services from './components/pages/services'
import OurExperts from './components/pages/ourExperts'
import FAQ from './components/pages/faq'
import ClinicalCommunity from './components/pages/clinicalCommunity'
import Blog from './components/pages/blog'
import BlogDetail from './components/pages/blogDetail'
import Associations from './components/doctor/signup/associations'
import Profile from './components/doctor/profile/profile'
import PersonalDetails from './components/patient/profile/personalDetails'
import PatientDashboard from './components/patient/profile/patientDashboard'
import PostAppointment from './components/patient/dashboard/postAppointment'
import MainDashboard from './components/patient/dashboard/mainDashboard'
import ProfileInformation from './components/patient/profile/profileInformation'
import messegeTest from './components/patient/dashboard/postAppointment/activity/messegeTest'
import Timeline from './components/patient/dashboard/timeline/timeline'
import ForMyKnowledge from './components/patient/dashboard/forMyKnowledge/forMyKnowledge'
import Diary from './components/patient/dashboard/timeline/diary'
import DoctorDashboard from './components/doctor/dashboard/dashboard'
import VideoCall from './components/doctor/dashboard/videoCall'
import VideoCallPatient from './components/patient/dashboard/videoCallPatient'
import Calender from './components/doctor/dashboard/calender'
import PatientTabs from './components/doctor/dashboard/patientTabs'
import AppointmentTabs from './components/doctor/dashboard/appointmentTabs'
import PatientDetailTab from './components/doctor/dashboard/patientDetailtab'
import CaseRecordTab from './components/doctor/dashboard/caseRecords/caseRecordTab'
import AddPatient from './components/doctor/dashboard/addPatient/addPatient'
import RecordVisit from './components/doctor/dashboard/recordVisit'
import Toolbox from './components/doctor/dashboard/toolboxTabs'
import NewAppintment from './components/doctor/dashboard/newAppintment'
import Staffs from './components/doctor/dashboard/staffs'
import AddStaff from './components/doctor/dashboard/addStaff'
import StaffSignIn from './components/doctor/signIn/staffSignIn'
import StaffDashboard from './components/doctor/dashboard/staffDashboard'
import RescheduleAppintment from './components/doctor/dashboard/rescheduleAppintment'
import DoctorDesk from './components/doctor/doscorDesk'
import PatientDesk from './components/patient/patientDesk'
import Summary from './components/doctor/summary'
import Notification from './components/doctor/dashboard/notification'
import PrescriptionDesign from './components/common/prescriptionDesign'
import PrescriptionDownload from './components/common/prescriptionDownload'
import PrivacyPolicy from './components/home/privacyPolicy'
import WebsiteDisclaimer from './components/home/websiteDisclaimer'
import PaymentReturn from './components/home/paymentReturn'
import ContactUs from './components/home/contactUs'
import Invoice from './components/common/invoice'
import TreatmentPlanTab from './components/patient/dashboard/timeline/treatmentPlanTab'
import ExternalSignin from './components/patient/signIn/externalSignin'
import AppointmentPay from './components/patient/dashboard/appointmentPayment'
import AppointmentSignUp from './components/patient/signIn/appointmentSignUp'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <HelmetComponent />
      <ToastContainer
        hideProgressBar
        autoClose={5000}
        toastClassName='alert alert-success alert-white'
        closeButton={<AlertClose />}
      />
      <Router>
        <Switch>
          <Route exact path='/chatTest' component={messegeTest} />
          <Route exact path='/' component={HomePage} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route
            exact
            path='/website-disclaimer'
            component={WebsiteDisclaimer}
          />
          <Route
            exact
            path='/patient/signin/external/:id'
            component={ExternalSignin}
          />
          <Route
            exact
            path='/payment-return-policy'
            component={PaymentReturn}
          />

          <Route exact path='/clinical-psychosocial' component={Psychosocial} />
          <Route exact path='/clinical-psychologist' component={Psychologist} />
          <Route exact path='/clinical-psychiatrist' component={Psychiatrist} />
          <Route exact path='/clinical-counsellor' component={Counsellor} />
          <Route exact path='/clinical-caregiver' component={CareGiver} />
          <Route exact path='/home' component={Home} />
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/our-experts' component={OurExperts} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/blog-detail/:url' component={BlogDetail} />
          <Route
            exact
            path='/clinical-community'
            component={ClinicalCommunity}
          />
          <Route exact path='/blog' component={Blog} />
          <Route
            exact
            path='/patient/personal-detail'
            component={PersonalDetails}
          />
          <Route
            exact
            path='/patient/dashboard/profile-information'
            component={ProfileInformation}
          />
          <Route
            exact
            path='/patient/dashboard-timeline'
            component={Timeline}
          />
          <Route
            exact
            path='/patient/dashboard/for-my-knowledge'
            component={ForMyKnowledge}
          />
          <Route exact path='/patient/:id/pay' component={AppointmentPay} />

          <Route exact path='/patient/dashboard-diary' component={Diary} />
          <Route
            exact
            path='/patient/dashboard-treatment-plan'
            component={TreatmentPlanTab}
          />
          <Route
            exact
            path='/patient/post-appointment'
            component={PostAppointment}
          />
          <Route
            exact
            path='/patient/dashboard-home'
            component={PatientDashboard}
          />
          <Route
            exact
            path='/patient/dashboard/:type?'
            component={MainDashboard}
          />
          <Route
            exact
            path='/patient/appointment-success'
            component={AppointmentSuccess}
          />
          <Route
            exact
            path='/patient/appointment/:profile?'
            component={Appointment}
          />
          <Route
            exact
            path='/patient/forget-password'
            component={ForgetPassword}
          />
          <Route exact path='/doctor/dashboard' component={DoctorDashboard} />
          <Route exact path='/staff/dashboard' component={StaffDashboard} />
          <Route
            exact
            path='/doctor/dashboard/appointment-history'
            component={AppointmentTabs}
          />
          <Route exact path='/doctor/patient' component={PatientTabs} />
          <Route
            exact
            path='/doctor/dashboard/case-record'
            component={CaseRecordTab}
          />
          <Route exact path='/doctor/staff' component={Staffs} />
          <Route exact path='/doctor/notification' component={Notification} />
          <Route exact path='/doctor/dashboard/toolbox' component={Toolbox} />
          <Route
            exact
            path='/doctor/patient-detail/:tab?/:id?'
            component={PatientDetailTab}
          />
          <Route
            exact
            path='/prescription/:id?'
            component={PrescriptionDownload}
          />
          <Route exact path='/invoice/:id?' component={Invoice} />
          <Route exact path='/doctor/addPatient' component={AddPatient} />
          <Route exact path='/staff/addPatient' component={AddPatient} />
          <Route exact path='/doctor/staff/add' component={AddStaff} />
          <Route exact path='/doctor/recordVisit' component={RecordVisit} />
          <Route exact path='/staff/recordVisit' component={RecordVisit} />
          <Route
            exact
            path='/doctor/newAppointment'
            component={NewAppintment}
          />
          <Route exact path='/patient/desk' component={PatientDesk} />
          <Route exact path='/doctor/desk' component={DoctorDesk} />
          <Route exact path='/doctor/summary' component={Summary} />
          <Route exact path='/staff/newAppointment' component={NewAppintment} />
          <Route
            exact
            path='/doctor/reschedule-appointment'
            component={RescheduleAppintment}
          />
          <Route exact path='/doctor/dashboard/calender' component={Calender} />
          <Route
            exact
            path='/doctor/video-call/:appointment_id'
            component={VideoCall}
          />
          <Route
            exact
            path='/patient/video-call/:appointment_id'
            component={VideoCallPatient}
          />
          <Route
            exact
            path='/patient/sign-in/:app?/:profile?'
            component={SignIn}
          />
          <Route
            exact
            path='/patient/appointment/sign-in/:profile'
            component={AppointmentSignUp}
          />
          <Route
            exact
            path='/patient/appointment/forget-password/:profile'
            component={ForgetPassword}
          />
          <Route
            exact
            path='/patient/sign-up/:app?/:profile?'
            component={RequestOtp}
          />
          <Route
            exact
            path='/patient/verify-partner/:id'
            component={RequestOtp}
          />
          <Route exact path='/doctor/sign-up' component={RequestOtpDoctor} />
          <Route
            exact
            path='/doctor/forget-password'
            component={ForgetPasswordDoctor}
          />
          <Route
            exact
            path='/doctor/sign-up/personal-detail'
            component={PersonalDetail}
          />
          <Route
            exact
            path='/doctor/sign-up/educational-detail/education'
            component={Education}
          />
          <Route
            exact
            path='/doctor/sign-up/educational-detail/experience'
            component={Experience}
          />
          <Route
            exact
            path='/doctor/sign-up/educational-detail/certification'
            component={Certification}
          />
          <Route
            exact
            path='/doctor/sign-up/educational-detail/affiliations'
            component={Award}
          />
          <Route
            exact
            path='/doctor/sign-up/educational-detail/publications'
            component={Publication}
          />
          <Route
            exact
            path='/doctor/sign-up/documentation'
            component={Documentation}
          />
          <Route exact path='/doctor/sign-up/clinic' component={Clinic} />
          <Route
            exact
            path='/doctor/sign-up/associations'
            component={Associations}
          />
          <Route
            exact
            path='/doctor/sign-up/practice-preferences'
            component={Practice}
          />
          <Route exact path='/doctor/sign-up/link' component={LinkSetup} />
          <Route exact path='/dr/:username/:appointment?' component={Profile} />
          <Route exact path='/doctor/sign-up/contract' component={Contract} />
          <Route exact path='/doctor/sign-up/schedule' component={Schedule} />
          <Route exact path='/doctor/sign-up/bank' component={Bank} />
          <Route exact path='/doctor/sign-up/success' component={Success} />
          <Route exact path='/doctor/sign-in' component={DoctorSignIn} />
          <Route exact path='/staff/sign-in' component={StaffSignIn} />
          <Route exact path='/patient/doctors' component={Doctors} />
          <Route exact path='/patient/chat' component={Chat} />
          <Route exact path='/counsellor/login' component={CounselorLogin} />
          <Route exact path='/counsellor/chat' component={CounselorChat} />
          <Route exact path='/contact-us' component={ContactUs} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
