import React, { Component } from "react";

export default class DashboardTopBar extends Component {
  render() {
    const { isDoctor = false } = this.props;
    const { first_name = "" } =
      !isDoctor && localStorage.getItem("patient")
        ? JSON.parse(localStorage.getItem("patient"))
        : {};
    return (
      <div class="dc-head d-flex flex-wrap align-items-center">
        {!isDoctor && (
          <div class="dc-head-left">
            <h4>
              {" "}
              <span>Hi {first_name} </span> Let’s setup your account…
            </h4>
          </div>
        )}
        <div class="dc-head-right ml-auto">
          <ul class="dashboard-link-list d-flex align-items-center justify-content-end">
            <li
              class={
                this.props.step === 1 ||
                this.props.step === 2 ||
                this.props.step === 3 ||
                //this.props.step === 4 ||
                this.props.step === 5
                  ? "active"
                  : ""
              }
            >
              <a>
                <span class="d-none d-xl-block">Personal Details</span>
              </a>
            </li>
            <li
              class={
                this.props.step === 2 ||
                this.props.step === 3 ||
                //this.props.step === 4 ||
                this.props.step === 5
                  ? "active"
                  : ""
              }
            >
              <a>
                <span class="d-none d-xl-block">
                  Nominee & Emergency Contact
                </span>
              </a>
            </li>
            {!isDoctor && (
              <li
                class={
                  this.props.step === 3 ||
                  //this.props.step === 4 ||
                  this.props.step === 5
                    ? "active"
                    : ""
                }
              >
                <a>
                  <span class="d-none d-xl-block">Verify ID</span>
                </a>
              </li>
            )}
            {/* <li
              class={
                this.props.step === 4 || this.props.step === 5 ? "active" : ""
              }
            >
              <a>
                <span class="d-none d-xl-block">Your History</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}
