import React, { Component } from 'react'
import notifications from './../../../../include/images/notifications-icon.svg'
import logo from './../../../../include/images/mindy-logo.svg'
import small from './../../../../include/images/small-down.svg'
import { Link } from 'react-router-dom'
import logout from './../../../../include/images/logout-icon.svg'
import { clearAllToken } from '../../../services/localStorageServices'
import {
  getPatient,
  loadPatientProfile,
  setInitialData,
} from '../../../../store/patient'
import { connect } from 'react-redux'
import AvtarImage from '../../../common/avtarImage'

class DashboardHeader extends Component {
  state = {
    toggleOn: false,
    isShow: false,
    patient: localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))
      : false,
  }

  changeBodyClass = () => {
    this.setState({ toggleOn: !this.state.toggleOn })
    if (this.state.toggleOn) {
      document.body.classList.remove('on')
    } else {
      document.body.classList.add('dashboard')
      document.body.classList.add('on')
    }
  }
  logOut = (e) => {
    if (this.props.setPatient) this.props.setPatient(false)
    e.preventDefault()
    clearAllToken()
    this.setState({ patient: false })
    window.location.href = '/'
  }
  toggle = () => {
    this.setState({ isShow: !this.state.isShow })
  }
  componentDidMount() {
    document.body.classList.add('dashboard')
    if (localStorage.getItem('patient')) {
      this.props.loadPatientProfile((res) => {
        if (res.status == 401) {
        } else {
          const patData = res.data.data
          if (
            (patData.nominated_representative_name && !patData.self) ||
            patData.self
          ) {
            this.props.setInitialData(false)
          }
        }
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('dashboard')
  }

  render() {
    const { first_name, last_name, thumb } = this.props.patientProfile
    return (
      <div class="dashboard-top-bar">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center">
              <div
                className={
                  this.state.toggleOn ? 'menu-toggle on' : 'menu-toggle'
                }
                onClick={this.changeBodyClass}
              >
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
              </div>

              <Link to="/patient/dashboard" class="navbar-brand">
                <img src={logo} alt="" />
              </Link>
              <div class="dtb-right ml-auto">
                <ul class="nav">
                  {/* <li class=" mr-5">
                    <a
                      onClick={(e) => e.preventDefault()}
                      href="#"
                      class="notification-box "
                    >
                      <img src={notifications} alt="" />
                      <span class="d-none d-md-block">
                        No New Notifications
                      </span>
                    </a>
                  </li> */}
                  <li>
                    <div class="custom-menu">
                      <div>
                        <a
                          onClick={(e) => e.preventDefault()}
                          href="javascript:void(0)"
                          class="profile-menu-box"
                        >
                          <div
                            class="profile-text-box d-none d-md-block"
                            onClick={(e) => {
                              this.toggle()
                            }}
                          >
                            {first_name && first_name + ' ' + last_name}
                            <img src={small} alt="" />
                          </div>
                          <div
                            class="profile-img"
                            onClick={(e) => {
                              this.toggle()
                            }}
                          >
                            {thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + thumb}
                                alt=""
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                        </a>
                        <div
                          class={
                            this.state.isShow === true
                              ? 'custom-dropdown show'
                              : 'custom-dropdown'
                          }
                        >
                          <ul class="menu-list">
                            <li>
                              <Link
                                to={'/patient/dashboard/profile-information'}
                              >
                                Profile Information
                              </Link>
                            </li>
                            <li>
                              <a href="/">Visit Site</a>
                            </li>
                            <li>
                              <a href="#!" onClick={this.logOut}>
                                <b>
                                  <img src={logout} alt="" />
                                  Logout
                                </b>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  patientProfile: getPatient(state).profile,
  loading: getPatient(state).loading,
})
const mapDispatchToProps = (dispatch) => ({
  loadPatientProfile: (callback) => dispatch(loadPatientProfile(callback)),
  setInitialData: (param) => dispatch(setInitialData(param)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader)
