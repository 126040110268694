import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "document",
  initialState: {
    document: [],
    category: [],
    add: {},
    share: {},
    doctorAdd:{},
    loading: false,
    categoryLoading: false,
    lastFetch: null,
  },
  reducers: {
    documentRequested: (document, action) => {
      document.loading = true;
    },

    documentReceived: (document, action) => {
      document.document = action.payload.data;
      document.loading = false;
      document.lastFetch = Date.now();
    },

    documentRequestFailed: (document, action) => {
      document.loading = false;
    },
    listRequested: (document, action) => {
      document.loading = true;
    },

    listReceived: (document, action) => {
      document.list = action.payload.data;
      document.loading = false;
      document.lastFetch = Date.now();
    },

    listRequestFailed: (document, action) => {
      document.loading = false;
    },

    documentShareReceived: (document, action) => {
      document.share = action.payload.data;
      document.loading = false;
      document.lastFetch = Date.now();
    },

    documentShareRequestFailed: (document, action) => {
      document.loading = false;
    },
    documentCategoryRequested: (document, action) => {
      document.categoryLoading = true;
    },

    documentCategoryReceived: (document, action) => {
      document.category = action.payload.data;
      document.categoryLoading = false;
      document.lastFetch = Date.now();
    },

    documentCategoryRequestFailed: (document, action) => {
      document.categoryLoading = false;
    },
    documentAdded: (document, action) => {
      document.add = action.payload;
      document.categoryLoading = false;
      document.lastFetch = Date.now();
    },

    documentAddRequestFailed: (document, action) => {
      document.categoryLoading = false;
      },
    doctordocumentAdded: (document, action) => {
      document.doctorAdd = action.payload;
      document.categoryLoading = false;
      document.lastFetch = Date.now();
    },

    doctordocumentAddRequestFailed: (document, action) => {
      document.categoryLoading = false;
    },
  },
});

export const {
  listRequested,
  listReceived,
  listRequestFailed,
  documentRequested,
  documentAdded,
  documentShareReceived,
  documentShareRequestFailed,
  documentAddRequestFailed,
  doctordocumentAdded,
  doctordocumentAddRequestFailed,
  documentCategoryReceived,
  documentCategoryRequested,
  documentCategoryRequestFailed,
  documentReceived,
  documentRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "document/";
const url1 = "documentCategory/";
const url2 = "document/list/forDoctor/";

export const loadDocument = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url,
      params,
      onStart: documentRequested.type,
      onSuccess: documentReceived.type,
      onError: documentRequestFailed.type,
    })
  );
};
export const loadList = (id,params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
        url: url2 + id,
        params,
        onStart: listRequested.type,
        onSuccess: listReceived.type,
        onError: listRequestFailed.type,
    })
  );
};
export const deletedDocument = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "delete",
      callback,
    })
  );
};
export const shareDocument = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + id,
      method: "PUT",
      callback,
      onSuccess: documentShareReceived.type,
      onError: documentShareRequestFailed.type,
    })
  );
};
export const loadDocumentCategory = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1,
      params,
      onStart: documentCategoryRequested.type,
      onSuccess: documentCategoryReceived.type,
      onError: documentCategoryRequestFailed.type,
    })
  );
};
export const addDocument = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url,
      method: "post",
      data: param,
      onSuccess: documentAdded.type,
      onError: documentAddRequestFailed.type,
    })
  );
};
export const addDoctorDocument = (id,param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: url + id,
      method: "post",
      data: param,
      onSuccess: doctordocumentAdded.type,
      onError: doctordocumentAddRequestFailed.type,
    })
  );
};

export const getDocument = createSelector(
  (state) => state.entities.document,
  (document) => document
);
