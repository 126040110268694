import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'
//import moment from "moment";

const slice = createSlice({
  name: 'doctorPatient',
  initialState: {
    verifiedList: [],
    doctorPatient: { data: [], pagination: {} },
    loading: false,
    lastFetch: null,
    update: {},
  },
  reducers: {
    doctorPatientRequested: (doctorPatient, action) => {
      doctorPatient.loading = true
    },

    doctorPatientReceived: (doctorPatient, action) => {
      doctorPatient.doctorPatient = action.payload
      doctorPatient.loading = false
      doctorPatient.lastFetch = Date.now()
    },

    doctorPatientRequestFailed: (doctorPatient, action) => {
      doctorPatient.loading = false
    },

    verifiedListRequested: (doctorPatient, action) => {
      doctorPatient.loading = true
    },

    verifiedListReceived: (doctorPatient, action) => {
      doctorPatient.verifiedList = action.payload.data
      doctorPatient.loading = false
    },
    verifiedListRequestFailed: (doctorPatient, action) => {
      doctorPatient.loading = false
    },

    updatedStar: (doctorPatient, action) => {
      doctorPatient.update = action.payload
      doctorPatient.loading = false
      doctorPatient.lastFetch = Date.now()
    },

    updatedStarRequestFailed: (doctorPatient, action) => {
      doctorPatient.loading = false
    },
  },
})

export const {
  verifiedListRequested,
  verifiedListReceived,
  verifiedListRequestFailed,
  doctorPatientRequested,
  doctorPatientReceived,
  doctorPatientRequestFailed,
  updatedStar,
  updatedStarRequestFailed,
} = slice.actions
export default slice.reducer

// Action Creators
const url = 'patient/list/forDoctor'
const url1 = 'patient/star/'
const url2 = 'patient/verifiedList/forDoctor'

export const loadAllverified = params => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url2,
      params,
      onStart: verifiedListRequested.type,
      onSuccess: verifiedListReceived.type,
      onError: verifiedListRequestFailed.type,
    })
  )
}

export const loadAllDoctorpatient =
  (params, callback) => (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        url: url,
        params,
        callback,
        onStart: doctorPatientRequested.type,
        onSuccess: doctorPatientReceived.type,
        onError: doctorPatientRequestFailed.type,
      })
    )
  }
export const updateStar = (id, callback) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: url1 + id,
      method: 'PUT',
      callback,
      onSuccess: updatedStar.type,
      onError: updatedStarRequestFailed.type,
    })
  )
}

export const getDoctorPatient = createSelector(
  state => state.entities.doctorPatient,
  doctorPatient => doctorPatient
)
