import React from "react";
import Joi from "joi-browser";
import SubmitBtn from "../../common/form/submitBtn";
import {
  emailInvalid,
  emailRequired,
  firstNameRequired,
  fnameMax,
  lastNameRequired,
  lnameMax,
  mobileInvalid,
  mobileRequired,
  passwordInvalid,
  passwordRequired,
  passwordInvalidMax,
} from "../../common/misc";
import Form from "../../common/form/form";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import { addStaff, updateStaff } from "../../../store/staff";

class PersonalDetails extends Form {
  state = {
    loader: false,
    btnClass: "btn btn-lg btn-primary btn-block",
    data: {
      first_name: "",
      last_name: "",
      password: "",
      email: "",
      phone: "",
    },
    errors: "",
  };

  schema = {
    first_name: Joi.string()
      .required()
      .max(50)
      .label("First Name")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = firstNameRequired;
              break;

            case "string.max":
              err.message = fnameMax;
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),
    phone: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          console.log("err", err);
          switch (err.type) {
            case "any.required":
              err.message = mobileRequired;
              break;
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = emailRequired;
              break;

            case "string.email":
              err.message = emailInvalid;
              break;
            case "string.max":
              err.message = emailInvalid;
              break;
          }
        });
        return errors;
      }),
    last_name: Joi.string()
      .required()
      .max(50)
      .label("Last Name")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = lastNameRequired;
              break;

            case "string.max":
              err.message = lnameMax;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    this.setState({
      loader: true,
    });

    const payLoad = this.state.data;
    if (this.props.history.location.state) {
      if (!payLoad.password) delete payLoad.password;
      this.props.updateStaff(
        this.props.history.location.state._id,
        payLoad,
        this.callback
      );
    } else {
      this.props.addStaff(payLoad, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      loader: false,
    });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Information saved."} />);
      this.props.history.push("/doctor/staff");
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  componentDidMount = () => {
    if (this.props.history.location.state) {
      const { data } = this.state;
      const { first_name, last_name, password, email, phone } =
        this.props.history.location.state;
      data.phone = phone;
      data.first_name = first_name;
      data.last_name = last_name;
      data.email = email;
      this.setState({ data });
      this.schema.password = Joi.string().allow("");
    }
  };

  render() {
    return (
      <div className="dashboard-content-box">
        <h4>
          {this.props.history.location.state ? "Update" : "Add New"} Staff
        </h4>
        <form onSubmit={this.handleSubmit}>
          <div className="dc-body d-flex flex-wrap align-items-lg-center">
            <div className="dc-left">
              {/* <h4 className="d-block d-xl-none">Personal Details</h4> */}
              <div className="dc-form-box">
                <div className="row"></div>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("first_name", "First Name")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("last_name", "Last Name")}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("phone", "Mobile Number", "text")}
                  </div>
                  <div className="col-md-6">
                    {this.renderInput("email", "Email")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {this.renderInput("password", "Password")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dc-bottom-box">
            <SubmitBtn
              label="Save"
              loading={this.state.loading}
              btnClass="btn btn-default no-shadow"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addStaff: (param, callback) => dispatch(addStaff(param, callback)),
  updateStaff: (id, param, callback) =>
    dispatch(updateStaff(id, param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(PersonalDetails));
