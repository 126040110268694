import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getAppointment,
  loadAllAppointmentForDoctor,
} from '../../../store/appointment'
import JourneyList from './journeyList'
import { getDoctor } from '../../../store/doctor'
import { loadChatForDoctor } from '../../../store/chats'
class Journey extends Component {
  state = { page: 1 }
  componentDidMount() {
    document.body.className = 'dashboard fixed'
    this.props.loadAllAppointmentForDoctor({
      page: 1,
      patient_id: this.props.id,
    })
  }
  loadFunc = () => {
    if (
      Math.ceil(
        this.props.appointment.pagination.filtered_record /
          this.props.appointment.pagination.record_per_page
      ) > this.state.page
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.props.loadAllAppointmentForDoctor({
          page: this.state.page + 1,
          patient_id: this.props.location.state,
        })
      )
    }
  }
  render() {
    const { appointment, loading } = this.props
    const { data, pagination } = appointment
    return (
      // <div
      //   class="tab-pane card fade show active"
      //   id="Journey"
      //   role="tabpanel"
      //   aria-labelledby="Journey-tab"
      // >
      //   <div class="card-header">Journey</div>
      // <div class="content-body show add-scrollbar  scrollbar-dynamic">
      data.length > 0 ? (
        <ul class='new-profile-list'>
          <JourneyList
            loadChatForDoctor={this.props.loadChatForDoctor}
            states={this.state}
            appointment={data}
            pagination={pagination}
            loadFunc={this.loadFunc}
            loading={loading}
            doctorProfile={this.props.doctorProfile}
          />
        </ul>
      ) : (
        <ul class='new-profile-list'>
          <li class='np-item'>No Records Found.</li>
        </ul>
      )
      //   {/* </div>
      // </div> */}
    )
  }
}

const mapStateToProps = state => ({
  appointment: getAppointment(state).doctorAppointment,
  loading: getAppointment(state).loadingDoctorAppointment,
  doctorProfile: getDoctor(state).profile,
})
const mapDispatchToProps = dispatch => ({
  loadChatForDoctor: id => dispatch(loadChatForDoctor(id)),
  loadAllAppointmentForDoctor: params =>
    dispatch(loadAllAppointmentForDoctor(params)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Journey))
