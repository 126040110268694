import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCaserecord,
  addDemographics,
  loadCaseRecord,
} from "../../../../store/caseRecord";
import download from "../../../../include/images/download-icon.svg";
import Joi from "joi-browser";
import Form from "../../../common/form/form";
import _ from "lodash";
import AlertSuccess from "../../../common/alertSuccess";
import { toast } from "react-toastify";
import {
  general,
  attitude_fir,
  attitude_sec,
  speech1,
  speech2,
  attentions,
  orient,
  memory1,
  memory2,
  abstract1,
  abstract2,
  stream,
  possession,
  content,
  attitude_fir1,
  delusion,
  perception1,
  perception2,
  perception3,
  mood1,
  speech,
  mood2,
  affect1,
  affect2,
  affect3,
  general1,
  thought,
} from "./common";

class MentalHealth extends Form {
  state = {
    general_first: [],
    attitude_first: [],
    attitude_second: [],
    general: [],
    quality: [],
    attention_second: [],
    orientation: [],
    memory_first: [],
    memory_second: [],
    abstract_second: [],
    abstract_third: [],
    thought_stream: [],
    thought_from_possession: [],
    thought_content: [],
    delusion_fourth: [],
    perception_first: [],
    perception_second: [],
    perception_third: [],
    mood_first: [],
    mood_second: [],
    affect_first: [],
    affect_second: [],
    affect_third: [],

    data: {
      session_type: "Detailed Examination",
      general_second: "",
      general_third: "",
      general_fourth: "",
      pma: "",
      rection_time: "",
      hallucinatory_behaviour: "",
      abnormal_option: "",
      abnormal_other: "",
      intensity: "",
      rate: "",
      volume: "",
      notes: "",
      attention_first: "",
      abstract_first: "",
      thought_first: "",
      speech_sample: "",
      thought_other1: "",
      thought_other2: "",
      thought_other3: "",
      delusion_first: "",
      delusion_second: "",
      delusion_third: "",
      delusion_other: "",
      perception_other: "",
      mood_other: "",
      judgement_first: "",
      judgement_notes: "",
      insight_first: "",
      insight_notes: "",
      mood_notes: "",
      affect_notes: "",
    },
    errors: {},
    type: [
      {
        id: "Detailed Examination",
        name: "Detailed Examination",
      },
      {
        id: "Brief Examination",
        name: "Brief Examination",
      },
    ],
  };
  schema = {
    session_type: Joi.string().allow(""),
    general_second: Joi.string().allow(""),
    general_third: Joi.string().allow(""),
    general_fourth: Joi.string().allow(""),
    pma: Joi.string().allow(""),
    rection_time: Joi.string().allow(""),
    hallucinatory_behaviour: Joi.string().allow(""),
    abnormal_option: Joi.string().allow(""),
    abnormal_other: Joi.string().allow(""),
    intensity: Joi.string().allow(""),
    rate: Joi.string().allow(""),
    volume: Joi.string().allow(""),
    notes: Joi.string().allow(""),
    attention_first: Joi.string().allow(""),
    abstract_first: Joi.string().allow(""),
    thought_first: Joi.string().allow(""),
    speech_sample: Joi.string().allow(""),
    thought_other1: Joi.string().allow(""),
    thought_other2: Joi.string().allow(""),
    thought_other3: Joi.string().allow(""),
    delusion_first: Joi.string().allow(""),
    delusion_second: Joi.string().allow(""),
    delusion_third: Joi.string().allow(""),
    delusion_other: Joi.string().allow(""),
    delusion_notes: Joi.string().allow(""),
    perception_other: Joi.string().allow(""),
    mood_other: Joi.string().allow(""),
    mood_notes: Joi.string().allow(""),
    affect_notes: Joi.string().allow(""),
    judgement_first: Joi.string().allow(""),
    judgement_notes: Joi.string().allow(""),
    insight_first: Joi.string().allow(""),
    insight_notes: Joi.string().allow(""),
  };
  doSubmit = () => {
    const {
      session_type,
      general_second,
      general_third,
      general_fourth,
      pma,
      rection_time,
      hallucinatory_behaviour,
      abnormal_option,
      abnormal_other,
      intensity,
      rate,
      volume,
      notes,
      attention_first,
      abstract_first,
      thought_first,
      speech_sample,
      thought_other1,
      mood_notes,
      affect_notes,
      thought_other2,
      thought_other3,
      delusion_first,
      delusion_second,
      delusion_third,
      delusion_other,
      delusion_notes,
      perception_other,
      mood_other,
      judgement_first,
      judgement_notes,
      insight_first,
      insight_notes,
    } = this.state.data;
    const {
      general_first,
      attitude_first,
      attitude_second,
      general,
      quality,
      attention_second,
      orientation,
      memory_first,
      memory_second,
      abstract_second,
      abstract_third,
      thought_stream,
      thought_from_possession,
      thought_content,
      delusion_fourth,
      perception_first,
      perception_second,
      perception_third,
      mood_first,
      mood_second,
      affect_first,
      affect_second,
      affect_third,
    } = this.state;
    const id = this.props.data._id;
    const formData = {
      mental_health_assesment: {
        mental_status_examination: _.pickBy(
          {
            session_type: session_type,
          },
          _.identity
        ),
        general_appearance: _.pickBy(
          {
            first_option: general_first,
            second_option: general_second,
            third_option: general_third,
            fourth_option: general_fourth,
          },
          _.identity
        ),
        attitude: _.pickBy(
          {
            first_option: attitude_first,
            second_option: attitude_second,
            pma: pma,
            rection_time: rection_time,
          },
          _.identity
        ),
        motor_behaviour: _.pickBy(
          {
            hallucinatory_behaviour: hallucinatory_behaviour,
            abnormal_movements: _.pickBy(
              {
                option: abnormal_option,
                other: abnormal_other,
              },
              _.identity
            ),
          },
          _.identity
        ),
        speach: _.pickBy(
          {
            general: general,
            quality: quality,
            intensity: intensity,
            rate: rate,
            volume: volume,
            notes: notes,
          },
          _.identity
        ),
        attention_concentration: _.pickBy(
          {
            first_option: attention_first,
            second_option: attention_second,
          },
          _.identity
        ),
        orientation: orientation,
        memory: _.pickBy(
          {
            first_option: memory_first,
            second_option: memory_second,
          },
          _.identity
        ),
        abstract_thinking_intelligence: _.pickBy(
          {
            first_option: abstract_first,
            second_option: abstract_second,
            third_option: abstract_third,
          },
          _.identity
        ),
        thought_disorder: _.pickBy(
          {
            first_option: thought_first,
            thought_stream: _.pickBy(
              {
                value: thought_stream,
                other_value: thought_other1,
              },
              _.identity
            ),
            thought_from_possession: _.pickBy(
              {
                value: thought_from_possession,
                other_value: thought_other2,
              },
              _.identity
            ),
            thought_content: _.pickBy(
              {
                value: thought_content,
                other_value: thought_other3,
              },
              _.identity
            ),
            speech_sample: speech_sample,
          },
          _.identity
        ),
        delusion: _.pickBy(
          {
            first_option: delusion_first,
            second_option: delusion_second,
            third_option: delusion_third,
            fourth_option: delusion_fourth,
            other: delusion_other,
            notes: delusion_notes,
          },
          _.identity
        ),
        perception: _.pickBy(
          {
            first_option: perception_first,
            second_option: perception_second,
            third_option: perception_third,
            other: perception_other,
          },
          _.identity
        ),
        mood: _.pickBy(
          {
            first_option: mood_first,
            second_option: mood_second,
            other: mood_other,
            notes: mood_notes,
          },
          _.identity
        ),
        affect: _.pickBy(
          {
            first_option: affect_first,
            second_option: affect_second,
            third_option: affect_third,
            notes: affect_notes,
          },
          _.identity
        ),
        judgement: _.pickBy(
          {
            first_option: judgement_first,
            notes: judgement_notes,
          },
          _.identity
        ),
        insight: _.pickBy(
          {
            first_option: insight_first,
            notes: insight_notes,
          },
          _.identity
        ),
      },
    };
    const newData = _.pickBy(formData, _.identity);
    this.props.addDemographics(newData, id, this.callBack);
  };
  callBack = (res) => {
    const _id = this.props.data && this.props.data._id;
    if (res && res.status === 200) {
      this.props.updateStep(null,4)
      this.props.loadCaseRecord(_id);
      toast(<AlertSuccess message={"Information Saved."} />);
    }
  };
  toggleGenFir = (Lang) => {
    let general_first = [...this.state.general_first];

    const find = general_first.find((v) => v === Lang);
    if (find) {
      const f = general_first.filter((vf) => vf !== Lang);
      general_first = f;
    } else {
      general_first.push(Lang);
    }
    this.setState({ general_first });
  };

  toggleGenSec = (e) => {
    const data = { ...this.state.data };
    data.general_second = e.target.value;
    this.setState({ data });
  };

  toggleGenThi = (e) => {
    const data = { ...this.state.data };
    data.general_third = e.target.value;
    this.setState({ data });
  };
  toggleGenFou = (e) => {
    const data = { ...this.state.data };
    data.general_fourth = e.target.value;
    this.setState({ data });
  };
  toggleAttFir = (Lang) => {
    let attitude_first = [...this.state.attitude_first];

    const find = attitude_first.find((v) => v === Lang);
    if (find) {
      const f = attitude_first.filter((vf) => vf !== Lang);
      attitude_first = f;
    } else {
      attitude_first.push(Lang);
    }
    this.setState({ attitude_first });
  };
  toggleAttSec = (Lang) => {
    let attitude_second = [...this.state.attitude_second];

    const find = attitude_second.find((v) => v === Lang);
    if (find) {
      const f = attitude_second.filter((vf) => vf !== Lang);
      attitude_second = f;
    } else {
      attitude_second.push(Lang);
    }
    this.setState({ attitude_second });
  };
  togglePMA = (e) => {
    const data = { ...this.state.data };
    data.pma = e.target.value;
    this.setState({ data });
  };
  toggleRection = (e) => {
    const data = { ...this.state.data };
    data.rection_time = e.target.value;
    this.setState({ data });
  };
  toggleBehaviour = (e) => {
    const data = { ...this.state.data };
    data.hallucinatory_behaviour = e.target.value;
    this.setState({ data });
  };
  toggleAbnormal = (e) => {
    const data = { ...this.state.data };
    data.abnormal_option = e.target.value;
    this.setState({ data });
  };

  toggleGeneral = (Lang) => {
    let general = [...this.state.general];

    const find = general.find((v) => v === Lang);
    if (find) {
      const f = general.filter((vf) => vf !== Lang);
      general = f;
    } else {
      general.push(Lang);
    }
    this.setState({ general });
  };
  toggleQuality = (Lang) => {
    let quality = [...this.state.quality];

    const find = quality.find((v) => v === Lang);
    if (find) {
      const f = quality.filter((vf) => vf !== Lang);
      quality = f;
    } else {
      quality.push(Lang);
    }
    this.setState({ quality });
  };
  toggleIntensity = (e) => {
    const data = { ...this.state.data };
    data.intensity = e.target.value;
    this.setState({ data });
  };
  toggleRate = (e) => {
    const data = { ...this.state.data };
    data.rate = e.target.value;
    this.setState({ data });
  };
  toggleVolume = (e) => {
    const data = { ...this.state.data };
    data.volume = e.target.value;
    this.setState({ data });
  };
  toggleAttention1 = (e) => {
    const data = { ...this.state.data };
    data.attention_first = e.target.value;
    this.setState({ data });
  };
  toggleOrientation1 = (e) => {
    let orientation = [...this.state.orientation];

    const find = orientation.find((v) => v === e.target.value);
    if (find) {
      const f = orientation.filter((vf) => vf !== e.target.value);
      orientation = f;
    } else {
      orientation.push(e.target.value);
    }
    this.setState({ orientation });
  };
  toggleAttention = (Lang) => {
    let attention_second = [...this.state.attention_second];

    const find = attention_second.find((v) => v === Lang);
    if (find) {
      const f = attention_second.filter((vf) => vf !== Lang);
      attention_second = f;
    } else {
      attention_second.push(Lang);
    }
    this.setState({ attention_second });
  };
  toggleOrientation = (Lang) => {
    let orientation = [...this.state.orientation];
    orientation = [Lang];
    this.setState({ orientation });
  };
  toggleMemoryFir1 = (e) => {
    let memory_first = [...this.state.memory_first];
    memory_first = [e.target.value];
    this.setState({ memory_first });
  };
  toggleMemoryFir = (Lang) => {
    let memory_first = [...this.state.memory_first];

    const find = memory_first.find((v) => v === Lang);
    if (find) {
      const f = memory_first.filter((vf) => vf !== Lang);
      memory_first = f;
    } else {
      memory_first.push(Lang);
    }
    this.setState({ memory_first });
  };
  toggleMemorySec = (Lang) => {
    let memory_second = [...this.state.memory_second];

    const find = memory_second.find((v) => v === Lang);
    if (find) {
      const f = memory_second.filter((vf) => vf !== Lang);
      memory_second = f;
    } else {
      memory_second.push(Lang);
    }
    this.setState({ memory_second });
  };
  toggleAbstract1 = (e) => {
    const data = { ...this.state.data };
    data.abstract_first = e.target.value;
    this.setState({ data });
  };
  toggleAbstFir = (Lang) => {
    let abstract_second = [...this.state.abstract_second];

    const find = abstract_second.find((v) => v === Lang);
    if (find) {
      const f = abstract_second.filter((vf) => vf !== Lang);
      abstract_second = f;
    } else {
      abstract_second.push(Lang);
    }
    this.setState({ abstract_second });
  };
  toggleAbstSec = (Lang) => {
    let abstract_third = [...this.state.abstract_third];

    const find = abstract_third.find((v) => v === Lang);
    if (find) {
      const f = abstract_third.filter((vf) => vf !== Lang);
      abstract_third = f;
    } else {
      abstract_third.push(Lang);
    }
    this.setState({ abstract_third });
  };

  toggleThought1 = (e) => {
    const data = { ...this.state.data };
    data.thought_first = e.target.value;
    this.setState({ data });
  };

  toggleStream = (Lang) => {
    let thought_stream = [...this.state.thought_stream];

    const find = thought_stream.find((v) => v === Lang);
    if (find) {
      const f = thought_stream.filter((vf) => vf !== Lang);
      thought_stream = f;
    } else {
      thought_stream.push(Lang);
    }
    this.setState({ thought_stream });
  };
  togglePossession = (Lang) => {
    let thought_from_possession = [...this.state.thought_from_possession];

    const find = thought_from_possession.find((v) => v === Lang);
    if (find) {
      const f = thought_from_possession.filter((vf) => vf !== Lang);
      thought_from_possession = f;
    } else {
      thought_from_possession.push(Lang);
    }
    this.setState({ thought_from_possession });
  };
  toggleContent = (Lang) => {
    let thought_content = [...this.state.thought_content];

    const find = thought_content.find((v) => v === Lang);
    if (find) {
      const f = thought_content.filter((vf) => vf !== Lang);
      thought_content = f;
    } else {
      thought_content.push(Lang);
    }
    this.setState({ thought_content });
  };
  toggleDelusion1 = (e) => {
    const data = { ...this.state.data };
    data.delusion_first = e.target.value;
    this.setState({ data });
  };
  toggleDelusion2 = (e) => {
    const data = { ...this.state.data };
    data.delusion_second = e.target.value;
    this.setState({ data });
  };
  toggleDelusion3 = (e) => {
    const data = { ...this.state.data };
    data.delusion_third = e.target.value;
    this.setState({ data });
  };
  toggleDelusion = (Lang) => {
    let delusion_fourth = [...this.state.delusion_fourth];

    const find = delusion_fourth.find((v) => v === Lang);
    if (find) {
      const f = delusion_fourth.filter((vf) => vf !== Lang);
      delusion_fourth = f;
    } else {
      delusion_fourth.push(Lang);
    }
    this.setState({ delusion_fourth });
  };
  togglePerFir1 = (e) => {
    let perception_first = [...this.state.perception_first];

    const find = perception_first.find((v) => v === e.target.value);
    if (find) {
      const f = perception_first.filter((vf) => vf !== e.target.value);
      perception_first = f;
    } else {
      perception_first.push(e.target.value);
    }
    this.setState({ perception_first });
  };
  togglePerFir = (Lang) => {
    let perception_first = [...this.state.perception_first];

    const find = perception_first.find((v) => v === Lang);
    if (find) {
      const f = perception_first.filter((vf) => vf !== Lang);
      perception_first = f;
    } else {
      perception_first.push(Lang);
    }
    this.setState({ perception_first });
  };
  togglePerSec = (Lang) => {
    let perception_second = [...this.state.perception_second];

    const find = perception_second.find((v) => v === Lang);
    if (find) {
      const f = perception_second.filter((vf) => vf !== Lang);
      perception_second = f;
    } else {
      perception_second.push(Lang);
    }
    this.setState({ perception_second });
  };
  togglePerThi = (Lang) => {
    let perception_third = [...this.state.perception_third];

    const find = perception_third.find((v) => v === Lang);
    if (find) {
      const f = perception_third.filter((vf) => vf !== Lang);
      perception_third = f;
    } else {
      perception_third.push(Lang);
    }
    this.setState({ perception_third });
  };
  toggleMoodFir = (Lang) => {
    let mood_first = [...this.state.mood_first];

    const find = mood_first.find((v) => v === Lang);
    if (find) {
      const f = mood_first.filter((vf) => vf !== Lang);
      mood_first = f;
    } else {
      mood_first.push(Lang);
    }
    this.setState({ mood_first });
  };
  toggleMoodSec = (Lang) => {
    let mood_second = [...this.state.mood_second];

    const find = mood_second.find((v) => v === Lang);
    if (find) {
      const f = mood_second.filter((vf) => vf !== Lang);
      mood_second = f;
    } else {
      mood_second.push(Lang);
    }
    this.setState({ mood_second });
  };
  toggleAffectFir = (Lang) => {
    let affect_first = [...this.state.affect_first];

    const find = affect_first.find((v) => v === Lang);
    if (find) {
      const f = affect_first.filter((vf) => vf !== Lang);
      affect_first = f;
    } else {
      affect_first.push(Lang);
    }
    this.setState({ affect_first });
  };
  toggleAffectSec = (Lang) => {
    let affect_second = [...this.state.affect_second];

    const find = affect_second.find((v) => v === Lang);
    if (find) {
      const f = affect_second.filter((vf) => vf !== Lang);
      affect_second = f;
    } else {
      affect_second.push(Lang);
    }
    this.setState({ affect_second });
  };

  toggleAffectThi = (Lang) => {
    let affect_third = [...this.state.affect_third];

    const find = affect_third.find((v) => v === Lang);
    if (find) {
      const f = affect_third.filter((vf) => vf !== Lang);
      affect_third = f;
    } else {
      affect_third.push(Lang);
    }
    this.setState({ affect_third });
  };
  toggleJudgement = (e) => {
    const data = { ...this.state.data };
    data.judgement_first = e.target.value;
    this.setState({ data });
  };
  toggleInsight = (e) => {
    const data = { ...this.state.data };
    data.insight_first = e.target.value;
    this.setState({ data });
  };
  componentDidMount() {
    const data = { ...this.state.data };
    data.session_type = "Detailed Examination";
    this.setState({ data });
    document.body.className = "dashboard fixed";
    if (this.props.data) {
      const { _id } = this.props.data;
      this.props.loadCaseRecord(_id);
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.case && this.props.loading !== prevProps.loading) {
      const { mental_health_assesment } = this.props.case;
      this.setState({
        data: {
          session_type:
            mental_health_assesment &&
            mental_health_assesment.mental_status_examination &&
            mental_health_assesment.mental_status_examination.session_type
              ? mental_health_assesment.mental_status_examination.session_type
              : "Detailed Examination",
          general_second:
            mental_health_assesment &&
            mental_health_assesment.general_appearance &&
            mental_health_assesment.general_appearance.second_option,
          general_third:
            mental_health_assesment &&
            mental_health_assesment.general_appearance &&
            mental_health_assesment.general_appearance.third_option,
          general_fourth:
            mental_health_assesment &&
            mental_health_assesment.general_appearance &&
            mental_health_assesment.general_appearance.fourth_option,
          pma: mental_health_assesment && mental_health_assesment.attitude.pma,
          rection_time:
            mental_health_assesment &&
            mental_health_assesment.attitude.rection_time,
          hallucinatory_behaviour:
            mental_health_assesment &&
            mental_health_assesment.motor_behaviour &&
            mental_health_assesment.motor_behaviour.hallucinatory_behaviour,
          abnormal_option:
            mental_health_assesment &&
            mental_health_assesment.motor_behaviour &&
            mental_health_assesment.motor_behaviour.abnormal_movements &&
            mental_health_assesment.motor_behaviour.abnormal_movements.option,
          abnormal_other:
            mental_health_assesment &&
            mental_health_assesment.motor_behaviour &&
            mental_health_assesment.motor_behaviour.abnormal_movements &&
            mental_health_assesment.motor_behaviour.abnormal_movements.other,
          intensity:
            mental_health_assesment && mental_health_assesment.speach.intensity,
          rate: mental_health_assesment && mental_health_assesment.speach.rate,
          volume:
            mental_health_assesment && mental_health_assesment.speach.volume,
          notes:
            mental_health_assesment && mental_health_assesment.speach.notes,
          attention_first:
            mental_health_assesment &&
            mental_health_assesment.attention_concentration.first_option,
          abstract_first:
            mental_health_assesment &&
            mental_health_assesment.abstract_thinking_intelligence.first_option,
          thought_first:
            mental_health_assesment &&
            mental_health_assesment.thought_disorder.first_option,
          speech_sample:
            mental_health_assesment &&
            mental_health_assesment.thought_disorder.speech_sample,
          thought_other3:
            mental_health_assesment &&
            mental_health_assesment.thought_disorder.thought_content &&
            mental_health_assesment.thought_disorder.thought_content
              .other_value,
          thought_other2:
            mental_health_assesment &&
            mental_health_assesment.thought_disorder.thought_from_possession &&
            mental_health_assesment.thought_disorder.thought_from_possession
              .other_value,
          thought_other1:
            mental_health_assesment &&
            mental_health_assesment.thought_disorder.thought_stream &&
            mental_health_assesment.thought_disorder.thought_stream.other_value,
          delusion_first:
            mental_health_assesment &&
            mental_health_assesment.delusion.first_option,
          delusion_second:
            mental_health_assesment &&
            mental_health_assesment.delusion.second_option,
          delusion_third:
            mental_health_assesment &&
            mental_health_assesment.delusion.third_option,
          delusion_other:
            mental_health_assesment && mental_health_assesment.delusion.other,
          delusion_notes:
            mental_health_assesment && mental_health_assesment.delusion.notes,
          perception_other:
            mental_health_assesment && mental_health_assesment.perception.other,
          mood_other:
            mental_health_assesment && mental_health_assesment.mood.other,
          mood_notes:
            mental_health_assesment && mental_health_assesment.mood.notes,
          affect_notes:
            mental_health_assesment && mental_health_assesment.affect.notes,
          judgement_first:
            mental_health_assesment &&
            mental_health_assesment.judgement &&
            mental_health_assesment.judgement.first_option,
          judgement_notes:
            mental_health_assesment &&
            mental_health_assesment.judgement &&
            mental_health_assesment.judgement.notes,
          insight_first:
            mental_health_assesment &&
            mental_health_assesment.insight &&
            mental_health_assesment.insight.first_option,
          insight_notes:
            mental_health_assesment &&
            mental_health_assesment.insight &&
            mental_health_assesment.insight.notes,
        },
        general_first:
          mental_health_assesment &&
          mental_health_assesment.general_appearance.first_option,
        attitude_first:
          mental_health_assesment &&
          mental_health_assesment.attitude.first_option,
        attitude_second:
          mental_health_assesment &&
          mental_health_assesment.attitude.second_option,
        orientation:
          mental_health_assesment && mental_health_assesment.orientation,
        general:
          mental_health_assesment && mental_health_assesment.speach.general,
        quality:
          mental_health_assesment && mental_health_assesment.speach.quality,
        attention_second:
          mental_health_assesment &&
          mental_health_assesment.attention_concentration.second_option,
        memory_first:
          mental_health_assesment &&
          mental_health_assesment.memory.first_option,
        memory_second:
          mental_health_assesment &&
          mental_health_assesment.memory.second_option,
        abstract_second:
          mental_health_assesment &&
          mental_health_assesment.abstract_thinking_intelligence.second_option,
        abstract_third:
          mental_health_assesment &&
          mental_health_assesment.abstract_thinking_intelligence.third_option,
        thought_content:
          mental_health_assesment &&
          mental_health_assesment.thought_disorder.thought_content &&
          mental_health_assesment.thought_disorder.thought_content.value,
        thought_from_possession:
          mental_health_assesment &&
          mental_health_assesment.thought_disorder.thought_from_possession &&
          mental_health_assesment.thought_disorder.thought_from_possession
            .value,
        thought_stream:
          mental_health_assesment &&
          mental_health_assesment.thought_disorder.thought_stream &&
          mental_health_assesment.thought_disorder.thought_stream.value,
        delusion_fourth:
          mental_health_assesment &&
          mental_health_assesment.delusion.fourth_option,
        perception_first:
          mental_health_assesment &&
          mental_health_assesment.perception.first_option,
        perception_second:
          mental_health_assesment &&
          mental_health_assesment.perception.second_option,
        perception_third:
          mental_health_assesment &&
          mental_health_assesment.perception.third_option,
        mood_first:
          mental_health_assesment && mental_health_assesment.mood.first_option,
        mood_second:
          mental_health_assesment && mental_health_assesment.mood.second_option,
        affect_first:
          mental_health_assesment &&
          mental_health_assesment.affect.first_option,
        affect_second:
          mental_health_assesment &&
          mental_health_assesment.affect.second_option,
        affect_third:
          mental_health_assesment &&
          mental_health_assesment.affect.third_option,
      });
    }
  };

  render() {
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="mental-health-assessment"
          role="tabpanel"
          aria-labelledby="mental-health-assessment"
        >
          <div class="card-header">Mental Health Assessment</div>
          <div class="content-body add-scrollbar  scrollbar-dynamic"> */}
        <form onSubmit={this.handleSubmit} id="myform">
          <div class="crf-content-box">
            <h4>Mental Status Examination</h4>
            <div class="radio-group-box">
              <div class="input-group-row d-flex flex-wrap">
                <div class="ig-column">
                  <h5>Session Type</h5>
                  {this.renderSelect(
                    "session_type",
                    "Session Type",
                    this.state.type
                  )}
                </div>
              </div>
            </div>

            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <div class="pane-seprator"></div>
                <h4>General Appearance</h4>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {general.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.general_first &&
                            this.state.general_first.find(
                              (el) => el === e.General
                            )
                          }
                          id={e.General}
                          class="form-check-input"
                          name="selectOption1"
                          onChange={() => this.toggleGenFir(e.General)}
                        />
                        <label class="form-check-label" htmlFor={e.General}>
                          {e.General}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="underage1"
                        name="selectOption2"
                        value="underage"
                        onChange={(e) => this.toggleGenSec(e)}
                        checked={this.state.data.general_second === "underage"}
                      />
                      <label class="form-check-label" htmlFor="underage1">
                        Underage
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="overage1"
                        name="selectOption2"
                        value="overage"
                        onChange={(e) => this.toggleGenSec(e)}
                        checked={this.state.data.general_second === "overage"}
                      />
                      <label class="form-check-label" htmlFor="overage1">
                        Overage
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="apa1"
                        name="selectOption2"
                        value="as per age"
                        onChange={(e) => this.toggleGenSec(e)}
                        checked={
                          this.state.data.general_second === "as per age"
                        }
                      />
                      <label class="form-check-label" htmlFor="apa1">
                        As per age
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="thinBuilt2"
                        name="selectOption3"
                        value="thin built"
                        onChange={(e) => this.toggleGenThi(e)}
                        checked={this.state.data.general_third === "thin built"}
                      />
                      <label class="form-check-label" htmlFor="thinBuilt2">
                        Thin Built
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="heavyBuilt2"
                        name="selectOption3"
                        value="heavy built"
                        onChange={(e) => this.toggleGenThi(e)}
                        checked={
                          this.state.data.general_third === "heavy built"
                        }
                      />
                      <label class="form-check-label" htmlFor="heavyBuilt2">
                        Heavy Built
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="averageBuilt2"
                        name="selectOption3"
                        value="average built"
                        onChange={(e) => this.toggleGenThi(e)}
                        checked={
                          this.state.data.general_third === "average built"
                        }
                      />
                      <label class="form-check-label" htmlFor="averageBuilt2">
                        Average Built
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="wellNourished3"
                        name="selectOption4"
                        value="well nourished"
                        onChange={(e) => this.toggleGenFou(e)}
                        checked={
                          this.state.data.general_fourth === "well nourished"
                        }
                      />
                      <label class="form-check-label" htmlFor="wellNourished3">
                        Well nourished
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="underNourished3"
                        name="selectOption4"
                        value="under nourished"
                        onChange={(e) => this.toggleGenFou(e)}
                        checked={
                          this.state.data.general_fourth === "under nourished"
                        }
                      />
                      <label class="form-check-label" htmlFor="underNourished3">
                        Under nourished
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="overWeight3"
                        name="selectOption4"
                        value="overweight"
                        onChange={(e) => this.toggleGenFou(e)}
                        checked={
                          this.state.data.general_fourth === "overweight"
                        }
                      />
                      <label class="form-check-label" htmlFor="overWeight3">
                        Overweight
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="obese3"
                        name="selectOption4"
                        value="obese"
                        onChange={(e) => this.toggleGenFou(e)}
                        checked={this.state.data.general_fourth === "obese"}
                      />
                      <label class="form-check-label" htmlFor="obese3">
                        Obese
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="pane-seprator"></div>
                <h4>General Appearance</h4>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {general1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.general_first &&
                            this.state.general_first.find(
                              (el) => el === e.General
                            )
                          }
                          id={e.General}
                          class="form-check-input"
                          name="selectOption1"
                          onChange={() => this.toggleGenFir(e.General)}
                        />
                        <label class="form-check-label" htmlFor={e.General}>
                          {e.General}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <div class="pane-seprator"></div>
                <h4>Attitude</h4>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {attitude_fir.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.attitude_first &&
                            this.state.attitude_first.find(
                              (el) => el === e.First
                            )
                          }
                          id={e.First}
                          class="form-check-input"
                          name="selectOption1"
                          onChange={() => this.toggleAttFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {attitude_sec.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.attitude_second &&
                            this.state.attitude_second.find(
                              (el) => el === e.Second
                            )
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="selectOption1"
                          onChange={() => this.toggleAttSec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>PMA</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="normal1"
                        name="pma"
                        value="normal"
                        onChange={(e) => this.togglePMA(e)}
                        checked={this.state.data.pma === "normal"}
                      />
                      <label class="form-check-label" htmlFor="normal1">
                        Normal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="increased1"
                        name="pma"
                        value="increased"
                        onChange={(e) => this.togglePMA(e)}
                        checked={this.state.data.pma === "increased"}
                      />
                      <label class="form-check-label" htmlFor="increased1">
                        Increased
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="decreased1"
                        name="pma"
                        value="decreased"
                        onChange={(e) => this.togglePMA(e)}
                        checked={this.state.data.pma === "decreased"}
                      />
                      <label class="form-check-label" htmlFor="decreased1">
                        Decreased
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Rection Time</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="normal2"
                        name="rectionTime"
                        value="normal"
                        onChange={(e) => this.toggleRection(e)}
                        checked={this.state.data.rection_time === "normal"}
                      />
                      <label class="form-check-label" htmlFor="normal2">
                        Normal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="increased2"
                        name="rectionTime"
                        value="increased"
                        onChange={(e) => this.toggleRection(e)}
                        checked={this.state.data.rection_time === "increased"}
                      />
                      <label class="form-check-label" htmlFor="increased2">
                        Increased
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="decreased2"
                        name="rectionTime"
                        value="decreased"
                        onChange={(e) => this.toggleRection(e)}
                        checked={this.state.data.rection_time === "decreased"}
                      />
                      <label class="form-check-label" htmlFor="decreased2">
                        Decreased
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="pane-seprator"></div>
                <h4>Attitude</h4>
                <div class="radio-group-box">
                  <h5>Select an Option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {attitude_fir1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.attitude_first &&
                            this.state.attitude_first.find(
                              (el) => el === e.First
                            )
                          }
                          id={e.First}
                          class="form-check-input"
                          name="selectOption1"
                          onChange={() => this.toggleAttFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {this.state.data.session_type === "Detailed Examination" && (
              <>
                <div class="pane-seprator"></div>
                <h4>Motor Behaviour</h4>
                <div class="radio-group-box">
                  <h5>Hallucinatory Behaviour</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="present3"
                        name="hallucinatoryBehaviour"
                        value="present"
                        onChange={(e) => this.toggleBehaviour(e)}
                        checked={
                          this.state.data.hallucinatory_behaviour === "present"
                        }
                      />
                      <label class="form-check-label" htmlFor="present3">
                        Present
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="absent3"
                        name="hallucinatoryBehaviour"
                        value="absent"
                        onChange={(e) => this.toggleBehaviour(e)}
                        checked={
                          this.state.data.hallucinatory_behaviour === "absent"
                        }
                      />
                      <label class="form-check-label" htmlFor="absent3">
                        Absent
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Abnormal Movements</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="tremors4"
                        name="abnormalMovements"
                        value="tremors"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={this.state.data.abnormal_option === "tremors"}
                      />
                      <label class="form-check-label" htmlFor="tremors4">
                        Tremors
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="dyestonia4"
                        name="abnormalMovements"
                        value="dyestonia"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={
                          this.state.data.abnormal_option === "dyestonia"
                        }
                      />
                      <label class="form-check-label" htmlFor="dyestonia4">
                        Dyestonia
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="dyskinesia4"
                        name="abnormalMovements"
                        value="dyskinesia"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={
                          this.state.data.abnormal_option === "dyskinesia"
                        }
                      />
                      <label class="form-check-label" htmlFor="dyskinesia4">
                        Dyskinesia
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="posturing4"
                        name="abnormalMovements"
                        value="posturing"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={
                          this.state.data.abnormal_option === "posturing"
                        }
                      />
                      <label class="form-check-label" htmlFor="posturing4">
                        Posturing
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="catatonic4"
                        name="abnormalMovements"
                        value="catatonic"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={
                          this.state.data.abnormal_option === "catatonic"
                        }
                      />
                      <label class="form-check-label" htmlFor="catatonic4">
                        Catatonic
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="other_1"
                        name="abnormalMovements"
                        value="other"
                        onChange={(e) => this.toggleAbnormal(e)}
                        checked={this.state.data.abnormal_option === "other"}
                      />
                      <label class="form-check-label" htmlFor="other_1">
                        Others
                      </label>
                    </div>
                  </div>
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("abnormal_other", "Start typing...")}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Speech</h4>
                <div class="radio-group-box">
                  <h5>General</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {speech1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.general &&
                            this.state.general.find((el) => el === e.First)
                          }
                          id={e.First}
                          class="form-check-input"
                          name="general"
                          onChange={() => {
                            console.log(e);
                            this.toggleGeneral(e.First);
                          }}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Quality</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {speech2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.quality &&
                            this.state.quality.find((el) => el === e.Second)
                          }
                          id={e.Second + "2"}
                          class="form-check-input"
                          name="quality"
                          onChange={() => {
                            console.log(e);
                            this.toggleQuality(e.Second);
                          }}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Second + "2"}
                        >
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div class="radio-group-box">
                  <h5>Intensity</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Increased6"
                        name="intensity"
                        value="increased"
                        onChange={(e) => this.toggleIntensity(e)}
                        checked={this.state.data.intensity === "increased"}
                      />
                      <label class="form-check-label" htmlFor="Increased6">
                        Increased
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Decresed6"
                        name="intensity"
                        value="decreased"
                        onChange={(e) => this.toggleIntensity(e)}
                        checked={this.state.data.intensity === "decreased"}
                      />
                      <label class="form-check-label" htmlFor="Decresed6">
                        Decreased
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Rate</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Increased7"
                        name="rate"
                        value="increased"
                        onChange={(e) => this.toggleRate(e)}
                        checked={this.state.data.rate === "increased"}
                      />
                      <label class="form-check-label" htmlFor="Increased7">
                        Increased
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Decresed7"
                        name="rate"
                        value="decreased"
                        onChange={(e) => this.toggleRate(e)}
                        checked={this.state.data.rate === "decreased"}
                      />
                      <label class="form-check-label" htmlFor="Decresed7">
                        Decreased
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Volume</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Increased8"
                        name="volume"
                        value="increased"
                        onChange={(e) => this.toggleVolume(e)}
                        checked={this.state.data.volume === "increased"}
                      />
                      <label class="form-check-label" htmlFor="Increased8">
                        Increased
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Decresed8"
                        name="volume"
                        value="decreased"
                        onChange={(e) => this.toggleVolume(e)}
                        checked={this.state.data.volume === "decreased"}
                      />
                      <label class="form-check-label" htmlFor="Decresed8">
                        Decreased
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="textarea-row">
                    <h5>Notes</h5>
                    {this.renderTextarea("notes")}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>Speech</h4>
                <div class="radio-group-box">
                  <h5>General</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {speech.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.general &&
                            this.state.general.find((el) => el === e.First)
                          }
                          id={e.First}
                          class="form-check-input"
                          name="general"
                          onChange={() => this.toggleGeneral(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div class="pane-seprator"></div>
            <h4>Attention & Concentration</h4>
            <div class="radio-group-box">
              <h5>Select an option</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="intact9"
                    name="select_option"
                    value="intact"
                    onChange={(e) => this.toggleAttention1(e)}
                    checked={this.state.data.attention_first === "intact"}
                  />
                  <label class="form-check-label" htmlFor="intact9">
                    Intact
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="impaired9"
                    name="select_option"
                    value="impaired"
                    onChange={(e) => this.toggleAttention1(e)}
                    checked={this.state.data.attention_first === "impaired"}
                  />
                  <label class="form-check-label" htmlFor="impaired9">
                    Impaired
                  </label>
                </div>
              </div>
            </div>
            {this.state.data.session_type === "Detailed Examination" && (
              <div class="radio-group-box">
                <h5>Select an option</h5>
                <div class="radio-group-row d-flex flex-wrap align-items-center">
                  {attentions.map((e) => (
                    <div class="form-check">
                      <input
                        type="checkbox"
                        checked={
                          this.state.attention_second &&
                          this.state.attention_second.find(
                            (el) => el === e.First
                          )
                        }
                        id={e.First}
                        class="form-check-input"
                        name="attention2"
                        onChange={() => this.toggleAttention(e.First)}
                      />
                      <label class="form-check-label" htmlFor={e.First}>
                        {e.First}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Orientation</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {orient.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.orientation &&
                            this.state.orientation.find((el) => el === e.First)
                          }
                          id={e.First}
                          class="form-check-input"
                          name="orientation"
                          onChange={() => this.toggleOrientation(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>Orientation</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="intact10"
                        name="select_optionn"
                        value="intact"
                        onChange={(e) => this.toggleOrientation1(e)}
                        checked={
                          this.state.orientation &&
                          this.state.orientation.find((el) => el === "intact")
                        }
                      />
                      <label class="form-check-label" htmlFor="intact10">
                        Intact
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="impaired10"
                        name="select_optionn"
                        value="impaired"
                        onChange={(e) => this.toggleOrientation1(e)}
                        checked={
                          this.state.orientation &&
                          this.state.orientation.find((el) => el === "impaired")
                        }
                      />
                      <label class="form-check-label" htmlFor="impaired10">
                        Impaired
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Memory</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {memory1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.memory_first &&
                            this.state.memory_first.find((el) => el === e.First)
                          }
                          id={e.First + "1"}
                          class="form-check-input"
                          name="select_Option1"
                          onChange={() => this.toggleMemoryFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First + "1"}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {memory2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.memory_second &&
                            this.state.memory_second.find(
                              (el) => el === e.Second
                            )
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="select_Option2"
                          onChange={() => this.toggleMemorySec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>Memory</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="intact11"
                        name="select_optioonn"
                        value="intact"
                        onChange={(e) => this.toggleMemoryFir1(e)}
                        checked={
                          this.state.memory_first &&
                          this.state.memory_first[0] === "intact"
                        }
                      />
                      <label class="form-check-label" htmlFor="intact11">
                        Intact
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="impaired11"
                        name="select_optioonn"
                        value="impaired"
                        onChange={(e) => this.toggleMemoryFir1(e)}
                        checked={
                          this.state.memory_first &&
                          this.state.memory_first[0] === "impaired"
                        }
                      />
                      <label class="form-check-label" htmlFor="impaired11">
                        Impaired
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="pane-seprator"></div>
            <h4>Abstract Thinking & Intelligence</h4>
            <div class="radio-group-box">
              <h5>Select an option</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Intact12"
                    name="select_option4"
                    value="Intact"
                    onChange={(e) => this.toggleAbstract1(e)}
                    checked={this.state.data.abstract_first === "Intact"}
                  />
                  <label class="form-check-label" htmlFor="Intact12">
                    Intact
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Imparied12"
                    name="select_option4"
                    value="Imparied"
                    onChange={(e) => this.toggleAbstract1(e)}
                    checked={this.state.data.abstract_first === "Imparied"}
                  />
                  <label class="form-check-label" htmlFor="Imparied12">
                    Imparied
                  </label>
                </div>
              </div>
            </div>
            {this.state.data.session_type === "Detailed Examination" && (
              <>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {abstract1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.abstract_second &&
                            this.state.abstract_second.find(
                              (el) => el === e.First
                            )
                          }
                          id={e.First}
                          class="form-check-input"
                          name="abstract_2"
                          onChange={() => this.toggleAbstFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {abstract2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.abstract_third &&
                            this.state.abstract_third.find(
                              (el) => el === e.Second
                            )
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="abstract_3"
                          onChange={() => this.toggleAbstSec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Thought Disorder</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Present13"
                        name="select_option7"
                        value="present"
                        onChange={(e) => this.toggleThought1(e)}
                        checked={this.state.data.thought_first === "present"}
                      />
                      <label class="form-check-label" htmlFor="Present13">
                        Present
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Absent13"
                        name="select_option7"
                        value="absent"
                        onChange={(e) => this.toggleThought1(e)}
                        checked={this.state.data.thought_first === "absent"}
                      />
                      <label class="form-check-label" htmlFor="Absent13">
                        Absent
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Thought Stream</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {stream.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.thought_stream &&
                            this.state.thought_stream.find(
                              (el) => el === e.Stream
                            )
                          }
                          id={e.Stream}
                          class="form-check-input"
                          name="selectOption_8"
                          onChange={() => this.toggleStream(e.Stream)}
                        />
                        <label class="form-check-label" htmlFor={e.Stream}>
                          {e.Stream}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("thought_other1", "Start typing...")}
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Thought from Possession</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {possession.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.thought_from_possession &&
                            this.state.thought_from_possession.find(
                              (el) => el === e.Possession
                            )
                          }
                          id={e.Possession + "2"}
                          class="form-check-input"
                          name="selectOption_9"
                          onChange={() => this.togglePossession(e.Possession)}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Possession + "2"}
                        >
                          {e.Possession}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("thought_other2", "Start typing...")}
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Thought Content</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {content.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.thought_content &&
                            this.state.thought_content.find(
                              (el) => el === e.Content
                            )
                          }
                          id={e.Content + "3"}
                          class="form-check-input"
                          name="selectOption_10"
                          onChange={() => this.toggleContent(e.Content)}
                        />
                        <label
                          class="form-check-label"
                          htmlFor={e.Content + "3"}
                        >
                          {e.Content}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("thought_other3", "Start typing...")}
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Speech Sample</h5>
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("speech_sample", "Start typing...")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>Thought Disorder</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {thought.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.thought_stream &&
                            this.state.thought_stream.find(
                              (el) => el === e.Thought
                            )
                          }
                          id={e.Thought}
                          class="form-check-input"
                          name="selectOption_118"
                          onChange={() => this.toggleStream(e.Thought)}
                        />
                        <label class="form-check-label" htmlFor={e.Thought}>
                          {e.Thought}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Delusion</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Primary14"
                        name="select_option12"
                        value="Primary"
                        onChange={(e) => this.toggleDelusion1(e)}
                        checked={this.state.data.delusion_first === "Primary"}
                      />
                      <label class="form-check-label" htmlFor="Primary14">
                        Primary
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Secondary14"
                        name="select_option12"
                        value="Secondary"
                        onChange={(e) => this.toggleDelusion1(e)}
                        checked={this.state.data.delusion_first === "Secondary"}
                      />
                      <label class="form-check-label" htmlFor="Secondary14">
                        Secondary
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Systematised15"
                        name="select_option13"
                        value="Systematised"
                        onChange={(e) => this.toggleDelusion2(e)}
                        checked={
                          this.state.data.delusion_second === "Systematised"
                        }
                      />
                      <label class="form-check-label" htmlFor="Systematised15">
                        Systematised
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Unsystemastied15"
                        name="select_option13"
                        value="Unsystemastied"
                        onChange={(e) => this.toggleDelusion2(e)}
                        checked={
                          this.state.data.delusion_second === "Unsystemastied"
                        }
                      />
                      <label
                        class="form-check-label"
                        htmlFor="Unsystemastied15"
                      >
                        Unsystemastied
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="MoodCongruent16"
                        name="select_option14"
                        value="MoodCongruent"
                        onChange={(e) => this.toggleDelusion3(e)}
                        checked={
                          this.state.data.delusion_third === "MoodCongruent"
                        }
                      />
                      <label class="form-check-label" htmlFor="MoodCongruent16">
                        Mood Congruent
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="MoodIncongruent16"
                        name="select_option14"
                        value="MoodIncongruent"
                        onChange={(e) => this.toggleDelusion3(e)}
                        checked={
                          this.state.data.delusion_third === "MoodIncongruent"
                        }
                      />
                      <label
                        class="form-check-label"
                        htmlFor="MoodIncongruent16"
                      >
                        Mood Incongruent
                      </label>
                    </div>
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {delusion.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.delusion_fourth &&
                            this.state.delusion_fourth.find(
                              (el) => el === e.Delusion
                            )
                          }
                          id={e.Delusion}
                          class="form-check-input"
                          name="selectOption_11"
                          onChange={() => this.toggleDelusion(e.Delusion)}
                        />
                        <label class="form-check-label" htmlFor={e.Delusion}>
                          {e.Delusion}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("delusion_other", "Start typing...")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div class="radio-group-box">
                <h4>Delusion</h4>
                <div class="textarea-row">
                  <h5>Notes</h5>
                  {this.renderTextarea("delusion_notes")}
                </div>
              </div>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Perception</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {perception1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.perception_first &&
                            this.state.perception_first.find(
                              (el) => el === e.First
                            )
                          }
                          id={e.First}
                          class="form-check-input"
                          name="selectOption_13"
                          onChange={() => this.togglePerFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {perception2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.perception_second &&
                            this.state.perception_second.find(
                              (el) => el === e.Second
                            )
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="selectOption_14"
                          onChange={() => this.togglePerSec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {perception3.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.perception_third &&
                            this.state.perception_third.find(
                              (el) => el === e.Third
                            )
                          }
                          id={e.Third + "2"}
                          class="form-check-input"
                          name="selectOption_15"
                          onChange={() => this.togglePerThi(e.Third)}
                        />
                        <label class="form-check-label" htmlFor={e.Third + "2"}>
                          {e.Third}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("perception_other", "Start typing...")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>Perception</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Auditoryhallucination17"
                        name="select_optionn12"
                        value="Auditory hallucination"
                        onChange={(e) => this.togglePerFir1(e)}
                        checked={
                          this.state.perception_first &&
                          this.state.perception_first.find(
                            (el) => el === "Auditory hallucination"
                          )
                        }
                      />
                      <label
                        class="form-check-label"
                        htmlFor="Auditoryhallucination17"
                      >
                        Auditory hallucination
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        type="radio"
                        class="form-check-input"
                        id="Visualhallucination17"
                        name="select_optionn12"
                        value="Visual hallucination"
                        onChange={(e) => this.togglePerFir1(e)}
                        checked={
                          this.state.perception_first &&
                          this.state.perception_first.find(
                            (el) => el === "Visual hallucination"
                          )
                        }
                      />
                      <label
                        class="form-check-label"
                        htmlFor="Visualhallucination17"
                      >
                        Visual hallucination
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Mood</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {mood1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.mood_first &&
                            this.state.mood_first.find((el) => el === e.First)
                          }
                          id={e.First}
                          class="form-check-input"
                          name="selectOption_13"
                          onChange={() => this.toggleMoodFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {mood2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.mood_second &&
                            this.state.mood_second.find((el) => el === e.Second)
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="selectOption_16"
                          onChange={() => this.toggleMoodSec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Speech Sample</h5>
                  <div class="input-group-row d-flex flex-wrap">
                    <div class="ig-column">
                      {this.renderInput("mood_other", "Start typing...")}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div class="radio-group-box">
                <h4>Mood</h4>
                <div class="textarea-row">
                  <h5>Notes</h5>
                  {this.renderTextarea("mood_notes")}
                </div>
              </div>
            )}
            <div class="pane-seprator"></div>
            {this.state.data.session_type === "Detailed Examination" ? (
              <>
                <h4>Affect</h4>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {affect1.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.affect_first &&
                            this.state.affect_first.find((el) => el === e.First)
                          }
                          id={e.First}
                          class="form-check-input"
                          name="selectOption_17"
                          onChange={() => this.toggleAffectFir(e.First)}
                        />
                        <label class="form-check-label" htmlFor={e.First}>
                          {e.First}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {affect2.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.affect_second &&
                            this.state.affect_second.find(
                              (el) => el === e.Second
                            )
                          }
                          id={e.Second}
                          class="form-check-input"
                          name="selectOption_18"
                          onChange={() => this.toggleAffectSec(e.Second)}
                        />
                        <label class="form-check-label" htmlFor={e.Second}>
                          {e.Second}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="radio-group-box">
                  <h5>Select an option</h5>
                  <div class="radio-group-row d-flex flex-wrap align-items-center">
                    {affect3.map((e) => (
                      <div class="form-check">
                        <input
                          type="checkbox"
                          checked={
                            this.state.affect_third &&
                            this.state.affect_third.find((el) => el === e.Third)
                          }
                          id={e.Third}
                          class="form-check-input"
                          name="selectOption_19"
                          onChange={() => this.toggleAffectThi(e.Third)}
                        />
                        <label class="form-check-label" htmlFor={e.Third}>
                          {e.Third}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div class="radio-group-box">
                <h4>Affect</h4>
                <div class="textarea-row">
                  <h5>Notes</h5>
                  {this.renderTextarea("affect_notes")}
                </div>
              </div>
            )}
            <div class="pane-seprator"></div>
            <h4>Judgement</h4>
            <div class="radio-group-box">
              <h5>Select an option</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Intact18"
                    name="select_option24"
                    value="Intact"
                    onChange={(e) => this.toggleJudgement(e)}
                    checked={this.state.data.judgement_first === "Intact"}
                  />
                  <label class="form-check-label" htmlFor="Intact18">
                    Intact
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="Impaired18"
                    name="select_option24"
                    value="Impaired"
                    onChange={(e) => this.toggleJudgement(e)}
                    checked={this.state.data.judgement_first === "Impaired"}
                  />
                  <label class="form-check-label" htmlFor="Impaired18">
                    Impaired
                  </label>
                </div>
              </div>
            </div>
            {this.state.data.session_type === "Detailed Examination" && (
              <div class="radio-group-box">
                <div class="textarea-row">
                  <h5>Notes</h5>
                  {this.renderTextarea("judgement_notes")}
                </div>
              </div>
            )}
            <div class="pane-seprator"></div>
            <h4>Insight</h4>
            {this.state.data.session_type === "Detailed Examination" ? (
              <div class="radio-group-box">
                <h5>Select an option</h5>
                <div class="radio-group-row d-flex flex-wrap align-items-center">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-1"
                      name="select_option25"
                      value="1"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "1"}
                    />
                    <label class="form-check-label" htmlFor="select-1">
                      1
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-2"
                      name="select_option25"
                      value="2"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "2"}
                    />
                    <label class="form-check-label" htmlFor="select-2">
                      2
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-3"
                      name="select_option25"
                      value="3"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "3"}
                    />
                    <label class="form-check-label" htmlFor="select-3">
                      3
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-4"
                      name="select_option25"
                      value="4"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "4"}
                    />
                    <label class="form-check-label" htmlFor="select-4">
                      4
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-5"
                      name="select_option25"
                      value="5"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "5"}
                    />
                    <label class="form-check-label" htmlFor="select-5">
                      5
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="select-6"
                      name="select_option25"
                      value="6"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "6"}
                    />
                    <label class="form-check-label" htmlFor="select-6">
                      6
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div class="radio-group-box">
                <h5>Select an option</h5>
                <div class="radio-group-row d-flex flex-wrap align-items-center">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="Intact5"
                      name="select_option26"
                      value="Intact"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "Intact"}
                    />
                    <label class="form-check-label" htmlFor="Intact5">
                      Intact
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="Impaired5"
                      name="select_option26"
                      value="Impaired"
                      onChange={(e) => this.toggleInsight(e)}
                      checked={this.state.data.insight_first === "Impaired"}
                    />
                    <label class="form-check-label" htmlFor="Impaired5">
                      Impaired
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div class="radio-group-box">
              <h4>Special Notes/Speech Sample/Thought Content</h4>
              <div class="textarea-row">
                {this.renderTextarea("insight_notes")}
              </div>
            </div>
          </div>
        </form>
        {/* </div>
        </div> */}
        <div class="patients-details-footer d-md-flex align-items-md-center justify-content-md-between">
          <Link to="/doctor/dashboard">
            <button class="btn btn-grey">EXIT</button>
          </Link>
          {/* <div class="pdf-btn d-flex align-items-center">
            <a href="javascript:void(0)">
              <img src={download} />
            </a>
          </div> */}
          <button type="submit" class="btn btn-primary btn-lg" form="myform">
            SAVE
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  demographicsAdd: getCaserecord(state).demographicsAdd,
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addDemographics: (params, id, callBack) =>
    dispatch(addDemographics(params, id, callBack)),
  loadCaseRecord: (id) => dispatch(loadCaseRecord(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MentalHealth)
);
