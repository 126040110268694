import React, { Component } from "react";
import _ from "lodash";
import closeIcon from "../../../../include/images/icon-close.svg";
import Form from "../../../common/form/form";
import Joi from "joi-browser";
import {
  addDiary,
  getDiary,
  loadDiary,
  updateDiary,
} from "../../../../store/diary";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import { isMobile } from "react-device-detect";

const initState = {
  foredit: false,
  data: {
    dairy_no: 1,
    situation: "",
    thought: "",
    emotion: "",
    alternate_balanced_thought: "",
  },
  errors: {},
  mapValues: [],
};
class DiaryEntry extends Form {
  state = initState;
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.addForm !== prevProps.addForm && !this.props.forEdit) {
      const data = {
        dairy_no: this.props.getDiary.lastRecords
          ? this.props.getDiary.lastRecords.dairy_no + 1
          : 1,
        situation: "",
        thought: "",
        emotion: "",
        alternate_balanced_thought: "",
      };
      this.setState({ data });
    }
    if (
      this.props.forEdit &&
      this.props.forEdit.dairy_no !== prevProps.forEdit.dairy_no
    ) {
      //alert(this.props.forEdit.dairy_no);
      const data = {
        dairy_no: this.props.forEdit.dairy_no,
        situation: this.props.forEdit.situation,
        thought: this.props.forEdit.thought,
        emotion: this.props.forEdit.emotion,
        alternate_balanced_thought:
          this.props.forEdit.alternate_balanced_thought,
      };
      this.setState({ data, foredit: true });
    }
    if (
      !this.props.getDiary.loading &&
      this.props.getDiary.loading !== prevProps.getDiary.loading
    ) {
      const { data } = this.state;
      data.dairy_no = this.props.getDiary.lastRecords
        ? this.props.getDiary.lastRecords.dairy_no + 1
        : 1;
      this.setState({ data });

      //this.state({ mapValues });
    }
  };
  onChange = (e) => {
    const { data, errors } = this.state;
    data[e.target.name] = e.target.value;
    const error = this.validateProperty(e.target);
    if (error) {
      errors[e.target.name] = error;
    }
    this.setState({ data, errors });
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema, { abortEarly: false });
    return error ? error.details[0].message : null;
  };
  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  callBack = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Information Saved"} />);
      this.setState({
        data: {
          dairy_no: 1,
          situation: "",
          thought: "",
          emotion: "",
          alternate_balanced_thought: "",
        },
        errors: {},
      });
      this.props.loadDiary({});
      this.props.closeDiaryEntry();
    } else {
      toast(<AlertError message={"Something failed"} />);
    }
  };

  callBackUpdate = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Information Updated"} />);
      this.setState({
        data: {
          dairy_no: 1,
          situation: "",
          thought: "",
          emotion: "",
          alternate_balanced_thought: "",
        },
        errors: {},
      });
      this.props.loadDiary({});
      this.props.closeDiaryEntry();
    } else {
      toast(<AlertError message={"Something failed"} />);
    }
  };

  doSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (errors) return this.setState({ errors });
    if (!this.props.forEdit) {
      this.props.removeEditData();
      const formData = { ...this.state.data };
      formData.date = moment().format("D-M-Y");
      this.props.addDiary({ data: formData, callback: this.callBack });
    } else {
      this.props.removeEditData();

      const fDataE = {
        situation: this.state.data.situation,
        thought: this.state.data.thought,
        emotion: this.state.data.emotion,
        alternate_balanced_thought: this.state.data.alternate_balanced_thought,
      };
      fDataE.date = moment().format("D-M-Y");
      console.log("fDataE", fDataE);
      this.props.updateDiary(
        this.state.data.dairy_no,
        fDataE,
        this.callBackUpdate
      );
    }
  };

  schema = {
    dairy_no: Joi.number().error(() => {
      return { message: "Invalid Number" };
    }),
    situation: Joi.string().error(() => {
      return { message: "Situation field is required" };
    }),
    thought: Joi.string().error(() => {
      return { message: "Thought field is required" };
    }),
    emotion: Joi.string().error(() => {
      return { message: "Emotion field is required" };
    }),
    alternate_balanced_thought: Joi.string().error(() => {
      return { message: "Alternate Balance Thought field is required" };
    }),
  };

  render() {
    const {
      dairy_no,
      situation,
      thought,
      emotion,
      alternate_balanced_thought,
    } = this.state.data;

    return (
      <>
        <div
          class="sidebar-box"
          id="diaryEntry"
          style={{ right: this.props.addForm ? "0px" : "-100%" }}
        >
          <div class="sidebar-head d-flex align-items-center">
            <h5>
              {this.props.forEdit.dairy_no ? "Update Diary" : "Add new Diary"}
            </h5>
            <div class="sidebar-head-right ml-auto d-flex align-items-center">
              <div></div>
              <div class="close-img" onClick={this.props.closeDiaryEntry}>
                <img src={closeIcon} alt="" />
              </div>
            </div>
          </div>
          <div
            class="sidebar-body height-auto dairy-sidebar"
            style={{ paddingBottom: `${isMobile ? "110px" : "50px"}` }}
          >
            <div class="check-box-group">
              <label>Today, {moment().format("ll")}</label>

              <input
                readOnly={true}
                value={"Diary No. " + dairy_no}
                type="text"
                name="dairy_no"
                class="form-control"
                placeholder="Diary No. 101…"
              />
              {this.state.errors.dairy_no && (
                <label class="error" style={{ color: "red" }}>
                  {this.state.errors.dairy_no}
                </label>
              )}
            </div>
            <h5>Situation</h5>
            <p>
              What were you doing? What was happening around? Were there any
              mood changes you noticed? Were you thinking about something?
            </p>
            <div class="check-box-group">
              <textarea
                value={situation}
                onChange={this.onChange}
                name="situation"
                class="form-control"
                rows="3"
                placeholder="Write here.."
              ></textarea>
              {this.state.errors.situation && (
                <label class="error" style={{ color: "red" }}>
                  {this.state.errors.situation}
                </label>
              )}
            </div>
            <h5>Thought</h5>

            <div class="check-box-group">
              <label>What went through your mind in that situation?</label>
              <input
                onChange={this.onChange}
                type="text"
                name="thought"
                value={thought}
                class="form-control"
                placeholder="Write here.."
              />
              {this.state.errors.thought && (
                <label class="error" style={{ color: "red" }}>
                  {this.state.errors.thought}
                </label>
              )}
            </div>
            <h5>Emotion</h5>

            <div class="check-box-group">
              <label>As a result of your thought, how did you feel?</label>
              <input
                onChange={this.onChange}
                type="text"
                name="emotion"
                value={emotion}
                class="form-control"
                placeholder="Write here.."
              />
              {this.state.errors.emotion && (
                <label class="error" style={{ color: "red" }}>
                  {this.state.errors.emotion}
                </label>
              )}
            </div>
            <h5>Alternate Balanced Thought</h5>

            <div class="check-box-group">
              <label>
                What positive thought would you advise others to think in
                similar situation?
              </label>
              <input
                onChange={this.onChange}
                type="text"
                name="alternate_balanced_thought"
                value={alternate_balanced_thought}
                class="form-control"
                placeholder="Write here.."
              />{" "}
              {this.state.errors.alternate_balanced_thought && (
                <label class="error" style={{ color: "red" }}>
                  {this.state.errors.alternate_balanced_thought}
                </label>
              )}
            </div>
            <div class="dc-footer">
              <a
                href="#!"
                class="btn btn-primary btn-lg"
                onClick={this.doSubmit}
              >
                Save Entry
              </a>
            </div>
          </div>
        </div>
        <div class={this.props.addForm ? "overlay4 show" : "overlay4"}></div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadDiary: (data) => dispatch(loadDiary(data)),
  addDiary: (data) => dispatch(addDiary(data)),
  updateDiary: (diary_no, data, callback) =>
    dispatch(updateDiary(diary_no, data, callback)),
});
const mapStateToProps = (state) => ({
  getDiary: getDiary(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DiaryEntry)
);
