import React from "react";
import dateFormat from "dateformat";

import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { SvgAvatar } from "../../util/svgavatar";
import Avatar from "../Avatar";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageTxtWrapperStyle,
  messageTxtStyle,
  messageInfoWrapperStyle,
  messageTimeStampStyle,
  messageThumbnailStyle,
  messageDetailStyle,
  nameWrapperStyle,
  nameStyle,
} from "./style";

import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";

const deletedmessagebubble = (props) => {
  let message = null;
  const messageDate = props.message.sentAt * 1000;
  if (props.messageOf === "sender") {
    message = (
      <React.Fragment>
        <div
          style={messageTxtWrapperStyle(props)}
          className="message__txt__wrapper"
        >
          <p style={messageTxtStyle(props)} className="message__txt">
            {Translator.translate("YOU_DELETED_THIS_MESSAGE", props.lang)}
          </p>
        </div>
        <div
          style={messageInfoWrapperStyle(props)}
          className="message__info__wrapper"
        >
          <span
            style={messageTimeStampStyle(props)}
            className="message__timestamp"
          >
            {dateFormat(messageDate, "shortTime")}
          </span>
        </div>
      </React.Fragment>
    );
  } else if (props.messageOf === "receiver") {
    let avatar = null,
      name = null;

    if (props.message.receiverType === "group") {
      if (!props.message.sender.avatar) {
        const uid = props.message.sender.getUid();
        const char = props.message.sender.getName().charAt(0).toUpperCase();

        props.message.sender.setAvatar(SvgAvatar.getAvatar(uid, char));
      }

      avatar = (
        <div style={messageThumbnailStyle()} className="message__thumbnail">
          <Avatar
            cornerRadius="50%"
            borderColor={props.theme.color.secondary}
            borderWidth="1px"
            image={props.message.sender.avatar}
          />
        </div>
      );
      name = (
        <div style={nameWrapperStyle(props)} className="message__name__wrapper">
          <span style={nameStyle(props)} className="message__name">
            {props.message.sender.name}
          </span>
        </div>
      );
    }

    message = (
      <React.Fragment>
        {avatar}
        <div style={messageDetailStyle(props)} className="message__details">
          {name}
          <div
            style={messageTxtWrapperStyle(props)}
            className="message__txt__wrapper"
          >
            <p style={messageTxtStyle(props)} className="message__txt">
              {Translator.translate("THIS_MESSAGE_DELETED", props.lang)}
            </p>
          </div>
          <div
            style={messageInfoWrapperStyle(props)}
            className="message__info__wrapper"
          >
            <span
              style={messageTimeStampStyle(props)}
              className="message__timestamp"
            >
              {dateFormat(messageDate, "shortTime")}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div style={messageContainerStyle(props)} className="message__deleted">
      <div style={messageWrapperStyle(props)} className="message__wrapper">
        {message}
      </div>
    </div>
  );
};

// Specifies the default values for props:
deletedmessagebubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

deletedmessagebubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default deletedmessagebubble;
