import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearAllToken } from "../../services/localStorageServices";
import borderCheckImg from "./../../../include/images/border-check-icon.svg";

class PartnerCompleted extends Component {
  state = {};

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center justify-content-center text-center">
          <div className="mr-box">
            <div className="form-check-icon">
              <img src={borderCheckImg} alt="" />
            </div>
            <p className="mb-5">
              Link Has Been Sent Successfully To{" "}
              {this.props.partnerDetail.email_id} For Registration
            </p>
            <Link
              to="/patient/dashboard"
              type="submit"
              className="btn btn-primary btn-block"
            >
              Proceed To My Dashboard
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerCompleted;
