import React, { Component } from "react";
import Joi from "joi-browser";
import loaderIcon from "../../../../include/images/loading-icon.svg";
import { toast } from "react-toastify";
import Form from "./../../../common/form/form";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import { Link, withRouter } from "react-router-dom";
import { sendOtp } from "../../../../store/patient";
import { mobileAvailability } from "../../../../store/doctor";

import {
  mobileInvalid,
  mobileRequired,
  dobRequired,
  dobInvalid,
} from "../../../common/misc";

import { connect } from "react-redux";
import SubmitBtn from "../../../common/form/submitBtn";
import { clearAllToken } from "../../../services/localStorageServices";
import { loadSeo } from "../../../../store/seo";

class VerifyMobile extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loader: false,
    data: { mobile: "" },
    errors: {},
  };

  componentDidMount = () => {
    clearAllToken();
    this.props.loadSeo("doctor-registration");
  };

  schema = {
    mobile: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        console.log(errors);
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        loader: true,
        btnClass: "btn btn-lg btn-primary btn-block disabled",
      });
      const params = {
        mobile: this.state.data.mobile,
        type: this.props.accountFor === "guardian" ? 2 : 1,
      };
      this.props.mobileAvailability(params, this.callback);
    }
  };

  callback = (res) => {
    if (res.status === 200) {
      const params = { mobile: this.state.data.mobile, type: "doctor" };
      this.props.sendOtp(params, this.otpCallBack);
    } else {
      this.setState({
        loader: false,
        btnClass: "btn btn-lg btn-primary btn-block",
      });
      toast(<AlertError message={res.data.error} />);
    }
  };

  otpCallBack = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />);
      this.props.updateStep(null, 3);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        mobile: this.state.data.mobile,
        DOB: this.state.data.DOB,
        type: 1,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head mb-4">
              <h2>Let's Get You Started</h2>
              <p className="login-text">
                Have an account? <Link to="sign-in">Log In</Link>
              </p>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-box">
                <div className="form-group">
                  {this.renderInput("mobile", "Mobile Number")}
                </div>

                <div className="form-group">
                  <SubmitBtn
                    label="Continue"
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
});
export default withRouter(connect(null, mapDispatchToProps)(VerifyMobile));
