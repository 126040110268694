import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { getDoctor, loadDoctorProfileByUsername } from '../../../store/doctor'
import { getMisc, loadConstant } from '../../../store/misc'
import Footer from '../../home/footer'
import Header from '../../home/header'
import imgTick from '../../../include/images/img-tick.svg'
import locationImg from '../../../include/images/location-icon.svg'
import awLogo from '../../../include/images/award-logo.svg'
import logo from '../../../include/images/yes-mindy-color-logo-small.png'
import { toast } from 'react-toastify'
import AlertError from '../../common/alertError'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import copyImg from '../../../include/images/feather-copy.svg'
import OrderReview from '../../patient/doctor/appointment/orderReview'
import LoadingBar from 'react-top-loading-bar'
import crossIcon from '../../../include/images/cross.svg'
import TypeSwitch from './../../patient/doctor/appointment/typeSwitch'
import DateTimeSelection from './../../patient/doctor/appointment/dateTimeSelection'
import {
  createOrder,
  getAppointment,
  loadDate,
  loadSlot,
  rescheduleAppointment,
  updateOrder,
  setAppointmentObjData,
} from '../../../store/appointment'
import PostAppointment from '../../patient/dashboard/postAppointment'

import arrowtr from './../../../include/images/arrow-tr.svg'
import { getPatient, loadPatientProfile } from '../../../store/patient'
import { Link } from 'react-router-dom'
import AlertSuccess from '../../common/alertSuccess'
import { WatiScript } from '../../../include/js/wati'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const ref = React.createRef(null)

class Profile extends Component {
  state = {
    patient: localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))
      : false,

    page: 1,
    doctorProfile: 0,
    selectedDate: '',
    selectedTime: '',
    preview: false,
    loading: false,
    orderDetail: {},
    apType: 'clinic',
    sideBar: '',
    Psychiatrist: '',
    Psychologist: '',
    appointId: '',
    loadingPage: false,
  }

  onBook = e => {
    e.preventDefault()
    localStorage.setItem('redirectProfile', this.props.location.pathname)
    this.props.history.push(
      'patient/appointment/sign-in/' + this.props.match.params.username
    )
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (
  //     !this.props.getPatient.loading &&
  //     this.props.getPatient.loading !== prevProps.getPatient.loading
  //   ) {
  //     const { Psychologist, Psychiatrist } = this.props.getPatient.profile;
  //     this.setState({ Psychologist, Psychiatrist }, () =>
  //       this.props.loadDoctor(this.arrangeParam())
  //     );
  //   }
  // };

  payNow = e => {
    e.preventDefault()
    if (this.state.apType === 'clinic') {
      const payLoad = {
        appointment_time: this.state.selectedTime,
        doctor_id: this.props.getDoctor.profile._id,
        type: this.state.apType,
      }

      this.props.createOrder(payLoad, this.callBackOrderLogin)
    } else {
      const res = this.state.orderDetail
      const patientData = JSON.parse(localStorage.getItem('patient'))
      var options = {
        key: process.env.REACT_APP_REZORPAY,
        amount: res.rpay.amount_due * 100,
        currency: 'INR',
        name: 'YesMindy',
        description: 'Appointment',
        image:
          process.env.REACT_APP_S3URL +
          'website/yes-mindy-color-logo-small.png',
        order_id: res.rpay.id,
        handler: response => {
          this.props.updateOrder(
            { payment_info: response },
            this.callBackPayment
          )
          console.log('paymentsuccess', response)
        },
        prefill: {
          name: patientData.first_name + ' ' + patientData.last_name,
          email: patientData.email,
          contact: patientData.mobile,
        },
        notes: {
          address: 'Appointment',
        },
        theme: {
          color: '#621ac0',
        },
      }
      var rzp1 = new window.Razorpay(options)
      rzp1.on('payment.failed', function (response) {
        console.log('paymentfailed', response)
      })
      rzp1.open()
    }
  }
  callBackPayment = res => {
    this.props.loadPatientProfile()
    const { patient } = this.state
    patient[this.props.getDoctor.profile.field_type.name] =
      this.props.getDoctor.profile._id
    localStorage.setItem('patient', JSON.stringify(patient))
    this.props.loadPatientProfile()
    this.removeBodyClass()
    if (res.status === 200) {
      this.props.history.push('/patient/appointment-success')
    } else {
      toast(<AlertError message={'Something went wrong.'} />)
    }
  }
  callBackReschedule = res => {
    if (res.status === 200) {
      toast(<AlertSuccess message={'Successfully rescheduled.'} />)
      this.props.history.push('/patient/dashboard')
    } else {
      toast(<AlertError message={res.error} />)
    }
  }

  doSubmit = type => {
    this.setState({ loading: true })

    if (!this.state.patient) {
      this.props.setAppointmentObjData({
        selectedTime: this.state.selectedTime,
        type,
      })
      localStorage.setItem('redirectProfile', this.props.location.pathname)
      this.props.history.push(
        '/patient/appointment/sign-in/' + this.props.match.params.username
      )
    } else {
      if (this.state.appointId) {
        const payLoad = {
          appointment_time: this.state.selectedTime,
        }
        this.props.rescheduleAppointment(
          this.state.appointId,
          payLoad,
          this.callBackReschedule
        )
      } else {
        if (type === 'clinic') {
          this.setState(
            {
              preview: true,
              loading: false,
              apType: type,
              orderDetail: {
                ret: {
                  appointment_time: this.state.selectedTime,
                  appointment_type: 'clinic',
                  consultation_charges: Number(
                    this.props.getDoctor.profile.schedules.clinicFee
                  ),
                },
              },
              sideBar: 'appointment',
            },
            () => {
              document.body.classList.add('toggled-tab')
            }
          )
        } else {
          const payLoad = {
            appointment_time: this.state.selectedTime,
            doctor_id: this.props.getDoctor.profile._id,
            type: type,
          }

          this.props.createOrder(payLoad, this.callBackOrder)
        }
      }
    }
  }

  changeTime = time => {
    this.setState({ selectedTime: time })
  }

  selectDate = (type, date) => {
    this.setState({ selectedDate: date, selectedTime: '' })
    this.props.loadSlot(this.props.getDoctor.profile._id, date, type)
  }
  removeBodyClass = e => {
    if (e) e.preventDefault()
    document.body.className = 'p-0'
  }
  changeSwitch = type => {
    this.setState({ apType: type, selectedDate: '' })
  }

  changeBodyClass = (e, nameClass) => {
    if (e) e.preventDefault()
    this.setState({ selectedDate: '', selectedTime: '', preview: false })
    document.body.classList.add(nameClass)
    this.props.loadDate()
  }

  componentDidMount = () => {
    if (this.state.patient) {
      this.setState({ loadingPage: true })
      this.props.loadPatientProfile(this.callBackPatient)
    }
    if (this.props.history.location.appointId) {
      this.setState(
        {
          apType: this.props.history.location.appointment_type,
          sideBar: 'appointment',
          appointId: this.props.history.location.appointId,
        },
        () => {
          this.changeBodyClass(null, 'toggled-tab')
        }
      )
    }

    const { Psychologist, Psychiatrist } = this.state.patient
    this.setState({ Psychologist, Psychiatrist })
    this.props.loadConstant()
    this.props.loadDoctorProfileByUsername(this.props.match.params.username)
  }

  callBackPatient = res => {
    if (res.data) {
      const patientData =
        this.props.getDoctor?.profile?.field_type?.name === 'Psychologist'
          ? res.data?.psychologist
          : res.data?.psychiatrist

      console.log('patientData', patientData)

      if (
        this.props.getAppointment.appointmentObj.selectedTime &&
        (patientData._id === this.props.getDoctor.profile._id ||
          !patientData?._id)
      ) {
        if (this.props.getAppointment.appointmentObj.type === 'clinic') {
          this.setState(
            {
              loadingPage: false,
              preview: true,
              loading: false,
              apType: this.props.getAppointment.appointmentObj.type,
              selectedTime:
                this.props.getAppointment.appointmentObj.selectedTime,
              orderDetail: {
                ret: {
                  appointment_time:
                    this.props.getAppointment.appointmentObj.selectedTime,
                  appointment_type: 'clinic',
                  consultation_charges:
                    this.props.getDoctor.profile.schedules.clinicFee,
                },
              },
              sideBar: 'appointment',
            },
            () => {
              document.body.classList.add('toggled-tab')
            }
          )
        } else {
          const payLoad = {
            appointment_time:
              this.props.getAppointment.appointmentObj.selectedTime,
            doctor_id: this.props.getDoctor.profile._id,
            type: this.props.getAppointment.appointmentObj.type,
          }

          this.props.createOrder(payLoad, this.callBackOrderLogin)
        }
      } else {
        this.setState({
          loadingPage: false,
        })
      }
    }
  }

  callBackOrder = res => {
    if (this.state.apType === 'clinic') {
      this.props.loadPatientProfile()
      const { patient } = this.state
      patient[this.props.getDoctor.profile.field_type.name] =
        this.props.getDoctor.profile._id
      localStorage.setItem('patient', JSON.stringify(patient))
      this.props.loadPatientProfile()
      this.removeBodyClass()
      if (res.status === 200) {
        this.props.history.push('/patient/appointment-success')
      } else {
        toast(<AlertError message={'Something went wrong.'} />)
      }
    } else {
      this.setState({
        preview: true,
        loading: false,
        orderDetail: res.data,
      })
    }
  }

  callBackOrderLogin = res => {
    if (this.state.apType === 'clinic') {
      this.props.loadPatientProfile()
      const { patient } = this.state

      patient[this.props.getDoctor.profile.field_type.name] =
        this.props.getDoctor.profile._id
      localStorage.setItem('patient', JSON.stringify(patient))

      this.props.loadPatientProfile()
      this.removeBodyClass()
      if (res.status === 200) {
        this.props.history.push('/patient/appointment-success')
      } else {
        toast(<AlertError message={'Something went wrong.'} />)
      }
    } else {
      this.setState(
        {
          loadingPage: false,
          preview: true,
          loading: false,
          orderDetail: res.data,
          sideBar: 'appointment',
        },
        () => {
          document.body.classList.add('toggled-tab')
        }
      )
    }
  }

  setPatient = data => {
    this.setState({ patient: data })
  }
  changePreview = preview => {
    this.setState({ preview })
  }

  render() {
    const { constants, blogCategory, blog } = this.props.getMisc
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants
    const {
      _id,
      documents_url,
      image_url,
      name,
      graduation,
      post_graduation,
      field_type,
      total_experience,
      practice_preferences,
      clinic_details,
      schedules,
      associations,
      award,
      publication,
      certification,
      super_speciality,
    } = this.props.getDoctor.profile
    const patientData =
      field_type?.name === 'Psychologist'
        ? this.props.getPatient.psychologist
        : this.props.getPatient.psychiatrist

    return (
      <>
        {/* <Helmet>
          <script id='wati'>{WatiScript}</script>
        </Helmet> */}
        <LoadingBar color='#621ac0' ref={ref} shadow={true} />
        <Header
          setPatient={this.setPatient}
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />

        <div className='tab-sidebar'>
          {this.state.sideBar === 'appointment' && (
            <div className='side-pannel-box'>
              {this.state.loadingPage ? (
                <h4>Loading ....</h4>
              ) : (
                <>
                  {this.state.preview && (
                    <OrderReview
                      changePreview={this.changePreview}
                      doctorProfile={this.state.doctorProfile}
                      getDoctor={{
                        doctors: { data: [this.props.getDoctor.profile] },
                      }}
                      months={months}
                      orderDetail={this.state.orderDetail}
                      formatAMPM={this.formatAMPM}
                      payNow={this.payNow}
                      clinic={this.state.apType === 'clinic' ? true : false}
                    />
                  )}

                  {!this.state.preview && (
                    <React.Fragment>
                      <div className='tab-sidebar-top d-flex'>
                        <a
                          href='#!'
                          className='close'
                          onClick={e => this.removeBodyClass(e)}>
                          <div className='icon-img'>
                            <img src={arrowtr} alt='' />
                          </div>
                        </a>
                        {this.props.getDoctor.profileLoading ? (
                          <h4>Loading ....</h4>
                        ) : (patientData &&
                            patientData._id ===
                              this.props.getDoctor.profile._id) ||
                          (patientData &&
                            Object.keys(patientData).length === 0) ? (
                          <div>
                            <div className='icon-text'>
                              <h4>
                                {this.state.apType === 'clinic'
                                  ? 'Book a slot for Clinic visit'
                                  : 'Book a slot for Online visit'}{' '}
                              </h4>
                              {this.props.getDoctor.profile.clinic_details && (
                                <div className='pi-location d-none d-flex align-items-center'>
                                  <div className='mr-3'>
                                    <img src='include/images/pin.svg' alt='' />
                                  </div>
                                  <span>
                                    {this.props.getDoctor.profile.clinic_details
                                      .city +
                                      ', ' +
                                      this.props.getDoctor.profile
                                        .clinic_details.state}
                                  </span>
                                  <span></span>
                                </div>
                              )}
                            </div>
                            <div className='pannel-top'>
                              <a
                                href='#!'
                                className='close'
                                onClick={e => this.removeBodyClass(e)}>
                                <img src={crossIcon} alt='' />
                              </a>
                            </div>
                            <div className='pannel-tab-box'>
                              {!this.state.appointId && (
                                <TypeSwitch
                                  schedules={schedules}
                                  changeSwitch={this.changeSwitch}
                                  loadDate={this.props.loadDate}
                                  apType={this.state.apType}
                                />
                              )}
                              <DateTimeSelection
                                apType={this.state.apType}
                                key={
                                  this.state.selectedTime +
                                  this.state.selectedDate
                                }
                                getDoctor={{
                                  doctors: {
                                    data: [this.props.getDoctor.profile],
                                  },
                                }}
                                selectDate={this.selectDate}
                                selectedTime={this.state.selectedTime}
                                selectedDate={this.state.selectedDate}
                                months={months}
                                getAppointment={this.props.getAppointment}
                                changeTime={this.changeTime}
                                formatAMPM={this.formatAMPM}
                                keyId={1}
                                doSubmit={this.doSubmit}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div style={{ marginTop: 50 }}>
                              <div className='pb-3'>
                                You already linked with Below doctor
                              </div>
                              <PostAppointment
                                newDoctor={true}
                                type={field_type?.name}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {!this.props.getDoctor.profile.name &&
          !this.props.getDoctor.profileLoading && (
            <div class='outer-container'>
              <div class='content-container doctor-profile-container'>
                <div class='container container1'>
                  <div class='row'>
                    <div class='col-md-12'>No active profile found</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {this.props.getDoctor.profile.name && (
          <div class='outer-container'>
            <div class='content-container doctor-profile-container'>
              <div class='container container1'>
                <div class='row'>
                  <div class='col-md-4'>
                    <div class='doc-img border-radius overflow-hidden'>
                      <img
                        src={process.env.REACT_APP_S3URL + image_url}
                        alt=''
                      />
                    </div>{' '}
                    {documents_url && (
                      <div class='registration-box d-flex align-items-center'>
                        <img src={imgTick} alt='' />{' '}
                        <span>
                          Registration No. :{' '}
                          {documents_url.registration_license.licence_no}
                        </span>
                      </div>
                    )}
                  </div>
                  <div class='col-md-8'>
                    <div class='doc-intro-box d-flex  justify-content-between'>
                      <div class='doc-intro-desc font-montserrat'>
                        <h5>{name}</h5>
                        <h6>
                          {graduation && graduation.education}
                          {post_graduation && ', ' + post_graduation.education}
                          {super_speciality &&
                            super_speciality.education &&
                            ', ' + super_speciality.education}
                        </h6>
                        <h6>{field_type.name}</h6>
                        {/* <h6>
                          {super_speciality &&
                            super_speciality.education &&
                            super_speciality.education}

                          {super_speciality &&
                          super_speciality.education &&
                          super_speciality.college_university
                            ? " (" + super_speciality.college_university + ")"
                            : ""}
                        </h6> */}
                        <h6>
                          {total_experience} Year{total_experience > 1 && 's'}{' '}
                          Experience
                        </h6>
                        {practice_preferences.language.length > 0 && (
                          <h6>
                            Speaks:{' '}
                            {practice_preferences.language.map((e, i) => (
                              <>
                                <span>{e}</span>
                                {practice_preferences.language.length - 1 !==
                                  i && ', '}
                              </>
                            ))}
                          </h6>
                        )}
                        <div class='d-flex align-items-center'>
                          <div class='location-pin-icon'>
                            <img src={locationImg} alt='' />
                          </div>{' '}
                          <span>{clinic_details.city}</span>
                        </div>
                        {field_type.name === 'Psychiatrist' ||
                        field_type.name === 'Psychologist' ? (
                          <div class='doc-fee-desc'>
                            {schedules.time && (
                              <>
                                {schedules.clinicFee &&
                                schedules.clinicFee > 0 ? (
                                  <div class='d-flex align-items-center'>
                                    {' '}
                                    <strong>Physical Fee:</strong>
                                    <ul class='fee-list d-flex align-items-center'>
                                      <li>₹ {schedules.clinicFee}</li>
                                      <li>{schedules.time} mins</li>
                                    </ul>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {schedules.onlineFee &&
                                schedules.onlineFee > 0 ? (
                                  <div class='d-flex align-items-center'>
                                    {' '}
                                    <strong>Online Fee:</strong>
                                    <ul class='fee-list d-flex align-items-center'>
                                      <li>₹ {schedules.onlineFee}</li>
                                      <li>{schedules.time} mins</li>
                                    </ul>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                            <div class='appointment-btn mb-0'>
                              {patientData &&
                              patientData._id &&
                              patientData._id !==
                                this.props.getDoctor.profile._id ? (
                                <>
                                  <div style={{ marginTop: 50 }}>
                                    <div className='pb-3'>
                                      You already linked with Below doctor
                                    </div>
                                    <PostAppointment
                                      newDoctor={true}
                                      type={field_type?.name}
                                    />
                                  </div>
                                </>
                              ) : schedules.onlineFee &&
                                (field_type.name === 'Psychiatrist' ||
                                  field_type.name === 'Psychologist') ? (
                                <>
                                  {(!this.props.getPatient.profile[
                                    field_type.name
                                  ] ||
                                    this.props.getPatient.profile[
                                      field_type.name
                                    ] === _id) && (
                                    <a
                                      disabled={
                                        //!this.state.patient ||
                                        this.state[field_type.name] !== '' &&
                                        this.state[field_type.name] === _id
                                      }
                                      href='#!'
                                      class={
                                        //!this.state.patient ||
                                        this.state[field_type.name] !== '' &&
                                        this.state[field_type.name] === _id &&
                                        !this.state.patient
                                          ? 'btn btn-primary disabled '
                                          : 'btn btn-primary'
                                      }
                                      onClick={e => {
                                        this.setState(
                                          {
                                            sideBar: 'appointment',
                                          },
                                          () => {
                                            this.changeBodyClass(
                                              e,
                                              'toggled-tab'
                                            )
                                            this.changeSwitch('online')
                                          }
                                        )
                                      }}>
                                      Book an appointment{' '}
                                    </a>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        ) : (
                          <div class='doc-fee-desc'>
                            <div class='appointment-btn mb-0'>
                              <Link
                                className='btn btn-primary'
                                to='/patient/chat'>
                                Chat
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class='stats-box'>
                        <ul class='stats-list'>
                          {award.length > 0 && (
                            <li>
                              <h6>Awards</h6>
                              <p>{award.length}</p>
                            </li>
                          )}
                          {certification.length > 0 && (
                            <li>
                              <h6>Certification</h6>
                              <p>{certification.length}</p>
                            </li>
                          )}
                          {publication.length > 0 && (
                            <li>
                              <h6>Publication</h6>
                              <p>{publication.length}</p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='profile-card-container'>
              <div class='container container1'>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='profile-card-content d-lg-flex flex-lg-wrap'>
                      <div class='pc-content-left'>
                        <div class='profile-card-box border-radius'>
                          <div class='profile-upper'>
                            <h5>A LITTLE ABOUT ME</h5>
                            <p>
                              Hello, I am {name} and I am a {field_type.name}{' '}
                              currently practicing from {clinic_details.city}{' '}
                              with {total_experience} years of experience. I am
                              a trained teleconsultant and use technology along
                              with my clinical acumen for effective results.
                            </p>
                            <p>{practice_preferences.message_to_patient}</p>
                            <p>{practice_preferences.about_me}</p>
                          </div>
                          {practice_preferences.therapy &&
                            practice_preferences.therapy.length > 0 && (
                              <div class='profile-mid'>
                                <h5>Preferred Therapy Mode</h5>
                                {practice_preferences.therapy.map(e => (
                                  <p>{e}</p>
                                ))}
                              </div>
                            )}
                          <div class='profile-lower font-montserrat d-flex align-items-center justify-content-center'>
                            <h4>
                              <a href='#!'>
                                {process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  this.props.match.params.username}
                              </a>
                            </h4>
                            <div class='pl-copy-link'>
                              <CopyToClipboard
                                text={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  this.props.match.params.username
                                }>
                                <a href='#!' onClick={e => e.preventDefault()}>
                                  <img src={copyImg} alt='' />
                                </a>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                        {clinic_details.city && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>CLINIC ADDRESS</h5>
                            </div>
                            <div class='pcb-body'>
                              <ul class='pcb-query-list'>
                                <li>
                                  <div class='pcb-query-icon'>
                                    <img src={locationImg} alt='' />
                                  </div>
                                  <div class='pcb-query-text'>
                                    <h6>
                                      {clinic_details.name
                                        ? clinic_details.name
                                        : clinic_details.city +
                                          ', ' +
                                          clinic_details.state +
                                          ' - ' +
                                          clinic_details.zipcode}
                                    </h6>
                                    {clinic_details.name && (
                                      <p>
                                        {clinic_details.address +
                                          ', ' +
                                          clinic_details.city +
                                          ', ' +
                                          clinic_details.state +
                                          ' - ' +
                                          clinic_details.zipcode}
                                      </p>
                                    )}
                                  </div>
                                </li>
                                {clinic_details.phone && (
                                  <li>
                                    <div class='pcb-query-icon'>
                                      <img
                                        src='include/images/feather-phone-call.svg'
                                        alt=''
                                      />
                                    </div>
                                    <div class='pcb-query-text'>
                                      <h6>
                                        Call{' '}
                                        <a
                                          href={
                                            'tel:+91' - +clinic_details.phone
                                          }>
                                          +91-{clinic_details.phone}
                                        </a>
                                      </h6>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                        {associations.length > 0 && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>I’M AFFILIATED WITH </h5>
                            </div>
                            <div class='pcb-body'>
                              <ul>
                                {associations.filter(
                                  e => e.associationstype === 'Hospital'
                                ).length > 0 && (
                                  <li>
                                    <h6>Hospitals</h6>
                                    {associations
                                      .filter(
                                        e => e.associationstype === 'Hospital'
                                      )
                                      .map(ed => (
                                        <p>{ed.associationsvalue}</p>
                                      ))}
                                  </li>
                                )}
                                {associations.filter(
                                  e => e.associationstype === 'Organisation'
                                ).length > 0 && (
                                  <li>
                                    <h6>Organisations</h6>
                                    {associations
                                      .filter(
                                        e =>
                                          e.associationstype === 'Organisation'
                                      )
                                      .map(ed => (
                                        <p>{ed.associationsvalue}</p>
                                      ))}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>

                      <div class='pc-content-right'>
                        {practice_preferences.interest.length > 0 && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>AREAS OF INTEREST</h5>
                            </div>
                            <div class='pcb-body'>
                              <ul>
                                {practice_preferences.interest.map(e => (
                                  <li>{e}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}

                        {practice_preferences.expertise.length > 0 && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>I SPECIALIZE IN</h5>
                            </div>
                            <div class='pcb-body'>
                              <ul>
                                {practice_preferences.expertise.map(e => (
                                  <li>{e}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {award.length > 0 && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>AWARDS</h5>
                            </div>
                            <div class='pcb-body'>
                              <ul class='award-list'>
                                {award.map(e => (
                                  <li>
                                    <div class='award-img-box'>
                                      <img src={awLogo} alt='' />
                                    </div>
                                    <div class='award-text-desc'>
                                      <h6>{e.title}</h6>
                                      <p>{e.year}</p>
                                    </div>
                                  </li>
                                ))}
                              </ul>{' '}
                              {/* <a href="#!" class="showall-btn">
                              Show all
                            </a> */}
                            </div>
                          </div>
                        )}

                        {publication.length > 0 && (
                          <div class='profile-card-box border-radius'>
                            <div class='pcb-head'>
                              <h5>I’VE PUBLISHED</h5>
                            </div>
                            <div class='pcb-body'>
                              <ul>
                                {publication.map(e => (
                                  <li>
                                    <h6>{e.title}</h6>
                                    <p>
                                      {e.journal}({e.year})
                                    </p>
                                  </li>
                                ))}
                              </ul>{' '}
                              {/* <a href="#!" class="showall-btn">
                              Show all
                            </a> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
        <div className='overlay' onClick={e => this.removeBodyClass(e)}></div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  getMisc: getMisc(state),
  getDoctor: getDoctor(state),
  getAppointment: getAppointment(state),
  getPatient: getPatient(state),
})

const mapDispatchToProps = dispatch => ({
  loadSlot: (drId, date, type) => dispatch(loadSlot(drId, date, type)),
  rescheduleAppointment: (id, data, callback) =>
    dispatch(rescheduleAppointment(id, data, callback)),
  createOrder: (data, callback) => dispatch(createOrder(data, callback)),
  updateOrder: (data, callback) => dispatch(updateOrder(data, callback)),
  loadPatientProfile: (callback = null) =>
    dispatch(loadPatientProfile(callback)),

  loadDate: () => dispatch(loadDate()),
  loadConstant: () => dispatch(loadConstant()),
  loadDoctorProfileByUsername: userName =>
    dispatch(loadDoctorProfileByUsername(userName)),
  setAppointmentObjData: data => dispatch(setAppointmentObjData(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
