import React, { Component } from "react";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

export default class Blog extends Component {
  render() {
    return (
      <div className="content-container pt-0">
        <div className="container container1">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">
              {window.location.pathname === "/clinical-psychosocial" && (
                <div class="heading text-center">
                  <h4>
                    Psychosocial <span>Insights</span>
                  </h4>
                </div>
              )}
              {window.location.pathname === "/clinical-psychologist" && (
                <div class="heading text-center">
                  <h4>
                    Psychology <span>Insights</span>
                  </h4>
                </div>
              )}
              {window.location.pathname === "/clinical-psychiatrist" && (
                <div class="heading text-center">
                  <h4>
                    Psychiatry <span>Insights</span>
                  </h4>
                </div>
              )}
              {window.location.pathname === "/clinical-counsellor" && (
                <div class="heading text-center">
                  <h4>
                    Dive Into <span>Counseling</span>
                  </h4>
                </div>
              )}

              <ul className="grid-list d-flex flex-wrap">
                {this.props.blog.map((e, i) => (
                  <li key={i} className="grid-item">
                    <Link
                      to={{ pathname: "/blog-detail/" + e.url }}
                      style={{ display: "unset" }}
                    >
                      <div className="grid-box">
                        <div className="grid-img">
                          <img
                            src={
                              process.env.REACT_APP_S3URL + e.featured_image_url
                            }
                            alt=""
                          />
                        </div>
                        <div className="grid-disc">
                          <h6>{HtmlParser(e.title)}</h6>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
