import React, { Component } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPlan, loadListPlanPat } from "../../../../store/treatmentPlan";
import { getPatient } from "../../../../store/patient";
import noteIcon from "../../../../include/images/note-icon.svg";

class Plan extends Component {
  state = {
    loading: true,
  };
  componentDidMount = () => {
    this.props.loadListPlanPat(() => {
      this.props.toggleLoading();
    });
  };

  //   componentDidUpdate = (prevProps) => {
  //     if (this.props.profile && this.props.profile != prevProps.profile) {
  //       this.props.loadListPlanPat(this.props.profile._id);
  //     }
  //   };

  render() {
    var psychiatrist;
    var psychologist;
    this.props.listPat &&
      this.props.listPat.map((e) => {
        if (e && e.field_type == "Psychiatrist") {
          psychiatrist = e;
        } else if (e && e.field_type == "Psychologist") {
          psychologist = e;
        }
      });

    return (
      <div class="content-box-inner">
        {!psychiatrist && !psychologist ? (
          <p style={{ textAlign: "left" }}>
            Treatment plan is curated by our experts as per your complaints,
            social settings, psychology framework and life scenario.
            <br /> It will help you know the path to recovery ahead and plan
            accordingly. It will soon be shared.{" "}
          </p>
        ) : (
          <div class="custom-accordion">
            {psychiatrist ? (
              <div class="ca-pannel">
                <input
                  class="ac-input"
                  id="ac-1"
                  name="accordion-1"
                  type="checkbox"
                />
                <label class="ac-label" for="ac-1">
                  <div class="ac-lable-content d-flex align-items-center justify-content-between">
                    <div class="ac-label-left d-flex align-items-center">
                      <div class="ac-label-img">
                        <img src={noteIcon} alt="" />
                      </div>
                      <div class="ac-label-text">
                        <h3>Psychiatrist Treatment Plan</h3>
                        {psychiatrist != undefined && (
                          <p>
                            {psychiatrist &&
                              psychiatrist.doctor_id &&
                              psychiatrist.doctor_id.name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="ac-label-right">
                      {(psychiatrist && psychiatrist.updated_at) ||
                      (psychiatrist && psychiatrist.created_at) ? (
                        <>
                          <p>Last Updated:</p>
                          <h4>
                            {moment(
                              psychiatrist && psychiatrist.updated_at
                                ? psychiatrist.updated_at
                                : psychiatrist.created_at
                            ).format("DD MMM, YYYY")}
                          </h4>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <i></i>
                </label>
                <div class="article ac-content">
                  {psychiatrist ? (
                    <>
                      {" "}
                      <h6>Primary problems that have been noticed are</h6>
                      {psychiatrist.primary_problem ? (
                        psychiatrist.primary_problem.map((e, i) => (
                          <p>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>There is also a secondary problem of</h6>
                      {psychiatrist.secondary_problem ? (
                        psychiatrist.secondary_problem.map((e, i) => (
                          <p>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>I would like to achieve the following with you</h6>
                      {psychiatrist.achieve ? (
                        psychiatrist.achieve.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>Technique I would use</h6>
                      {psychiatrist.technique ? (
                        psychiatrist.technique.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6> Medical plan I would like to use</h6>
                      {psychiatrist.medical_plan ? (
                        psychiatrist.medical_plan.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>Conduct following assessments</h6>
                      {psychiatrist.assesment ? (
                        psychiatrist.assesment.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      {psychiatrist.appointment_advise &&
                        psychiatrist.appointment_advise.days != null &&
                        psychiatrist.appointment_advise.session != null && (
                          <h6>{`I advise appointments every ${
                            psychiatrist.appointment_advise &&
                            psychiatrist.appointment_advise.days
                          } days until ${
                            psychiatrist.appointment_advise &&
                            psychiatrist.appointment_advise.session
                          } sessions`}</h6>
                        )}
                      <h6>I would like to Recommend or advise</h6>
                      {psychiatrist.recomendation ? (
                        <p>{psychiatrist.recomendation}</p>
                      ) : (
                        <p>NIL</p>
                      )}
                    </>
                  ) : (
                    <p>No Record Found</p>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {psychologist ? (
              <div class="ca-pannel">
                <input
                  class="ac-input"
                  id="ac-2"
                  name="accordion-1"
                  type="checkbox"
                />
                <label class="ac-label" for="ac-2">
                  <div class="ac-lable-content d-flex align-items-center justify-content-between">
                    <div class="ac-label-left d-flex align-items-center">
                      <div class="ac-label-img">
                        <img src={noteIcon} alt="" />
                      </div>
                      <div class="ac-label-text">
                        <h3>Psychologist Treatment Plan</h3>
                        {psychologist && (
                          <p>
                            {psychologist &&
                              psychologist.doctor_id &&
                              psychologist.doctor_id.name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div class="ac-label-right">
                      {(psychologist && psychologist.updated_at) ||
                      (psychologist && psychologist.created_at) ? (
                        <>
                          <p>Last Updated:</p>
                          <h4>
                            {moment(
                              psychologist && psychologist.updated_at
                                ? psychologist.updated_at
                                : psychologist.created_at
                            ).format("DD MMM, YYYY")}
                          </h4>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <i></i>
                </label>
                <div class="article ac-content">
                  {psychologist ? (
                    <>
                      {" "}
                      <h6>Primary problems that have been noticed are</h6>
                      {psychologist.primary_problem ? (
                        psychologist.primary_problem.map((e, i) => (
                          <p>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>There is also a secondary problem of</h6>
                      {psychologist.secondary_problem ? (
                        psychologist.secondary_problem.map((e, i) => (
                          <p>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>I would like to achieve the following with you</h6>
                      {psychologist.achieve ? (
                        psychologist.achieve.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>Technique I would use</h6>
                      {psychologist.technique ? (
                        psychologist.technique.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6> Medical plan I would like to use</h6>
                      {psychologist.medical_plan ? (
                        psychologist.medical_plan.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      <h6>Conduct following assessments</h6>
                      {psychologist.assesment ? (
                        psychologist.assesment.map((e, i) => (
                          <p key={i}>{`${i + 1}. ${e}`}</p>
                        ))
                      ) : (
                        <p>NIL</p>
                      )}
                      {psychologist.appointment_advise &&
                        psychologist.appointment_advise.days != null &&
                        psychologist.appointment_advise.session != null && (
                          <h6>{`I advise appointments every ${
                            psychologist.appointment_advise &&
                            psychologist.appointment_advise.days
                          } days until ${
                            psychologist.appointment_advise &&
                            psychologist.appointment_advise.session
                          } sessions`}</h6>
                        )}
                      <h6>I would like to Recommend or advise</h6>
                      {psychologist.recomendation ? (
                        <p>{psychologist.recomendation}</p>
                      ) : (
                        <p>NIL</p>
                      )}
                    </>
                  ) : (
                    <p>No Record Found</p>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listPat: getPlan(state).listPat,
  profile: getPatient(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  loadListPlanPat: (callback) => dispatch(loadListPlanPat(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plan));
