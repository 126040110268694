import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPatient } from '../../../../store/patient'
import {
  getPlan,
  loadSummaryPat,
  loadSummaryPatbyId,
} from '../../../../store/treatmentPlan'
import ReactTooltip from 'react-tooltip'
import LinesEllipsis from 'react-lines-ellipsis'

class Summary extends Component {
  state = {
    doctor_id: '',
  }
  componentDidMount = () => {
    this.props.loadSummaryPat()
  }

  loadDate = () => {
    this.props.loadSummaryPat({ doctor_id: this.state.doctor_id })
  }

  render() {
    console.log('summary', this.props.summaryPat)
    return (
      <>
        <div class="content-box-inner">
          <div class="doc-filter-head d-md-flex align-items-md-center">
            <div class="doc-filter-box d-md-flex flex-wrap align-items-center">
              <div class="filter-text-box">
                <h6>Filters:</h6>
              </div>
              <div class="filter-check-box">
                <ul class="filter-check-list">
                  <li class="filter-check-item">
                    <div class="form-group preferred-field show">
                      <div class="custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0">
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="smb"
                            name="Psychiatrist"
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: this.props.profile.Psychiatrist,
                                },
                                () => this.loadDate(),
                              )
                            }}
                            checked={
                              this.state.doctor_id ==
                              this.props.profile.Psychiatrist
                            }
                          />
                          <label class="custom-control-label" htmlFor="smb">
                            By Psychiatrist
                          </label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="mid"
                            name="Psychologist"
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: this.props.profile.Psychologist,
                                },
                                () => this.loadDate(),
                              )
                            }}
                            checked={
                              this.state.doctor_id ==
                              this.props.profile.Psychologist
                            }
                          />
                          <label class="custom-control-label" htmlFor="mid">
                            By Psychologist
                          </label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="0"
                            name="other"
                            onClick={() => {
                              this.setState(
                                {
                                  doctor_id: '',
                                },
                                () => this.loadDate(),
                              )
                            }}
                            checked={this.state.doctor_id === ''}
                          />
                          <label class="custom-control-label" htmlFor="0">
                            All
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <ul class="doc-info-list">
            {this.props.summaryPat &&
              this.props.summaryPat.map((e) => {
                const atPresentValues =
                  e.currentFollowup &&
                  e.currentFollowup.formData &&
                  e.currentFollowup.formData['Current Complaints:']
                    ? e.currentFollowup
                    : e.currentPrescription
                    ? {
                        formData: {
                          ...e.currentPrescription,
                          'Current Complaints:': e.currentPrescription
                            .chief_complaints
                            ? e.currentPrescription.chief_complaints
                            : 'N/A',
                        },
                      }
                    : null
                const sideEffectsData =
                  e.currentFollowup && e.currentFollowup.formData

                const sideEffectMedical =
                  sideEffectsData && sideEffectsData['Medicine tolerance:']
                    ? sideEffectsData['Medicine tolerance:']
                    : sideEffectsData && sideEffectsData['Medicine Tolerance:']
                    ? sideEffectsData['Medicine Tolerance:']
                    : ''
                const sideEffectValue =
                  sideEffectsData && sideEffectsData['Side Effects']
                    ? sideEffectsData['Side Effects']
                    : sideEffectsData &&
                      sideEffectsData['Side effects details (if present):']
                    ? sideEffectsData['Side effects details (if present):']
                    : ''

                return (
                  <li class="doc-info-item">
                    <div class="doc-info-box">
                      <div class="doc-info-upper d-flex flex-wrap">
                        <div class="left">
                          {`${moment
                            .utc(e.appointment_time)
                            .format('DD MMM')},`}{' '}
                          <span>{`${moment
                            .utc(e.appointment_time)
                            .format('YYYY')}`}</span>
                          <span>{`${moment
                            .utc(e.appointment_time)
                            .format('hh:mm a')}`}</span>
                        </div>
                        <div class="mid d-flex">
                          <div class="block d-flex">
                            <div class="img-block">
                              <img
                                src={
                                  e.doctor_id &&
                                  e.doctor_id.image_url &&
                                  process.env.REACT_APP_S3URL +
                                    e.doctor_id.image_url
                                }
                                alt=""
                              />
                            </div>
                            <div class="text-block">
                              <h3>{e.doctor_id && e.doctor_id.name}</h3>
                              <p>
                                {e.doctor_id &&
                                  e.doctor_id.field_type &&
                                  e.doctor_id.field_type.name}
                              </p>
                            </div>
                          </div>
                          <div class="block">
                            <ReactTooltip className="testPurple" html={true} />
                            <h6>At Present:</h6>
                            <h5
                              data-tip={`${
                                atPresentValues &&
                                atPresentValues.formData &&
                                atPresentValues.formData[
                                  'Current Complaints:'
                                ] &&
                                typeof atPresentValues.formData[
                                  'Current Complaints:'
                                ] == 'object'
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ].length > 0
                                    ? atPresentValues.formData[
                                        'Current Complaints:'
                                      ] &&
                                      atPresentValues.formData[
                                        'Current Complaints:'
                                      ].map((e) => ` ${e.label}`)
                                    : 'NA'
                                  : atPresentValues &&
                                    atPresentValues.formData &&
                                    atPresentValues.formData[
                                      'Current Complaints:'
                                    ]
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ]
                                  : 'NA'
                              }`}
                            >
                              {atPresentValues &&
                              atPresentValues.formData &&
                              atPresentValues.formData['Current Complaints:'] &&
                              typeof atPresentValues.formData[
                                'Current Complaints:'
                              ] == 'object'
                                ? atPresentValues.formData[
                                    'Current Complaints:'
                                  ].length > 0
                                  ? atPresentValues.formData[
                                      'Current Complaints:'
                                    ].length > 1
                                    ? `${
                                        atPresentValues.formData[
                                          'Current Complaints:'
                                        ][0] &&
                                        atPresentValues.formData[
                                          'Current Complaints:'
                                        ][0].label
                                      }...`
                                    : atPresentValues.formData[
                                        'Current Complaints:'
                                      ][0] &&
                                      atPresentValues.formData[
                                        'Current Complaints:'
                                      ][0].label
                                  : 'NA'
                                : atPresentValues &&
                                  atPresentValues.formData &&
                                  atPresentValues.formData[
                                    'Current Complaints:'
                                  ]
                                ? atPresentValues.formData[
                                    'Current Complaints:'
                                  ]
                                : 'NA'}
                            </h5>
                          </div>
                        </div>
                        <div class="right d-flex">
                          <div class="block">
                            <h6>Last advise:</h6>
                            <h5
                              data-tip={`${
                                e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name === 'Psychologist'
                                  ? e.prevPrescription &&
                                    e.prevPrescription.theray_method &&
                                    e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.theray_method +
                                      e.prevPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : e.prevPrescription &&
                                      e.prevPrescription.theray_method
                                    ? e.prevPrescription.theray_method
                                    : e.prevPrescription &&
                                      e.prevPrescription.therapy_technique
                                    ? e.prevPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : 'NA'
                                  : e.prevPrescription &&
                                    e.prevPrescription.medical_prescription &&
                                    e.prevPrescription.medical_prescription
                                      .length > 0 &&
                                    e.prevPrescription.medical_prescription[0]
                                      .composition
                                  ? e.prevPrescription.medical_prescription
                                      .map(
                                        (elp) =>
                                          elp.formulation +
                                          ' ' +
                                          elp.composition +
                                          ' ' +
                                          elp.dose,
                                      )
                                      .join(', ')
                                  : 'NA'
                              }`}
                            >
                              {e.doctor_id &&
                              e.doctor_id.field_type &&
                              e.doctor_id.field_type.name &&
                              e.doctor_id.field_type.name === 'Psychologist' ? (
                                <LinesEllipsis
                                  text={
                                    e.prevPrescription &&
                                    e.prevPrescription.theray_method &&
                                    e.prevPrescription.therapy_technique
                                      ? e.prevPrescription.theray_method +
                                        e.prevPrescription.therapy_technique
                                          .map((elp) => elp.label)
                                          .join(', ')
                                      : e.prevPrescription &&
                                        e.prevPrescription.theray_method
                                      ? e.prevPrescription.theray_method
                                      : e.prevPrescription &&
                                        e.prevPrescription.therapy_technique
                                      ? e.prevPrescription.therapy_technique
                                          .map((elp) => elp.label)
                                          .join(', ')
                                      : 'NIL'
                                  }
                                  maxLine="2"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="letters"
                                />
                              ) : (
                                <LinesEllipsis
                                  text={
                                    e.prevPrescription &&
                                    e.prevPrescription.medical_prescription &&
                                    e.prevPrescription.medical_prescription
                                      .length > 0 &&
                                    e.prevPrescription.medical_prescription[0]
                                      .composition
                                      ? e.prevPrescription.medical_prescription
                                          .map(
                                            (elp) =>
                                              elp.formulation +
                                              ' ' +
                                              elp.composition +
                                              ' ' +
                                              elp.dose,
                                          )
                                          .join(', ')
                                      : 'NIL'
                                  }
                                  maxLine="2"
                                  ellipsis="..."
                                  trimRight
                                  basedOn="letters"
                                />
                              )}
                            </h5>
                          </div>
                          <div class="block side-effects">
                            <ReactTooltip className="testPurple" html={true} />
                            <h6>Side effects:</h6>
                            <p
                              data-tip={`${
                                sideEffectValue &&
                                typeof sideEffectValue == 'object'
                                  ? sideEffectValue.length > 0
                                    ? sideEffectValue.length > 1
                                      ? `${
                                          sideEffectValue[0] &&
                                          sideEffectValue[0].label
                                        }...`
                                      : sideEffectValue[0] &&
                                        sideEffectValue[0].label
                                    : 'NA'
                                  : sideEffectValue && sideEffectMedical
                                  ? sideEffectMedical + ' , ' + sideEffectValue
                                  : sideEffectMedical
                                  ? sideEffectMedical
                                  : sideEffectValue
                                  ? sideEffectValue
                                  : 'NA'
                              }`}
                            >
                              {sideEffectValue &&
                              typeof sideEffectValue == 'object'
                                ? sideEffectValue.length > 0
                                  ? sideEffectValue.length > 1
                                    ? `${
                                        sideEffectValue[0] &&
                                        sideEffectValue[0].label
                                      }...`
                                    : sideEffectValue[0] &&
                                      sideEffectValue[0].label
                                  : 'NA'
                                : sideEffectValue && sideEffectMedical
                                ? sideEffectMedical + ' , ' + sideEffectValue
                                : sideEffectMedical
                                ? sideEffectMedical
                                : sideEffectValue
                                ? sideEffectValue
                                : 'NA'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="doc-info-lower d-flex flex-wrap">
                        <div class="left">
                          <div class="block">
                            <h6>Improvement:</h6>
                            <p>
                              {e.currentFollowup &&
                              e.currentFollowup.formData &&
                              e.currentFollowup.formData['Overall Improvement:']
                                ? e.currentFollowup.formData[
                                    'Overall Improvement:'
                                  ]
                                : 'NIL'}
                            </p>
                          </div>
                        </div>
                        <div class="mid d-flex">
                          <div class="block blue">
                            <h6>Current advise:</h6>
                            <p
                              data-tip={`${
                                e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name === 'Psychologist'
                                  ? e.currentPrescription &&
                                    e.currentPrescription.theray_method &&
                                    e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.theray_method +
                                      e.currentPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : e.currentPrescription &&
                                      e.currentPrescription.theray_method
                                    ? e.currentPrescription.theray_method
                                    : e.currentPrescription &&
                                      e.currentPrescription.therapy_technique
                                    ? e.currentPrescription.therapy_technique
                                        .map((elp) => elp.label)
                                        .join(', ')
                                    : 'NA'
                                  : e.currentPrescription &&
                                    e.currentPrescription
                                      .medical_prescription &&
                                    e.currentPrescription.medical_prescription
                                      .length > 0 &&
                                    e.currentPrescription
                                      .medical_prescription[0].composition
                                  ? e.currentPrescription.medical_prescription
                                      .map(
                                        (elp) =>
                                          elp.formulation +
                                          ' ' +
                                          elp.composition +
                                          ' ' +
                                          elp.dose,
                                      )
                                      .join(', ')
                                  : 'NA'
                              }`}
                            >
                              <strong>
                                {e.doctor_id &&
                                e.doctor_id.field_type &&
                                e.doctor_id.field_type.name &&
                                e.doctor_id.field_type.name ===
                                  'Psychologist' ? (
                                  <LinesEllipsis
                                    text={
                                      e.currentPrescription &&
                                      e.currentPrescription.theray_method &&
                                      e.currentPrescription.therapy_technique
                                        ? e.currentPrescription.theray_method +
                                          e.currentPrescription.therapy_technique
                                            .map((elp) => elp.label)
                                            .join(', ')
                                        : e.currentPrescription &&
                                          e.currentPrescription.theray_method
                                        ? e.currentPrescription.theray_method
                                        : e.currentPrescription &&
                                          e.currentPrescription
                                            .therapy_technique
                                        ? e.currentPrescription.therapy_technique
                                            .map((elp) => elp.label)
                                            .join(', ')
                                        : 'NIL'
                                    }
                                    maxLine="2"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                ) : (
                                  <LinesEllipsis
                                    text={
                                      e.currentPrescription &&
                                      e.currentPrescription
                                        .medical_prescription &&
                                      e.currentPrescription.medical_prescription
                                        .length > 0 &&
                                      e.currentPrescription
                                        .medical_prescription[0].composition
                                        ? e.currentPrescription.medical_prescription
                                            .map(
                                              (elp) =>
                                                elp.formulation +
                                                ' ' +
                                                elp.composition +
                                                ' ' +
                                                elp.dose,
                                            )
                                            .join(', ')
                                        : 'NIL'
                                    }
                                    maxLine="2"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                )}
                              </strong>
                            </p>
                          </div>
                        </div>
                        {/* <div class="right d-flex">
                      <div class="block green">
                        <h6>Special mention:</h6>
                        <p>
                          <strong>Started to go to office again</strong>
                        </p>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  summaryPat: getPlan(state).summaryPat,
  profile: getPatient(state).profile,
})

const mapDispatchToProps = (dispatch) => ({
  loadSummaryPat: (data) => dispatch(loadSummaryPat(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Summary))
