import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import DashboardHeader from "../../profile/common/dashboardHeader";
import DashboardLeftBar from "../../profile/common/dashboardLeftBar";
import MyJourney from "./myJourney";
import MyReport from "./myReport";
import Task from "./task";
import { withRouter } from "react-router-dom";
import Plan from "./plan";
import Tracker from "./tracker";
import Summary from "./summary";
import { getPatient } from "../../../../store/patient";

class TreatmentPlan extends Component {
  state = {
    step: 1,
    loading: false,
  };

  toggleLoading = () => {
    this.setState({ loading: false });
  };

  updateStep = (e, step) => {
    if (e) e.preventDefault();
    this.setState({ step });
  };
  componentDidMount = () => {
    document.body.classList.remove("p-0");
    document.body.classList.add("dashboard");
    if (this.props.location.hash === "#plan") {
      this.setState({ step: 2 });
    }
  };
  componentWillUnmount = () => {
    document.body.classList.remove("dashboard");
    document.body.classList.add("p-0");
  };
  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return <Tracker updateStep={this.updateStep} step={this.state.step} />;
      case 2:
        return (
          <Plan
            updateStep={this.updateStep}
            step={this.state.step}
            toggleLoading={this.toggleLoading}
          />
        );
      case 3:
        return <Summary updateStep={this.updateStep} step={this.state.step} />;

      default:
        break;
    }
  };

  render() {
    return (
      <>
        {!this.state.laoding && (
          <>
            <ReactTooltip className="testPurple" html={true} />
            <DashboardLeftBar updateStep={this.updateStep} />
            <DashboardHeader />
            <div class="dashboard-main-container">
              <div class="treatment">
                <h4>My Treatment</h4>
                <div class="chatbot-box">
                  <div class="chatbot-head d-none d-md-block">
                    <ul
                      class="nav nav-tabs nav-tabs2"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.step == 1
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tracker-tab"
                          data-toggle="tab"
                          href="#tracker"
                          role="tab"
                          aria-controls="tracker"
                          aria-selected="true"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          <b>Tracker</b>
                          <span>Tracker</span>
                          <i
                            data-tip="Coming soon feature. Soon you will be able to track your treatment     
                        and recovery."
                            class="fas fa-info-circle ml-2"
                            aria-hidden="true"
                            style={{
                              color: "rgb(24 0 88 / 50%)",
                              fontSize: "12px",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.step == 2
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="plan-tab"
                          data-toggle="tab"
                          href="#plan"
                          role="tab"
                          aria-controls="plan"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          <b>Plan</b>
                          <span>Plan</span>
                          <i
                            data-tip="Our experts are going to create a customised plan for you (Psychiatrist and Psychologist) separately. Which you can follow and stay compliant to get faster recovery."
                            class="fas fa-info-circle ml-2"
                            aria-hidden="true"
                            style={{
                              color: "rgb(24 0 88 / 50%)",
                              fontSize: "12px",
                            }}
                          ></i>
                        </a>
                      </li>
                      <li class="nav-item" role="presentation">
                        <a
                          class={
                            this.state.step == 3
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="summary-tab"
                          data-toggle="tab"
                          href="#summary"
                          role="tab"
                          aria-controls="summary"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          <b>Summary</b>
                          <span>Summary</span>
                          <i
                            data-tip="To get a summary of your treatment journey so far with all the important highlights. You and your experts will have a holistic view always."
                            class="fas fa-info-circle ml-2"
                            aria-hidden="true"
                            style={{
                              color: "rgb(24 0 88 / 50%)",
                              fontSize: "12px",
                            }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="chatbot-body">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class={
                          this.state.step === 1
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          Tracker
                        </div>
                        <div
                          className={
                            this.state.step === 1
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 1 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 2
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          Plan
                        </div>
                        <div
                          className={
                            this.state.step === 2
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 2 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 3
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          Summary
                        </div>
                        <div
                          className={
                            this.state.step === 3
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 3 && this.leftSideStep()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: getPatient(state).loading,
});

export default withRouter(connect(mapStateToProps, null)(TreatmentPlan));
