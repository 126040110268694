import React, { Component } from 'react'
import logo from '../../../../include/images/yes-mindy-main-logo-white-color.png'
import checkmark from '../../../../include/images/border-check-icon.svg'
import { Link } from 'react-router-dom'

class AppointmentSuccess extends Component {
  state = {
    profile_completed: true,
  }

  componentDidMount() {
    if (localStorage.getItem('patient')) {
      const patient = JSON.parse(localStorage.getItem('patient'))
      if (!patient.profile_completed) {
        this.setState({ profile_completed: false }, () => {
          this.id = setTimeout(() => {
            this.props.history.push('/patient/dashboard')
          }, 3000)
        })
      } else {
        this.id = setTimeout(() => {
          this.props.history.push('/patient/dashboard')
        }, 3000)
      }
    }
  }

  componentWillUnmount = () => {
    if (this.state.profile_completed) {
      clearTimeout(this.id)
    }
  }

  render() {
    return (
      <div class="member-container">
        <div class="top-logo">
          <a href="/">
            {' '}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div class="member-box d-flex login-form">
          <div class="member-left">
            <div class="ml-box">
              <h1>YesMindy</h1>
              <p class="mb-0">You are almost done</p>
            </div>
          </div>
          <div class="member-right">
            <div class="member-right-content d-md-flex align-items-center justify-content-center text-center">
              <div class="mr-box">
                <div class="form-check-icon">
                  <img src={checkmark} alt="" />
                </div>
                <h3>YesMindy - Journey To Wellness Begins</h3>
                {this.state.profile_completed ? (
                  <>
                    {/* <Link
                      to="/patient/dashboard"
                      class="btn btn-primary btn-block"
                    >
                      Proceed to Dashboard
                    </Link> */}
                  </>
                ) : (
                  <>
                    {/* <br />
                    <Link
                      to="/patient/dashboard"
                      class="btn btn-primary btn-block"
                    >
                      Complete your profile
                    </Link> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AppointmentSuccess
