import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
    name: "bio",
    initialState: {
        levelOfImpairement: [],
        bioAnswer: {},
        list: [],
        loading: false,
        answerLoading: true,
        lastFetch: null,
    },
    reducers: {
        levelOfImpairementRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },
        levelOfImpairementReceived: (followUp, action) => {
            followUp.levelOfImpairement = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        levelOfImpairementRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        getDataRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },
        getDataReceived: (followUp, action) => {
            followUp.bioAnswer = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        getDataRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
        listRequested: (followUp, action) => {
            followUp.answerLoading = true;
        },

        listReceived: (followUp, action) => {
            followUp.list = action.payload.data;
            followUp.answerLoading = false;
            followUp.lastFetch = Date.now();
        },
        listRequestFailed: (followUp, action) => {
            followUp.answerLoading = false;
        },
    },
});

export const {
    levelOfImpairementRequested,
    levelOfImpairementRequestFailed,
    levelOfImpairementReceived,
    getDataRequested,
    getDataRequestFailed,
    getDataReceived,
    listRequested,
    listReceived,
    listRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "bio-psycho-social/";

export const loadAnswerBio = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "form-data/" + id,
            onStart: getDataRequested.type,
            onSuccess: getDataReceived.type,
            onError: getDataRequestFailed.type,
        })
    );
};
export const loadlevelOfImpairement = () => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "lavel-of-impairments",
            onStart: levelOfImpairementRequested.type,
            onSuccess: levelOfImpairementReceived.type,
            onError: levelOfImpairementRequestFailed.type,
        })
    );
};
export const loadListData = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: url + "list/" + id,
            onStart: listRequested.type,
            onSuccess: listReceived.type,
            onError: listRequestFailed.type,
        })
    );
};
export const addAnswerBio = (param, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            callback,
            url: url,
            method: "post",
            data: param,
            // onSuccess: followAnswerAdded.type,
            // onError: followAnswerAddRequestFailed.type,
        })
    );
};

export const getBio = createSelector(
    (state) => state.entities.bio,
    (bio) => bio
);
