import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  getAssessment,
  loadDetailAssessment,
  loadSubmittedTask,
  loadAnswerAssessment,
  addAnswerAssessmentDoctor,
} from '../../../store/assessment'
import close from '../../../include/images/icon-close.svg'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import material from '../../../include/images/material-note.svg'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import moment from 'moment'
import { getDoctor } from '../../../store/doctor'
import { queries } from '@testing-library/react'

class Task extends Component {
  state = {
    category: '',
    appoint_id: '',
    isShow: false,
    id: '',
    title: '',
    data: {},
    doctorId: '',
    currentCategory: '',
  }
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },
      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      'height': 55,
      'minHeight': 55,
      'borderColor': state.isFocused ? '#6119c0' : '#e0e0e0',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  toggle = (id, title, appoint_id, doctorId, category) => {
    this.setState({
      isShow: !this.state.isShow,
      id: id,
      title: title,
      appoint_id: appoint_id,
      doctorId,
      currentCategory: category,
    })
  }
  toggleClose = () => {
    this.setState({
      isShow: false,
      title: '',
      id: '',
      appoint_id: '',
      currentCategory: '',
      data: {},
      is_submitted: false,
    })
  }
  componentDidMount = () => {
    this.props.loadSubmittedTask({ patient_id: this.props.id })
    if (!this.props.videoCall) document.body.className = 'dashboard fixed'
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.category != prevState.category) {
      this.props.loadSubmittedTask({
        patient_id: this.props.id,
        category: this.state.category,
      })
    }
    if (this.state.id != '' && this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        doctor_id: this.state.doctorId,
        patient_id: this.props.id,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
  }
  handleSubmit = () => {
    //e.preventDefault()
    const formData = { ...this.state.data }
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        doctor_id: this.state.doctorId,
        patient_id: this.props.id,
        assesment_id: this.state.id,
        is_submitted: this.state.is_submitted,
        formData,
      }
      console.log('call if payload', payLoad)
      this.props.addAnswerAssessment(payLoad, this.callBack)
    } else {
      payLoad = {
        doctor_id: this.state.doctorId,
        patient_id: this.props.id,
        assesment_id: this.state.id,
        formData,
      }
      console.log('call else payload', payLoad)
      this.props.addAnswerAssessment(payLoad, this.callBack)
    }
  }
  callBack = res => {
    //this.props.loadAllAssessment({ doctor_id: this.props.doctor_id })
    if (res && res.status === 200) {
      this.props.loadAnswerAssessment({
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appointment_id,
        assesment_id: this.state.id,
      })
      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        data: {},
      })
      this.toggleClose()
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        data: {},
      })
      this.toggleClose()
    }
  }
  render() {
    const { detail, assessment } = this.props
    const category = item =>
      item.assesment_id && item.assesment_id.category.name
    const result = _.groupBy(assessment, category)
    const groupArrays = Object.keys(result).map(category => {
      return {
        category,
        assess: result[category],
      }
    })

    const answer = assessment.find(
      v => v.assesment_id && v.assesment_id._id === this.state.id
    )
    const ques =
      detail.questions &&
      detail.questions.map(e =>
        _.chain(e.questions)
          .groupBy('section.name')
          .map((value, key) => ({
            section: key,
            question_group: _.chain(value)
              .groupBy('question_group.name')
              .map((value, key) => ({ question_group: key, question: value }))
              .value(),
          }))
          .value()
      )
    console.log('ques', this.state.currentCategory)
    return (
      <>
        <div className='cb-outer'>
          <div className='filter-content-box d-md-flex flex-wrap align-items-center'>
            <div className='filter-text-box'>
              <h6>Filters:</h6>
            </div>
            <div className='filter-check-box'>
              <ul className='filter-check-list'>
                <li className='filter-check-item'>
                  <div className='form-group preferred-field'>
                    <div className='custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0'>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='Assessments'
                          name='Assessment'
                          onClick={() => {
                            this.setState({ category: 'Assessments' })
                          }}
                          checked={this.state.category === 'Assessments'}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='Assessments'>
                          Assessments
                        </label>
                      </div>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='Worksheets'
                          name='Worksheet'
                          onClick={() => {
                            this.setState({ category: 'Worksheets' })
                          }}
                          checked={this.state.category === 'Worksheets'}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='Worksheets'>
                          Worksheets
                        </label>
                      </div>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='Homeworks'
                          name='Homework'
                          onClick={() => {
                            this.setState({ category: 'Homeworks' })
                          }}
                          checked={this.state.category === 'Homeworks'}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='Homeworks'>
                          Homeworks
                        </label>
                      </div>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='Psychoeducation'
                          name='Psychoeducation'
                          onClick={() => {
                            this.setState({ category: 'Psychoeducation' })
                          }}
                          checked={this.state.category === 'Psychoeducation'}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='Psychoeducation'>
                          Psychoeducation
                        </label>
                      </div>
                      <div className='custom-control custom-radio'>
                        <input
                          type='radio'
                          className='custom-control-input'
                          id='All'
                          name='All'
                          onClick={() => {
                            this.setState({ category: '' })
                          }}
                          checked={this.state.category === ''}
                        />
                        <label className='custom-control-label' htmlFor='All'>
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class='tab-pane-content-box tpc-report'>
          <div class='tpc-lower'>
            {groupArrays.length === 0 ? (
              <div className='document-content-box '>
                You can allot the task to your patient from toolbox section
              </div>
            ) : (
              <>
                {groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Assessments' ||
                    e2.category === 'Homeworks' ||
                    e2.category === 'Worksheets' ||
                    e2.category === 'Psychoeducation' ? (
                      <div className='document-content-box '>
                        <div className='tpc-month-box'>
                          <h6>{e2.category}</h6>
                        </div>
                        <ul class='new-profile-list'>
                          {e2.assess.map((e1, i) => (
                            <li class='np-item' key={i}>
                              <div class='np-box d-lg-flex align-items-center justify-content-between'>
                                <div class='np-left d-lg-flex flex-wrap align-items-center'>
                                  <div class='np-text-box'>
                                    <p className='task'>Title</p>
                                    <h5>
                                      {e1.assesment_id && e1.assesment_id.title}
                                    </h5>
                                  </div>
                                </div>
                                {!this.props.videoCall && (
                                  <>
                                    <div class='np-mid'>
                                      <p className='task'>Alloted By</p>
                                      <p>{e1.doctor_id.name}</p>
                                    </div>
                                    <div class='np-mid'>
                                      <p className='task'>Allotment</p>
                                      <p>
                                        {moment(e1.created_at).format(
                                          'DD-MM-YYYY'
                                        )}
                                      </p>
                                    </div>
                                    <div class='np-mid'>
                                      <p className='task'>Submission</p>
                                      <p>
                                        {e1.updated_at
                                          ? moment(e1.updated_at).format(
                                              'DD-MM-YYYY'
                                            )
                                          : 'Not submitted'}
                                      </p>
                                    </div>{' '}
                                  </>
                                )}
                                <div class='np-right d-flex flex-wrap'>
                                  <a
                                    href='javascript:void(0)'
                                    class='np-links'
                                    data-toggle='notes'
                                    onClick={() => {
                                      console.log('e1', e1)
                                      this.toggle(
                                        e1.assesment_id._id,
                                        e1.assesment_id.title,
                                        e1.appointment_id,
                                        e1.doctor_id._id,
                                        e2.category
                                      )
                                    }}>
                                    <img src={material} alt='' />
                                  </a>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        {/* </div>
        </div> */}
        <div
          className={
            this.state.isShow === true || this.state.isShow === true
              ? 'overlay4 show'
              : 'overlay4'
          }></div>
        <div
          className={
            this.state.isShow === true
              ? 'sidebar-box preSession opennote'
              : 'sidebar-box preSession closenote'
          }
          id='pre'>
          <div className='sidebar-head d-flex align-items-center'>
            {this.state.isShow === true && (
              <h5>
                {this.state.title}{' '}
                {detail.data && detail.data.header_text && (
                  <small>({detail.data.header_text})</small>
                )}
              </h5>
            )}
            {this.state.isShow === true ? (
              <div className='close-img ml-auto' onClick={this.toggleClose}>
                <img src={close} alt='' />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='sidebar-body'>
            {this.state.id &&
              ques &&
              ques.map((e, i) => (
                <>
                  {e.map((e1, i1) => (
                    <div className='check-box-group' key={i1}>
                      {e1.section !== 'undefined' && (
                        <label class='section'>{e1.section}</label>
                      )}
                      {e1.question_group.map(e2 => (
                        <>
                          <div class='d-flex flex-column'>
                            <label class='question_group'>
                              {e2.question_group !== 'undefined' &&
                                e2.question_group}
                            </label>
                          </div>
                          {e2.question &&
                            e2.question.map(e3 => (
                              <>
                                <>
                                  <label style={{ color: '#26262a' }}>
                                    {e3.question}
                                  </label>
                                  {e3.response_type ===
                                    'free_text_pill_box' && (
                                    <CreatableSelect
                                      noOptionsMessage={() =>
                                        'Type and press enter to add options'
                                      }
                                      isMulti
                                      classNamePrefix='select'
                                      isDisabled={false}
                                      isLoading={false}
                                      name='color'
                                      styles={this.customStyles}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      onChange={e => {
                                        if (
                                          this.state.currentCategory ===
                                          'Assessments'
                                        ) {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }
                                      }}
                                    />
                                  )}
                                  {e3.response_type ===
                                    'multiselect_dropdown' && (
                                    <Select
                                      isMulti
                                      className='basic-single'
                                      classNamePrefix='select'
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name='color'
                                      styles={this.customStyles}
                                      options={e3.options.map(ex => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      onChange={e => {
                                        if (
                                          this.state.currentCategory ===
                                          'Assessments'
                                        ) {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }
                                      }}
                                    />
                                  )}
                                  {e3.response_type === 'dropdown' && (
                                    <Select
                                      className='basic-single'
                                      classNamePrefix='select'
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name='color'
                                      styles={this.customStyles}
                                      options={e3.options.map(ex => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      onChange={e => {
                                        if (
                                          this.state.currentCategory ===
                                          'Assessments'
                                        ) {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e
                                          this.setState({ data })
                                        }
                                      }}
                                    />
                                  )}
                                  {e3.response_type === 'text' && (
                                    <input
                                      type='text'
                                      name=''
                                      className='form-control'
                                      placeholder='Write here…'
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      onChange={e => {
                                        if (
                                          this.state.currentCategory ===
                                          'Assessments'
                                        ) {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e.target.value
                                          this.setState({ data })
                                        }
                                      }}
                                    />
                                  )}
                                  {e3.response_type === 'text_area' && (
                                    <textarea
                                      style={{ padding: '10px' }}
                                      type='text'
                                      className='form-control'
                                      placeholder=''
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      onChange={e => {
                                        if (
                                          this.state.currentCategory ===
                                          'Assessments'
                                        ) {
                                          const data = { ...this.state.data }
                                          data[e3.question] = e.target.value
                                          this.setState({ data })
                                        }
                                      }}></textarea>
                                  )}

                                  <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                    {e3.response_type === 'checkbox' &&
                                      e3.options.map((e4, i2) => (
                                        <div className='form-check' key={i2}>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value={e4.title}
                                            id={e4.title + e4._id}
                                            name={e4.title + e4._id}
                                            checked={
                                              this.state.data &&
                                              this.state.data[e3.question] &&
                                              this.state.data[e3.question].find(
                                                echeck => echeck === e4.title
                                              )
                                            }
                                            onChange={event => {
                                              if (
                                                this.state.currentCategory ===
                                                'Assessments'
                                              ) {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                if (event) {
                                                  const findData =
                                                    data[e3.question] &&
                                                    data[e3.question].find(
                                                      echeck =>
                                                        echeck ===
                                                        event.target.value
                                                    )

                                                  if (findData) {
                                                    data[e3.question] = data[
                                                      e3.question
                                                    ].filter(
                                                      eqs =>
                                                        eqs !==
                                                        event.target.value
                                                    )
                                                  } else {
                                                    if (data[e3.question]) {
                                                      data[e3.question].push(
                                                        event.target.value
                                                      )
                                                    } else {
                                                      data[e3.question] = [
                                                        event.target.value,
                                                      ]
                                                    }
                                                  }

                                                  this.setState({ data })
                                                }
                                              }
                                            }}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor={e4.title + e4._id}>
                                            <span
                                              style={{
                                                marginLeft: '0.5rem',
                                              }}></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                    {e3.response_type === 'radio' &&
                                      e3.options.map((e4, i2) => (
                                        <div className='form-check' key={i2}>
                                          <input
                                            className='form-check-input'
                                            type='radio'
                                            value={e4.title}
                                            checked={
                                              this.state.data &&
                                              this.state.data[e3.question] ==
                                                e4.title
                                            }
                                            onChange={e => {
                                              if (
                                                this.state.currentCategory ===
                                                'Assessments'
                                              ) {
                                                const data = {
                                                  ...this.state.data,
                                                }
                                                data[e3.question] =
                                                  e.target.value
                                                this.setState({ data })
                                              }
                                            }}
                                            id={e3.question + e4.title}
                                            name={e3.question + e4.title}
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor={e3.question + e4.title}>
                                            <span
                                              style={{
                                                marginLeft: '2rem',
                                              }}></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className='radiobox-group d-flex flex-wrap align-items-center'>
                                    {e3.response_type === 'doc' &&
                                      e3.options.length > 0 && (
                                        <>
                                          {e3.options[0].title
                                            .split('.')
                                            .pop() === 'pdf' ||
                                          e3.options[0].title
                                            .split('.')
                                            .pop() === 'PDF' ? (
                                            <>
                                              <embed
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e3.options[0].title
                                                }
                                              />
                                            </>
                                          ) : (
                                            <img
                                              src={
                                                process.env.REACT_APP_S3URL +
                                                e3.options[0].title
                                              }
                                              alt=''
                                            />
                                          )}
                                        </>
                                      )}
                                  </div>
                                  <br />
                                </>
                              </>
                            ))}
                        </>
                      ))}
                    </div>
                  ))}
                </>
              ))}
            {detail.data && detail.data.footer_text && (
              <small>{detail.data.footer_text}</small>
            )}
            <div class='score-parent'>
              <div class='score-child'>
                <div class='mt-3' style={{ fontSize: '14px' }}>
                  {' '}
                  {this.props.answer &&
                    this.props.answer.data &&
                    this.props.answer.data.scoreNumber > 0 && (
                      <>
                        <label style={{ color: '#621ac0', fontWeight: '500' }}>
                          Total Score -{' '}
                        </label>{' '}
                        <label>{this.props.answer.data.scoreNumber}</label>
                      </>
                    )}
                </div>
                <div style={{ fontSize: '14px' }}>
                  {' '}
                  {this.props.answer &&
                    this.props.answer.data &&
                    this.props.answer.data.scoreValue && (
                      <>
                        <label style={{ color: '#621ac0', fontWeight: '500' }}>
                          Severity -{' '}
                        </label>{' '}
                        <label>
                          {this.props.answer.data.scoreValue.severity}
                        </label>
                      </>
                    )}
                </div>
                <div style={{ fontSize: '14px' }}>
                  {' '}
                  {this.props.answer &&
                    this.props.answer.data &&
                    this.props.answer.data.scoreValue && (
                      <>
                        <label style={{ color: '#621ac0', fontWeight: '500' }}>
                          Recommendation -{' '}
                        </label>{' '}
                        <label>
                          {this.props.answer.data.scoreValue.recommendation}
                        </label>
                      </>
                    )}
                </div>
              </div>
            </div>

            {this.state.currentCategory === 'Assessments' && (
              <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                {(this.props.answer.data &&
                  this.props.answer.data.is_submitted === false) ||
                this.props.answer.data === null ? (
                  <>
                    <button
                      onClick={this.handleSubmit}
                      className='btn btn-outline no-shadow'>
                      Save
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ is_submitted: true }, () =>
                          this.handleSubmit()
                        )
                      }
                      className='btn btn-primary'>
                      Submit
                    </button>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className={
            this.state.isShow === true ? 'overlay4 show' : 'overlay4'
          }></div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  detail: getAssessment(state).detail,
  assessment: getAssessment(state).task,
  loading: getAssessment(state).answerLoading,
  answer: getAssessment(state).answer,
  profile: getDoctor(state).profile,
})
const mapDispatchToProps = dispatch => ({
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadSubmittedTask: params => dispatch(loadSubmittedTask(params)),
  loadAnswerAssessment: params => dispatch(loadAnswerAssessment(params)),
  addAnswerAssessment: (data, callBack) =>
    dispatch(addAnswerAssessmentDoctor(data, callBack)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Task)
