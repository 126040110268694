import React, { Component } from 'react'
import calculatorimg from '../../../include/images/calculatorimg.svg'
import material from '../../../include/images/material-note.svg'
import match from '../../../include/images/icon-matchat.svg'
import InfiniteScroll from 'react-infinite-scroller'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import Prescription from '../../patient/dashboard/timeline/prescription'
import Messages from './messages'
import NotesThought from './notesThought'
import _ from 'lodash'
import FollowupView from './followUp/followupView'

export default class JourneyList extends Component {
  state = {
    isShow: false,
    isShow2: false,
    isShow3: false,
    appoint_id: '',
    patientId: '',
    doc_id: '',
    doc_name: '',
    image: '',
    field: '',
    patient_id: '',
    appointment_id: '',
    doctorId: '',
    patientType: '',
    isShow1: false,
    id: '',
    first_name: '',
    last_name: '',
    time: '',
    star: '',
    yesmindy_identification_number: '',
    age: '',
    sex: '',
    appointmentDate: '',
    initial: true,
  }

  toggle1 = (
    id,
    first_name,
    last_name,
    thumb,
    appointment_time,
    star,
    yesmindy_identification_number,
    appointment_id,
    patient_id,
    age,
    sex
  ) => {
    this.setState({
      patient_id,
      appointment_id,
      isShow1: !this.state.isShow1,
      id: id,
      first_name: first_name,
      last_name: last_name,
      image: thumb,
      time: appointment_time,
      star: star,
      yesmindy_identification_number: yesmindy_identification_number,
      age,
      sex,
    })
  }

  formatAMPM(date) {
    var hours = new moment(date, 'HH').utc().hour() // date.getHours();
    var minutes = new moment(date, 'mm').utc().minutes() //date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }
  toggle = (id, patientId, doctorId, patientType) => {
    this.setState({
      isShow: !this.state.isShow,
      appoint_id: id,
      patientId: patientId,
      doctorId: doctorId,
      patientType: patientType,
    })
  }
  toggle2 = (patient_id, id, name, image, field, date) => {
    this.props.loadChatForDoctor(patient_id)
    this.setState({
      isShow2: !this.state.isShow2,
      patient_id: patient_id,
      doc_id: id,
      doc_name: name,
      image: image,
      field: field,
      appointmentDate: date,
      initial: !this.state.isShow2 ? true : false,
    })
  }
  toggle3 = appointment_id => {
    this.setState({
      isShow3: !this.state.isShow3,
      appointment_id: appointment_id,
    })
  }
  render() {
    return (
      <div
        style={{ height: '700px', overflow: 'auto' }}
        ref={ref => (this.scrollParentRef = ref)}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={this.props.loadFunc}
          hasMore={
            !this.props.loading &&
            this.props.pagination &&
            this.props.pagination.filtered_record !=
              this.props.pagination.total_record_on_current_page
          }
          loader={
            <div className='loader' key={0}>
              Loading ...
            </div>
          }
          useWindow={false}
          getScrollParent={() => this.scrollParentRef}>
          {this.props.appointment &&
            this.props.appointment.map((e, i) => (
              <li class='np-item' key={i}>
                <ReactTooltip className='testPurple' html={true} />
                <div class='np-box d-lg-flex align-items-center justify-content-between'>
                  <div class='np-left d-lg-flex flex-wrap align-items-center'>
                    <div class='np-img-box'>
                      {e.doctor_id && (
                        <img
                          src={
                            process.env.REACT_APP_S3URL + e.doctor_id.image_url
                          }
                          alt=''
                        />
                      )}
                    </div>
                    <div class='np-text-box'>
                      <div class='d-flex align-items-center'>
                        <h5>{e.doctor_id && e.doctor_id.name}</h5>
                        {e.appointment_type === 'online' ? (
                          <span class='patient-tags light-green'>Online</span>
                        ) : (
                          <span className='patient-tags light-grey'>
                            {_.capitalize(e.appointment_type)}
                          </span>
                        )}
                      </div>
                      <p>
                        {e.doctor_id && e.doctor_id.field_type.name}{' '}
                        {e.doctor_id &&
                          e.doctor_id.graduation &&
                          ', ' + e.doctor_id.graduation.education}
                        {e.doctor_id &&
                          e.doctor_id.post_graduation &&
                          ', ' + e.doctor_id.post_graduation.education}
                      </p>
                    </div>
                  </div>
                  <div class='np-mid'>
                    <span>
                      {moment(e.appointment_time).utc().format('DD MMM, YYYY')}
                    </span>
                    <span>
                      {this.formatAMPM(new Date(e.appointment_time))} -{' '}
                      {this.formatAMPM(
                        new Date(
                          moment(e.appointment_time).add(
                            e.doctor_id ? e.doctor_id.schedules.time : 25,
                            'minutes'
                          )
                        )
                      )}
                    </span>
                  </div>
                  <div class='np-right d-flex flex-wrap justify-content-end'>
                    <a
                      href='#'
                      class='np-links'
                      data-toggle='calculator'
                      data-tip='Prescription'
                      aria-hidden='true'
                      onClick={event => {
                        event.preventDefault()
                        this.toggle3(e._id)
                      }}>
                      <img src={calculatorimg} alt='' />
                    </a>
                    <a
                      href='javascript:void(0)'
                      class='np-links'
                      data-toggle='notes'
                      data-tip='Notes & Thoughts'
                      aria-hidden='true'
                      onClick={() =>
                        this.toggle(
                          e._id,
                          e.patient_id._id,
                          e.doctor_id && e.doctor_id._id,
                          e.patient_id.type
                        )
                      }>
                      <img src={material} alt='' />
                    </a>
                    {e.doctor_id &&
                    this.props.doctorProfile._id === e.doctor_id._id ? (
                      <a
                        href='javascript:void(0)'
                        class='np-links'
                        onClick={() =>
                          this.toggle2(
                            e.patient_id._id,
                            e.doctor_id._id,
                            e.doctor_id.name,
                            e.doctor_id.image_url,
                            e.doctor_id.field_type.name,
                            moment(e.appointment_time).format('DD/MM/YYYY')
                          )
                        }
                        data-tip='Message'
                        aria-hidden='true'
                        data-toggle='matchat'>
                        <img src={match} alt='' />
                      </a>
                    ) : (
                      <></>
                    )}

                    {e.patient_id && e.followup ? (
                      <a
                        href='javascript:void(0)'
                        class='np-links'
                        onClick={() =>
                          this.toggle1(
                            e.patient_id._id,
                            e.patient_id.first_name,
                            e.patient_id.last_name,
                            e.patient_id.image_url,
                            e.appointment_time,
                            e.patient_id.star,
                            e.patient_id.yesmindy_identification_number,
                            e._id,
                            e.patient_id._id,
                            e.patient_id.date_of_birth,
                            e.patient_id.sex
                          )
                        }
                        data-tip='Follow Up Notes'
                        aria-hidden='true'
                        data-toggle='matchat'>
                        <img src={material} alt='' class='rotate' />
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </InfiniteScroll>
        <NotesThought
          isShow={this.state.isShow}
          toggle={this.toggle}
          stateData={this.state}
          appoint_id={this.state.appoint_id}
          patientId={this.state.patientId}
          doctorId={this.state.doctorId}
          patientType={this.state.patientType}
        />
        <Messages
          isShow={this.state.isShow2}
          toggle={this.toggle2}
          doctor_id={this.state.doc_id}
          patient_id={this.state.patient_id}
          doc_name={this.state.doc_name}
          image={this.state.image}
          field={this.state.field}
          appointmentDate={this.state.appointmentDate}
          setInitial={() => {
            this.setState({
              initial: false,
            })
          }}
          initial={this.state.initial}
        />
        <Prescription
          isShow={this.state.isShow3}
          toggle={this.toggle3}
          appointment_id={this.state.appointment_id}
        />
        {this.state.id && (
          <FollowupView
            appointment_id={this.state.appointment_id}
            patient_id={this.state.patient_id}
            time={this.props.time}
            yesmindy_identification_number={
              this.state.yesmindy_identification_number
            }
            sex={this.state.sex}
            age={this.state.age}
            toggle1={this.toggle1}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            image={this.state.image}
            star={this.state.star}
            isShow2={this.state.isShow1}
            toggle2={this.toggle1}
            id={this.state.id}
            loadList={this.props.loadList}
          />
        )}
        <div
          className={
            this.state.isShow1 === true ? 'overlay4 show' : 'overlay4'
          }></div>
      </div>
    )
  }
}
