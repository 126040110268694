import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import loaderIcon from "../../../include/images/loading-icon.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { resetPassword } from "../../../store/doctor";
import AlertSuccess from "../../common/alertSuccess";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import {
  passwordInvalid,
  passwordRequired,
  passwordInvalidMax,
  accountTypeRequired,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";

class SetPassword extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: { password: "" },
    errors: { password: "" },
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: false,
      });

      const param = {
        mobile: this.props.verifyMobile.mobile,
        otp: this.props.verifyMobile.otp,
        password: this.state.data.password,
      };
      param.type =
        this.props.verifyMobile.type === 3
          ? this.state.data.type
          : this.props.verifyMobile.type;

      this.props.resetPassword(param, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      btnClass: "btn btn-lg btn-primary btn-block",
      loading: false,
    });
    if (res.status === 200) {
      this.props.updateStep(4);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };
  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <div className="mr-step">Step 3/3</div>
              <h2 className="mb-3">Set Password</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-box">
                {this.renderPasswordInput("password", "Set Password")}
                <div className="form-group">
                  <SubmitBtn
                    label="Continue"
                    loading={this.state.loading}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (param, callback) => dispatch(resetPassword(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(SetPassword));
