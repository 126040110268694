import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import introImg from '../../include/images/intro-img-1.png'
import m1 from '../../include/images/member-01.png'
import m2 from '../../include/images/Geriatric.png'
import m3 from '../../include/images/member-03.png'
import downArrow from '../../include/images/down-arrow-icon.svg'
import closeIcon from '../../include/images/md-close-icon.svg'
import whyImg from '../../include/images/video-poster.png'
import Blog from './common/blog'
import Thoughts from './common/thoughts'
import { loadDoctorFeatured, getDoctor } from '../../store/doctor'
import { Modal } from 'react-bootstrap'
import {
  getMisc,
  loadConstant,
  loadBlogCategory,
  loadBlog,
} from '../../store/misc'
import { connect } from 'react-redux'
import Header from '../home/header'
import Footer from './footer'
import { loadSeo } from '../../store/seo'

const mobileSlider = {
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  infinite: false,
  centerMode: false,
  className:
    'home-services-list home-service-slider d-flex flex-wrap slick-initialized slick-slider',
  responsive: [
    {
      breakpoint: 5000,
      settings: {
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 579,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: false,
      },
    },
  ],
}
class HomePage extends Component {
  state = {
    initSlider: false,
    sliderSetting: {
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: false,
      centerMode: false,
      className:
        'home-services-list home-service-slider d-flex flex-wrap add-orange-shape slick-initialized slick-slider',

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 774,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 501,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    patient: localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))
      : false,
    tabPane: false,
    modelShowVideo: false,
    loading: false,
    error: false,
    formError: false,
    navBar: false,
  }

  componentDidMount = () => {
    this.props.loadSeo('home-page')

    setTimeout(() => {
      this.setState({ initSlider: true })
    }, 1000)
    this.props.loadDoctorFeatured('featured_on_home')
    this.props.loadConstant()
    this.props.loadBlogCategory()
    this.props.loadBlog({ page_title: 'HOME', published: true })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  playPause = () => {
    const myVideo = document.getElementById('video1')
    if (myVideo.paused) myVideo.play()
    else myVideo.pause()
  }

  toggleVideo = e => {
    if (e) e.preventDefault()
    this.setState({ modelShowVideo: !this.state.modelShowVideo })
  }

  tabPaneToggle = (e, type = false) => {
    e.preventDefault()
    if (this.state.tabPane === type) {
      this.setState({ tabPane: false })
    } else {
      this.setState({ tabPane: type ? type : false })
    }
  }
  render() {
    const { constants, blogCategory, blog, loadingBlogCategory } =
      this.props.getMisc
    const { featured } = this.props
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants
    const home = blog.filter(i => i.published === true).slice(0, 3)
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div className='outer-container'>
          <div className='hero-container d-flex align-items-center hero1-background'>
            <div className='container container1'>
              <div className='row flex-row-reverse align-items-center'>
                <div className='col-md-6'>
                  <div className='hero-content'>
                    <h3>
                      Begin
                      <span>the journey</span>
                    </h3>
                    <p>
                      Welcome to a holistic wellness journey with our handpicked
                      mental health professionals. We are an exclusive platform
                      dedicated to cater to all your mental health needs. We
                      work tirelessly to give you the quality care you or your
                      loved ones deserve.
                    </p>
                    <Link
                      // to={
                      //   this.state.patient
                      //     ? 'patient/doctors'
                      //     : 'patient/appointment'
                      // }
                      to='/our-experts'
                      className='btn hero-btn'>
                      Book an appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='content-container intro-container'>
            <div className='container container1'>
              <div className='row d-flex align-items-center'>
                <div className='col-lg-5'>
                  <div className='intro-text-box'>
                    <h3>
                      What’s On
                      <span>Your Mind Today?</span>
                    </h3>
                    <p>
                      Make your mental health and wellbeing a priority. Its
                      often easier said than done. We are
                      <br /> here to help you through therapy.
                    </p>
                  </div>
                </div>
                <div className='col-lg-7'>
                  <div className='intro-img-box'>
                    <div className='intro-img'>
                      <img src={introImg} alt='' />
                    </div>
                    <div className='intro-btn-group d-flex align-items-center justify-content-center'>
                      <Link
                        to='patient/chat'
                        className='btn btn-default'
                        target='_blank'>
                        Chat with us{' '}
                      </Link>

                      <h6>It’s Free</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.tabPane
                ? 'content-container query-container mob-no-padd-top add-box-shadow'
                : 'content-container query-container mob-no-padd-top'
            }>
            <div className='container container1'>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='query-intro-box'>
                    <h3>
                      Do I Need
                      <span>Help ?</span>
                    </h3>
                    <div className='query-btn text-center'>
                      <Link
                        to='patient/chat'
                        target='_blank'
                        className='btn btn-default btn-block'>
                        Connect With Us
                      </Link>
                      <p>Know someone in need?</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 d-flex align-items-start justify-content-end'>
                  <ul
                    className='nav nav-tabs helping-member-list d-md-flex flex-nowrap align-items-start'
                    id='myTab'
                    role='tablist'>
                    <li className='hm-item nav-item'>
                      <a
                        onClick={e => this.tabPaneToggle(e, 'adults')}
                        className='nav-link hm-box'
                        id='tab-A'
                        data-toggle='tab'
                        href='#adults'
                        role='tab'
                        aria-controls='adults'
                        aria-selected='true'>
                        <div className='hm-img'>
                          <img src={m1} alt='' />
                          <div className='hm-icon'>
                            <img src={downArrow} alt='' />
                          </div>
                        </div>
                        <p>Adults</p>
                      </a>
                    </li>
                    <li className='hm-item nav-item'>
                      <a
                        onClick={e => this.tabPaneToggle(e, 'adolescents')}
                        className='nav-link hm-box'
                        id='tab-B'
                        data-toggle='tab'
                        href='#adolescents'
                        role='tab'
                        aria-controls='adolescents'
                        aria-selected='false'>
                        <div className='hm-img'>
                          <img src={m2} alt='' />
                          <div className='hm-icon'>
                            <img src={downArrow} alt='' />
                          </div>
                        </div>
                        <p>Geriatric</p>
                      </a>
                    </li>
                    <li
                      className='hm-item nav-item'
                      style={{ marginRight: '30px' }}>
                      <a
                        onClick={e => this.tabPaneToggle(e, 'youngerChildren')}
                        className='nav-link hm-box'
                        id='tab-C'
                        data-toggle='tab'
                        href='#youngerChildren'
                        role='tab'
                        aria-controls='youngerChildren'
                        aria-selected='false'>
                        <div className='hm-img'>
                          <img src={m3} alt='' />
                          <div className='hm-icon'>
                            <img src={downArrow} alt='' />
                          </div>
                        </div>
                        <p>Child & Adolescent</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id='content' className='tab-content' role='tablist'>
            <div
              className={
                this.state.tabPane === 'adults'
                  ? 'member-detail-container card tab-pane fade show active'
                  : 'member-detail-container card tab-pane fade show'
              }
              id='adults'
              role='tabpanel'
              aria-labelledby='tab-A'>
              <div className='card-header add-mob-box-shadow'>
                <a className='hm-box'>
                  <div className='hm-img'>
                    <img src={m1} alt='' />
                    <div
                      className='hm-icon'
                      onClick={e => this.tabPaneToggle(e, 'adults')}>
                      <img src={downArrow} alt='' />
                    </div>
                  </div>
                  <p>Adults</p>
                </a>
              </div>
              <div
                className={
                  this.state.tabPane === 'adults'
                    ? 'content-body show'
                    : 'content-body'
                }>
                <div className='member-detail-head'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-12 text-center'>
                        <h6>Adults</h6>
                        <div
                          className='md-close-icon'
                          onClick={this.tabPaneToggle}>
                          <img src={closeIcon} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-detail-body'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Mood Fluctuations</li>
                            <li>Depressed Mood</li>
                            <li>Free Floating Anxiety</li>
                            <li>Panic Attacks</li>
                            <li>Sleep Difficulties</li>
                            <li>Anger Issues</li>
                            <li>Addiction</li>
                            <li>Substance Abuse</li>
                            <li>Suicidal Thoughts</li>
                            <li>Odd Behaviours</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Odd Beliefs</li>
                            <li>Hearing Voices</li>
                            <li>Memory Problems/ Concentration Difficulties</li>
                            <li>Lack Of Motivation and Interest</li>
                            <li>Social Withdrawal</li>
                            <li>Repetitive Actions and Thoughts</li>
                            <li>Emotionally Instability</li>
                            <li>Increased Risk Taking and Impulsive</li>
                            <li>Difficulty In Relationships</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Family Conflicts</li>
                            <li>Work life Imbalance</li>
                            <li>Difficulties With Stressors</li>
                            <li>Loss of Loved One</li>
                            <li>Traumatic Experience</li>
                            <li>Difficulties Due to Chronic Illness</li>
                            <li>Eating Problems</li>
                            <li>Sexual Difficulties</li>
                            <li>Illness Anxiety Issues</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.tabPane === 'adolescents'
                  ? 'member-detail-container card tab-pane fade show active'
                  : 'member-detail-container card tab-pane fade show'
              }
              role='tabpanel'
              aria-labelledby='tab-B'
              id='adolescents'>
              <div className='card-header add-mob-box-shadow'>
                <a className='hm-box'>
                  <div className='hm-img'>
                    <img src={m2} alt='' />
                    <div
                      className='hm-icon'
                      onClick={e => this.tabPaneToggle(e, 'adolescents')}>
                      <img src={downArrow} alt='' />
                    </div>
                  </div>
                  <p>Geriatics</p>
                </a>
              </div>
              <div
                className={
                  this.state.tabPane === 'adolescents'
                    ? 'content-body show'
                    : 'content-body'
                }>
                <div className='member-detail-head'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-12 text-center'>
                        <h6>Geriatics</h6>
                        <div
                          className='md-close-icon'
                          onClick={this.tabPaneToggle}>
                          <img src={closeIcon} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-detail-body'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Forgetfulness</li>
                            <li>Confusions</li>
                            <li>Repetitive Behaviour</li>
                            <li>Irritability</li>
                            <li>Frequent Crying Spells</li>
                            <li>Emotionally Unstable</li>
                            <li>Difficulty Recalling</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Memory Weakening</li>
                            <li>Losing Interest</li>
                            <li>Sudden Changes</li>
                            <li>Difficulty Speaking</li>
                            <li>Sudden Odd Habits</li>
                            <li>Poor Self Care</li>
                            <li>Sleep Problems</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Excess Demands</li>
                            <li>Excess Talking</li>
                            <li>Frequent Anger Outburst</li>
                            <li>Fearful Thoughts</li>
                            <li>Anxious</li>
                            <li>Negativity</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.tabPane === 'youngerChildren'
                  ? 'member-detail-container card tab-pane fade show active'
                  : 'member-detail-container card tab-pane fade show'
              }
              role='tabpanel'
              aria-labelledby='tab-C'
              id='youngerChildren'>
              <div className='card-header add-mob-box-shadow'>
                <a className='hm-box'>
                  <div className='hm-img'>
                    <img src={m3} alt='' />
                    <div
                      className='hm-icon'
                      onClick={e => this.tabPaneToggle(e, 'youngerChildren')}>
                      <img src={downArrow} alt='' />
                    </div>
                  </div>
                  <p>Child & Adolescent</p>
                </a>
              </div>
              <div
                className={
                  this.state.tabPane === 'youngerChildren'
                    ? 'content-body show'
                    : 'content-body'
                }>
                <div className='member-detail-head'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-12 text-center'>
                        <h6>Child & Adolescent</h6>
                        <div
                          className='md-close-icon'
                          onClick={this.tabPaneToggle}>
                          <img src={closeIcon} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='member-detail-body'>
                  <div className='container container1'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Disciplinary Issues </li>
                            <li>Difficulties At School </li>
                            <li>Difficulties With Peers </li>
                            <li>Hyperactivities</li>
                            <li>Risk Taking Behaviours</li>
                            <li>Lack of Attention/ Concentration</li>
                            <li>Poor Learning Skills</li>
                            <li>Socially Awkward</li>
                            <li>Poor Communication Skills</li>
                            <li>Withdrawn from Others</li>
                            <li>Increased Fear</li>
                            <li>Easily Anxious</li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Sleep Difficulties </li>
                            <li>Parenting Management </li>
                            <li>Bed Wetting </li>
                            <li>Deteriorating Academic Performance </li>
                            <li>Increased Lying </li>
                            <li>Increased Impulsivity </li>
                            <li>Increased Stubbornness </li>
                            <li>Difficulties At Home </li>
                            <li>Delusions and Hallucinations </li>
                            <li>Intense Mood Fluctuations </li>
                            <li>Withdrawal From Activities </li>
                            <li>Increased Anger Outbursts </li>
                          </ul>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='mdb-content'>
                          <ul className='new-dot-list'>
                            <li>Game/ Internet Dependence </li>
                            <li>Substance/ Drug Abuse</li>
                            <li>Family Conflicts</li>
                            <li>Relationship Issues</li>
                            <li>Trauma Related Issues</li>
                            <li>Childhood Abuse</li>
                            <li>Career Related Confusions</li>
                            <li>Eating Pattern Unhealthy</li>
                            <li>Fainting Spells</li>
                            <li>Self-Harming Acts</li>
                            <li>Harming/ Bullying Others</li>
                            <li>Siblings Rivalry</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='content-container media-container'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-10 offset-xl-1  col-md-12 offset-md-0 text-center'>
                  <div className='media-content-box add-orange-shape'>
                    <div className='heading'>
                      <h4>
                        Why <span>Mindy?</span>
                      </h4>
                    </div>
                    <div className='media-box border-radius overflow-hidden'>
                      <div
                        className='embed-responsive embed-responsive-16by9'
                        onClick={this.playPause}>
                        <video
                          style={{ cursor: 'pointer' }}
                          id='video1'
                          width='470'
                          height='255'
                          poster={whyImg}
                          controls={false}>
                          <source
                            src='https://d2z6xjd2pnztki.cloudfront.net/website/Comp.mp4'
                            type='video/mp4'
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='content-container'>
            <div className='container container1'>
              <div className='row'>
                <div className='col-xl-10 offset-xl-1 text-center'>
                  <div className='heading'>
                    <h4>
                      Meet our <span>Experts</span>
                    </h4>
                  </div>
                  {this.state.initSlider ? (
                    <Slider key={this.state.initSlider} {...mobileSlider}>
                      {featured &&
                        featured.map((e, i) => (
                          <li className='expert-item'>
                            <div className='expert-box'>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target='_blank'
                                style={{ width: '100%' }}>
                                <div className='expert-img'>
                                  <img
                                    src={
                                      process.env.REACT_APP_S3URL + e.image_url
                                    }
                                    alt=''
                                  />
                                </div>
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target='_blank'>
                                <h4>{e.name}</h4>
                              </a>
                              <p>
                                {e.graduation && e.graduation.education + ', '}

                                {e.post_graduation &&
                                  e.post_graduation.education}

                                {e.super_speciality &&
                                  ', ' + e.super_speciality.education}
                              </p>
                            </div>
                          </li>
                        ))}
                    </Slider>
                  ) : (
                    featured && featured.map((e, i) => '')
                  )}

                  <div className='viewmore-btn text-center'>
                    <Link to={'/our-experts'} className='btn btn-default'>
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='content-container home-service-container pb-0 mob-no-padd-top'>
            <div className='container container1'>
              <div className='row'>
                <div className='col-xl-10 offset-xl-1 text-center'>
                  <div className='heading'>
                    <h4>
                      Stay In The Know <span>With Mindy</span>
                    </h4>
                  </div>
                  {this.state.initSlider ? (
                    <Slider
                      key={this.state.initSlider}
                      {...this.state.sliderSetting}>
                      {blogCategory.map((e, i) => (
                        <li className='hsl-item' key={i}>
                          <Link to={{ pathname: '/blog', state: e.name }}>
                            <div className='hsl-box'>
                              <div className='hsl-bg'>
                                <img
                                  src={process.env.REACT_APP_S3URL + e.image}
                                  alt=''
                                />
                              </div>
                              <div className='hsl-content'>{e.name}</div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </Slider>
                  ) : (
                    blogCategory.map((e, i) => (
                      <li className='hsl-item' key={i}>
                        <Link to={{ pathname: '/blog', state: e.name }}>
                          <div className='hsl-box'>
                            <div className='hsl-bg'>
                              <img
                                src={process.env.REACT_APP_S3URL + e.image}
                                alt=''
                              />
                            </div>
                            <div className='hsl-content'>{e.name}</div>
                          </div>
                        </Link>
                      </li>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <Blog blog={home} />
          <div className='content-container home-block-container'>
            <div className='container container1'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='heading text-center'>
                    <h4>
                      Join Our <span>Community</span>
                    </h4>
                  </div>
                  <ul className='block-list d-md-flex flex-wrap'>
                    <li className='block-item'>
                      <div className='block-box'>
                        <div className='block-bg d-flex cb-left-img'>
                          <div className='block-text d-flex flex-column'>
                            <div className='block-text-box'>
                              <h4>For Health Professionals</h4>
                            </div>
                            <p>
                              We would love to partner with you or work with you
                              if we share the passion to deliver quality driven
                              Metal Health services. We will be your companion
                              in your mission.
                            </p>
                            <div className='block-btn mt-auto'>
                              <Link
                                to={{
                                  pathname: '/clinical-community',
                                  join: 'Join',
                                }}
                                className='btn btn-default'>
                                Join Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className='block-item'>
                      <div className='block-box'>
                        <div className='block-bg d-flex cb-right-img'>
                          <div className='block-text d-flex flex-column'>
                            <div className='block-text-box'>
                              <h4>Become A Voice For Mental Health</h4>
                            </div>
                            <p>
                              We believe what community as a whole can do for
                              the field of Mental Health cannot be done by one.
                              Help us bring the transformation.
                            </p>
                            <div className='block-btn mt-auto'>
                              <Link
                                to={{
                                  pathname: '/clinical-community',
                                  join: 'Join',
                                }}
                                className='btn btn-default'>
                                Join Now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Thoughts />
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered mt-0'
          className='modal fade form-popup video-popup show'
          show={this.state.modelShowVideo}
          onHide={this.toggleVideo}>
          <div className='modal-body'>
            <div className='embed-responsive embed-responsive-16by9'>
              <iframe
                className='embed-responsive-item'
                src='https://www.youtube.com/embed/eEzD-Y97ges'
                id='video'
                allowscriptaccess='always'
                allow='autoplay'></iframe>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  getMisc: getMisc(state),
  featured: getDoctor(state).featured,
})

const mapDispatchToProps = dispatch => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlogCategory: () => dispatch(loadBlogCategory()),
  loadBlog: params => dispatch(loadBlog(params)),
  loadDoctorFeatured: params => dispatch(loadDoctorFeatured(params)),
  loadSeo: page_key => dispatch(loadSeo(page_key)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
