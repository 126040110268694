import Joi from "joi-browser";
import React, { Component } from "react";
import Form from "../../common/form/form";
import {
  firstNameRequired,
  lastNameRequired,
  dobRequired,
  dobInvalid,
} from "../../common/misc";
import loaderIcon from "../../../include/images/loading-icon.svg";
// import { toast } from "react-toastify";
// import AlertError from "../../common/alertError";
// import { patientSignUp } from "../../../store/patient";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertSuccess from "../../common/alertSuccess";

class PatientDetails extends Form {
  state = {
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    data: {
      DOB: "",
      first_name: "",
      last_name: "",
    },
    errors: {
      DOB: "",
      first_name: "",
      last_name: "",
    },
  };

  componentDidMount = () => {
    if (this.props.allState.patientDetails.DOB) {
      this.setState({
        data: {
          DOB: new Date(this.props.allState.patientDetails.DOB),
          first_name: this.props.allState.patientDetails.first_name,
          last_name: this.props.allState.patientDetails.last_name,
        },
      });
    }
  };

  schema = {
    first_name: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: firstNameRequired };
      }),

    last_name: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: lastNameRequired };
      }),
    DOB: Joi.date()
      .max(new Date())
      .required()
      .label("Date of birth")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "date.base":
              err.message = dobRequired;
              break;

            case "date.max":
              err.message = "Date cannot be in future";
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    this.props.updatePatientDetails(this.state.data);
    this.props.updateStep(null, 11);
  };

  render() {
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              {/* <a href="" onClick={(e) => this.props.updateStep(e, 3)}>
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  ></path>
                </svg>
              </a> */}

              <h2 className="mb-3">Patient's Details</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group custom-input">
                {this.renderInput("first_name", "First Name")}
                {this.renderInput("last_name", "Last Name")}
                {this.renderDateInput("DOB", "Date Of Birth")}
                <div className="form-group">
                  <button
                    href="#"
                    type="submit"
                    className={this.state.btnClass}
                  >
                    {this.state.loading ? (
                      <img src={loaderIcon} alt="" />
                    ) : (
                      <span>Next</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(PatientDetails));
