import { combineReducers } from "redux";

import patientReducer from "./patient";
import doctorReducer from "./doctor";
import appointmentReducer from "./appointment";
import miscReducer from "./misc";
import seoReducer from "./seo";
import assessmentReducer from "./assessment";
import chatReducer from "./chats";
import documentReducer from "./document";
import diaryReducer from "./diary";
import doctorPatientReducer from "./doctorPatient";
import staff from "./staff";
import prescriptionReducer from "./prescription";
import caseRecordReducer from "./caseRecord";
import followUpReducer from "./followUp";
import notification from "./notification";
import bioReducer from "./bioPsycosocial"
import treatmentPlanReducer from "./treatmentPlan"

export default combineReducers({
  patient: patientReducer,
  misc: miscReducer,
  doctor: doctorReducer,
  appointment: appointmentReducer,
  seo: seoReducer,
  assessment: assessmentReducer,
  chats: chatReducer,
  document: documentReducer,
  diary: diaryReducer,
  doctorPatient: doctorPatientReducer,
  staff: staff,
  prescription: prescriptionReducer,
  caseRecord: caseRecordReducer,
  followUp: followUpReducer,
  bio: bioReducer,
  treatmentPlan: treatmentPlanReducer,
  notification: notification,
});
