import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PatientList from "./patientList";
import {
  getDoctorPatient,
  loadAllDoctorpatient,
} from "../../../store/doctorPatient";

class OtherPatient extends Component {
  state = {
    content: false,
    id: "",
    page: 1,
  };

  componentDidMount() {
    document.body.className = "dashboard fixed";
    //this.props.loadAllDoctorpatient({ page: this.state.page, star: "false" });
  }
  loadFunc = () => {
    if (
      Math.ceil(
        this.props.doctorPatient.pagination.filtered_record /
          this.props.doctorPatient.pagination.record_per_page
      ) > this.state.page
    ) {
      this.setState({ page: this.state.page + 1 }, () =>
        this.props.loadAllDoctorpatient({
          page: this.state.page,
          star: "false",
        })
      );
    }
  };
  toggleContent = (id) => {
    this.setState({
      content: !this.state.content,
      id: id,
    });
  };

  render() {
    const { doctorPatient, loading } = this.props;
    const { data, pagination } = doctorPatient;
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="allpatients"
          role="tabpanel"
          aria-labelledby="allpatients-tab"
        >
          <div class="card-header">All Patients</div> */}
        {/* <div class="content-body show add-scrollbar  scrollbar-dynamic"> */}
        <ul class="chat-member-list bordered has-shadow ">
          <PatientList
            states={this.state}
            doctorPatient={data}
            pagination={pagination}
            loadFunc={this.loadFunc}
            loading={loading}
            toggleContent={this.toggleContent}
            id={this.state.id}
            content={this.state.content}
          />
        </ul>
        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadAllDoctorpatient: (params) => dispatch(loadAllDoctorpatient(params)),
});
const mapStateToProps = (state) => ({
  doctorPatient: getDoctorPatient(state).doctorPatient,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtherPatient)
);
