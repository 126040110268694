import Header from "./header";
import WalkIn from "./walkIns";
import LeftBar from "./leftBar";
import { connect } from "react-redux";
import React, { Component } from "react";
import AllAppointments from "./allAppointment";
import { withRouter, Link } from "react-router-dom";
import BookedAppointment from "./bookedAppointment";
import AppointmentHeader from "./appointmentHeader";
import ReactTooltip from "react-tooltip";

class AppointmentTabs extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  };

  updateStep = (e, step) => {
    if (e) e.preventDefault();
    this.setState({ step });
  };
  componentDidMount() {
    document.body.className = "dashboard";
  }
  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <AllAppointments
            updateStep={this.updateStep}
            step={this.state.step}
          />
        );
      case 2:
        return (
          <BookedAppointment
            updateStep={this.updateStep}
            step={this.state.step}
          />
        );
      case 3:
        return <WalkIn updateStep={this.updateStep} step={this.state.step} />;

      default:
        break;
    }
  };

  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };
  render() {
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          <div class="dashboard-main-container">
            {/* <div class="dashboard-main-heading">
            <h2>
              Welcome, <strong>Dr. Shah</strong>
            </h2>
            <p>Have a nice day at work</p>
          </div> */}
            <div class="dashboard-wrapper">
              <div class="dashboard-single-panel">
                <AppointmentHeader step={this.state.step} />
                <div class="dsp-body">
                  <div class="tab-container d-none d-md-block">
                    <ul
                      class="nav nav-tabs nav-tabs2"
                      id="myTab"
                      role="tablist"
                    >
                      <li
                        class="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          data-tip="Total appointments of all modes booked so far by the expert or patient"
                          className={
                            this.state.step === 1
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="all"
                          role="tab"
                          aria-controls="all"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          <b>All Appointments</b>
                          <span>All Appointments</span>
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          data-tip="Appointments booked by patients and fees deposited with YesMindy"
                          className={
                            this.state.step === 2
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="starred"
                          role="tab"
                          aria-controls="starred"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          <b>Booked Appointments</b>
                          <span>Booked Appointments</span>
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          data-tip="Total number of clinic visits"
                          className={
                            this.state.step === 3
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="Other"
                          role="tab"
                          aria-controls="other"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          <b>Clinic</b>
                          <span>Clinic</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content-box">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class={
                          this.state.step === 1
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          All Appointments
                        </div>
                        <div
                          className={
                            this.state.step === 1
                              ? "show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{
                            position: "relative",
                          }}
                        >
                          {" "}
                          {this.state.step === 1 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 2
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          Booked Appointments
                        </div>
                        <div
                          className={
                            this.state.step === 2
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 2 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 3
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          Clinic
                        </div>
                        <div
                          className={
                            this.state.step === 3
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 3 && this.leftSideStep()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null)(AppointmentTabs));
