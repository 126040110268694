import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { patientSignInExternal } from "../../../store/patient";
import AlertError from "../../common/alertError";
import Form from "../../common/form/form";

import { setToken } from "../../services/localStorageServices";
import { loadSeo, loadTermTemplate } from "../../../store/seo";

class ExternalSignIn extends Form {
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.patientSignInExternal(
        this.props.match.params.id,
        this.callback
      );
    }
  }

  callback = (res) => {
    if (res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data;
      setToken(xRefreshToken, xAuthToken);
      localStorage.setItem("patient", JSON.stringify(res.data.data));
      localStorage.setItem(
        "externalPartner",
        res.data.data.externalPartner.title
      );
      this.props.history.push("/patient/doctors");
    } else return toast(<AlertError message={res.data.error} />);
  };

  render() {
    return <div className="member-container"></div>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  patientSignInExternal: (param, callback) =>
    dispatch(patientSignInExternal(param, callback)),
  loadSeo: (page_key) => dispatch(loadSeo(page_key)),
  loadTermTemplate: (key) => dispatch(loadTermTemplate(key)),
});

export default withRouter(connect(null, mapDispatchToProps)(ExternalSignIn));
