import React, { Component } from 'react'
import Joi from 'joi-browser'
import loaderIcon from '../../../include/images/loading-icon.svg'
import { toast } from 'react-toastify'
import Form from './../../common/form/form'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { withRouter } from 'react-router-dom'
import { loadTermTemplate } from '../../../store/seo'
import TermTemplatePopup from '../../common/termTemplatePopup'

import {
  mobileAvailability,
  patientAvailability,
  sendOtp,
} from '../../../store/patient'
import {
  mobileInvalid,
  mobileRequired,
  accountNotExist,
} from '../../common/misc'

import { connect } from 'react-redux'
import SubmitBtn from '../../common/form/submitBtn'

class VerifyMobile extends Form {
  state = {
    type: 1,
    btnClass: 'btn btn-lg btn-primary btn-block',
    loader: false,
    emailData: '',
    data: { username: '' },
    errors: { username: '' },
    popShow: false,
  }

  schema = {
    username: Joi.string()
      .required()
      .error(() => {
        return { message: 'Mobile / Email field is required' }
      }),
  }

  doSubmit = () => {
    if (!this.state.loader) {
      if (
        !isNaN(parseInt(this.state.data.username)) &&
        this.state.data.username.length !== 10
      ) {
        this.setState({
          errors: {
            username: 'Invalid mobile number. Do not enter country code',
          },
        })
        return
      }
      this.setState({
        loader: true,
        btnClass: 'btn btn-lg btn-primary btn-block disabled',
      })
      const params = {
        username: this.state.data.username,
      }
      this.props.mobileAvailability(params, this.callback)
    }
  }

  callback = res => {
    console.log('resresresres', res)
    if (!res) return
    this.setState({
      loader: false,
      btnClass: 'btn btn-lg btn-primary btn-block',
    })
    console.log('resresresres', res.status)
    if (res.status === 200) {
      if (!res.data.data.length)
        return toast(<AlertError message={res.data.error} />)
      if (res.data.data.length === 2) {
        this.setState({ type: 3 })
      } else {
        this.setState({ type: res.data.data[0].type })
      }
      const params = {
        mobile: res.data.data[0].mobile,
        email: res.data.data[0].email,
        type: 'resetPassword',
      }
      this.setState({ emailData: res.data.data[0].email })
      this.props.sendOtp(params, this.otpCallBack)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  otpCallBack = res => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />)
      this.props.updateStep(2)
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        type: this.state.type,
        mobile: res.data.mobile,
        email: this.state.emailData,
      })
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow })
  }

  openPopup = (e, key) => {
    e.preventDefault()
    this.props.loadTermTemplate(key)
    this.togglePopup()
  }

  componentDidMount() {
    if (this.props.mobile) {
      this.setState({
        data: { username: this.props.mobile },
      })
    }
  }

  render() {
    return (
      <div className='member-right'>
        <div className='member-right-content d-md-flex align-items-center'>
          <div className='mr-box'>
            <div className='mr-head mb-4'>
              <div className='mr-step'>Step 1/3</div>
              <h2>Setting password</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className='form-box'>
                <div className='form-group'>
                  {this.renderInput('username', 'Mobile / Email')}
                </div>

                <div className='form-group'>
                  <SubmitBtn
                    label='Continue'
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <TermTemplatePopup
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  mobileAvailability: (param, callback) =>
    dispatch(patientAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
  loadTermTemplate: key => dispatch(loadTermTemplate(key)),
})
export default withRouter(connect(null, mapDispatchToProps)(VerifyMobile))
