import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import moment from "moment";

const slice = createSlice({
  name: "misc",
  initialState: {
    summary: [],
    summaryLoading: false,
    summaryLastFetch: null,
    constants: {},
    modilityoftherapies: [],
    areasofintrests: [],
    blogCategory: [],
    blog: [],
    fblog: [],
    featuredblog: [],
    topArticle: [],
    blogdetail: {},
    pagination: {},
    customerQuery: {},
    keyword: [],
    faq: [],
    fieldTypes: [],
    state: [],
    city: [],
    filterCity: [],
    loadingFieldTypes: false,
    lastFetchFieldTypes: null,
    loadingState: false,
    lastFetchState: null,
    loadingCity: false,
    lastFetchCity: null,
    loadingFilterCity: false,
    lastFetchFilterCity: null,
    loadingConstant: false,
    lastFetchConstant: null,
    loadingBlogCategory: false,
    customerQueryLoading: false,
    lastFetchBlogCategory: null,
    lastFetchFaq: null,
    loadingBlog: false,
    loadingTopArticle: false,
    loadingBlogDetail: false,
    loadingFaq: false,
    lastFetchBlog: null,
    lastFetchTopArticle: null,

    lastFetchKeyword: null,
    lastFetchBlogDetail: null,
    loadingModilityoftherapies: false,
    lastFetchModilityoftherapies: null,
    loadingAreasofintrests: false,
    lastFetchAreasofintrests: null,
  },
  reducers: {
    fieldTypesRequested: (misc, action) => {
      misc.loadingFieldTypes = true;
    },
    fieldTypesReceived: (misc, action) => {
      misc.fieldTypes = action.payload;
      misc.loadingFieldTypes = false;
      misc.lastFetchFieldTypes = Date.now();
    },
    fieldTypesRequestFailed: (misc, action) => {
      misc.loadingFieldTypes = false;
    },
    ///summary
    summaryRequested: (misc, action) => {
      misc.summaryLoading = true;
    },
    summaryReceived: (misc, action) => {
      misc.summary = action.payload;
      misc.summaryLoading = false;
      misc.summaryLastFetch = Date.now();
    },
    fblogReceived: (misc, action) => {
      misc.fblog = action.payload.data;
    },
    summaryRequestFailed: (misc, action) => {
      misc.summary = [];
      misc.summaryLoading = false;
    },

    customerAdded: (misc, action) => {
      misc.customerQuery = action.payload;
      misc.customerQueryLoading = false;
      misc.lastFetch = Date.now();
    },

    customerAddRequestFailed: (misc, action) => {
      misc.customerQueryLoading = false;
    },
    modilityoftherapiesRequested: (misc, action) => {
      misc.loadingModilityoftherapies = true;
    },
    modilityoftherapiesReceived: (misc, action) => {
      misc.modilityoftherapies = action.payload;
      misc.loadingModilityoftherapies = false;
      misc.lastFetchModilityoftherapies = Date.now();
    },
    modilityoftherapiesRequestFailed: (misc, action) => {
      misc.loadingModilityoftherapies = false;
    },

    areasofintrestsRequested: (misc, action) => {
      misc.loadingareasofintrests = true;
    },
    areasofintrestsReceived: (misc, action) => {
      misc.areasofintrests = action.payload;
      misc.loadingAreasofintrests = false;
      misc.lastFetchAreasofintrests = Date.now();
    },
    areasofintrestsRequestFailed: (misc, action) => {
      misc.loadingAreasofintrests = false;
    },

    /**Const */
    constantRequested: (misc, action) => {
      misc.loadingConstant = true;
    },
    constantReceived: (misc, action) => {
      misc.constants = action.payload;
      misc.loadingConstant = false;
      misc.lastFetchConstant = Date.now();
    },
    constantRequestFailed: (misc, action) => {
      misc.loadingConstant = false;
    },
    /**Blog cat */
    blogCategoryRequested: (misc, action) => {
      misc.loadingBlogCategory = true;
    },
    blogCategoryReceived: (misc, action) => {
      misc.blogCategory = action.payload.data;
      misc.loadingBlogCategory = false;
      misc.lastFetchBlogCategory = Date.now();
    },
    blogCategoryRequestFailed: (misc, action) => {
      misc.loadingBlogCategory = false;
    },

    faqRequested: (misc, action) => {
      misc.loadingFaq = true;
    },
    faqReceived: (misc, action) => {
      misc.faq = action.payload.data;
      misc.loadingFaq = false;
      misc.lastFetchFaq = Date.now();
    },
    faqRequestFailed: (misc, action) => {
      misc.loadingFaq = false;
    },

    /**Blog */
    blogRequested: (misc, action) => {
      misc.loadingBlog = true;
    },
    blogReceived: (misc, action) => {
      misc.blog = action.payload.data;
      misc.pagination = action.payload.pagination;
      misc.loadingBlog = false;
      misc.lastFetchBlog = Date.now();
    },

    blogFReceived: (misc, action) => {
      misc.featuredblog = action.payload.data;
      misc.loadingBlog = false;
      misc.lastFetchBlog = Date.now();
    },
    blogRequestFailed: (misc, action) => {
      misc.loadingBlog = false;
    },
    topArticleRequested: (misc, action) => {
      misc.loadingTopArticle = true;
    },
    topArticleReceived: (misc, action) => {
      misc.topArticle = action.payload.data;
      misc.loadingTopArticle = false;
      misc.lastFetchTopArticle = Date.now();
    },
    topArticleRequestFailed: (misc, action) => {
      misc.loadingTopArticle = false;
    },
    blogdetailRequested: (misc, action) => {
      misc.loadingBlogDetail = true;
    },
    blogdetailReceived: (misc, action) => {
      misc.blogdetail = action.payload.data;
      misc.loadingBlogDetail = false;
      misc.lastFetchBlogDetail = Date.now();
    },
    blogdetailRequestFailed: (misc, action) => {
      misc.loadingBlogDetail = false;
    },

    keywordRequested: (misc, action) => {
      misc.loadingBlog = true;
    },
    keywordReceived: (misc, action) => {
      misc.keyword = action.payload.data;
      misc.loadingBlog = false;
      misc.lastFetchKeyword = Date.now();
    },
    keywordRequestFailed: (misc, action) => {
      misc.loadingBlog = false;
    },

    stateRequested: (misc) => {
      misc.loadingState = true;
    },
    stateReceived: (misc, action) => {
      misc.state = action.payload;
      misc.loadingState = false;
      misc.lastFetchState = Date.now();
    },
    stateRequestFailed: (misc) => {
      misc.loadingState = false;
    },

    filterCityRequested: (misc) => {
      misc.loadingFiltereCity = true;
    },
    filterCityReceived: (misc, action) => {
      misc.filterCity = action.payload;
      misc.loadingFiltereCity = false;
      misc.lastFetchFiltereCity = Date.now();
    },
    filterCityRequestFailed: (misc) => {
      misc.loadingFiltereCity = false;
    },

    cityRequested: (misc, action) => {
      misc.loadingCity = true;
    },
    cityReceived: (misc, action) => {
      misc.city = action.payload;
      misc.loadingCity = false;
      misc.lastFetChcity = Date.now();
    },
    cityRequestFailed: (misc, action) => {
      misc.loadingCity = false;
    },
  },
});

export const {
  fblogReceived,
  faqRequested,
  faqReceived,
  faqRequestFailed,
  fieldTypesRequested,
  fieldTypesReceived,
  fieldTypesRequestFailed,
  cityRequested,
  cityReceived,
  cityRequestFailed,
  stateRequested,
  stateReceived,
  stateRequestFailed,
  filterCityReceived,
  filterCityRequested,
  filterCityRequestFailed,
  constantReceived,
  constantRequestFailed,
  constantRequested,
  blogCategoryReceived,
  blogCategoryRequestFailed,
  blogCategoryRequested,
  blogReceived,
  blogRequestFailed,
  blogRequested,
  topArticleReceived,
  topArticleRequestFailed,
  topArticleRequested,
  blogdetailRequested,
  blogdetailRequestFailed,
  blogdetailReceived,
  customerAdded,
  customerAddRequestFailed,
  keywordRequested,
  keywordReceived,
  keywordRequestFailed,
  cRequested,
  areasofintrestsRequested,
  areasofintrestsReceived,
  areasofintrestsRequestFailed,
  modilityoftherapiesRequested,
  modilityoftherapiesReceived,
  modilityoftherapiesRequestFailed,
  blogFReceived,
  summaryReceived,
  summaryRequestFailed,
  summaryRequested,
} = slice.actions;
export default slice.reducer;

// Action Creators
const fieldTypeUrl = "fieldType/";
const cityUrl = "city";
const faqUrl = "faq";
const modilityOfTherapyUrl = "modilityOfTherapy";
const areasofintrestsUrl = "areasOfIntrest";

export const loadAreasofintrests = () => (dispatch, getState) => {
  const { lastFetchAreasofintrests } = getState().entities.misc;

  const diffInMinutes = moment().diff(
    moment(lastFetchAreasofintrests),
    "minutes"
  );
  if (diffInMinutes < 10) return;

  return dispatch(
    apiCallBegan({
      url: areasofintrestsUrl,
      onStart: areasofintrestsRequested.type,
      onSuccess: areasofintrestsReceived.type,
      onError: areasofintrestsRequestFailed.type,
    })
  );
};

export const loadModilityoftherapies = () => (dispatch, getState) => {
  const { lastFetchModilityoftherapies } = getState().entities.misc;

  const diffInMinutes = moment().diff(
    moment(lastFetchModilityoftherapies),
    "minutes"
  );
  if (diffInMinutes < 10) return;

  return dispatch(
    apiCallBegan({
      url: modilityOfTherapyUrl,
      onStart: modilityoftherapiesRequested.type,
      onSuccess: modilityoftherapiesReceived.type,
      onError: modilityoftherapiesRequestFailed.type,
    })
  );
};
export const addCustomerquery = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback,
      url: "/customerQuery",
      method: "post",
      data: param,
      onSuccess: customerAdded.type,
      onError: customerAddRequestFailed.type,
    })
  );
};

export const loadSummary = (params) => (dispatch) => {
  console.log("paramsparams", params);
  return dispatch(
    apiCallBegan({
      params,
      url: "appointment/summery",
      onStart: summaryRequested.type,
      onSuccess: summaryReceived.type,
      onError: summaryRequestFailed.type,
    })
  );
};

export const loadFieldType =
  (params = {}) =>
  (dispatch, getState) => {
    return dispatch(
      apiCallBegan({
        params,
        url: fieldTypeUrl,
        onStart: fieldTypesRequested.type,
        onSuccess: fieldTypesReceived.type,
        onError: fieldTypesRequestFailed.type,
      })
    );
  };

export const loadConstant = () => (dispatch, getState) => {
  const { lastFetchConstant } = getState().entities.misc;
  const diffInMinutes = moment().diff(moment(lastFetchConstant), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "constant",
      onStart: constantRequested.type,
      onSuccess: constantReceived.type,
      onError: constantRequestFailed.type,
    })
  );
};

export const loadBlogCategory = () => (dispatch, getState) => {
  // const { lastFetchBlogCategory } = getState().entities.misc;
  // const diffInMinutes = moment().diff(moment(lastFetchBlogCategory), "minutes");
  // if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "blog-category",
      onStart: blogCategoryRequested.type,
      onSuccess: blogCategoryReceived.type,
      onError: blogCategoryRequestFailed.type,
    })
  );
};
export const loadFaq = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: faqUrl,
      params,
      onStart: faqRequested.type,
      onSuccess: faqReceived.type,
      onError: faqRequestFailed.type,
    })
  );
};

export const loadBlog = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "blog",
      params,
      onStart: blogRequested.type,
      onSuccess: blogReceived.type,
      onError: blogRequestFailed.type,
    })
  );
};
///clinical/record
export const loadfBlog = () => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "blog/clinical/record",
      onSuccess: fblogReceived.type,
    })
  );
};
export const loadFeaturedBlog = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "blog",
      params: { is_featured: true, is_published: true },
      onStart: blogRequested.type,
      onSuccess: blogFReceived.type,
      onError: blogRequestFailed.type,
    })
  );
};
export const loadtopArticle = (params) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "blog",
      params,
      onStart: topArticleRequested.type,
      onSuccess: topArticleReceived.type,
      onError: topArticleRequestFailed.type,
    })
  );
};
export const loadBlogDetail = (id) => (dispatch, getState) => {
  return dispatch(
    apiCallBegan({
      url: "blog/findByUrl/" + id,
      onStart: blogdetailRequested.type,
      onSuccess: blogdetailReceived.type,
      onError: blogdetailRequestFailed.type,
    })
  );
};
export const loadKeyword = () => (dispatch, getState) => {
  const { lastFetchKeyword } = getState().entities.misc;
  const diffInMinutes = moment().diff(moment(lastFetchKeyword), "minutes");
  if (diffInMinutes < 10) return;
  return dispatch(
    apiCallBegan({
      url: "blog/keywords",
      onStart: keywordRequested.type,
      onSuccess: keywordReceived.type,
      onError: keywordRequestFailed.type,
    })
  );
};

export const loadCity = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: cityUrl,
      data,
      method: "POST",
      onStart: cityRequested.type,
      onSuccess: cityReceived.type,
      onError: cityRequestFailed.type,
    })
  );
};

export const loadState = () => (dispatch, getState) => {
  const { lastFetchState } = getState().entities.misc;

  const diffInMinutes = moment().diff(moment(lastFetchState), "minutes");
  if (diffInMinutes < 1) return;

  return dispatch(
    apiCallBegan({
      url: cityUrl,
      onStart: stateRequested.type,
      onSuccess: stateReceived.type,
      onError: stateRequestFailed.type,
    })
  );
};

export const loadFilterCity = () => (dispatch, getState) => {
  const { lastFetchFilterCity } = getState().entities.misc;

  const diffInMinutes = moment().diff(moment(lastFetchFilterCity), "minutes");
  if (diffInMinutes < 10) return;

  return dispatch(
    apiCallBegan({
      url: cityUrl + "/filterCity",
      onStart: filterCityRequested.type,
      onSuccess: filterCityReceived.type,
      onError: filterCityRequestFailed.type,
    })
  );
};

export const customerQuery = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "customerQuery",
      method: "POST",
      data,
      callback,
    })
  );
};

export const doctorDesk = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "doctor/desk",
      method: "POST",
      data,
      callback,
    })
  );
};

export const patientDesk = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "patient/desk",
      method: "POST",
      data,
      callback,
    })
  );
};
export const getMisc = createSelector(
  (state) => state.entities.misc,
  (misc) => misc
);
