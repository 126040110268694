import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./header";
import { withRouter } from "react-router-dom";
import LeftBar from "./leftBar";
import { deleteStaff, getStaff, loadStaff } from "../../../store/staff";
import notificationIcon from "../../../include/images/promotions.svg";
import { getNotification, loadNotification } from "../../../store/notification";
import moment from "moment";
import NoRecord from "../../common/noRecord";

class Notification extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  };
  componentDidMount() {
    document.body.className = "dashboard";
    this.props.loadNotification();
  }

  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };

  render() {
    console.log("getNotification", this.props.getNotification);
    return (
      <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div className="dashboard-main-container">
          <div className="dashboard-wrapper">
            <div className="dashboard-single-panel">
              <div className="dsp-head d-flex aligsn-items-md-center justify-content-md-between">
                <h4>Notifications</h4>
              </div>
              <div className="dsp-body">
                <div className="tab-container d-none d-md-block"></div>
                <div className="tab-content-box">
                  <div className="tab-content" id="myTabContent">
                    <div
                      class="tab-pane card fade show active"
                      id="allpatients"
                      role="tabpanel"
                      aria-labelledby="allpatients-tab"
                    >
                      <ul class="new-profile-list">
                        {this.props.getNotification.notification.total.length >
                        0 ? (
                          this.props.getNotification.notification.total.map(
                            (es) => (
                              <li class="np-item">
                                <div class="np-item d-flex flex-wrap">
                                  <div class="person-details-box d-flex align-items-center flex-grow-1">
                                    <div class="person-text-box">
                                      <h3 style={{ fontWeight: "400" }}>
                                        {es.text}
                                      </h3>
                                      {moment(es.created_on).calendar()}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )
                        ) : (
                          <NoRecord
                            header="No New Notifications"
                            text="bridging the gaps in Mental Healthcare system by bringing all the stakeholders closer to each other using updated technology"
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  getStaff: getStaff(state),
  getNotification: getNotification(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadNotification: () => dispatch(loadNotification()),
  loadStaff: () => dispatch(loadStaff()),
  deleteStaff: (id, callback) => dispatch(deleteStaff(id, callback)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notification)
);
