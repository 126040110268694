import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../include/images/yes-mindy-main-logo-white-color.png";

class Appointment extends Component {
  state = {};

  navigate = (e, url) => {
    localStorage.setItem("landingPage", "appointment");
    e.preventDefault();
    this.props.history.push(url);
  };

  render() {
    console.log("qwe", this.props.match.params.profile);
    return (
      <div class="member-container">
        <div class="top-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div class="member-box d-flex login-form">
          <div class="member-left">
            <div class="ml-box">
              <h1>
                Building A Professional Team For Your Complete Mental Health
                Needs
              </h1>
            </div>
          </div>
          <div class="member-right">
            <div class="member-right-content d-md-flex align-items-center">
              <div class="mr-box">
                <div class="form-group">
                  <Link
                    onClick={(e) =>
                      this.navigate(
                        e,
                        `/patient/sign-in/appointment/${
                          this.props.match.params.profile
                            ? this.props.match.params.profile
                            : ""
                        }`
                      )
                    }
                    class="btn btn-lg btn-secondary btn-block"
                  >
                    <span>Old User - Continue</span>
                  </Link>
                </div>
                <div class="form-group">
                  <Link
                    onClick={(e) =>
                      this.navigate(
                        e,
                        `/patient/sign-up/appointment/${
                          this.props.match.params.profile
                            ? this.props.match.params.profile
                            : ""
                        }`
                      )
                    }
                    class="btn btn-lg btn-secondary btn-block"
                  >
                    <span>New User - Begin</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Appointment;
