import React from "react";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";
import image1 from "../../include/images/community-hero-img.svg";
import image11 from "../../include/images/community-health-icon1.svg";
import Form from "./../common/form/form";
import { Link } from "react-router-dom";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import {
  getMisc,
  loadConstant,
  loadBlogCategory,
  loadBlog,
  loadKeyword,
  addCustomerquery,
  loadFeaturedBlog,
  loadfBlog,
} from "../../store/misc";
import Joi from "joi-browser";
import mindIcon from "../../include/images/expert-mind-icon.svg";
import Blog from "./../home/common/blog";
import modelCloseIcon from "../../include/images/modal-close-icon.svg";
import { Modal } from "react-bootstrap";

class ClinicalCommunity extends Form {
  state = {
    formType: "Health Professional",
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    type_text: "Media & Updates",
    formSuccessModel: false,
    data: {
      name1: "",
      expreince1: "",
      email1: "",
      designation1: "",
      name2: "",
      expreince2: "",
      email2: "",
      designation2: "",
    },
    errors: {},
  };

  toggleFormSuccess = () => {
    this.setState({ formSuccessModel: !this.state.formSuccessModel });
  };
  schema = {
    name1: Joi.string().allow(""),
    email1: Joi.string().allow(""),
    expreince1: Joi.string().allow(""),
    designation1: Joi.string().allow(""),
    name2: Joi.string().allow(""),
    email2: Joi.string().allow(""),
    expreince2: Joi.string().allow(""),
    designation2: Joi.string().allow(""),
  };

  doSubmit = () => {
    const {
      name1,
      email1,
      designation1,
      expreince1,
      name2,
      email2,
      expreince2,
      designation2,
    } = this.state.data;
    const data = {
      name: name1 ? name1 : name2,
      email: email1 ? email1 : email2,
      expreince: expreince1 ? expreince1 : expreince2,
      designation: designation1 ? designation1 : designation2,
      formType: name2 ? "Voice For Mental Health" : this.state.formType,
      type: expreince2 ? 2 : 1,
    };
    this.props.addCustomerquery(data, this.callBack);
  };

  callBack = (res) => {
    if (res && res.status === 200) {
      this.props.loadConstant();
      this.props.loadBlogCategory();
      this.props.loadBlog({ published: true });

      this.props.loadKeyword();
      this.toggleFormSuccess();
      this.setState({
        data: {
          name1: "",
          email1: "",
          designation1: "",
          expreince1: "",
          name2: "",
          email2: "",
          designation2: "",
          expreince2: "",
        },
      });
    }
  };
  componentDidMount = () => {
    this.props.loadfBlog();
    if (this.props.location.join === "Join") {
      var elmnt = document.getElementById("content");
      elmnt.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
    this.props.loadConstant();
    this.props.loadBlogCategory();
    this.props.loadBlog({ published: true });
    this.props.loadFeaturedBlog();
    this.props.loadKeyword();
  };

  myFunction = (text) => {
    this.setState({ type_text: text });
    var elemnt = document.getElementById("typee1");
    elemnt.scrollIntoView(true);
  };

  render() {
    const { constants, blogCategory, blog, keyword, featuredblog } =
      this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    const {
      name1,
      email1,
      expreince1,
      designation1,
      name2,
      email2,
      expreince2,
      designation2,
    } = this.state.data;
    const shuffled = [...keyword].sort(() => 0.5 - Math.random());
    let selected = shuffled.slice(0, 10);
    const featured = featuredblog;
    const home = blog
      .filter((i) => i.published === true && i.page === "COMMUNITY")
      .slice(0, 3);
    var last_element = featured; //.slice(-1);
    const type = this.props.getMisc.fblog.filter(
      (i) => i.type_text == this.state.type_text
    );
    console.log("---->", type, this.state.type_text);

    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />
        <div class="outer-container">
          <div class="community-hero-container">
            <div class="container container1">
              <div class="row flex-row-reverse align-items-end">
                <div class="col-xl-4 offset-xl-0 col-lg-4">
                  <div class="community-hero-content">
                    <p>
                      <strong>Let's stand together</strong>Join our mental
                      health journey to create a safe space for people to share
                      their stories & learn more with our professionals
                    </p>
                  </div>
                </div>
                <div class="col-xl-8 col-lg-8">
                  <div class="community-hero-img">
                    <img src={image1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-container community-qoute-conainer">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  {last_element.map((e, i) => (
                    <div class="mt-2 cq-content-outer d-flex flex-wrap">
                      <div class="cq-img-box">
                        <div class="cq-img">
                          <img
                            src={
                              process.env.REACT_APP_S3URL + e.featured_image_url
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="cq-content">
                        <div class="heading">
                          <Link to={{ pathname: "/blog-detail/" + e.url }}>
                            <h5>{e.title}</h5>
                          </Link>
                        </div>
                        <blockquote>
                          <HTMLEllipsis
                            unsafeHTML={e.text}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </blockquote>
                        {e.button_label && (
                          <div class="cq-btn">
                            <a
                              href={e.button_link}
                              target="_blank"
                              class="btn btn-default btn-lg"
                            >
                              {e.button_label}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div class="content-container pb-0">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading text-center">
                    <h4>
                      Stay In The Know <span>With Mindy</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 offset-md-0">
                  <ul class="home-services-list responsive-services-list d-flex flex-wrap">
                    {blogCategory.map((e, i) => (
                      <li class="hsl-item">
                        <Link to={{ pathname: "/blog", state: e.name }}>
                          <div class="hsl-box">
                            <div class="hsl-bg">
                              <img
                                src={process.env.REACT_APP_S3URL + e.image}
                                alt=""
                              />
                            </div>
                            <div class="hsl-content">{e.name}</div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="keywords-container overflow-hidden">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="heading-with-line grey">
                    <h3>
                      <span>Frequent Key Words</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10 offset-lg-1 col-md-12 offset-md-0">
                  <div class="keywords-box">
                    <ul class="community-kw-list d-flex flex-wrap">
                      {selected.map((e, i) => (
                        <li>
                          <Link
                            to={{
                              pathname: "/blog",
                              keyword: e.title,
                            }}
                            class="community-kw-link"
                          >
                            {e.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container join-community-conainer" id="content">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading text-center">
                    <h4>
                      Join our <span>Community</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-5 mb-lg-0">
                  <div className="join-community-content d-flex flex-column">
                    <h2>
                      For Health <br />
                      Professionals
                    </h2>
                    <p>
                      We would love to partner with you or work with you if we
                      share the passion to deliver quality driven Metal Health
                      services. We will be your companion in your mission.
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <div className="jc-form-content d-flex mt-auto">
                        <div className="fc-right">
                          {this.renderQuery("name1", "Name")}
                          {this.renderQuery("email1", "Email ID")}
                          {this.renderQuery("designation1", "Designation")}
                          {this.renderQuery(
                            "expreince1",
                            "Years of Experience"
                          )}
                          <div className="form-group mb-0">
                            <button
                              type="submit"
                              disabled={
                                !name1 | !email1 | !designation1 | !expreince1
                              }
                              className={
                                !name1 | !email1 | !designation1 | !expreince1
                                  ? "btn btn-primary disabled"
                                  : "btn btn-primary"
                              }
                            >
                              Join now
                            </button>
                          </div>
                        </div>
                        <div className="jc-form-img">
                          <img src={image11} alt="" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="join-community-content right">
                    <h2>
                      Become A Voice For <br />
                      Mental Health
                    </h2>
                    <p>
                      We believe what community as a whole can do for the field
                      of Mental Health cannot be done by one. Help us bring the
                      transformation.
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <div className="jc-form-content d-flex mt-auto">
                        <div className="fc-right">
                          {this.renderQuery("name2", "Name")}
                          {this.renderQuery("email2", "Email ID")}
                          {this.renderQuery(
                            "designation2",
                            "How would you like to be involved"
                          )}
                          {this.renderQuery("expreince2", "Location")}
                          <div className="form-group mb-0">
                            <button
                              type="submit"
                              disabled={
                                !name2 | !email2 | !designation2 | !expreince2
                              }
                              className={
                                !name2 | !email2 | !designation2 | !expreince2
                                  ? "btn btn-primary disabled"
                                  : "btn btn-primary"
                              }
                            >
                              Join now
                            </button>
                          </div>
                        </div>
                        <div className="jc-form-img">
                          <img src={image11} alt="" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container mental-health-conainer" id="typee1">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading text-center">
                    <h4>
                      Mental Heath <span>Limelight</span>
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="mental-health-content">
                    {type.map((e, i) => (
                      <div class="mh-sign-post d-flex flex-wrap mt-2">
                        <div class="mh-sign-post-img">
                          <Link to={{ pathname: "/blog-detail/" + e.url }}>
                            <img
                              src={
                                process.env.REACT_APP_S3URL +
                                e.featured_image_url
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div class="mh-sign-post-content">
                          <Link
                            to={{ pathname: "/blog-detail/" + e.url }}
                            style={{ display: "unset" }}
                          >
                            <h3>{e.title}</h3>
                          </Link>
                          <h6>
                            By <strong>{e.author}</strong>
                          </h6>
                          <p>
                            <HTMLEllipsis
                              unsafeHTML={e.text}
                              maxLine="4"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </p>
                          <div class="mh-read_more">
                            <Link to={{ pathname: "/blog-detail/" + e.url }}>
                              Read More{" "}
                              <span>
                                <i class="fas fa-caret-right"></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}

                    <ul class="mh-sign-post-list d-flex flex-wrap">
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.myFunction("Media & Updates")}
                          className="mh-sign-post-link"
                        >
                          Media &<br /> Updates
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          className="mh-sign-post-link"
                          onClick={() =>
                            this.myFunction("Awards and Achievements")
                          }
                        >
                          Awards
                          <br /> & Achievements
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            this.myFunction("YesMindy Affiliations")
                          }
                          className="mh-sign-post-link"
                        >
                          YesMindy
                          <br /> Affiliations
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.myFunction("Social Outreach")}
                          className="mh-sign-post-link"
                        >
                          Social
                          <br /> Outreach
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.myFunction("Online Engagements")}
                          className="mh-sign-post-link"
                        >
                          Online
                          <br />
                          Engagements
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            this.myFunction("Mental Health Influencers")
                          }
                          className="mh-sign-post-link"
                        >
                          Mental Health <br /> Influencers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Blog blog={home} />
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-dialog modal-dialog-centered mt-0"
          className="modal fade form-popup show"
          show={this.state.formSuccessModel}
          onHide={this.toggleFormSuccess}
        >
          <button
            onClick={this.toggleFormSuccess}
            type="button"
            className="close"
          >
            <img src={modelCloseIcon} alt="" onClick={this.toggleFormSuccess} />
          </button>

          <div className="modal-body d-flex align-items-center justify-content-center text-center">
            <div className="popup-content d-flex  flex-column align-items-center justify-content-center">
              <img src={mindIcon} alt="" />
              <h3>
                Thank you for contacting us. We will get back to you as soon as
                possible.
              </h3>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadBlogCategory: () => dispatch(loadBlogCategory()),
  loadBlog: () => dispatch(loadBlog()),
  loadfBlog: () => dispatch(loadfBlog()),
  loadFeaturedBlog: () => dispatch(loadFeaturedBlog()),
  loadKeyword: () => dispatch(loadKeyword()),
  addCustomerquery: (param, callback) =>
    dispatch(addCustomerquery(param, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClinicalCommunity);
