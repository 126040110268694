import React from 'react'
import Joi from 'joi-browser'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Form from '../../common/form/form'
import { loadSeo } from '../../../store/seo'
import AlertError from '../../common/alertError'
import SubmitBtn from '../../common/form/submitBtn'
import { Link, withRouter } from 'react-router-dom'
import AlertSuccess from '../../common/alertSuccess'
import { doctorSignIn } from '../../../store/doctor'
import {
  loginFailed,
  loginSuccess,
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  usernameRequired,
} from '../../common/misc'
import { setDoctorToken } from '../../services/localStorageServices'
import logo from '../../../include/images/yes-mindy-main-logo-white-color.png'

class SignIn extends Form {
  state = {
    btnClass: 'btn btn-lg btn-primary btn-block',
    loading: false,
    data: {
      username: '',
      password: '',
    },
    errors: {
      username: '',
      password: '',
    },
  }

  componentDidMount = () => {
    this.props.loadSeo('doctor-login')
  }

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label('Password')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = passwordRequired
              break

            case 'string.min':
              err.message = passwordInvalid
              break

            case 'string.max':
              err.message = passwordInvalidMax
              break
          }
        })
        return errors
      }),
    username: Joi.string()
      .required()
      .label('Username')
      .error(() => {
        return { message: usernameRequired }
      }),
  }

  doSubmit = () => {
    if (!this.state.loading) {
      this.setState({
        btnClass: 'btn btn-lg btn-primary btn-block disabled',
        loading: true,
      })
      const { data } = this.state
      this.props.doctorSignIn(data, this.callback)
    }
  }

  callback = res => {
    this.setState({
      btnClass: 'btn btn-lg btn-primary btn-block',
      loading: false,
    })
    if (res && res.status === 200) {
      const { xAuthToken, xRefreshToken } = res.data
      setDoctorToken(xRefreshToken, xAuthToken)
      if (!res.data.data.is_approved)
        return this.props.history.push('/doctor/sign-up/personal-detail')
      this.props.history.push('/doctor/dashboard')
    } else {
      toast(<AlertError message={loginFailed} />)
    }
  }

  render() {
    return (
      <div className='member-container'>
        <div className='top-logo'>
          <a href='/'>
            {' '}
            <img src={logo} alt='' />
          </a>
        </div>
        <div className='member-bg hero-background1'></div>
        <div className='member-box d-flex login-form'>
          <div className='member-left'>
            <div className='ml-box'>
              <h1>YesMindy.</h1>
              <p className='mb-0'>Please enter your password to continue</p>
            </div>
          </div>
          <div className='member-right'>
            <div className='member-right-content d-md-flex align-items-center'>
              <div className='mr-box'>
                <div className='mr-head'>
                  <h2>Login</h2>
                  <p className='login-text'>
                    New user? <Link to='sign-up'>Sign Up</Link>
                  </p>
                </div>
                <form action='' onSubmit={this.handleSubmit}>
                  <div className='form-box'>
                    {this.renderInput('username', 'Mobile / Email')}
                    {this.renderPasswordInput('password', 'Password')}

                    <div className='form-group'>
                      <SubmitBtn
                        label='Login'
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                    <p className='forgot-password text-center'>
                      <Link to='forget-password'>Forgot/Set Password</Link>
                    </p>
                    <div class='seprator'>or</div>
                    <div class='added-button'>
                      <div class='login-as-text-box text-center'>
                        <h5>
                          <Link to='/counsellor/login'>
                            Login as a Counsellor
                          </Link>
                        </h5>
                      </div>
                      {/* <div class="seprator">or</div> */}
                      <div class='login-as-text-box text-center'>
                        <h5>
                          <Link to='/staff/sign-in'>Login as a Staff</Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  doctorSignIn: (param, callback) => dispatch(doctorSignIn(param, callback)),
  loadSeo: page_key => dispatch(loadSeo(page_key)),
})
export default withRouter(connect(null, mapDispatchToProps)(SignIn))
