import Joi from 'joi-browser'
import moment from 'moment'
import React, { Component } from 'react'
import Form from '../../common/form/form'
import SelectSearchAsync from '../../common/form/selectSearchAsync'
import SelectSearch from '../../common/form/selectSearch'

import SubmitBtn from '../../common/form/submitBtn'
import { getPatient, patientSearch } from '../../../store/patient'
import Header from './header'
import LeftBar from './leftBar'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
  createOrderByDoctor,
  getAppointment,
  loadDate,
  loadSlot,
} from '../../../store/appointment'
import { toast } from 'react-toastify'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { getDoctor } from '../../../store/doctor'
import ReactTooltip from 'react-tooltip'

class NewAppointment extends Form {
  state = {
    loader: false,
    btnClass: 'btn btn-primary btn-lg',
    data: {
      patient: '',
      date: '',
      time: '',
      fee: '',
      type: '',
      isGetPayment: false,
    },
    errors: {},
    sidebarToogle: false,
  }

  toggleSidebar = () => {
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  getTimeStops = (start, end) => {
    var startTime = moment(start, 'HH:mm')
    var endTime = moment(end, 'HH:mm')

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day')
    }

    var timeStops = []

    while (startTime <= endTime) {
      timeStops.push({
        name: new moment(startTime).format('hh:mm A'),
        value: new moment(startTime).format('HH:mm'),
      })
      startTime.add(15, 'minutes')
    }
    return timeStops
  }
  schema = {
    patient: Joi.object()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'object.base':
              err.message = 'Patient Required'
              break
          }
        })
        return errors
      }),
    type: Joi.object()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'object.base':
              err.message = 'Type Required'
              break
          }
        })
        return errors
      }),
    date: Joi.object()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'date.base':
              err.message = 'Date required'
              break
          }
        })
        return errors
      }),

    time: Joi.object().error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'object.base':
            err.message = 'Time required'
            break
        }
      })
      return errors
    }),

    fee: Joi.number().error(errors => {
      errors.forEach(err => {
        switch (err.type) {
          case 'number.base':
            err.message = 'Fee required'
            break
        }
      })
      return errors
    }),

    isGetPayment: Joi.boolean(),
  }
  componentDidMount() {
    document.body.classList.remove('p-0')
    document.body.classList.add(
      this.props.history.location.pathname !== '/staff/newAppointment'
        ? 'dashboard'
        : 'dashboard-staff'
    )
    this.props.patientSearch({
      sort_by: 'created_on',
      sort: 'desc',
    })
    this.props.loadDate()
  }

  updateData = data => {
    this.setState({ data })
    console.log(data)
  }

  loadOptions = inputValue => {
    this.props.patientSearch({
      search: inputValue,
      sort_by: 'created_on',
      sort: 'desc',
    })
    console.log(inputValue)
  }
  updateStep = step => this.setState({ step })

  onChange = res => {
    const patient = res.currentTarget
    const { data, errors } = this.state
    data.patient = patient
    errors.patient = ''
    this.setState({ data, errors })
  }

  onChangeDate = res => {
    const date = res.currentTarget
    const { data, errors } = this.state
    data.date = date
    errors.date = ''
    this.setState({ data, errors })
    this.props.loadSlot(
      this.props.doctorProfile._id,
      date.value,
      this.state.data.type.value === 'walkin'
        ? 'clinic'
        : this.state.data.type.value
    )
  }

  onChangeType = res => {
    const type = res.currentTarget
    const { data, errors } = this.state
    if (type.value === 'walkin') {
      data.date = {
        name: this.props.getAppointment.dates[0].date,
        value: this.props.getAppointment.dates[0].date,
      }
      this.props.loadSlot(
        this.props.doctorProfile._id,
        this.props.getAppointment.dates[0].date,
        type.value === 'walkin' ? 'clinic' : type.value
      )
    }
    data.type = type
    errors.type = ''
    this.setState({ data, errors })
  }

  onChangeTime = res => {
    const time = res.currentTarget
    const { data, errors } = this.state
    data.time = time
    errors.time = ''
    this.setState({ data, errors })
  }

  doSubmit = () => {
    const { patient, date, time, fee, type, isGetPayment } = this.state.data
    const timeArr = time.value.split(':')
    const dateTime = moment(date.value)

    const payload = {
      patient_id: patient.value,
      appointment_time: dateTime.format(
        'YYYY-MM-DD[T' + timeArr[0] + ':' + timeArr[1] + ':00.000Z]'
      ),
      fees: fee,
      type: type.value,
      isGetPayment,
    }
    console.log(date, payload, 'val submit')
    if (!this.state.loader) {
      this.setState({
        btnClass: 'btn btn-primary btn-lg disabled',
        loader: true,
      })
      this.props.createOrderByDoctor(payload, this.callBack)
    }
  }

  callBack = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loader: false,
    })
    if (res && res.status === 200) {
      toast(<AlertSuccess message={'Infomation saved.'} />)
      this.props.history.goBack()
    } else {
      toast(<AlertError message={'Something went wrong.'} />)
    }
  }

  render() {
    const times = this.getTimeStops('00:00', '23:45')
    const patients = this.props.patients
    const options = patients.map(e => ({
      value: e._id,
      name: e.first_name + ' ' + e.last_name + ' (' + e.mobile + ')',
    }))
    return (
      <div className={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
        {this.props.history.location.pathname !== '/staff/newAppointment' && (
          <LeftBar />
        )}
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div className='dashboard-main-container'>
          <div className='dashboard-content-box'>
            <div className='d-flex align-items-start'>
              <h4>
                New Appointment
                <span className='ml-2' style={{ fontSize: '14px' }}>
                  (Only for your existing patients. You can add new patient from
                  dashboard)
                </span>
              </h4>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className='dc-body d-flex flex-wrap align-items-lg-center'>
                <div className='dc-left'>
                  <h4 className='d-block d-xl-none'>Personal Details</h4>
                  <div className='dc-form-box'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <SelectSearchAsync
                          name='patient'
                          options={options}
                          label='Patient'
                          error={this.state.errors.patient}
                          value={this.state.data.patient}
                          onChange={this.onChange}
                          loadOptions={this.loadOptions}
                        />
                      </div>
                    </div>

                    {this.state.data.patient && (
                      <>
                        <div className='row'>
                          <div className='col-md-6'>
                            <SelectSearch
                              name='Type'
                              options={[
                                { name: 'online', value: 'online' },
                                { name: 'clinic', value: 'clinic' },
                                { name: 'walk-in', value: 'walkin' },
                              ]}
                              label='Type'
                              error={this.state.errors.type}
                              value={this.state.data.type}
                              onChange={this.onChangeType}
                            />
                          </div>{' '}
                          <div className='col-md-6'>
                            {this.renderInput('fee', 'Fees', 'number')}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <SelectSearch
                              name='date'
                              options={this.props.getAppointment.dates.map(
                                e => ({ name: e.date, value: e.date })
                              )}
                              label='Date'
                              error={this.state.errors.date}
                              value={this.state.data.date}
                              onChange={this.onChangeDate}
                              isDisabled={
                                this.state.data.type &&
                                this.state.data.type.value === 'walkin'
                              }
                            />
                          </div>
                          <div className='col-md-6'>
                            <SelectSearch
                              name='time'
                              options={this.props.getAppointment.slots.map(
                                e => ({
                                  name: e.is_available
                                    ? moment(e.start).utc().format('hh:mm A')
                                    : moment(e.start).utc().format('hh:mm A') +
                                      '-' +
                                      'Not available',
                                  value: moment(e.start).utc().format('HH:mm'),
                                  isDisabled: !e.is_available,
                                })
                              )}
                              label='Time'
                              error={this.state.errors.time}
                              value={this.state.data.time}
                              onChange={this.onChangeTime}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div class='form-check form-check-inline'>
                              <input
                                type='checkbox'
                                checked={this.state.isGetPayment}
                                id={1}
                                class='form-check-input'
                                name='isGetPayment'
                                onChange={e =>
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      isGetPayment: e.target.checked,
                                    },
                                  })
                                }
                              />
                              <label class='form-check-label ml-4' htmlFor={1}>
                                Get Payment
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='prescription-btn d-flex justify-content-between align-items-center'>
                <a
                  class='btn btn-outline no-shadow'
                  onClick={() => this.props.history.goBack()}>
                  Back
                </a>
                <SubmitBtn
                  label='Save'
                  loading={this.state.loading}
                  btnClass={this.state.btnClass}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  patientSearch: param => dispatch(patientSearch(param)),
  loadDate: () => dispatch(loadDate()),
  createOrderByDoctor: (param, callback) =>
    dispatch(createOrderByDoctor(param, callback)),
  loadSlot: (drId, date, type) => dispatch(loadSlot(drId, date, type)),
})
const mapStateToProps = state => ({
  patients: getPatient(state).patientList,
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewAppointment)
)
