import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chat from './chat'
import { withRouter } from 'react-router-dom'
import Prescription from './prescription/prescription'
import Followup from './followUp/followUp'
import CaseRecords from './caseRecords/caseRecords'
import { loadList } from '../../../store/prescription'
import TaskTab from './taskTab'
import ReactTooltip from 'react-tooltip'
import FollowUpNotes from './prescription/followUpNotes'

class SideTabs extends Component {
  state = {
    step: 2,
  }

  updateStep = (e, step) => {
    if (e) e.preventDefault()
    this.setState({ step })
  }

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <Chat
            updateStep={this.updateStep}
            step={this.state.step}
            appointment_date={this.props.appointment_date}
          />
        )
      case 2:
        return (
          <Prescription
            updateStep={this.updateStep}
            step={this.state.step}
            appointment_date={this.props.appointment_date}
          />
        )
      case 3:
        return (
          <FollowUpNotes
            updateStep={this.updateStep}
            step={this.state.step}
            appointment_date={this.props.appointment_date}
          />
          // <Followup
          //   updateStep={this.updateStep}
          //   step={this.state.step}
          //   appointment_date={this.props.appointment_date}
          // />
        )
      case 4:
        return (
          <CaseRecords
            updateStep={this.updateStep}
            step={this.state.step}
            appointment_date={this.props.appointment_date}
          />
        )
      case 5:
        return (
          <TaskTab
            updateStep={this.updateStep}
            step={this.state.step}
            appointment_date={this.props.appointment_date}
          />
        )

      default:
        break
    }
  }

  render() {
    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <div class='pannel-right'>
          <div class='pr-box bg-white border-radius2'>
            <div class='pr-head d-none d-md-block'>
              <ul class='nav nav-tabs nav-tabs2' id='myTab' role='tablist'>
                <li class='nav-item' role='presentation'>
                  <a
                    data-tip='Prescriptions can only be filled after the appointment is over and CRF is completed.'
                    className={
                      this.state.step === 2 ? 'nav-link active' : 'nav-link'
                    }
                    id='prescriptions-tab'
                    data-toggle='tab'
                    role='tab'
                    aria-controls='prescriptions'
                    aria-selected='true'>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={e => this.updateStep(null, 2)}>
                      Prescriptions
                    </span>
                  </a>
                </li>
                <li class='nav-item' role='presentation'>
                  <a
                    data-tip='Follow up note is only from second appointment onwards'
                    className={
                      this.state.step === 3 ? 'nav-link active' : 'nav-link'
                    }
                    id='followup-tab'
                    data-toggle='tab'
                    role='tab'
                    aria-controls='followup'
                    aria-selected='true'>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={e => this.updateStep(null, 3)}>
                      Follow Up
                    </span>
                  </a>
                </li>
                <li class='nav-item' role='presentation'>
                  <a
                    data-tip="CRF will be considered complete only after the respective expert's diagnosis is made. "
                    className={
                      this.state.step === 4 ? 'nav-link active' : 'nav-link'
                    }
                    id='case-records-tab'
                    data-toggle='tab'
                    role='tab'
                    aria-controls='case-records'
                    aria-selected='true'>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={e => this.updateStep(null, 4)}>
                      Case Records
                    </span>
                  </a>
                </li>
                <li class='nav-item' role='presentation'>
                  <a
                    data-tip='List of patients with current date appointment only. Task can be allotted to any patient from toolbox.'
                    className={
                      this.state.step === 5 ? 'nav-link active' : 'nav-link'
                    }
                    id='tasks-tab'
                    data-toggle='tab'
                    href='#tasks'
                    role='tab'
                    aria-controls='tasks'
                    aria-selected='false'>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={e => this.updateStep(null, 5)}>
                      Tasks
                    </span>
                  </a>
                </li>
                <li class='nav-item' role='presentation'>
                  <a
                    data-tip='Chats will remain active for 3 days. Active chats can be accessed going to the specific date of appointment. '
                    id='chats-tab'
                    data-toggle='tab'
                    role='tab'
                    aria-controls='chats'
                    aria-selected='true'
                    className={
                      this.state.step === 1 ? 'nav-link active' : 'nav-link'
                    }>
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={e => this.updateStep(null, 1)}>
                      Chats
                    </span>
                  </a>
                </li>
              </ul>
            </div>

            <div class='pr-body chatbot-body add-scrollbar scrollbar-dynamic'>
              <div
                className={
                  this.state.step === 2
                    ? 'tab-pane card show active'
                    : 'tab-pane card fade d-none'
                }
                id='prescriptions'
                role='tabpanel'
                aria-labelledby='prescriptions-tab'>
                <div
                  onClick={e => this.updateStep(null, 2)}
                  class='card-header'>
                  Prescriptions
                </div>
                <div
                  className={
                    this.state.step === 2
                      ? 'content-body show'
                      : 'content-body fade'
                  }>
                  {this.state.step === 2 && this.leftSideStep()}
                </div>
              </div>

              <div
                className={
                  this.state.step === 3
                    ? 'tab-pane card show active'
                    : 'tab-pane card fade d-none'
                }
                id='prescriptions'
                role='tabpanel'
                aria-labelledby='prescriptions-tab'>
                <div
                  onClick={e => this.updateStep(null, 3)}
                  class='card-header'>
                  Follow up
                </div>
                <div
                  className={
                    this.state.step === 3
                      ? 'content-body show'
                      : 'content-body fade'
                  }>
                  {this.state.step === 3 && this.leftSideStep()}
                </div>
              </div>

              <div
                className={
                  this.state.step === 4
                    ? 'tab-pane card show active'
                    : 'tab-pane card fade d-none'
                }
                id='prescriptions'
                role='tabpanel'
                aria-labelledby='prescriptions-tab'>
                <div
                  onClick={e => this.updateStep(null, 4)}
                  class='card-header'>
                  Case Records
                </div>
                <div
                  className={
                    this.state.step === 4
                      ? 'content-body show'
                      : 'content-body fade'
                  }>
                  {this.state.step === 4 && this.leftSideStep()}
                </div>
              </div>

              <div
                className={
                  this.state.step === 5
                    ? 'tab-pane card show active'
                    : 'tab-pane card fade d-none'
                }
                id='prescriptions'
                role='tabpanel'
                aria-labelledby='prescriptions-tab'>
                <div
                  onClick={e => this.updateStep(null, 5)}
                  class='card-header'>
                  Tasks
                </div>
                <div
                  className={
                    this.state.step === 5
                      ? 'content-body show'
                      : 'content-body fade'
                  }>
                  {this.state.step === 5 && this.leftSideStep()}
                </div>
              </div>

              <div
                className={
                  this.state.step === 1
                    ? 'tab-pane card show active'
                    : 'tab-pane card fade  d-none'
                }
                id='chats'
                role='tabpanel'
                aria-labelledby='chats-tab'>
                <div
                  onClick={e => this.updateStep(null, 1)}
                  class='card-header'>
                  Chats
                </div>
                <div
                  className={
                    this.state.step === 1
                      ? 'content-body show'
                      : 'content-body fade'
                  }>
                  {this.state.step === 1 && this.leftSideStep()}
                </div>
              </div>
            </div>
            {/* <div class="pr-body add-scrollbar scrollbar-dynamic">
              <div class="tab-content" id="myTabContent">
                {this.leftSideStep()}
              </div>
            </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(connect(null, null)(SideTabs))
