import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
//import moment from "moment";

const slice = createSlice({
  name: "diary",
  initialState: {
    diaries: [],
    patientDiary: [],
    lastRecords: {},
    loading: false,
    lastFetch: null,
  },
  reducers: {
    diaryRequested: (diary, action) => {
      diary.loading = true;
      diary.lastRecords = {};
    },

    diaryReceived: (diary, action) => {
      diary.diaries = action.payload.data;
      diary.lastRecords = action.payload.lastRecords;
      diary.loading = false;
      diary.lastFetch = Date.now();
    },

    diaryRequestFailed: (diary, action) => {
      diary.loading = false;
    },
    diaryPatientRequested: (diary, action) => {
      diary.loading = true;
      diary.lastRecords = {};
    },

    diaryPatientReceived: (diary, action) => {
      diary.patientDiary = action.payload.data;
      diary.lastRecords = action.payload.lastRecords;
      diary.loading = false;
      diary.lastFetch = Date.now();
    },

    diaryPatientRequestFailed: (diary, action) => {
      diary.loading = false;
      diary.patientDiary = [];
    },
  },
});

export const {
  diaryRequested,
  diaryRequestFailed,
  diaryReceived,
  diaryPatientRequested,
  diaryPatientReceived,
  diaryPatientRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "diary/";

export const loadDiary = (params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      params,
      url: url,
      onStart: diaryRequested.type,
      onSuccess: diaryReceived.type,
      onError: diaryRequestFailed.type,
    })
  );
};
export const loadPatientDiary = (id, params) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url + "/byPatient/" + id,
      params,
      onStart: diaryPatientRequested.type,
      onSuccess: diaryPatientReceived.type,
      onError: diaryPatientRequestFailed.type,
    })
  );
};
export const addDiary = (data) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback: data.callback,
      url: url,
      method: "POST",
      data: data.data,
    })
  );
};

export const deleteDiary = (diary_no, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback: callback,
      url: url + diary_no,
      method: "DELETE",
    })
  );
};

export const updateDiary = (diary_no, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback: callback,
      url: url + diary_no,
      method: "PUT",
      data,
    })
  );
};

export const shareDiary = (diary_no, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      callback: callback,
      url: url + "share/" + diary_no,
      method: "PUT",
      data: { share: true },
    })
  );
};

export const getDiary = createSelector(
  (state) => state.entities.diary,
  (diary) => diary
);
