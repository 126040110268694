import React, { Component } from "react";
// import "./../../include/css/pdf.css";
import logo from "./../../include/images/pdf/yes-mindy-logo.png";
import footerLogo from "./../../include/images/pdf/footer-logo.png";

class PrescriptionDesign extends React.Component {
  render() {
    const ref = React.createRef();
    const options = {
      orientation: "landscape",
      unit: "in",
      format: [4, 2],
    };
    return (
      <div class="divTable">
        <div class="divTableHeading">
          <div class="divTableRow">
            <div class="divTableHead">
              <table>
                <tr>
                  <td colspan="2" align="right" valign="middle">
                    <img width="55" height="25" src={logo} alt="" />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ width: "50%", lineHeight: "17px" }}
                    align="left"
                    valign="top"
                  >
                    <b style={{ fontSize: "12px" }}>Dr. Saras Prasad</b>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      MBBS, MD
                    </p>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      Psychiatrist
                    </p>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      Regd : DMC/R/****
                    </p>
                    <p style={{ fontSize: "11px", fontWeight: "400" }}>
                      Noida, Utter Pradesh
                    </p>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      lineHeight: "17px",
                      align: "right",
                      valign: "top",
                    }}
                  >
                    <address style={{ fontSize: "11px", fontStyle: "normal" }}>
                      <p>
                        <strong>Clinic :</strong> Yatharth Hospital
                      </p>
                      <p>Noida Extension</p>
                    </address>
                    <a
                      style={{ fontSize: "11px", fontStyle: "normal" }}
                      href="tel:0120-4455541"
                    >
                      0120-4455541
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="divTableBody">
          <div class="divTableRow">
            <div class="divTableCell">
              <table style={{ fontSize: "12px", lineHeight: "18px" }}>
                <tr>
                  <td style={{ width: "46%", padding: "18px 0" }}>
                    <table>
                      <tr>
                        <td>
                          <strong>Name :</strong>
                        </td>
                        <td>David</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Age :</strong>
                        </td>
                        <td>24</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gender :</strong>
                        </td>
                        <td>M</td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "54%", padding: "18px 0" }}>
                    <table>
                      <tr>
                        <td>
                          <strong>UHID :</strong>
                        </td>
                        <td>1234455666</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Session date :</strong>
                        </td>
                        <td>24/07/2021</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mode of visit :</strong>
                        </td>
                        <td>Online</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    style={{ fontSize: "12px", lineHeight: "16px" }}
                  >
                    <b>Diagnosis</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style={{ padding: "20px 0 100px" }}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th style={{ width: "13%" }}>Formulation</th>
                          <th style={{ width: "16%" }}>Brand name</th>
                          <th style={{ width: "17%" }}>Composition</th>
                          <th style={{ width: "11%" }}>Dose</th>
                          <th style={{ width: "13%" }}>Frequency</th>
                          <th style={{ width: "11%" }}>Date</th>
                          <th style={{ width: "19%" }}>Instructions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            TAB
                          </td>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            Dicorate-ER
                          </td>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            Divalproex <br /> Sodium
                          </td>
                          <td align="left" valign="top">
                            250mg
                          </td>
                          <td align="left" valign="top">
                            1— x — 2
                          </td>
                          <td align="left" valign="top">
                            20 days
                          </td>
                          <td align="left" valign="top">
                            1 Tablet in the Morning and 2 at Night
                          </td>
                        </tr>
                        <tr>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            TAB
                          </td>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            Dicorate-ER
                          </td>
                          <td
                            align="left"
                            valign="top"
                            style={{ textTransform: "uppercase" }}
                          >
                            Divalproex <br /> Sodium
                          </td>
                          <td align="left" valign="top">
                            250mg
                          </td>
                          <td align="left" valign="top">
                            1— x — 2
                          </td>
                          <td align="left" valign="top">
                            20 days
                          </td>
                          <td align="left" valign="top">
                            1 Tablet in the Morning and 2 at Night
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="divTableRow">
            <div class="divTableCell">
              <table>
                <tr>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "54%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>Lab Investigations</b>
                    </p>
                    <p>CBC, RFT , Liver Funtions Test</p>
                  </td>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "46%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>Radiology Investigations</b>
                    </p>
                    <p>MRI Brain.</p>
                  </td>
                </tr>
                <tr>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "54%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>Special Advise</b>
                    </p>
                    <p>Do not consume alcohol</p>
                  </td>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "46%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>Referral</b>
                    </p>
                    <p>Clinical Psychologist</p>
                  </td>
                </tr>
                <tr>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "54%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>Follow Up</b>
                    </p>
                    <p>15 days</p>
                  </td>
                  <td
                    align="left"
                    valign="top"
                    style={{
                      width: "46%",
                      fontSize: "10px",
                      lineHeight: "14px",
                      paddingBottom: "22px",
                    }}
                  >
                    <p style={{ paddingBottom: "9px" }}>
                      <b>To Chemist</b>
                    </p>
                    <p>Dispense medicine only for 15 days</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="divTableFoot">
          <div class="divTableRow">
            <div class="divTableCell">
              <table class="footer-table">
                <thead>
                  <tr>
                    <td align="left" valign="top" colspan="2">
                      <p
                        style={{
                          fontSize: "10px",
                          lineHeight: "14px",
                          padding: "0 45px 15px",
                        }}
                      >
                        <b>Signature</b>
                      </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: "2px dashed #E4E4E4",
                        padding: "9px 0 9px 45px",
                      }}
                    >
                      <p style={{ fontSize: "8px", lineHeight: "14px" }}>
                        Please note that the prescription Is not a Medicolegal
                        document. For any emergency / severe <br /> complaints
                        kindly contact nearby hospital/ clinic (For physical
                        consultation
                      </p>
                    </td>
                    <td
                      align="right"
                      valign="top"
                      style={{
                        borderTop: "2px dashed #E4E4E4",
                        padding: "9px 45px 9px 0",
                        lineHeight: "9px",
                      }}
                    >
                      <table>
                        <tr>
                          <td align="right" valign="top">
                            <span
                              style={{ fontSize: "8px", lineHeight: "14px" }}
                            >
                              Powered By :
                            </span>
                          </td>
                          <td align="right" valign="top">
                            <img
                              style={{ display: "block" }}
                              width="53"
                              height="15"
                              src={footerLogo}
                              alt=""
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>{" "}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrescriptionDesign;
