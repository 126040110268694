import React, { Component } from "react";
import Joi from "joi-browser";
import loaderIcon from "../../../include/images/loading-icon.svg";
import { toast } from "react-toastify";
import Form from "./../../common/form/form";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";
import { withRouter } from "react-router-dom";
import { sendOtp } from "../../../store/patient";
import { mobileAvailability } from "../../../store/doctor";

import {
  mobileInvalid,
  mobileRequired,
  accountNotExist,
} from "../../common/misc";

import { connect } from "react-redux";
import SubmitBtn from "../../common/form/submitBtn";

class VerifyMobile extends Form {
  state = {
    type: 1,
    btnClass: "btn btn-lg btn-primary btn-block",
    loader: false,
    data: { mobile: "" },
    errors: { mobile: "" },
  };

  componentDidMount = () => {
    localStorage.removeItem("x-auth-token-doctor");
    localStorage.removeItem("x-refresh-token-doctor");
  };

  schema = {
    mobile: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        console.log(errors);
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = mobileRequired;
              break;

            case "number.max":
              err.message = mobileInvalid;
              break;

            case "number.min":
              err.message = mobileInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (!this.state.loader) {
      this.setState({
        loader: true,
        btnClass: "btn btn-lg btn-primary btn-block disabled",
      });
      const params = {
        mobile: this.state.data.mobile,
      };
      this.props.mobileAvailability(params, this.callback);
    }
  };

  callback = (res) => {
    this.setState({
      loader: false,
      btnClass: "btn btn-lg btn-primary btn-block",
    });
    if (res.status === 200) {
      toast(<AlertError message={accountNotExist} />);
    } else {
      const params = { mobile: this.state.data.mobile, type: "resetPassword" };
      this.props.sendOtp(params, this.otpCallBack);
    }
  };

  otpCallBack = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={res.data.otp} />);
      this.props.updateStep(2);
      this.props.updateVerifyMobile({
        otp: res.data.otp,
        type: this.state.type,
        mobile: this.state.data.mobile,
      });
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  render() {
    console.log(this.state);
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head mb-4">
              <div className="mr-step">Step 1/3</div>
              <h2>Setting password</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-box">
                <div className="form-group">
                  {this.renderInput("mobile", "Mobile Number")}
                </div>
                <div className="form-group">
                  <SubmitBtn
                    label="Continue"
                    loading={this.state.loader}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  mobileAvailability: (param, callback) =>
    dispatch(mobileAvailability(param, callback)),
  sendOtp: (param, callback) => dispatch(sendOtp(param, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(VerifyMobile));
