import React, { Component } from "react";
import logo from "../../../include/images/yes-mindy-color-logo-small.png";
import communicationIcon from "../../../include/images/communication-center-icon1.svg";
import dashIcon from "../../../include/images/dashboard-icon.svg";
import { getMisc, loadConstant } from "../../../store/misc";
import { connect } from "react-redux";
import { clearAllToken } from "../../services/localStorageServices";
import HtmlParser from "react-html-parser";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

const langs = [
  { Language: "English" },
  { Language: "Asami" },
  { Language: "Bengali" },
  { Language: "Gujarati" },
  { Language: "Hindi" },
  { Language: "Kannada" },
  { Language: "Malajalam" },
  { Language: "Marathi" },
  { Language: "Orija" },
  { Language: "Punjabi" },
  { Language: "Tamil" },
  { Language: "Telugu" },
  { Language: "Urdu" },
];
class Header extends Component {
  state = {
    cityLength: 5,
    languageLength: 5,
    toggle: false,
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
  };
  logOut = (e) => {
    this.setState({ patient: false });
    e.preventDefault();
    clearAllToken();
    this.props.history.push("/");
  };

  componentDidMount = () => {
    this.props.loadConstant();
  };

  render() {
    const { constants } = this.props.getMisc;
    const { helpline_no, header_email_address } = constants;
    return (
      <header id="header" className="header">
        <div class="header-upper d-flex align-items-center">
          <div class="container container1">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <ul class="query-list d-lg-flex align-items-center d-none">
                  <li>
                    <img src={communicationIcon} alt="" /> <span>Helpline</span>
                  </li>
                  <li>
                    {" "}
                    <a href="tel:9167 XXXX 12">{helpline_no}</a>
                  </li>
                  <li>
                    {" "}
                    <a href="mailto:demo@demo.com">{header_email_address}</a>
                  </li>
                </ul>
                {this.state.patient && (
                  <ul class="query-list d-flex align-items-lg-center ml-auto ">
                    <li>
                      <Link
                        to="/patient/dashboard"
                        class="header-right-box d-flex align-items-center justify-content-center"
                      >
                        <img src={dashIcon} alt="" />{" "}
                        <span class="d-none d-lg-block mr-1">Go to </span>{" "}
                        <span class="d-none d-sm-block">dashboard</span>
                      </Link>
                    </li>
                    {this.state.patient.first_name && (
                      <li>
                        {HtmlParser(
                          `Welcome, <b>${this.state.patient.first_name} </b>`
                        )}
                      </li>
                    )}
                    <li>
                      {" "}
                      <a href="#!" onClick={this.logOut}>
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <dic className="header-lower">
          <nav class="navbar navbar-expand-xl">
            <div class="container container1">
              <div class="header-logo-area d-flex ">
                <Link to="/" class="navbar-brand">
                  <img src={logo} alt="" />
                </Link>
              </div>

              <div class="header-right d-flex align-items-center mr-0">
                <div class="search-box d-none d-lg-block">
                  <input
                    type="text"
                    class="search-input"
                    value={this.props.name}
                    placeholder="Search an Expert"
                    onChange={this.props.setName}
                  />
                </div>
                <div
                  class={
                    this.state.toggle
                      ? "filter-menu dropdown d-none d-lg-block show"
                      : "filter-menu dropdown d-none d-lg-block"
                  }
                >
                  <div
                    class="dropdown-toggle filter-box d-flex align-items-center"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      this.setState({ toggle: !this.state.toggle });
                    }}
                  >
                    <span class="filter-menu-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0.703125 19.7344H2.8125V23.2969C2.8125 23.6852 3.12731 24 3.51562 24C3.90394 24 4.21875 23.6852 4.21875 23.2969V19.7344H6.32812C6.71644 19.7344 7.03125 19.4196 7.03125 19.0312V16.2188C7.03125 16.0323 6.95714 15.8535 6.82528 15.7215L4.21875 13.115V0.703125C4.21875 0.314812 3.90394 0 3.51562 0C3.12731 0 2.8125 0.314812 2.8125 0.703125V13.115L0.205969 15.7215C0.0741094 15.8535 0 16.0323 0 16.2188V19.0312C0 19.4196 0.314812 19.7344 0.703125 19.7344ZM1.40625 16.51L3.51562 14.4006L5.625 16.51V18.3281H1.40625V16.51Z" />
                        <path d="M12.7031 15.5156H14.8125C15.2008 15.5156 15.5156 15.2008 15.5156 14.8125V12C15.5156 11.8135 15.4416 11.6347 15.3097 11.5028L12.7031 8.89627V0.703125C12.7031 0.314812 12.3883 0 12 0C11.6117 0 11.2969 0.314812 11.2969 0.703125V8.89627L8.69034 11.5028C8.55848 11.6347 8.48438 11.8135 8.48438 12V14.8125C8.48438 15.2008 8.79919 15.5156 9.1875 15.5156H11.2969V23.2969C11.2969 23.6852 11.6117 24 12 24C12.3883 24 12.7031 23.6852 12.7031 23.2969V15.5156ZM9.89062 12.2912L12 10.1819L14.1094 12.2912V14.1094H9.89062V12.2912Z" />
                        <path d="M24 6.375C24 6.18853 23.9259 6.0097 23.794 5.8778L21.1875 3.27127V0.703125C21.1875 0.314812 20.8727 0 20.4844 0C20.0961 0 19.7812 0.314812 19.7812 0.703125V3.27127L17.1747 5.8778C17.0428 6.0097 16.9688 6.18853 16.9688 6.375V9.1875C16.9688 9.57581 17.2836 9.89062 17.6719 9.89062H19.7812V23.2969C19.7812 23.6852 20.0961 24 20.4844 24C20.8727 24 21.1875 23.6852 21.1875 23.2969V9.89062H23.2969C23.6852 9.89062 24 9.57581 24 9.1875V6.375ZM22.5938 8.48438H18.375V6.66623L20.4844 4.55686L22.5938 6.66623V8.48438Z" />
                      </svg>
                    </span>
                    <span>filters</span>
                  </div>
                  <div
                    class={
                      this.state.toggle
                        ? "dropdown-menu filter-dropdown-menu show"
                        : "dropdown-menu filter-dropdown-menu"
                    }
                  >
                    <div class="fdm-upper">
                      <div class="container">
                        <div class="fdm-upper-content w-100 d-md-flex flex-wrap">
                          <div class="filter-menu-item">
                            <div class="filter-menu-box">
                              <h6>Gender</h6>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="defaultCheck1"
                                  checked={this.props.params.gender.find(
                                    (v) => v === "M"
                                  )}
                                  onChange={() => this.props.changeGender("M")}
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck1"
                                >
                                  Male
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="defaultCheck2"
                                  checked={this.props.params.gender.find(
                                    (v) => v === "F"
                                  )}
                                  onChange={() => this.props.changeGender("F")}
                                />
                                <label
                                  class="form-check-label"
                                  for="defaultCheck2"
                                >
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="filter-menu-item">
                            <div class="filter-menu-box">
                              <h6>City</h6>

                              {this.props.filterCity
                                .slice(
                                  0,
                                  this.state.cityLength === "all"
                                    ? this.props.filterCity.length
                                    : 5
                                )
                                .map((city) => (
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id={city._id}
                                      checked={this.props.params.cityId.find(
                                        (v) => v === city._id
                                      )}
                                      onChange={() =>
                                        this.props.changeCity(city._id)
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for={city._id}
                                    >
                                      {city.city}
                                    </label>
                                  </div>
                                ))}
                              <br />
                              {this.state.cityLength === 5 && (
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ cityLength: "all" });
                                  }}
                                >
                                  Show more
                                </a>
                              )}
                            </div>
                          </div>

                          <div class="filter-menu-item">
                            <div class="filter-menu-box">
                              <h6>Fee Range</h6>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="fee1"
                                  checked={this.props.params.feesNumber.find(
                                    (v) => v === 1
                                  )}
                                  onChange={() => this.props.changeFees(1)}
                                />
                                <label class="form-check-label" for="fee1">
                                  Lesser than 500₹
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="fee2"
                                  checked={this.props.params.feesNumber.find(
                                    (v) => v === 2
                                  )}
                                  onChange={() => this.props.changeFees(2)}
                                />
                                <label class="form-check-label" for="fee2">
                                  Between 500 and 700 ₹
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="fee3"
                                  checked={this.props.params.feesNumber.find(
                                    (v) => v === 3
                                  )}
                                  onChange={() => this.props.changeFees(3)}
                                />
                                <label class="form-check-label" for="fee3">
                                  Between 700 and 1000 ₹
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="fee4"
                                  checked={this.props.params.feesNumber.find(
                                    (v) => v === 4
                                  )}
                                  onChange={() => this.props.changeFees(4)}
                                />
                                <label class="form-check-label" for="fee4">
                                  Between 1000 and 1500 ₹
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="fee5"
                                  checked={this.props.params.feesNumber.find(
                                    (v) => v === 5
                                  )}
                                  onChange={() => this.props.changeFees(5)}
                                />
                                <label class="form-check-label" for="fee5">
                                  More than 1500 ₹
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="filter-menu-item">
                            <div class="filter-menu-box">
                              <h6>Languages</h6>

                              {langs
                                .slice(
                                  0,
                                  this.state.languageLength === "all"
                                    ? langs.length
                                    : 5
                                )
                                .map((e) => (
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={this.props.params.language.find(
                                        (el) => el === e.Language
                                      )}
                                      id={e.Language}
                                      onChange={() =>
                                        this.props.changeLang(e.Language)
                                      }
                                    />
                                    <label
                                      class="form-check-label"
                                      for={e.Language}
                                    >
                                      {e.Language}
                                    </label>
                                  </div>
                                ))}
                              <br />
                              {this.state.languageLength === 5 && (
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ languageLength: "all" });
                                  }}
                                >
                                  Show more
                                </a>
                              )}
                            </div>
                          </div>
                          <div class="filter-menu-item">
                            <div class="filter-menu-box">
                              <h6>Experience</h6>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="exp1"
                                  checked={this.props.params.experienceNumber.find(
                                    (v) => v === 1
                                  )}
                                  onChange={() => this.props.changeExp(1)}
                                />
                                <label class="form-check-label" for="exp1">
                                  Lesser than 2 years
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="exp2"
                                  checked={this.props.params.experienceNumber.find(
                                    (v) => v === 2
                                  )}
                                  onChange={() => this.props.changeExp(2)}
                                />
                                <label class="form-check-label" for="exp2">
                                  Between 2 and 5 years
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="exp3"
                                  checked={this.props.params.experienceNumber.find(
                                    (v) => v === 3
                                  )}
                                  onChange={() => this.props.changeExp(3)}
                                />
                                <label class="form-check-label" for="exp3">
                                  Between 5 and 10 years
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="exp4"
                                  checked={this.props.params.experienceNumber.find(
                                    (v) => v === 4
                                  )}
                                  onChange={() => this.props.changeExp(4)}
                                />
                                <label class="form-check-label" for="exp4">
                                  Between 10 and 15 years
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="exp5"
                                  checked={this.props.params.experienceNumber.find(
                                    (v) => v === 5
                                  )}
                                  onChange={() => this.props.changeExp(5)}
                                />
                                <label class="form-check-label" for="exp5">
                                  More than 15 years
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fdm-lower">
                      <div class="container">
                        <div class="fdm-lower-content w-100 d-md-flex align-items-md-center justify-content-md-end">
                          <a
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ toggle: !this.state.toggle });
                              this.props.applyFilter();
                            }}
                            class="btn btn-secondary"
                          >
                            Apply
                          </a>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ toggle: !this.state.toggle });
                            }}
                            href="#!"
                            class="btn btn-cancel d-none d-md-block"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </dic>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
