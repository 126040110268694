import React, { Component } from 'react'
import ActivityTab from './activity/activityTab'
import Messeges from './activity/messeges'
import Notes from './activity/notes'
import Task from './activity/task'
import { withRouter } from 'react-router-dom'
import Document from './activity/documents'

class Activity extends Component {
  state = {
    tab: 'notes',
  }

  setTab = (tab, e) => {
    this.props.history.push('/patient/dashboard/')
    if (e) e.preventDefault()
    this.setState({ tab })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props !== this.prevProps &&
      this.props.match.params.type === 'pre-Appointment' &&
      this.state.tab !== 'notes'
    )
      this.setState({ tab: 'notes' })
  }
  render() {
    return (
      <div className="chatbot-pannel-right">
        <h4>Activities</h4>
        <div
          className="chatbot-box"
          id="activity-content"
          ref={this.props.myRef}
        >
          <div className="chatbot-head d-none d-md-block">
            <ActivityTab setTab={this.setTab} tab={this.state.tab} />
          </div>
          <div className="chatbot-body">
            <div className="tab-content" id="myTabContent">
              <div
                className={
                  this.state.tab === 'messages'
                    ? 'tab-pane card fade show active'
                    : 'tab-pane card fade'
                }
                id="messages"
                role="tabpanel"
                aria-labelledby="messages-tab"
              >
                <Messeges
                  setTab={this.setTab}
                  tab={this.state.tab}
                  doctor_id={this.props.doctor_id}
                  patient_id={JSON.parse(localStorage.getItem('patient'))._id}
                />
              </div>

              <div
                className={
                  this.state.tab === 'notes'
                    ? 'tab-pane card fade show active'
                    : 'tab-pane card fade'
                }
                id="notes"
                role="tabpanel"
                aria-labelledby="notes-tab"
              >
                <Notes
                  setTab={this.setTab}
                  tab={this.state.tab}
                  aapointment_id={this.props.appointment_id}
                  doctor_id={this.props.doctor_id}
                />
              </div>

              <div
                className={
                  this.state.tab === 'tasks'
                    ? 'tab-pane card fade show active'
                    : 'tab-pane card fade'
                }
                id="tasks"
                role="tabpanel"
                aria-labelledby="tasks-tab"
              >
                <Task
                  setTab={this.setTab}
                  tab={this.state.tab}
                  aapointment_id={this.props.appointment_id}
                  doctor_id={this.props.doctor_id}
                />
              </div>

              <div
                className={
                  this.state.tab === 'documents'
                    ? 'tab-pane card fade show active'
                    : 'tab-pane card fade'
                }
                id="documents"
                role="tabpanel"
                aria-labelledby="documents-tab"
              >
                <Document setTab={this.setTab} tab={this.state.tab} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Activity)
