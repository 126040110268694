import React, { Component } from 'react'

class TypeSwitch extends Component {
  state = {}
  render() {
    const { schedules } = this.props

    return (
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* {JSON.stringify(schedules)} */}
        {schedules && schedules.onlineFee && schedules.onlineFee != 0 ? (
          <li className="nav-item">
            <a
              onClick={(e) => {
                e.preventDefault()
                this.props.changeSwitch('online')
                this.props.loadDate()
              }}
              className={
                this.props.apType === 'online' ? 'nav-link active' : 'nav-link'
              }
              id="tab-f"
              data-toggle="tab"
              href="#pane-tab-f"
              role="tab"
              aria-selected={this.props.apType === 'online'}
            >
              Online visit
            </a>
          </li>
        ) : (
          ''
        )}
        {schedules && schedules.clinicFee && schedules.clinicFee != 0 ? (
          <li className="nav-item">
            <a
              onClick={(e) => {
                e.preventDefault()
                this.props.changeSwitch('clinic')
                this.props.loadDate()
              }}
              className={
                this.props.apType === 'clinic' ? 'nav-link active' : 'nav-link'
              }
              id="tab-e"
              data-toggle="tab"
              href="#pane-tab-e"
              role="tab"
              aria-selected={this.props.apType === 'clinic'}
            >
              Clinic visit
            </a>
          </li>
        ) : (
          ''
        )}
      </ul>
    )
  }
}

export default TypeSwitch
