export const mobileRequired = "Mobile Number field is required";
export const relationRequired = "Relation field is required";

export const mobileInvalid = "Mobile Number field is invalid";

export const phoneRequired = "Phone Number field is required";
export const phoneInvalid = "Phone Number field is invalid";

export const dobRequired = "Date of birth field is required";
export const dobInvalid =
  "Age should be more than 18 years in order to proceed";
export const invalidOTP = "OTP is invalid";
export const expiredOTP = "Your OTP has expired. Please resend. ";

export const firstNameRequired = "First Name field is required";
export const typeRequired = "Type field is required";

export const lastNameRequired = "Last Name field is required";
export const nameRequired = "Name field is required";
export const sexRequired = "Sex field is required";
export const fieldTypeRequired = "Field Type field is required";
export const photoRequired = "Photo field is required";
export const signRequired = "Signature field is required";

export const emailRequired = "Email field is required";
export const emailInvalid = "Email field is invalid";

export const passwordRequired = "Password field is required";
export const passwordInvalid =
  "Password length must be at least 8 characters long";

export const usernameRequired = "Username field is required";

export const securityQuestionRequired = "Security Question field is required";

export const answerRequired = "Security Answer field is required";
export const answerInvalid =
  "Security Answer length must be at least 5 characters long";

export const partnerEmailInvalid =
  "You cannot use the same email address for your partner. Please enter any other email address.";

export const partnerMobileInvalid =
  "You cannot use the same phone number for your partner. Please enter any other phone number.";

export const passwordInvalidMax =
  "Password length cannot be more than 25 characters long.";

export const loginFailed = "Login failed!";
export const loginSuccess = "Login successful!";
export const accountNotExist = "Sorry, this account does not exist.";
export const accountTypeRequired = "Account Type field is required";
export const emailExist =
  "A user with this email/mobile number already exists. Please login to access the dashboard or use another email/mobile number for sign up.";

export const nameMax = "Name cannot be more than 100 characters long.";
export const fnameMax = "First Name cannot be more than 50 characters long.";
export const lnameMax = "Last Name cannot be more than 50 characters long.";

//doctor

export const drPersonalDetailSaved = "Personal details saved successfully";
export const drPersonalDetailFaild = "Something went wrong, Try again";

export const educationRequired = "Graduation field is required";

export const collegeRequired = "College/University name field is required";
export const yearRequired = "Year field is required";
export const yearInvalid = "Year field is invalid";

export const yearOfPassingRequired = "Year of passing field is required";
export const yearOfPassingInvalid = "Year of passing field is invalid";

export const profileUpdatedSuccess = "Information saved.";

export const exprienceRequired = "Experience field is required";
export const exprienceInvalid = "Experience field is invalid";

export const postRequired = "Post field is required";
export const placeRequired = "Place field is required";
export const durationRequired = "Duration field is required";

export const certificationRequired =
  "Certifications/PDF/Superspeciality field is required";
export const awardRequired = "Awards/Affiliations field is required";
export const journalRequired = "Journal field is required";
export const titleRequired = "Title/Area Of Research field is required";

export const graduationRequired = "Graduation certificate field is required";
export const postgraduationRequired =
  "Post Graduation certificate field is required";
export const registrationRequired =
  "Registration/License certificate field is required";
export const experienceRequired = "Experience certificate field is required";
export const licenseRequired = "License Number field is required";

export const clinicAddressRequired = "Clinic Address field is required";
export const cityRequired = "City field is required";
export const stateRequired = "State field is required";
export const picsRequired =
  "Images of the clinic are mandatory. Please upload.";

export const vertualRequired = "Vertual URL field is required";

export const clinicRequired = "Clinic URL field is required";

export const aboutmeMax = "Word count cannot be more than 20";
export const msgMax = "Word count cannot be more than 50";

export const timePerioFromRequired = "Time Period from field is required";
export const timePerioFromInvalid = " Time Period from field is invalid";

export const timePerioToRequired = "Time Period to field is required";
export const timePerioToInvalid = " Time Period to field is invalid";
///bank
export const bankNameRequired = "Bank name field is required";
export const accountRequired = "Account number field is required";
export const accountInvalid = "Account number is invalid";
export const ifscRequired = "IFSC code field is required";
export const pancardRequired = "Pancard field is required";
export const zipRequired = "Zip Code field is required";

export const fileRequired = "Document field is required.";
export const designationRequired = "Designation field is required.";

export const categoryRequired = "Category field is required";
export const docTitleRequired = "Document Title field is required.";
