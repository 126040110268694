import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./header";
import AllPatient from "./patient";
import { withRouter } from "react-router-dom";
import LeftBar from "./leftBar";
import PatientHeader from "./patientHeader";
import StarPatient from "./starPatient";
import OtherPatient from "./otherPatient";
import ReactTooltip from "react-tooltip";

class PatientTabs extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
  };

  updateStep = (e, step) => {
    if (e) e.preventDefault();
    this.setState({ step });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <AllPatient updateStep={this.updateStep} step={this.state.step} />
        );
      case 2:
        return (
          <StarPatient updateStep={this.updateStep} step={this.state.step} />
        );
      case 3:
        return (
          <OtherPatient updateStep={this.updateStep} step={this.state.step} />
        );

      default:
        break;
    }
  };

  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };

  render() {
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          <div className="dashboard-main-container">
            <div className="dashboard-wrapper">
              <div className="dashboard-single-panel">
                <PatientHeader step={this.state.step} />
                <div className="dsp-body">
                  <div className="tab-container d-none d-md-block">
                    <ul
                      className="nav nav-tabs nav-tabs2"
                      id="myTab"
                      role="tablist"
                    >
                      <li
                        className="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={
                            this.state.step === 1
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="all"
                          role="tab"
                          aria-controls="all"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          <b>All Patients</b>
                          <span>All Patients</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          data-tip="Your important patients. Clicking on star will make them appear here"
                          className={
                            this.state.step === 2
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="starred"
                          role="tab"
                          aria-controls="starred"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          <b>Starred Patients</b>
                          <span>Starred Patients</span>
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        role="presentation"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          className={
                            this.state.step === 3
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="tasks-tab"
                          data-toggle="Other"
                          role="tab"
                          aria-controls="other"
                          aria-selected="false"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          <b>Other Patients</b>
                          <span>Other Patients</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content-box">
                    <div className="tab-content" id="myTabContent">
                      <div
                        class={
                          this.state.step === 1
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 1)}
                        >
                          All Patients
                        </div>
                        <div
                          className={
                            this.state.step === 1
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 1 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 2
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 2)}
                        >
                          Starred Patients
                        </div>
                        <div
                          className={
                            this.state.step === 2
                              ? "content-body show add-scrollbar  scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar  scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 2 && this.leftSideStep()}
                        </div>
                      </div>
                      <div
                        class={
                          this.state.step === 3
                            ? "tab-pane card fade show active"
                            : "tab-pane card fade d-none"
                        }
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <div
                          class="card-header"
                          onClick={(e) => this.updateStep(null, 3)}
                        >
                          Other Patients
                        </div>
                        <div
                          className={
                            this.state.step === 3
                              ? "content-body show add-scrollbar scrollbar-dynamic d-block"
                              : "content-body show add-scrollbar scrollbar-dynamic d-none"
                          }
                          style={{ position: "relative" }}
                        >
                          {" "}
                          {this.state.step === 3 && this.leftSideStep()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(connect(null)(PatientTabs));
