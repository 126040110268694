import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import LinesEllipsis from "react-lines-ellipsis";
import { connect } from "react-redux";
import DashboardHeader from "../../profile/common/dashboardHeader";
import DashboardLeftBar from "../../profile/common/dashboardLeftBar";
import { withRouter } from "react-router-dom";
import DiaryEntry from "./diryEntry";
import verticalDots from "./../../../../include/images/vertical-dots.svg";
import shareIcon from "./../../../../include/images/Icon-share.svg";
import trashIcom from "./../../../../include/images/trash-alt.svg";
import efeather from "./../../../../include/images/Icon-feather-edit.svg";
import { isMobile } from "react-device-detect";

import {
  getDiary,
  loadDiary,
  deleteDiary,
  shareDiary,
} from "../../../../store/diary";
import moment from "moment";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alertSuccess";
import AlertError from "../../../common/alertError";
import DeletePopup from "../../../common/deletePopup";
import NoRecord from "../../../common/noRecord";

const months = [
  { name: "Month", number: "" },
  { name: "January", number: 1 },
  { name: "February", number: 2 },
  { name: "March", number: 3 },
  { name: "April", number: 4 },
  { name: "May", number: 5 },
  { name: "June", number: 6 },
  { name: "July", number: 7 },
  { name: "August", number: 8 },
  { name: "September", number: 9 },
  { name: "October", number: 10 },
  { name: "November", number: 11 },
  { name: "December", number: 12 },
];
const years = ["", "2022", "2021", "2020", "2019"];

class Timeline extends Component {
  state = {
    month: "",
    year: "",
    step: 1,
    addForm: false,
    showMenu: false,
    activeState: 0,
    menuOptios: "",
    forEdit: false,
    delete: false,
  };

  removeEditData = () => {
    this.setState({ forEdit: false });
  };

  callback = (res) => {
    if (res.status === 200) {
      this.toggleDelete();
      toast(<AlertSuccess message={"Deleted successfully"} />);
      if (this.state.month && this.state.year) {
        this.props.loadDiary({
          date: this.state.month + "-" + this.state.year,
        });
      } else {
        this.props.loadDiary({});
      }
    } else {
      toast(<AlertError message={"Something failed"} />);
    }
  };

  delDiary = (diary_no) => {
    this.setState((state) => {
      return { delete: !state.delete, diary_no };
    });
  };

  toggleDelete = () => {
    this.setState((state) => {
      return {
        delete: !state.delete,
      };
    });
  };

  callbackShare = (res) => {
    if (res.status === 200) {
      toast(<AlertSuccess message={"Successfully shared with your experts"} />);
      if (this.state.month && this.state.year) {
        this.props.loadDiary({
          date: this.state.month + "-" + this.state.year,
        });
      } else {
        this.props.loadDiary({});
      }
    } else {
      toast(<AlertError message={"Something failed"} />);
    }
  };

  diaryShare = (diary_no) => {
    this.props.shareDiary(diary_no, this.callbackShare);
  };

  onChangeMonth = (e) => {
    this.setState({ month: e.target.value });
    if (e.target.value && this.state.year) {
      this.props.loadDiary({ date: e.target.value + "-" + this.state.year });
    }
  };

  onChangeYear = (e) => {
    this.setState({ year: e.target.value });
    if (e.target.value && this.state.month) {
      this.props.loadDiary({ date: this.state.month + "-" + e.target.value });
    }
  };

  closeDiaryEntry = (e) => {
    if (e) e.preventDefault();
    this.setState({ addForm: false });
  };

  updateStep = (e, step) => {
    if (e) e.preventDefault();
    this.setState({ step });
  };
  componentDidMount = () => {
    this.props.loadDiary({});
    document.body.classList.remove("p-0");
    document.body.classList.add("dashboard");
  };
  componentWillUnmount = () => {
    document.body.classList.remove("dashboard");
    document.body.classList.add("p-0");
  };

  render() {
    const data = _.mapValues(
      _.groupBy(this.props.getDiary.diaries, "date"),
      (clist) => clist.map((car) => _.omit(car, "date"))
    );

    const selectedDiary =
      this.props.getDiary.diaries.length > 0
        ? this.props.getDiary.diaries.find(
            (e) => e.dairy_no == this.state.activeState
          )
          ? this.props.getDiary.diaries.find(
              (e) => e.dairy_no == this.state.activeState
            )
          : this.props.getDiary.diaries[0]
        : null;

    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <DashboardLeftBar />
        <DashboardHeader />
        <div
          class="dashboard-main-container"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({
              menuOptios: "",
              showMenu: false,
            });
          }}
        >
          <div class="my-diary-box">
            <div class="mdb-head d-md-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h4>My Diary</h4>
                <i
                  data-tip="The thoughts you record here can be for your own purpose only or you can share the chosen ones with your treating team experts. Recording your important thought which pops up at any moment can help you share it later with your experts to help them understand you better."
                  class="fas fa-info-circle ml-2"
                  aria-hidden="true"
                  style={{ color: "rgb(24 0 88 / 50%)", fontSize: "12px" }}
                ></i>
              </div>

              <div class="mdb-right-content d-flex flex-wrap align-items-center">
                <div class="mdb-btn m-2" data-toggle="diaryEntry">
                  <a
                    href="#!"
                    class="btn btn-outline no-shadow"
                    onClick={(e) => {
                      this.setState({ month: "", year: "" }, () =>
                        this.props.loadDiary({})
                      );
                    }}
                  >
                    Clear Filter
                  </a>
                </div>
                <div class="mdb-btn m-2" data-toggle="diaryEntry">
                  <a
                    href="#!"
                    class="btn btn-primary btn-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      this.removeEditData();
                      this.setState({ addForm: true });
                    }}
                  >
                    + Add New Dairy Entry
                  </a>
                </div>
              </div>
            </div>
            <div class="mdb-body d-lg-flex flex-wrap">
              <div class="mdb-sidebar">
                <div class="mdb-sidebar-head  d-lg-flex flex-wrap">
                  <div class="form-group flex-grow-1">
                    <select class="form-control" onChange={this.onChangeMonth}>
                      {months.map((mo) => (
                        <option
                          value={mo.number}
                          selected={this.state.month === mo.number}
                        >
                          {mo.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="form-group show flex-grow-1">
                    <select class="form-control" onChange={this.onChangeYear}>
                      {years.map((yr) => (
                        <option selected={this.state.year === yr} value={yr}>
                          {yr ? yr : "Year"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div class="mdb-sidebar-body">
                  {Object.keys(data).length > 0 ? (
                    Object.keys(data).map((el, i) => (
                      <>
                        <h6>
                          {moment().diff(
                            el.split("-")[1] +
                              "/" +
                              el.split("-")[0] +
                              "/" +
                              el.split("-")[2],
                            "days"
                          ) === 0
                            ? "Today"
                            : moment(
                                el.split("-")[1] +
                                  "/" +
                                  el.split("-")[0] +
                                  "/" +
                                  el.split("-")[2]
                              ).format("ll")}
                        </h6>

                        {data[el] &&
                          data[el].map((ed, i) => (
                            <div
                              class="mdb-intro-group"
                              style={
                                this.state.activeState == ed.dairy_no
                                  ? { background: "rgb(88 43 184 / 15%)" }
                                  : {}
                              }
                            >
                              <div
                                style={
                                  this.state.activeState === ed.dairy_no
                                    ? {
                                        background: "#e6dff5",
                                      }
                                    : {}
                                }
                                style={{ cursor: "pointer" }}
                                class="mdb-intro-box d-flex flex-wrap"
                                onClick={(e) => {
                                  this.setState({
                                    activeState: ed.dairy_no,
                                    menuOptios: "",
                                  });
                                }}
                              >
                                <div class="mdb-intro-text">
                                  <h5>Diary No. {ed.dairy_no}</h5>
                                  <p>
                                    <LinesEllipsis
                                      text={ed.situation}
                                      maxLine={1}
                                      ellipsis="..."
                                      trimRight
                                      basedOn="letters"
                                    />
                                  </p>
                                </div>

                                <div class="mdb-dropdown ml-auto">
                                  <div class="dropright show">
                                    <a
                                      class="dropdown-toggle"
                                      href="#"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        this.setState({
                                          menuOptios: ed.dairy_no,
                                        });
                                      }}
                                    >
                                      <img src={verticalDots} alt="" />
                                    </a>
                                    <div
                                      class={
                                        this.state.menuOptios === ed.dairy_no
                                          ? "dropdown-menu dropdown-custom show"
                                          : "dropdown-menu dropdown-custom "
                                      }
                                      style={
                                        isMobile
                                          ? {
                                              position: "absolute",
                                              transform:
                                                "translate3d(-170px, 0px, 0px)",
                                              top: "0px",
                                              left: "0px",
                                              willChange: "transform",
                                            }
                                          : {
                                              position: "absolute",
                                              transform:
                                                "translate3d(6px, 0px, 0px)",
                                              top: "0px",
                                              left: "0px",
                                              willChange: "transform",
                                            }
                                      }
                                      x-placement={
                                        isMobile ? "left-start" : "right-start"
                                      }
                                    >
                                      {!ed.shared && (
                                        <>
                                          <a
                                            class="dropdown-item"
                                            href="#!"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({
                                                addForm: true,
                                                forEdit: ed,
                                              });
                                            }}
                                          >
                                            Edit
                                          </a>

                                          <a
                                            class="dropdown-item"
                                            href="#!"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.diaryShare(ed.dairy_no);
                                            }}
                                          >
                                            Share
                                          </a>
                                        </>
                                      )}
                                      <a
                                        class="dropdown-item"
                                        href="#!"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.delDiary(ed.dairy_no);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    ))
                  ) : (
                    <NoRecord
                      header="No Diary Entry Started Yet"
                      text="introduces My Diary feature. Which helps you collect your thoughts and feelings in a systematic way. You can share your diary entries with your experts for their analysis and hence, better treatment. Let’s keep you connected."
                    />
                  )}
                </div>
              </div>

              {selectedDiary && (
                <div class="mdb-content">
                  <div class="mdb-content-head d-lg-flex flex-wrap">
                    <div class="mdb-content-left d-flex align-items-center flex-grow-1">
                      <h2>Diary No. {selectedDiary.dairy_no}</h2>
                      {!selectedDiary.shared && (
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              addForm: true,
                              forEdit: selectedDiary,
                            });
                          }}
                        >
                          <img src={efeather} alt="" />
                        </a>
                      )}
                    </div>
                    <div class="mdb-content-right flex-grow-1 d-flex align-items-center justify-content-end">
                      <b>
                        {moment(selectedDiary.updated_on).format("MMM Do YY")}
                      </b>
                      <div class="mdb-content-dropdown">
                        <div class="dropright">
                          <a
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.setState({ showMenu: true });
                            }}
                            class="dropdown-toggle"
                            href="#"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src={verticalDots} alt="" />
                          </a>

                          <div
                            class={
                              this.state.showMenu
                                ? "dropdown-menu dropdown-custom show"
                                : "dropdown-menu dropdown-custom "
                            }
                            style={{
                              position: "absolute",
                              transform: "translate3d(-170px, 0px, 0px)",
                              top: "0px",
                              left: "0px",
                              willChange: "transform",
                            }}
                            x-placement="left-start"
                          >
                            {!selectedDiary.shared && (
                              <>
                                <a
                                  class="dropdown-item"
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      addForm: true,
                                      forEdit: selectedDiary,
                                    });
                                  }}
                                >
                                  Edit
                                </a>

                                <a
                                  class="dropdown-item"
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.diaryShare(selectedDiary.dairy_no);
                                  }}
                                >
                                  Share
                                </a>
                              </>
                            )}
                            <a
                              class="dropdown-item"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.delDiary(
                                  selectedDiary && selectedDiary.dairy_no
                                );
                              }}
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mdb-content-body">
                    <h5>Situation</h5>
                    <p>
                      What were you doing? What was happening around? Were there
                      any mood changes you noticed? Were you thinking about
                      something?
                    </p>
                    <div class="mdb-text-box">{selectedDiary.situation}</div>
                    <h5>THOUGHT</h5>
                    <p>What went through your mind in that situation?</p>
                    <div class="mdb-text-box">{selectedDiary.thought}</div>
                    <h5>emotional</h5>
                    <p>What went through your mind in that situation?</p>
                    <div class="mdb-text-box">{selectedDiary.emotion}</div>
                    <h5>ALTERNATE BALANCED THOUGHT</h5>
                    <p>What went through your mind in that situation?</p>
                    <div class="mdb-text-box">
                      {selectedDiary.alternate_balanced_thought}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <DeletePopup
          show={this.state.delete}
          onHide={this.toggleDelete}
          deleted={this.props.deleteDiary}
          delete_id={this.state.diary_no}
          callback={this.callback}
        />

        <DiaryEntry
          removeEditData={this.removeEditData}
          forEdit={this.state.forEdit}
          addForm={this.state.addForm}
          closeDiaryEntry={this.closeDiaryEntry}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadDiary: (params) => dispatch(loadDiary(params)),
  deleteDiary: (diary_no, callback) =>
    dispatch(deleteDiary(diary_no, callback)),
  shareDiary: (diary_no, callback) => dispatch(shareDiary(diary_no, callback)),
});
const mapStateToProps = (state) => ({
  getDiary: getDiary(state),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Timeline)
);
