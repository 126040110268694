import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { loadDoctorFeatured, getDoctor } from '../../../store/doctor'
import _ from 'lodash'
import { connect } from 'react-redux'

import Slider from 'react-slick'
const mobileSlider = {
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  infinite: false,
  centerMode: false,

  responsive: [
    {
      breakpoint: 5000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 579,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
      },
    },
  ],
}

class Expert extends Component {
  state = {
    patient: localStorage.getItem('patient')
      ? JSON.parse(localStorage.getItem('patient'))
      : false,
  }
  componentDidMount = () => {
    this.props.loadDoctorFeatured('featured_on_expert')
  }
  render() {
    const { featured } = this.props
    const monthName = (item) => item.field_type.name
    const result = _.groupBy(featured, monthName)
    const groupArrays = Object.keys(result).map((category) => {
      return {
        category,
        doc: result[category],
      }
    })
    return (
      <div className="content-container">
        <div className="container container1">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 text-center">
              <div className="heading">
                {window.location.pathname === '/clinical-psychosocial' && (
                  <h4>
                    Our <span>Psychiatric Social Department</span>
                  </h4>
                )}
                {window.location.pathname === '/clinical-psychologist' && (
                  <h4>
                    Our <span>Clinical Psychologists</span>
                  </h4>
                )}
                {window.location.pathname === '/clinical-psychiatrist' && (
                  <h4>
                    Our <span>Psychiatrists</span>
                  </h4>
                )}
                {window.location.pathname === '/clinical-counsellor' && (
                  <h4>
                    Our <span>Counsellor Psychologists</span>
                  </h4>
                )}
              </div>

              {window.location.pathname === '/clinical-psychiatrist' &&
                groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Psychiatrist' && (
                      <Slider {...mobileSlider}>
                        {e2.doc.map((e, i) => (
                          <li className="expert-item">
                            <div className="expert-box">
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <div className="expert-img">
                                  <img
                                    src={
                                      process.env.REACT_APP_S3URL + e.image_url
                                    }
                                    alt=""
                                  />
                                </div>
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <h4>{e.name}</h4>
                              </a>
                              <p>
                                {e.graduation && e.graduation.education + ', '}

                                {e.post_graduation &&
                                  e.post_graduation.education}

                                {e.super_speciality &&
                                  ', ' + e.super_speciality.education}
                              </p>
                            </div>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </>
                ))}
              {window.location.pathname === '/clinical-psychologist' &&
                groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Psychologist' && (
                      <Slider {...mobileSlider}>
                        {e2.doc.map((e, i) => (
                          <li className="expert-item">
                            <div className="expert-box">
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <div className="expert-img">
                                  <img
                                    src={
                                      process.env.REACT_APP_S3URL + e.image_url
                                    }
                                    alt=""
                                  />
                                </div>
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <h4>{e.name}</h4>
                              </a>
                              <p>
                                {e.graduation && e.graduation.education + ', '}

                                {e.post_graduation &&
                                  e.post_graduation.education}

                                {e.super_speciality &&
                                  ', ' + e.super_speciality.education}
                              </p>
                            </div>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </>
                ))}
              {window.location.pathname === '/clinical-psychosocial' &&
                groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Psychosocial Worker' && (
                      <Slider {...mobileSlider}>
                        {e2.doc.map((e, i) => (
                          <li className="expert-item">
                            <div className="expert-box">
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <div className="expert-img">
                                  <img
                                    src={
                                      process.env.REACT_APP_S3URL + e.image_url
                                    }
                                    alt=""
                                  />
                                </div>
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <h4>{e.name}</h4>
                              </a>
                              <p>
                                {e.graduation && e.graduation.education + ', '}

                                {e.post_graduation &&
                                  e.post_graduation.education}

                                {e.super_speciality &&
                                  ', ' + e.super_speciality.education}
                              </p>
                            </div>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </>
                ))}
              {window.location.pathname === '/clinical-counsellor' &&
                groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Counsellor' && (
                      <Slider {...mobileSlider}>
                        {e2.doc.map((e, i) => (
                          <li className="expert-item">
                            <div className="expert-box">
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <div className="expert-img">
                                  <img
                                    src={
                                      process.env.REACT_APP_S3URL + e.image_url
                                    }
                                    alt=""
                                  />
                                </div>
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_BASEURL +
                                  'dr/' +
                                  e.user_name
                                }
                                target="_blank"
                              >
                                <h4>{e.name}</h4>
                              </a>
                              <p>
                                {e.graduation && e.graduation.education + ', '}

                                {e.post_graduation &&
                                  e.post_graduation.education}

                                {e.super_speciality &&
                                  ', ' + e.super_speciality.education}
                              </p>
                            </div>
                          </li>
                        ))}
                      </Slider>
                    )}
                  </>
                ))}
              {window.location.pathname === '/clinical-psychiatrist' ||
              window.location.pathname === '/clinical-psychologist' ? (
                <div class="viewmore-btn text-center">
                  <Link
                    to={
                      this.state.patient
                        ? 'patient/doctors'
                        : 'patient/appointment'
                    }
                    className="btn btn-default btn-lg"
                  >
                    Book a consultation
                  </Link>
                </div>
              ) : (
                ''
              )}
              {window.location.pathname === '/clinical-counsellor' && (
                <div class="viewmore-btn text-center">
                  <Link
                    to="patient/chat"
                    className="btn btn-default btn-lg"
                    target="_blank"
                  >
                    Chat with us{' '}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  featured: getDoctor(state).featured,
})

const mapDispatchToProps = (dispatch) => ({
  loadDoctorFeatured: (params) => dispatch(loadDoctorFeatured(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Expert)
