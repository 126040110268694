import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../../common/form/form";
import loaderIcon from "../../../include/images/loading-icon.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { patientSignUp, updatePatientProfile } from "../../../store/patient";
import AlertSuccess from "../../common/alertSuccess";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import {
  passwordInvalid,
  passwordRequired,
  securityQuestionRequired,
  answerRequired,
  answerInvalid,
  passwordInvalidMax,
} from "../../common/misc";
import SubmitBtn from "../../common/form/submitBtn";
import { loadTermTemplate } from "../../../store/seo";
import TermTemplatePopup from "../../common/termTemplatePopup";

class SetPassword extends Form {
  state = {
    popShow: false,
    btnClass: "btn btn-lg btn-primary btn-block",
    loading: false,
    sequerityQuestions: [
      {
        id: "What was your childhood nickname?",
        name: "What was your childhood nickname?",
      },
      {
        id: "In what city did you meet your spouse?",
        name: "In what city did you meet your spouse?",
      },
      {
        id: "What is the name of your favorite childhood friend?",
        name: "What is the name of your favorite childhood friend?",
      },
      {
        id: "What school did you attend for sixth grade?",
        name: "What school did you attend for sixth grade?",
      },
      {
        id: "In what city or town was your first job?",
        name: "In what city or town was your first job?",
      },
    ],
    data: { password: "", security_question: "", answer: "" },
    errors: { password: "", security_question: "", answer: "" },
  };
  togglePopup = () => {
    this.setState({ popShow: !this.state.popShow });
  };

  openPopup = (e, key) => {
    e.preventDefault();
    this.props.loadTermTemplate(key);
    this.togglePopup();
  };

  schema = {
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;
          }
        });
        return errors;
      }),
    security_question: Joi.string()
      .required()
      .label("Security question")
      .error(() => {
        return { message: securityQuestionRequired };
      }),
    answer: Joi.string()
      .required()
      .label("Answer")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = answerRequired;
              break;

            case "string.min":
              err.message = answerInvalid;
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    if (this.props.accountFor === "couple") {
      this.props.setPasswordInfo(this.state.data);
      return this.props.updateStep(null, 7);
    }
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block disabled",
        loading: true,
      });

      if (this.props.accountFor === "guardian") {
        //return console.log(this.props.allState);
        const { answer, password, security_question } = this.state.data;
        const { verifyMobile, patientDetails, guardianDetails } =
          this.props.allState;
        const { mobile, otp } = verifyMobile;
        const { DOB, first_name, last_name } = patientDetails;
        const {
          email_id,
          first_name: guardian_first_name,
          last_name: guardian_last_name,
          relation: guardian_relation,
        } = guardianDetails;

        const payLoad = {
          email: email_id,
          password,
          first_name,
          last_name,
          date_of_birth: DOB.toString(),
          type: 2,
          security_question,
          security_answer: answer,
          partner: false,
          guardian_first_name,
          guardian_last_name,
          guardian_relation,
        };

        this.props.updatePatientProfile(payLoad, this.callback);
      } else {
        const { answer, password, security_question } = this.state.data;
        const { verifyMobile, personalDetails } = this.props.allState;
        const { mobile, otp, DOB, type } = verifyMobile;
        const { email_id, first_name, last_name } = personalDetails;

        const payLoad = {
          email: email_id,
          password,
          first_name,
          last_name,
          date_of_birth: DOB.toString(),
          type,
          security_question,
          security_answer: answer,
          partner: false,
        };

        this.props.updatePatientProfile(payLoad, this.callback);
      }
    }
  };

  callback = (res) => {
    if (res.status === 200) {
      localStorage.setItem("patient", JSON.stringify(res.data));
      this.props.updateStep(null, 6);
      //toast(<AlertSuccess message={res.data.error} />);
    } else {
      this.setState({
        btnClass: "btn btn-lg btn-primary btn-block",
        loading: false,
      });
      toast(<AlertError message={res.data.error} />);
    }
  };
  render() {
    console.log(
      "this.props.allState.verifyMobile.type",
      this.props.allState.guardianDetails
    );
    return (
      <div className="member-right">
        <div className="member-right-content d-md-flex align-items-center">
          <div className="mr-box">
            <div className="mr-head">
              <a
                href=""
                onClick={(e) =>
                  this.props.updateStep(
                    e,
                    !this.props.allState.guardianDetails.first_name ? 4 : 11
                  )
                }
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 31.494 31.494"
                  space="preserve"
                >
                  <path
                    d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554
                  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587
                  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z"
                  ></path>
                </svg>
              </a>
              <div className="mr-step">Step 5/5</div>
              <h2 className="mb-3">Set Password</h2>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-box">
                {this.renderPasswordInput("password", "Set Password")}
                {this.renderSelect(
                  "security_question",
                  "Security question",
                  this.state.sequerityQuestions
                )}
                {this.renderInput("answer", "Answer")}
                <div className="form-group">
                  <SubmitBtn
                    label="Register"
                    loading={this.state.loading}
                    btnClass={this.state.btnClass}
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="mr-bottom">
            <p>
              By clicking continue, I agree to{" "}
              <a href="" onClick={(e) => this.openPopup(e, "PatientTerms")}>
                terms of service
              </a>{" "}
              and{" "}
              <a
                onClick={(e) => this.openPopup(e, "user-privacy-policy")}
                href=""
              >
                privacy policy.
              </a>
            </p>
          </div>
        </div>
        <TermTemplatePopup
          show={this.state.popShow}
          toggle={this.togglePopup}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  patientSignUp: (param, callback) => dispatch(patientSignUp(param, callback)),
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
  loadTermTemplate: (key) => dispatch(loadTermTemplate(key)),
});
export default withRouter(connect(null, mapDispatchToProps)(SetPassword));
