import React, { Component } from 'react'
import close from '../../../../include/images/icon-close.svg'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  addAnswerAssessment,
  loadAllAssessment,
} from '../../../../store/assessment'
import { connect } from 'react-redux'
import { getPatient } from '../../../../store/patient'
import AlertSuccess from '../../../common/alertSuccess'
import AlertError from '../../../common/alertError'
import { toast } from 'react-toastify'
import moment from 'moment'
import { loadAppointmentDetailPatient } from '../../../../store/appointment'
class NotesThought extends Component {
  state = {
    isShow1: false,
    title: '',
    appointId: '',
    doctor_id: '',
    appointment_id: '',
    assesment_id: '',
    id: '',
    post: false,
    pre: false,
    progress: false,
    data: {},
    is_submitted: false,
    followup: false,
  }
  togglePost = id => {
    if (this.state.post === false) {
      this.setState({
        id: id,
        pre: false,
        post: !this.state.post,
        progress: false,
      })
    } else {
      this.setState({
        id: '',
        pre: false,
        post: !this.state.post,
        progress: false,
        data: '',
      })
    }
  }
  togglePre = id => {
    if (this.state.pre === false) {
      this.setState({
        id: id,
        post: false,
        pre: !this.state.pre,
        progress: false,
      })
    } else {
      this.setState({
        id: '',
        post: false,
        pre: !this.state.pre,
        progress: false,
        data: '',
      })
    }
  }
  toggleProgress = id => {
    if (this.state.progress === false) {
      this.setState({
        id: id,
        post: false,
        progress: !this.state.progress,
        pre: false,
      })
    } else {
      this.setState({
        id: '',
        post: false,
        progress: !this.state.progress,
        pre: false,
        data: '',
      })
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isShow && this.props.isShow !== prevProps.isShow) {
      this.props.loadAppointmentDetailPatient(this.props.appoint_id, res =>
        this.setState({ followup: res.data.data.followup }, () => {
          this.props.loadAllAssessment({
            noPrev: true,
            appointment_id: this.props.appoint_id,
            patient_id: this.props.getPatientProfile._id,
            category: this.state.followup
              ? [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Progress Notes - Guardian'
                    : 'Progress Notes - Self',
                ]
              : [
                  this.props.getPatientProfile.type === 2
                    ? 'Pre-Session Thoughts - Guardian'
                    : 'Pre-Session Thoughts - Self',
                  this.props.getPatientProfile.type === 2
                    ? 'Post-Session Thoughts - Guardian'
                    : 'Post-Session Thoughts - Self',
                ],
          })
        })
      )
    }

    if (this.state.id != '' && this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        doctor_id:
          (this.props.psychiatrist && this.props.psychiatrist._id) ||
          (this.props.psychologist && this.props.psychologist._id),
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        is_submitted:
          this.props.answer.data && this.props.answer.data.is_submitted,
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const formData = { ...this.state.data }
    var payLoad
    if (this.state.is_submitted === true) {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
        is_submitted: this.state.is_submitted,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    } else {
      payLoad = {
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
        formData,
      }
      this.props.addAnswerAssessment(payLoad, this.callBack)
    }
  }
  callBack = res => {
    if (res && res.status === 200) {
      toast(<AlertSuccess message='Information Saved.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
      this.props.loadAnswerAssessment({
        doctor_id: this.props.doctor_id,
        appointment_id: this.props.appoint_id,
        assesment_id: this.state.id,
      })
      this.props.loadAllAssessment({
        noPrev: true,
        appointment_id: this.props.appoint_id,
        patient_id: this.props.getPatientProfile._id,
        category: this.state.followup
          ? [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Progress Notes - Guardian'
                : 'Progress Notes - Self',
            ]
          : [
              this.props.getPatientProfile.type === 2
                ? 'Pre-Session Thoughts - Guardian'
                : 'Pre-Session Thoughts - Self',
              this.props.getPatientProfile.type === 2
                ? 'Post-Session Thoughts - Guardian'
                : 'Post-Session Thoughts - Self',
            ],
      })
    } else {
      toast(<AlertError message='Something Went Wrong.' />)
      this.setState({
        isShow1: '',
        isShow2: '',
        isShow3: '',
        data: {},
      })
    }
  }
  toggle1 = (id, title, appointId) => {
    this.setState({
      isShow1: !this.state.isShow1,
      title: title,
      id: id,
      appointId,
    })
  }
  render() {
    const { detail, assessment } = this.props
    return (
      <>
        <div
          className={
            this.props.isShow === true
              ? 'sidebar-box notesopen'
              : 'sidebar-box notesclose'
          }
          id='calculator'>
          <div className='sidebar-head d-flex align-items-center'>
            <h5>Notes & Thoughts</h5>
            <div
              className='close-img ml-auto'
              onClick={e => {
                e.preventDefault()
                this.props.toggle()
              }}>
              <img src={close} alt='' />
            </div>
          </div>
          <div className='sidebar-body height-auto'>
            {assessment.length &&
              (this.state.followup
                ? [
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Pre-session Thoughts' ||
                        eds.title.trim() === 'Pre-session Thoughts For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Progress Notes' ||
                        eds.title.trim() === 'Progress Notes For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Post session Thoughts' ||
                        eds.title.trim() ===
                          'Post session Thoughts For Guardian'
                    ) || [],
                  ]
                : [
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Pre-session Thoughts' ||
                        eds.title.trim() === 'Pre-session Thoughts For Guardian'
                    ) || [],
                    assessment.find(
                      eds =>
                        eds.title.trim() === 'Post session Thoughts' ||
                        eds.title.trim() ===
                          'Post session Thoughts For Guardian'
                    ) || [],
                  ]
              ).map((e, i) => (
                <>
                  {(e.title &&
                    e.title.trim() === 'Post session Thoughts For Guardian') ||
                  (e.title && e.title.trim() === 'Post session Thoughts') ? (
                    <div className='notes-box d-flex align-items-center'>
                      <p>{e.title}</p>
                      {e.assesmentAnswer &&
                      e.assesmentAnswer.is_submitted === true ? (
                        <div
                          className={
                            moment().diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) > 0
                              ? 'fillup-btn'
                              : 'fillup-btn fillup-btn-didable'
                          }
                          onClick={() => {
                            if (
                              moment().diff(
                                moment(e.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 0
                            )
                              this.toggle1(e._id, e.title, e.appointment_id)
                          }}>
                          View
                        </div>
                      ) : (
                        <div
                          className={
                            moment().diff(
                              moment(e.appointment_time).subtract({
                                hours: 5,
                                minutes: 30,
                              }),
                              'minutes'
                            ) > 0
                              ? 'fillup-btn'
                              : 'fillup-btn fillup-btn-didable'
                          }
                          onClick={() => {
                            if (
                              moment().diff(
                                moment(e.appointment_time).subtract({
                                  hours: 5,
                                  minutes: 30,
                                }),
                                'minutes'
                              ) > 0
                            )
                              this.toggle1(e._id, e.title, e.appointment_id)
                          }}>
                          Fill Up
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='notes-box d-flex align-items-center'>
                      <p>{e.title}</p>
                      {e.assesmentAnswer &&
                      e.assesmentAnswer.is_submitted === true ? (
                        <div
                          className='fillup-btn'
                          onClick={() =>
                            this.toggle1(e._id, e.title, e.appointment_id)
                          }>
                          View
                        </div>
                      ) : (
                        <div
                          className={'fillup-btn'}
                          onClick={() => {
                            this.toggle1(e._id, e.title, e.appointment_id)
                          }}>
                          Fill Up
                        </div>
                      )}
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
        <div
          className={
            this.state.isShow1 === true
              ? 'sidebar-box preSession opennote'
              : 'sidebar-box preSession closenote'
          }
          id='pre'>
          <div className='sidebar-head d-flex align-items-center'>
            {this.state.isShow1 === true && <h5>Fill {this.state.title}</h5>}
            {this.state.isShow1 === true ? (
              <div
                className='close-img ml-auto'
                onClick={e => this.setState({ isShow1: false })}>
                <img src={close} alt='' />
              </div>
            ) : (
              ''
            )}
          </div>
          <form action='' onSubmit={this.handleSubmit}>
            <div className='sidebar-body'>
              {this.props.answer.data &&
              this.props.answer.data.is_submitted === true
                ? detail.questions &&
                  detail.questions.map((e, i) => (
                    <>
                      {e.questions.map((e1, i1) => {
                        const showValue =
                          Array.isArray(this.state.data[e1.question]) &&
                          this.state.data[e1.question].length > 0 &&
                          typeof this.state.data[e1.question][0] === 'object'
                            ? this.state.data[e1.question]
                                .map(u => u.label)
                                .join(',')
                            : Array.isArray(this.state.data[e1.question])
                            ? this.state.data[e1.question].join(',')
                            : typeof this.state.data[e1.question] === 'object'
                            ? this.state.data[e1.question].label
                            : this.state.data[e1.question]
                        return (
                          <div className='check-box-group' key={i1}>
                            <div class='scb-right pb-3'>
                              <p>{e1.question}</p>
                              <h6>{this.state.data && showValue}</h6>
                            </div>
                            {/* <label>{e1.question}</label>
                            {e1.response_type === 'free_text_pill_box' && (
                              <CreatableSelect
                                noOptionsMessage={() =>
                                  'Type and press enter to add options'
                                }
                                isMulti
                                classNamePrefix='select'
                                isDisabled={false}
                                isLoading={false}
                                name='color'
                                styles={this.customStyles}
                                value={
                                  this.state.data &&
                                  this.state.data[e1.question]
                                }
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data[e1.question] = e
                                  this.setState({ data })
                                }}
                              />
                            )}
                            {e1.response_type === 'multiselect_dropdown' && (
                              <Select
                                isMulti
                                className='basic-single'
                                classNamePrefix='select'
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name='color'
                                styles={this.customStyles}
                                options={e1.options.map(ex => ({
                                  label: ex.title,
                                  value: ex.title,
                                }))}
                                value={
                                  this.state.data &&
                                  this.state.data[e1.question]
                                }
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data[e1.question] = e
                                  this.setState({ data })
                                }}
                              />
                            )}
                            {e1.response_type === 'dropdown' && (
                              <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name='color'
                                styles={this.customStyles}
                                options={e1.options.map(ex => ({
                                  label: ex.title,
                                  value: ex.title,
                                }))}
                                value={
                                  this.state.data &&
                                  this.state.data[e1.question]
                                }
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data[e1.question] = e
                                  this.setState({ data })
                                }}
                              />
                            )}

                            {e1.response_type === 'text' && (
                              <input
                                type='text'
                                name=''
                                className='form-control'
                                value={
                                  this.state.data &&
                                  this.state.data[e1.question]
                                }
                                placeholder='Write here…'
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data[e1.question] = e.target.value
                                  this.setState({ data })
                                }}
                              />
                            )}
                            {e1.response_type === 'text_area' && (
                              <textarea
                                type='text'
                                className='form-control'
                                placeholder=''
                                value={
                                  this.state.data &&
                                  this.state.data[e1.question]
                                }
                                onChange={e => {
                                  const data = { ...this.state.data }
                                  data[e1.question] = e.target.value
                                  this.setState({ data })
                                }}></textarea>
                            )}

                            <div className='radiobox-group d-flex flex-wrap align-items-center'>
                              {e1.response_type === 'checkbox' &&
                                e1.options.map((e2, i2) => (
                                  <div className='form-check' key={i2}>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      checked={
                                        this.state.data &&
                                        this.state.data[e1.question] &&
                                        this.state.data[e1.question].find(
                                          efs => efs === e2.title
                                        )
                                      }
                                      value={e2.title}
                                      id={e2.title}
                                      name={e2.title + e2._id}
                                      onChange={event => {
                                        const data = { ...this.state.data }
                                        if (event) {
                                          const findData =
                                            data[e1.question] &&
                                            data[e1.question].find(
                                              echeck =>
                                                echeck === event.target.value
                                            )

                                          if (findData) {
                                            data[e1.question] = data[
                                              e1.question
                                            ].filter(
                                              eqs => eqs !== event.target.value
                                            )
                                          } else {
                                            if (data[e1.question]) {
                                              data[e1.question].push(
                                                event.target.value
                                              )
                                            } else {
                                              data[e1.question] = [
                                                event.target.value,
                                              ]
                                            }
                                          }

                                          this.setState({ data })
                                        }
                                      }}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={e2.title}>
                                      <span
                                        style={{
                                          marginLeft: '2.75rem',
                                        }}></span>
                                      {e2.title}
                                    </label>
                                  </div>
                                ))}
                            </div>
                            <div className='radiobox-group d-flex flex-wrap align-items-center'>
                              {e1.response_type === 'radio' &&
                                e1.options.map((e2, i2) => (
                                  <div className='form-check' key={i2}>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      checked={
                                        this.state.data &&
                                        this.state.data[e1.question] ===
                                          e2.title
                                      }
                                      onChange={e => {
                                        const data = { ...this.state.data }
                                        data[e1.question] = e.target.value
                                        this.setState({ data })
                                      }}
                                      value={e2.title}
                                      id={e2.title + e1._id}
                                      name={e2.title + e1._id}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={e2.title + e1._id}>
                                      <span
                                        style={{
                                          marginLeft: '2.75rem',
                                        }}></span>
                                      {e2.title}
                                    </label>
                                  </div>
                                ))}
                            </div> */}
                          </div>
                        )
                      })}
                    </>
                  ))
                : detail.questions &&
                  detail.questions.map((e, i) => (
                    <>
                      {e.questions.map((e1, i1) => (
                        <div className='check-box-group' key={i1}>
                          <label>{e1.question}</label>
                          {e1.response_type === 'free_text_pill_box' && (
                            <CreatableSelect
                              noOptionsMessage={() =>
                                'Type and press enter to add options'
                              }
                              isMulti
                              classNamePrefix='select'
                              isDisabled={false}
                              isLoading={false}
                              name='color'
                              styles={this.customStyles}
                              value={
                                this.state.data && this.state.data[e1.question]
                              }
                              onChange={e => {
                                const data = { ...this.state.data }
                                data[e1.question] = e
                                this.setState({ data })
                              }}
                            />
                          )}
                          {e1.response_type === 'multiselect_dropdown' && (
                            <Select
                              isMulti
                              className='basic-single'
                              classNamePrefix='select'
                              isDisabled={false}
                              isLoading={false}
                              isClearable={false}
                              isRtl={false}
                              isSearchable={false}
                              name='color'
                              styles={this.customStyles}
                              options={e1.options.map(ex => ({
                                label: ex.title,
                                value: ex.title,
                              }))}
                              value={
                                this.state.data && this.state.data[e1.question]
                              }
                              onChange={e => {
                                const data = { ...this.state.data }
                                data[e1.question] = e
                                this.setState({ data })
                              }}
                            />
                          )}
                          {e1.response_type === 'dropdown' && (
                            <Select
                              className='basic-single'
                              classNamePrefix='select'
                              isDisabled={false}
                              isLoading={false}
                              isClearable={false}
                              isRtl={false}
                              isSearchable={false}
                              name='color'
                              styles={this.customStyles}
                              options={e1.options.map(ex => ({
                                label: ex.title,
                                value: ex.title,
                              }))}
                              value={
                                this.state.data && this.state.data[e1.question]
                              }
                              onChange={e => {
                                const data = { ...this.state.data }
                                data[e1.question] = e
                                this.setState({ data })
                              }}
                            />
                          )}
                          {e1.response_type === 'text' && (
                            <input
                              type='text'
                              name=''
                              onChange={e => {
                                const data = { ...this.state.data }
                                if (e) {
                                  data[e1.question] = e.target.value
                                  this.setState({ data })
                                }
                              }}
                              className='form-control'
                              value={
                                this.state.data && this.state.data[e1.question]
                              }
                              placeholder='Write here…'
                            />
                          )}
                          {e1.response_type === 'text_area' && (
                            <textarea
                              type='text'
                              className='form-control'
                              placeholder=''
                              value={
                                this.state.data && this.state.data[e1.question]
                              }
                              onChange={e => {
                                const data = { ...this.state.data }
                                data[e1.question] = e.target.value
                                this.setState({ data })
                              }}></textarea>
                          )}

                          <div className='radiobox-group d-flex flex-wrap align-items-center'>
                            {e1.response_type === 'checkbox' &&
                              e1.options.map((e2, i2) => (
                                <div className='form-check' key={i2}>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={event => {
                                      const data = {
                                        ...this.state.data,
                                      }
                                      if (event) {
                                        const findData =
                                          data[e1.question] &&
                                          data[e1.question].find(
                                            echeck =>
                                              echeck === event.target.value
                                          )

                                        if (findData) {
                                          data[e1.question] = data[
                                            e1.question
                                          ].filter(
                                            eqs => eqs !== event.target.value
                                          )
                                        } else {
                                          if (data[e1.question]) {
                                            data[e1.question].push(
                                              event.target.value
                                            )
                                          } else {
                                            data[e1.question] = [
                                              event.target.value,
                                            ]
                                          }
                                        }

                                        this.setState({ data })
                                      }
                                    }}
                                    checked={
                                      this.state.data &&
                                      this.state.data[e1.question] &&
                                      this.state.data[e1.question].find(
                                        efs => efs === e2.title
                                      )
                                    }
                                    value={e2.title}
                                    id={e2.title + e1._id}
                                    name={e2.title + e1._id}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={e2.title + e1._id}>
                                    <span
                                      style={{
                                        marginLeft: '2.75rem',
                                      }}></span>
                                    {e2.title}
                                  </label>
                                </div>
                              ))}
                          </div>
                          <div className='radiobox-group d-flex flex-wrap align-items-center'>
                            {e1.response_type === 'radio' &&
                              e1.options.map((e2, i2) => (
                                <div
                                  className='form-check'
                                  key={i2}
                                  onChange={e => {
                                    const data = { ...this.state.data }
                                    if (e) {
                                      data[e1.question] = e.target.value
                                      this.setState({ data })
                                    }
                                  }}>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    checked={
                                      this.state.data &&
                                      this.state.data[e1.question] === e2.title
                                    }
                                    value={e2.title}
                                    id={e2.title + e1._id}
                                    name={e2.title + e1._id}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={e2.title + e1._id}>
                                    <span
                                      style={{
                                        marginLeft: '2.75rem',
                                      }}></span>
                                    {e2.title}
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
              <div className='sidebar-footer d-flex align-items-center justify-content-between'>
                {(this.props.answer.data &&
                  this.props.answer.data.is_submitted === false) ||
                this.props.answer.data === null ? (
                  <>
                    <button type='submit' className='btn btn-outline no-shadow'>
                      Save
                    </button>
                    <button
                      type='submit'
                      onClick={() => this.setState({ is_submitted: true })}
                      className='btn btn-primary'>
                      Submit
                    </button>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </form>
        </div>
        <div
          className={
            this.props.isShow === true || this.props.isShow1 === true
              ? 'overlay4 show'
              : 'overlay4'
          }></div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  detail: getAssessment(state).detail,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  getPatientProfile: getPatient(state).profile,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
  assessment: getAssessment(state).assessment,
})
const mapDispatchToProps = dispatch => ({
  loadAppointmentDetailPatient: (data, callBack) =>
    dispatch(loadAppointmentDetailPatient(data, callBack)),
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadAnswerAssessment: params => dispatch(loadAnswerAssessment(params)),
  addAnswerAssessment: (data, callBack) =>
    dispatch(addAnswerAssessment(data, callBack)),
  loadAllAssessment: params => dispatch(loadAllAssessment(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(NotesThought)
