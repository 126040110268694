import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./header";
import { Link, withRouter } from "react-router-dom";
import LeftBar from "./leftBar";
import { deleteStaff, getStaff, loadStaff } from "../../../store/staff";
import { toast } from "react-toastify";
import AlertError from "../../common/alertError";
import AlertSuccess from "../../common/alertSuccess";
import editIcon from "../../../include/images/outline/edit.svg";
import delIcon from "../../../include/images/outline/trash.svg";
import NoRecord from "../../common/noRecord";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

class Staffs extends Component {
  state = {
    step: 1,
    sidebarToogle: false,
    popUp: false,
    id: "",
  };
  togglePopup = (e, id) => {
    if (e) e.preventDefault();
    this.setState({ popUp: true, id });
  };
  componentDidMount() {
    document.body.className = "dashboard";
    this.props.loadStaff();
  }

  callback = (res) => {
    this.props.loadStaff();
    this.setState({ popUp: false });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Record deleted."} />);
    } else {
      toast(<AlertError message={res.data.error} />);
    }
  };

  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };
  render() {
    return (
      <>
        <ReactTooltip className="testPurple" html={true} />
        <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          <div className="dashboard-main-container">
            <div className="dashboard-wrapper">
              <div className="dashboard-single-panel">
                <div className="dsp-head d-flex aligsn-items-md-center justify-content-md-between">
                  <h4>My Staff</h4>
                  <div className="dsp-right-content d-flex">
                    <div className="new-filter-box-outer d-flex align-items-center justify-content-center"></div>
                    <Link
                      data-tip="You can add your staff from here to manage your appointments. They can’t access the patient’s health records."
                      to={"/doctor/staff/add"}
                      class="btn btn-default no-shadow"
                    >
                      + New Staff
                    </Link>
                  </div>
                </div>
                <div className="dsp-body">
                  <div className="tab-container d-none d-md-block"></div>
                  <div className="tab-content-box">
                    <div className="tab-content" id="myTabContent">
                      <div
                        class="tab-pane card fade show active"
                        id="allpatients"
                        role="tabpanel"
                        aria-labelledby="allpatients-tab"
                      >
                        <ul class="chat-member-list bordered has-shadow ">
                          <div class="content-body show add-scrollbar  scrollbar-dynamic">
                            {this.props.getStaff.staffs.length > 0 ? (
                              this.props.getStaff.staffs.map((es) => (
                                <li class="cm-item">
                                  <div class="cm-box noarrow d-flex flex-wrap">
                                    <div class="person-details-box d-flex align-items-center flex-grow-1">
                                      <div class="person-text-box">
                                        <h3>
                                          {es.first_name + " " + es.last_name}
                                        </h3>
                                        <p>
                                          {es.email}{" "}
                                          {es.phone ? ", " + es.phone : ""}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="np-right d-flex flex-wrap">
                                      <ReactTooltip
                                        className="testPurple"
                                        html={true}
                                      />
                                      <Link
                                        data-tip="Edit staff details"
                                        to={{
                                          pathname: "/doctor/staff/add",
                                          state: es,
                                        }}
                                        class="np-links"
                                        data-toggle="document"
                                      >
                                        <img src={editIcon} alt="" />
                                      </Link>
                                      <a
                                        data-tip="Delete the staff"
                                        href="#!"
                                        class="np-links"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.togglePopup(e, es._id);
                                        }}
                                      >
                                        <img src={delIcon} alt="" />
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              ))
                            ) : (
                              <NoRecord
                                noLogo={true}
                                header="No Staff Added By You"
                                text="Staff will be able to handle booking of online or offline appointments for you and also add new patients to your dashboard. They have restricted access of your dashboard. You may add or remove staff anytime."
                              />
                            )}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade time-block-modal"
            show={this.state.popUp}
            onHide={(e) => this.setState({ popUp: false })}
            centered
            dialogClassName="profile"
            contentClassName="p-0"
          >
            <div class="tbm-box">
              <div class="tbm-head">
                <h5>Delete Staff</h5>
              </div>
              <div class="tbm-body">
                <p>Are you sure you want to proceed?</p>
              </div>
              <div class="tbm-footer d-flex align-items-center">
                <a
                  disable={this.state.loading}
                  onClick={(e) => this.setState({ popUp: false })}
                  href="#!"
                  className={
                    this.state.loading
                      ? "btn btn-outline no-shadow disabled"
                      : "btn btn-outline no-shadow"
                  }
                  data-dismiss="modal"
                >
                  Cancel
                </a>
                <a
                  onClick={(e) =>
                    this.props.deleteStaff(this.state.id, this.callback)
                  }
                  href="#!"
                  class="btn btn-default no-shadow"
                >
                  yes
                </a>
              </div>
            </div>
          </Modal>
        </div>

        {/* <Modal
          className="modal fade custom-modal"
          show={this.state.popUp}
          onHide={(e) => this.setState({ popUp: false })}
        >
          <div class="modal-body">
            <h6>Delete Staff</h6>
            <p>Are you sure you want to proceed?</p>{" "}
            <div class="custom-modal-btn">
              <a
                disabled={this.state.loader}
                href="#!"
                class={
                  this.state.loader
                    ? "btn btn-primary btn-block disabled"
                    : "btn btn-primary btn-block"
                }
                onClick={(e) =>
                  this.props.deleteStaff(this.state.id, this.callback)
                }
              >
                Yes
              </a>
              <a
                href="#!"
                class="btn-text"
                onClick={(e) => this.setState({ popUp: false })}
              >
                No
              </a>
            </div>
          </div>
        </Modal> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  getStaff: getStaff(state),
});
const mapDispatchToProps = (dispatch) => ({
  loadStaff: () => dispatch(loadStaff()),
  deleteStaff: (id, callback) => dispatch(deleteStaff(id, callback)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Staffs));
