import React, { Component } from "react";
import logo from "../../../../include/images/yes-mindy-main-logo-white-color.png";

import VerifyMobile from "./verifyMobile";
import VerifyOtp from "./verifyOtp";

class RequestOtpDoctor extends Component {
  state = {
    accountFor: "self",
    partnerId: false,
    step: 1,
    type: 1,
    verifyMobile: {},
    personalDetails: {},
    guardianDetails: {},
    patientDetails: {},
    partnerDetail: {},
    passwordInfo: {},
    inforMation: [
      [
        "Welcome Onboard - Looking Forward To Create A Difference In Lives Of People As A Team",
        "Welcome Onboard - Looking Forward To Create A Difference In Lives Of People As A Team.",
        "Welcome Onboard - Looking Forward To Create A Difference In Lives Of People As A Team",
        "Information You Share Is For Us Only.",
        "Your Security And Privacy Is Of High Concern To Us.",
        "",
        "Let Us Know A Bit About Your Partner",
        "Your Profile Is Successfully Created",
        "",
        "Information You Share Is For Us Only.",
        "We Will Be With You At Every Step Towards The Recovery Of Your Loved One.",
      ],
    ],
  };

  componentDidMount = () => {
    if (this.props.match.params.id)
      this.setState({ step: 9, partnerId: this.props.match.params.id });
  };

  setPasswordInfo = (passwordInfo) => {
    this.setState({ passwordInfo });
  };

  updateStep = (e, step, accountFor = false) => {
    if (e) e.preventDefault();
    this.setState({ step });
    if (accountFor) {
      this.setState({ accountFor });
    }
  };

  updateVerifyMobile = (verifyMobile) => {
    this.setState({ verifyMobile });
  };

  updatePersonalDetails = (personalDetails) => {
    this.setState({ personalDetails });
  };

  updatePatientDetails = (patientDetails) => {
    this.setState({ patientDetails });
  };

  updateGuardianDetails = (guardianDetails) => {
    this.setState({ guardianDetails });
  };
  updatePartnerDetails = (partnerDetail) => {
    this.setState({ partnerDetail });
  };

  leftSideStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <VerifyMobile
            accountFor={this.state.accountFor}
            updateStep={this.updateStep}
            updateVerifyMobile={this.updateVerifyMobile}
            verifyMobile={this.state.verifyMobile}
          />
        );

      case 3:
        return (
          <VerifyOtp
            accountFor={this.state.accountFor}
            updateStep={this.updateStep}
            verifyMobile={this.state.verifyMobile}
            updateVerifyMobile={this.updateVerifyMobile}
          />
        );

      default:
        break;
    }
  };

  render() {
    return (
      <div className="member-container">
        <div className="top-logo">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="member-bg hero-background"></div>
        <div className="member-box d-flex login-form">
          <div className="member-left">
            <div className="ml-box">
              <h1>
                {this.state.accountFor === "guardian" && this.state.step === 2
                  ? "Thank You For Taking Care Of Your Loved Ones."
                  : this.state.inforMation[this.state.type - 1][
                      this.state.step - 1
                    ]}
              </h1>

              {this.state.step === 7 && (
                <p className="mb-0"> We Will Get In Touch.</p>
              )}
              {this.state.step === 8 && (
                <p className="mb-0">
                  Welcome To The Most Professional, Secured and Integrated
                  Platform For All Your Mental Health Needs.
                </p>
              )}
            </div>
          </div>
          {this.leftSideStep()}
        </div>
      </div>
    );
  }
}

export default RequestOtpDoctor;
