import React, { Component } from "react";
import Header from "../doctor/dashboard/header";
import LeftBar from "./dashboard/leftBar";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import { doctorDesk, getMisc, loadConstant, loadFaq } from "../../store/misc";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertSuccess from "../common/alertSuccess";
import cimage from "./../../include/images/communication-center-icon-white.svg";

class DoctorDesk extends Component {
  state = {
    selectedId: false,
    data: {
      topic: "",
      description: "",
    },
    errors: {},
    sidebarToogle: false,
  };
  componentDidMount() {
    this.props.loadConstant();
    document.body.className = "dashboard";
    this.props.loadFaq({ category: "Doctor" });
  }
  onChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    const { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    if (!data.topic || !data.description)
      return toast(<AlertError message={"Topic and Description required."} />);
    this.props.doctorDesk(data, this.callback);
  };
  callback = (res) => {
    this.setState({
      data: {
        topic: "",
        description: "",
      },
    });
    if (res.status === 200) {
      toast(<AlertSuccess message={"Your query has been sent to the Admin"} />);
    } else {
      toast(<AlertError message={"Something went wrong."} />);
    }
  };

  toggleSidebar = (e) => {
    if (e) e.preventDefault();
    this.setState({ sidebarToogle: !this.state.sidebarToogle });
  };

  render() {
    console.log("getMiscgetMisc", this.state.selectedId, this.props.getMisc);
    return (
      <div class={this.state.sidebarToogle ? "dashboard on" : "dashboard"}>
        <LeftBar />
        <Header
          sidebarToogle={this.state.sidebarToogle}
          toggleSidebar={this.toggleSidebar}
        />
        <div class="dashboard-main-container desk-container">
          <div class="row">
            <div class="col-md-12">
              <div class="desk-upper">
                <div class="du-header w-100 d-md-flex flex-wrap flex-md-row-reverse align-items-md-center">
                  <ul class="query-list d-flex flex-wrap align-items-center ml-auto">
                    <li>
                      <img src={cimage} alt="" />
                      <span>Helpline</span>
                    </li>
                    <li>
                      <a href="tel:7042597742">7042597742</a>
                    </li>
                    <li>
                      <a href="mailto:team@yesmindy.com">team@yesmindy.com</a>
                    </li>
                  </ul>
                  <h3>Raise a New Query</h3>
                </div>
                <div class="desk-query-box d-flex align-items-center justify-content-center">
                  <div class="query-input">
                    <input
                      onChange={this.onChange}
                      type="text"
                      class="form-control"
                      placeholder="Topic of Query"
                      value={this.state.data.topic}
                      name="topic"
                    />
                    {/* {this.state.errors.topic && (
                      <label className="error">{this.state.errors.topic}</label>
                    )} */}
                  </div>
                  <div class="input-group">
                    <input
                      onChange={this.onChange}
                      type="text"
                      class="form-control"
                      placeholder="Describe your issue"
                      value={this.state.data.description}
                      name="description"
                    />

                    <div class="submit-btn">
                      <a
                        onClick={this.handleSubmit}
                        href="!#"
                        class="btn btn-default"
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
                <div class="desk-lower">
                  <h3>FAQS</h3>
                  <div class="faq-box">
                    <div class="accordion" id="accordionExample">
                      {this.props.getMisc.map((e, i) => (
                        <div class="item">
                          <a
                            href="#!"
                            class={
                              this.state.selectedId === e._id
                                ? "ih-box"
                                : "ih-box collapsed"
                            }
                            id={"headingOne" + i}
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded={
                              this.state.selectedId === e._id ? "true" : "false"
                            }
                            aria-controls="collapseOne"
                            onClick={(ed) => {
                              ed.preventDefault();
                              this.setState({
                                selectedId: e._id,
                              });
                              console.log("getMiscgetMisc", e);
                            }}
                          >
                            <h3>
                              <span>
                                Q{i + 1}. {e.question}
                              </span>
                            </h3>
                            <span class="icon ml-auto">
                              <i>
                                <img
                                  src="include/images/down-icon-1.svg"
                                  alt=""
                                />
                              </i>
                            </span>
                          </a>
                          <div
                            id="collapseOne"
                            class={
                              this.state.selectedId === e._id
                                ? "collapse show"
                                : "collapse"
                            }
                            aria-labelledby={"headingOne" + i}
                            data-parent="#accordionExample"
                          >
                            <div class="t-p">
                              <h5>{e.answer}</h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  doctorDesk: (param, callback) => dispatch(doctorDesk(param, callback)),
  loadFaq: (param) => dispatch(loadFaq(param)),
  loadConstant: () => dispatch(loadConstant()),
});
const mapStateToProps = (state) => ({
  getMisc: getMisc(state).faq,
  constants: getMisc(state).constants,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DoctorDesk)
);
