import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCaserecord,
  addDemographics,
  loadCaseRecord,
} from "../../../../store/caseRecord";
import seUploadFun from "../../../services/s3Services";
import download from "../../../../include/images/download-icon.svg";
import _ from "lodash";
import AlertSuccess from "../../../common/alertSuccess";
import { toast } from "react-toastify";
import AlertError from "../../../common/alertError";
class Demographics extends Component {
  state = {
    education: "",
    occupation: "",
    marital_status: "",
    residence: "",
    family: "",
    source_of_referral: "",
    displayImage1: "",
    photoStatus1: "",
    appointment_category: "",
    consent: "",
    consent_id: "",
    id_proof: "",
    displayImage2: "",
    photoStatus2: "",
    informant: "",
    informant_name: "",
    informant_relation: "",
    type_of_patient: "",
    name: "",
    relation: "",
    number: "",

    relations: [
      {
        id: "Father",
        name: "Father",
      },
      {
        id: "Mother",
        name: "Mother",
      },

      {
        id: "Grand Father",
        name: "Grand Father",
      },
      {
        id: "Grand Mother",
        name: "Grand Mother",
      },
      {
        id: "Brother",
        name: "Brother",
      },
      {
        id: "Sister",
        name: "Sister",
      },
      {
        id: "Son",
        name: "Son",
      },
      {
        id: "Daughter",
        name: "Daughter",
      },
      {
        id: "Husband",
        name: "Husband",
      },
      {
        id: "Wife",
        name: "Wife",
      },
    ],
  };
  onformSubmit = (e) => {
    e.preventDefault();

    const {
      education,
      occupation,
      marital_status,
      residence,
      family,
      source_of_referral,
      appointment_category,
      consent,
      consent_id,
      id_proof,
      informant,
      informant_name,
      informant_relation,
      type_of_patient,
      name,
      relation,
      number,
    } = this.state;
    if (consent === "explicit" && !consent_id)
      return toast(<AlertError message={"Consent file is required."} />);
    const id = this.props.data._id;
    const formData = {
      demographics: _.pickBy(
        {
          education: education,
          occupation: occupation,
          marital_status: marital_status,
          residence: residence,
          family: family,
          source_of_referral: source_of_referral,
          appointment_details: _.pickBy(
            {
              appointment_category: appointment_category,
              consent: consent,
              consent_file: consent_id,
              informant: informant,
              informant_name: informant_name,
              informant_relation: informant_relation,
              type_of_patient: type_of_patient,
              nominated_guardian: _.pickBy(
                {
                  name: name,
                  relation: relation,
                  number: number.toString(),
                },
                _.identity
              ),
            },
            _.identity
          ),
          id_proof: id_proof,
        },
        _.identity
      ),
    };
    const newData = _.pickBy(formData, _.identity);
    this.props.addDemographics(newData, id, this.callBack);
  };
  callBack = (res) => {
    const _id = this.props.data && this.props.data._id;
    if (res && res.status === 200) {
      this.props.updateStep(null,2)
      this.props.loadCaseRecord(_id);
      toast(<AlertSuccess message={"Information Saved."} />);
    }
  };

  toggleEducation = (e) => {
    this.setState({ education: e.target.value });
  };
  toggleType = (e) => {
    this.setState({ type_of_patient: e.target.value });
  };
  toggleInformant = (e) => {
    this.setState({ informant: e.target.value });
  };
  toggleConsent = (e) => {
    this.setState({ consent: e.target.value });
  };
  toggleAppointment = (e) => {
    this.setState({ appointment_category: e.target.value });
  };
  toggleFamily = (e) => {
    this.setState({ family: e.target.value });
  };
  toggleSource = (e) => {
    this.setState({ source_of_referral: e.target.value });
  };
  toggleOccupation = (e) => {
    this.setState({ occupation: e.target.value });
  };
  toggleMarital = (e) => {
    this.setState({ marital_status: e.target.value });
  };
  toggleResidence = (e) => {
    this.setState({ residence: e.target.value });
  };
  componentDidMount() {
    document.body.className = "dashboard fixed";
    if (this.props.data) {
      const {
        nominated_representative_name,
        representative_relation,
        representative_number,
        _id,
      } = this.props.data;
      this.props.loadCaseRecord(_id);
      const { name, relation, number } = this.state;

      this.setState({
        name: name
          ? name
          : nominated_representative_name
          ? nominated_representative_name
          : "",
        relation: relation
          ? relation
          : representative_relation
          ? representative_relation
          : "",
        number: number
          ? number
          : representative_number
          ? representative_number
          : "",
      });
    }
  }
  fileUpload1 = (e) => {
    this.setState({ photoStatus1: "Loading" });
    const fileName =
      "doctor/consent/file/" +
      this.props.data._id +
      "/" +
      e.target.files[0].name;
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      this.setState({
        photoStatus1: "Uploaded successfully. Click here to change",
        consent_id: result.key,
        displayImage1: e.target.files[0].name,
      });
    });
  };
  fileUpload2 = (e) => {
    this.setState({ photoStatus2: "Loading" });
    const fileName =
      "doctor/idProof/file/" + this.props.data._id + e.target.files[0].name;
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      this.setState({
        photoStatus2: "Uploaded successfully. Click here to change",
        id_proof: result.key,
        displayImage2: e.target.files[0].name,
      });
    });
  };
  addtionalDemographics = (first, second) => {
    const { demographics } = this.props.case;
    return demographics && demographics[first] && demographics[first][second]
      ? demographics[first][second]
      : "";
  };
  addtionalDemographics1 = (first, second, third) => {
    const { demographics } = this.props.case;
    return demographics &&
      demographics[first] &&
      demographics[first][second] &&
      demographics[first][second][third]
      ? demographics[first][second][third]
      : "";
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data !== prevProps.data) {
      const {
        nominated_representative_name,
        representative_relation,
        representative_number,
        _id,
      } = this.props.data;
      this.props.loadCaseRecord(_id);

      const { name, relation, number } = this.state;
      this.setState({
        name: name
          ? name
          : nominated_representative_name
          ? nominated_representative_name
          : "",
        relation: relation
          ? relation
          : representative_relation
          ? representative_relation
          : "",
        number: number
          ? number
          : representative_number
          ? representative_number
          : "",
      });
    }

    if (this.props.case && this.props.loading !== prevProps.loading) {
      const { demographics } = this.props.case;
      const image2 =
        demographics &&
        demographics.id_proof &&
        demographics.id_proof.split("/");
      const image1 =
        demographics &&
        demographics.appointment_details &&
        demographics.appointment_details.consent_file &&
        demographics.appointment_details.consent_file.split("/");
      this.setState({
        education: demographics && demographics.education,
        occupation: demographics && demographics.occupation,
        marital_status: demographics && demographics.marital_status,
        residence: demographics && demographics.residence,
        family: demographics && demographics.family,
        source_of_referral: demographics && demographics.source_of_referral,
        id_proof: demographics && demographics.id_proof,
        displayImage2: image2 && image2[image2 && image2.length - 1],
        displayImage1: image1 && image1[image1 && image1.length - 1],
        appointment_category: this.addtionalDemographics(
          "appointment_details",
          "appointment_category"
        ),
        consent: this.addtionalDemographics("appointment_details", "consent"),
        consent_id: this.addtionalDemographics(
          "appointment_details",
          "consent_file"
        ),
        informant: this.addtionalDemographics(
          "appointment_details",
          "informant"
        ),
        informant_name: this.addtionalDemographics(
          "appointment_details",
          "informant_name"
        ),
        informant_relation: this.addtionalDemographics(
          "appointment_details",
          "informant_relation"
        ),
        type_of_patient: this.addtionalDemographics(
          "appointment_details",
          "type_of_patient"
        ),
        name: this.addtionalDemographics1(
          "appointment_details",
          "nominated_guardian",
          "name"
        ),
        relation: this.addtionalDemographics1(
          "appointment_details",
          "nominated_guardian",
          "relation"
        ),
        number: this.addtionalDemographics1(
          "appointment_details",
          "nominated_guardian",
          "number"
        ),
      });
    }
  };

  render() {
    const { demographicsAdd } = this.props;
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="demographics"
          role="tabpanel"
          aria-labelledby="demographics-tab"
        >
          <div class="card-header">Demographics</div>
          <div class="content-body show add-scrollbar  scrollbar-dynamic"> */}
        <form onSubmit={this.onformSubmit} id="myform">
          <div class="crf-content-box">
            <h4>Demographics</h4>
            <div class="radio-group-box">
              <h5>Education</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    id="noEducation"
                    name="education"
                    class="form-check-input"
                    checked={this.state.education === "noEducation"}
                    value="noEducation"
                    onChange={(e) => this.toggleEducation(e)}
                  />
                  <label class="form-check-label" htmlFor="noEducation">
                    No Education
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="underGraduate"
                    checked={this.state.education === "underGraduate"}
                    value="underGraduate"
                    onChange={(e) => this.toggleEducation(e)}
                    name="education"
                  />
                  <label class="form-check-label" htmlFor="underGraduate">
                    Under Graduate
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    checked={this.state.education === "Graduate"}
                    value="Graduate"
                    onChange={(e) => this.toggleEducation(e)}
                    id="Graduate"
                    name="education"
                  />
                  <label class="form-check-label" htmlFor="Graduate">
                    Graduate
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    checked={this.state.education === "postGraduate"}
                    value="postGraduate"
                    onChange={(e) => this.toggleEducation(e)}
                    id="postGraduate"
                    name="education"
                  />
                  <label class="form-check-label" htmlFor="postGraduate">
                    Post Graduate
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Occupation</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    onChange={(e) => this.toggleOccupation(e)}
                    class="form-check-input"
                    value="student"
                    id="student"
                    name="occupation"
                    checked={this.state.occupation === "student"}
                  />
                  <label class="form-check-label" htmlFor="student">
                    Student
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    onChange={(e) => this.toggleOccupation(e)}
                    value="unemployed"
                    id="unemployed"
                    name="occupation"
                    checked={this.state.occupation === "unemployed"}
                  />
                  <label class="form-check-label" htmlFor="unemployed">
                    Unemployed
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    onChange={(e) => this.toggleOccupation(e)}
                    value="selfEmployed"
                    id="selfEmployed"
                    name="occupation"
                    checked={this.state.occupation === "selfEmployed"}
                  />
                  <label class="form-check-label" htmlFor="selfEmployed">
                    Self Employed
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    onChange={(e) => this.toggleOccupation(e)}
                    value="pvtGovEmployed"
                    id="pvtGovEmployed"
                    name="occupation"
                    checked={this.state.occupation === "pvtGovEmployed"}
                  />
                  <label class="form-check-label" htmlFor="pvtGovEmployed">
                    Pvt./Govt Employed
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Marital status</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="single"
                    value="single"
                    name="maritalStatus"
                    onChange={(e) => this.toggleMarital(e)}
                    checked={this.state.marital_status === "single"}
                  />
                  <label class="form-check-label" htmlFor="single">
                    Single
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="married"
                    checked={this.state.marital_status === "married"}
                    value="married"
                    name="maritalStatus"
                    onChange={(e) => this.toggleMarital(e)}
                  />
                  <label class="form-check-label" htmlFor="married">
                    Married
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="separated"
                    checked={this.state.marital_status === "separated"}
                    value="separated"
                    name="maritalStatus"
                    onChange={(e) => this.toggleMarital(e)}
                  />
                  <label class="form-check-label" htmlFor="separated">
                    Separated
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="widow"
                    checked={this.state.marital_status === "widow"}
                    value="widow"
                    name="maritalStatus"
                    onChange={(e) => this.toggleMarital(e)}
                  />
                  <label class="form-check-label" htmlFor="widow">
                    Widow
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Residence</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="rural"
                    name="residence"
                    value="rural"
                    checked={this.state.residence === "rural"}
                    onChange={(e) => this.toggleResidence(e)}
                  />
                  <label class="form-check-label" htmlFor="rural">
                    Rural
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="urban"
                    name="residence"
                    value="urban"
                    checked={this.state.residence === "urban"}
                    onChange={(e) => this.toggleResidence(e)}
                  />
                  <label class="form-check-label" htmlFor="urban">
                    Urban
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="semirural"
                    name="residence"
                    value="semirural"
                    checked={this.state.residence === "semirural"}
                    onChange={(e) => this.toggleResidence(e)}
                  />
                  <label class="form-check-label" htmlFor="semirural">
                    Semi-Rural
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="semiUrban"
                    name="residence"
                    value="semiUrban"
                    checked={this.state.residence === "semiUrban"}
                    onChange={(e) => this.toggleResidence(e)}
                  />
                  <label class="form-check-label" htmlFor="semiUrban">
                    Semi Urban
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Family</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="joint"
                    name="family"
                    value="joint"
                    checked={this.state.family === "joint"}
                    onChange={(e) => this.toggleFamily(e)}
                  />
                  <label class="form-check-label" htmlFor="joint">
                    Joint
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="nuclear"
                    value="nuclear"
                    name="family"
                    checked={this.state.family === "nuclear"}
                    onChange={(e) => this.toggleFamily(e)}
                  />
                  <label class="form-check-label" htmlFor="nuclear">
                    Nuclear
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Source of referral</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="self"
                    value="self"
                    name="souceOfReferral"
                    checked={this.state.source_of_referral === "self"}
                    onChange={(e) => this.toggleSource(e)}
                  />
                  <label class="form-check-label" htmlFor="self">
                    Self
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="doctor"
                    value="doctor"
                    checked={this.state.source_of_referral === "doctor"}
                    onChange={(e) => this.toggleSource(e)}
                    name="souceOfReferral"
                  />
                  <label class="form-check-label" htmlFor="doctor">
                    Doctor
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="familyFriend"
                    value="familyFriend"
                    checked={this.state.source_of_referral === "familyFriend"}
                    onChange={(e) => this.toggleSource(e)}
                    name="souceOfReferral"
                  />
                  <label class="form-check-label" htmlFor="familyFriend">
                    Family/Friends
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="psychologist"
                    value="psychologist"
                    checked={this.state.source_of_referral === "psychologist"}
                    onChange={(e) => this.toggleSource(e)}
                    name="souceOfReferral"
                  />
                  <label class="form-check-label" htmlFor="psychologist">
                    Psychologist
                  </label>
                </div>
              </div>
            </div>
            <div class="pane-seprator"></div>
            <h4>Appointment Details</h4>
            <div class="radio-group-box">
              <h5>Appointment Category</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="individual1"
                    value="individual"
                    name="appointmentCat"
                    checked={this.state.appointment_category === "individual"}
                    onChange={(e) => this.toggleAppointment(e)}
                  />
                  <label class="form-check-label" htmlFor="individual1">
                    Individual
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="caregiver"
                    value="caregiver"
                    name="appointmentCat"
                    checked={this.state.appointment_category === "caregiver"}
                    onChange={(e) => this.toggleAppointment(e)}
                  />
                  <label class="form-check-label" htmlFor="caregiver">
                    Caregiver
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="couple"
                    value="couple"
                    name="appointmentCat"
                    checked={this.state.appointment_category === "couple"}
                    onChange={(e) => this.toggleAppointment(e)}
                  />
                  <label class="form-check-label" htmlFor="couple">
                    Couple
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Consent</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="guardianCaregiver"
                    name="consent"
                    value="guardian/Caregiver"
                    checked={this.state.consent === "guardian/Caregiver"}
                    onChange={(e) => this.toggleConsent(e)}
                  />
                  <label class="form-check-label" htmlFor="guardianCaregiver">
                    Guardian/Caregiver
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="implicit"
                    name="consent"
                    value="implicit"
                    checked={this.state.consent === "implicit"}
                    onChange={(e) => this.toggleConsent(e)}
                  />
                  <label class="form-check-label" htmlFor="implicit">
                    Implicit
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="explicit"
                    name="consent"
                    value="explicit"
                    checked={this.state.consent === "explicit"}
                    onChange={(e) => this.toggleConsent(e)}
                  />
                  <label class="form-check-label" htmlFor="explicit">
                    Explicit
                  </label>
                </div>
                <div
                  class="row mb-0"
                  style={{ display: "unset", marginLeft: "0" }}
                >
                  <label class="btn btn-upload" htmlFor="photograph2">
                    <span>
                      <i class="fas fa-paperclip"></i>Upload File/Image
                    </span>
                  </label>
                  <input
                    type="file"
                    id="photograph2"
                    class="d-none"
                    onChange={this.fileUpload1}
                  />
                  <a
                    href={process.env.REACT_APP_S3URL + this.state.consent_id}
                    target="_blank"
                  >
                    <span class="uplooad-file-intro mr-2">
                      {this.state.displayImage1}
                    </span>
                  </a>
                  <img
                    width="75px"
                    src={process.env.REACT_APP_S3URL + this.state.consent_id}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Informant</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="self1"
                    name="informant"
                    value="self"
                    checked={this.state.informant === "self"}
                    onChange={(e) => this.toggleInformant(e)}
                  />
                  <label class="form-check-label" htmlFor="self1">
                    Self
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="family1"
                    name="informant"
                    value="family"
                    checked={this.state.informant === "family"}
                    onChange={(e) => this.toggleInformant(e)}
                  />
                  <label class="form-check-label" htmlFor="family1">
                    Family
                  </label>
                </div>

                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="friend1"
                    name="informant"
                    value="friend"
                    checked={this.state.informant === "friend"}
                    onChange={(e) => this.toggleInformant(e)}
                  />
                  <label class="form-check-label" htmlFor="friend1">
                    Friend
                  </label>
                </div>
              </div>
              <div class="input-group-row d-flex flex-wrap">
                <div class="ig-column">
                  <div
                    class={
                      this.state.informant_name
                        ? "form-group custom-input show on"
                        : "form-group custom-input"
                    }
                  >
                    <label htmlFor="text2">Name</label>
                    <input
                      type="text"
                      id="text2"
                      value={this.state.informant_name}
                      onChange={(e) =>
                        this.setState({ informant_name: e.target.value })
                      }
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="ig-column">
                  <div class="form-group show">
                    <select
                      class="form-control"
                      value={this.state.informant_relation}
                      onChange={(e) =>
                        this.setState({
                          informant_relation: e.target.value,
                        })
                      }
                    >
                      <option>Relationship</option>
                      {this.state.relations.map((e1, i) => (
                        <option>{e1.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>Type Of Patient</h5>
              <div class="radio-group-row d-flex flex-wrap align-items-center">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="nop"
                    name="Top"
                    value="new online patient"
                    checked={
                      this.state.type_of_patient === "new online patient"
                    }
                    onChange={(e) => this.toggleType(e)}
                  />
                  <label class="form-check-label" htmlFor="nop">
                    New Online Patient
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="foa"
                    name="Top"
                    value="first online appointment"
                    checked={
                      this.state.type_of_patient === "first online appointment"
                    }
                    onChange={(e) => this.toggleType(e)}
                  />
                  <label class="form-check-label" htmlFor="foa">
                    First Online Appointment(Previously seen)
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="ncp"
                    name="Top"
                    value="new clinic patient"
                    checked={
                      this.state.type_of_patient === "new clinic patient"
                    }
                    onChange={(e) => this.toggleType(e)}
                  />
                  <label class="form-check-label" htmlFor="ncp">
                    New Clinic Patient
                  </label>
                </div>
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="ocp"
                    name="Top"
                    value="old clinic patient"
                    checked={
                      this.state.type_of_patient === "old clinic patient"
                    }
                    onChange={(e) => this.toggleType(e)}
                  />
                  <label class="form-check-label" htmlFor="ocp">
                    Old Clinic Patient
                  </label>
                </div>
              </div>
            </div>
            <div class="radio-group-box">
              <h5>
                Nominated Guardian{" "}
                <small>(Skip if patient has not declared)</small>
              </h5>
              <div class="input-group-row d-flex flex-wrap">
                <div class="ig-column">
                  <div
                    class={
                      this.state.name
                        ? "form-group custom-input show on"
                        : "form-group custom-input"
                    }
                  >
                    <label htmlFor="text2">Name</label>
                    <input
                      type="text"
                      id="text2"
                      class="form-control"
                      onChange={(e) => this.setState({ name: e.target.value })}
                      value={this.state.name}
                    />
                  </div>
                </div>
                <div class="ig-column">
                  <div class="form-group show">
                    <select
                      class="form-control"
                      value={this.state.relation}
                      onChange={(e) =>
                        this.setState({ relation: e.target.value })
                      }
                    >
                      <option>Relationship</option>
                      {this.state.relations.map((e1, i) => (
                        <option>{e1.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div class="ig-column">
                  <div
                    class={
                      this.state.number
                        ? "form-group custom-input show on"
                        : "form-group custom-input"
                    }
                  >
                    <label htmlFor="text3">Number</label>
                    <input
                      type="text"
                      id="text3"
                      class="form-control"
                      value={this.state.number}
                      onChange={(e) =>
                        this.setState({ number: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="radio-group-box"
              style={{
                paddingLeft: "15px",
              }}
            >
              <h5>ID Proof/Patient's Consent</h5>
              <div class="row mb-0" style={{ display: "unset" }}>
                <label class="btn btn-upload" htmlFor="photograph3">
                  <span>
                    <i class="fas fa-paperclip"></i>Upload File/Image
                  </span>
                </label>
                <input
                  type="file"
                  id="photograph3"
                  class="d-none"
                  onChange={this.fileUpload2}
                />
                <a
                  href={process.env.REACT_APP_S3URL + this.state.id_proof}
                  target="_blank"
                >
                  <span class="uplooad-file-intro mr-3">
                    {this.state.displayImage2}
                  </span>
                </a>
                <img
                  width="75px"
                  src={process.env.REACT_APP_S3URL + this.state.id_proof}
                  alt=""
                />
              </div>
            </div>
          </div>
        </form>
        {/* </div>
        </div> */}
        <div class="patients-details-footer d-md-flex align-items-md-center justify-content-md-between">
          <Link to="/doctor/dashboard">
            <button class="btn btn-grey btn-lg">EXIT</button>
          </Link>
          {/* <div class="pdf-btn d-flex align-items-center">
            <a href="javascript:void(0)">
              <img src={download} />
            </a>
          </div> */}
          <button type="submit" class="btn btn-primary btn-lg" form="myform">
            SAVE
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  demographicsAdd: getCaserecord(state).demographicsAdd,
  case: getCaserecord(state).caserecord,
  loading: getCaserecord(state).caseRecordLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addDemographics: (params, id, callBack) =>
    dispatch(addDemographics(params, id, callBack)),
  loadCaseRecord: (id) => dispatch(loadCaseRecord(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Demographics)
);
