import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'
import AgoraRTC from 'agora-rtc-sdk-ng'

import 'rsuite/dist/rsuite-no-reset.min.css'

AgoraRTC.setLogLevel(4)

const tagManagerArgs = {
  gtmId: 'GTM-WDDS232',
}

TagManager.initialize(tagManagerArgs)

Sentry.init({
  dsn: 'https://88f5cb83b77342af84688e6356fdc45b@o499020.ingest.sentry.io/5577097',
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
