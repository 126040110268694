import React, { Component } from 'react'
import DashboardHeader from '../profile/common/dashboardHeader'
import DashboardLeftBar from '../profile/common/dashboardLeftBar'
import Activity from './postAppointment/activity'
import DoctorSelection from './postAppointment/doctorSelection'
import { Helmet } from 'react-helmet'
import { WatiScript } from '../../../include/js/wati'

class PostAppointment extends Component {
  state = {
    doctor_id: '',
    appointment_id: '',
    dropDown: false,
    tab: 'messages',
    scroll: true,
  }
  myRef = React.createRef()
  setDropDown = dropDown => this.setState({ dropDown })
  // updateStep = (e, step) => {
  //   if (e) e.preventDefault();
  //   this.setState({ step });
  // };
  setDoctorId = doctor_id => this.setState({ doctor_id })
  setAapointment_id = appointment_id => this.setState({ appointment_id })
  setTabe = tab => {
    this.setState({ tab })
  }

  dropDownToggle = () => {
    this.setState({ dropDown: !this.state.dropDown })
  }

  componentDidMount = () => {
    document.body.classList.remove('p-0')
  }

  componentDidUpdate = () => {
    if (this.myRef.current && this.state.scroll) {
      let element = document.getElementById('activity-content')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        this.setState({ scroll: false })
      }
    }
  }

  render() {
    const { newDoctor = false, type = '' } = this.props
    return (
      <>
        {/* <Helmet>
          <script id='wati'>{WatiScript}</script>
        </Helmet> */}
        <div onClick={e => this.setState({ dropDown: false })}>
          {!newDoctor && (
            <>
              <DashboardLeftBar />
              <DashboardHeader />
            </>
          )}
          <div
            className={
              !newDoctor ? 'dashboard-main-container chatbot-container' : ''
            }>
            <div
              className={!newDoctor ? 'chatbot-pannel d-flex flex-wrap' : ''}>
              <DoctorSelection
                newDoctor={newDoctor}
                type={type}
                doctor_id={this.state.doctor_id}
                setDropDown={this.setDropDown}
                setAapointment_id={this.setAapointment_id}
                setDoctorId={this.setDoctorId}
                tab={this.state.tab}
                setTable={this.setTabe}
                dropDownToggle={this.dropDownToggle}
                dropDown={this.state.dropDown}
                appointment_id={this.state.appointment_id}
              />
              {this.state.doctor_id && !newDoctor && (
                <Activity
                  myRef={this.myRef}
                  appointment_id={this.state.appointment_id}
                  doctor_id={this.state.doctor_id}
                  dropDownToggle={this.dropDownToggle}
                  dropDown={this.state.dropDown}
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PostAppointment
