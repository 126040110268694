import React from "react";

import { jsx } from "@emotion/react";
import PropTypes from "prop-types";

import { checkMessageForExtensionsData } from "../../util/common";

import ToolTip from "../ToolTip";
import ReplyCount from "../ReplyCount";
import ReadReciept from "../ReadReciept";
import RegularReactionView from "../RegularReactionView";

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageTxtWrapperStyle,
  messageTxtContainerStyle,
  messageTxtStyle,
  messageBtnStyle,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle,
} from "./style";

import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";

import whiteboardIcon from "./resources/senderwhiteboard.png";

class SenderWhiteboardBubble extends React.PureComponent {
  messageFrom = "sender";

  constructor(props) {
    super(props);
    const message = Object.assign({}, props.message, {
      messageFrom: this.messageFrom,
    });

    this.state = {
      message: message,
      isHovering: false,
    };
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.message);
    const currentMessageStr = JSON.stringify(this.props.message);

    if (previousMessageStr !== currentMessageStr) {
      const message = Object.assign({}, this.props.message, {
        messageFrom: this.messageFrom,
      });
      this.setState({ message: message });
    }
  }

  launchCollaborativeWhiteboard = () => {
    let whiteboardUrl = null;
    let whiteboardData = checkMessageForExtensionsData(
      this.state.message,
      "whiteboard"
    );
    if (
      whiteboardData &&
      whiteboardData.hasOwnProperty("board_url") &&
      whiteboardData.board_url.length
    ) {
      let username = this.props.loggedInUser.name.split(" ").join("_");
      // Appending the username to the board_url
      whiteboardUrl = whiteboardData.board_url + "&username=" + username;
      window.open(whiteboardUrl, "", "fullscreen=yes, scrollbars=auto");
    }
  };

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  render() {
    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.state.message,
      "reactions"
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            style={messageReactionsWrapperStyle()}
            className="message__reaction__wrapper"
          >
            <RegularReactionView
              {...this.props}
              message={this.state.message}
              reaction={reactionsData}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = <ToolTip {...this.props} message={this.state.message} />;
    }

    const documentTitle = Translator.translate(
      "CREATED_WHITEBOARD",
      this.props.lang
    );
    return (
      <div
        style={messageContainerStyle()}
        className="sender__message__container message__document"
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
      >
        {toolTipView}

        <div style={messageWrapperStyle()} className="message__wrapper">
          <div
            style={messageTxtWrapperStyle(this.props)}
            className="message__whiteboard__wrapper"
          >
            <div
              style={messageTxtContainerStyle()}
              className="message__whiteboard__container"
            >
              <img
                src={whiteboardIcon}
                alt={Translator.translate(
                  "COLLABORATIVE_WHITEBOARD",
                  this.props.lang
                )}
              />
              <p style={messageTxtStyle()} className="document__title">
                {documentTitle}
              </p>
            </div>
            <ul
              style={messageBtnStyle(this.props)}
              className="document__button"
            >
              <li onClick={this.launchCollaborativeWhiteboard}>
                <p>{Translator.translate("LAUNCH", this.props.lang)}</p>
              </li>
            </ul>
          </div>
        </div>

        {messageReactions}

        <div
          style={messageInfoWrapperStyle()}
          className="message__info__wrapper"
        >
          <ReplyCount {...this.props} message={this.state.message} />
          <ReadReciept {...this.props} message={this.state.message} />
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
SenderWhiteboardBubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

SenderWhiteboardBubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default SenderWhiteboardBubble;
