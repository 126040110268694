import React, { Component } from "react";
import headerImg from "../../include/images/invoice-header.png";
import logo from "./../../include/images/pdf/yes-mindy-logo.png";
import footerImg from "./../../include/images/purple-bg.png";
import { connect } from "react-redux";
import moment from "moment";
import { jsPDF } from "jspdf";
import { getMisc, loadConstant } from "../../store/misc";
import { getAppointment, loadAppointmentDetail } from "../../store/appointment";

class Invoice extends Component {
  generatePdf = () => {
    const doc = new jsPDF("p", "pt", "a4");

    doc.html(document.getElementById("pdfReady"), {
      callback: (pdfDoc) => {
        pdfDoc.save(this.props.forAppointment.data.appointment_no);
      },
    });
  };

  componentDidMount() {
    console.log("this.propsthis.props", this.props);
    this.props.loadAppointmentDetail(this.props.match.params.id);
    this.props.loadConstant();
  }
  render() {
    const ref = React.createRef();
    const options = {
      //   orientation: "landscape",
      //   unit: "in",
      //   format: [4, 2],
    };
    //console.log("forAppointment", this.props.forAppointment);
    const { header_email_address, helpline_no } = this.props.constants;
    const { data } = this.props.forAppointment;
    const { doctor_id, patient_id } = data;
    return (
      <>
        {doctor_id && doctor_id.name && (
          <div className="divTable" id="pdfReady">
            <link rel="stylesheet" href="/invoice.css" />
            <div className="divTableHeading">
              <div className="divTableRow">
                <div className="divTableHead">
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <img
                            width={307}
                            height={100}
                            style={{ display: "block" }}
                            src={headerImg}
                            alt=""
                          />
                        </td>
                        <td style={{ width: "50%", paddingLeft: "7%" }}>
                          <img width={60} height={30} src={logo} alt="logo" />
                          <p
                            style={{
                              fontSize: "7px",
                              lineHeight: "10px",
                              fontWeight: 600,
                              letterSpacing: "0.8px",
                              textTransform: "uppercase",
                              paddingTop: "3px",
                              paddingBottom: "18px",
                            }}
                          >
                            Health and Wellness Inovations PVT. LTD
                          </p>
                          <h4
                            style={{
                              fontSize: "10px",
                              lineHeight: "14px",
                              margin: 0,
                              textDecoration: "underline",
                              paddingBottom: "6px",
                            }}
                          >
                            Office :
                          </h4>
                          <p
                            style={{
                              fontSize: "9px",
                              lineHeight: "12px",
                              fontWeight: 400,
                              letterSpacing: "0.3px",
                            }}
                          >
                            A3/ 15 Rohini, New Delhi, India
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* header end here */}
            {/* body start here */}
            <div className="divTableBody">
              {/* row start here */}
              <div className="divTableRow">
                <div className="divTableCell">
                  <p
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#000",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.5)",
                        paddingRight: "8px",
                      }}
                    >
                      NAME :
                    </span>
                    {patient_id.first_name + " " + patient_id.last_name}
                  </p>
                  <b
                    style={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#000",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      paddingTop: "11px",
                      letterSpacing: "0.2px",
                      display: "inline-block",
                      paddingBottom: "40px",
                    }}
                  >
                    INVOICE # {data.appointment_no}
                  </b>
                </div>
              </div>
              {/* row end here */}
              {/* row start here */}
              <div className="divTableRow">
                <div className="divTableCell">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "15px",
                            letterSpacing: "0.46px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.5)",
                            textAlign: "left",
                            textTransform: "uppercase",
                            padding: "10px 0",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "15px",
                            fontWeight: 600,
                            letterSpacing: "0.46px",
                            color: "rgba(0, 0, 0, 0.5)",
                            textAlign: "left",
                            textTransform: "uppercase",
                            padding: "10px 0",
                          }}
                        >
                          DATE
                        </th>
                        <th
                          style={{
                            fontSize: "11px",
                            lineHeight: "15px",
                            fontWeight: 600,
                            letterSpacing: "0.46px",
                            color: "rgba(0, 0, 0, 0.5)",
                            textAlign: "right",
                            textTransform: "uppercase",
                            padding: "10px 0",
                          }}
                        >
                          FEES
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <strong
                            style={{
                              display: "inline-block",
                              fontSize: "11px",
                              lineHeight: "18px",
                              fontWeight: 600,
                              letterSpacing: "0.2px",
                              color: "#000",
                              padding: "20px 0",
                            }}
                          >
                            {doctor_id.name} <br />
                            {doctor_id.field_type.name} <br />
                            {data.appointment_type} Appointment
                          </strong>
                        </td>
                        <td>
                          <strong
                            style={{
                              display: "inline-block",
                              fontSize: "11px",
                              lineHeight: "18px",
                              fontWeight: 600,
                              letterSpacing: "0.2px",
                              color: "#000",
                              padding: "20px 0",
                            }}
                          >
                            {moment(data.appointment_time).format("LL")}
                          </strong>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <strong
                            style={{
                              display: "inline-block",
                              fontSize: "11px",
                              lineHeight: "18px",
                              fontWeight: 600,
                              letterSpacing: "0.2px",
                              color: "#000",
                              padding: "20px 0",
                            }}
                          >
                            {data.consultation_charges.toFixed(2)} INR
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.7)",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            padding: "21px 15px 0 0",
                          }}
                        >
                          FEES :
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.7)",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            padding: "21px 0 0px",
                          }}
                        >
                          <strong>
                            {data.consultation_charges.toFixed(2)} INR
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.7)",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingRight: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          TAX :
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.7)",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingTop: "5px",
                          }}
                        >
                          <strong>
                            {data.taxes ? data.taxes.toFixed(2) : "0.00"} INR
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "rgba(0, 0, 0, 0.7)",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingRight: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          SERVICE FEE :
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 600,
                            color: "#000",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingTop: "5px",
                          }}
                        >
                          <strong>
                            {data.convenience_charges
                              ? data.convenience_charges.toFixed(2)
                              : "0.00"}{" "}
                            INR
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 700,
                            color: "#000",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingRight: "15px",
                            paddingTop: "5px",
                          }}
                        >
                          <b>AMOUNT : </b>
                        </td>
                        <td
                          style={{
                            fontSize: "11px",
                            lineHeight: "18px",
                            fontWeight: 700,
                            color: "#000",
                            letterSpacing: "0.2px",
                            textAlign: "right",
                            paddingTop: "5px",
                          }}
                        >
                          <b> {data.amount.toFixed(2)} INR</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* row end here */}
            </div>
            {/* body end here */}
            {/* footer start here */}
            <div className="divTableFoot">
              <div className="divTableRow">
                <div
                  className="divTableCell"
                  style={{
                    paddingTop: "100px !important",
                    paddingBottom: "50px !important",
                  }}
                >
                  <table className="table2">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>
                          <h4
                            style={{
                              fontSize: "13px",
                              lineHeight: "18px",
                              letterSpacing: "0.3px",
                              fontWeight: 700,
                              margin: 0,
                              paddingBottom: "9px",
                            }}
                          >
                            CONTACT US FOR ANY QUERIES :-
                          </h4>
                          <p
                            style={{
                              fontSize: "9px",
                              lineHeight: "16px",
                              letterSpacing: "0.6px",
                              fontWeight: 600,
                              color: "rgba(0, 0, 0, 0.8)",
                            }}
                          >
                            <a href="#!">www.yesmindy.com</a>
                          </p>
                          <p
                            style={{
                              fontSize: "9px",
                              lineHeight: "16px",
                              letterSpacing: "0.6px",
                              fontWeight: 600,
                              color: "rgba(0, 0, 0, 0.8)",
                            }}
                          >
                            Email{" "}
                            <a href={"mailto:" + header_email_address}>
                              {header_email_address}
                            </a>
                          </p>
                          <p
                            style={{
                              fontSize: "9px",
                              lineHeight: "16px",
                              letterSpacing: "0.6px",
                              fontWeight: 600,
                              color: "rgba(0, 0, 0, 0.8)",
                            }}
                          >
                            Mobile{" "}
                            <a href={"tel:" + helpline_no}>{helpline_no}</a>
                          </p>
                        </td>
                        <td style={{ width: "50%" }}>
                          <img
                            width="100%"
                            height={70}
                            style={{ display: "block" }}
                            src={footerImg}
                            alt=""
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* footer end here */}
          </div>
        )}
        <div style={{ textAlign: "-webkit-center" }}>
          <button className="btn btn-sm btn-success" onClick={this.generatePdf}>
            Download
          </button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
  loadAppointmentDetail: (id) => dispatch(loadAppointmentDetail(id)),
});
const mapStateToProps = (state) => ({
  forAppointment: getAppointment(state).appointmentDetail,
  constants: getMisc(state).constants,
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
