import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./../../common/form/form";
import SignUpLeftBar from "../common/signUpLeftBar";
import AlertSuccess from "../../common/alertSuccess";
import AlertError from "../../common/alertError";

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from "../../../store/doctor";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getDoctorToken } from "../../services/localStorageServices";
import {
  drPersonalDetailFaild,
  profileUpdatedSuccess,
  bankNameRequired,
  accountRequired,
  ifscRequired,
  pancardRequired,
  accountInvalid,
} from "../../common/misc";
import FileInput from "../../common/form/fileInput";
import { getMisc } from "../../../store/misc";
import seUploadFun from "../../services/s3Services";
import SubmitBtn from "../../common/form/submitBtn";
import HelpText from "./helpText";
class Bank extends Form {
  state = {
    stateOnchange: false,
    states: [],
    btnClass: "btn btn-primary btn-lg",
    loading: false,
    data: {
      name: "",
      account_no: "",
      ifsc_code: "",
      pancard: "",
    },
    errors: {},
  };

  schema = {
    name: Joi.string()
      .required()
      .error(() => {
        return { message: bankNameRequired };
      }),
    account_no: Joi.number()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          console.log("eee", err.type);
          switch (err.type) {
            case "any.required":
              err.message = accountRequired;
              break;
            case "number.base":
              err.message = accountInvalid;
              break;
          }
        });
        return errors;
      }),

    ifsc_code: Joi.string()
      .required()
      .error(() => {
        return { message: ifscRequired };
      }),
    pancard: Joi.string()
      .required()
      .error(() => {
        return { message: pancardRequired };
      }),
  };

  fileUpload = (e) => {
    const _id = this.props.doctorProfile._id;
    const files = e.target.files;
    for (var i = 0; i < files.length; i++) {
      const element = files[i];
      const fileName =
        "doctor/" +
        _id +
        "/account/pancard/" +
        Date.now() +
        "-" +
        element.lastModified +
        "-" +
        element.size;
      const res = seUploadFun(element, fileName);
      res.then((result) => {
        const { data, errors } = this.state;
        errors.pancard = "";
        data.pancard = result.key;
        this.setState({ data, errors });
      });
    }
  };

  doSubmit = () => {
    const {
      name: bank_name,
      account_no,
      pancard: pancard_url,
      ifsc_code,
    } = this.state.data;
    const payLoad = {
      bank_details: {
        bank_name,
        account_no,
        ifsc_code,
        pancard_url,
      },
    };
    console.log(payLoad);
    if (!this.state.loading) {
      this.setState({
        btnClass: "btn btn-primary btn-lg disabled",
        loading: true,
      });
      this.props.doctorUpdate(payLoad, this.callBack);
    }
  };

  callBack = (res) => {
    this.setState({
      btnClass: "btn btn-primary btn-lg",
      loading: false,
    });
    if (res.status === 200) {
      this.props.history.push("/doctor/sign-up/link");
      toast(<AlertSuccess message={profileUpdatedSuccess} />);
      this.props.loadDoctorProfile();
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />);
    }
  };

  componentDidMount = () => {
    if (getDoctorToken("auth")) {
      this.props.loadDoctorProfile();
    } else {
      this.props.history.push("/doctor/sign-up/personal-detail");
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.doctorProfile.bank_details
    ) {
      const {
        bank_name: name,
        account_no,
        ifsc_code,
        pancard_url: pancard,
      } = this.props.doctorProfile.bank_details;
      const data = {
        name,
        account_no,
        ifsc_code,
        pancard,
      };
      this.setState({ data });
    }
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };

  render() {
    console.log(this.state);
    return (
      <React.Fragment>
        <SignUpLeftBar />
        <div className="main-container">
          <div className="main-head">
            <button className="nav-toggler" onClick={this.changeBodyClass}>
              <i className="fas fa-bars"></i>
            </button>
            <a href="#" className="brand-logo">
              <img src="include/images/yes-mindy-logo.svg" alt="" />
            </a>
          </div>
          <HelpText />
          <div className="container ml-0">
            <div className="main-heading">
              <h1>Bank Details</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="main-content">
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput("name", "Bank name", "text")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput("account_no", "Account number", "text")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderInput("ifsc_code", "IFSC Code", "text")}
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6">
                    <FileInput
                      totalUploaded={this.state.data["pancard"]}
                      error={this.state.errors["pancard"]}
                      label=" Pancard"
                      name="pancard"
                      status="Add file"
                      onchange={this.fileUpload}
                      text={this.state.data["pancard"]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-5">
                    <div className="form-group">
                      <SubmitBtn
                        label="Save & Continue"
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bank);
